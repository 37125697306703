import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { ILotMappedItem } from '@app/logic/lots';
import { ISpecGroupDto } from '@app/logic/spec-groups';

interface IData {
    houseAreas: ISpecGroupDto[];
    lotMappedItem: ILotMappedItem;
    lotSpec: ILotSpecMappedItem;
    fullscreen: boolean;
    isSkinnyView: boolean;
    lotSpecVersion: number;
}

@Component({
    templateUrl: './lot-spec-view-fullscreen-dialog.component.html',
    styleUrls: ['./lot-spec-view-fullscreen-dialog.component.scss']
})
export class LotSpecViewFullScreenDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '100%';

    constructor(
        public readonly dialogRef: MatDialogRef<LotSpecViewFullScreenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) { }

    public ngOnInit(): void {
        const test = this.data;
    }
}
