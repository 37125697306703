<div class="cb-margin flex-col">
    <mat-card class="flex-row">
        <mat-card-content class="mat-card-content-row start-center">
            <mat-icon>search</mat-icon>
            <mat-form-field class="cb-margin-left cb-margin-right-2x flex">
                <mat-label>Search Notes</mat-label>
                <input type="text"
                       aria-label="Search Notes"
                       [formControl]="searchText"
                       name="{{entityName}}-notes-search"
                       matInput/>
            </mat-form-field>
            <button mat-raised-button
                    color="primary"
                    *ngIf="canAddNote.value"
                    (click)="createNote()">
                Add Note
            </button>
        </mat-card-content>
    </mat-card>
    <cb-notes-list class="cb-margin-top"
                   [permissions]="permissions"
                   [notesLoaded]="notesLoaded"
                   [edit]="edit"
                   [entity]="entity"
                   [notes]="filteredNotes.value"
                   (notesChange)="notes=$event"></cb-notes-list>
</div>
