import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {CbDisplayValueModule} from '@app/shared/components/display-value/display-value.module';
import {SlotsComponent} from './slots.component';
import {ManageSlotDialogComponent} from './slot-dialog/manage-slot-dialog.component';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbInfoMessageModule, CbInfoPopupModule, CbLoadingSpinnerModule, CbSelectListModule, CbTableModule} from 'cb-hub-lib';
import {DragulaModule} from 'ng2-dragula';
import {MatExpansionModule} from '@angular/material/expansion';
import {CbClickModule} from '@app/shared/directives/click/click.module';
import {HouseAreaSlotsTableComponent} from './house-area-slots-table/house-area-slots-table.component';
import {MatSlideToggleModule as MatSlideToggleModule} from '@angular/material/slide-toggle';
import {CbWaitForModule} from '@app/shared/directives/wait-for/wait-for.module';
import {CbPostLoadModule} from '@app/shared/directives/postload/postload.module';

@NgModule({
    declarations: [
        SlotsComponent,
        ManageSlotDialogComponent,
        HouseAreaSlotsTableComponent,
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbDisplayValueModule,
        CbHubTableModule,
        CbTableModule,
        DragulaModule,
        CbInfoPopupModule,
        CbSelectListModule,
        MatExpansionModule,
        CbClickModule,
        MatSlideToggleModule,
        CbWaitForModule,
        CbPostLoadModule,
    ],
    exports: [
        SlotsComponent
    ]
})
export class SlotsSettingsModule { }
