import {UsersLogicService} from '@app/logic/users';
import {MatDialog} from '@angular/material/dialog';
import {Injectable} from '@angular/core';
import {SearchService} from './base.search.service';

@Injectable()
export class BusinessAccountsInputFindSearchService extends SearchService {

    constructor(
        public readonly userLogicService: UsersLogicService,
        public readonly dialog: MatDialog
    ) {
        super();
    }
}
