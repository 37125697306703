<div class="cb-margin">
    <mat-card class="cb-margin-bottom flex-col">
        <mat-card-content>
            <form #form="ngForm">
                <div class="start-center flex-row flex">
                    <mat-icon>search</mat-icon>
                    <cb-input type="text"
                              label="Search"
                              [(ngModel)]="searchFiltersCache.data.query"
                              (ngModelChange)="searchFiltersChanged$.next($event)"
                              name="search"
                              class="cb-margin-left cb-margin-right-2x flex">
                    </cb-input>
                    <cb-select label="Assigned To"
                               name="userId"
                               [(ngModel)]="searchFiltersCache.data.userId"
                               [options]="teamUsersOptions$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select-list label="Status"
                                    name="statusId"
                                    class="cb-margin-right-2x flex"
                                    [allOptions]="revisionStatuses$ | async"
                                    [multiple]="true"
                                    [(value)]="searchFiltersCache.data.statusId">
                    </cb-select-list>
                    <cb-select label="Order"
                               name="resultOrders"
                               [(ngModel)]="searchFiltersCache.data.order"
                               [options]="resultOrders$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select class="cb-margin-right"
                               label="Direction"
                               [(ngModel)]="searchFiltersCache.data.direction"
                               name="direction"
                               [options]="resultDirections$ | async">
                    </cb-select>
                    <span class="flex"></span>
                    <cb-button class="cb-margin"
                               (clicked)="createRevision()">
                        Create Revision
                    </cb-button>
                </div>
            </form>
            <cb-table *ngIf="cacheLoaded && searchEnabled"
                      class="cb-margin-top flex"
                      label="Pricing Revisions"
                      maxHeightOffset="280"
                      [infiniteScrollEnabled]="infiniteScrollEnabled"
                      [fetch]="fetchResults()"
                      [loaded]="loaded"
                      [queryUpdated]="searchFiltersChanged$"
                      [(results)]="results"
                      [(currentPage)]="currentPage">
                <thead cbTableHead>
                <tr>
                    <td>Job #</td>
                    <td>Lot #</td>
                    <td>Client Account</td>
                    <td>Revision</td>
                    <td>Complexity</td>
                    <td>Takeoff ID</td>
                    <td>Takeoff Applied</td>
                    <td>Created</td>
                    <td>Due</td>
                    <td>Status</td>
                    <td>Assigned To</td>
                    <td>Reviewer</td>
                    <td></td>
                </tr>
                </thead>
                <tbody cbTableBody>
                <tr *ngFor="let revision of results">
                    <cb-td-text [value]="revision.jobNumber"></cb-td-text>
                    <cb-td-text [value]="revision.lotNumber ? String(revision.lotNumber) : null"></cb-td-text>
                    <!-- revision.clientAccountName does not exist so this is a bug; commented out as part of #39702 -->
                    <!-- remove the below cb-td-text when fixing -->
                    <!--                <cb-td-text [value]="revision.clientAccountName"></cb-td-text>-->
                    <cb-td-text [value]="'-'"></cb-td-text>
                    <cb-td-text [value]="revision.revisionNumber"></cb-td-text>
                    <cb-td-text [value]="revision.complexityCode"></cb-td-text>
                    <cb-td-text [value]="revision.takeOffId ? String(revision.takeOffId) : null"></cb-td-text>
                    <cb-td-checkbox [value]="revision.takeOffApplied"></cb-td-checkbox>
                    <cb-td-date [value]="revision.createdDate"></cb-td-date>
                    <cb-td-date [value]="revision.dueBy"></cb-td-date>
                    <cb-td-text [value]="revision.status"></cb-td-text>
                    <cb-td-text [value]="revision.assignedToUserName"></cb-td-text>
                    <cb-td-text [value]="revision.reviewerName"></cb-td-text>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <cb-button [menu]="menu"></cb-button>
                        <mat-menu #menu="matMenu">
                            <cb-button [isMenuItem]="true"
                                       icon="edit"
                                       menuItemLabel="Edit Revision"
                                       *ngIf="hasPermissionsToEditRevision()"
                                       (clicked)="editRevision(revision)">
                            </cb-button>
                            <cb-button [isMenuItem]="true"
                                       icon="archive"
                                       menuItemLabel="Finalise Revision"
                                       *ngIf="hasPermissionsToFinaliseRevision()"
                                       (clicked)="finaliseRevision(revision)">
                            </cb-button>
                            <cb-button [isMenuItem]="true"
                                       icon="pageview"
                                       menuItemLabel="View Lot"
                                       (clicked)="viewLot(revision.lotId)">
                            </cb-button>
                        </mat-menu>
                    </td>
                </tr>
                </tbody>
            </cb-table>
        </mat-card-content>
    </mat-card>
</div>
