<mat-card class="cb-margin flex-col flex">
    <mat-card-header class="cb-margin-bottom-2x">
        <mat-icon class="cb-grey"
                  mat-card-avatar>account_circle</mat-icon>
        <mat-card-title style="margin-bottom: 6px">{{dto.name}}</mat-card-title>
        <mat-card-subtitle>{{dto.jobTitle | cbDasher}}</mat-card-subtitle>
        <span class="flex"></span>
        <mat-icon color="primary"
                  [hidden]="!dto.isActive">check_circle</mat-icon>
        <mat-icon class="cb-grey"
                  [hidden]="dto.isActive">radio_button_unchecked</mat-icon>
    </mat-card-header>
    <mat-card-content class="flex cb-margin-bottom-2x">
        <ng-container>
            <div class="flex-col">
                <cb-display-value-new label="Business Region">
                    {{dto.primaryBusinessEntity}}
                </cb-display-value-new>
                <cb-display-value-new label="Email">
                    {{dto.email}}
                </cb-display-value-new>
                <cb-display-value-new label="Username">
                    {{dto.username}}
                </cb-display-value-new>
            </div>
        </ng-container>
    </mat-card-content>
    <mat-divider class="card-divider"></mat-divider>
    <mat-card-actions class="end-center flex-row">
        <cb-button (clicked)="viewUser(dto.id)"
                   color="accent"
                   *ngIf="canView()"
                   [isStroked]="true">
            <mat-icon>pageview</mat-icon>
            &nbsp;View
        </cb-button>
    </mat-card-actions>
</mat-card>
