<cb-dialog [dialogHeading]="isAdd() ? 'Create Contact' : 'Edit Contact'"
           (cancelDialog)="cancel(createContactForm.dirty)">

    <!-- <ng-container *cbWaitFor="mappedItem"> -->
    <form #createContactForm="ngForm"
          #infiniteScrollContainer
          class="cb-padding flex-row flex">
        <div class="cb-margin-right-3x flex-col flex-33">
            <h4 class="cb-title cb-margin-top-2x cb-margin-bottom-2x">Contact Info</h4>
            <mat-checkbox name="incomplete"
                          [(ngModel)]="mappedItem.incomplete"
                          [disabled]="getIncompleteDisabled()"
                          *ngIf="!data.mustComplete">
                Don't know full details
            </mat-checkbox>
            <cb-select [required]="!mappedItem.incomplete"
                       name="title"
                       label="Title"
                       [options]="salutationLookup"
                       [(ngModel)]="mappedItem.title">
            </cb-select>
            <div class="start-center flex-row">
                <div class="flex-col flex">
                    <cb-input type="text"
                              name="firstName"
                              label="First Name"
                              [maxlength]="100"
                              [required]="true"
                              [(ngModel)]="mappedItem.firstName"
                              (change)="fullNameChanged(fullNameTooltip)">
                    </cb-input>
                    <cb-input type="text"
                              name="lastName"
                              label="Last Name"
                              [maxlength]="100"
                              [required]="!mappedItem.incomplete"
                              [(ngModel)]="mappedItem.lastName"
                              (change)="fullNameChanged(fullNameTooltip)">
                    </cb-input>
                </div>
                <button [hidden]="contactsMatching.fullName.length < 1"
                        class="cb-margin-left"
                        mat-icon-button
                        #fullNameTooltip="matTooltip"
                        matTooltip
                        (click)="openSelectExistingContact(fullNameTooltip)">
                    <mat-icon>info_circle</mat-icon>
                </button>
            </div>
            <cb-select [required]="!mappedItem.incomplete"
                       name="status"
                       label="Contact Status"
                       [options]="contactStatusLookup"
                       [(ngModel)]="mappedItem.status">
            </cb-select>
            <div class="start-center flex-row">
                <cb-input class="flex"
                          type="text"
                          name="email"
                          label="Email"
                          [maxlength]="100"
                          [required]="true"
                          [(ngModel)]="mappedItem.email"
                          (change)="emailChanged(emailTooltip)"></cb-input>
                <mat-icon *ngIf="mappedItem.preferredContact === CONTACT_METHOD_ENUM.Email"
                          class="cb-margin-left"
                          color="accent"
                          title="Preferred Contact Method">check_circle</mat-icon>
                <button [hidden]="contactsMatching.emailAddress.length < 1"
                        class="cb-margin-left"
                        mat-icon-button
                        #emailTooltip="matTooltip"
                        matTooltip
                        (click)="openSelectExistingContact(emailTooltip)">
                    <mat-icon>info_circle</mat-icon>
                </button>
            </div>
            <div class="start-center flex-row">
                <cb-input class="flex"
                          type="text"
                          name="phoneHome"
                          label="Home Phone"
                          [maxlength]="100"
                          [(ngModel)]="mappedItem.phoneHome"
                          (change)="phoneHomeChanged(phoneHomeTooltip)"></cb-input>
                <mat-icon *ngIf="mappedItem.preferredContact === CONTACT_METHOD_ENUM.HomePhone"
                          class="cb-margin-left"
                          color="accent"
                          title="Preferred Contact Method">check_circle</mat-icon>
                <button [hidden]="contactsMatching.phoneHome.length < 1"
                        class="cb-margin-left"
                        mat-icon-button
                        #phoneHomeTooltip="matTooltip"
                        matTooltip
                        (click)="openSelectExistingContact(phoneHomeTooltip)">
                    <mat-icon>info_circle</mat-icon>
                </button>
            </div>
            <div class="flex-row">
                <cb-input type="text"
                          name="phoneWork"
                          label="Work Phone"
                          [maxlength]="100"
                          [(ngModel)]="mappedItem.phoneWork"
                          (change)="addRemoveContactMethod(CONTACT_METHOD_ENUM.WorkPhone)"
                          style="width: 100%;"></cb-input>
                <span class="flex"></span>
                <mat-icon *ngIf="mappedItem.preferredContact === CONTACT_METHOD_ENUM.WorkPhone"
                          color="accent"
                          title="Preferred Contact Method">check_circle</mat-icon>
            </div>
            <div class="start-center flex-row">
                <cb-input class="flex"
                          type="text"
                          name="phoneMobile"
                          label="Mobile Phone"
                          [maxlength]="100"
                          [(ngModel)]="mappedItem.phoneMobile"
                          (change)="phoneMobileChanged(phoneMobileTooltip)"></cb-input>
                <mat-icon *ngIf="mappedItem.preferredContact === CONTACT_METHOD_ENUM.MobilePhone"
                          class="cb-margin-left"
                          color="accent"
                          title="Preferred Contact Method">check_circle</mat-icon>
                <button [hidden]="contactsMatching.phoneMobile.length < 1"
                        class="cb-margin-left"
                        mat-icon-button
                        #phoneMobileTooltip="matTooltip"
                        matTooltip
                        (click)="openSelectExistingContact(phoneMobileTooltip)">
                    <mat-icon>info_circle</mat-icon>
                </button>
            </div>
            <cb-select [required]="true"
                       name="contactMethods"
                       label="Preferred Contact Method"
                       [options]="contactMethods"
                       [required]="true"
                       [(ngModel)]="mappedItem.preferredContact">
            </cb-select>
        </div>
        <div class="cb-margin-right-2x flex-col flex-33">
            <cb-address-new [(ngModel)]="mappedItem.physicalAddress"
                            addressTitle="Physical Address"
                            name="physicalAddress"
                            [required]="!mappedItem.incomplete">
            </cb-address-new>
            <h4 class="cb-title cb-padding-top-3x">Marketing</h4>
            <div class="cb-margin-top-3x">
                <label [ngClass]="{'required': !mappedItem.incomplete}"
                       class="cb-margin-right-2x">Gender</label>
                <mat-radio-group name="gender"
                                 [(ngModel)]="mappedItem.gender"
                                 [required]="!mappedItem.incomplete">
                    <mat-radio-button [value]="'M'"
                                      class="cb-margin-right-2x">Male</mat-radio-button>
                    <mat-radio-button [value]="'F'">Female</mat-radio-button>
                </mat-radio-group>
            </div>
            <cb-select name="ageRange"
                       label="Age range"
                       [options]="ageRangeLookup"
                       [(ngModel)]="mappedItem.ageRange">
            </cb-select>
            <mat-checkbox name="getsSurveys"
                          [(ngModel)]="mappedItem.getsSurveys">Participate in surveys</mat-checkbox>
            <mat-checkbox name="getsNewsletter"
                          [(ngModel)]="mappedItem.getsNewsletter">Receive e-newsletter</mat-checkbox>
            <mat-checkbox name="isStaffMember"
                          [(ngModel)]="mappedItem.isStaffMember">Staff Member</mat-checkbox>
        </div>
        <div class="cb-margin-right-3x flex-col flex-33">
            <cb-address-new [(ngModel)]="mappedItem.postalAddress"
                            addressTitle="Postal Address"
                            name="postalAddress"
                            [required]="false">
            </cb-address-new>
        </div>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                [disabled]="!createContactForm.dirty || !createContactForm.valid || contactsMatching?.emailAddress?.length > 0"
                color="primary"
                (cbClick)="saveContact()"
                [clickLimit]="1">
            Save Contact
        </button>
        <button mat-raised-button
                (click)="cancel(createContactForm.dirty)">
            Cancel
        </button>
    </div>

    <!-- </ng-container> -->
</cb-dialog>
