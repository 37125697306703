<div class="cb-margin flex-col">
    <mat-tab-group class="flex"
                   (selectedIndexChange)="onTabChanged($event);"
                   [selectedIndex]="selectedTabIndex"
                   cbTabUrlParam="cbTabRouteId2"
                   mat-stretch-tabs="false"
                   [cbTabRoute]="LOTS_ROUTE">
        <mat-tab label="Programme"
                 cbTabRouteId="programme"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-lot-build-programme *cbWaitFor="lotDto$$ | async"
                                        [lotDto]="lotDto"
                                        (reloadLotMappedItem)="reloadLotMappedItem.emit()">
                </cb-lot-build-programme>
            </ng-template>
        </mat-tab>
        <mat-tab label="Documents"
                 cbTabRouteId="documents"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-lot-build-documents *cbWaitFor="lotDto$$ | async"
                                        [lotDto]="lotDto"></cb-lot-build-documents>
            </ng-template>
        </mat-tab>
        <mat-tab label="Orders"
                 cbTabRouteId="orders"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-lot-build-orders-search id="lot-view-tabscontent"
                                            style="display: block; max-height: calc(100vh - 227px); overflow-y: auto;"
                                            *cbWaitFor="lotMappedItem$$ | async"
                                            [lotMappedItem]="lotMappedItem"></cb-lot-build-orders-search>
            </ng-template>
        </mat-tab>
        <mat-tab label="Build Overhead Costs"
                 cbTabRouteId="buildoverheadcosts"
                 cbNestedTabLevels="0"
                 *ngIf="lotBuildOverheadCostsPermissions.canView()">
            <ng-template matTabContent>
                    <cb-lot-build-overhead-costs *cbWaitFor="lotMappedItem$$ | async"
                        [lotMappedItem]="lotMappedItem"></cb-lot-build-overhead-costs>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
