<cb-dialog (cancelDialog)="dialogRef.close(contact)">
    <div class="flex-row flex">
        <div class="cb-padding-right-2x flex-col flex">
            <mat-card class="cb-margin-bottom-2x">
                <mat-card-content>
                    <div class="flex-row">
                        <span class="mat-subtitle-1 flex">Accounts</span>
                        <button mat-icon-button
                                (click)="onAddEditClicked(BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Accounts)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <cb-table class="cb-margin-top"
                              [loaded]="true">
                        <thead cbTableHead>
                        <tr>
                            <td>Region</td>
                            <td>District</td>
                            <td>Area</td>
                            <td>Active</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody cbTableBody>
                        <tr *ngFor="let area of accountsAreas">
                            <td>{{ area.location?.region?.label }}</td>
                            <td>{{ area.location?.district?.label }}</td>
                            <td>{{ area.location?.area?.label }}</td>
                            <td>
                                <mat-icon class="cb-grey">check_box</mat-icon>
                            </td>
                            <td cbTableShrinkCol
                                cbTableStickyCol="end">
                                <button mat-icon-button
                                        class="cb-margin-right-2x"
                                        (click)="onAddEditClicked(BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Accounts, area)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button
                                        (click)="onDeleteClicked(area)">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </cb-table>
                </mat-card-content>
            </mat-card>
            <mat-card class="cb-margin-bottom-2x">
                <mat-card-content>
                    <div class="flex-row">
                        <span class="mat-subtitle-1 flex">Health and Safety</span>
                        <button mat-icon-button
                                (click)="onAddEditClicked(BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.HealthAndSafety)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <cb-table class="cb-margin-top"
                              [loaded]="true">
                        <thead cbTableHead>
                        <tr>
                            <td>Region</td>
                            <td>District</td>
                            <td>Area</td>
                            <td>Active</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody cbTableBody>
                        <tr *ngFor="let area of healthAndSafetyAreas">
                            <td>{{ area.location?.region?.label }}</td>
                            <td>{{ area.location?.district?.label }}</td>
                            <td>{{ area.location?.area?.label }}</td>
                            <td>
                                <mat-icon class="cb-grey">check_box</mat-icon>
                            </td>
                            <td cbTableShrinkCol
                                cbTableStickyCol="end">
                                <button mat-icon-button
                                        class="cb-margin-right-2x"
                                        (click)="onAddEditClicked(BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.HealthAndSafety, area)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button
                                        (click)="onDeleteClicked(area)">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </cb-table>
                </mat-card-content>
            </mat-card>
            <mat-card *ngIf="data.account && data.account.isInstaller"
                      class="cb-margin-bottom-2x">
                <mat-card-content>
                    <div class="flex-row">
                        <span class="mat-subtitle-1 flex">Installation</span>
                        <button mat-icon-button
                                (click)="onAddEditClicked(BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Installation)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <cb-table class="cb-margin-top"
                              [loaded]="true">
                        <thead cbTableHead>
                        <tr>
                            <td>Trade Type</td>
                            <td>Region</td>
                            <td>District</td>
                            <td>Area</td>
                            <td>Active</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody cbTableBody>
                        <tr *ngFor="let area of installationAreas">
                            <td>{{ getTradeTypeLabel(area.tradeTypeId) }}</td>
                            <td>{{ area.location?.region?.label }}</td>
                            <td>{{ area.location?.district?.label }}</td>
                            <td>{{ area.location?.area?.label }}</td>
                            <td>
                                <mat-icon class="cb-grey">check_box</mat-icon>
                            </td>
                            <td cbTableShrinkCol
                                cbTableStickyCol="end">
                                <button mat-icon-button
                                        class="cb-margin-right-2x"
                                        (click)="onAddEditClicked(BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Installation, area)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button
                                        (click)="onDeleteClicked(area)">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </cb-table>
                </mat-card-content>
            </mat-card>
            <mat-card class="cb-margin-bottom-2x">
                <mat-card-content>
                    <div class="flex-row">
                        <span class="mat-subtitle-1 flex">Orders</span>
                        <button mat-icon-button
                                (click)="onAddEditClicked(BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Orders)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <cb-table class="cb-margin-top"
                              [loaded]="true">
                        <thead cbTableHead>
                        <tr>
                            <td>Email Address</td>
                            <td>Region</td>
                            <td>District</td>
                            <td>Area</td>
                            <td>Active</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody cbTableBody>
                        <tr *ngFor="let area of ordersAreas">
                            <td>{{ area.emailAddress || contact.email }}</td>
                            <td>{{ area.location?.region?.label }}</td>
                            <td>{{ area.location?.district?.label }}</td>
                            <td>{{ area.location?.area?.label }}</td>
                            <td>
                                <mat-icon class="cb-grey">check_box</mat-icon>
                            </td>
                            <td cbTableShrinkCol
                                cbTableStickyCol="end">
                                <button mat-icon-button
                                        class="cb-margin-right-2x"
                                        (click)="onAddEditClicked(BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Orders, area)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button
                                        (click)="onDeleteClicked(area)">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </cb-table>
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-content>
                    <div class="flex-row">
                        <span class="mat-subtitle-1 flex">Sales</span>
                        <button mat-icon-button
                                (click)="onAddEditClicked(BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Sales)">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <cb-table class="cb-margin-top"
                              [loaded]="true">
                        <thead cbTableHead>
                        <tr>
                            <td>Region</td>
                            <td>District</td>
                            <td>Area</td>
                            <td>Active</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody cbTableBody>
                        <tr *ngFor="let area of salesAreas">
                            <td>{{ area.location?.region?.label }}</td>
                            <td>{{ area.location?.district?.label }}</td>
                            <td>{{ area.location?.area?.label }}</td>
                            <td>
                                <mat-icon class="cb-grey">check_box</mat-icon>
                            </td>
                            <td cbTableShrinkCol
                                cbTableStickyCol="end">
                                <button mat-icon-button
                                        class="cb-margin-right-2x"
                                        (click)="onAddEditClicked(BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Sales, area)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button
                                        (click)="onDeleteClicked(area)">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </cb-table>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="flex-col flex-20">
            <div class="flex-row">
                <span class="mat-subtitle-1 flex">{{ contact.name }}</span>
                <button mat-icon-button
                        (click)="onViewClicked()">
                    <mat-icon>pageview</mat-icon>
                </button>
            </div>
            <cb-input label="Position"
                      name="postion"
                      class="cb-margin-top-2x"
                      [(ngModel)]="contact.position"
                      [readonly]="true"></cb-input>
            <cb-input label="Email"
                      name="email"
                      class="cb-margin-top-2x"
                      [ngModel]="contact.email"
                      [readonly]="true"></cb-input>
            <cb-input label="Mobile"
                      name="mobile"
                      class="cb-margin-top-2x"
                      [ngModel]="contact.phoneMobile"
                      [readonly]="true"></cb-input>
        </div>
    </div>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                (click)="dialogRef.close(contact)">Close
        </button>
    </div>
</cb-dialog>
