<mat-card class="viewContainer flex-col flex">
    <mat-card-content>
        <form class="flex-col"
              #lotVariationViewForm="ngForm">
            <div class="flex-row">
                <h4 class="cb-title flex">Variation #{{ variationMappedItem.variationNumber }}</h4>
                <span class="flex"> </span>
                <button mat-raised-button
                        [matMenuTriggerFor]="menu"
                        *ngIf="showVariationMenu()">
                    Options
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngIf="!hasBeenApproved() && canEditVariation()"
                            mat-menu-item
                            (click)="editVariationDetails()">
                        <mat-icon>edit</mat-icon>
                        Edit Details
                    </button>
                    <button *ngIf="!hasBeenApproved() && canEditVariation()"
                            mat-menu-item
                            (click)="variationChanges()">
                        <mat-icon>edit</mat-icon>
                        Add or Remove Changes
                    </button>
                    <button *ngIf="!hasBeenApproved() && canDeleteVariation()"
                            mat-menu-item
                            (click)="deleteVariation()">
                        <mat-icon>delete</mat-icon>
                        Delete Variation
                    </button>
                    <button *ngIf="!hasBeenApproved() && canGenerateVariationReport()"
                            mat-menu-item
                            (click)="generateApprovalDocument()">
                        <mat-icon>insert_drive_file</mat-icon>
                        Generate Approval Document
                    </button>
                    <button *ngIf="hasBeenApproved()"
                            mat-menu-item
                            (click)="downloadSignedApprovalDocument()">
                        <mat-icon>file_download</mat-icon>
                        Download Signed Approval
                    </button>
                    <button *ngIf="canReuploadApprovalDocument()"
                            mat-menu-item
                            [disabled]="!canFinaliseVariation()"
                            (click)="reuploadApprovalDocument()">
                        <mat-icon>restore_page</mat-icon>
                        Replace Signed Approval
                    </button>
                </mat-menu>
            </div>
            <div class="flex-wrap flex-100">
                <div class="flex-col flex-50">
                    <cb-display-value-new label="Type"
                                          [value]="variationMappedItem?.costNature | cbEnumToLabel: COST_NATURE_ENUM">
                    </cb-display-value-new>
                    <cb-display-value-new label="Date"
                                          [value]="variationMappedItem?.variationDate | date:'dd MMM yyyy' ">
                    </cb-display-value-new>
                    <cb-display-value-new label="Variation To"
                                          [value]="variationMappedItem.variationToName">
                    </cb-display-value-new>
                    <div class="cb-margin-bottom flex-col">
                        <label class="label">Address</label>
                        <div>{{ variationMappedItem?.address?.street }}</div>
                        <div>{{ variationMappedItem?.address?.suburb }}</div>
                        <div>{{ variationMappedItem?.address?.city }} {{ variationMappedItem?.address?.postCode }}</div>
                        <div>{{ variationMappedItem?.address?.regionName }}</div>
                    </div>
                </div>
                <div class="flex-50">
                    <cb-display-value-new label="Status"
                                          [value]="variationMappedItem?.variationStatus | cbEnumToLabel: VARIATION_STATUS_ENUM">
                    </cb-display-value-new>
                    <cb-display-value-new label="Client Sale"
                                          [value]="variationMappedItem?.clientSaleNumber">
                    </cb-display-value-new>
                    <cb-checkbox name="extraToSaleAndPurchaseAgreement"
                                 label="Extra to Sale & Purchase Agreement"
                                 [readonly]="true"
                                 [(ngModel)]="variationMappedItem.extraToSaleAndPurchaseAgreement">
                    </cb-checkbox>
                    <cb-display-value-new label="Variation From"
                                          [value]="variationMappedItem.variationRaisedByUser ">
                    </cb-display-value-new>
                </div>
            </div>
            <cb-table [loaded]="true"
                      maxHeightOffset="310"
                      class="cb-margin-bottom-2x">
                <thead cbTableHead>
                <tr>
                    <td>Change Number</td>
                    <td>Description</td>
                    <td>Amount</td>
                    <td></td>
                </tr>
                </thead>
                <tbody cbTableBody>
                <tr cbTableRow
                    *ngFor="let variationLine of variationMappedItem.lines | cbSortByImpurePipe: 'sortOrder': 'asc'">
                    <td>{{ variationLine.changeRecordNumber }}</td>
                    <td>{{ variationLine.changeDetails }}</td>
                    <td *ngIf="variationMappedItem.isExtraToClient">
                        <strong>{{ variationLine.lineCost | cbCurrency:'addGst' }} (Inc Gst)</strong>
                    </td>
                    <td *ngIf="!variationMappedItem.isExtraToClient">
                        <strong>{{ variationLine.lineCost | cbCurrency }}</strong>
                    </td>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <button mat-icon-button
                                (click)="viewChange($event, variationLine.changeRecordId)">
                            <mat-icon>pageview</mat-icon>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="2"></td>
                    <td *ngIf="variationMappedItem.isExtraToClient">
                        <strong> {{ variationMappedItem.variationTotal | cbCurrency:'addGst' }} (Inc Gst)</strong>
                    </td>
                    <td *ngIf="!variationMappedItem.isExtraToClient">
                        <strong> {{ variationMappedItem.variationTotal | cbCurrency }} </strong>
                    </td>
                    <td></td>
                </tr>
                </tbody>
            </cb-table>
            <div class="cb-margin-bottom flex-col"
                 *ngIf="isAwaitingSignedApproval() && canFinaliseVariation()">
                <cb-drag-and-drop-document [(documentEntityMappedItem)]="documentEntity">
                </cb-drag-and-drop-document>
                <footer class="center-center flex-row flex">
                    <span class="flex"></span>
                    <button mat-raised-button
                            [disabled]="!hasDocumentBeenUploaded()"
                            color="primary"
                            (click)="uploadVariationDocument()">
                        Upload
                    </button>
                </footer>
            </div>
            <cb-user-date-time-new label="Created"
                                   [floatingLabel]="true"
                                   [date]="variationMappedItem?.createdDate"
                                   [displayTime]="true"
                                   [userFullName]="variationMappedItem?.createdByName"></cb-user-date-time-new>
            <cb-user-date-time-new label="Approval Document Generated"
                                   [floatingLabel]="true"
                                   [date]="variationMappedItem.approvalDocumentGeneratedDate"
                                   [displayTime]="true"
                                   [userFullName]="variationMappedItem.approvalDocumentGeneratedByName">
            </cb-user-date-time-new>
            <cb-user-date-time-new label="Approval Uploaded"
                                   [floatingLabel]="true"
                                   [date]="variationMappedItem.approvedDate"
                                   [displayTime]="true"
                                   [userFullName]="variationMappedItem.approvedByUser"></cb-user-date-time-new>
            <cb-user-date-time-new *ngIf="variationMappedItem.isDeleted"
                                   label="Deleted"
                                   [floatingLabel]="true"
                                   [date]="variationMappedItem.updatedDate"
                                   [displayTime]="true"
                                   [userFullName]="variationMappedItem.updatedByName"></cb-user-date-time-new>
        </form>
    </mat-card-content>
</mat-card>
