<mat-card class="flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon mat-mdc-header-card-avatar">
            <mat-icon class="cb-primary"
                      title="Complete"
                      *ngIf="mappedItem?.stepDesignAndBuildComplete">
                check_circle
            </mat-icon>
            <mat-icon class="cb-error"
                      title="Incomplete"
                      *ngIf="!mappedItem?.stepDesignAndBuildComplete">
                error
            </mat-icon>
        </div>
        <mat-card-title [ngClass]="!(mappedItem?.stepDesignAndBuildComplete) ? 'cb-error' : ''">
            2. Design &amp; Build
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="start-start flex-col flex">
        <cb-display-value-new label="Lot Number"
                              [ngClass]="!(designAndBuildInterest?.lot?.lotNumber) ? 'cb-error' : ''">
            {{designAndBuildInterest?.lot?.lotNumber}}
        </cb-display-value-new>
        <cb-display-value-new label="Address">
            <div class="flex-col">
                <span [ngClass]="!(designAndBuildInterest?.lot?.address?.street) ? 'cb-error' : ''">
                    {{designAndBuildInterest?.lot?.address?.street | cbDasher}}</span>
                <span [ngClass]="!(designAndBuildInterest?.lot?.address?.suburb) ? 'cb-error' : ''">
                    {{designAndBuildInterest?.lot?.address?.suburb | cbDasher}}</span>
                <span [ngClass]="!(designAndBuildInterest?.lot?.address?.postCode) ? 'cb-error' : ''">
                    {{designAndBuildInterest?.lot?.address?.postCode | cbDasher}}</span>
                <span [ngClass]="!(designAndBuildInterest?.lot?.address?.city) ? 'cb-error' : ''">
                    {{designAndBuildInterest?.lot?.address?.city | cbDasher}}</span>
                <span [ngClass]="!(designAndBuildInterest?.lot?.address?.regionName) ? 'cb-error' : ''">
                    {{designAndBuildInterest?.lot?.address?.regionName | cbDasher}}</span>
            </div>
        </cb-display-value-new>
        <cb-display-value-new label="Build Type"
                              [ngClass]="!(designAndBuildInterest?.buildTypeId) ? 'cb-error' : ''">
            {{buildType?.label}}
        </cb-display-value-new>
    </mat-card-content>
    <div *ngIf="canEdit && !mappedItem.isQualified()">
        <mat-divider class="card-divider"></mat-divider>
    </div>
    <mat-card-actions class="end-center flex-row"
                      *ngIf="canEdit && !mappedItem.isQualified()">
        <button mat-stroked-button
                color="accent"
                *ngIf="canEdit"
                (cbClick)="edit()">
            <mat-icon>edit</mat-icon>
            &nbsp;Edit
        </button>
    </mat-card-actions>
</mat-card>
