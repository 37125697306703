import {VendorsInputFindDirective} from './vendors-input-find.directive';
import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {getBaseFormComponentDirectiveProvider} from '../../../base-form-component';
import {provideParentForm} from '@app/shared/providers/provide-parent-form.provider';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {VendorsInputFindDialogCardsComponent} from '../../dialog/extensions/vendors/vendors-input-find-dialog-cards.component';
import {VendorsLogicService} from '@app/logic/vendors/vendors.logic.service';
import {VendorSearchService} from '@app/core/services/search/vendor.search.service';

@Component({
    selector: 'cb-vendors-input-find-cards',
    templateUrl: '../../base-input-find.directive.html',
    styleUrls: ['../../base-input-find.directive.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(VendorsInputFindCardsComponent),
        VendorSearchService,
        provideParentForm()
    ]
})
export class VendorsInputFindCardsComponent extends VendorsInputFindDirective {
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public readonly vendorsLogicService: VendorsLogicService,
        public readonly navigationService: NavigationService,
        public readonly searchService: VendorSearchService) {
        super(
            dialogRef,
            router,
            vendorsLogicService,
            navigationService,
            searchService
        );
        this._myDialog = VendorsInputFindDialogCardsComponent;
    }
}
