<h1 mat-dialog-title class="dialog-toolbar">
    {{ dialogHeading }}
    <span class="flex"></span>
    <button [attr.tabindex]="disableCloseButtonFocus ? -1 : null" mat-icon-button mat-dialog-close (click)="cancel(true) ">
        <mat-icon>close</mat-icon>
    </button>
</h1>

<mat-dialog-content class="content-container cb-padding-top-2x">
    <ng-content></ng-content>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <ng-content select="[actions]"></ng-content>
</mat-dialog-actions>
