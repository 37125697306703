import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ILotSpecScheduleItemMappedItem, LotSpecScheduleItemLogicService} from '@app/logic/lot-spec-schedule-item';
import {BlockingDialogComponent} from '@app/shared/components/dialog/blocking-dialog/blocking-dialog.component';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {ComputedProperty} from '@app/shared/utils/computed-property.util';
import {COST_TYPE_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {LotSpecificationService} from '../services/lot-specification.service';

interface IData {
    lotId: number;
    houseAreaItems: ILotSpecScheduleItemMappedItem[];
    houseAreaName: string;
}

@Component({
    selector: 'cb-lot-spec-schedule-items-remove-house-area-dialog',
    templateUrl: './lot-spec-schedule-items-remove-house-area-dialog.component.html',
    styleUrls: ['./lot-spec-schedule-items-remove-house-area-dialog.component.scss']
})
export class LotSpecScheduleItemsRemoveHouseAreaDialogComponent {
    public static readonly MIN_WIDTH = '40%';
    public static readonly BLOCK_MESSAGE = 'Please wait... your data is being processed';
    private readonly lotId: number;
    public selectedHouseAreaItems: { [id: number]: boolean } = {};
    public houseAreaItems: ILotSpecScheduleItemMappedItem[];
    public readonly costTypeEnum = COST_TYPE_ENUM;
    public blockingRef: MatDialogRef<BlockingDialogComponent>;
    public hasDeleted: { [id: number]: boolean } = {};
    public readonly canSave = new ComputedProperty(() => {
        return Object.values(this.selectedHouseAreaItems).some(x => x === true);
    });
    public houseAreaName: string;

    constructor(
        public readonly dialogRef: MatDialogRef<LotSpecScheduleItemsRemoveHouseAreaDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        public readonly lotSpecificationService: LotSpecificationService,
        public readonly lotSpecScheduleItemLogic: LotSpecScheduleItemLogicService,
    ) {
        this.houseAreaItems = data.houseAreaItems;
        this.lotId = data.lotId;
        this.houseAreaName = data.houseAreaName;
    }

    public getQRef(quoteLineId): string {
        if (quoteLineId > 0) {
            return 'Q' + quoteLineId;
        } else {
            return '';
        }
    }

    public removeHouseAreaItems(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete Items from the Lot Specification',
            // eslint-disable-next-line max-len
            message: 'Are you sure you want to delete these items from the Lot Specification?\n\nPlease Note\nAny child items will be removed with the parent item AND\nActive quote lines associated with removed items will be abandoned, and quotes with no active quote lines will be abandoned.',
            confirmed: () => {
                this.blockingRef = this.cbDialog.block('Please wait... your data is being processed');
                this.removeHouseAreaItem(0);
            }
        });
    }

    public removeHouseAreaItem(index: number): void {
        // If we have removed all the possible house area items, close this dialog
        if (this.houseAreaItems.length <= index) {
            this.blockingRef?.close();
            return this.dialogRef.close(Object.keys(this.selectedHouseAreaItems));
        }

        // If the current house area item has never been checked or is unchecked (false), skip this one
        const id = this.houseAreaItems[index]?.id;
        if (!this.selectedHouseAreaItems[id]) {
            return this.removeHouseAreaItem(index + 1);
        }

        const item = this.houseAreaItems[index];

        this.lotSpecScheduleItemLogic.delete(item, this.lotId).subOnce({
            next: () => {
                this.hasDeleted[id] = true;
                this.removeHouseAreaItem(index + 1);
            },
            error: () => {
                this.blockingRef.close();
            }
        });
    }

    public selectAll(): void {
        this.houseAreaItems.forEach(item => {
            this.selectedHouseAreaItems[item.id] = true;
        });
        this.canSave.recompute();
    }

    public deSelectAll(): void {
        this.selectedHouseAreaItems = {};
        this.canSave.recompute();
    }

    public cancel(): void {
        this.deSelectAll();
        this.blockingRef?.close();
        this.dialogRef.close();
    }

}
