<cb-dialog>
    <form class="flex-col flex"
          #predecessorForm="ngForm">
        <span class="mat-headline-6">{{getDisplayString(data.stage, data.activity)}}</span>
        <mat-form-field *ngIf="!predecessor.predecessorBuildProgrammeActivityId"
                        class="cb-margin-bottom-2x">
            <mat-label>Predecessor Activity</mat-label>
            <input matInput
                   [required]="true"
                   [formControl]="activityControl"
                   [matAutocomplete]="auto"/>
            <mat-autocomplete autoActiveFirstOption
                              #auto="matAutocomplete"
                              [displayWith]="getDisplayWith.bind(this)">
                <mat-option *ngFor="let existingActivity of filteredOptions | async"
                            [value]="existingActivity.id">{{existingActivity.label}}</mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <cb-checkbox label="Set the Activity Start Date based on completion of the predecessor Activity's End Date"
                     name="shouldSetStartBasedOnPredecessor"
                     class="cb-margin-top-2x"
                     [(ngModel)]="predecessor.shouldSetStartBasedOnPredecessor"></cb-checkbox>
        <mat-radio-group class="flex-col flex"
                         [(ngModel)]="predecessor.dateCalcType"
                         [ngModelOptions]="{standalone: true}">
            <mat-radio-button *ngFor="let dateType of dateCalculationTypes"
                              class="mat-accent"
                              [value]="dateType.id">{{dateType.label}}
            </mat-radio-button>
        </mat-radio-group>
        <cb-input label="Lag Days"
                  name="lagDays"
                  class="cb-margin-top-2x"
                  [required]="!!predecessor.shouldSetStartBasedOnPredecessor"
                  [(ngModel)]="predecessor.lagDays"></cb-input>
        <strong>(Days from Activity End Date)</strong>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                [disabled]="!predecessorForm.valid || (!predecessor.predecessorBuildProgrammeActivityId && !activityControl.value)"
                (click)="saveClicked()">Save</button>
        <button mat-raised-button
                (click)="dialogRef.close()">Cancel</button>
    </div>
</cb-dialog>
