<form #dateFilterForm="ngForm"
      class="filter-row start-center flex-row">
    <cb-datepicker label="Date From"
                   name="dateFrom"
                   [(ngModel)]="dateFrom"
                   (change)="cleanSearch()"></cb-datepicker>
    <cb-datepicker label="Date To"
                   name="dateTo"
                   [(ngModel)]="dateTo"
                   (change)="cleanSearch()"></cb-datepicker>
    <cb-select label="Message Name"
               name="messageName"
               [(ngModel)]="messageName"
               [options]="messageNames"
               (change)="cleanSearch()"></cb-select>
    <mat-icon>
        search
    </mat-icon>
    <mat-form-field class="flex">
        <mat-label>Search Message History</mat-label>
        <input type="text"
               aria-label="Search Message History"
               [formControl]="searchText"
               name="logsSearch"
               matInput/>
    </mat-form-field>
    <button [matMenuTriggerFor]="menu"
            mat-icon-button>
        <mat-icon>
            more_horiz
        </mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
                (click)="reindexLogs()">
            Fetch Latest Messages
        </button>
        <button mat-menu-item
                (click)="initiatePing()">
            Initiate Ping
        </button>
    </mat-menu>
</form>
<div class="chips-container flex-col">
    <mat-chip-set>
        <mat-chip (removed)="clearFilters()">
            <strong class="mat-body-2">
                Clear All Filters
            </strong>
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
        <mat-chip *ngIf="dateFrom != null"
                  (removed)="clearDateFrom()">
            <strong class="mat-body-2">
                Date From - {{dateFrom | date}}
            </strong>
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
        <mat-chip *ngIf="dateTo != null"
                  (removed)="clearDateTo()">
            <strong class="mat-body-2">
                Date To - {{dateTo | date}}
            </strong>
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
        <mat-chip *ngIf="messageName != null && messageName !== '0'"
                  (removed)="clearMessageName()">
            <strong class="mat-body-2">
                Message Name - {{messageName}}
            </strong>
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
        <mat-chip *ngIf="!isNullOrWhiteSpace(searchText.value)"
                  (removed)="searchText.setValue('')">
            <strong class="mat-body-2">
                Query - {{searchText.value}}
            </strong>
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
    </mat-chip-set>
</div>
<cb-table class="cb-margin-top flex"
          maxHeightOffset="312"
          [objectColumns]="columns"
          [loaded]="true"
          [fetch]="search()"
          [queryUpdated]="queryUpdated"
          [infiniteScrollEnabled]="infiniteScrollEnabled"
          [(results)]="logs"
          [(currentPage)]="currentPage">
    <tr cbTableRow
        *ngFor="let message of logs">
        <cb-td-text [value]="message.date | date:'dd/MM/yyyy HH:mm:ss'"></cb-td-text>
        <cb-td-text [value]="message.instanceId"></cb-td-text>
        <cb-td-text [value]="message.instancePrivateIp"></cb-td-text>
        <cb-td-text [value]="message.sentOrReceived"></cb-td-text>
        <cb-td-text [value]="message.name"></cb-td-text>
        <cb-td-text [value]="message.version"></cb-td-text>
        <td cbTableShrinkCol
            cbTableStickyCol="end">
            <button mat-icon-button
                    (click)="viewMessage(message);">
                <mat-icon>
                    help_outline
                </mat-icon>
            </button>
        </td>
    </tr>
</cb-table>
