<div class="flex-col flex">
    <mat-expansion-panel class="mat-elevation-z cb-margin-bottom"
                         expanded="true">
        <mat-expansion-panel-header>
            <mat-panel-title class="cb-grey">
                <mat-icon>announcement</mat-icon>
                &nbsp;
                <b>Alerts</b>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="hasAbmCodeAlert()"
             class="alert-message warn">
            <mat-icon>warning</mat-icon>
            <span>ABM Code Required By Finance</span>
        </div>
        <div *ngIf="!account.isClosedOrDeclined() && !account.ordersContactExistsOnAccount && !account.isRatesOnly"
             class="alert-message warn">
            <mat-icon>warning</mat-icon>
            <span>Orders Contact Required</span>
        </div>
        <div *ngIf="!account.isClosedOrDeclined() && !account.creditorsContactExistsOnAccount"
             class="alert-message warn">
            <mat-icon>warning</mat-icon>
            <span>Accounts Contact Required</span>
        </div>
        <div *ngIf="!account.isClosedOrDeclined() && !account.allRequiredDocumentsUploaded"
             class="alert-message warn">
            <mat-icon>warning</mat-icon>
            <span>Document Upload Required</span>
        </div>
        <div *ngIf="!account.isClosedOrDeclined() && account.anyDocumentRequiresReview"
             class="alert-message warn">
            <mat-icon>warning</mat-icon>
            <span>Document Review Required</span>
        </div>
        <div *ngIf="account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Closed"
             class="alert-message">
            <mat-icon>info_outline</mat-icon>
            <span>Account Closed</span>
        </div>
        <div *ngIf="account.status === BUSINESS_ACCOUNT_STATUS_ENUM.Declined"
             class="alert-message error">
            <mat-icon>error</mat-icon>
            <span>Account Declined</span>
        </div>
        <div *ngIf="account.status === BUSINESS_ACCOUNT_STATUS_ENUM.RequestCancelled"
             class="alert-message">
            <mat-icon>info_outline</mat-icon>
            <span>Request Cancelled</span>
        </div>
        <div *ngIf="account.status === BUSINESS_ACCOUNT_STATUS_ENUM.RequestDeclined"
             class="alert-message error">
            <mat-icon>error</mat-icon>
            <span>Request Declined</span>
        </div>
        <div *ngIf="account.status === BUSINESS_ACCOUNT_STATUS_ENUM.OnHold"
             class="alert-message error">
            <mat-icon>error</mat-icon>
            <span>Account On Hold</span>
        </div>
    </mat-expansion-panel>
    <hr class="mat-divider cb-margin-bottom"/>
    <mat-expansion-panel class="mat-elevation-z cb-margin-bottom"
                         expanded="true">
        <mat-expansion-panel-header>
            <mat-panel-title class="cb-grey">
                <mat-icon>assignment</mat-icon>
                &nbsp;
                <b>Account Approval</b>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="flex-col"
             #requestApproval>
            <span class="mat-subtitle-1">Account Request</span>
            <div class="cb-padding-left flex-col">
                <button *ngIf="canSubmitAccount()"
                        mat-stroked-button
                        color="accent"
                        (click)="onSubmitAccountClicked()">
                    Submit Account For Approval
                </button>
                <button *ngIf="canSubmitRequest()"
                        mat-stroked-button
                        color="accent"
                        (click)="onSubmitRequestClicked()">
                    Submit Request For Approval
                </button>
                <button *ngIf="canCancelRequest()"
                        mat-stroked-button
                        color="warn"
                        (click)="onCancelRequestClicked()">
                    Cancel Account Request
                </button>
                <cb-user-date-time-new [label]="'Requested By'"
                                       [userFullName]="account.requestedBy"
                                       [date]="account.requestedDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true"></cb-user-date-time-new>
                <cb-user-date-time-new *ngIf="account.requestCancelledByUser"
                                       [label]="'Request Cancelled By'"
                                       [userFullName]="account.requestCancelledByUser"
                                       [date]="account.requestCancelledDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true"></cb-user-date-time-new>
            </div>
        </div>
        <div class="flex-col"
             #requestApproval>
            <span class="mat-subtitle-1">Request Approval</span>
            <div class="cb-padding-left flex-col">
                <div class="flex-row"
                     *ngIf="canApproveRequest()">
                    <button mat-stroked-button
                            color="accent"
                            (click)="onApproveRequestClicked()">
                        <mat-icon>check_circle</mat-icon>
                        &nbsp;Approve Request
                    </button>
                    <span class="flex"></span>
                    <button mat-stroked-button
                            color="warn"
                            (click)="onDeclineRequestClicked()">
                        <mat-icon class="cb-error">cancel</mat-icon>
                        &nbsp;Decline Request
                    </button>
                </div>
                <cb-user-date-time-new *ngIf="account.requestApprovedByUser"
                                       [label]="'Approved By'"
                                       [userFullName]="account.requestApprovedByUser"
                                       [date]="account.requestApprovedDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true"></cb-user-date-time-new>
                <cb-user-date-time-new *ngIf="account.requestDeclinedByUser"
                                       [label]="'Declined By'"
                                       [userFullName]="account.requestDeclinedByUser"
                                       [date]="account.requestDeclinedDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true"></cb-user-date-time-new>
                <cb-text-area *ngIf="account.requestDeclinedByUser"
                              label="Declined Reason"
                              name="requestDeclinedReason"
                              [ngModel]="account.requestDeclinedReason"
                              [readonly]="true"></cb-text-area>
            </div>
        </div>
        <div class="flex-col"
             #contractApproval>
            <span class="mat-subtitle-1">Contract Approval</span>
            <div class="cb-padding-left flex-col">
                <div class="flex-row"
                     *ngIf="canApproveContract()">
                    <button mat-stroked-button
                            color="accent"
                            (click)="onApproveContractClicked()">
                        <mat-icon>check_circle</mat-icon>
                        &nbsp;Approve
                    </button>
                    <span class="flex"></span>
                    <button mat-stroked-button
                            color="warn"
                            (click)="onDeclineContractClicked()">
                        <mat-icon class="cb-error">cancel</mat-icon>
                        &nbsp;Decline
                    </button>
                </div>
                <cb-user-date-time-new *ngIf="account.approvedByProcurementUser"
                                       [label]="'Approved By'"
                                       [userFullName]="account.approvedByProcurementUser"
                                       [date]="account.approvedByProcurementDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true"></cb-user-date-time-new>
                <cb-user-date-time-new *ngIf="account.declinedByUser"
                                       [label]="'Declined By'"
                                       [userFullName]="account.declinedByUser"
                                       [date]="account.declinedDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true"></cb-user-date-time-new>
                <cb-text-area *ngIf="account.declinedByUser"
                              label="Declined Reason"
                              name="declinedReason"
                              [ngModel]="account.declinedReason"
                              [readonly]="true"></cb-text-area>
            </div>
        </div>
        <div class="flex-col"
             #healthApproval>
            <span class="mat-subtitle-1">Health &amp; Safety Approval</span>
            <div class="cb-padding-left flex-col">
                <div class="flex-row"
                     *ngIf="canApproveHealthAndSafety()">
                    <button mat-stroked-button
                            color="accent"
                            (click)="onApproveHealthAndSafetyClicked()">
                        <mat-icon>check_circle</mat-icon>
                        &nbsp;Approve
                    </button>
                    <span class="flex"></span>
                    <button mat-stroked-button
                            color="warn"
                            (click)="onDeclineHealthAndSafetyClicked()">
                        <mat-icon class="cb-error">cancel</mat-icon>
                        &nbsp;Decline
                    </button>
                </div>
                <cb-user-date-time-new *ngIf="account.approvedByHealthAndSafetyUser"
                                       [label]="'Approved By'"
                                       [userFullName]="account.approvedByHealthAndSafetyUser"
                                       [date]="account.approvedByHealthAndSafetyDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true"></cb-user-date-time-new>
                <cb-user-date-time-new *ngIf="account.healthandSafetyRequestDeclinedByUser"
                                       [label]="'Declined By'"
                                       [userFullName]="account.healthandSafetyRequestDeclinedByUser"
                                       [date]="account.healthandSafetyRequestDeclinedDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true"></cb-user-date-time-new>
                <cb-text-area *ngIf="account.healthandSafetyRequestDeclinedByUser"
                              label="Declined Reason"
                              name="healthandSafetyRequestDeclinedReason"
                              [ngModel]="account.healthandSafetyRequestDeclinedReason"
                              [readonly]="true"></cb-text-area>
            </div>
        </div>
        <div class="flex-col"
             #overrideApproval
             *ngIf="hasApprovalOverride()">
            <span class="mat-subtitle-1">Approval Override</span>
            <div class="cb-padding-left flex-col">
                <button *ngIf="canRequestApprovalOverride()"
                        mat-stroked-button
                        color="accent"
                        (click)="onRequestApprovalOverrideClicked()">
                    <mat-icon>warning</mat-icon>
                    &nbsp;Request Override
                </button>
                <cb-user-date-time-new *ngIf="account.approvalOverrideRequest"
                                       [label]="'Requested By'"
                                       [userFullName]="account.approvalOverrideRequest.requestedBy"
                                       [date]="account.approvalOverrideRequest.requestedDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true"></cb-user-date-time-new>
                <cb-text-area *ngIf="account.approvalOverrideRequest"
                              label="Approval Override Reason"
                              name="approvalOverrideRequestDescription"
                              [ngModel]="account.approvalOverrideRequest.description"
                              [readonly]="true"></cb-text-area>
                <div class="flex-row"
                     *ngIf="canApproveOverrideRequests()">
                    <div class="button-container">
                        <button mat-stroked-button
                                class="button-custom-disable"
                                color="accent"
                                [disabled]="!account.abmCode"
                                (click)="onApproveOverrideClicked()">
                            <mat-icon>check_circle</mat-icon>
                            &nbsp;Approve Override
                        </button>
                        <cb-popup-tip *ngIf="!account.abmCode"
                            class="blocking-reason-popup"
                            [icon]="'help'"
                            [iconColour]="'cb-accent'"
                            [horizontalPosition]="'right'"
                            [verticalPosition]="'top'">
                            <div class="flex-col">
                                <div class="mat-body-2 cb-margin"
                                     style="white-space: normal">
                                    Override can NOT be completed until an ABM Code has been loaded by the Finance Team.
                                </div>
                                <button mat-raised-button
                                        color="primary"
                                        class="cb-margin-top-2x"
                                        [disabled]="!account.canEditFinanceInformation() || !permissions.canEditFinanceInformation()"
                                        (click)="editFinanceInformationClicked.emit()">
                                    Edit Finance Information
                                </button>
                            </div>
                        </cb-popup-tip>
                    </div>
                    <button mat-stroked-button
                            class="button-custom-disable"
                            [ngClass]="!account.abmCode ? 'cb-margin-left-4x' : 'cb-margin-left-2x'"
                            color="warn"
                            [disabled]="!account.abmCode"
                            (click)="onDeclineOverrideClicked()">
                        <mat-icon>cancel</mat-icon>
                        &nbsp;Decline Override
                    </button>
                </div>
                <cb-user-date-time-new *ngIf="account.approvalOverrideRequest && account.approvalOverrideRequest.processedBy"
                                       [label]="BUSINESS_ACCOUNT_APPROVAL_OVERRIDE_REQUEST_OUTCOME_ENUM[account.approvalOverrideRequest.outcome]+' By'"
                                       [userFullName]="account.approvalOverrideRequest.processedBy"
                                       [date]="account.approvalOverrideRequest.processedDate"
                                       [displayTime]="true"
                                       [floatingLabel]="true"></cb-user-date-time-new>
            </div>
        </div>
    </mat-expansion-panel>
</div>
