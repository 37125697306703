import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbSideBarModule } from '@app/shared/components/side-bar/side-bar.module';
import { CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule } from 'cb-hub-lib';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BuildActivitiesComponent } from './build-activities.component';
import { BuildActivityDetailsComponent } from './tabs/build-activity-details/build-activity-details.component';
import { BuildActivityScheduleComponent } from './tabs/build-activity-schedule/build-activity-schedule.component';
import { BuildActivityTradeAndSupplyTypesComponent } from './tabs/build-activity-trade-and-supply-types/build-activity-trade-and-supply-types.component';

@NgModule({
    declarations: [
        BuildActivitiesComponent,
        BuildActivityDetailsComponent,
        BuildActivityScheduleComponent,
        BuildActivityTradeAndSupplyTypesComponent
    ],
    imports: [

        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        MatTooltipModule,
        CbSideBarModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatDividerModule,
        InfiniteScrollModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        MatAutocompleteModule,
        CbFormsModule,
        CbTableModule
    ],
    exports: [
        BuildActivitiesComponent
    ]
})
export class BuildActivitiesSettingsModule { }
