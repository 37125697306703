import { BaseLogicService } from '@app/logic/base/base-logic.service';
import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IProductCentreLogicService } from '@app/logic/product-centre/interfaces/i.product-centre.logic.service';
import { IProductCentreMappedItem } from '@app/logic/product-centre/interfaces/i.product-centre.mapped';
import { Observable } from 'rxjs';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { cleanObjectDeep } from '../../../../projects/cb-hub-lib/src/lib/utils/clean-object.util';
import { isNullOrWhiteSpace } from '../../../../projects/cb-hub-lib/src/lib/utils/string.util';
import { ProductCentreMappedItem } from '@app/logic/product-centre/product-centre.mapped';
import { IProductCentreCategoryDto } from '@app/logic/product-centre/i.product-centre-category.dto';
import { IProductCentreDocumentDto, ProductSearchTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';

export interface ProductCentreSearchParams {
    query: string;
    cat: number[]; // Category IDs
    t: ProductSearchTypeEnumId;// product types
    currentPage: number;
    pageSize: number;
    ao: boolean; // Active only
    be: number[]; // Business entities
}

@Injectable()
export class ProductCentreLogicService
    extends BaseLogicService<IProductCentreDocumentDto, IProductCentreMappedItem>
    implements IProductCentreLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('product-centre', ProductCentreMappedItem);
    }

    public $getSearchList(params: ProductCentreSearchParams): Observable<ISearchResult<IProductCentreDocumentDto>> {
        cleanObjectDeep(params, isNullOrWhiteSpace);
        return this.$http.get<ISearchResult<IProductCentreDocumentDto>>(`${this.$baseUri}/search`, params);
    }

    public getAllSlotCategories(): Observable<IProductCentreCategoryDto[]>{
        return this.$http.get<IProductCentreCategoryDto[]>(`${this.$baseUri}/categories`);
    }
}
