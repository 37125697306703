<cb-dialog dialogHeading="Submit Changes for Pricing">
    <form class="flex-col"
          #submitChangesForPricingform="ngForm">
        <span class="cb-margin-bottom-2x">
            <b>Which changes do you want to submit for Pricing?</b>
        </span>
        <cb-table class="flex"
                  label="Change Records"
                  [stringColumns]="displayedColumns"
                  [loaded]="true">
            <tr cbTableRow
                *ngFor="let change of pendingChanges">
                <td>
                    <cb-checkbox class="checkbox-toggle"
                                 name="isSelectedSubmitForPricing"
                                 label
                                 [(ngModel)]="change.isSelectedSubmitForPricing">
                    </cb-checkbox>
                </td>
                <td>
                    {{ change.changeNumber }}
                </td>
                <td>{{ change.changeDetails }}</td>
                <td>{{ change.costNature | cbEnumToLabel: COST_NATURE_ENUM }}</td>
            </tr>
        </cb-table>
    </form>
    <div actions>
        <button color="primary"
                mat-raised-button
                [disabled]="!isSubmitEnabled()"
                (cbClick)="submitChangesForPricing()"
                [clickLimit]="1">
            Submit
        </button>
        <button mat-raised-button
                (cbClick)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
