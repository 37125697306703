<cb-dialog dialogHeading="Reject Pre-Consent Plan">
    <form class="flex-col flex"
          #rejectPreConsentPlanForm="ngForm"
          id="itemForm">
        <cb-text-area label="Comments"
                      name="comments"
                      [required]="true"
                      [(ngModel)]="mappedItem.rejectionReason"
                      [maxlength]="1024">
        </cb-text-area>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="rejectPreConsentPlanForm.pristine || rejectPreConsentPlanForm.invalid"
                (click)="rejectPreconsent()">Submit
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
