import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

@Injectable()
export class SelectItemsService {
    public handleNewItem: (item: any) => void;
    public handleEditItem: (item: any) => void;
    public dialogComponent: any;
    public dialogHeading: string;
    constructor(
        public readonly dialog: MatDialog
    ) {
    }

    public openDialog = (item: any, dialogHeading: string): MatDialogRef<any> => {
        return this.dialog
            .open(
                this.dialogComponent,
                {
                    minWidth: '95%',
                    data: {
                        dialogHeading,
                        item
                    },
                    panelClass: 'cb-dialog-container'
                }
            );
    };

    public newItemClicked = (emptyItem: any): void => {
        this.openDialog(emptyItem, `Add ${this.dialogHeading}`)
            .afterClosed()
            .subOnce(this.handleNewItem.bind(this));
    };

    public editItemClicked = (item: any): void => {
        const copy = JSON.parse(JSON.stringify(item));
        this.openDialog(copy, `Edit ${this.dialogHeading}`)
            .afterClosed()
            .subOnce(this.handleEditItem.bind(this));
    };

    public deleteItemClicked = (item, items): Array<any> => {
        return items.filter(x => x.id !== item.id);
    };
}
