import {ChangeDetectorRef, Component, HostBinding, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { FormMode } from '@app/shared/enums/form';
import {
    DESIGN_SCHEME_LINE_STATUS_ENUM,
    IDesignRequirementItemDto,
} from '@classictechsolutions/hubapi-transpiled-enums';
import { DesignSchemeItemDialogComponent } from '../design-scheme-item-dialog/design-scheme-item-dialog.component';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { NgForm } from '@angular/forms';

@Component({

    selector: 'cb-design-scheme-items',
    templateUrl: './design-scheme-items.component.html',
    styleUrls: ['./design-scheme-items.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class DesignSchemeItemsComponent {
    @Input() public selectedTabIndex: number;
    @Input() public mappedItem: IDesignSchemeMappedItem;
    @Input() public showHeaderAndAddButton = true;
    @Input() public showDeleteButton = true;
    @Input() public addAndSave = false;
    @Input() public parentForm: NgForm;

    @HostBinding('class') class = 'flex-row';
    public FormMode = FormMode;
    public DESIGN_SCHEME_LINE_STATUS_ENUM = DESIGN_SCHEME_LINE_STATUS_ENUM;

    constructor(
        public readonly matDialog: MatDialog,
        private readonly cdRef: ChangeDetectorRef) { }

    public addEditItem(formMode: FormMode, item: IDesignRequirementItemDto): void {

        let nextID = 1;

        if (this.mappedItem.designRequirementItems) {
            nextID = this.mappedItem.designRequirementItems.length + 1;
        }

        if (!item) {
            item = { itemId: nextID } as any;
        }

        this.matDialog.open(DesignSchemeItemDialogComponent, {
            data: {
                item,
                mappedItem: this.mappedItem,
                formMode,
                addAndSave: this.addAndSave,
                selectedTabIndex: this.selectedTabIndex
            },
            panelClass: 'cb-dialog-container',
            minWidth: '40%',
        })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.parentForm?.form.markAsDirty();
                    this.cdRef.detectChanges();
                }
            });
    }

    public deleteItem(mappedItem: IDesignRequirementItemDto): void {
        mappedItem.isDeleted = true;

        const index = this.mappedItem.designRequirementItems.findIndex(
            (item) => item.itemId === mappedItem.itemId
        );

        this.mappedItem.designRequirementItems.splice(index, 1, mappedItem);
        this.parentForm.form.markAsDirty();
    }

}
