export interface WipColumn {
    key: string;
    label: string;
    type: 'date' | 'text' | 'custom';
    filterType: 'custom' | 'sortable';
}

export class WipConstants {
    public static displayedColumns: WipColumn[] = [
        {
            key: 'jobNumber',
            label: 'Job Number',
            type: 'custom',
            filterType: 'sortable',
        },
        {
            key: 'lotNumber',
            label: 'Lot Number',
            type: 'text',
            filterType: 'sortable'
        },
        {
            key: 'jobStatus',
            label: 'Job Status',
            type: 'text',
            filterType: 'sortable'
        },
        {
            key: 'businessEntity',
            label: 'Business Entity',
            type: 'custom',
            filterType: 'custom'
        },
        {
            key: 'projectName',
            label: 'Project Name',
            type: 'text',
            filterType: 'custom'
        },
        {
            key: 'releaseStage',
            label: 'Release Stage',
            type: 'text',
            filterType: 'custom'
        },
        {
            key: 'address',
            label: 'Address',
            type: 'text',
            filterType: 'sortable'
        },
        {
            key: 'contractType',
            label: 'Contract Type',
            type: 'text',
            filterType: 'custom'
        },
        {
            key: 'client',
            label: 'Client',
            type: 'text',
            filterType: 'sortable'
        },
        {
            key: 'buildingConsultant',
            label: 'Building Consultant',
            type: 'text',
            filterType: 'custom'
        },
        {
            key: 'constructionManager',
            label: 'Construction Manager',
            type: 'text',
            filterType: 'sortable'
        },
        {
            key: 'buildType',
            label: 'Build Type',
            type: 'text',
            filterType: 'sortable'
        },
        {
            key: 'titleStatus',
            label: 'Title Status',
            type: 'text',
            filterType: 'sortable'
        },
        {
            key: 'titleDate',
            label: 'Title Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'consentLodgedDate',
            label: 'Consent Lodged Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'consentDueOutDate',
            label: 'Consent Due Out Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'consentApprovedDate',
            label: 'Consent Approved Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'unconditionalDate',
            label: 'Unconditional Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'workingDrawingsDate',
            label: 'Working Drawings Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'settlementDate',
            label: 'Settlement Forecast',
            type: 'custom',
            filterType: 'sortable'
        },
        {
            key: 'settlementEstimateEndDate',
            label: 'Settlement Estimate End Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'floorDownCurrentEndDate',
            label: 'Floor Down Current End Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'settlementActualEndDate',
            label: 'Settlement Actual End Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'floorDownCurrentStartDate',
            label: 'Floor Down Current Start Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'floorDownActualEndDate',
            label: 'Floor Down Actual End Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'contractWorksExpiryDate',
            label: 'Contract Works Expiry Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'floorDownEstimateEndDate',
            label: 'Floor Down Estimate End Date',
            type: 'date',
            filterType: 'sortable'
        },
        {
            key: 'floorDownDate',
            label: 'Floor Down Forecast',
            type: 'custom',
            filterType: 'sortable'
        },
        {
            key: 'district',
            label: 'District',
            type: 'text',
            filterType: 'custom'
        },
        {
            key: 'area',
            label: 'Area',
            type: 'text',
            filterType: 'custom'
        },
        {
            key: 'contractConditions',
            label: 'Contract Conditions',
            type: 'custom',
            filterType: 'sortable'
        }
    ];

    public static displayedColumnsMap: Record<string, WipColumn> =
        WipConstants.displayedColumns.reduce<Record<string, WipColumn>>((acc, col) => {
            acc[col.key] = col;
            return acc;
        }, {});
}


export class WipHelper {
    private static readonly COLUMN_NAME_MAPPINGS: Record<string, string> = {
        workingDrawingsDate: 'WD Hub Due Date',
        floorDownDate: 'Floor Down Forecast',
        settlementDate: 'Settlement Forecast'
    };

    public static formatColumnName(name: string): string {
        const formattedName = this.COLUMN_NAME_MAPPINGS[name];
        if (formattedName) {
            return formattedName;
        }
        return name
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    }
}
