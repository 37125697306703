import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WipMainPageComponent } from './wip-main-page/wip-main-page.component';
import { WipFavoriteViewsPageComponent } from './wip-favorite-views-page/wip-favorite-views-page.component';
import { WipTableComponent } from '@app/views/wip/wip-table/wip-table.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgForOf, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

export const WIP_HOME_ROUTE = 'wip';
const WIP_ROUTE_DATA = {
    breadcrumb: 'Wip'
};

@NgModule({
    declarations: [
        WipMainPageComponent,
    ],
    exports: [],
    imports: [
        RouterModule.forRoot([
            {
                component: WipMainPageComponent,
                path: WIP_HOME_ROUTE,
                data: WIP_ROUTE_DATA
            }
        ], {}),
        WipTableComponent,
        WipFavoriteViewsPageComponent,
        SidePanelComponent,
        MatIconModule,
        MatButtonModule,
        NgIf,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        NgForOf
    ]
})
export class WipModule { }
