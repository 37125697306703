import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WipFavoriteView, WipService } from '@app/logic/wip/wip.service';
import { HeaderControlService } from '@app/shared/services/header.control.service';
import { IUserDto, UsersLogicService } from '@app/logic/users';
import { SelectListItem } from '@app/core/services/enum/enum.service';
import { BusinessEntitiesLogicService } from '@app/logic/business-entity';
import { IActiveIdAndNameDto, IProjectDetailsDto, LOT_CONTRACT_TYPE_ENUM, USER_TAG_CONSTANTS_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { ClientSalePermissions } from '@app/core/permissions';
import { ProjectsLogicService } from '@app/logic/projects';
import { WipTableComponent } from '@app/views/wip/wip-table/wip-table.component';
import { ILocationDto, LocationLogicService } from '@app/logic/location';

@Component({
    selector: 'cb-wip-main-page',
    templateUrl: './wip-main-page.component.html',
    styleUrls: ['./wip-main-page.component.scss']
})
export class WipMainPageComponent implements OnDestroy, OnInit {
    public collapsed = true;
    public selectedView: WipFavoriteView;
    public isEditMode = false;
    public isAddNewViewMode = false;
    public favoriteViews: WipFavoriteView[] = [];
    public buildingConsultants: IUserDto[];
    public businessEntitiesDict: { [id: number]: string };
    public projects: { [id: string]: number };
    public contractTypes: SelectListItem[];
    public districts: ILocationDto[];

    @ViewChild(WipTableComponent) public childComponent!: WipTableComponent;

    constructor(private wipService: WipService, private headerControl: HeaderControlService,
                public readonly clientSalePermissions: ClientSalePermissions,
                private readonly usersLogicService: UsersLogicService,
                private readonly businessEntityLogic: BusinessEntitiesLogicService,
                private readonly projectsLogic: ProjectsLogicService,
                private readonly locationLogicService: LocationLogicService) {
        const updateWipBtn = document.createElement('button');
        updateWipBtn.innerHTML = 'Update WIP';

        const resetFiltersBtn = document.createElement('button');
        resetFiltersBtn.innerHTML = 'Reset Filters';

        this.headerControl.updateHeaderButtons([
            {
                element: updateWipBtn,
                onClick: this.updateWip.bind(this)
            },
            {
                element: resetFiltersBtn,
                onClick: this.resetFilters.bind(this)
            }
        ]);
    }

    public ngOnInit(): void {
        this.wipService.getFavoriteViewsForUser().subscribe(views => {
            this.favoriteViews = views;
            if (views && views.length > 0) {
                this.onViewSelected(views[0]);
            }
        });

        this.clientSalePermissions.observable.subOnce(() => {
            this.loadBuildingConsultants().subOnce(consultants => {
                this.buildingConsultants = consultants.sort((a, b) => a.label.localeCompare(b.label));
            });
        });

        this.businessEntityLogic.getSkinnyBusinessEntityDetails().subOnce((idAndLabelDtos: IActiveIdAndNameDto[]) => {
            this.businessEntitiesDict = idAndLabelDtos.reduce((dict, entity) => {
                dict[entity.id] = entity.name;
                return dict;
            }, {});
        });

        this.projectsLogic.$getList().subOnce((projects: IProjectDetailsDto[]) => {
            this.projects = projects.reduce((dict, entity) => {
                dict[entity.projectName] = entity.id;
                return dict;
            }, {});
        });

        this.locationLogicService.getUserDistrictLocationsList().subOnce((locations: ILocationDto[]) => {
            this.districts = locations;
        });

        this.contractTypes = LOT_CONTRACT_TYPE_ENUM.toSelectList();
    }

    public loadBuildingConsultants(): Observable<IUserDto[]> {
        if (this.clientSalePermissions.canView()) {
            return this.usersLogicService.getListByTag({ tag: USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT });
        }
    }

    public onViewSelected(view: WipFavoriteView): void {
        this.selectedView = view;
    }

    private updateWip(): void {
        this.wipService.refreshDocuments().subOnce(x=>{
            window.location.reload();
        });
    }
    private resetFilters(): void {
        this.childComponent.resetAllFilters();
    }
    public ngOnDestroy(): void {
        this.headerControl.updateHeaderButtons(null);
    }

    public onModeChanged(isEditMode: boolean): void {
        this.isEditMode = isEditMode;
    }

    public onAddNewClicked(): void {
        this.isAddNewViewMode = true;
        this.selectedView = null;
    }

    public onViewUpdated(updatedView: WipFavoriteView): void {
        const index = this.favoriteViews.findIndex(v => v.id === updatedView.id);
        if (index !== -1) {
            this.favoriteViews[index] = updatedView;
        }
        this.selectedView = updatedView;
    }

    public onViewCreated(newView: WipFavoriteView): void {
        console.log('Created View:', newView);
        this.favoriteViews.push(newView);
        this.selectedView = newView;
    }

    public onSaveClicked(isDefaultView: boolean): void {
        this.isEditMode = false;
        if (isDefaultView === true) {
            this.wipService.getFavoriteViewsForUser().subscribe(views => {
                this.favoriteViews = views;
            });
        }
    }

    public onDeleteView(view: WipFavoriteView): void {
        if (confirm(`Are you sure you want to delete the view "${view.name}"?`)) {
            this.wipService.deleteFavoriteView(view.id).subscribe({
                next: () => {
                    this.favoriteViews = this.favoriteViews.filter(v => v.id !== view.id);
                    if (this.favoriteViews.length > 0) {
                        this.onViewSelected(this.favoriteViews[0]);
                    }
                    this.isEditMode = false;
                    console.log(`Favorite view "${view.name}" deleted successfully.`);
                },
                error: (err) => {
                    console.error('Error deleting favorite view:', err);
                }
            });
        }
    }

    public backToViewClicked(isCreatingNewView: boolean): void {
        if (isCreatingNewView) {
            if (this.favoriteViews.length > 0) {
                this.onViewSelected(this.favoriteViews[0]);
            }
        }
        this.isEditMode = false;
    }
}
