<div class="cb-margin-top-3x flex-row flex">
    <div class="cb-margin-right-3x flex-col flex-50">
        <cb-select label="Build Type"
                   [(ngModel)]="mappedItem.buildTypeId"
                   name="buildTypeId"
                   [options]="buildTypes"
                   [required]="true">
        </cb-select>
        <cb-user-autocomplete-scroller [tag]="approverTags"
                                       class="flex-initial"
                                       label="Approver"
                                       name="approverUserId"
                                       [(ngModel)]="selectedApprover"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [required]="true"
                                       [active]="true"
                                       (change)="approverChanged()">
        </cb-user-autocomplete-scroller>
    </div>
    <div class="flex-col flex-50">
        <cb-input name="floorArea"
                  label="Floor Area"
                  type="number"
                  [(ngModel)]="mappedItem.amenities.floorArea"
                  [required]="true"
                  svgIcon="floor-plan"
                  suffix="m&#178;">
        </cb-input>
        <cb-input name="landArea"
                  label="Land Area"
                  type="number"
                  [(ngModel)]="mappedItem.amenities.landArea"
                  [required]="true"
                  svgIcon="floor-plan"
                  suffix="m&#178;">
        </cb-input>
        <cb-input name="noBedrooms"
                  label="Bedroom"
                  type="number"
                  [(ngModel)]="mappedItem.amenities.noBedrooms"
                  [required]="true"
                  [pattern]="positiveIntegerPattern"
                  svgIcon="bed">
        </cb-input>
        <cb-input name="noBathrooms"
                  label="Bathroom"
                  type="number"
                  [(ngModel)]="mappedItem.amenities.noBathrooms"
                  [required]="true"
                  [pattern]="positiveIntegerPattern"
                  icon="wc">
        </cb-input>
        <cb-input name="noLivingRooms"
                  label="Living"
                  type="number"
                  [(ngModel)]="mappedItem.amenities.noLivingRooms"
                  [required]="true"
                  [pattern]="positiveIntegerPattern"
                  icon="weekend">
        </cb-input>
        <cb-garage-select class="flex"
                          name="garages"
                          label="Garages"
                          [(ngModel)]="mappedItem.amenities.noGarages"
                          [required]="true">
        </cb-garage-select>
        <cb-input name="noStudy"
                  label="Study"
                  type="number"
                  [(ngModel)]="mappedItem.amenities.noStudy"
                  [pattern]="positiveIntegerPattern"
                  [required]="true"
                  icon="import_contacts">
        </cb-input>
    </div>
</div>
