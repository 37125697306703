<div class="flex-col">
    <cb-dialog dialogHeading="Document Upload">
        <div class="document-container flex-col">
            <form class="flex-col flex"
                  #documentUploadForm="ngForm">

                <!-- Show Only On Document Upload - NOT REUPLOAD -->
                <div *ngIf="!data.isDocumentReupload">
                    <mat-form-field>
                        <mat-label>Document Group</mat-label>
                        <mat-select [(value)]="selectedGroup"
                                    (selectionChange)="onGroupSelection($event.value)">
                            <mat-option *ngFor="let group of availableForUploadDocumentGroups"
                                        [value]="group">
                                {{ group.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="showTypeSelector">
                        <mat-label>Document Type</mat-label>
                        <mat-select [(value)]="selectedType"
                                    (selectionChange)="onTypeSelection($event.value)">
                            <mat-option *ngFor="let type of availableForUploadDocumentTypes"
                                        [value]="type">
                                {{ type.documentType.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Show Only On Document Upload - NOT REUPLOAD -->
                <cb-document-upload [hidden]="!showDragDrop"
                                    class="cb-margin-bottom-2x"
                                    [documentEntityMappedItem]="data.documentEntityMappedItem">
                </cb-document-upload>
                <div *ngIf="displayDocumentReviewSection()">
                    <cb-select label="Document Review"
                               [(ngModel)]="tempDocumentReviewDto.status"
                               name="reveiwStatus"
                               [required]="true"
                               [options]="documentReviewStatuses">
                    </cb-select>
                    <cb-input class="cb-padding-top"
                              name="rejectedReason"
                              label="Rejected Reason"
                              [required]="true"
                              *ngIf="tempDocumentReviewDto.status === DOCUMENT_REVIEW_STATUS_ENUM.Rejected"
                              [(ngModel)]="tempDocumentReviewDto.comments">
                    </cb-input>
                </div>
            </form>
        </div>
            <div actions>
                <button mat-raised-button
                        color="primary"
                        class="save-button"
                        [disabled]="documentUploadForm.invalid"
                        (click)="onSubmit()">Upload
                </button>
                <button
                    mat-raised-button
                    (click)="cancel()">Close
                </button>
            </div>

    </cb-dialog>
</div>
