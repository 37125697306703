import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { CategoryLogicService } from '@app/logic/product-categories';
import {
    IProductCategoryDto,
    IProductCategoryTreeDto
} from '@app/logic/product-categories/interfaces/i.product-category.dto';
import { IProductCategoryMappedItem } from '@app/logic/product-categories/interfaces/i.product-category.mapped';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { CategoryAddedSubject } from '@app/views/products/tabs/product-categories/product-categories-tab.component';
import { flatten, last } from 'lodash';
import { Observable, Subject, forkJoin, mergeMap, takeUntil } from 'rxjs';

import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';

@Component({
    selector: 'cb-category-filter',
    templateUrl: './category-filter.component.html',
    styleUrls: ['./category-filter.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(CategoryFilterComponent),
        CategoryLogicService
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class CategoryFilterComponent extends BaseFormComponentDirective implements OnInit, OnDestroy {
    @Input() public cachedSelectedCategories: any;
    @Input() public includeDeleted = false;
    @Input() public categoryEdited$?: Observable<IProductCategoryDto>;
    @Input() public categoryAdded$?: Observable<CategoryAddedSubject>;
    @Input() public restrictedCategories: number[];
    @Input() public isFetching: boolean;
    @HostBinding('class') public class = 'flex-row flex';

    private readonly destroy$ = new Subject();
    @ViewChild(MatTable, {}) public table: MatTable<any>;
    public selectedCategories: number[] = [];
    public currentlySelectedCategory: IProductCategoryMappedItem;
    public searchParamsSessionKey: string;

    public categoryTree: IProductCategoryTreeDto[] = [{
        categories: [],
        id: undefined,
        label: 'Main Category',
        selectedCategory: undefined
    }];

    public displayedColumns = [
        'categoryTreeItem',
        'categoryTreeItemSelection',
        'actions',
    ];

    public savedCategoriesIds: number[];

    public ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }

    constructor(public readonly productCategoryLogicService: CategoryLogicService) {
        super();
    }

    public ngOnInit(): void {
        this.categoryEdited$?.pipe(takeUntil(this.destroy$)).subscribe(this.handleCategoryEdited);
        this.categoryAdded$?.pipe(takeUntil(this.destroy$)).subscribe(this.handleCategoryAdded);
        this.fetchAndApplyCategoriesToCategoryTree(undefined)
            .subOnce({ next: this.applyCachedTreeInExists });
    }

    public getSelectedCategoryLabel = (
        categoryTreeItemSelectionParam: number,
        categoryTreeItemCategories: Array<IProductCategoryDto>): string => {
        const selectedCategory = categoryTreeItemCategories
            .find(x => x.id === categoryTreeItemSelectionParam);
        return selectedCategory?.name;
    };

    public onCategorySelected = (
        selectedCategoryParam: number,
        categoryTreeItemCategories: Array<IProductCategoryDto>
    ) => {
        const newItem$ = this.productCategoryLogicService.$getItem(selectedCategoryParam);
        const newItemWithCategoryTree$ = newItem$.pipe(
            mergeMap(newItem => {
                return this.fetchAndApplyCategoriesToCategoryTree(newItem.id);
            }));
        newItemWithCategoryTree$.subOnce();
        newItem$.subOnce({
            next: newItem => {
                this.setSelectedCategory(newItem);
                this.fireOnChange(newItem);
            }
        });
    };

    public removeLastCategory = (): void => {
        this.isFetching = true;
        this.categoryTree.pop();
        const lastCategory = last(this.categoryTree);
        lastCategory.selectedCategory = undefined;
        this.table.renderRows();
        if (lastCategory.id === undefined) {
            this.fireOnChange(lastCategory.selectedCategory);
            return;
        }
        this.productCategoryLogicService.$getItem(lastCategory.id).subOnce({
            next: (productCategoryDto) => this.fireOnChange(productCategoryDto)
        });
    };

    public isLastCategoryRestricted(): boolean {

        let isLastCategoryRestricted = false;

        if (this.categoryTree?.length > 0 && this.restrictedCategories?.length > 0) {
            // Get Last Item from categoryTree
            const lastItem = this.categoryTree[this.categoryTree.length - 1]?.id;

            // check if last exist in Restricted list
            isLastCategoryRestricted = this.restrictedCategories.some(x => x === lastItem);

        }

        return isLastCategoryRestricted;
    }

    private readonly applyCachedTreeInExists = () => {
        const cached: number[] = this.cachedSelectedCategories;
        if (!!cached) {
            forkJoin(cached.map((id) => this.getCategoriesForParent(id))).subOnce({
                next: (results: IProductCategoryMappedItem[][]) => {
                    cached.forEach((id, indx) => {
                        if (id) {
                            const selectedCategory = last(this.categoryTree).categories.filter(item => item.id === id)[0];

                            this.setSelectedCategory(selectedCategory as any);
                            this.fireOnChange(selectedCategory as any);
                            if (last(this.categoryTree).id === id) {
                                this.applyCategoriesToCurrentCategoryTree(results[indx]);
                            }
                        }
                    });
                    this.productCategoryLogicService.$getItem(last(this.categoryTree).id).subOnce({
                        next: this.fireOnChange
                    });
                }
            });
        }
    };

    private readonly setSelectedCategory = (selectedCategory: IProductCategoryDto) => {

        if (!selectedCategory) {
            return;
        }

        const categoryTreeCategories = last(this.categoryTree);

        categoryTreeCategories.selectedCategory = selectedCategory;

        this.addNewLevelToCategoryTree(selectedCategory);
    };

    private readonly getSelectedCategoriesFromCategoryTree = () => {
        return this.categoryTree.map((treeLevel) => {
            if (treeLevel.selectedCategory) {
                return treeLevel.selectedCategory.id;
            }
        }
        );
    };

    private readonly fireOnChange = (selectedCategory: IProductCategoryDto): void => {
        this.value = {
            selectedCategories: this.getSelectedCategoriesFromCategoryTree(),
            category: selectedCategory
        };
    };

    private readonly fetchAndApplyCategoriesToCategoryTree = (id: number): Observable<any> => {
        return this.getCategoriesForParent(id)
            .pipe(mergeMap((x) => this.applyCategoriesToCurrentCategoryTree(x)));
    };

    private readonly addNewLevelToCategoryTree = (category: IProductCategoryDto, categories: IProductCategoryMappedItem[] = []) => {
        const categoryTreeLevel = {
            categories,
            id: category.id,
            label: category.name,
            selectedCategory: undefined
        };

        this.categoryTree.push(categoryTreeLevel);
        this.table.renderRows();
        return categoryTreeLevel;
    };

    private readonly getCategoriesForParent = (id: number): Observable<any> => {
        return this.productCategoryLogicService.getCategoriesByParentId(this.includeDeleted, id);
    };

    private readonly applyCategoriesToCurrentCategoryTree = (categories: IProductCategoryMappedItem[]) => {

        if (categories.length > 0) {
            // add categories to category tree categories so that we can easily loop over them without getting from server.
            last(this.categoryTree).categories = categories;
        }

        return categories;
    };

    private readonly handleCategoryAdded = (data: { category: IProductCategoryMappedItem; parentCategory: IProductCategoryMappedItem }) => {
        const categoryTree = last(this.categoryTree);
        if (categoryTree) {
            categoryTree.categories.push(data.category);
        }
    };

    private readonly handleCategoryEdited = (category: IProductCategoryMappedItem) => {
        const categories = flatten(this.categoryTree.map(tree => tree.categories));
        categories.filter(item => item.id === category.id).forEach(currentCategory => {
            currentCategory.name = category.name;
            currentCategory.label = category.label;
            currentCategory.parentId = category.parentId;
            currentCategory.attributes = category.attributes;
            currentCategory.isDeleted = category.isDeleted;
            currentCategory.isActive = category.isActive;
        });
    };
}
