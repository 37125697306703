import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface IData {
    dialogHeading: string;
    message: string;
    action: string;
}

@Component({
    selector: 'cb-business-account-approval-reason-dialog',
    templateUrl: './business-account-approval-reason-dialog.component.html',
    styleUrls: ['./business-account-approval-reason-dialog.component.scss']
})
export class BusinessAccountApprovalReasonComponent {
    public static readonly MIN_WIDTH = '60%';

    public reason = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly dialogRef: MatDialogRef<BusinessAccountApprovalReasonComponent>,
    ) {
    }

    public canSave(): boolean {
        return !!this.reason;
    }

    public onSaveClicked(): void {
        if (this.canSave()) {
            this.dialogRef.close(this.reason);
        }
    }
}
