<cb-dialog dialogHeading="Rate Composition">
    <div class="flex-row flex">
        <cb-table [loaded]="true"
                  [stringColumns]="rateCompositionColumns"
                  tableLabel="Rate Composition for {{rate.locations[0].locationLabel}}"
                  class="table-size">
            <tbody cbTableBody>
            <tr *ngFor="let item of rate.locations[0].rates">
                <td>
                    {{ item.label }}
                </td>
                <td>
                    {{ item.rate }}
                </td>
                <td>
                    {{ item.percentage }}
                </td>
                <td cbTableShrinkCol
                    cbTableStickyCol="end"
                    class="layoutGap-4">
                    <button mat-icon-button
                            (click)="viewProduct(item)">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </td>
            </tr>
            <tr>
                <td>Total</td>
                <td>
                    {{ rateTotal.toString() }}
                </td>
                <td>
                    {{ percentTotal + "%" }}
                </td>
                <td></td>
            </tr>
            </tbody>
        </cb-table>
    </div>
    <div actions>
        <button mat-raised-button
                (click)="cancel()">Close
        </button>
    </div>
</cb-dialog>
