<form class="flex-col flex-fill"
      #form="ngForm"
      style="position: relative; max-width: 280px">
    <button mat-mini-fab
            color="accent"
            class="collapse-button"
            (click)="collapsed$.next(!collapsed$.value)"
            [matTooltip]="collapsed$.value ? 'Expand' : 'Collapse'">
        <mat-icon>
            {{ collapsed$.value ? 'chevron_left' : 'chevron_right ' }}
        </mat-icon>
    </button>
    <mat-card class="collapsible-card flex-col">
        <mat-card-content>
            <div class="flex-row"
                 *ngIf="(collapsed$ | async)">
                <h1 class="mat-headline-6 collapsed-title">Project Information</h1>
            </div>
            <div class="flex-col flex"
                 *ngIf="!(collapsed$ | async)">
                <h4 class="mat-headline-6">{{ mappedItem.code }}</h4>
                <h4 class="mat-subtitle-2 cb-margin-bottom-nil">{{ mappedItem.projectName }}</h4>
                <div style="position: relative"
                     class="cb-margin-bottom-2x">
                    <mat-divider></mat-divider>
                </div>

                <div class="flex-row flex">

                    <span class="flex"></span>
                    <button [matMenuTriggerFor]="menu"
                            mat-icon-button>
                        <mat-icon>more_horiz</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                                (click)="uploadImage()">
                            <mat-icon>file_upload</mat-icon>
                            &nbsp;
                            Upload Logo
                        </button>
                        <button *ngIf="showDeleteButton"
                                mat-menu-item
                                (click)="deleteImage()">
                            <mat-icon>delete</mat-icon>
                            &nbsp;
                            Delete Logo
                        </button>
                    </mat-menu>

                </div>

                <cb-image-button class="flex"
                                 [baseUrl]="mappedItem.projectLogoUrl"
                                 [height]="200"
                                 [width]="400">
                </cb-image-button>


                <cb-display-value-new label="Lot"
                                      [value]="mappedItem.lot">
                </cb-display-value-new>
                <cb-address-new [(ngModel)]="mappedItem.address"
                                [readonly]="true"
                                name="address"
                                class="cb-margin-bottom-2x">
                </cb-address-new>
                <cb-display-value-new label="Account Owner"
                                      [value]="mappedItem.accountOwner.label">
                </cb-display-value-new>
                <button mat-raised-button
                        class="cb-margin-bottom-2x"
                        color="primary"
                        (cbClick)="editProject()"
                        [disabled]="!mappedItem.canEdit()"
                        *ngIf="projectPermissions.canEdit()">
                    Edit Project
                </button>
                <button mat-raised-button
                        color="primary"
                        class="cb-margin-bottom-2x"
                        (cbClick)="completeProject()"
                        [disabled]="!mappedItem.canComplete()"
                        *ngIf="projectPermissions.canComplete()">
                    Complete Project
                </button>
                <button mat-raised-button
                        color="primary"
                        class="cb-margin-bottom-2x"
                        (cbClick)="cancelProject()"
                        [disabled]="!mappedItem.canCancel()"
                        *ngIf="projectPermissions.canCancel()">
                    Cancel Project
                </button>
                <cb-user-date-time-new *ngIf="mappedItem.createdDate && mappedItem.createdByName"
                                       label="Created"
                                       [displayTime]="true"
                                       [date]="mappedItem.createdDate"
                                       [userFullName]="mappedItem.createdByName"
                                       [floatingLabel]="true">
                </cb-user-date-time-new>
                <cb-user-date-time-new *ngIf="mappedItem.updatedDate && mappedItem.updatedByName"
                                       label="Updated"
                                       [displayTime]="true"
                                       [date]="mappedItem.updatedDate"
                                       [userFullName]="mappedItem.updatedByName"
                                       [floatingLabel]="true">
                </cb-user-date-time-new>
            </div>
        </mat-card-content>
    </mat-card>
</form>
