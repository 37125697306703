<cb-dialog>
    <form class="flex-row flex"
          #form="ngForm">
        <div class="cb-padding-right flex-col flex">
            <cb-autocomplete-scroller class="flex-initial"
                                      label="Document Type"
                                      name="documentTypeId"
                                      [(ngModel)]="data.documentType.documentType"
                                      [requireMatch]="true"
                                      [matchProps]="['id']"
                                      [querySearch]="querySearch"></cb-autocomplete-scroller>
            <div class="flex-row">
                <cb-checkbox label="Code Only"
                             name="codeOnly"
                             class="cb-padding-top"
                             [(ngModel)]="data.documentType.codeOnly">
                </cb-checkbox>
                <cb-info-popup [icon]="'info'"
                               [tooltip]="'If selected this will disable upload from all system areas. This is used to allow document stubs to be created by the back end code only.'">
                </cb-info-popup>
            </div>
            <cb-checkbox label="Allow Multiples"
                         name="allowMultiples"
                         [(ngModel)]="data.documentType.allowMultiples">
            </cb-checkbox>
            <cb-checkbox label="Can Set Renewal Date"
                         name="canSetRenewalDate"
                         [(ngModel)]="data.documentType.canSetRenewalDate">
            </cb-checkbox>
            <cb-input label="Default Months Until Renewal"
                      name="defaultMonthsUntilRenewal"
                      type="number"
                      [(ngModel)]="data.documentType.defaultMonthsUntilRenewal">
            </cb-input>
        </div>
        <div class="cb-padding-left flex-col flex">
            <cb-select label="Required Type"
                       name="requiredType'"
                       [options]="requiredTypes"
                       [(ngModel)]="data.documentType.requiredType"></cb-select>
            <cb-select label="Approval Type"
                       name="approvalType'"
                       [options]="approvalTypes"
                       [(ngModel)]="data.documentType.approvalType"></cb-select>
            <cb-table [loaded]="true">
                <thead cbTableHead>
                    <tr>
                        <td>
                            Approval Tags
                        </td>
                        <td style="width: 60px;"></td>
                    </tr>
                </thead>
                <tbody cbTableBody>
                    <tr *ngIf="!!availableTags?.length">
                        <td>
                            <form>
                                <cb-select label="Select Approval By"
                                           name="selectedTagId'"
                                           [options]="availableTags"
                                           [(ngModel)]="selectedTagId">
                                </cb-select>
                            </form>
                        </td>
                        <td>
                            <button mat-icon-button
                                    [disabled]="!selectedTagId"
                                    (click)="onAddTagCliced()">
                                <mat-icon>
                                    add
                                </mat-icon>
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let tag of data.documentType.approvalTags">
                        <td>
                            {{getTagDisplayLabel(tag)}}
                        </td>
                        <td>
                            <button mat-icon-button
                                    (click)="onRemoveTagClick(tag)">
                                <mat-icon>
                                    remove_circle
                                </mat-icon>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="!data.documentType.approvalTags?.length">
                        <td colspan="2">
                            <mat-icon color="accent">
                                info
                            </mat-icon>
                            <span>
                                &nbsp;There are no Approval Tag for this Document Type
                            </span>
                        </td>
                    </tr>
                </tbody>
            </cb-table>
            <cb-checkbox label="Is Active"
                         name="isActive"
                         class="cb-margin-top"
                         [(ngModel)]="data.documentType.isActive">
            </cb-checkbox>
        </div>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="onSaveClicked()"
                [disabled]="!form.valid || !canSave()">
            Save
        </button>
        <button mat-raised-button
                (click)="dialogRef.close()">
            Cancel
        </button>
    </div>
</cb-dialog>
