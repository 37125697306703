<div class="cb-margin">
    <mat-card class="cb-margin-bottom flex-col">
        <mat-card-content>
            <form #form="ngForm">
                <div class="start-center flex-row flex">
                    <mat-icon>search</mat-icon>
                    <cb-input type="text"
                              label="Search"
                              [(ngModel)]="searchFilters.data.query"
                              (ngModelChange)="searchFiltersChanged$.next($event)"
                              name="search"
                              class="cb-margin-left cb-margin-right-2x flex">
                    </cb-input>
                    <cb-select label="Assigned To"
                               name="userId"
                               *ngIf="qsTeamPermissions.canSeeAll()"
                               [(ngModel)]="searchFilters.data.userId"
                               [options]="teamUsersOptions$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select-list label="Status"
                                    name="statusId"
                                    class="cb-margin-right-2x flex"
                                    [allOptions]="takeoffStatuses$ | async"
                                    [multiple]="true"
                                    [(value)]="searchFilters.data.statusId">
                    </cb-select-list>
                    <cb-select label="Order"
                               name="resultOrders"
                               [(ngModel)]="searchFilters.data.order"
                               [options]="resultOrders$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select class="cb-margin-right"
                               label="Direction"
                               [(ngModel)]="searchFilters.data.direction"
                               name="direction"
                               [options]="resultDirections$ | async">
                    </cb-select>
                </div>
            </form>
            <cb-table *ngIf="searchFiltersLoaded && searchEnabled"
                      class="cb-margin-top flex"
                      label="Takeoffs"
                      maxHeightOffset="280"
                      [infiniteScrollEnabled]="infiniteScrollEnabled"
                      [fetch]="fetchResults()"
                      [loaded]="loaded"
                      [queryUpdated]="searchFiltersChanged$"
                      [(results)]="results"
                      [(currentPage)]="currentPage">
                <thead cbTableHead>
                <tr>
                    <td>Job #</td>
                    <td>Takeoff #</td>
                    <td>Imported By</td>
                    <td>Imported Date</td>
                    <td>Status</td>
                    <td>Assigned To</td>
                    <td></td>
                </tr>
                </thead>
                <tbody cbTableBody>
                <tr *ngFor="let takeoff of results">
                    <cb-td-text [value]="takeoff.jobNumber"></cb-td-text>
                    <cb-td-text [value]="takeoff.id"></cb-td-text>
                    <cb-td-text [value]="takeoff.createdByName"></cb-td-text>
                    <cb-td-date [value]="takeoff.createdDate"></cb-td-date>
                    <cb-td-text [value]="takeoff.statusLabel">
                    </cb-td-text>
                    <cb-td-text [value]="takeoff.assignedToUserName"></cb-td-text>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <!-- takeoff.transferHistoryId does not exist so this is a bug; commented out as part of #39702 -->
                        <!--                        <cb-button (clicked)="getTransferHistoryFile(takeoff.transferHistoryId)"-->
                        <!--                                   color-->
                        <!--                                   *ngIf="canDownloadTakeOff(takeoff) | async"-->
                        <!--                                   icon="file_download">-->
                        <!--                        </cb-button>-->
                        <cb-button (clicked)="viewTakeOff(takeoff)"
                                   color
                                   icon="pageview">
                        </cb-button>
                    </td>
                </tr>
                </tbody>
            </cb-table>
        </mat-card-content>
    </mat-card>
</div>
