<cb-dialog>
    <form class="flex-col flex"
          #buildOverheadCostForm="ngForm">
        <cb-autocomplete-scroller class="flex"
                                  label="Build Overhead Cost"
                                  name="buildOverheadCost"
                                  [(ngModel)]="mappedItem.buildOverheadCost"
                                  [requireMatch]="true"
                                  [required]="true"
                                  [matchProps]="['name']"
                                  [querySearch]="querySearch">
        </cb-autocomplete-scroller>
        <cb-currency label="Amount (GST Inclusive)"
                     name="valueGSTInc"
                     [ngModel]="mappedItem.valueGstIncl"
                     (ngModelChange)="updateGstExclusive($event)"
                     [required]="true">
        </cb-currency>
        <cb-currency label="Amount (GST Exclusive)"
                     name="valueGSTExc"
                     [ngModel]="mappedItem.value"
                     (ngModelChange)="updateGstInclusive($event)"
                     [required]="true">
        </cb-currency>
        <cb-input type="text"
                  name="notes"
                  label="Notes"
                  [(ngModel)]="mappedItem.notes">
        </cb-input>
    </form>
    <div actions>
        <button *ngIf="formMode == FormMode.Add"
                mat-raised-button
                color="primary"
                (click)="saveAndNext(buildOverheadCostForm)"
                [disabled]="!buildOverheadCostForm.dirty || !buildOverheadCostForm.valid || saveInProgress">
            Save & Next
        </button>
        <button mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="!buildOverheadCostForm.dirty || !buildOverheadCostForm.valid || saveInProgress">
            {{ formMode == FormMode.Add ? 'Save & Close' : 'Save' }}
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
