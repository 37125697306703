import { Component, EventEmitter, Inject, NgZone, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { CategoryLogicService } from '@app/logic/product-categories';
import { IProductCategoryDto } from '@app/logic/product-categories/interfaces/i.product-category.dto';
import { slotDto } from '@app/logic/slots/dtos/slot.dto';
import { ISlotMappedItem } from '@app/logic/slots/interfaces/i.slot.mapped';
import { ISlotsLogicService } from '@app/logic/slots/interfaces/i.slots.logic-service';
import { SlotsLogicService } from '@app/logic/slots/slots.logic-service';
import { SpecGroupsLogicService } from '@app/logic/spec-groups';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { IManageDialogData } from '@app/shared/interfaces/i.manage-dialog-data';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';
import { COST_TYPE_ENUM, EXTERNAL_SYSTEM_ENUM, IExternalReferenceDto, IParentSlotSaveableDto, ISlotDto, ITagDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IPromised, toPromisedArray } from 'cb-hub-lib';
import { orderBy } from 'lodash';
import { map, Subject } from 'rxjs';

@Component({
    selector: 'cb-manage-slot-dialog',
    templateUrl: './manage-slot-dialog.component.html',
    styleUrls: ['./manage-slot-dialog.component.scss']
})
export class ManageSlotDialogComponent extends BaseDialogFormViewDirective<ISlotDto, ISlotMappedItem, ISlotsLogicService>
    implements OnInit {
    public static readonly MIN_WIDTH = '90%';
    public newExternalReferenceCollectionItem: IExternalReferenceDto;
    public removedChildren: ISlotDto[] = [];
    public WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;
    public newChildSlotCollectionItem: ISlotDto;
    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public houseAreas = toPromisedArray(
        this.specGroupLogic.$getList().pipe(
            map(results => orderBy(results, 'sortOrder', 'asc'))));
    public costTypes = COST_TYPE_ENUM
        .toSelectList()
        .filter(ct => ct.id !== COST_TYPE_ENUM.None &&
            ct.id !== COST_TYPE_ENUM.OwnersCare &&
            ct.id !== COST_TYPE_ENUM.Quote);
    public categories: IProductCategoryDto[];
    public selectedProductCategoryModel: IProductCategoryDto | any;
    public externalSystemList = EXTERNAL_SYSTEM_ENUM.toLookup();
    public EXTERNAL_SYSTEM_ENUM = EXTERNAL_SYSTEM_ENUM;
    public fullChildSlots: ISlotDto[];
    public slotTags: ITagDto[];
    public externalReferenceColumns = [
        'External Id',
        'External System',
        ''
    ];
    public childSlotColumns = [
        'Name',
        'Cost Type',
        'Tag(s)',
        'Active',
        'Req Child',
        ''
    ];
    public originalChildSlots: ISlotDto[] = [];
    public readonly slotsUpdated = new EventEmitter<ISlotDto[]>();
    public removedChildSlots = new Subject<ISlotDto[]>();
    public addedChildSlots = new Subject<ISlotDto[]>();

    /** BUG #16539 - Workaround used in ngIf on the "External System add line select", used to show and hide the select component in rapid succession to reset the component */
    public showExternalSystemSelect = true;

    constructor(
        public dialogRef: MatDialogRef<ManageSlotDialogComponent>,
        public toastService: ToastService,
        public readonly dialogService: CbDialogService,
        public readonly slotsLogicService: SlotsLogicService,
        public readonly productCategoryLogicService: CategoryLogicService,
        @Inject(SpecGroupsLogicService) public readonly specGroupLogic: SpecGroupsLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<ISlotMappedItem> & { slotTags: ITagDto[] },
        public readonly ngZone: NgZone,
    ) {
        super(dialogRef, toastService);
        this.newExternalReferenceCollectionItem = { externalReferenceId: undefined, externalSystem: undefined, id: 0 };
        this.newChildSlotCollectionItem = null;
        this.mappedItem = data.mappedItem;
        this.slotTags = data.slotTags.filter(tag => tag.isActive || this.mappedItem?.tags?.some(mappedTag => mappedTag.id === tag.id));

        if (this.mappedItem.categories?.length > 0 && this.mappedItem.categories[0].id > 0) {
            this.selectedProductCategoryModel = {
                ...this.mappedItem.categories[0],
                treeDisplay: (this.mappedItem.categories[0] as any).categoryPath,
            };
        }

        if (this.mappedItem.id > 0) {
            this.formMode = FormMode.Edit;
        } else {
            this.formMode = FormMode.Add;
        }

    }

    public ngOnInit(): void {
        this.defaultCostType();
        if (this.isEdit()) {
            this.slotsLogicService.getChildSlots(this.data.mappedItem.id).subOnce(childSlots => {
                this.fullChildSlots = orderBy(childSlots, (x) => x.reportOrder);
                this.originalChildSlots = cloneDeepSafe(this.fullChildSlots);
            });
        }
    }

    public addToParent(): void {
        this.dialogRef.close(this.mappedItem);
    }

    public get parentDisplayText(): string {
        if (this.mappedItem?.$parentSlot?.name) {
            return `${this.mappedItem?.$parentSlot?.name} (${this.mappedItem?.$parentSlot?.houseArea?.label})`;
        }

        if (this.mappedItem?.parent?.name) {
            return `${this.mappedItem?.parent?.name} (${this.mappedItem?.parent?.houseArea?.label})`;
        }
    }

    public createChild(form: UntypedFormGroup): any {
        const mappedItem = this.slotsLogicService.$createMappedItem({
            houseArea: this.mappedItem.houseArea,
            parentSlotId: this.mappedItem.id
        });

        mappedItem.$parentSlot = this.mappedItem;

        return this.dialogService
            .open(
                ManageSlotDialogComponent,
                {
                    data: {
                        slotTags: this.slotTags,
                        dialogHeading: 'Add Child Schedule Item',
                        mappedItem,
                    },
                }
            )
            .afterClosed()
            .subOnce((result: ISlotMappedItem) => {
                if (result) {
                    result.categories = result?.categories?.map(
                        (cat: IProductCategoryDto | any) => ({ ...cat, categoryPath: cat.treeDisplay })
                    );
                    const parentDto = this.mappedItem.$mapToDtoItem(slotDto);
                    const resultDto = result.$mapToDtoItem(slotDto);
                    resultDto.parent = parentDto;
                    this.fullChildSlots = [...(this.fullChildSlots ?? []), resultDto];
                    // fix #17353 - set form as dirty in timeout AFTER reassigning this.fullChildSlots (because this triggers set form as pristine)
                    setTimeout(() => {
                        form.markAsDirty();
                    });
                }
            });
    }

    public get hasParent(): boolean {
        return !!this.mappedItem.$parentSlot || !!this.mappedItem?.parent || !!this.mappedItem?.parentSlotId;
    }

    public get houseAreaDisabled(): boolean {
        const disabled = this.mappedItem?.childSlots?.length > 0 || !!this.hasParent || !!this.fullChildSlots?.length;
        return disabled;
    }

    public defaultCostType(): void {
        if (!this.mappedItem?.costType) {
            this.mappedItem.costType = COST_TYPE_ENUM.Actual;
            this.mappedItem.isRequired = true;
        }
    }


    public onCostTypeChange(): void {
        if (this.mappedItem.costType === COST_TYPE_ENUM.Actual) {
            this.mappedItem.isRequired = true;
        }
    }

    public productCategorySelected(value: IProductCategoryDto | void | any, form: any): void {
        if (value) {
            this.mappedItem.categories = [value];
        } else {
            this.mappedItem.categories = [];
        }
    }

    public externalReferenceCollectionItemAdded(collection: IExternalReferenceDto[]): void {
        this.mappedItem.externalReferences = collection;
        this.showExternalSystemSelect = false;
        this.ngZone.run(() => {
            setTimeout(() => {
                this.newExternalReferenceCollectionItem = {} as IExternalReferenceDto;
                this.showExternalSystemSelect = true;
            });
        });
    }

    public externalReferenceCollectionItemRemoved(emittedValue: { collection: IExternalReferenceDto[]; removedItem: IExternalReferenceDto }, form: UntypedFormGroup): void {
        form.markAsDirty();
        this.mappedItem.externalReferences = emittedValue.collection;
    }

    public childSlotCollectionItemAdded(collection: IPromised<ISlotDto[]>, form: UntypedFormGroup): void {
        this.newChildSlotCollectionItem = null;
        this.childSlotCollectionItemChanged(collection, form);
    }

    public childSlotAddButtonDisabled(): boolean {
        return !this.newChildSlotCollectionItem?.name
            || this.newChildSlotCollectionItem?.costType == null
            || this.fullChildSlots?.some(x => x.id === this.newChildSlotCollectionItem.id);
    }

    public externalReferencesAddButtonDisabled(): boolean {
        return !this.newExternalReferenceCollectionItem?.externalReferenceId
            || this.newExternalReferenceCollectionItem?.externalSystem == null
            || this.mappedItem.externalReferences?.some(x => x.externalReferenceId === this.newExternalReferenceCollectionItem.externalReferenceId);
    }

    public childSlotCollectionItemChanged(collection: IPromised<ISlotDto[]>, form: UntypedFormGroup): void {
        form.markAsDirty();
        this.fullChildSlots = collection;
        this.mappedItem.childSlots = this.fullChildSlots.map(slot => slot.id);
        this.removedChildSlots.next(cloneDeepSafe(this.originalChildSlots.filter(x => !this.mappedItem.childSlots.includes(x.id))));
        this.addedChildSlots.next(cloneDeepSafe(this.fullChildSlots.filter(x => !this.originalChildSlots.map(x => x.id).includes(x.id))));
    }

    public childSlotCollectionItemRemoved(emittedValue: IPromised<{ collection: ISlotDto[]; removedItem: ISlotDto }>, form: UntypedFormGroup): void {
        form.markAsDirty();
        this.removedChildren = [...this.removedChildren, emittedValue.removedItem];
        this.fullChildSlots = emittedValue.collection;
        this.mappedItem.childSlots = this.fullChildSlots.map(slot => slot.id);
        this.removedChildSlots.next(cloneDeepSafe(this.originalChildSlots.filter(x => !this.mappedItem.childSlots.includes(x.id))));
        this.addedChildSlots.next(cloneDeepSafe(this.fullChildSlots.filter(x => !this.originalChildSlots.map(x => x.id).includes(x.id))));
    }

    public slotSelected(value: ISlotDto): void {
        if (value) {
            this.newChildSlotCollectionItem = value;
        }
    }

    public save(): void {
        if (this.fullChildSlots?.length > 0) {
            this.saveParentWithChildren();
        } else {
            this.saveSlotWithoutChildren();
        }
    }

    private saveParentWithChildren(): void {
        const parentSlotSaveableDto = {
            ...this.mappedItem.$getMappedDtoItem(),
            children: this.fullChildSlots
        } as IParentSlotSaveableDto;
        this.slotsLogicService.saveParentSlot(parentSlotSaveableDto).subOnce(slotsArray => {
            this.handleNext(this.handleSaveSuccess(slotsArray));
            this.saveInProgress = false;
        });
    }

    private saveSlotWithoutChildren(): void {
        this.mappedItem.$save().subOnce(slotsArray => {
            this.handleNext(this.handleSaveSuccess(slotsArray));
            this.saveInProgress = false;
        });
    }

    /*
        Dragula content.
    */
    public reportOrders: { [slotId: number]: number } = {};

    public sortOrderChange(reportOrders: { [slotId: number]: number } = {}, form: UntypedFormGroup): void {
        form.markAsDirty();
        Object.assign(this.reportOrders, reportOrders);
    }
}
