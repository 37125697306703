<mat-tab-group class="flex"
               (selectedTabChange)="onTabChanged($event);"
               mat-stretch-tabs="false"
               [selectedIndex]="selectedIndex">
    <mat-tab label="Details">
        <cb-scheme-details [mappedItem]="mappedItem"
                           [displayName]="false"></cb-scheme-details>
    </mat-tab>
    <mat-tab label="Items & Changes ({{mappedItem.designRequirementItems?.length }})">
        <cb-design-scheme-items *cbWaitFor="mappedItem"
                                [mappedItem]="mappedItem"
                                [showHeaderAndAddButton]="false"
                                [showDeleteButton]="false"
                                [addAndSave]="true"></cb-design-scheme-items>
    </mat-tab>
    <mat-tab *ngIf="!lotMappedItem?.hasScheduleSpecTemplate"
             label="Specification">
        <ng-template matTabContent>
            <cb-design-scheme-lot-specification [lot]="lotMappedItem"
                                                [mappedItem]="mappedItem"></cb-design-scheme-lot-specification>
        </ng-template>
    </mat-tab>
    <mat-tab *ngIf="lotMappedItem?.hasScheduleSpecTemplate"
             label="Specification ">
        <ng-template matTabContent>
            <cb-lot-spec-view *cbWaitFor="lotMappedItem && mappedItem"
                              [lotMappedItem]="lotMappedItem"
                              [isSkinnyView]="true"
                              [lotSpecVersion]="mappedItem.lotSpecVersion"></cb-lot-spec-view>
        </ng-template>
    </mat-tab>
    <mat-tab label="Files ({{documentCount}})">
        <cb-document *cbWaitFor="mappedItem"
                     [noCard]="true"
                     [entity]="mappedItem"
                     [permissions]="designSchemePermissions?.getDocumentPermissions()"
                     (documentsChange)="manageSchemeData.documents = $event"
                     (documentCountChange)="documentCount = $event"
                     [hideDeletedToggle]="true"
                     [hideRenewalColumn]="true"
                     [hideUploadButton]="!mappedItem.canAddDocuments"
                     [includeLinked]="true"
                     [enableCheckbox]="true"
                     [edit]="mappedItem.canAddDocuments"
                     [systemArea]="systemArea"></cb-document>
    </mat-tab>
    <mat-tab label="Questions ({{questionCount}})">
        <cb-generic-question-list *cbWaitFor="mappedItem"
                                  [entityId]="mappedItem.id"
                                  [systemArea]="systemArea"
                                  [lotId]="mappedItem.lotId"
                                  [edit]="mappedItem.canAddQuestions"
                                  (questionCountChange)="questionCount = $event"
                                  [childItemLabel]="'Change'"
                                  [childItems]="designSchemeLines"
                                  [childSystemArea]="childQuestionSystemArea">
        </cb-generic-question-list>

        <!-- [questionNumber]="councilRfiViewService?.questionNumber" -->
    </mat-tab>
    <mat-tab label="History">
        <ng-template [matTabContent]>
            <cb-history-list *cbWaitFor="mappedItem"
                             [entityId]="mappedItem.id"
                             [entity]="HISTORY_SEARCH_ENTITY.DesignScheme">
            </cb-history-list>
        </ng-template>
    </mat-tab>
</mat-tab-group>
