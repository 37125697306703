import {AGE_RANGES_ENUM, CONTACT_METHOD_ENUM, CONTACT_STATUS_ENUM, ContactMethodEnumId, SALUTATION_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {Component, Inject, OnInit} from '@angular/core';

import {ContactsLogicService, IContactSearchDto, IContactsLogicService} from '@app/logic/contacts';
import {IContactMappedItem} from '@app/logic/contacts/interfaces/i.contact.mapped';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Debounce} from '@app/shared/decorators/debounce.decorator';
import {MatTooltip} from '@angular/material/tooltip';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {CreateContactSelectExistingDialogComponent} from '../create-contact-select-existing-dialog/create-contact-select-existing-dialog.component';
import {IEnumLookup} from '@classictechsolutions/typescriptenums';
import * as _ from 'lodash';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {ToastService} from '@app/core/services/toast/toast.service';
import {FormMode} from '@app/shared/enums/form';
import {IContactDto} from '@app/logic/contacts/interfaces/i.contact.dto';


@Component({
    selector: 'cb-create-contact-dialog',
    templateUrl: './create-contact-dialog.component.html',
    styleUrls: ['./create-contact-dialog.component.scss']
})
export class CreateContactDialogComponent extends BaseDialogFormViewDirective<IContactDto, IContactMappedItem, IContactsLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '85%';

    public readonly contactStatusLookup = CONTACT_STATUS_ENUM.toLookup();
    public readonly salutationLookup = SALUTATION_ENUM.toLookup();
    public readonly ageRangeLookup = AGE_RANGES_ENUM.toLookup();
    public readonly contactMethodLookup = CONTACT_METHOD_ENUM.toLookup();
    public CONTACT_METHOD_ENUM = CONTACT_METHOD_ENUM;
    public contactMethods: IEnumLookup<ContactMethodEnumId>[] = [];
    public availableContactMethods: IEnumLookup<ContactMethodEnumId>[];
    public isIncompleteInitiallyChecked: boolean;

    public contactsMatching = {
        fullName: [] as IContactSearchDto[],
        phoneHome: [] as IContactSearchDto[],
        phoneMobile: [] as IContactSearchDto[],
        emailAddress: [] as IContactSearchDto[],
    };

    constructor(
        private readonly toastService: ToastService,
        protected readonly contactLogic: ContactsLogicService,
        public readonly dialogRef: MatDialogRef<CreateContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { mappedItem: IContactMappedItem | null; mustComplete: boolean; defaultData: Partial<IContactDto> },
        private readonly cbDialog: CbDialogService,
    ) {
        super(dialogRef, toastService, cbDialog);
        this.mappedItem = data.mappedItem;
        if (!this.mappedItem) {
            this.formMode = FormMode.Add;
            this.mappedItem = this.contactLogic.$createMappedItem(this.data.defaultData);
        } else {
            this.formMode = FormMode.Edit;
        }
        this.isIncompleteInitiallyChecked = this.mappedItem?.incomplete;
    }

    public ngOnInit(): void {
        super.ngOnInit();
        CONTACT_METHOD_ENUM.toLookup({
            transform: (results) => {
                this.availableContactMethods = _.clone(results);
                results.forEach((contactMethod) => {
                    this.addRemoveContactMethod(contactMethod.id);
                });
                return results;
            }
        });
    }

    public getIncompleteDisabled(): boolean {
        return this.isEdit() && !this.isIncompleteInitiallyChecked;
    }

    public addRemoveContactMethod = (contactMethod: ContactMethodEnumId): void => {
        let method: Function;
        if (this.contactMethodHasValue(contactMethod)) {
            method = this.addContactMethod;
        } else {
            method = this.removeContactMethod;
        }
        method(contactMethod);
    };

    private readonly addContactMethod = (contactMethod: ContactMethodEnumId) => {
        const foundContactMethod = _.find(this.availableContactMethods, (currentContactMethod, index) => {
            if (currentContactMethod.id === contactMethod) {
                return true;
            }
            return false;
        });
        if (foundContactMethod) {
            _.remove(this.availableContactMethods, { id: foundContactMethod.id });
            this.contactMethods.push(foundContactMethod);
        }
    };

    private readonly removeContactMethod = (contactMethod: ContactMethodEnumId) => {
        const foundContactMethod = _.find(this.contactMethods, (currentContactMethod, index) => {
            if (currentContactMethod.id === contactMethod) {
                return true;
            }
            return false;
        });
        if (foundContactMethod) {
            _.remove(this.contactMethods, { id: foundContactMethod.id });
            this.availableContactMethods.push(foundContactMethod);
        }
    };

    private readonly contactMethodHasValue = (contactMethod: ContactMethodEnumId) => {
        let value = '';
        switch (contactMethod) {
            case CONTACT_METHOD_ENUM.HomePhone:
                value = this.mappedItem.phoneHome;
                break;
            case CONTACT_METHOD_ENUM.WorkPhone:
                value = this.mappedItem.phoneWork;
                break;
            case CONTACT_METHOD_ENUM.MobilePhone:
                value = this.mappedItem.phoneMobile;
                break;
            case CONTACT_METHOD_ENUM.Email:
                value = this.mappedItem.email;
                break;
            default:
                break;
        }
        return (value) ? value.length > 0 : false;
    };

    public saveContact(): void {
        if (this.contactsMatching?.emailAddress?.length > 0) {
            return;
        } else {
            this.mappedItem
                .$save()
                .subOnce(contact => {
                    this.dialogRef.close(contact);
                });
        }
    }

    @Debounce(1000)
    public fullNameChanged(tooltip: MatTooltip): void {
        this.contactLogic
            .findExistingByFullName(this.mappedItem.firstName, this.mappedItem.lastName)
            .subOnce((results) => {
                this.contactsMatching.fullName = results;
                this.showTooltip(tooltip, results.length, 'name');
            });
    }

    @Debounce(1000)
    public emailChanged(tooltip: MatTooltip): void {
        this.contactLogic
            .findExistingByEmailAddress(this.mappedItem.email)
            .subOnce((results) => {
                this.contactsMatching.emailAddress = results;
                this.showTooltip(tooltip, results.length, 'email address');
            });

        this.addRemoveContactMethod(CONTACT_METHOD_ENUM.Email);
    }

    @Debounce(1000)
    public phoneHomeChanged(tooltip: MatTooltip): void {
        this.contactLogic
            .findExistingByHomePhone(this.mappedItem.phoneHome)
            .subOnce((results) => {
                this.contactsMatching.phoneHome = results;
                this.showTooltip(tooltip, results.length, 'home phone number');
            });

        this.addRemoveContactMethod(CONTACT_METHOD_ENUM.HomePhone);

    }

    @Debounce(1000)
    public phoneMobileChanged(tooltip: MatTooltip): void {
        this.contactLogic
            .findExistingByHomePhone(this.mappedItem.phoneMobile)
            .subOnce((results) => {
                this.contactsMatching.phoneMobile = results;
                this.showTooltip(tooltip, results.length, 'mobile phone number');
            });

        this.addRemoveContactMethod(CONTACT_METHOD_ENUM.MobilePhone);

    }

    public openSelectExistingContact(tooltip: MatTooltip): void {
        tooltip.hide();

        const existingContacts = Object
            .values(this.contactsMatching)
            .reduce((prev, curr) => {
                prev.push(...curr);
                return prev;
            }, [])
            // distinct by id
            .filter((value, index, self) => self.findIndex((found) => found.id === value.id) === index);

        this.cbDialog.open(CreateContactSelectExistingDialogComponent, {
            data: {
                createContactDialogRef: this.dialogRef,
                existingContacts,
            },
            minWidth: '50%',
        });
    }

    private showTooltip(tooltip: MatTooltip, numResults: number, label: string): void {
        if (numResults < 1) { return; }
        tooltip.message = `Found ${numResults} contact${numResults > 1 ? 's' : ''} matching this ${label}. Click to view/select.`;
        tooltip.show();
        setTimeout(() => {
            tooltip.hide();
        }, 10000);
    }
}
