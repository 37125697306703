import {UsersLogicService} from '@app/logic/users';
import {MatDialog} from '@angular/material/dialog';
import {Injectable} from '@angular/core';
import {SearchService} from './base.search.service';
import {Observable} from 'rxjs';
import {takeOne} from 'cb-hub-lib';
import {IUserSearchDto} from '@app/logic/users/interfaces/i.user-search.dto';

@Injectable()
export class UserSearchService extends SearchService {

    constructor(
        public readonly userLogicService: UsersLogicService,
        public readonly dialog: MatDialog
    ) {
        super();
    }

    public getResults(query: string, currentPage: number): Observable<IUserSearchDto[]> {
        return new Observable<IUserSearchDto[]>((subscriber) => {
            if (!this.searchResultsLoaded) {
                this.searchResultsLoaded = true;

                const resultsObservable = this.userLogicService.search({
                    query,
                    currentPage,
                    ...this.extraSearchParams
                }, false);

                if (!resultsObservable) {
                    return;
                }

                resultsObservable
                    .subOnce(x => {
                        this.setSearchResults<IUserSearchDto>(x);
                        subscriber.next(this.searchResults$.value as IUserSearchDto[]);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value as IUserSearchDto[]);
                subscriber.complete();
            }
        });
    }

    public readonly doSearch = (query: string, page: number): Observable<IUserSearchDto[]> => {
        this.searchResultsLoaded = false;
        return this.getResults(query, page).pipe(takeOne());
    };
}
