import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IProductCentreFilterMappedItem } from '@app/logic/product-centre/interfaces/i.product-centre-filter.mapped';
import { IProductCentreFilterLogicService } from '@app/logic/product-centre/interfaces/i.product-centre-filter.logic.service';
import { IProductCentreFilterAttributeDto, IProductCentreFilterDto } from '@classictechsolutions/hubapi-transpiled-enums';

export class ProductCentreFilterMappedItem
    extends BaseMappedItem<IProductCentreFilterDto, IProductCentreFilterMappedItem, IProductCentreFilterLogicService>
    implements IProductCentreFilterMappedItem {

    @DtoProp public readonly id: string | null;
    @DtoProp public name: string;
    @DtoProp public isActive: boolean;
    @DtoProp public attributes: IProductCentreFilterAttributeDto[];

    constructor(
        sourceData: IProductCentreFilterDto,
        logicService: IProductCentreFilterLogicService
    ) {
        super(sourceData, logicService, ProductCentreFilterMappedItem);
    }
}
