import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IIntegrationLogSearchDto} from '@app/logic/integration-logs';

interface IData {
    log: IIntegrationLogSearchDto;
}

@Component({
    templateUrl: './system-log-view-dialog.component.html',
    styleUrls: ['./system-log-view-dialog.component.scss'],
})
export class SystemLogViewDialogComponent {
    public static readonly MIN_WIDTH = '40%';

    public details: string[];

    constructor(
        public readonly dialogRef: MatDialogRef<SystemLogViewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData
    ) { }

    public close(): void {
        this.dialogRef.close();
    }
}
