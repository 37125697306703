import { OnInit, Inject, Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITaskMappedItem, ITaskDto } from '@app/logic/tasks';
import { ITaskOutcomeDto } from '@app/logic/task-outcomes/interfaces/i.task-outcome.dto';
import { Observable } from 'rxjs';
import { taskTypeLabels } from '@app/logic/task-outcomes/task.constants';

interface IData {
    allowFollowUp: boolean;
    task: ITaskMappedItem;
}

@Component({
    selector: 'cb-close-task-dialog',
    templateUrl: './close-task-dialog.component.html',
    styleUrls: ['./close-task-dialog.component.scss']
})
export class CloseTaskDialogComponent implements OnInit {

    public outcomes: Observable<ITaskOutcomeDto[]>;
    public taskTypeLabel: string;
    public scheduleFollowUp = false;
    public followUpTask = {} as ITaskDto;

    constructor(
        public readonly dialogRef: MatDialogRef<CloseTaskDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData
    ) { }

    public ngOnInit(): void {
        this.taskTypeLabel = taskTypeLabels[this.data.task.taskType];
        this.outcomes = this.data.task.getPossibleTaskOutcomes();
    }

    public save(): void {
        this.data.task.closeTask(this.scheduleFollowUp || this.data.task.isCallback, this.followUpTask).subscribe({
            next: result => this.dialogRef.close(result)
        });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

}
