<mat-card class="flex-col flex"
          *cbWaitFor="mappedItem?.id > 0">
    <mat-card-header>
        <mat-card-title class="title">{{ mappedItem.name }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <cb-display-value-new label="Status"
                              [value]="mappedItem.status"></cb-display-value-new>
        <cb-display-value-new label="Assigned To"
                              [value]="mappedItem.assignedToUserName"></cb-display-value-new>
        <cb-display-value-new label="Complexity"
                              [value]="mappedItem?.designComplexity?.complexity"></cb-display-value-new>
        <cb-display-value-new label="Due Date"
                              [value]="mappedItem.dueBy | cbDate"></cb-display-value-new>
        <cb-display-value-new label="Target Hours"
                              [value]="mappedItem.targetWorkHours"></cb-display-value-new>
        <cb-display-value-new label="Elapsed Time"
                              [value]="durationText"></cb-display-value-new>
        <cb-display-value-new *ngIf="mappedItem.reviewerName && mappedItem.reviewRequired"
                              label="Reviewer"
                              [value]="mappedItem.reviewerName"></cb-display-value-new>
    </mat-card-content>
</mat-card>
