import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbInfoMessageModule, CbLoadingSpinnerModule} from 'cb-hub-lib';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {NotificationTemplatesComponent} from './notification-templates.component';
import {NotificationTemplateDialogComponent} from './notification-template-dialog/notification-template-dialog.component';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
    declarations: [
        NotificationTemplatesComponent,
        NotificationTemplateDialogComponent,
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        MatTabsModule,
    ],
    exports: [
        NotificationTemplatesComponent
    ]
})
export class NotificationTemplatesModule { }
