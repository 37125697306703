<cb-council-rfi-action-bar [councilRfi]="councilRfi"
                           [dependantsComplete]="allTasksClosed && allChangeRecordsComplete">
</cb-council-rfi-action-bar>
<div class="cb-margin-top flex-row flex">
    <mat-card class="cb-margin-right flex-col flex-20">
        <mat-card-header>
            <mat-card-title>RFI #{{ councilRfi.id }}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="flex-col">
            <cb-display-value-new label="Assigned Team"
                                  [value]="councilRfi?.assignedToTeamLabel"></cb-display-value-new>
            <cb-display-value-new label="Assigned To"
                                  [value]="councilRfi?.assignedToUserName"></cb-display-value-new>
            <cb-display-value-new label="Status"
                                  [value]="councilRfi?.statusLabel"></cb-display-value-new>
            <cb-user-date-time-new label="Created"
                                   [floatingLabel]="true"
                                   [userFullName]="councilRfi?.createdByUserName"
                                   [date]="councilRfi?.createdDate"></cb-user-date-time-new>
            <cb-user-date-time-new label="Updated"
                                   [floatingLabel]="true"
                                   [userFullName]="councilRfi?.updatedByUserName"
                                   [date]="councilRfi?.updatedDate"></cb-user-date-time-new>
        </mat-card-content>
    </mat-card>
    <mat-card class="main-tabs-card flex-row flex">
        <mat-card-content>
            <mat-tab-group class="card-tab-group"
                           mat-stretch-tabs="false"
                           [(selectedIndex)]="selectedTabIndex">
                <mat-tab label="Details">
                    <ng-template matTabContent>
                        <cb-council-rfi-details [councilRfi]="councilRfi"></cb-council-rfi-details>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Change Records ({{changeRecordCount}})">
                    <cb-council-rfi-change-records [councilRfi]="councilRfi"
                                                   (changeRecordCountChange)="changeRecordCount = $event"
                                                   (allChangeRecordsComplete)="allChangeRecordsComplete = $event">
                    </cb-council-rfi-change-records>
                </mat-tab>
                <mat-tab label="Tasks ({{taskCount}})">
                    <cb-tasks class="flex-col"
                              *cbWaitFor="councilRfi"
                              (taskCountChange)="taskCount = $event"
                              (allTasksClosed)="allTasksClosed = $event"
                              [entity]="councilRfi"
                              [taskTypes]="taskTypes"
                              [edit]="!councilRfi.isComplete"
                              [completableButNotEditable]="councilRfi.isComplete"
                              [permissions]="councilRfiPermissions?.getTaskPermissions()">
                    </cb-tasks>
                </mat-tab>
                <mat-tab label="Questions ({{questionsCount}})">
                    <cb-generic-question-list *cbWaitFor="councilRfi"
                                              [entityId]="councilRfi.id"
                                              [systemArea]="SYSTEM_AREA"
                                              [lotId]="councilRfi.lotId"
                                              [edit]="!councilRfi.isComplete"
                                              (questionCountChange)="questionsCount = $event"
                                              [questionNumber]="councilRfiViewService?.questionNumber">
                    </cb-generic-question-list>
                </mat-tab>
                <mat-tab label="Files ({{documentCount}})">
                    <cb-document *cbWaitFor="councilRfi"
                                 (documentCountChange)="documentCount = $event"
                                 [entity]="councilRfi"
                                 [permissions]="councilRfiPermissions?.getDocumentPermissions()"
                                 [includeLinked]="false"
                                 [systemArea]="SYSTEM_AREA"
                                 [noCard]="true"></cb-document>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>
</div>
