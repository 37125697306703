import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { ILotSpecPinned, ILotSpecSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ILotSpecItemMappedItem } from '@app/logic/lot-spec-item';
import { LotSpecScheduleLogicService } from '@app/logic/lot-spec-schedule';
import { ILotSpecAllItem } from '@app/logic/lot-spec/interfaces/i.lot-spec-all-item.dto';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { LotSpecLogicService } from '@app/logic/lot-spec/lot-spec.logic.service';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { SpecGroupsLogicService } from '@app/logic/spec-groups';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import {
    CostTypeEnumId,
    COST_TYPE_ENUM,
    ILotSpecColourItemDto,
    ILotSpecItemDto,
    ISpecGroupDto,
    LOT_SPEC_ITEM_MANAGE_ATTRIBUTE_ENTITY_TYPE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { isNullOrWhiteSpace, toPromise, toPromisedArray } from 'cb-hub-lib';
import _, { orderBy } from 'lodash';
import {BehaviorSubject, map, Subject, Subscription} from 'rxjs';
import { ApplySpecTemplateDialogComponent } from '../apply-spec-template-dialog/apply-spec-template-dialog.component';
import { HOUSE_AREA_PIN_PREFIX, ILotSpecItemExpandedPanels } from '../lot-spec-items-list/lot-spec-items-list.component';
import { LotSpecViewFullScreenDialogComponent } from '../lot-spec-view-fullscreen-dialog/lot-spec-view-fullscreen-dialog.component';
import { costTypeOrder, CostTypesListItem } from '../models/cost-types-list-item';
import { HouseAreaListItem } from '../models/house-area-list-item';

@Component({
    selector: 'cb-lot-spec-view',
    templateUrl: './lot-spec-view.component.html',
    styleUrls: ['./lot-spec-view.component.scss']
})
export class LotSpecViewComponent implements OnInit, OnDestroy {

    private _lotMappedItem: ILotMappedItem;
    @Input() public set lotMappedItem(_lotMappedItem: ILotMappedItem) {
        if (_lotMappedItem) {
            this.userCacheItem.init().then(() => {
                this._lotMappedItem = _lotMappedItem;
                this.loadLotSpec(_lotMappedItem.id);
            });
        }
    }

    public get lotMappedItem(): ILotMappedItem {
        return this._lotMappedItem;
    }

    /** is this instance fullscreen/dialog */
    @Input() public fullscreen;

    @Input() public isSkinnyView: boolean;
    @Input() public lotSpecVersion: number;

    public specTemplateName: string;
    @Input() public lotSpec: ILotSpecMappedItem;
    public loaded = false;

    public isIncompleteItemsOnly = false;
    public isIncompleteItemsOnlySubject: Subject<boolean> = new Subject<boolean>();

    private fullSpecItemTypes = LOT_SPEC_ITEM_MANAGE_ATTRIBUTE_ENTITY_TYPE_ENUM.toSelectList();
    private fullCostTypes: IEnumLookup<CostTypeEnumId>[];
    public fullHouseAreas: ISpecGroupDto[];
    private houseAreaLabelsIndex: { [specGroupId: number]: string };

    private subscriptions$ = new Subscription();
    private lotSpecUpdateSub$ = new Subscription();

    @Input() public houseAreas: ISpecGroupDto[];

    public filteredSpecGroupScheduleItems;
    public expandedAreas = {} as ILotSpecItemExpandedPanels;
    public isExpandCollapseAllClicked:BehaviorSubject<boolean> = new BehaviorSubject(false);
    public allExpanded = false;
    public totalHouseAreaItems = 0;

    public viewFullscreen(): void {
        const ref = this.cbDialog
            .open(LotSpecViewFullScreenDialogComponent, {
                data: {
                    houseAreas: this.houseAreas,
                    lotMappedItem: this.lotMappedItem,
                    lotSpec: this.lotSpec,
                    fullscreen: true,
                    isSkinnyView: this.isSkinnyView,
                    lotSpecVersion: this.lotSpecVersion
                },
                minWidth: '100%',
                minHeight: '100%',
                fullWidth: true,
            });
    }

    public reloadLotSpec() {
        this.loaded = false;
        this.loadLotSpec(this.lotMappedItem.id);
    }

    public readonly filteredSpecGroupItems = new ComputedProperty(() => {
        const specGroupItems = {} as { [specGroupId: number]: { specGroupId: number; label: string; sortOrder: number; items: ILotSpecAllItem[] } };

        // Implemented for expand collapse functionality when using new schedule items
        if (this.filteredSpecGroupScheduleItems && this.filteredSpecGroupScheduleItems.length > 0) {

            return this.filteredSpecGroupScheduleItems;
        }

        if (this.lotSpec?.allItems == null || this.houseAreaLabelsIndex == null || this.fullHouseAreas == null) {
            return orderBy(specGroupItems, 'sortOrder', 'asc');
        }
        const searchData = this.userCacheItem?.copyData();
        for (const key in this.lotSpec.allItems) {
            if (Object.prototype.hasOwnProperty.call(this.lotSpec.allItems, key) && this.lotSpec.allItems[key]?.length > 0) {
                if (specGroupItems[key] == null) {
                    specGroupItems[key] = {} as any;
                }
                specGroupItems[key].items = this.lotSpec.allItems[key].filter((x) => {
                    if (searchData?.selectedlotSpecItemEntityTypes?.length > 0) {
                        if (x.isColourItem && !searchData?.selectedlotSpecItemEntityTypes.includes(LOT_SPEC_ITEM_MANAGE_ATTRIBUTE_ENTITY_TYPE_ENUM.ColourItem)) {
                            return false;
                        }
                        else if (!x.isColourItem && !searchData?.selectedlotSpecItemEntityTypes.includes(LOT_SPEC_ITEM_MANAGE_ATTRIBUTE_ENTITY_TYPE_ENUM.LotSpecItem)) {
                            return false;
                        }
                    }
                    let itemName = '';
                    let itemCategory = '';
                    if (x.isColourItem) {
                        itemName = (x.item as ILotSpecColourItemDto)?.colourItem?.name?.toLowerCase() ?? '';
                    } else {
                        itemName = (x.item as ILotSpecItemMappedItem)?.productDisplay?.toLowerCase() ?? '';
                        itemCategory = (x.item as ILotSpecItemMappedItem)?.productCategory?.toLowerCase() ?? '';
                    }
                    if (!isNullOrWhiteSpace(searchData?.keyword) && (!isNullOrWhiteSpace(itemName) || !isNullOrWhiteSpace(itemCategory))) {
                        const keyword = searchData?.keyword?.toLowerCase() ?? '';
                        if (
                            (isNullOrWhiteSpace(itemName) || (!itemName.includes(keyword) && !keyword.includes(itemName)))
                            && (isNullOrWhiteSpace(itemCategory) || (!itemCategory.includes(keyword) && !keyword.includes(itemCategory)))
                        ) {
                            return false;
                        }
                    }
                    if (searchData?.selectedCostTypes?.length > 0) {
                        if (!x.isColourItem && !searchData?.selectedCostTypes.includes((x.item as ILotSpecItemDto).costType)) {
                            return false;
                        }
                    }
                    if (searchData?.selectedHouseAreas?.length > 0) {
                        if (!searchData?.selectedHouseAreas.includes(x.item.specGroupId)) {
                            return false;
                        }
                    }
                    return true;
                });
                if (specGroupItems[key].items.length === 0 && !searchData?.selectedHouseAreas.includes(key)) {
                    delete specGroupItems[key];
                }
            }
        }

        this.totalHouseAreaItems = 0;
        for (const key in specGroupItems) {
            if (Object.prototype.hasOwnProperty.call(specGroupItems, key)) {
                const element = specGroupItems[key];
                const label = this.houseAreaLabelsIndex[key] ?? 'Unknown';
                element.label = `${label} (${element.items.length})`;
                element.specGroupId = +key;
                element.sortOrder = this.fullHouseAreas.find(x => +x.id === element.specGroupId)?.sortOrder;
                this.totalHouseAreaItems += element.items?.length ?? 0;
            }
        }
        const arr = orderBy(specGroupItems, 'sortOrder', 'asc');
        this.setAllExpanded(arr);
        return arr;
    });

    public readonly filteredTags = new ComputedProperty(() => {

        if (!this.currentUser?.$resolved || (!this.lotSpec?.lotSpecScheduleItems)) {
            return [];
        }

        const tags = this.lotSpec.lotSpecScheduleItems.flatMap(item => {
            return item.tags.map((tag, idx) => {
                return {
                    id: tag,
                    label: tag
                };
            });
        });

        return _.uniqBy(tags, 'label');

    });

    public readonly filteredHouseAreas = new ComputedProperty(() => {

        if (!this.fullHouseAreas || !this.currentUser?.$resolved || (!this.lotSpec.allItems && !this.lotSpec.lotSpecScheduleItems)) {
            return [];
        }

        // Old spec items
        if (this.lotSpec.items.length > 0) {

            return this.fullHouseAreas
                .map(fullHouseArea => new HouseAreaListItem(this.currentUser.isQSTeam(), fullHouseArea,
                    this.lotSpec.allItems[fullHouseArea.id]?.filter(allItem => !allItem.item.isDeleted).length)
                )
                .filter(x => x.isVisible)
                .sort((a, b) => a.sortOrder - b.sortOrder);
        } else {
            // New schedule items
            return this.fullHouseAreas
                .map(fullHouseArea => new HouseAreaListItem(this.currentUser.isQSTeam(), fullHouseArea,
                    this.lotSpec.lotSpecScheduleItems?.filter(item => item.specGroupId === fullHouseArea.id).length)
                )
                .filter(x => x.isVisible)
                .sort((a, b) => a.sortOrder - b.sortOrder);
        }


    });

    public readonly filteredCostTypes = new ComputedProperty(() => {
        if (!this.fullCostTypes || (!this.lotSpec.allItems && !this.lotSpec.lotSpecScheduleItems)) {
            return [];
        }

        // Old spec items
        if (this.lotSpec.items.length > 0) {
            const houseAreaItems = Object.values(this.lotSpec.allItems)
                .reduce((prev, curr) => prev.push(...curr) && prev, []);

            return this.fullCostTypes
                .map(fullCostType => new CostTypesListItem(fullCostType, houseAreaItems
                    .filter(allItem => !allItem.item.isDeleted)
                    .filter(allItem => !allItem.isColourItem && (allItem.item as ILotSpecItemDto).costType === fullCostType.id).length)
                )
                .sort((a, b) => costTypeOrder[a.id] - costTypeOrder[b.id])
                .sort((a) => a.itemsCount > 0 ? -1 : 1);

        } else {
            // New schedule items
            return this.fullCostTypes
                .map(fullCostType => new CostTypesListItem(fullCostType, this.lotSpec.lotSpecScheduleItems
                    ?.filter(item => item.costType === fullCostType.id).length)
                )
                .sort((a, b) => costTypeOrder[a.id] - costTypeOrder[b.id])
                .sort((a) => a.itemsCount > 0 ? -1 : 1);
        }

    });

    public readonly filteredSpecItemTypes = new ComputedProperty(() => {
        if (!this.fullSpecItemTypes || !this.lotSpec?.allItems) {
            return [];
        }
        const houseAreaItems: ILotSpecAllItem[] = Object.values(this.lotSpec.allItems)
            .reduce((prev, curr) => prev.push(...curr) && prev, []);

        return cloneDeepSafe(this.fullSpecItemTypes).map(x => {
            if (x.id === LOT_SPEC_ITEM_MANAGE_ATTRIBUTE_ENTITY_TYPE_ENUM.ColourItem) {
                x.label = `${x.label} (${houseAreaItems.filter(y => y.isColourItem).length})`;
            } else if (x.id === LOT_SPEC_ITEM_MANAGE_ATTRIBUTE_ENTITY_TYPE_ENUM.LotSpecItem) {
                x.label = `${x.label} (${houseAreaItems.filter(y => !y.isColourItem).length})`;
            }
            return x;
        });
    });

    public get userCacheItem(): UserCacheItem<ILotSpecSearch> {
        return this.userCacheService.lotSpecSearch;
    }

    public get lotSpecPinnedCache(): UserCacheItem<ILotSpecPinned> {
        return this.userCacheService.lotSpecPinned;
    }

    constructor(
        private readonly specGroupLogic: SpecGroupsLogicService,
        private readonly currentUser: CurrentUserService,
        private readonly lotSpecLogicService: LotSpecLogicService,
        private readonly lotSpecScheduleLogicService: LotSpecScheduleLogicService,
        private readonly userCacheService: UserCacheService,
        private readonly lotLogicService: LotsLogicService,
        private readonly cbDialog: CbDialogService,
    ) {
    }

    public ngOnInit(): void {
        if (this.fullscreen) {
            this.handleLoadLotSpec(this.lotSpec);
        } else {
            this.houseAreas = toPromisedArray(this.specGroupLogic.$getList()
                .pipe(map(results => orderBy(results, 'sortOrder', 'asc'))));
        }

        this.userCacheItem.init().then(() => {
            this.loaded = true;
            this.subscriptions$.add(
                this.userCacheItem.updated$.subscribe(() => {
                    this.recomputeProps();
                })
            );
        });
        this.loadPinnedPanels();
    }

    private loadPinnedPanels = (): void => {
        this.lotSpecPinnedCache.init().then(() => {
            const state = this.lotSpecPinnedCache.copyData();
            this.expandedAreas = Object.keys(state).reduce((obj, houseAreaKey) => {
                const specGroupId = houseAreaKey.replace(HOUSE_AREA_PIN_PREFIX, '');
                obj[specGroupId] = state[houseAreaKey];
                return obj;
            }, {});
            this.setAllExpanded();
        });
    };

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        this.lotSpecUpdateSub$.unsubscribe();
    }

    public expandCollapseAll = (): void => {
        this.setAllExpanded();
        const newStatus = !this.allExpanded;
        this.isExpandCollapseAllClicked.next(newStatus);
        this.filteredSpecGroupItems.value.forEach((value) => {
            if (newStatus) {
                // set timeout to expand panels one after another - better for performance in this case
                setTimeout(() => {
                    this.expandedAreas[value.specGroupId] = newStatus;
                });
            } else {
                this.expandedAreas[value.specGroupId] = newStatus;
            }
        });
        this.allExpanded = newStatus;
    };

    public setAllExpanded = (specGroupItems = this.filteredSpecGroupItems.value): void => {
        let expandedPanelsCount = 0;
        let panelCount = 0;
        specGroupItems.forEach((value) => {
            if (this.expandedAreas[value.specGroupId]) {
                expandedPanelsCount++;
            }
            panelCount++;
        });

        if (expandedPanelsCount === panelCount) {
            this.allExpanded = true;
        } else {
            this.allExpanded = false;
        }
    };

    public setFilteredScheduleItems = (specGroupScheduleItems): void => {
        this.filteredSpecGroupScheduleItems = specGroupScheduleItems;
        this.filteredSpecGroupItems.recompute();
        this.setAllExpanded();
    };

    public clearFilters(): void {
        this.userCacheItem.data.keyword = null;
        this.userCacheItem.data.selectedCostTypes = [];
        this.userCacheItem.data.selectedHouseAreas = [];
        this.userCacheItem.data.selectedTags = [];
        this.userCacheItem.data.selectedlotSpecItemEntityTypes = [];
    }

    public isIncompleteOnlyChange($event): void {
        this.isIncompleteItemsOnly = $event;
        this.isIncompleteItemsOnlySubject.next($event);
    }

    public applySpecTemplate(): void {
        this.cbDialog
            .open(
                ApplySpecTemplateDialogComponent,
                {
                    data: {
                        lot: this.lotMappedItem.$clone(),
                        lotSpec: this.lotSpec.$clone(),
                    }
                }
            )
            .afterClosed()
            .subOnce((result) => {
                // overwrite existing lot spec if ids do not match
                // if ids do match the LotSpecMappedItem was already updated
                if (result.id !== this.lotSpec.id) {
                    this.handleLoadLotSpec(
                        this.lotSpecLogicService
                            .$createMappedItem(result)
                    );
                }

                this.lotMappedItem.$reload().subOnce();
            });
    }

    public loadLotSpec(lotId: number): void {
        if (!lotId) {
            this.lotSpec = null;
            return;
        }

        if (this.isSkinnyView && this.lotMappedItem?.hasScheduleSpecTemplate && this.lotSpecVersion > 0) {
            this.lotSpecScheduleLogicService
                .getLotSpecScheduleAtVersion(lotId, this.lotSpecVersion)
                .subOnce(this.handleLoadLotSpec);
        } else {
            this.lotSpecLogicService
                .$getMappedItem(lotId)
                .subOnce(this.handleLoadLotSpec);
        }
    }


    public handleLoadLotSpec = (result: ILotSpecMappedItem): void => {
        this.loaded = true;
        this.lotSpec = result;
        this.setTemplateName();
        this.initLists();
        this.lotSpecUpdateSub$.unsubscribe();
        this.lotSpecUpdateSub$ = new Subscription();
        this.lotSpecUpdateSub$.add(
            this.lotSpec.$updated.subscribe(() => {
                this.setTemplateName();
                this.initLists();
                this.recomputeProps();
            })
        );
    };

    private recomputeProps(): void {
        this.filteredSpecGroupItems.recompute();
        this.filteredHouseAreas.recompute();
        this.filteredSpecItemTypes.recompute();
        this.filteredCostTypes.recompute();
        this.filteredTags.recompute();
    }

    private initLists(): void {
        Promise
            .all([this.initHouseAreas(), this.initCostTypes()])
            .then(() => {
                this.recomputeProps();
            });
    }

    private setTemplateName(): void {
        const specTemplateMsg = isNullOrWhiteSpace(this.lotSpec?.baseTemplateName) ? 'No Spec Template Applied' : this.lotSpec?.baseTemplateName;
        this.specTemplateName = `${specTemplateMsg} - Version ${this.lotSpec.specVersion}`;
    }

    private initCostTypes(): Promise<void> {
        return new Promise((resolve) => {
            this.fullCostTypes = COST_TYPE_ENUM.toLookup();
            resolve();
        });
    }

    private initHouseAreas(): Promise<void> {
        return toPromise(
            this.specGroupLogic
                .$getList()
        )
            .then(result => {
                this.fullHouseAreas = result;
                this.houseAreaLabelsIndex = this.fullHouseAreas.reduce((prev, curr) => {
                    prev[curr.id] = curr.label;
                    return prev;
                }, {});
            });
    }

    public isApplyLotSpecDisabled = (): boolean => {
        return this.lotSpec?.appliedTemplateId > 0 || this.lotSpec?.isLocked;
    };

    public setTotalHouseAreaItems(totalItems): void {
        this.totalHouseAreaItems = totalItems;
    }

    public generateReport(): void {
        this.lotLogicService.generateLotSpecReport(this.lotMappedItem.id, this.lotSpecVersion).subOnce();
    }
}
