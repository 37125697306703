<div class="flex-row">
    <form class="flex flex-display"
          #lotSearchForm="ngForm">
        <mat-card class="flex-row flex">
            <mat-card-content class="mat-card-content-row layoutGap-20">
                <div class="flex-row center-center search-filter">
                    <mat-form-field class="flex flex-display mg-20">
                        <mat-label>Type in Lot or Project Details and Press Enter to Search</mat-label>
                        <input #searchQuery
                               matInput
                               type="text"
                               name="search"
                               [ngModel]="searchData.query"
                               (keydown.enter)="onEnter($event, searchQuery.value)"
                               [disabled]="fetchInProgress"/>
                    </mat-form-field>
                    <button mat-icon-button
                            [disabled]="fetchInProgress"
                            (click)="onEnter($event, searchQuery.value)">
                        <mat-icon>
                            search
                        </mat-icon>
                    </button>
                </div>
                <cb-select label="Filter By"
                           name="lotFilterType"
                           class="flex"
                           [(ngModel)]="searchData.lotFieldToSearch"
                           (ngModelChange)="searchQueryUpdated$.next()"
                           [options]="lotSearchFields"
                           [disabled]="fetchInProgress">
                </cb-select>
                <cb-select-list class="flex"
                                label="Contract Type"
                                [allOptions]="contractTypes"
                                [multiple]="true"
                                [value]="searchData.contractTypes"
                                (selectChanged)="selectedContractTypeChanged($event)"
                                [disabled]="fetchInProgress">
                </cb-select-list>
                <cb-select-list class="flex"
                                label="Lot Status"
                                [allOptions]="jobStatuses"
                                [multiple]="true"
                                [value]="searchData.lotStatuses"
                                (selectChanged)="selectedJobStatusesChanged($event)"
                                [disabled]="fetchInProgress">
                </cb-select-list>
                <cb-select label="Region"
                           name="regions"
                           class="flex"
                           [(ngModel)]="searchData.locationId"
                           (ngModelChange)="searchQueryUpdated$.next()"
                           [options]="regions"
                           [disabled]="fetchInProgress">
                </cb-select>
                <div class="flex-row flex end-center">
                    <div class="flex-row mg-20">
                        <mat-checkbox name="myLotsOnlyCheckbox"
                                      [(ngModel)]="searchData.myLotsOnly"
                                      (change)="searchQueryUpdated$.next();"
                                      [checked]="searchData.myLotsOnly === true"
                                      [disabled]="fetchInProgress">
                            Only My Lots
                        </mat-checkbox>
                    </div>
                    <div class="flex-row mg-0">
                        <button mat-icon-button
                                [matMenuTriggerFor]="popUpSearchMenu"
                                [disabled]="fetchInProgress">
                            <mat-icon>
                                filter_list
                            </mat-icon>
                        </button>
                    </div>
                    <mat-menu #popUpSearchMenu="matMenu">
                        <cb-lot-search-popup-filter *cbWaitFor="searchData && floorAreas"
                                                    (click)="$event.stopPropagation()"
                                                    [searchData]="searchData"
                                                    [floorAreas]="floorAreas"
                                                    [lotTypes]="lotTypes"
                                                    (emitPopUpSearchEvent)="searchQueryUpdated$.next()">
                        </cb-lot-search-popup-filter>
                    </mat-menu>
                </div>
            </mat-card-content>
        </mat-card>
    </form>
</div>
<div class="flex-row"
     *ngIf="filterChips && filterChips.length > 0">
    <mat-card class="flex-100">
        <mat-card-content>
            <mat-chip-set>
                <mat-chip *ngFor="let chip of filterChips"
                          (removed)="removeFilterChip(chip.id)"
                          [disabled]="fetchInProgress">
                    {{ chip.text }}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
            </mat-chip-set>
        </mat-card-content>
    </mat-card>
</div>
