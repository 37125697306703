<cb-dialog class="flex-col"
           dialogHeading="Request Pricing On Lots">
    <form class="flex-col flex"
          #form="ngForm">
        <cb-table class="cb-margin-top cb-margin-bottom-3x"
                  [loaded]="loaded">
            <thead cbTableHead>
                <tr>
                    <td>Lot #</td>
                    <td>Job #</td>
                    <td>Scheme Status</td>
                    <td>Applied Concept</td>
                    <td style="text-align: center; width: 150px;">Request Pricing</td>
                </tr>
            </thead>
            <tbody cbTableBody>
                <tr cbTableRow
                    *ngFor="let lot of filteredLots | cbSortByPipe: 'lotNumber': 'asc'; let $index = index">
                    <cb-td-text [value]="lot.lotNumber"></cb-td-text>
                    <cb-td-text [value]="lot.jobNumber"></cb-td-text>
                    <td>
                        <cb-td-text *ngIf="!lot.designSchemeStatusId"
                                    value="Any"></cb-td-text>
                        <cb-td-enum *ngIf="lot.designSchemeStatusId"
                                    [value]="lot.designSchemeStatusId"
                                    [enum]="DESIGN_SCHEME_STATUS_ENUM">
                        </cb-td-enum>
                    </td>
                    <cb-td-text [value]="lot.appliedConceptName"></cb-td-text>
                    <td style="text-align: center; width: 150px;">
                        <mat-checkbox [checked]="lot.$shouldApplyConcept"
                                      (change)="clicked(lot, form.form)">
                        </mat-checkbox>
                    </td>
                </tr>
                <tr *ngIf="lots?.length < 1 && loaded">
                    <td colspan="17">
                        <mat-icon class="cb-grey">info</mat-icon>
                        &nbsp;No Lots have been added to this Release Stage
                    </td>
                </tr>
            </tbody>
        </cb-table>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="isSaveDisabled(form.form)"
                (cbClick)="requestPricing()"
                [clickLimit]="1">Request Pricing</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
