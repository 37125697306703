<mat-accordion multi="true" class="flex">
    <mat-expansion-panel [expanded]="true" class="flex-col">
        <mat-expansion-panel-header>
            <mat-panel-title class="start-center">
                <mat-icon svgIcon="information"></mat-icon>
                <h1 class="mat-body-strong cb-margin-left">Client Info</h1>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="info-table-container">
            <span class="copy-info-clipboard"
                  matTooltip="{{clipboardTooltip}}">
                <button mat-icon-button
                        (click)="copyInfoToClipboard($event)"
                        (mouseleave)="resetClipboardTooltip()">
                    <mat-icon svgIcon="content-copy"></mat-icon>
                </button>
            </span>
            <table id="info-table">
                <tbody>
                    <tr>
                        <td>Username</td>
                        <td>{{currentUserService.userName}}</td>
                    </tr>
                    <tr>
                        <td>User Guid</td>
                        <td>{{currentUserService.guid}}</td>
                    </tr>
                    <tr>
                        <td>ClassicHUB Version</td>
                        <td>{{environment.buildVersion}}</td>
                    </tr>
                    <tr>
                        <td>Client IP</td>
                        <td>{{clientIp}}</td>
                    </tr>
                    <tr>
                        <td>Operating System</td>
                        <td>{{browser.os}}</td>
                    </tr>
                    <tr>
                        <td>Browser</td>
                        <td>{{browser.name}} - {{browser.version}}</td>
                    </tr>
                    <tr>
                        <td>Screen Resolution</td>
                        <td>{{window.screen.width}} x {{window.screen.height}}</td>
                    </tr>
                    <tr>
                        <td>Inner Window Dimensions</td>
                        <td>{{window.innerWidth}} x {{window.innerHeight}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="flex-col flex"
                         [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title class="start-center">
                <mat-icon svgIcon="database"></mat-icon>
                <h1 class="mat-body-strong cb-margin-left">Data</h1>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <button mat-raised-button
                (click)="confirmClearUserPreferencesCache()"
                color="primary"
                matTooltip="Clears your cache for user preferences">Clear User Preferences
            Cache</button>
        <form #settingsMasterForm="ngForm"
              *ngIf="userCache.resolved"
              class="cb-margin-top-2x flex-col">
            <p class="mat-body-strong cb-margin-bottom">Toggle Sticky Search/Cache Areas</p>
            <div class="flex-wrap flex">
                <ul *ngFor="let category of userCache.categories"
                    class="list cb-padding-right-3x cb-padding-bottom flex-col md-33 lg-25 sm-50 xl-20 xs-100">
                    <p class="mat-body-strong cb-margin-bottom">{{userCache.allCategorised[category].label}}</p>
                    <li *ngFor="let item of userCache.allCategorised[category].items">
                        <cb-checkbox [label]="item.label"
                                     *ngIf="item.canAccess()"
                                     class="flex"
                                     name="item-{{item.key}}"
                                     [(ngModel)]="userCache.master[item.key]"></cb-checkbox>
                    </li>
                </ul>
            </div>
            <footer class="button-footer flex-row layoutGap-8">
                <button mat-raised-button
                        (click)="confirmSaveUserCache(settingsMasterForm)"
                        [disabled]="settingsMasterForm.pristine"
                        color="primary"
                        matTooltip="Saves the changes made to the above settings selections">Save Changes</button>
                <button mat-raised-button
                        (click)="confirmCheckAllUserCache(settingsMasterForm)"
                        matTooltip="Check all of the Toggle Sticky Search/Cache Areas">Check All</button>
                <button mat-raised-button
                        (click)="confirmUncheckAllUserCache(settingsMasterForm)"
                        matTooltip="Uncheck all of the Toggle Sticky Search/Cache Areas">Uncheck All</button>
            </footer>
        </form>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true" class="flex-col">
        <mat-expansion-panel-header>
            <mat-panel-title class="start-center">
                <mat-icon svgIcon="file-document-box-outline"></mat-icon>
                <h1 class="mat-body-strong cb-margin-left">Logs</h1>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <dl>
            <dt class="cb-margin-bottom cb-help-cursor">
                <span matTooltip="HTTP Error Logs are a session wide record of errors received on frontend via HTTP requests">HTTP
                    Error
                    Logs</span>
            </dt>
            <dd class="button-row start-center flex-row">
                <button mat-raised-button
                        (click)="httpErrors.downloadLogs()"
                        color="primary"
                        matTooltip="Downloads Http Error Logs for the current users session">Download Session Http Error
                    Logs</button>
                <button mat-raised-button
                        (click)="confirmClearSessionHttpErrorLogs()"
                        color="primary"
                        matTooltip="Clears ALL Http Error Logs for the current users session">Clear Session Http Error
                    Logs</button>
            </dd>
        </dl>
    </mat-expansion-panel>
</mat-accordion>
