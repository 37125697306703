<cb-dialog>
    <mat-radio-group *ngIf="data.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Orders"
                     class="cb-margin-bottom-2x"
                     [(ngModel)]="hasOptionalEmailAddress">
        <div class="flex-col">
            <mat-radio-button [value]="false">Email</mat-radio-button>
            <span class="cb-margin-top-2x">{{data.contact.email}}</span>
        </div>
        <form class="cb-margin-top-2x flex-col">
            <mat-radio-button [value]="true">Optional Email Address</mat-radio-button>
            <cb-input label="Optional Email Address"
                      name="emailAddress"
                      [disabled]="!hasOptionalEmailAddress"
                      [maxlength]="150"
                      [(ngModel)]="emailAddress"></cb-input>
        </form>
    </mat-radio-group>
    <cb-business-account-location-picker [isForm]="true"
                                         [region]="region"
                                         [district]="district"
                                         [area]="area"
                                         [availableLocations]="availableLocations"
                                         (locationChange)="onLocationChanged($event)">
    </cb-business-account-location-picker>
    <form *ngIf="data.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Installation"
          class="cb-margin-top-2x"
          [ngSwitch]="!data.contactLocation">
        <div *ngSwitchCase="true">
            <span class="cb-label">Trade Types</span>
            <cb-checkbox *ngFor="let type of availableTradeTypes"
                         [name]="'tradeType'+type.id"
                         [label]="type.label"
                         [ngModel]="isTradeTypeChecked(type.id)"
                         (change)="onTradeTypeChanged(type.id, $event)"></cb-checkbox>
        </div>
        <div *ngSwitchDefault>
            <cb-select label="Trade Type"
                       name="tradeType"
                       [required]="true"
                       [options]="availableTradeTypes"
                       [ngModel]="selectedTradeTypes[0]"
                       (change)="selectedTradeTypes[0]=$event"></cb-select>
        </div>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                [disabled]="!canSave()"
                (click)="onSaveClicked()">Save</button>
        <button mat-raised-button
                (click)="dialogRef.close()">Cancel</button>
    </div>
</cb-dialog>
