import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {IPropertySearch} from '@app/core/services/user-cache/user-cache-areas';
import {UserCacheItem} from '@app/core/services/user-cache/user-cache-item';
import {UserCacheService} from '@app/core/services/user-cache/user-cache.service';
import {LotsLogicService} from '@app/logic/lots';
import {ILotDocumentDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {ISearchResult} from '@app/shared/components/search/i.search';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {CurrentUserService} from '@app/core/authentication/current.user';

@Component({
    selector: 'app-lot-search',
    templateUrl: './lot-search.component.html',
    styleUrls: ['./lot-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotSearchComponent implements AfterViewInit, OnDestroy {

    private readonly subscription$ = new Subscription();
    public readonly queryUpdate$ = new Subject();
    public fetchInProgress = false;

    public get userCacheItem(): UserCacheItem<IPropertySearch> {
        return this.userCacheService.lotSearch;
    }

    public searchEnabled$ = new BehaviorSubject(false);
    public get searchEnabled(): boolean {
        return this.searchEnabled$.value;
    }

    public currentPage: number;
    public results: ILotDocumentDto[] = [];
    public floorAreas: number[];

    constructor(
        private readonly lotsLogicService: LotsLogicService,
        private readonly userCacheService: UserCacheService,
        private readonly navigationService: NavigationService,
        private readonly currentUser: CurrentUserService,) {
        this.loadFloorArea();
    }


    public ngAfterViewInit(): void {
        this.initSearchFiltersCache();
    }

    public ngOnDestroy(): void {
        this.subscription$.unsubscribe();
    }

    private loadFloorArea(): void {
        this.lotsLogicService
            .getMaxMinCostArea()
            .subOnce((result) => {
                this.floorAreas = [Math.floor(result.floorAreas[0]), Math.ceil(result.floorAreas[1])];
            });
    }

    public fetchResults(): Observable<ISearchResult<ILotDocumentDto>> {
        if (!this.searchEnabled) {
            return;
        }

        return this.lotsLogicService.search(this.getQueryParams(), false);
    }

    private getQueryParams(): any {
        const data = this.userCacheItem.copyData();
        return this.searchEnabled ? {
            numberOfBedrooms: data.numberOfBedrooms > 0 ? data.numberOfBedrooms : null,
            numberOfBathrooms: data.numberOfBathrooms > 0 ? data.numberOfBathrooms : null,
            numberOfLivingRooms: data.numberOfLivingRooms > 0 ? data.numberOfLivingRooms : null,
            numberOfGarages: data.numberOfGarages > 0 ? data.numberOfGarages : null,

            locationId: data.locationId > 0 ? data.locationId : null,
            lotType: data.lotType > 0 ? data.lotType : null,
            lotStatuses: data.lotStatuses?.length > 0 ? data.lotStatuses : null,
            contractTypes: data.contractTypes?.length > 0 ? data.contractTypes : null,
            isSpecBuild: data.isSpecBuild ? true : null,
            isShowHome: data.isShowHome ? true : null,
            myLotsOnly: data.myLotsOnly ? true : null,

            query: data.query,
            order: data.order?.label ?? null,
            currentPage: this.currentPage,
            lotFieldToSearch: data.lotFieldToSearch > 0 ? data.lotFieldToSearch : 0,

            floorAreaMax: data.floorAreaMax,
            floorAreaMin: data.floorAreaMin
        } : undefined;
    }

    public shouldShowNoResultsMessage(): boolean {
        return (this.results?.length < 1);
    }

    public viewLot(id: number): void {
        this.navigationService.navigate([`lots/${id}/summary`]);
    }

    public searchFilterChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.queryUpdate$.next(null);
    };

    public initSearchFiltersCache(): void {
        Promise.all([
            this.userCacheItem.init(),
            this.currentUser.$promise,
        ]).then(() => {
            this.searchEnabled$.next(true);
            this.subscription$.add(
                this.userCacheItem.updated$.subscribe({
                    next: this.searchFilterChanged
                })
            );
        });
    }
}
