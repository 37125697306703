<cb-dialog dialogHeading="View Manual Purchase Order">
    <form name="manualPurchaseOrderForm">
        <div class="cb-margin flex-row">
            <div class="flex-col flex">
                <span class="cb-title">Order To - {{mappedItem.supplierName}}</span>
                <cb-display-value-new label="Postal Address">
                    <span class="mat-body-2">
                        {{mappedItem.supplierStreetAddress| cbDasher}}
                        <br/>
                        {{mappedItem.supplierSuburb| cbDasher}}
                        <br/>
                        {{mappedItem.supplierPostCode| cbDasher}}
                        <br/>
                        {{mappedItem.supplierCity| cbDasher}}
                        <br/>
                        {{mappedItem.supplierAddressRegion | cbDasher}}
                        <br/>
                    </span>
                </cb-display-value-new>
                <cb-display-value-new label="Email Address"
                                      [value]="mappedItem.supplierEmailAddress">
                </cb-display-value-new>
                <cb-display-value-new label="Cost Nature">
                    {{COST_NATURE_ENUM[mappedItem.costNature]}}
                </cb-display-value-new>
                <cb-display-value-new label="Client Variation"
                                      *ngIf="isExtraToClient">
                    <div *ngIf="mappedItem.variationId">
                        <span class="mat-body-strong">
                            {{'VO#' + mappedItem.variationNumber}}
                        </span>
                        -
                        <span>
                            {{mappedItem.variationAmount | cbCurrency: 'addGst'}} (Inc Gst)
                        </span>
                    </div>
                    <div class="start-center flex-row"
                         *ngIf="!mappedItem.variationId">
                        <mat-icon class="mat-warn">warning</mat-icon>
                        A Variation Needs to be Selected
                    </div>
                </cb-display-value-new>
                <div class="flex-col flex"
                     *ngIf="isOrderExtraToSupplier">
                    <cb-display-value-new label="Chargable Business Account"
                                          [value]="mappedItem.chargeableBusinessAccountName">
                    </cb-display-value-new>
                    <cb-display-value-new label="Chargable Business Account Contact"
                                          [value]="mappedItem.chargeableBusinessAccountContactName || mappedItem.freeTextChargeableBusinessAccountContact">
                    </cb-display-value-new>
                    <cb-display-value-new label="Discussion Comments"
                                          [value]="mappedItem.discussionDescription">
                    </cb-display-value-new>
                    <cb-display-value-new label="Discussion Date"
                                          *ngIf="mappedItem.discussionDate">
                        {{mappedItem.discussionDate | date}}
                    </cb-display-value-new>
                </div>
                <cb-display-value-new label="Comments"
                                      [value]="mappedItem.comments"></cb-display-value-new>
            </div>
            <div class="flex-col flex">
                <span class="cb-title">Order #{{mappedItem.orderNumber}}</span>
                <cb-display-value-new label="Order Date">
                    <span>{{mappedItem.orderDate | date}}</span>
                </cb-display-value-new>
                <cb-display-value-new label="Activity">
                    <span>{{mappedItem.activityCode}} -
                        {{mappedItem.activityName}}</span>
                </cb-display-value-new>
                <cb-display-value-new label="Start Date"
                                      *ngIf="mappedItem.buildProgrammeActivity && mappedItem.buildProgrammeActivityId">
                    {{mappedItem.buildProgrammeActivity.startDate | date}}
                </cb-display-value-new>
                <cb-display-value-new label="End Date"
                                      *ngIf="mappedItem.buildProgrammeActivity && mappedItem.buildProgrammeActivityId">
                    {{mappedItem.buildProgrammeActivity.endDate | date}}
                </cb-display-value-new>
                <cb-display-value-new label="Duration Days"
                                      *ngIf="mappedItem.buildProgrammeActivity && mappedItem.buildProgrammeActivityId">
                    {{mappedItem.buildProgrammeActivity.duration}}
                </cb-display-value-new>
                <cb-display-value-new label="Order Status"
                                      [value]="PURCHASE_ORDER_STATUS_ENUM[mappedItem.statusId]">
                </cb-display-value-new>
                <span class="mat-body-strong">Approval</span>
                <cb-display-value-new class="flex-col"
                                      label="Approval Status">
                    <span class="mat-body-2 cb-margin-botom"
                          *ngIf="showPendingSince.value">Pending since
                        {{mappedItem.submittedDate | date}}</span>
                    <cb-user-date-time-new [label]="mappedItem.approvalStatusLabel + ' by'"
                                           [date]="mappedItem.approvedDate"
                                           [userFullName]="mappedItem.approvedByName"
                                           *ngIf="!showPendingSince.value && mappedItem.approvedByName != undefined && mappedItem.approvedDate != undefined">
                    </cb-user-date-time-new>
                </cb-display-value-new>
                <cb-display-value-new label="Approval Comments"
                                      [value]="mappedItem.approvalComments"></cb-display-value-new>
            </div>
        </div>
        <div class="cb-margin flex-row">
            <div class="flex-col flex">
                <div class="standardTable mat-body-2">
                    <table>
                        <thead>
                            <tr>
                                <td>Code</td>
                                <td>Item</td>
                                <td class="right-align">UOM</td>
                                <td class="right-align">Quantity</td>
                                <td class="right-align">Rate</td>
                                <td class="right-align">Subtotal</td>
                                <td class="right-align">GST</td>
                                <td class="actions"></td>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let lineItem of mappedItem.mappedLines | cbSortByPipe: 'createdDate' : 'asc'| cbSortByPipe: 'sortOrder' : 'asc'">
                                <tr [ngClass]="{ 'poLineUnknownRate' : lineItem.costToBeDetermined }">
                                    <ng-container *ngIf="lineItem.isNoteLine">
                                        <td colspan="7">
                                            <span class="restricted-width-text"
                                                  cb-percentage-max-width="70">{{lineItem.description |
                                                cbDasher}}</span>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="!lineItem.isNoteLine">
                                        <td>{{lineItem.productCode | cbDasher}}</td>
                                        <td>{{lineItem.description | cbDasher}}</td>
                                        <td class="right-align">{{lineItem.uom}}</td>
                                        <td class="right-align">{{lineItem.quantity}}</td>
                                        <td class="right-align">{{lineItem.rate | cbCurrency}}</td>
                                        <td class="right-align">{{lineItem.lineSubtotalCalc() | cbCurrency}}
                                        </td>
                                        <td class="actions">
                                            <mat-icon>{{lineItem.gstApplies ? "check_box" : "check_box_outline_blank"}}
                                            </mat-icon>
                                        </td>
                                    </ng-container>
                                    <td class="actions poLineRowButtons">
                                        <button mat-icon-button
                                                *ngIf="canManage.value"
                                                (click)="editLineItem(lineItem)">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button mat-icon-button
                                                *ngIf="canManage.value"
                                                (click)="removeLineItem(lineItem)">
                                            <mat-icon>remove_circle</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                                <tr [ngClass]="{ 'poLineUnknownRate' : lineItem.costToBeDetermined }"
                                    *ngIf="lineItem.notes">
                                    <td style="width: 30px;">
                                        <mat-icon class="cb-small-icon">insert_drive_file</mat-icon>
                                    </td>
                                    <td></td>
                                    <td>
                                        <span class="mat-body-2 preserve-line-breaks">{{lineItem.notes}}</span>
                                    </td>
                                    <td colspan="5"></td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="canManage.value">
                                <td colspan="7">
                                    <span *ngIf="mappedItem.mappedLines.length < 1">
                                        <cb-info-message message="There are no Line Items on this Manual Order"
                                                         icon="info_circle"
                                                         iconClass="mat-accent">
                                        </cb-info-message>
                                    </span>
                                </td>
                                <td class="actions">
                                    <button mat-icon-button
                                            [matMenuTriggerFor]="addLineMenu">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                    <mat-menu #addLineMenu="matMenu">
                                        <button mat-menu-item
                                                (click)="addLineItem()">
                                            <mat-icon>assignment</mat-icon>
                                            Line Item
                                        </button>
                                        <button mat-menu-item
                                                (click)="addNoteLine()">
                                            <mat-icon>note_add</mat-icon>
                                            Note Line
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="6"
                                    class="right-align">
                                    <div class="right-align end-center flex-row">
                                        <div class="flex-col">
                                            <span class="mat-body-2">
                                                Subtotal: {{mappedItem.orderSubtotalCalc() | cbCurrency}}
                                            </span>
                                            <span class="mat-body-2">
                                                GST: {{mappedItem.orderGstCalc() | cbCurrency}}
                                            </span>
                                            <span class="mat-body-strong">
                                                Total: {{mappedItem.orderTotalCalc() | cbCurrency}}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td colspan="2"
                                    class="actions">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </form>
    <div actions class="flex flex-row center-right">
        <button mat-raised-button
                color="primary"
                *ngIf="!data.downloadOnly && isExtraToClient"
                (click)="selectVariation()">
            <mat-icon>search</mat-icon>
            &nbsp;Select Client Variation
        </button>
        <button mat-raised-button
                color="primary"
                *ngIf="mappedItem.canDownload"
                (click)="mappedItem.download()">
            <mat-icon>file_download</mat-icon>
            &nbsp;Download
        </button>
        <button mat-raised-button
                color="accent"
                *ngIf="canManage.value"
                (click)="edit()">Edit Order Details</button>
        <button mat-raised-button
                color="accent"
                *ngIf="canSubmit.value"
                (click)="submit()">Submit Manual Order</button>
        <button mat-raised-button
                color="primary"
                *ngIf="canUnsubmit.value"
                (click)="unsubmit()">Withdraw Submission</button>
        <button mat-raised-button
                color="primary"
                *ngIf="canReview.value"
                (click)="review()">Review</button>
        <div class="flex-row"
             *ngIf="canManage.value || canSubmit.value || canUnsubmit.value || canReview.value">
            <mat-divider style="height:36px;margin:0 12px 0 18px;"></mat-divider>
        </div>
        <button mat-raised-button
                (click)="cancel()">Close</button>
    </div>
</cb-dialog>
