<cb-dialog class="flex-col"
           dialogHeading="{{ isAdd() ? 'Add' : 'Edit' }} Project Release Stage"
           *cbWaitFor="mappedItem && houseAndLandConsults">
    <form class="flex-col flex"
          #form="ngForm">
        <div class="flex-row flex"
             style="width: 100%">
            <div class="cb-margin-right-2x flex-col flex-33">
                <h3 class="mat-headline-6"
                    style="margin-bottom: 0">Release</h3>
                <div style="position: relative"
                     class="cb-margin-bottom-3x">
                    <mat-divider></mat-divider>
                </div>
                <cb-input name="name"
                          label="Release Name"
                          [required]="true"
                          [maxlength]="150"
                          [(ngModel)]="mappedItem.name">
                </cb-input>
                <label class="cb-margin-right-2x cb-margin-bottom required">Release Type</label>
                <mat-radio-group name="releaseType"
                                 class="cb-margin-bottom flex-col"
                                 [(ngModel)]="mappedItem.type"
                                 [required]="true"
                                 [disabled]="isEdit()"
                                 (change)="releaseTypeChanged()">
                    <mat-radio-button [value]="PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand">
                        House and Land
                    </mat-radio-button>
                    <mat-radio-button [value]="PROJECT_RELEASE_STAGE_TYPE_ENUM.DesignAndBuild">
                        Design and Build
                    </mat-radio-button>
                </mat-radio-group>
                <cb-display-value-new label="Contract Type"
                                      [value]="mappedItem.getContractTypeDisplay()">
                </cb-display-value-new>
                <cb-selection-list name="allowedLotContractTypes"
                                   [(ngModel)]="mappedItem.allowedLotContractTypes"
                                   [ngModelOptions]="{ standalone: true }"
                                   *ngIf="
                        mappedItem.type ==
                        PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand
                    "
                                   [options]="houseAndLandlotContractTypes"
                                   [required]="true"
                                   [slim]="true"
                                   checkboxPosition="before"
                                   label="Allowed Lot Contract Types">
                </cb-selection-list>
                <cb-selection-list name="allowedLotContractTypes"
                                   [(ngModel)]="mappedItem.allowedLotContractTypes"
                                   [ngModelOptions]="{ standalone: true }"
                                   [required]="true"
                                   *ngIf="
                        mappedItem.type ==
                        PROJECT_RELEASE_STAGE_TYPE_ENUM.DesignAndBuild
                    "
                                   [options]="designAndBuildlotContractTypes"
                                   [slim]="true"
                                   checkboxPosition="before"
                                   label="Allowed Lot Contract Types">
                </cb-selection-list>
                <cb-vendors-input-find-cards label="Vendor"
                                             [(ngModel)]="vendor"
                                             *ngIf="
                        mappedItem.type ===
                        PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand
                    "
                                             name="selectedVendor"
                                             [required]="true"
                                             [text]="mappedItem.vendorDisplay || vendor?.legalName"
                                             [removeable]="false"
                                             #selectedReferrer="ngModel"
                                             (change)="vendorChanged()">
                </cb-vendors-input-find-cards>
                <cb-sellingagents-input-find-cards label="Selling Agent"
                                                   [(ngModel)]="agent"
                                                   name="selectedAgent"
                                                   [required]="false"
                                                   [text]="mappedItem.agentDisplay || agent?.legalName"
                                                   #selectedReferrer="ngModel"
                                                   (change)="agentChanged()">
                </cb-sellingagents-input-find-cards>
                <cb-select label="Business Entity"
                           name="businessEntityId"
                           [(ngModel)]="mappedItem.businessEntityId"
                           [options]="businessEntities">
                </cb-select>
                <cb-select label="HL Coordinator"
                           name="houseAndLandCoordinatorId"
                           [required]="true"
                           [(ngModel)]="mappedItem.houseAndLandCoordinatorId"
                           [options]="houseAndLandConsults">
                </cb-select>
            </div>
            <div class="cb-margin-right-2x flex-col flex-33">
                <h3 class="mat-headline-6"
                    style="margin-bottom: 0">Key Dates</h3>
                <div style="position: relative"
                     class="cb-margin-bottom-3x">
                    <mat-divider></mat-divider>
                </div>
                <cb-datepicker label="Tender Due Date"
                               name="followUpFrom"
                               *ngIf="
                        mappedItem.type !==
                        PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand
                    "
                               [(ngModel)]="mappedItem.tenderDueDate"
                               pickerType="calendar">
                </cb-datepicker>
                <cb-datepicker label="Project Release Due Date"
                               name="dueDate"
                               [required]="true"
                               [(ngModel)]="mappedItem.dueDate"
                               pickerType="calendar">
                </cb-datepicker>
                <cb-select label="Land Title Status"
                           name="landTitleStatus"
                           [required]="true"
                           (change)="landTitleStatusChanged()"
                           [(ngModel)]="mappedItem.landTitleStatus"
                           [options]="LAND_TITLE_STATUS_ENUM.toLookup()">
                </cb-select>
                <cb-checkbox label="Lots Have Title"
                             name="lotsHaveTitle"
                             [disabled]="
                        mappedItem.landTitleStatus !==
                        LAND_TITLE_STATUS_ENUM.TitleUploaded
                    "
                             [(ngModel)]="mappedItem.lotsHaveTitle">
                </cb-checkbox>
                <cb-datepicker label="Lot Title Date"
                               name="lotTitleDate"
                               [required]="mappedItem.lotsHaveTitle"
                               [(ngModel)]="mappedItem.lotTitleDate"
                               pickerType="calendar">
                </cb-datepicker>
                <cb-checkbox label="Lot Title Date Confirmed"
                             name="lotTitleDateConfirmed"
                             [(ngModel)]="mappedItem.lotTitleDateConfirmed"
                             *ngIf="!mappedItem.lotsHaveTitle">
                </cb-checkbox>
                <h3 class="mat-headline-6"
                    style="margin-bottom: 0">Insurance</h3>
                <div style="position: relative"
                     class="cb-margin-bottom-3x">
                    <mat-divider></mat-divider>
                </div>
                <cb-input name="insuranceInterestedParty"
                          label="Insurance Interested Party"
                          [maxlength]="150"
                          [(ngModel)]="mappedItem.insuranceInterestedParty">
                </cb-input>
                <cb-input name="insuranceInterestedPartyEmail"
                          label="Insurance Interested Party Email"
                          [maxlength]="150"
                          type="email"
                          [(ngModel)]="mappedItem.insuranceInterestedPartyEmail">
                </cb-input>
                <label class="cb-margin-right-2x cb-margin-bottom required">Contract Works Insurance will be maintained
                    at</label>
                <mat-radio-group name="insuranceLevelRadio"
                                 class="cb-margin-bottom flex-col"
                                 [(ngModel)]="insuranceLevel"
                                 [required]="true"
                                 (change)="insuranceLevelChanged()">
                    <mat-radio-button value="lot"> Lot Level</mat-radio-button>
                    <mat-radio-button value="project">
                        Project Level
                    </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="insuranceLevel == 'project'">
                    <cb-checkbox label="Insurance is in Place"
                                 name="insuranceInPlace"
                                 [(ngModel)]="mappedItem.insurance.isInPlace">
                    </cb-checkbox>
                    <div *ngIf="mappedItem.insurance.isInPlace">
                        <cb-datepicker label="Insurance Start Date"
                                       name="start"
                                       [(ngModel)]="mappedItem.insurance.start"
                                       pickerType="calendar"
                                       *ngIf="mappedItem.insurance.end">
                        </cb-datepicker>
                        <cb-datepicker label="Insurance End Date"
                                       name="end"
                                       [(ngModel)]="mappedItem.insurance.end"
                                       pickerType="calendar"
                                       *ngIf="mappedItem.insurance.end">
                        </cb-datepicker>
                    </div>
                </div>
            </div>
            <div class="flex-col flex-33"
                 *ngIf="
                    mappedItem.type !==
                    PROJECT_RELEASE_STAGE_TYPE_ENUM.DesignAndBuild
                ">
                <h3 class="mat-headline-6"
                    style="margin-bottom: 0">Marketing</h3>
                <div style="position: relative"
                     class="cb-margin-bottom-3x">
                    <mat-divider></mat-divider>
                </div>
                <cb-select label="Marketing Type"
                           name="marketingType"
                           [required]="true"
                           [(ngModel)]="mappedItem.marketingType"
                           [options]="MARKETING_TYPE_ENUM.toLookup()">
                </cb-select>
                <cb-datepicker label="Market Release Date"
                               name="marketReleaseDateObject"
                               [required]="true"
                               [(ngModel)]="mappedItem.marketReleaseDate"
                               pickerType="calendar">
                </cb-datepicker>
                <cb-select label="Showhome Level"
                           name="showHomeLevel"
                           [required]="true"
                           [(ngModel)]="mappedItem.showHomeLevel"
                           [options]="SHOW_HOME_LEVEL_ENUM.toLookup()">
                </cb-select>
                <cb-datepicker label="Showhome Completion Date"
                               name="showHomeDate"
                               [(ngModel)]="mappedItem.showHomeCompletionDate"
                               pickerType="calendar"
                               [required]="true"
                               *ngIf="mappedItem.isShowHomeSelected">
                </cb-datepicker>
            </div>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="getDisabled(form.form) || saveInProgress"
                (cbClick)="save()">
            {{ !saveInProgress ? "Save Release" : "Saving" }}
        </button>
        <button mat-raised-button
                (click)="cancel(form.dirty)">
            Cancel
        </button>
    </div>
</cb-dialog>
