<div class="cb-margin">
    <mat-card class="cb-margin-bottom flex-col">
        <mat-card-content>
            <form #form="ngForm">
                <div class="start-center flex-row flex">
                    <mat-icon>search</mat-icon>
                    <cb-input type="text"
                              label="Search"
                              [(ngModel)]="searchFilters.data.query"
                              name="search"
                              class="cb-margin-left cb-margin-right-2x flex">
                    </cb-input>
                    <cb-select label="Assigned To"
                               name="userId"
                               *ngIf="permissions.canSeeAll()"
                               [(ngModel)]="searchFilters.data.userId"
                               [options]="teamUsersOptions$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select label="Order"
                               name="resultOrders"
                               [(ngModel)]="searchFilters.data.order"
                               [options]="resultOrders$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select class="cb-margin-right"
                               label="Direction"
                               [(ngModel)]="searchFilters.data.direction"
                               name="direction"
                               [options]="resultDirections$ | async">
                    </cb-select>
                </div>
            </form>
            <cb-table *ngIf="searchFiltersLoaded && searchEnabled"
                      class="cb-margin-top flex"
                      label="Tasks"
                      maxHeightOffset="280"
                      [loaded]="loaded">
                <thead cbTableHead>
                <tr>
                    <td>Subject</td>
                    <td>Comments</td>
                    <td>Due Date</td>
                    <td>Assigned To</td>
                    <td></td>
                </tr>
                </thead>
                <tbody cbTableBody>
                <tr *ngFor="let task of (sortedResult$ | async)">
                    <cb-td-text [value]="task.subject"></cb-td-text>
                    <cb-td-text [value]="task.comments"></cb-td-text>
                    <cb-td-date [value]="task.dueDate"></cb-td-date>
                    <cb-td-text [value]="task.assignedToName"></cb-td-text>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <cb-button (clicked)="viewTask(task)"
                                   icon="pageview">
                        </cb-button>
                    </td>
                </tr>
                </tbody>
            </cb-table>
        </mat-card-content>
    </mat-card>
</div>
