<cb-dialog dialogHeading="Rate Variations">
    <div class="rateVariations fixed-header-table">
        <table>
            <thead>
                <tr>
                    <td></td>
                    <td>
                        <h4>Imported</h4>
                    </td>
                    <td>
                        <h4>Catalogue</h4>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let variation of (rateVariations$ | async)">
                    <td>
                        <h4>{{variation.name}}</h4>
                    </td>
                    <td>
                        <cb-button [color]="variation.priceOption == 1 ? 'accent' : 'white'"
                                   (clicked)="variation.priceOption = 1">
                            {{variation.takeOffRate | cbCurrency | cbDasher}}
                        </cb-button>
                    </td>
                    <td>
                        <cb-button [color]="variation.priceOption == 2 ? 'accent' : 'white'"
                                   [disabled]="variation.quoteLineId != undefined"
                                   (clicked)="variation.priceOption = 2">
                            {{variation.catalogueRate | cbCurrency | cbDasher}}
                        </cb-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div actions>
        <cb-button (clicked)="doneClicked()">
            Done
        </cb-button>
    </div>
</cb-dialog>
