import {Component, Inject, Input, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { IBundleTemplateMappedItem } from '@app/logic/bundle-templates/interfaces/i.bundle-template.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { BundleTemplatesLogicService } from '@app/logic/bundle-templates';
import { IBundleTemplatesLogicService } from '@app/logic/bundle-templates/interfaces/i.bundle-templates.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BundleTemplateMappedItem } from '@app/logic/bundle-templates/bundle-template.mapped';
import { SelectItemsDialogComponent } from '@app/shared/components/forms/select-items-dialog/select-items-dialog.component';
import { BundleTemplateItemDialogComponent } from '../bundle-template-item-dialog/bundle-template-item-dialog.component';
import { SelectItemsService } from '@app/shared/components/forms/select-items-dialog/select-items-service';
import {
    IBundleTemplateDto,
    IBundleTemplateItemDto,
} from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: BundleTemplateMappedItem;
}

@Component({
    selector: 'cb-bundle-template-dialog',
    templateUrl: './bundle-template-dialog.component.html',
    styleUrls: ['./bundle-template-dialog.component.scss'],
    providers: [BundleTemplatesLogicService, SelectItemsService]
})
export class BundleTemplateDialogComponent extends BaseDialogFormViewDirective<IBundleTemplateDto, IBundleTemplateMappedItem, IBundleTemplatesLogicService> {
    @ViewChild(SelectItemsDialogComponent, {}) public selectItemsDialog: SelectItemsDialogComponent<IBundleTemplateItemDto>;

    public mappedItem: BundleTemplateMappedItem;
    public readonly columns = [
        {valueProp: 'name', type: 'text'},
        {valueProp: 'categoryPath', type: 'text'},
        {valueProp: 'isRequired', type: 'checkbox'},
    ];
    public readonly columnsNames: string[] = ['Name', 'Category', 'Required', '']

    public newEmptyItem = {
        isLocked: false,
        isRequired: false
    };

    constructor(
        public readonly toastService: ToastService,
        public readonly selectItemsService: SelectItemsService,
        public readonly dialogRef: MatDialogRef<BundleTemplateDialogComponent>,
        public readonly dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.selectItemsService.dialogComponent = BundleTemplateItemDialogComponent;
        this.selectItemsService.dialogHeading = 'Bundle Template Item';
        if (!this.mappedItem.bundleItems) {
            this.mappedItem.bundleItems = [];
        }
        this.mappedItem.bundleItems.push({} as IBundleTemplateItemDto);
    }
}
