import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {DesignSchemesLogicService} from '@app/logic/design-schemes';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {BlockingDialogComponent} from '@app/shared/components/dialog/blocking-dialog/blocking-dialog.component';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {DESIGN_SCHEME_STATUS_ENUM, DesignSchemeStatusEnumId} from '@classictechsolutions/hubapi-transpiled-enums';
import {IEnumLookup} from '@classictechsolutions/typescriptenums';
import {filter, forEach} from 'lodash';
import {ICustomProjectReleaseLotDesignSummaryDto} from '../../release-lots-design.component';

interface IData {
    lots: ICustomProjectReleaseLotDesignSummaryDto[];
}

@Component({
    selector: 'cb-request-scheme-dialog',
    templateUrl: './request-schemes-dialog.component.html',
    styleUrls: ['./request-schemes-dialog.component.scss']
})
export class RequestSchemesDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '1000px';

    public blockRef: MatDialogRef<BlockingDialogComponent, any>;
    public lots: ICustomProjectReleaseLotDesignSummaryDto[];
    public loaded = false;
    public filteredLots: ICustomProjectReleaseLotDesignSummaryDto[];
    private itemsToRequestDesignSchemeOn = 0;
    public performingSave = false;
    public DESIGN_SCHEME_STATUS_ENUM = DESIGN_SCHEME_STATUS_ENUM;
    public designSchemeStatuses = DESIGN_SCHEME_STATUS_ENUM.toLookup().unshift({ id: undefined, label: 'Any' } as IEnumLookup<DesignSchemeStatusEnumId>);
    constructor(
        public readonly dialogRef: MatDialogRef<RequestSchemesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        private readonly toastService: ToastService,
        private readonly designSchemeLogicService: DesignSchemesLogicService,
        private readonly cbDialog: CbDialogService,
    ) {
        super(dialogRef, toastService);
        this.lots = data.lots;
    }

    public ngOnInit(): void {
        this.filterLots();
        this.loaded = true;
    }

    public requestDesignSchemes(): void {
        this.blockRef = this.cbDialog.block('Requesting Design Schemes...');
        this.performingSave = true;
        this.itemsToRequestDesignSchemeOn = 0;

        forEach(this.filteredLots, (item: ICustomProjectReleaseLotDesignSummaryDto) => {
            if (item.$shouldRequestDesignScheme) {
                this.itemsToRequestDesignSchemeOn += 1;
                this.designSchemeLogicService.requestDesignScheme(item.id as number).subOnce(() => {
                    this.handleRequestedDesignSchemeOnItem();
                });
            }
        });
    }

    public clicked(lot: ICustomProjectReleaseLotDesignSummaryDto, form: UntypedFormGroup): void {
        lot.$shouldRequestDesignScheme = !lot.$shouldRequestDesignScheme;
        form.markAsDirty();
    }

    private filterLots(): void {
        this.filteredLots = filter(this.lots, (lot) => {
            if (this.canRequestDesignScheme(lot)) {
                lot.$shouldRequestDesignScheme = false;
            }
            return this.canRequestDesignScheme(lot);
        });
    }

    private readonly handleRequestedDesignSchemeOnItem = (): void => {
        this.itemsToRequestDesignSchemeOn -= 1;

        if (this.itemsToRequestDesignSchemeOn === 0) {
            this.performingSave = false;
            this.toastService.saveSuccess();
            this.dialogRef.close(true);
            this.blockRef.close();
        }
    };

    private hasSpecTemplate(lot: ICustomProjectReleaseLotDesignSummaryDto): boolean {
        return !!lot.specTemplateId;
    }

    private canRequestDesignScheme(lot: ICustomProjectReleaseLotDesignSummaryDto): boolean {
        return this.hasSpecTemplate(lot) && !lot.hasClientSale && !lot.hasIncompletedDesignSchemes;
    }

}
