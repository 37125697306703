<cb-dialog dialogHeading="Finalise Pre-Consent">
    <form #finalisePreConsentPlanForm="ngForm">
        <div class="flex-col flex">
            <div class="flex-row flex">
                <cb-display-value-new class="flex"
                                      label="Lot #"
                                      [value]="mappedItem?.lotNumber.toString()">
                </cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Job #"
                                      [value]="jobNumber">
                </cb-display-value-new>
            </div>
            <cb-select name="designComplexity"
                       label="Design Complexity"
                       [options]="designComplexities"
                       [required]="true"
                       [(ngModel)]="mappedItem.designComplexity.id">
            </cb-select>
            <cb-display-value-new label="Approver"
                                  [value]="mappedItem.createdByName">
            </cb-display-value-new>
        </div>
    </form>
    <div actions>
        <button color="primary"
                mat-raised-button
                [disabled]="finaliseInProgress || finalisePreConsentPlanForm.invalid"
                (cbClick)="finalisePreConsentPlan()">
            Finalise Pre-Consent</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
