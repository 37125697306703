import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { ClientAccountSearchService } from '@app/core/services/search/client-account-search.service';
import { ClientAccountsInputFindDirective } from './client-accounts-input-find.directive';
import { ClientAccountsInputFindDialogCardsComponent } from '../../dialog/extensions/client-accounts-input-find-dialogs/client-accounts-input-find-dialog-cards.component';

@Component({
    selector: 'cb-client-accounts-input-find-cards',
    templateUrl: '../../base-input-find.directive.html',
    styleUrls: ['../../base-input-find.directive.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(ClientAccountsInputFindCardsComponent),
        ClientAccountSearchService,
        provideParentForm()
    ]
})
export class ClientAccountsInputFindCardsComponent extends ClientAccountsInputFindDirective {
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public searchService: ClientAccountSearchService) {
        super(
            dialogRef,
            router,
            searchService
        );
        this._myDialog = ClientAccountsInputFindDialogCardsComponent;
    }
}
