import { Component, OnInit, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IProductDto } from '@app/logic/products/interfaces/i.product.dto';
import { IProductMappedItem, ProductLogicService } from '@app/logic/products';
import { IProductLogicService } from '@app/logic/products/interfaces/i.product.logic.service';
import { FormMode } from '@app/shared/enums/form';
import { OFFERING_STATUS_ENUM, OFFERING_UNAVAILABLE_REASON_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';

interface IData {
    mappedItem: IProductMappedItem;
}

@Component({
    templateUrl: './manage-availability-dialog.component.html',
    styleUrls: ['./manage-availability-dialog.component.scss'],
    providers: [
        ProductLogicService
    ]
})
export class ManageAvailabilityDialogComponent
    extends BaseDialogFormViewDirective<IProductDto, IProductMappedItem, IProductLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '600px';

    public mappedItem: IProductMappedItem;
    public offeringStatus = OFFERING_STATUS_ENUM.toLookup();
    public unavailableReasons = OFFERING_UNAVAILABLE_REASON_ENUM.toLookup();
    public selectedProduct: IProductDto;
    public productSearchText: string;
    public OFFERING_STATUS_ENUM = OFFERING_STATUS_ENUM;

    constructor(
        public readonly dialogRef: MatDialogRef<ManageAvailabilityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        protected readonly productLogicService: ProductLogicService
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
    }

    public ngOnInit(): void {
        this.formMode = FormMode.Add;
        if (this.mappedItem.replacementOfferingId) {
            this.productLogicService.$getItem(this.mappedItem.replacementOfferingId).subOnce(product => {
                this.selectedProduct = product;
            });
        }
    }

    public productSelected(value: IProductDto | void): void {
        if (value) {
            this.selectedProduct = value;
        }
    }

    public saveMethod(): Observable<any> {
        if (this.selectedProduct && this.mappedItem.replacementOfferingId !== this.selectedProduct.id) {
            this.mappedItem.replacementOfferingId = this.selectedProduct.id;
        }
        return this.mappedItem.$save();
    }

    public isProductRequired(): boolean {
        return this.mappedItem.status !== 1;
    }
}
