<cb-dialog dialogHeading="Confirm Design Scheme Details">
    <form class="flex-row layoutGap-16"
          #lotConfirmForm="ngForm">
        <div class="flex-col flex">
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Lot #"
                                      [value]="data.lotMappedItem.lotNumber"></cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Job #"
                                      [value]="data.lotMappedItem.jobNumber"></cb-display-value-new>
            </div>
            <div class="flex-col"
                 style="margin-top: 10px;">
                <cb-input type="text"
                          label="DP #"
                          name="dpNumber"
                          [(ngModel)]="mappedItem.dpNumber"
                          [required]="true"></cb-input>
                <cb-select name="buildTypeId"
                           label="Build Type"
                           [(ngModel)]="mappedItem.buildTypeId"
                           [options]="buildTypes"
                           [required]="true"></cb-select>
                <cb-select name="standardPlanId"
                           label="Standard Plan"
                           [(ngModel)]="mappedItem.standardPlanId"
                           [options]="standardPlans"></cb-select>
                <cb-select name="designComplexity"
                           label="Design Complexity"
                           [(ngModel)]="mappedItem.designComplexity.id"
                           [options]="designComplexities"
                           [required]="true"></cb-select>
                <cb-display-value-new label="Approver"
                                      [value]="mappedItem.approverUserName"></cb-display-value-new>
            </div>
        </div>
        <div class="flex-col flex">
            <div class="flex-row">
                <cb-input class="flex"
                          type="numeric"
                          label="Floor Area"
                          name="floorArea"
                          [(ngModel)]="mappedItem.amenities.floorArea"
                          [required]="true"></cb-input>
                <span class="mb-body-1"
                      style="margin-right: 30px;">
                    m
                    <sup>2</sup>
                </span>
                <cb-input class="flex"
                          type="numeric"
                          label="Land Area"
                          name="landArea"
                          [(ngModel)]="mappedItem.amenities.landArea"
                          [required]="true"></cb-input>
                <span class="mb-body-1">
                    m
                    <sup>2</sup>
                </span>
            </div>
            <div class="flex-row">
                <cb-input class="flex"
                          type="numeric"
                          label="Bedrooms"
                          name="noBedrooms"
                          [(ngModel)]="mappedItem.amenities.noBedrooms"
                          [required]="true"
                          style="margin-right: 30px;"></cb-input>
                <cb-input class="flex"
                          type="numeric"
                          label="Bathrooms"
                          name="noBathrooms"
                          [(ngModel)]="mappedItem.amenities.noBathrooms"
                          [required]="true"></cb-input>
            </div>
            <div class="flex-row">
                <cb-input class="flex"
                          type="numeric"
                          label="Living"
                          name="livingRooms"
                          [(ngModel)]="mappedItem.amenities.noLivingRooms"
                          [required]="true"
                          style="margin-right: 30px;"></cb-input>
                <cb-garage-select class="flex"
                                  name="garages"
                                  label="Garages"
                                  [(ngModel)]="mappedItem.amenities.noGarages"
                                  [required]="true"></cb-garage-select>
            </div>
        </div>
    </form>
    <div actions>
        <button color="primary"
                mat-raised-button
                (click)="confirmSubmitScheme()"
                [disabled]="isSaveDisabled(lotConfirmForm)">Submit Scheme</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
