import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BuildProgrammeTemplateLogicService, BuildProgrammeTemplateMappedItem, IBuildProgrammeTemplateDto } from '@app/logic/build-programme-template';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { find } from 'lodash';
import { MatDialogRef } from '@angular/material/dialog';
import { BuildProgrammeTemplateDialogComponent } from '../build-programme-template-dialog/build-programme-template-dialog.component';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-build-programme-template-side-panel',
    templateUrl: './build-programme-template-side-panel.component.html',
    styleUrls: ['./build-programme-template-side-panel.component.scss']
})
export class BuildProgrammeTemplateSidePanelComponent {
    @Input() public template: IBuildProgrammeTemplateDto;
    @Input() public canEdit: boolean;
    @Input() public isEditing$: BehaviorSubject<boolean>;
    @Input() public refresh$: BehaviorSubject<boolean>;

    @Output() public readonly saveClick = new EventEmitter();

    public collapsed$ = new BehaviorSubject<boolean>(false);

    private regions: IBusinessEntityDto[];

    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly buildProgrammeTemplateLogic: BuildProgrammeTemplateLogicService,
        private readonly businessEntitiesLogic: BusinessEntitiesLogicService
    ) {
        this.businessEntitiesLogic.$getList().subOnce(result => this.regions = result);
    }

    public getRegionsText(): string {
        const regions = [];
        if (this.template.regions?.length && this.regions?.length) {
            this.template.regions.forEach((regionId: number) => {
                const match = find(this.regions, { id: regionId });
                if (match) {
                    regions.push(match.name);
                }
            });
        }

        return regions.length ? regions.join(', ') : this.regions?.length?.toString() ? 'Nationwide' : '';
    }

    public editItemClicked(): MatDialogRef<BuildProgrammeTemplateDialogComponent> {
        const mappedItem = this.buildProgrammeTemplateLogic.$createMappedItem(this.template, BuildProgrammeTemplateMappedItem);
        const dialog = this.cbDialog.open(BuildProgrammeTemplateDialogComponent, {
            data: {
                dialogHeading: 'Edit Build Programme Template',
                mappedItem,
            },
        });
        dialog.afterClosed().subOnce((item: BuildProgrammeTemplateMappedItem) => this.refresh$.next(!this.refresh$.value));
        return dialog;
    }
}
