<cb-dialog [dialogHeading]="data.title">
    <form class="flex-col flex"
          #externalSystemFieldForm="ngForm">
        <cb-slot-autocomplete-scroller class="cb-margin-top flex flex-row"
                                       label="Schedule Item Name"
                                       name="selectedScheduleItem"
                                       style="min-width: 300px;"
                                       [(ngModel)]="selectedScheduleItem"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [shouldShowHouseAreaInLabel]="true"
                                       (optionSelected)="scheduleItemSelected($event)"
                                       [required]="true"
                                       [readonly]="isEdit$ | async">
        </cb-slot-autocomplete-scroller>
        <cb-select label="External System"
                   name="fieldTypes"
                   [required]="true"
                   [options]="externalSystems"
                   [(ngModel)]="mappedItem.externalSystemFieldTemplateItem.externalSystemId"
                   (change)="loadExternalSystemFields($event)"
                   [readonly]="isEdit$ | async">
        </cb-select>
        <cb-select label="External Field Name"
                   name="externalSystemFieldTemplateItems"
                   [required]="true"
                   labelProp="fieldName"
                   [valueProp]="WHOLE_OPTION_VALUE_PROP"
                   [options]="externalSystemFieldTemplateItems"
                   [(ngModel)]="mappedItem.externalSystemFieldTemplateItem"
                   (change)="externalFieldNameChanged()"
                   [readonly]="isEdit$ | async">
        </cb-select>
        <span *ngIf="slotExternaReferenceCombinationAlreadyExists"
              class="cb-error flex"
              style="margin-top: -8px">
            <small>
                SIM - External Reference combination already exists
            </small>
        </span>
        <mat-form-field
            *ngIf="mappedItem.externalSystemFieldTemplateItem.fieldTypeId === EXTERNAL_SYSTEM_FIELD_TYPE_ENUM.StringValue">
            <mat-label>
                External Field Value
            </mat-label>
            <input #stringFieldValue
                   type="text"
                   required
                   matInput
                   [(ngModel)]="mappedItem.fieldValue"
                   [maxlength]="mappedItem.externalSystemFieldTemplateItem.fieldLength"
                   name="stringFieldValue"/>
            <mat-hint align="end">
                {{ stringFieldValue.value.length }} /
                {{ mappedItem.externalSystemFieldTemplateItem.fieldLength }}
            </mat-hint>
        </mat-form-field>
        <mat-form-field
            *ngIf="mappedItem.externalSystemFieldTemplateItem.fieldTypeId === EXTERNAL_SYSTEM_FIELD_TYPE_ENUM.IntValue">
            <mat-label>
                External Field Value
            </mat-label>
            <input #numberFieldValue
                   type="number"
                   [pattern]="externalFieldValueRegex"
                   required
                   matInput
                   [(ngModel)]="mappedItem.fieldValue"
                   name="numberFieldValue"/>
            <mat-hint align="end">
                {{ numberFieldValue.value.length }} /
                {{ mappedItem.externalSystemFieldTemplateItem.fieldLength }}
            </mat-hint>
        </mat-form-field>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (cbClick)="save()"
                [clickLimit]="1"
                [disabled]="!externalSystemFieldForm.dirty || !externalSystemFieldForm.valid || slotExternaReferenceCombinationAlreadyExists">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
