<mat-card class="flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon mat-mdc-header-card-avatar">
            <mat-icon class="cb-primary"
                      title="Complete"
                      *ngIf="mappedItem?.contractType != LOT_CONTRACT_TYPE_ENUM.None">
                check_circle
            </mat-icon>
            <mat-icon class="cb-error"
                      title="Incomplete"
                      *ngIf="mappedItem?.contractType == LOT_CONTRACT_TYPE_ENUM.None">
                error
            </mat-icon>
        </div>
        <mat-card-title [ngClass]="!(mappedItem?.stepDesignAndBuildComplete) ? 'cb-error' : ''">
            2. Contract Type
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="center-center flex-row flex">
        <mat-icon class="cb-grey cb-margin-right">
            info
        </mat-icon>
        Contract Type needs to be selected
    </mat-card-content>
    <div *ngIf="canEdit && !mappedItem.isQualified()">
        <mat-divider class="card-divider"></mat-divider>
    </div>
    <mat-card-actions class="end-center flex-row"
                      *ngIf="canEdit && !mappedItem.isQualified()">
        <button mat-stroked-button
                color="accent"
                *ngIf="canEdit && !mappedItem.isQualified()"
                (cbClick)="chooseContractType()">
            Choose Contract Type
        </button>
    </mat-card-actions>
</mat-card>
