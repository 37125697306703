<div class="cb-margin"
     *cbWaitFor="cacheLoaded$ | async">
    <mat-card class="cb-margin-bottom flex-col">
        <mat-card-content>
            <form #form="ngForm">
                <div class="start-center flex-row flex">
                    <mat-icon>search</mat-icon>
                    <cb-input type="text"
                              label="Search"
                              [(ngModel)]="searchFiltersCache.data.query"
                              (ngModelChange)="searchFiltersChanged$.next($event)"
                              name="search"
                              class="cb-margin-left cb-margin-right-2x flex">
                    </cb-input>
                    <cb-select label="Assigned To"
                               name="userId"
                               *ngIf="designTeamPermissions.canSeeAll()"
                               [(ngModel)]="searchFiltersCache.data.userId"
                               [options]="teamUsersOptions$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select-list label="Status"
                                    name="statusId"
                                    class="cb-margin-right-2x flex"
                                    [allOptions]="workingDrawingStatuses$ | async"
                                    [multiple]="true"
                                    [(value)]="searchFiltersCache.data.statusId">
                    </cb-select-list>
                    <cb-select label="Order"
                               name="resultOrders"
                               [(ngModel)]="searchFiltersCache.data.order"
                               [options]="resultOrders$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select class="cb-margin-right"
                               label="Direction"
                               [(ngModel)]="searchFiltersCache.data.direction"
                               name="direction"
                               [options]="resultDirections$ | async">
                    </cb-select>
                    <span class="flex"></span>
                    <cb-button class="cb-margin"
                               (clicked)="openDesignTeamWorkLoadDialog()">
                        Workload
                    </cb-button>
                </div>
            </form>
            <cb-table class="cb-margin-top flex"
                      label="Working Drawings"
                      maxHeightOffset="280"
                      [infiniteScrollEnabled]="infiniteScrollEnabled"
                      [fetch]="fetchResults()"
                      [loaded]="loaded"
                      [queryUpdated]="searchFiltersChanged$"
                      [(results)]="results"
                      [(currentPage)]="currentPage">
                <thead cbTableHead>
                <tr>
                    <td>Job #</td>
                    <td>lot #</td>
                    <td>Project</td>
                    <td>Address</td>
                    <td>Client Account Name</td>
                    <td>Prepared By</td>
                    <td>Complexity</td>
                    <td>Created</td>
                    <td>Due</td>
                    <td>Status</td>
                    <td>Assigned To</td>
                    <td></td>
                </tr>
                </thead>
                <tbody cbTableBody>
                <tr *ngFor="let workingDrawing of results">
                    <cb-td-text [value]="workingDrawing.$jobNumber"></cb-td-text>
                    <cb-td-text [value]="workingDrawing.$lotNumber"></cb-td-text>
                    <cb-td-text [value]="workingDrawing.$projectName"></cb-td-text>
                    <cb-td-text [value]="getStreetAddress(workingDrawing)"></cb-td-text>
                    <cb-td-text [value]="workingDrawing.$clientAccountName"></cb-td-text>
                    <cb-td-text [value]="workingDrawing.createdByName"></cb-td-text>
                    <cb-td-text [value]="workingDrawing.$designComplexityLabel"></cb-td-text>
                    <cb-td-date [value]="workingDrawing.createdDate"></cb-td-date>
                    <cb-td-date [value]="workingDrawing.$dueBy"></cb-td-date>
                    <cb-td-text [value]="workingDrawing.statusLabel"></cb-td-text>
                    <cb-td-text [value]="workingDrawing.assignedToName"></cb-td-text>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <cb-button [menu]="menu"></cb-button>
                        <mat-menu #menu="matMenu">
                            <cb-button [isMenuItem]="true"
                                       icon="edit"
                                       menuItemLabel="Edit Working Drawing"
                                       [disabled]="!(canEdit$(workingDrawing) | async)"
                                       *ngIf="hasPermissionsToEdit$ | async"
                                       (clicked)="editWorkingDrawing(workingDrawing)">
                            </cb-button>
                            <cb-button [isMenuItem]="true"
                                       icon="pageview"
                                       menuItemLabel="View Working Drawing"
                                       (clicked)="viewWorkingDrawing(workingDrawing)">
                            </cb-button>
                        </mat-menu>
                    </td>
                </tr>
                </tbody>
            </cb-table>
        </mat-card-content>
    </mat-card>
</div>
