<div class="cb-margin flex-col flex"
     [ngClass]="{ 'mat-card': !inputFindDialogMode }">
    <mat-card-content>
        <form class="flex-col"
              #productCatalogueSearchForm="ngForm">
            <div class="flex-row space-between-center">
                <div class="flex-row start-center">
                    <cb-input type="text"
                              name="searchFilter"
                              class="cb-margin-right-2x flex"
                              label="Keywords"
                              [maxlength]="100"
                              [allowEnterKeyEvents]="true"
                              (keydown.enter)="performSearch()"
                              [(ngModel)]="userCacheService.productCatalogSearch.data.query">
                    </cb-input>
                    <button mat-icon-button
                            class="search-button cb-margin-right"
                            (click)="performSearch()"
                            title="Search"
                            [disabled]="searchIsLoading || !canPerformSearch()">
                        <mat-icon>search</mat-icon>
                    </button>
                    <cb-select label="Filter Type"
                               class="cb-margin-right-2x filter-type flex"
                               name="productSearchTypeId"
                               [(ngModel)]="userCacheService.productCatalogSearch.data.productSearchTypeId"
                               (change)="productSearchTypeChanged()"
                               [options]="productSearchType">
                    </cb-select>
                    <div class="start-start flex-row">
                        <cb-checkbox label="Active Only"
                                     #isActiveComponent
                                     (change)="toggleActiveOnly($event)"
                                     class="cb-margin-right-2x flex"
                                     name="activeOnly"
                                     [(ngModel)]="userCacheService.productCatalogSearch.data.activeProducts"
                                     [disabled]="activeOnlyIsLocked()">
                        </cb-checkbox>
                        <cb-checkbox class="flex no-wrap cb-margin-right-2x"
                                     label="Strict Search"
                                     name="StrictSearch"
                                     #isStrictSearch
                                     (change)="toggleStrictSearch($event)"
                                     style="height: 30px"
                                     [(ngModel)]="userCacheService.productCatalogSearch.data.strictSearch">Strict Search
                        </cb-checkbox>
                        <cb-checkbox class="flex no-wrap"
                                     label="Show Only Null Rated"
                                     name="showOnlyNullRated"
                                     #isOnlyNullRatedComponent
                                     (change)="toggleNullRated($event)"
                                     [(ngModel)]="userCacheItem.data.nullRated"
                                     [disabled]="nullRatedIsLocked()">
                        </cb-checkbox>
                    </div>
                </div>
                <div class="flex-row">
                    <button mat-raised-button
                            color="primary"
                            *ngIf="canExportSearchResults()"
                            (click)="exportToCsv()"
                            class="cb-margin-right-2x no-wrap">
                        Export to CSV
                    </button>
                    <div *ngIf="canCreateProduct()">
                        <button mat-raised-button
                                color="primary"
                                [matMenuTriggerFor]="actionsMenu">
                            Create new Item
                        </button>
                        <mat-menu #actionsMenu>
                            <button mat-menu-item
                                    *ngFor="let type of productTypes"
                                    (click)="openCreate(type.id, $event)">
                                {{ type.label }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="start-center flex-row"
                 *ngIf="filterChips && filterChips.length > 0">
                <mat-chip-set>
                    <mat-chip *ngFor="let chip of filterChips; let index = index;"
                              (removed)="removeChip(chip)">
                        <strong class="mat-body-2">{{ chip.text }}</strong>
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                </mat-chip-set>
            </div>
            <div class="flex-wrap flex">
                <div class="cb-margin-bottom-2x cb-margin-right-2x lt-lg-100 flex-33">
                    <cb-category-filter class="flex"
                                        (change)="setSelectedCategory($event)"
                                        *ngIf="cachedSelectedCategories"
                                        name="selectedCategories"
                                        [cachedSelectedCategories]="cachedSelectedCategories"
                                        [(ngModel)]="userCacheService.productCatalogSearch.data.selectedCategories"
                                        [restrictedCategories]="restrictedCategories"
                                        [isFetching]="isFetching$ | async">
                    </cb-category-filter>
                </div>
                <div class="lt-lg-100 flex">
                    <cb-table class="cb-margin-top"
                              label="Product Catalogue Items"
                              maxHeightOffset="313"
                              [objectColumns]="columns"
                              [loaded]="true"
                              [fetch]="fetchSearchResults()"
                              [queryUpdated]="queryUpdate"
                              [infiniteScrollEnabled]="userCacheItem?.resolved"
                              [(currentPage)]="currentPage"
                              [(results)]="searchResults"
                              (fetchInProgressChange)="isFetching$.next($event)">
                        <tbody cbTableBody>
                        <tr *ngFor="let product of searchResults">
                            <td>
                                <cb-td-icon *ngIf="product.isCompositeItemWithoutAssignedItems"
                                            svgValue="hexagon-outline"
                                            toolTip="Composite Item with no Items Assigned">
                                </cb-td-icon>
                                <cb-td-icon *ngIf="product.isCompositeItemWithAssignedItems"
                                            svgValue="hexagon-slice-6"
                                            toolTip="Composite Item with Items Assigned">
                                </cb-td-icon>
                                <cb-td-icon *ngIf="product.compositeItemId"
                                            svgValue="hexagon-slice-1"
                                            toolTip="Assigned to Composite Item">
                                </cb-td-icon>
                                <cb-td-icon *ngIf="product.hasImage"
                                            value="photo"
                                            toolTip="Has Image">
                                </cb-td-icon>
                                <cb-td-icon *ngIf="product.inSpec"
                                            value="style"
                                            toolTip="Is in Spec">
                                </cb-td-icon>
                                <cb-td-icon *ngIf="product.manualColourEntryRequired"
                                            value="format_paint"
                                            toolTip="Manual Colour Entry Required">
                                </cb-td-icon>
                                <cb-td-icon *ngIf="product.showInColourYourDreams"
                                            value="book_open_page_variant"
                                            toolTip="Show in Colour Schedule">
                                </cb-td-icon>
                            </td>
                            <td>{{ product?.code }}</td>
                            <td>{{ product?.name }}
                                <cb-display-pill *ngIf="product.isStandardProduct"
                                                 [displayPillText]="'Standard'"></cb-display-pill>
                            </td>
                            <td>{{ product?.uomDisplay }}</td>
                            <cb-td-currency [value]="product?.nationalRate"></cb-td-currency>
                            <td>{{ product.mainCategory }}</td>
                            <cb-td-checkbox [value]="product?.isActive"></cb-td-checkbox>
                            <td *ngIf="!inputFindDialogMode"
                                cbTableShrinkCol
                                cbTableStickyCol="end">
                                <button mat-icon-button
                                        matTooltip="View Product"
                                        class="cb-margin-right"
                                        (click)="viewProduct(product, $event)">
                                    <mat-icon>pageview</mat-icon>
                                </button>
                                <button mat-icon-button
                                        matTooltip="Edit Product"
                                        (click)="openEdit(product)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                            <td *ngIf="inputFindDialogMode"
                                cbTableShrinkCol
                                cbTableStickyCol="end">
                                <button mat-icon-button
                                        matTooltip="Select Product"
                                        (click)="selectProduct(product)">
                                    <mat-icon>pageview</mat-icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </cb-table>
                </div>
            </div>
        </form>
    </mat-card-content>
</div>
