import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { IEnum } from '@app/shared/interfaces/i.lookup.dto';
import { IIdAndLabelDto, IProjectDetailsDto, IProjectDocumentDto, ISkinnyUserDto, LotContractTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { BaseLogicService } from '../base/base-logic.service';
import { IProjectsLogicService } from './interfaces/i.projects.logic.service';
import { ProjectMappedItem } from './project.mapped';

@Injectable()
export class ProjectsLogicService
    extends BaseLogicService<IProjectDetailsDto, ProjectMappedItem>
    implements IProjectsLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'projects',
            ProjectMappedItem
        );
    }

    public getProjectTeamMembersByProjectRelease(projectReleaseId: number): Observable<ISkinnyUserDto[]> {
        return this.$http.get(`${this.$baseUri}/${projectReleaseId}/projectreleaseteammembers`);
    }

    public getProjectLabels(): Observable<IIdAndLabelDto[]> {
        return this.$http.get(`${this.$baseUri}/labels`);
    }

    public getProjectReleaseLabels(projectId: number): Observable<IIdAndLabelDto[]> {
        return this.$http.get(`${this.$baseUri}/${projectId}/releases/labels`);
    }

    public getProjectsReleaseLabels(projectIds: number[]): Observable<IIdAndLabelDto[]> {
        return this.$http.post<IIdAndLabelDto[]>(`${this.$baseUri}/releases/labels`, projectIds);
    }

    public getAllowedLotContractTypesForRelease(releaseStageId: number): Observable<LotContractTypeEnumId[]> {
        return this.$http.get(`releasestages/${releaseStageId}/allowedlotcontracttypes`);
    }

    public getProjectsByArea(areaId: number): Observable<IEnum[]> {
        return this.$http.get(`${this.$baseUri}/area/${areaId}`);
    }

    public $getSearchList(params: any): Observable<ISearchResult<IProjectDocumentDto>> {
        return this.$http.get<ISearchResult<IProjectDocumentDto>>(`${this.$baseUri}/search`, params);
    }

    public isProjectNameUnique(projectId: number, projectName: string): Observable<boolean> {
        return this.$http.get<boolean>(`${this.$baseUri}/${projectId}/checkname`, { name: projectName });
    }

    public updateImage(projectId: number, file: File,): Observable<IProjectDetailsDto> {
        const fd = new FormData();
        fd.append('file', file);
        return this.$http.post(`${this.$baseUri}/${projectId}/image`, fd);
    }

    public deleteImage(projectId: number): Observable<IProjectDetailsDto> {
        return this.$http.delete<IProjectDetailsDto>(`${this.$baseUri}/${projectId}/image`);
    }

}
