import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SonarTableComponent } from './sonar-table.component';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbTableModule } from 'cb-hub-lib';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';



@NgModule({
    declarations: [SonarTableComponent],
    imports: [
        RouterModule.forRoot([
            {
                component: SonarTableComponent,
                path: 'sonar-documentation-table',
            }
        ], {}),
        CommonModule,
        CbHubTableModule,
        CbTableModule,
        MatButtonModule,
        MatCardModule,
    ]
})
export class SonarTableModule { }
