<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x flex-col flex">
    <div class="start-center flex-row layoutGap-16">
        <h3 class="mat-headline-6 flex">
            External System Fields
        </h3>
        <button mat-raised-button
                (click)="newItemClicked();"
                [disabled]="!canEdit()"
                color="primary">
            Add External Field
        </button>
        <button mat-raised-button
                (cbClick)="saveOrder()"
                [throttleTime]="2000"
                [disabled]="!sortOrderChanged || !canEdit()"
                color="primary">
            Save Field Order
        </button>
    </div>
    <mat-tab-group class="cb-margin-top" mat-stretch-tabs="false">
        <mat-tab label="CostX">
            <ng-template matTabContent>
                <cb-table class="cb-margin-top"
                          label="External System Fields"
                          [stringColumns]="displayedColumns"
                          [loaded]="searchResultsLoaded"
                          [minWidth]="1000"
                          [maxHeightOffset]="243">
                    <tbody cbTableBody
                           [dragula]="EXTERNAL_SYSTEM_FIELDS"
                           [(dragulaModel)]="dragulaModel">
                        <tr *ngFor="let ele of searchResults">
                            <td>
                                {{ele.fieldName}}
                            </td>
                            <cb-td-enum [value]="ele.fieldTypeId"
                                        [enum]="EXTERNAL_SYSTEM_FIELD_TYPE_ENUM">
                            </cb-td-enum>
                            <td>
                                {{ele.fieldLength}}
                            </td>
                            <cb-td-checkbox [value]="ele.isActive"></cb-td-checkbox>
                            <td class="layoutGap-10"
                                cbTableShrinkCol
                                cbTableStickyCol="end">
                                <button mat-icon-button
                                        (click)="editItemClickedForDto(ele);"
                                        [disabled]="!canEdit()">
                                    <mat-icon>
                                        edit
                                    </mat-icon>
                                </button>
                                <button mat-icon-button
                                        (click)="deleteItem(ele);"
                                        [disabled]="!canEdit()">
                                    <mat-icon>
                                        delete
                                    </mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </cb-table>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
