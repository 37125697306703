<div class="flex cb-margin-left-3x cb-margin-right-3x layoutGap-30 cb-margin-bottom-2x">
    <mat-slide-toggle [checked]="true">Show Unpublished</mat-slide-toggle>
    <mat-slide-toggle class="cb-margin-top cb-margin-right-2x">View All Stages</mat-slide-toggle>
</div>

<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x flex-col layoutGap-col-10">
    <mat-radio-button class="direction-radio-button layoutGap-30">Credit</mat-radio-button>

    <mat-radio-group name="closetask-outcome-followup" class="layoutGap-30">
        <mat-radio-button>Approved</mat-radio-button>
        <mat-radio-button>Rejected</mat-radio-button>
    </mat-radio-group>
    <mat-radio-button>Design</mat-radio-button>
</div>

<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x flex-col layoutGap-col-10">
    <form>
        <cb-checkbox label="Active" name="isLocked" [(ngModel)]="isLocked"></cb-checkbox>
    </form>
    <mat-checkbox name="showDeleted">Show Deleted</mat-checkbox>
    <mat-checkbox name="disabled" disabled="true">Disabled</mat-checkbox>
</div>

<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x">
    <button mat-raised-button color="primary" disabled="true">Add Widget</button>

    <button mat-icon-button>
        <mat-icon>filter_list</mat-icon>
    </button>

    <div class="start-center flex-row layoutGap-20 flex">
        <h1 class="mat-headline-6">Name</h1>
        <mat-icon color="accent" matTooltip="Published">check_circle</mat-icon>
    </div>

    <button [matMenuTriggerFor]="menu"
            mat-icon-button
            matTooltip="Edit"
            matTooltipPosition="right"
            [disabled]="false">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item
        >
            <mat-icon>help_outline</mat-icon>
            <span> View Details</span>
        </button>
        <button mat-menu-item
        >
            <mat-icon>rate_review</mat-icon>
            <span>Review Document</span>
        </button>
    </mat-menu>
</div>

<mat-list class="flex">
    <mat-list-item>
        <div class="flex flex-row start-center">
            <mat-icon matListItemIcon>info_outline</mat-icon>
            <div class="flex-col flex">
                <h3 matListItemTitle>mat List Item Title</h3>
                <h2 matListItemLine>matListItemTitle</h2>
                <p class="mat-caption cb-grey" matListItemLine>Received at 11 Oct 2024</p>
            </div>
            <button mat-mini-fab color="accent">
                <mat-icon>markunread</mat-icon>
            </button>
        </div>
    </mat-list-item>
</mat-list>


<mat-tab-group cbTabUrlParam="cbTabRouteId"
               mat-stretch-tabs="false"
               class="cs-indent-right-tabs flex">
    <mat-tab label="Build"
             cbTabRouteId="build"
             cbNestedTabLevels="1">
        <ng-template matTabContent>
            <cb-lot-build-area></cb-lot-build-area>
        </ng-template>
    </mat-tab>
</mat-tab-group>


<mat-tab-group class="flex"
               mat-stretch-tabs="false">
    <mat-tab label="Details"
             cbTabRouteId="details">
    </mat-tab>
    <mat-tab label="Release Stages"
             cbTabRouteId="release-stages">
    </mat-tab>
</mat-tab-group>

<!--Select variations-->
<div class="layoutGap-20 cb-margin-2x start-center flex-row">
    <form>
        <cb-select label="Filter By"
                   name="lotFilterType"
                   class="flex"
                   [options]="options">
        </cb-select>
    </form>

    <mat-form-field>
        <mat-label>Region</mat-label>
        <mat-select>
            <mat-option *ngFor="let option of options"
                        [value]="option.id">
                {{ option.label }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<!--Input variations-->
<!--Input text-->
<div class="layoutGap-20 cb-margin-2x start-center flex-row">
    <form>
        <cb-input class="input-contact flex"
                  type="text"
                  label="Type in First Name, Last Name or Phone Number to Search">
        </cb-input>
    </form>

    <mat-form-field>
        <mat-label>Document Type</mat-label>
        <input matInput
               type="text"
               name="type"/>
    </mat-form-field>
</div>

<!--Input textarea-->
<div class="layoutGap-20 cb-margin-2x start-center flex-row">
    <form>
        <cb-text-area label="Reason for Rejection"
                      name="reasonRejection">
        </cb-text-area>
    </form>

    <mat-form-field>
        <mat-label>Plan Description</mat-label>
        <textarea matInput
                  type="text"
                  name="description">
                </textarea>
    </mat-form-field>
</div>

<div class="layoutGap-20 cb-margin-2x start-center flex-row">
    <!--Input datepicker-->
    <form>
        <cb-datepicker label="Actual / Expected Title Date"
                       name="expectedTitleDate"
                       [pickerType]="'calendar'">
        </cb-datepicker>
    </form>

    <!--Input autocomplete-->
    <form>
        <cb-user-autocomplete-scroller label="Build Overhead Cost"
                                  name="buildOverheadCost"
                                  [matchProps]="['name']"
                                  [tag]="BUILDING_CONSULTANT_TAG">
        </cb-user-autocomplete-scroller>
    </form>
</div>
