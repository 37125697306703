import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes/interfaces/i.design-scheme.mapped';
import { NoteDialogComponent } from '@app/shared/components/notes/note-dialog/note-dialog.component';
import { FormMode } from '@app/shared/enums/form';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import {
    DesignSchemeLineStatusEnumId,
    DESIGN_SCHEME_LINE_STATUS_ENUM,
    IDesignRequirementItemDto,
} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-design-scheme-item-dialog',
    templateUrl: './design-scheme-item-dialog.component.html',
    styleUrls: ['./design-scheme-item-dialog.component.scss'],
})
export class DesignSchemeItemDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '400px';
    public FormMode = FormMode;

    public tempItem: IDesignRequirementItemDto;

    public DESIGN_SCHEME_LINE_STATUS_ENUM = DESIGN_SCHEME_LINE_STATUS_ENUM;
    public lineStatus: DesignSchemeLineStatusEnumId;

    constructor(
        public readonly dialogRef: MatDialogRef<NoteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData
    ) {
        this.lineStatus = this.data.item.designSchemeLineStatus;
    }

    public ngOnInit(): void {
        this.tempItem = cloneDeepSafe(this.data.item);
    }

    public addToList(): void {
        this.tempItem.designSchemeLineStatus = this.lineStatus;

        if (this.data.formMode === FormMode.Add) {
            this.data.mappedItem.designRequirementItems.push(this.tempItem);
        } else {
            const index = this.data.mappedItem.designRequirementItems.findIndex(
                (item) => item.itemId === this.data.item.itemId
            );

            this.data.mappedItem.designRequirementItems.splice(index, 1, this.tempItem);
        }

        this.dialogRef.close(this.tempItem);
    }

    public saveItem(): void {
        this.addToList();
        this.data.mappedItem.$save().subOnce(() => {
        });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public checkStatus(lineStatus): void {
        switch (lineStatus) {
            case DESIGN_SCHEME_LINE_STATUS_ENUM.None: {
                this.lineStatus = DESIGN_SCHEME_LINE_STATUS_ENUM.Completed;
                break;
            }

            case DESIGN_SCHEME_LINE_STATUS_ENUM.Completed: {
                this.lineStatus = DESIGN_SCHEME_LINE_STATUS_ENUM.NotStarted;
                break;
            }
            case DESIGN_SCHEME_LINE_STATUS_ENUM.NotStarted: {
                this.lineStatus = DESIGN_SCHEME_LINE_STATUS_ENUM.NotDoing;
                break;
            }
            case DESIGN_SCHEME_LINE_STATUS_ENUM.NotDoing: {
                this.lineStatus = DESIGN_SCHEME_LINE_STATUS_ENUM.Completed;
                break;
            }
        }
    }
}

interface IData {
    item: IDesignRequirementItemDto;
    mappedItem: IDesignSchemeMappedItem;
    formMode: FormMode;
    addAndSave: boolean;
    selectedTabIndex: number;
}
