import { ICloseTaskDto } from './../../../../logic/tasks/interfaces/i.close-task.dto';
import { TaskDialogComponent } from './../task-dialog/task-dialog.component';
import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ITaskMappedItem } from '@app/logic/tasks';
import { MatDialog } from '@angular/material/dialog';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { CloseTaskDialogComponent } from '../close-task-dialog/close-task-dialog.component';
import { ITaskPermissions } from '@app/core/permissions/base.permissions';
import { taskTypeClassMap } from '@app/logic/task-outcomes/task.constants';
import moment from 'moment';


@Component({
    selector: 'cb-task-card',
    templateUrl: './task-card.component.html',
    styleUrls: ['./task-card.component.scss']
})
export class TaskCardComponent implements OnChanges {

    @Input() public task: ITaskMappedItem;
    @Input() public edit: boolean;
    @Input() public completableButNotEditable: boolean;
    @Input() public permissions: ITaskPermissions;
    @Input() public readonly allowFollowUp: boolean;

    @Output() public taskClosed = new EventEmitter<ICloseTaskDto>();

    public readonly canComplete = new ComputedProperty(
        () => this.permissions
            && this.permissions.canCompleteTask()
            && !this.task.isClosed
            && (this.edit || this.completableButNotEditable)
            && this.currentUser.guid === this.task.assignedToId
    );

    public readonly canEdit = new ComputedProperty(
        () => this.task
            && this.edit
            && this.task.isActive
            && !this.task.isClosed
            && this.permissions
            && this.permissions.canEditTask()
            && (this.currentUser.guid === this.task.assignedToId || this.currentUser.teams.some(x => x === this.task.assignedToTeamKey))
    );

    public readonly taskRelativeDate = new ComputedProperty(() => this.task && moment(this.task.isClosed ? this.task.closedDate : this.task.dueDate).fromNow());

    public readonly taskStatusClass = new ComputedProperty(() => {
        if (!this.task) {
            return '';
        }
        if (this.task.isClosed) {
            return 'complete';
        }
        const dueDate = new Date(this.task.dueDate);
        const isOverdue = dueDate.getTime() < Date.now();
        if (isOverdue) {
            return 'overdue';
        }
        const isDueSoon = dueDate.getTime() < (Date.now() + 1000 * 3600 * 24 * 5);
        if (isDueSoon) {
            return 'dueSoon';
        }
        return '';
    });

    public readonly taskIconName = new ComputedProperty(() => this.task && taskTypeClassMap[this.task.taskType]);

    constructor(
        public readonly matDialog: MatDialog,
        public readonly currentUser: CurrentUserService
    ) { }

    public ngOnChanges(): void {
        this.canComplete.recompute();
        this.canEdit.recompute();
        this.taskRelativeDate.recompute();
        this.taskStatusClass.recompute();
        this.taskIconName.recompute();
    }

    public openCompleteDialog(): void {
        this.matDialog
            .open(
                CloseTaskDialogComponent,
                {
                    data: {
                        task: this.task.$clone(),
                        allowFollowUp: this.allowFollowUp
                    },
                    panelClass: 'cb-dialog-container',
                    minWidth: 400
                })
            .afterClosed()
            .subscribe({
                next: (result: ICloseTaskDto | boolean) => {
                    if (!result) {
                        return;
                    }
                    this.taskClosed.emit(result as ICloseTaskDto);
                    this.canComplete.recompute();
                    this.canEdit.recompute();
                }
            });
    }

    public openEditDialog(): void {
        this.matDialog
            .open(
                TaskDialogComponent,
                {
                    data: { task: this.task.$clone() },
                    panelClass: 'cb-dialog-container',
                    minWidth: 400
                })
            .afterClosed()
            .subscribe({
                next: () => {
                    this.canComplete.recompute();
                    this.canEdit.recompute();
                }
            });
    }

}
