import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import { IProductCentreLogicService } from '@app/logic/product-centre/interfaces/i.product-centre.logic.service';
import { IProductCentreMappedItem } from '@app/logic/product-centre/interfaces/i.product-centre.mapped';
import { IProductCentreDocumentDto, IProductCentreTierDocumentDto, ProductTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';

export class ProductCentreMappedItem
    extends BaseMappedItem<IProductCentreDocumentDto, IProductCentreMappedItem, IProductCentreLogicService>
    implements IProductCentreMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public code: string;
    @DtoProp public name: string;
    @DtoProp public uomDisplay: string;
    @DtoProp public mainCategory: string;
    @DtoProp public nationalRate: number;
    @DtoProp public categoryTree: number[];
    @DtoProp public skus: string;
    @DtoProp public manualColourEntryRequired: boolean;
    @DtoProp public showInColourYourDreams: boolean;
    @DtoProp public categoryPath: string[];
    @DtoProp public regions: string[];
    @DtoProp public hasImage: boolean;
    @DtoProp public inSpec: boolean;
    @DtoProp public isStandardProduct: boolean;
    @DtoProp public dynamicFilters: Record<string, string>;
    @DtoProp public tiers: IProductCentreTierDocumentDto[];
    @DtoProp public imageUrl: string;
    @DtoProp public isActive: boolean;
    @DtoProp public compositeItemId: number;
    @DtoProp public componentItems: string;
    @DtoProp public isCompositeItem: boolean;
    @DtoProp public tierSortOrder: number;
    @DtoProp public productType: ProductTypeEnumId;

    public unavailableStartDateObject: Date;
    public unavailableEndDateObject: Date;
    public baseImageUrl: string;
    public imageUrls: any[];

    constructor(
        sourceData: IProductCentreDocumentDto,
        logicService: IProductCentreLogicService
    ) {
        super(sourceData, logicService, ProductCentreMappedItem);
    }
}
