<div class="landing">
    <!-- Search Section -->
    <div class="search-container">
        <mat-form-field appearance="outline" class="search-bar">
            <mat-label>Search Products</mat-label>
            <input
                    matInput
                    placeholder="E.g., Shower, Cooktop"
                    (keydown.enter)="performSearch()"
                    [formControl]="filters.search"
            />
            <mat-icon matSuffix *ngIf="loading" class="spinner-icon">
                <mat-spinner [diameter]="24" [strokeWidth]="4"></mat-spinner>
            </mat-icon>
            <button mat-icon-button
                    matSuffix
                    *ngIf="!loading"
                    class="search-button"
                    (click)="performSearch()"
                    title="Search"
                    [disabled]="loading">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>

        <div class="paginator">
            <mat-paginator *ngIf="filteredProducts.length > 50"
                           [length]="filteredProducts.length"
                           [pageSize]="50"
                           (page)="paginate($event)"
                           [hidePageSize]="true">
            </mat-paginator>
        </div>
    </div>

    <div class="welcome-header" *ngIf="!searchInitialized">
        <h1>Product Selection Centre</h1>
        <p>Start by searching for a keyword...</p>
    </div>

    <!-- No Results Section -->
    <div *ngIf="!loading && searchInitialized && displayedProducts.length === 0" class="no-results">
        <h3>
            <mat-icon class="material-icons cb-error">cancel</mat-icon>&nbsp;No search results found.
        </h3>
        <p>Try adjusting your search criteria and try again.</p>
    </div>
</div>

<div class="container" *ngIf="searchInitialized">
    <!-- Filters Section -->
    <div class="filters">
        <div class="cb-padding-bottom center-center">
            <button mat-raised-button
                    color="primary"
                    class="reindex-button"
                    (click)="resetFilters()">
                Reset Filters
            </button>
        </div>

        <!-- Categories -->
        <div class="filter-group" *ngIf="products.length > 0">
            <h4 (click)="toggleCollapsedState('categories')" class="filter-heading">
                CATEGORIES
                <mat-icon>{{ collapsedFilters['categories'] ? 'expand_more' : 'expand_less' }}</mat-icon>
            </h4>
            <div *ngIf="!collapsedFilters['categories']" class="filter-options filter-options-categories">
                <mat-expansion-panel [expanded]="true" style="all: unset; padding: 0">
                    <ng-container *ngIf="slotCategories?.length; else noCategories">
                        <div class="menu-container">
                            <ng-container
                                    *ngTemplateOutlet="recursiveListTmpl; context: { $implicit: slotCategories, level: 0 }">
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-template #noCategories>
                        <p *ngIf="loadingMenu">Loading categories...</p>
                        <p *ngIf="!loadingMenu">No categories available</p>
                    </ng-template>
                </mat-expansion-panel>
                <ng-template #recursiveListTmpl let-items let-level="level">
                    <ul [ngClass]="{'menu-list': level === 0, 'submenu': level > 0}">
                        <li *ngFor="let item of items" class="menu-item-container">
                            <ng-container *ngIf="item.badgeCount > 0">
                                <div class="menu-item"
                                     [class.selected]="isSelectedMenu(item)"
                                     (click)="selectItem(item, $event)">
                                    <span>{{ item.label + ' (' + item.badgeCount + ')'}}</span>
                                    <span *ngIf="item.children?.length"
                                          class="icon-toggle"
                                          (click)="toggleCollapsedState(item.label + item.id, $event)">
                                <mat-icon>
                                    {{ collapsedFilters[item.label + item.id] ? 'expand_more' : 'expand_less' }}
                                </mat-icon>
                            </span>
                                </div>

                                <ng-container *ngIf="!collapsedFilters[item.label + item.id] && item.children?.length">
                                    <ng-container *ngTemplateOutlet="
                            recursiveListTmpl;
                            context: {
                                $implicit: item.children, level: level + 1
                            }">
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </li>
                    </ul>
                </ng-template>
            </div>
        </div>

        <!-- Tiers -->
        <div class="filter-group" *ngIf="tierRanges?.length > 0">
            <h4 (click)="toggleCollapsedState('tier')" class="filter-heading">
                TIER
                <mat-icon>{{ collapsedFilters['tier'] ? 'expand_more' : 'expand_less' }}</mat-icon>
            </h4>
            <div *ngIf="!collapsedFilters['tier']" class="filter-options">
                <div *ngFor="let tier of tierRanges">
                    <mat-checkbox
                            class="custom-checkbox"
                            [checked]="selectedTierRanges.has(tier.id)"
                            (change)="toggleTierRange(tier, $event.checked)">
                        {{ tier.label }}
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <!-- Business Entities -->
        <div class="filter-group" *ngIf="businessEntities?.length > 0">
            <h4 (click)="toggleCollapsedState('businessEntity')" class="filter-heading">
                BUSINESS ENTITIES
                <mat-icon>{{ collapsedFilters['businessEntity'] ? 'expand_more' : 'expand_less' }}</mat-icon>
            </h4>
            <div *ngIf="!collapsedFilters['businessEntity']" class="filter-options">
                <div *ngFor="let entity of businessEntities">
                    <mat-checkbox
                            class="custom-checkbox"
                            [checked]="selectedBusinessEntities.has(entity.id)"
                            (change)="toggleBusinessEntity(entity.id, $event.checked)">
                        {{ entity.name }}
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <!-- Dynamic Filters -->
        <div *ngIf="dynamicFilterOptions?.length > 0">
            <div class="filter-group" *ngFor="let filter of dynamicFilterOptions; let i = index">
                <h4 (click)="toggleCollapsedState(filter.key)" class="filter-heading">
                    {{ filter.key.toUpperCase() }}
                    <mat-icon>{{ collapsedFilters[filter.key] ? 'expand_more' : 'expand_less' }}</mat-icon>
                </h4>
                <div *ngIf="!collapsedFilters[filter.key]" class="filter-options">
                    <div *ngFor="let option of filter.values">
                        <mat-checkbox
                                class="custom-checkbox"
                                [checked]="isSelected('dynamicCategories', { key: filter.key, value: option })"
                                (change)="updateDynamicFilter(filter.key, option, $event.checked)">
                            {{ option }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <!-- Misc filter -->
        <form class="flex-col" #productCentreSearchForm="ngForm">
            <div class="filter-group">
                <h4 (click)="toggleCollapsedState('misc')" class="filter-heading">
                    MISC
                    <mat-icon>{{ collapsedFilters['misc'] ? 'expand_more' : 'expand_less' }}</mat-icon>
                </h4>
                <div *ngIf="!collapsedFilters['misc']" class="filter-options">
                    <div class="flex-row">
                        <mat-checkbox
                                class="custom-checkbox"
                                name="activeOnly"
                                [(ngModel)]="filters.activeOnly"
                                (change)="toggleActiveOnly($event.checked)">
                            Active Products Only
                        </mat-checkbox>
                    </div>
                    <div class="flex-row">
                        <cb-select label="Product Type"
                                   style="height: 45px"
                                   class="cb-margin-right-2x filter-type flex"
                                   name="productType"
                                   [(ngModel)]="filters.productType"
                                   (change)="productSearchTypeChanged()"
                                   [options]="productTypes">
                        </cb-select>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!-- Products Section -->
    <div class="products">
        <!-- Product Grid -->
        <ng-container *ngIf="!loading && displayedProducts.length > 0">
            <div class="product-grid">
                <mat-card *ngFor="let product of displayedProducts; trackBy: trackById"
                          [ngClass]="{ 'inactive-product': !product.isActive }"
                          (click)="viewProduct(product.id)">
                    <mat-card-header>
                        <mat-card-title>
                            {{ product.name }}
                        </mat-card-title>
                        <mat-card-subtitle>
                            <span>{{ product.mainCategory }}</span>
                        </mat-card-subtitle>
                    </mat-card-header>
                    <div class="image-carousel">
                        <img mat-card-image [src]="getProductImage(product)" alt="{{ product.name }}" cbHandleInvalidImage/>
                    </div>
                    <mat-card-content>
                        <div>
                            <strong>National Rate:</strong> {{ product?.nationalRate < 0 ? '$-' : product?.nationalRate | cbCurrency }}<br />
                            <span *ngIf="product.uomDisplay"><strong>UOM:</strong> {{ product.uomDisplay }}</span>
                        </div>
                        <div class="product-code">
                            {{ product.code }}&nbsp;
                            <button mat-icon-button
                                    matTooltip="Copy product code to clipboard"
                                    (click)="copyProductCodeToClipboard($event, product.code)">
                                <mat-icon svgIcon="clipboard-text-play-outline"></mat-icon>
                            </button>
                        </div>
                    </mat-card-content>
                    <mat-card-footer>
                        <div>
                            <cb-td-icon *ngIf="product.isCompositeItem && product.componentItems?.length <= 0"
                                        svgValue="hexagon-outline"
                                        toolTip="Composite Item with no Items Assigned">
                            </cb-td-icon>
                            <cb-td-icon *ngIf="product.isCompositeItem && product.componentItems?.length > 0"
                                        svgValue="hexagon-slice-6"
                                        toolTip="Composite Item with Items Assigned">
                            </cb-td-icon>
                        </div>
                        <div>
                            <ng-container *ngFor="let productTier of product.tiers; trackBy: trackById">
                                <cb-display-pill *ngIf="productTier.tier !== TIER_ENUM.None && tierMatchesBusinessEntity(productTier)"
                                                 class="cb-margin-right cb-no-padding"
                                                 [backgroundColor]="getPillColour(productTier.tier)"
                                                 [textColor]="'black'"
                                                 [fontWeight]="'normal'"
                                                 [displayPillText]="(productTier.tier | cbEnumToLabel: TIER_ENUM)">
                                </cb-display-pill>
                            </ng-container >

                            <cb-display-pill *ngIf="product.isStandardProduct"
                                             class="cb-margin-right cb-no-padding"
                                             [fontWeight]="'normal'"
                                             [displayPillText]="'Standard'"></cb-display-pill>
                        </div>
                    </mat-card-footer>
                </mat-card>
            </div>
        </ng-container>
    </div>
</div>
