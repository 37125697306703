<div class="flex-col flex">
    <h3 class="mat-headline-6 cb-margin-top">Predecessor Activities</h3>
    <div class="standardTable">
        <table>
            <thead>
                <tr>
                    <td>Lot Number</td>
                    <td>Code</td>
                    <td>Activity Name</td>
                    <td>Relationship Type</td>
                    <td>Lag Days</td>
                    <td>Locked</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let relationship of activityRelationships.predecessors">
                    <td>{{relationship.lotNumber | cbDasher}}</td>
                    <td>{{relationship.code | cbDasher}}</td>
                    <td>{{relationship.name | cbDasher}}</td>
                    <td>{{relationship.relationship | cbDasher}}</td>
                    <td>{{relationship.lagDays}}</td>
                    <td>
                        <mat-icon [svgIcon]="relationship.isLocked ? 'checkbox-marked' : 'checkbox-blank-outline'">
                        </mat-icon>
                    </td>
                    <td class="actions">
                        <button mat-icon-button
                                [matMenuTriggerFor]="actionsMenu">
                            <mat-icon svgIcon="dots-horizontal"></mat-icon>
                        </button>
                        <mat-menu #actionsMenu>
                            <button mat-menu-item
                                    [disabled]="activityRelationships?.predecessors?.length < 1"
                                    (click)="editPredecessor(relationship)">
                                <mat-icon svgIcon="pencil"></mat-icon>
                                Edit
                            </button>
                            <button mat-menu-item
                                    [disabled]="activityRelationships?.predecessors?.length < 1"
                                    (click)="clearPredecessor(relationship.id)">
                                <mat-icon svgIcon="minus-circle"></mat-icon>
                                Clear
                            </button>
                        </mat-menu>
                    </td>
                </tr>
                <tr *ngIf="activityRelationships?.predecessors?.length < 1">
                    <td colspan="6">
                        <div class="start-center flex-row">
                            <mat-icon class="cb-grey">info_circle</mat-icon>
                            <span>&nbsp;There are no Predecessors</span>
                        </div>
                    </td>
                    <td class="actions">
                        <button mat-icon-button
                                (click)="addPredecessor()">
                            <mat-icon svgIcon="plus"></mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <cb-table [loaded]="true"
              [stringColumns]="successorColumns"
              tableLabel="Successor Activities"
              label="Successors"
              class="cb-margin-top-2x">
        <tbody cbTableBody>
        <tr *ngFor="let item of activityRelationships?.descendants">
            <td>
                {{ item.lotNumber }}
            </td>
            <td>
                {{ item.code }}
            </td>
            <td>
                {{ item.name }}
            </td>
            <td>
                {{ item.relationship }}
            </td>
            <td>
                {{ item.lagDays }}
            </td>
            <td>
                <mat-icon class="cb-grey">
                    {{ item.isLocked ? 'check_box' : 'check_box_outline_blank' }}
                </mat-icon>
            </td>
        </tr>
        </tbody>
    </cb-table>
</div>
