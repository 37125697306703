import * as _ from 'lodash';

import {AGE_RANGES_ENUM, CONTACT_METHOD_ENUM, CONTACT_STATUS_ENUM, ContactMethodEnumId, SALUTATION_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {IContactMappedItem} from '@app/logic/contacts/interfaces/i.contact.mapped';
import {IEnumLookup} from '@classictechsolutions/typescriptenums';
import {provideParentForm} from '@app/shared/providers/provide-parent-form.provider';
import {Debounce} from '@app/shared/decorators/debounce.decorator';
import {MatTooltip} from '@angular/material/tooltip';
import {ContactsLogicService, IContactSearchDto} from '@app/logic/contacts';

@Component({
    selector: 'cb-contact-edit',
    templateUrl: './contact-edit.component.html',
    styleUrls: ['./contact-edit.component.scss'],
    providers: [
        provideParentForm()
    ]
})
export class ContactEditComponent implements OnInit {

    @Input() public contact: IContactMappedItem;
    @Output() public isEmailDuplicate = new EventEmitter<boolean>();

    public readonly contactStatusLookup = CONTACT_STATUS_ENUM.toLookup();
    public readonly salutationLookup = SALUTATION_ENUM.toLookup();
    public readonly ageRangeLookup = AGE_RANGES_ENUM.toLookup();
    public contactMethods: IEnumLookup<ContactMethodEnumId>[] = [];
    public availableContactMethods: IEnumLookup<ContactMethodEnumId>[];
    public CONTACT_METHOD_ENUM = CONTACT_METHOD_ENUM;

    public contactsMatching = {
        emailAddress: [] as IContactSearchDto[],
    };


    @Debounce(1000)
    public emailChanged(tooltip: MatTooltip): void {
        this.contactLogic
            .findExistingByEmailAddress(this.contact.email)
            .subOnce((results) => {
                this.contactsMatching.emailAddress = results.filter(x => x.id !== this.contact.id);
                this.showTooltip(tooltip, this.contactsMatching.emailAddress.length, 'email address');
                this.isEmailDuplicate.emit(this.contactsMatching?.emailAddress?.length > 0);
            });

        this.addRemoveContactMethod(CONTACT_METHOD_ENUM.Email);
    }

    constructor(protected readonly contactLogic: ContactsLogicService) {
    }


    public ngOnInit(): void {

        CONTACT_METHOD_ENUM.toLookup({
            transform: (results) => {
                this.availableContactMethods = _.clone(results);
                results.forEach((contactMethod) => {
                    this.addRemoveContactMethod(contactMethod.id);
                });
                return results;
            }
        });

    }

    public addRemoveContactMethod(contactMethod: ContactMethodEnumId): void {
        let method: Function;
        if (this.contactMethodHasValue(contactMethod)) {
            method = this.addContactMethod;
        } else {
            method = this.removeContactMethod;
        }
        method(contactMethod);
    }

    private readonly addContactMethod = (contactMethod: ContactMethodEnumId) => {
        const foundContactMethod = _.find(this.availableContactMethods, (currentContactMethod, index) => {
            if (currentContactMethod.id === contactMethod) {
                return true;
            }
            return false;
        });
        if (foundContactMethod) {
            _.remove(this.availableContactMethods, { id: foundContactMethod.id });
            this.contactMethods.push(foundContactMethod);
        }
    };

    private readonly removeContactMethod = (contactMethod: ContactMethodEnumId) => {
        const foundContactMethod = _.find(this.contactMethods, (currentContactMethod, index) => {
            if (currentContactMethod.id === contactMethod) {
                return true;
            }
            return false;
        });
        if (foundContactMethod) {
            _.remove(this.contactMethods, { id: foundContactMethod.id });
            this.availableContactMethods.push(foundContactMethod);
        }
    };

    private readonly contactMethodHasValue = (contactMethod: ContactMethodEnumId) => {
        let value = '';
        switch (contactMethod) {
            case CONTACT_METHOD_ENUM.HomePhone:
                value = this.contact.phoneHome;
                break;
            case CONTACT_METHOD_ENUM.WorkPhone:
                value = this.contact.phoneWork;
                break;
            case CONTACT_METHOD_ENUM.MobilePhone:
                value = this.contact.phoneMobile;
                break;
            case CONTACT_METHOD_ENUM.Email:
                value = this.contact.email;
                break;
            default:
                break;
        }
        return (value) ? value.length > 0 : false;
    };

    private showTooltip(tooltip: MatTooltip, numResults: number, label: string): void {
        if (numResults < 1) {
            return;
        }
        tooltip.message = `Found ${numResults} contact${numResults > 1 ? 's' : ''} matching this ${label}.`;
        tooltip.show();
        setTimeout(() => {
            tooltip.hide();
        }, 10000);
    }
}
