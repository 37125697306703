import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { ILotMappedItem } from '@app/logic/lots';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { toPromisedArray } from 'cb-hub-lib';
import { ISkinnySpecTemplateDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { orderBy } from 'lodash';
import { map } from 'rxjs';

@Component({
    templateUrl: './apply-spec-template-dialog.component.html',
    styleUrls: ['./apply-spec-template-dialog.component.scss']
})
export class ApplySpecTemplateDialogComponent {
    public static readonly MIN_WIDTH = '33%';

    public selectedTemplateId: number;
    public readonly templates: ISkinnySpecTemplateDto[];
    public applyInProgress = false;

    // eslint-disable-next-line max-len
    private readonly alreadyAppliedMessage = 'A Specification Template has already been applied to this Lot. Applying another one will add to the existing Specification Items. Are you sure you want to apply another Specification Template?';

    constructor(
        public readonly dialogRef: MatDialogRef<ApplySpecTemplateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            lot: ILotMappedItem;
            lotSpec: ILotSpecMappedItem;
        },
        private readonly cbDialog: CbDialogService,
    ) {
        this.templates = toPromisedArray(this.data.lot.getAvailableSpecTemplates().pipe(map(x => orderBy(x, 'name', 'asc'))));
    }

    public confirmApplySpecTemplate(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Apply Specification Template',
            // eslint-disable-next-line max-len
            message: this.data.lot.specTemplateId ? this.alreadyAppliedMessage : 'Are you sure you want to apply this Specification Template?',
            confirmed: this.applySpecTemplate,
        });
    }

    private applySpecTemplate = (): void => {
        this.applyInProgress = true;
        const ref = this.cbDialog.block('Applying Specification Template...');
        this.data
            .lotSpec
            .applySpecTemplate(this.selectedTemplateId)
            .subOnce({
                next: (result) => {
                    this.dialogRef.close(result);
                    setTimeout(() => {
                        ref.close();
                    }, 1000);
                },
                error: () => {
                    this.applyInProgress = false;
                    ref.close();
                }
            });
    };

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
