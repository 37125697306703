import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IProductCentreFilterAttributeDto, IProductCentreFilterDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { IProductCentreFilterMappedItem } from '@app/logic/product-centre/interfaces/i.product-centre-filter.mapped';
import { IProductCentreFilterLogicService } from '@app/logic/product-centre/interfaces/i.product-centre-filter.logic.service';
import { ProductCentreFilterLogicService } from '@app/logic/product-centre/product-centre-filter.logic.service';
import { ProductCentreFilterMappedItem } from '@app/logic/product-centre/product-centre-filter.mapped';
import { FormMode } from '@app/shared/enums/form';
import { Observable, Subscription } from 'rxjs';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import {
    ProductCentreFilterAttributeDialogComponent
} from '@app/views/settings/pages/product-centre-configuration/product-centre-filter-attribute-dialog/product-centre-filter-attribute-dialog.component';
import { DragulaService } from 'ng2-dragula';
import { moveItemInArray } from '@angular/cdk/drag-drop';

interface IData {
    mappedItem: ProductCentreFilterMappedItem;
    otherData: IProductCentreFilterDto[];
}

const DRAGULA_IGNORE = 'cb-table-footer-row';

@Component({
    selector: 'cb-product-centre-filter-dialog',
    templateUrl: './product-centre-filter-dialog.component.html',
    styleUrls: ['./product-centre-filter-dialog.component.scss']
})
export class ProductCentreFilterDialogComponent
    extends BaseDialogFormViewDirective<IProductCentreFilterDto, IProductCentreFilterMappedItem, IProductCentreFilterLogicService>
    implements OnDestroy, OnInit {
    public readonly DRAG_NAME = 'ATTRIBUTES';
    public static readonly MIN_WIDTH = '50%';

    public subscriptions$ = new Subscription();
    public dataChanged = false;

    public readonly attributeColumns = [
        '',
        'Match Value',
        'Is Regular Expression',
        'Is Active',
        'Prerequisite Filter',
        'Prerequisite Filter Match Value',
        ''
    ];

    public dataSource: any[];
    public showActiveOnly = true;
    public dragulaModel: string[] = [];
    public reorderingEnabled: boolean;

    private _enoughAttributesToReorder = false;
    public get canReorderAttributes(): boolean {
        return this._enoughAttributesToReorder;
    };

    public get mappedItem(): ProductCentreFilterMappedItem {
        return this.data.mappedItem;
    }

    constructor(
        public readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<ProductCentreFilterDialogComponent>,
        protected readonly productCentreLogicService: ProductCentreFilterLogicService,
        private readonly cbDialog: CbDialogService,
        private readonly dragulaService: DragulaService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastService);
        this.setupDragula();
    }

    public ngOnInit(): void {
        this.formMode = this.mappedItem.id ? FormMode.Edit : FormMode.Add;
        this.mappedItem.attributes = this.mappedItem.attributes || [];
        this._enoughAttributesToReorder = this.mappedItem.attributes.length > 1;
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        this.dragulaService.destroy(this.DRAG_NAME);
    }

    /** Creates dragula group for the attributes and suscribes to dragula observables */
    private setupDragula(): void {
        this.dragulaService.createGroup(
            this.DRAG_NAME,
            {
                removeOnSpill: false,
                revertOnSpill: true,
                accepts: (el: Element) => {
                    const isLastRow = el.parentElement?.lastElementChild === el;
                    return this.reorderingEnabled && !isLastRow && el.classList.contains('drag-item');
                },
                moves: (el: Element, target: Element, handle: Element) => {
                    return this.reorderingEnabled && handle.classList.contains('drag-handle');
                },
            }
        );

        this.subscriptions$.add(
            this.dragulaService.dropModel(this.DRAG_NAME)
                .subscribe(
                    ({ el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
                        if (sourceIndex === targetIndex) {
                            return;
                        }
                        moveItemInArray(this.mappedItem.attributes, sourceIndex, targetIndex);
                        this.mappedItem.attributes.forEach((attribute, index) => {
                            attribute.sortOrder = index;
                        });
                        this.setDragulaModel();
                        this.dataChanged = true;
                    }
                )
        );
    }

    /** Sets the dragula model for drag and drop - separate to the actual array of data because
     * the dragula model needs an item for the header row as well...
     */
    private setDragulaModel(): void {
        this.dragulaModel = this.mappedItem.attributes.map(x => x.id);
    }

    public removeFilterAttribute(index: number): void {
        this.mappedItem.attributes.splice(index, 1);
        this._enoughAttributesToReorder = this.mappedItem.attributes.length > 1;
        this.dataChanged = true;
    }

    private manageAttribute(filterAttribute: IProductCentreFilterAttributeDto): Observable<any> {
        const data = {
            attribute: filterAttribute,
            availableFilters: this.data.otherData
        };
        return this.cbDialog
            .open(ProductCentreFilterAttributeDialogComponent, {
                data,
                minWidth: '45%',
                maxHeight: '50%'
            })
            .afterClosed();
    }

    public editAttribute(filterAttribute: IProductCentreFilterAttributeDto): void {
        this.manageAttribute(filterAttribute)
            .subOnce((result) => {
                if (result) {
                    Object.assign(filterAttribute, result);
                    this.dataChanged = true;
                }
            });
    }

    public addAttribute(): void {
        this.manageAttribute(this.newFilterAttribute())
            .subOnce((result) => {
                if (result) {
                    this.mappedItem.attributes.push(result);
                    this._enoughAttributesToReorder = this.mappedItem.attributes.length > 1;
                    this.dataChanged = true;
                }
            });
    }

    private newFilterAttribute(): IProductCentreFilterAttributeDto {
        return {
            productCentreFilterId: this.mappedItem.id,
            isActive: true,
            sortOrder: (this.mappedItem.attributes?.length ?? 0)
        } as IProductCentreFilterAttributeDto;
    }

    public getPrerequisiteFilterName(prerequisiteFilterMatchId: string): string {
        if (!prerequisiteFilterMatchId) {
            return '';
        }
        const filter = this.data.otherData.find(filter => filter.id === prerequisiteFilterMatchId);
        return filter ? filter.name : '';
    }
}
