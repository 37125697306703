<div class="flex-parent flex-wrap xs-column">
    <div class="standard-plan-card xs-100 flex-25"
         *ngFor="let plan of standardPlans">
        <mat-card (click)="openPlan(plan)">
            <div class="flex-row flex">
                <img mat-card-image
                     src="{{getDisplayImage(plan)}}"
                     cbHandleInvalidImage
                     [height]="400"
                     style="width: 100%"
                     alt="Plan Image"/>
            </div>
            <mat-card-content>
                <div class="standard-plan-info-area flex-col layoutGap-10">
                    <div class="start-start flex-row layoutGap-20 flex">
                        <h1 class="mat-headline-6">{{plan.name}}</h1>
                        <mat-icon *ngIf="plan.isPublished"
                                  color="accent"
                                  style="margin-top: 4px "
                                  matTooltip="Published">check_circle</mat-icon>
                    </div>
                    <div class="flex-row layoutGap-20 flex">
                        <div class="flex-col flex">
                            <cb-amenities [amenities]="plan.amenities"></cb-amenities>
                        </div>
                        <div class="flex-col flex">
                            <div class="flex-col">
                                <cb-display-value-new class="flex"
                                                      label="Floor Plan">
                                    <div class="start-center">
                                        <mat-icon>border_clear</mat-icon>
                                        <span class="mat-body-2">&nbsp;{{plan.floorArea | cbDasher}}M²</span>
                                    </div>
                                </cb-display-value-new>
                            </div>
                            <div class="flex-col">
                                <cb-display-value-new class="flex"
                                                      label="Cost Range">
                                    <div class="start-center">
                                        <mat-icon>attach_money</mat-icon>
                                        <span class="mat-body-2">&nbsp;{{getCostRange(plan)}}</span>
                                    </div>
                                </cb-display-value-new>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
