import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {IWorkingDrawingMappedItem} from '@app/logic/working-drawings';
import {IWorkingDrawingsLogicService} from '@app/logic/working-drawings/interfaces/i.working-drawings-logic.service';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {SimpleFormInputType} from '@app/shared/components/dialog/simple-form-dialog/simple-form-dialog.component';
import {DOCUMENT_GROUP_CODES_CONST, IWorkingDrawingDto, SYSTEM_AREA_ENUM, WORKING_DRAWING_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-complete-working-drawing-dialog',
    templateUrl: './complete-working-drawing-dialog.component.html',
    styleUrls: ['./complete-working-drawing-dialog.component.scss']
})
export class CompleteWorkingDrawingDialogComponent extends BaseDialogFormViewDirective<IWorkingDrawingDto, IWorkingDrawingMappedItem, IWorkingDrawingsLogicService> {
    public static readonly MIN_WIDTH = '80%';

    public get mappedItem(): IWorkingDrawingMappedItem {
        return this.data.mappedItem;
    }

    public readonly WORKING_DRAWING_DOC_GROUP = DOCUMENT_GROUP_CODES_CONST.WORKING_DRAWING_DOC_GROUP;
    public readonly systemArea = SYSTEM_AREA_ENUM.WorkingDrawing;

    constructor(
        public readonly dialogRef: MatDialogRef<CompleteWorkingDrawingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: IWorkingDrawingMappedItem;
        },
        private readonly cbDialog: CbDialogService,
        toastService: ToastService
    ) {
        super(dialogRef, toastService, cbDialog);
        this.mappedItem.$reload().subOnce();
    }

    public acceptWorkingDrawing(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Accept Working Drawing',
            message: 'Are you sure you want to accept this Working Drawing?',
            yesLabel: 'Confirm',
            noLabel: 'Cancel',
            confirmed: () => {
                this.mappedItem.statusId = WORKING_DRAWING_STATUS_ENUM.Completed;
                this.save();
            }
        });
    }

    public rejectWorkingDrawing(): void {
        this.cbDialog.simpleFormDialog({
            dialogHeading: 'Reject Working Drawing',
            yesLabel: 'Confirm',
            noLabel: 'Close',
            value: '',
            formInputConfig: {
                type: SimpleFormInputType.Textarea,
                label: 'Reason for Rejection',
                required: true,
            },
            confirmed: (result) => {
                this.mappedItem.rejectionNotes = result;
                this.mappedItem.failedReview = true;
                this.mappedItem.statusId = WORKING_DRAWING_STATUS_ENUM.InQueueForRework;
                this.save();
            }
        });
    }
}
