<div class="flex flex-row center-center"
     *ngIf="!disabled && !buttonOnly">
    <mat-form-field class="flex"
                    [ngClass]="{'cb-soft-required': softRequired && !required }">
        <mat-label>{{ label }}</mat-label>
        <input class="flex"
               matInput
               [name]="internalName"
               [(ngModel)]="text"
               [ignoreDirty]="ignoreDirty"
               type="text"
               [maxlength]="maxlength"
               [required]="required"
               [readOnly]="true"
               (click)="openSearchDialog()"
               [ngClass]="{'pointer': cursorPointer}"/>
    </mat-form-field>
    <button mat-icon-button
            class="cb-margin-right"
            (click)="openSearchDialog()">
        <mat-icon title="Search {{label}}s">search</mat-icon>
    </button>
    <button mat-icon-button
            *ngIf="removeable"
            (click)="clear()"
            [disabled]="!text">
        <mat-icon title="Clear Selected {{label}}">remove_circle</mat-icon>
    </button>
    <ng-content></ng-content>
</div>
<div class="flex"
     *ngIf="disabled && !buttonOnly"
     [ngClass]="{'cb-soft-required': softRequired && !required }">
    <label class="label">{{label}}</label>
    <span>{{text ? text : '-'}} &nbsp;</span>
    <button mat-icon-button
            *ngIf="id"
            (click)="viewItem()">
        <mat-icon>pageview</mat-icon>
    </button>
    <ng-content></ng-content>
</div>
<button mat-icon-button
        *ngIf="buttonOnly"
        [disabled]="disabled"
        (click)="openSearchDialog()">
    <mat-icon title="Search {{label}}s">search</mat-icon>
</button>
