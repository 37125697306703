<cb-dialog dialogHeading="Lot Classic Contacts">
    <form class="flex-col flex"
          #classicContactsForm="ngForm">
        <cb-display-value-new class="flex"
                              *ngIf="businessEntityField.isVisible"
                              label="Business Entity"
                              [value]="mappedItem?.businessEntity?.label">
        </cb-display-value-new>
        <cb-users-input-find-table class="flex"
                                   *ngIf="hlCoordinatorField.isVisible"
                                   label="HL Coordinator"
                                   [(ngModel)]="contacts.houseAndLandCoordinator.id"
                                   name="houseAndLandCoordinator"
                                   [text]="contacts.houseAndLandCoordinator.label"
                                   [removeable]="true"
                                   [searchParams]="getTagSearchParams(tags.HOUSE_AND_LAND_COORDINATOR)">
        </cb-users-input-find-table>
        <cb-users-input-find-table class="flex"
                                   *ngIf="buildingConsultantField.isVisible"
                                   label="Building Consultant"
                                   [(ngModel)]="contacts.buildingConsultant.id"
                                   name="buildingConsultant"
                                   [text]="contacts.buildingConsultant.label"
                                   [removeable]="true"
                                   [searchParams]="getTagSearchParams(tags.BUILDING_CONSULTANT)">
        </cb-users-input-find-table>
        <cb-users-input-find-table class="flex"
                                   *ngIf="buildingCoordinatorField.isVisible"
                                   label="Building Coordinator"
                                   [(ngModel)]="contacts.buildingCoordinator.id"
                                   name="buildingCoordinator"
                                   [text]="contacts.buildingCoordinator.label"
                                   [removeable]="true"
                                   [searchParams]="getTagSearchParams(tags.BUILDING_COORDINATOR)">
        </cb-users-input-find-table>
        <cb-users-input-find-table class="flex"
                                   *ngIf="designerField.isVisible"
                                   label="Designer"
                                   [(ngModel)]="contacts.designer.id"
                                   name="designer"
                                   [text]="contacts.designer.label"
                                   [removeable]="true"
                                   [searchParams]="getTeamSearchParams(teams.DesignTeamKey)">
        </cb-users-input-find-table>
        <cb-users-input-find-table class="flex"
                                   *ngIf="quantitySurveyorField.isVisible"
                                   label="QS"
                                   [(ngModel)]="contacts.quantitySurveyor.id"
                                   name="quantitySurveyor"
                                   [text]="contacts.quantitySurveyor.label"
                                   [removeable]="true"
                                   [searchParams]="getTeamSearchParams(teams.QsTeamKey)">
        </cb-users-input-find-table>
        <cb-users-input-find-table class="flex"
                                   *ngIf="foundationManagerField.isVisible"
                                   label="Foundation Manager"
                                   [(ngModel)]="contacts.foundationManager.id"
                                   name="foundationManager"
                                   [text]="contacts.foundationManager.label"
                                   [removeable]="true"
                                   [searchParams]="getTagSearchParams(tags.CONSTRUCTION_MANAGER)">
        </cb-users-input-find-table>
        <cb-users-input-find-table class="flex"
                                   *ngIf="framingManagerField.isVisible"
                                   label="Framing Manager"
                                   [(ngModel)]="contacts.framingManager.id"
                                   name="framingManager"
                                   [text]="contacts.framingManager.label"
                                   [removeable]="true"
                                   [searchParams]="getTagSearchParams(tags.CONSTRUCTION_MANAGER)">
        </cb-users-input-find-table>
        <cb-users-input-find-table class="flex"
                                   *ngIf="qualityAssuranceManagerField.isVisible"
                                   label="QA Manager"
                                   [(ngModel)]="contacts.qualityAssuranceManager.id"
                                   name="qualityAssuranceManager"
                                   [text]="contacts.qualityAssuranceManager.label"
                                   [removeable]="true"
                                   [searchParams]="getTagSearchParams(tags.QA_MANAGER)">
        </cb-users-input-find-table>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="classicContactsForm.pristine || classicContactsForm.invalid"
                (click)="save()">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
