<mat-card class="flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon mat-mdc-header-card-avatar">
            <mat-icon class="cb-primary"
                      title="Complete"
                      *ngIf="mappedItem?.stepEnquiryOriginComplete">
                check_circle
            </mat-icon>
            <mat-icon class="cb-error"
                      title="Incomplete"
                      *ngIf="!mappedItem?.stepEnquiryOriginComplete">
                error
            </mat-icon>
        </div>
        <mat-card-title [ngClass]="!(mappedItem?.stepEnquiryOriginComplete) ? 'cb-error' : ''">
            3. Enquiry Origin
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="start-start flex-col flex">
        <cb-display-value-new class="flex"
                              label="Enquiry Origin">
            {{ENQUIRY_ORIGIN_ENUM[mappedItem?.enquiryOrigin]}}
        </cb-display-value-new>
        <cb-checkbox name="visited"
                     label="Visited a Showhome"
                     [readonly]="true"
                     [(ngModel)]="mappedItem.visitedAShowHome">
        </cb-checkbox>
        <cb-display-value-new class="flex"
                              label="Showhome"
                              *ngIf="getDisplayShowhomeSelect()">
            {{showHomeToDisplay?.label}}
        </cb-display-value-new>
        <cb-display-value-new class="flex"
                              label="Other Showhome"
                              *ngIf="mappedItem.otherShowHome">
            {{mappedItem?.otherShowHome}}
        </cb-display-value-new>
        <cb-display-value-new class="flex"
                              label="Enquired On">
            {{mappedItem?.enquiryOriginOn | date}}
        </cb-display-value-new>
        <div class="flex-row flex"
             *ngIf="mappedItem?.referredById">
            <cb-display-value-new class="flex"
                                  label="Select Referrer">
                <div class="start-center flex-row flex">
                    {{getReferredByName()}} &nbsp;
                    <mat-icon class="cb-grey"
                              style="cursor: pointer;"
                              (click)="viewReferrer(mappedItem?.referredById, $event)">
                        pageview
                    </mat-icon>
                </div>
            </cb-display-value-new>
        </div>
    </mat-card-content>
    <div *ngIf="canEdit && !mappedItem?.isQualified()">
        <mat-divider class="card-divider"></mat-divider>
    </div>
    <mat-card-actions class="end-center flex-row"
                      *ngIf="canEdit && !mappedItem?.isQualified()">
        <button mat-stroked-button
                color="accent"
                (cbClick)="editEnquiryOriginDetails($event)">
            <mat-icon>edit</mat-icon>
            &nbsp;Edit
        </button>
    </mat-card-actions>
</mat-card>
