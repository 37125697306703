import { ComputedPropertySubject } from './../../../utils/computed-property.util';
import { Component, OnInit, Input, OnChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ITaskPermissions } from '@app/core/permissions/base.permissions';
import { IBaseMappedItem } from '@app/logic/base/interfaces/i.base-mapped-item';
import { IBaseLogicService } from '@app/logic/base/interfaces/i.base-logic.service';
import { ITaskMappedItem, TasksLogicService, ITaskEntity } from '@app/logic/tasks';
import { MatDialog } from '@angular/material/dialog';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { TaskDialogComponent } from '../task-dialog/task-dialog.component';
import { ICloseTaskDto } from '@app/logic/tasks/interfaces/i.close-task.dto';
import { ITaskType } from '@app/logic/tasks/interfaces/i.Task.dto';

@Component({
    selector: 'cb-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, OnChanges, OnDestroy {

    @Input() public readonly permissions: ITaskPermissions;
    @Input() public readonly edit: boolean;
    @Input() public readonly completableButNotEditable: boolean;
    @Input() public readonly allowFollowUp: boolean;
    @Input() public readonly entityName: string;
    @Input() public readonly entity: IBaseMappedItem<any, any, IBaseLogicService<any, any>> & ITaskEntity<string | number>;
    @Input() public readonly taskTypes: Array<ITaskType>;

    public tasks: ITaskMappedItem[] = [];
    public tasksLoaded = false;
    public searchText = new UntypedFormControl();

    public filteredTasks = new ComputedPropertySubject(() => this.tasks && this.filterTasks && this.tasks.filter(this.filterTasks), []);
    public filteredIncompleteTasks = new ComputedProperty(() => this.filteredTasks.value && this.filteredTasks.value.filter(x => !x.isClosed), []);
    public filteredCompleteTasks = new ComputedProperty(() => this.filteredTasks.value && this.filteredTasks.value.filter(x => x.isClosed), []);

    public canAddTask = new ComputedProperty(
        () => {
            return this.permissions && this.edit && this.permissions.canAddTask();
        },
        false
    );

    private readonly allTasksClosed$ = new ComputedPropertySubject(() => !!this.tasks?.every((v) => v?.isClosed), false);
    @Output() public readonly allTasksClosed = new EventEmitter<boolean>();
    @Output() public readonly taskCountChange = new EventEmitter<number>();

    public readonly subscription = new Subscription();

    constructor(
        public readonly tasksLogicService: TasksLogicService,
        public readonly matDialog: MatDialog
    ) { }

    public ngOnInit(): void {
        this.tasksLogicService.getMappedTasks(this.entity.$logicService.$baseUri, this.entity.taskEntityId)
            .subOnce({
                next: (result) => {
                    this.tasks = result;
                    this.filteredTasks.recompute();
                }
            });

        this.subscription.add(
            this.searchText.valueChanges.subscribe({
                next: () => this.filteredTasks.recompute()
            })
        );

        this.subscription.add(
            this.filteredTasks.subscribe({
                next: () => {
                    this.filteredIncompleteTasks.recompute();
                    this.filteredCompleteTasks.recompute();
                    this.allTasksClosed$.recompute();
                    this.tasksLoaded = true;
                }
            })
        );

        this.subscription.add(
            this.allTasksClosed$.subscribe({
                next: () => {
                    this.allTasksClosed.emit(this.allTasksClosed$.value);
                    this.taskCountChange.emit(this.tasks?.length ?? 0);
                }
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngOnChanges(): void {
        this.canAddTask.recompute();
    }

    public createTask(): void {
        this.matDialog.open(TaskDialogComponent, {
            data: {
                ...(this.taskTypes && { taskTypes: this.taskTypes }),
                task: this.tasksLogicService.createMappedTaskItem(this.entity.taskEntityUri, this.entity.taskEntityId),
                isProjectRelease: ('isProjectRelease' in this.entity)
            },
            panelClass: 'cb-dialog-container',
            minWidth: 400
        })
            .afterClosed()
            .subOnce({
                next: (task: ITaskMappedItem) => {
                    this.tasks.unshift(task);
                    this.filteredTasks.recompute();
                }
            });
    }

    public filterTasks = (value: ITaskMappedItem, index: number, array: ITaskMappedItem[]): boolean => {
        if (!value) {
            return false;
        }

        if (!this.searchText.value || this.searchText.value.length === 0) {
            return true;
        }

        const lowerCaseSearch = this.searchText.value.toLowerCase();
        return (value.comments && value.comments.toLowerCase().indexOf(lowerCaseSearch) > -1) ||
            (value.subject && value.subject.toLowerCase().indexOf(lowerCaseSearch) > -1);
    };

    public taskClosed(_task: ICloseTaskDto): void {
        this.filteredTasks.recompute();
    }
}
