<div *cbWaitFor="lotSpec"
     class="cb-margin start-center flex-row">
    <cb-display-value-new class="flex"
                          label="Lot Spec Version"
                          [value]="lotSpec.specVersion"></cb-display-value-new>
    <button mat-raised-button
            color="primary"
            (click)="generateReport()">
        <mat-icon>insert_drive_file</mat-icon>
        &nbsp;Generate Report
    </button>
    <button class="cb-margin-left"
            mat-raised-button
            [disabled]="!hasItems"
            (click)="expandCollapseAll()">
        <span class="start-center flex-row"
              *ngIf="!allExpanded.value">
            <span>Expand All&nbsp;</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </span>
        <span class="start-center flex-row"
              *ngIf="allExpanded.value">
            <span>Collapse All&nbsp;</span>
            <mat-icon>keyboard_arrow_up</mat-icon>
        </span>
    </button>
</div>
<mat-accordion [multi]="true"
               class="accordion">
    <mat-expansion-panel class="panel"
                         *ngFor="let group of specGroups;"
                         [(expanded)]="expandedPanels[group.specGroupId]"
                         (expandedChange)="setAllExpanded()">
        <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
            <mat-panel-title>
                <span class="mat-body-strong cb-margin-top">{{group.label}}</span>
            </mat-panel-title>
            <mat-panel-description class="end-center flex-row">
                <ng-container *ngIf="expandedPanels[group.specGroupId] && canEditSchemeSpecs() && showAddButton()">
                    <button style="margin: 3px;"
                            color="primary"
                            mat-raised-button
                            [matMenuTriggerFor]="action"
                            (click)="$event.stopPropagation()"
                            [disabled]="!mappedItem.canManage">
                        Add Item
                    </button>
                    <mat-menu #action="matMenu">
                        <button mat-menu-item
                                (click)="addLotSpecItem(group.specGroupId)">
                            <mat-icon class="cb-grey">image</mat-icon>
                            <span>&nbsp;Lot Spec Item</span>
                        </button>
                        <button mat-menu-item
                                (click)="addLotSpecColourItem(group.specGroupId)">
                            <mat-icon class="cb-grey">format_paint</mat-icon>
                            <span>&nbsp;Colour Item</span>
                        </button>
                    </mat-menu>
                </ng-container>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <cb-table [loaded]="true"
                  [stringColumns]="columns">
            <tbody cbTableBody>
                <ng-container *ngFor="let specItem of group.items">
                    <tr *ngIf="!specItem.isColourItem; else colourItem">
                        <td>
                            <div class="flex-row start-center">
                                <mat-icon class="cb-grey cb-help-cursor"
                                          *ngIf="isBundle($any(specItem.item).costType)">folder
                                </mat-icon>
                                <mat-icon class="cb-grey cb-help-cursor"
                                          matTooltip="Is part of bundle"
                                          *ngIf="$any(specItem.item).bundleId">attach_file</mat-icon>
                                <mat-icon class="cb-grey cb-help-cursor"
                                          *ngIf="specItem.item.showInClientSpecification"
                                          matTooltip="Included in Flyer / Create Schedule">style</mat-icon>
                                <mat-icon class="cb-grey cb-help-cursor"
                                          *ngIf="$any(specItem.item).showInColourYourDreams"
                                          svgIcon="book-open-page-variant"
                                          matTooltip="Included in Colour Schedule"></mat-icon>
                                <mat-icon class="cb-grey cb-help-cursor"
                                          *ngIf="$any(specItem.item).isDescriptiveOnlyCost"
                                          matTooltip="Descriptive Only">notes</mat-icon>
                                <mat-icon class="cb-grey cb-help-cursor"
                                          *ngIf="$any(specItem.item).hasImage">photo</mat-icon>
                            </div>
                        </td>
                        <cb-td-enum [value]="$any(specItem.item).costType"
                                    [enum]="COST_TYPE"></cb-td-enum>
                        <cb-td-text [value]="$any(specItem.item).productCode"></cb-td-text>
                        <td>{{$any(specItem.item).productDisplay ? $any(specItem.item).productDisplay :
                            $any(specItem.item).productOther
                            ? $any(specItem.item).productOther : "-"}}</td>
                        <td>
                            <ul class="inline-list"
                                *ngIf="$any(specItem.item).offeringOptions && $any(specItem.item).offeringOptions.length > 0">
                                <li *ngFor="let opt of $any(specItem.item).offeringOptions">{{opt.name}}
                                </li>
                            </ul>
                            <span class="inline-list"
                                  *ngIf="!$any(specItem.item).offeringOptions || $any(specItem.item).offeringOptions.length < 1">-</span>
                        </td>
                        <cb-td-text [value]="getQuantity($any(specItem.item))"></cb-td-text>
                        <cb-td-currency *ngIf="$any(specItem.item).isProvisionalCost || $any(specItem.item).isEstimatedCost"
                                        [value]="$any(specItem.item).rate"
                                        [gstMode]="$any(specItem.item).isProvisionalCost ? 'addGst' : null"></cb-td-currency>
                        <td *ngIf="!$any(specItem.item).isProvisionalCost && !$any(specItem.item).isEstimatedCost">
                            -</td>
                        <cb-td-text [value]="$any(specItem.item).supplierDisplay"></cb-td-text>
                        <td cbTableStickyCol="end"
                            cbTableShrinkCol>
                            <button *ngIf="canEditSchemeSpecs()"
                                    mat-icon-button
                                    [matMenuTriggerFor]="action"
                                    (click)="$event.stopPropagation()"
                                    [disabled]="!mappedItem.canManage">
                                <mat-icon class="cb-grey">more_horiz</mat-icon>
                            </button>
                            <mat-menu #action="matMenu">
                                <button mat-menu-item
                                        (click)="editLotSpecItem($any(specItem.item))">
                                    <mat-icon class="cb-grey">mode_edit</mat-icon>
                                    <span>&nbsp;Edit Product</span>
                                </button>
                                <button mat-menu-item
                                        (click)="removeLotSpecItem($any(specItem.item))"
                                        [disabled]="$any(specItem).bundleId">
                                    <mat-icon class="cb-grey">remove_circle</mat-icon>
                                    <span>&nbsp;Remove Item</span>
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    <ng-template #colourItem>
                        <tr>
                            <td>
                                <div class="flex-row start-center">
                                    <mat-icon class="cb-grey cb-help-cursor">format_paint</mat-icon>
                                    <mat-icon class="cb-grey cb-help-cursor"
                                              *ngIf="isBundle($any(specItem.item).costType)">folder
                                    </mat-icon>
                                    <mat-icon class="cb-grey cb-help-cursor"
                                              *ngIf="$any(specItem.item).bundleId">attach_file</mat-icon>
                                    <mat-icon class="cb-grey cb-help-cursor"
                                              matTooltip="Included in Flyer / Create Schedule"
                                              *ngIf="specItem.item.showInClientSpecification">style
                                    </mat-icon>
                                    <mat-icon class="cb-grey cb-help-cursor"
                                              *ngIf="$any(specItem.item).showInColourYourDreams"
                                              svgIcon="book-open-page-variant"
                                              title="Included in Colour Schedule"></mat-icon>
                                    <mat-icon class="cb-grey cb-help-cursor"
                                              *ngIf="$any(specItem.item).isDescriptiveOnlyCost">notes</mat-icon>
                                    <mat-icon class="cb-grey cb-help-cursor"
                                              *ngIf="$any(specItem.item).hasImage">photo</mat-icon>
                                </div>
                            </td>
                            <td>Colour</td>
                            <cb-td-text [value]="$any(specItem.item).colourItem.code"></cb-td-text>
                            <cb-td-text [value]="$any(specItem.item).colourItem.name"></cb-td-text>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td cbTableStickyCol="end"
                                cbTableShrinkCol>
                                <button *ngIf="canEditSchemeSpecs()"
                                        mat-icon-button
                                        [matMenuTriggerFor]="action"
                                        (click)="$event.stopPropagation()"
                                        [disabled]="!mappedItem.canManage">
                                    <mat-icon class="cb-grey">more_horiz</mat-icon>
                                </button>
                                <mat-menu #action="matMenu">
                                    <button mat-menu-item
                                            (click)="editColourItem($any(specItem.item))">
                                        <mat-icon class="cb-grey">mode_edit</mat-icon>
                                        <span>&nbsp;Edit Product</span>
                                    </button>
                                    <button mat-menu-item
                                            (click)="removeLotSpecColourItem($any(specItem.item))"
                                            [disabled]="$any(specItem).bundleId">
                                        <mat-icon class="cb-grey">remove_circle</mat-icon>
                                        <span>&nbsp;Remove Item</span>
                                    </button>
                                </mat-menu>
                            </td>
                        </tr>
                    </ng-template>
                </ng-container>
            </tbody>
        </cb-table>
        <cb-loading-spinner *ngIf="!specGroups"
                            class="loading-spinner">
        </cb-loading-spinner>
    </mat-expansion-panel>
</mat-accordion>
