import { Directive } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Router } from '@angular/router';
import { takeOne } from 'cb-hub-lib';
import { ClientAccountSearchService } from '@app/core/services/search/client-account-search.service';
import { BaseInputFindDirective } from '../../base-input-find.directive';
import { ClientAccountsInputFindDialogDirective } from '../../dialog/extensions/client-accounts-input-find-dialogs/client-account-input-find-dialog.directive';
import { IClientAccountDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Directive()
export class ClientAccountsInputFindDirective extends BaseInputFindDirective {
    protected _myDialog: ComponentType<ClientAccountsInputFindDialogDirective>;
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public searchService: ClientAccountSearchService) {
        super();
    }

    public openSearchDialog = (): void => {
        this.dialogRef.open(
            this._myDialog,
            {
                minWidth: '80%',
                data: {
                    extraSearchParams: this.searchParams,
                    placeholder: 'Type First Name, Last Name, or Email',
                    dialogHeading: 'Client Accounts',
                    contentHeading: 'Account Search',
                    searchService: this.searchService
                },
                panelClass: 'cb-dialog-container'
            }
        )
            .afterClosed()
            .pipe(takeOne())
            .subscribe({
                next: (clientAccount: IClientAccountDto) => {
                    if (clientAccount) {
                        this.text = clientAccount.legalName;
                        this.value = clientAccount;
                    }
                }
            });
    };

    public viewItem = (): void => {
        this.router.navigate([`/clientaccounts/edit/${this.id}/summary`]);
    };
}
