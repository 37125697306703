import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { BankAccountsLogicService } from '@app/logic/bank-accounts';
import { BusinessEntitiesLogicService } from '@app/logic/business-entity/business-entity.logic.service';
import { IBusinessEntityMappedItem } from '@app/logic/business-entity/interfaces/i.business-entity.mapped';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { FormMode } from '@app/shared/enums/form';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { BUILD_ACTIVITY_REQUIRED_LEVEL_ENUM, IIdAndLabelDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { SelectListItem } from 'src/app/core/services/enum/enum.service';

export interface BusinessEntitiesExtraSearchFilters {
    activityCode: string;
    activityName: string;
}

@Component({
    selector: 'cb-business-entity-details',
    templateUrl: './business-entity-details.component.html',
    styleUrls: ['./business-entity-details.component.scss'],
    providers: [
        BusinessEntitiesLogicService,
        BankAccountsLogicService,
        UsersLogicService,
        provideParentForm()
    ]
})
export class BusinessEntityDetailsComponent implements OnInit {
    public formMode: FormMode;
    public businessEntityRequiredLevels: Array<SelectListItem>;
    public availableBusinessEntities: Array<IIdAndLabelDto>;
    public isView: () => boolean;
    public isEdit: () => boolean;
    @Input() public mappedItem: IBusinessEntityMappedItem;
    @Input() public businessEntityForm: NgForm;

    constructor(
        public readonly navigationService: NavigationService,
        public readonly currentUser: CurrentUserService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: MatDialog,
        public controlContainer: ControlContainer,
        private readonly bankAccountsLogicService: BankAccountsLogicService
    ) {
        this.formMode = FormMode.View;
        this.businessEntityRequiredLevels = BUILD_ACTIVITY_REQUIRED_LEVEL_ENUM.toSelectList();
    }

    public ngOnInit(): void {
        if (this.mappedItem.id > 0) {
            this.bankAccountsLogicService
                .getAvailableBankAccountsForBusinessEntity(this.mappedItem.id)
                .subOnce({
                    next: (x) => {
                        this.availableBusinessEntities = x;
                    },
                    error: (err) => {
                        console.error(err);
                    }
                });
        }
    }
}
