import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { BusinessAccountLogicService, IBusinessAccountContactDto, IBusinessAccountLocationDto, IBusinessAccountMappedItem } from '@app/logic/business-accounts';
import { ILocationDto, LocationLogicService } from '@app/logic/location';
import { BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM, ITradeTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { find, map, some } from 'lodash';
import { concatAll, of } from 'rxjs';

interface IData {
    account: IBusinessAccountMappedItem;
    contact: IBusinessAccountContactDto;
    contactType: number;
    contactLocation: IBusinessAccountLocationDto;
}

@Component({
    selector: 'cb-business-account-contact-role-dialog',
    templateUrl: './business-account-contact-role-dialog.component.html',
    styleUrls: ['./business-account-contact-role-dialog.component.scss']
})
export class BusinessAccountContactRoleDialogComponent {
    public static readonly MIN_WIDTH = '60%';
    public tradeTypes: ITradeTypeDto[];
    public BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM = BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM;

    public region: number;
    public district: number;
    public area: number;
    public selectedTradeTypes: number[];
    public emailAddress: string;
    public hasOptionalEmailAddress = false;
    public availableTradeTypes: ITradeTypeDto[];
    public availableLocations: number[];

    private locations: ILocationDto[];
    private selectedLocation: ILocationDto;

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly dialogRef: MatDialogRef<BusinessAccountContactRoleDialogComponent>,
        private readonly lookupService: LookupService,
        private readonly locationLogicService: LocationLogicService,
        private readonly businessAccountLogicService: BusinessAccountLogicService,
    ) {
        this.lookupService.getLookup('tradeTypes').then(tradeTypes => this.tradeTypes = tradeTypes as unknown as ITradeTypeDto[]);
        this.locationLogicService.getFlatTree(true).subOnce(locations => this.locations = locations);
    }

    public ngOnInit(): void {
        this.region = this.data.contactLocation?.location?.region?.id || null;
        this.district = this.data.contactLocation?.location?.district?.id || null;
        this.area = this.data.contactLocation?.location?.area?.id || null;
        this.emailAddress = this.data.contactLocation?.emailAddress || null;
        this.hasOptionalEmailAddress = !!this.emailAddress;
        this.availableLocations = map(this.data.account?.locations ?? [], 'id');

        this.availableTradeTypes = map(this.data.account.businessAccountTradeTypes, item => {
            return {
                id: item.tradeTypeId,
                label: `${item.parent?.label} - ${item.label}`,
            } as ITradeTypeDto;
        });
        this.selectedTradeTypes = this.data.contactLocation ? [this.data.contactLocation.tradeTypeId || 0] : [];
    }

    public onLocationChanged(location: any): void {
        this.selectedLocation = find(this.locations, item => {
            let isMatch = false;
            if (location.region) {
                isMatch = item.region?.id && location.region === item.region.id;
            }

            if (location.district) {
                isMatch = item.district?.id && location.district === item.district.id;
            }

            if (location.area) {
                isMatch = item.area?.id && location.area === item.area.id;
            }

            return isMatch;
        });
    }

    public isTradeTypeChecked(tradeTypeId: number): boolean {
        return this.selectedTradeTypes && this.selectedTradeTypes.indexOf(tradeTypeId) > -1;
    }

    public onTradeTypeChanged(tradeTypeId: number, isChecked: boolean): void {
        if (isChecked) {
            if (this.selectedTradeTypes.indexOf(tradeTypeId) === -1) {
                this.selectedTradeTypes.push(tradeTypeId);
            }
        } else {
            const index = this.selectedTradeTypes.indexOf(tradeTypeId);
            if (index > -1) {
                this.selectedTradeTypes.splice(index, 1);
            }
        }
    }

    public canSave(): boolean {
        if (this.data.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Installation) {
            return this.selectedLocation?.id && this.selectedTradeTypes?.length && (
                this.data.contactLocation
                    ? this.isUnique(this.data.contactType, this.selectedLocation.id, this.selectedTradeTypes[0], null, this.data.contactLocation.id)
                    : !some(this.selectedTradeTypes, selectedTradeType => !this.isUnique(this.data.contactType, this.selectedLocation.id, selectedTradeType, null)));
        } else {
            return this.selectedLocation?.id && this.isUnique(this.data.contactType, this.selectedLocation.id, null, this.emailAddress, this.data.contactLocation?.id);
        }
    }

    public onSaveClicked(): void {
        if (this.data.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Installation) {
            const observableCalls = map(this.selectedTradeTypes, selectedTradeType =>
                this.businessAccountLogicService.addEditContactLocation(this.data.account.id, this.data.contact.id, this.data.contactLocation?.id || 0,
                    this.data.contactType, this.selectedLocation.id, undefined, selectedTradeType));

            const source = of(...observableCalls);
            const resultObservable = source.pipe(concatAll());

            let count = 0;
            resultObservable.subscribe(savedContact => {
                count++;
                if (count === this.selectedTradeTypes.length) {
                    this.dialogRef.close(savedContact);
                }
            });
        } else {
            this.businessAccountLogicService.addEditContactLocation(this.data.account.id, this.data.contact.id, this.data.contactLocation?.id || 0,
                this.data.contactType, this.selectedLocation.id, this.hasOptionalEmailAddress ? this.emailAddress : '', undefined)
                .subOnce(savedContact => this.dialogRef.close(savedContact));
        }
    }

    private isUnique(contactType: number, locationId: number, tradeTypeId: number, emailAddress: string, existingId?: number): boolean {
        return this.data.contact?.areas ?
            !some(this.data.contact?.areas || [], item => {
                return item.location?.id === locationId && item.contactType === contactType
                    && (tradeTypeId ? item.tradeTypeId === tradeTypeId : true)
                    && (emailAddress ? item.emailAddress === emailAddress : true)
                    && (existingId ? item.id !== existingId : true);
            })
            : false;
    }
}
