<mat-card class="top-panel button-row flex-row">
    <mat-card-content class="flexible-gap">
        <button mat-raised-button
                (click)="goBackToList()">
            <mat-icon>arrow_back</mat-icon>
            Back to Consents
        </button>
        <button mat-raised-button
                *ngIf="resourceConsent?.isApproved"
                color="primary"
                (click)="editConsentNumber()">
            <mat-icon>edit</mat-icon>
            Edit Consent Number
        </button>
        <button mat-raised-button
                *ngIf="canEditConsent()"
                color="primary"
                (click)="editResourceConsent()"
                [disabled]="resourceConsent?.isComplete">
            <mat-icon>edit</mat-icon>
            Manage Consent
        </button>
        <button *ngIf="canChangeConsentStatus()"
                mat-raised-button
                aria-label="Change Consent Status"
                color="primary"
                [matMenuTriggerFor]="menu"
                [disabled]="resourceConsent?.isComplete">
            <mat-icon>arrow_drop_down</mat-icon>
            Consent Status
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item
                    (click)="setInQueue()"
                    [disabled]="resourceConsent?.isComplete || !resourceConsent?.canSetInQueue">
                <mat-icon mat-menu-align-target
                          color="accent">timelapse
                </mat-icon>
                In Queue
            </button>
            <button mat-menu-item
                    (click)="setInProgress()"
                    [disabled]="resourceConsent?.isComplete || !resourceConsent?.canSetInProgress">
                <mat-icon mat-menu-align-target
                          color="warn">check
                </mat-icon>
                In Progress
            </button>
            <button mat-menu-item
                    (click)="setOnHold()"
                    [disabled]="resourceConsent?.isComplete || !resourceConsent?.canSetOnHold">
                <mat-icon mat-menu-align-target
                          color="primary">pause
                </mat-icon>
                On Hold
            </button>
            <button mat-menu-item
                    (click)="setSubmittedToCouncil()"
                    [disabled]="!resourceConsent?.canSetSubmitted">
                <mat-icon mat-menu-align-target
                          color="primary">send
                </mat-icon>
                Submitted to Council
            </button>
            <button mat-menu-item
                    (click)="setCouncilReceived()"
                    [disabled]="!resourceConsent?.canSetCouncilReceived">
                <mat-icon mat-menu-align-target
                          color="primary">inbox
                </mat-icon>
                Council Received
            </button>
            <button mat-menu-item
                    (click)="grantConsent()"
                    [disabled]="resourceConsent?.isComplete  || !hasResourceConsentDocument">
                <mat-icon mat-menu-align-target
                          color="primary">send
                </mat-icon>
                Consent Granted
            </button>
            <button mat-menu-item
                    (click)="denyConsent()"
                    [disabled]="resourceConsent?.isComplete">
                <mat-icon mat-menu-align-target
                          color="warn">error
                </mat-icon>
                Consent Denied
            </button>
        </mat-menu>
        <button mat-raised-button
                *ngIf="canEditConsent()"
                color="primary"
                (click)="assignResourceConsent()"
                [disabled]="resourceConsent?.isComplete">
            <mat-icon>person</mat-icon>
            Reassign
        </button>
        <button mat-raised-button
                color="primary"
                *ngIf="canDeleteConsent()"
                [disabled]="resourceConsent?.isComplete"
                (click)="deleteResourceConsent()">
            <mat-icon>delete</mat-icon>
            Delete
        </button>
    </mat-card-content>
</mat-card>
