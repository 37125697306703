import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITeamsMappedItem } from '@app/logic/teams/interfaces/i.teams.mapped';
import { ITeamsLogicService } from '@app/logic/teams/interfaces/i.teams.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { Observable } from 'rxjs';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { ITeamDto, TEAM_CODES_CONST, TeamCodesConstId } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-team-dialog',
    templateUrl: './teams-dialog.component.html',
    styleUrls: ['./teams-dialog.component.scss']
})
export class TeamsDialogComponent extends BaseDialogFormViewDirective<ITeamDto, ITeamsMappedItem, ITeamsLogicService> {

    public isSystemTag: boolean;

    public readonly teamKeys: IEnumLookup<TeamCodesConstId>[];

    constructor(
        public readonly dialogRef: MatDialogRef<TeamsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: { mappedItem: ITeamsMappedItem; existingKeys: string[] },
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.isSystemTag = this.mappedItem.isSystemTag;

        if (!this.data.existingKeys) {
            this.data.existingKeys = [];
        }
        this.teamKeys = TEAM_CODES_CONST.toLookup().filter(x => !this.data.existingKeys.includes(x.id));
    }

    public saveMethod(): Observable<any> {
        return this.mappedItem.$save();
    }
}
