<cb-dialog dialogHeading="Edit Variation Details">
    <form class="flex-col flex"
          #createChangeForm="ngForm">
        <cb-display-value-new label="Variation Number"
                              [value]="mappedItem.variationNumber">
        </cb-display-value-new>
        <cb-display-value-new label="Variation To"
                              [value]="mappedItem.variationToName">
        </cb-display-value-new>
        <cb-user-date-time-new class="flex"
                               label="Variation Date"
                               [floatingLabel]="true"
                               [date]="mappedItem.variationDate">
        </cb-user-date-time-new>
        <cb-user-autocomplete-scroller class="cb-margin-top flex"
                                       label="Variation From"
                                       name="selectedVariationFromUserSearch"
                                       [(ngModel)]="selectedVariationFromUser"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [active]="true"
                                       [required]="true">
        </cb-user-autocomplete-scroller>
        <cb-checkbox name="extraToSaleAndPurchaseAgreement"
                     label="Extra to Sale & Purchase Agreement"
                     [(ngModel)]="mappedItem.extraToSaleAndPurchaseAgreement">
        </cb-checkbox>
        <cb-address-new [(ngModel)]="mappedItem.address"
                        addressTitle="Address"
                        name="physicalAddress"
                        [hideHeading]="true"
                        [required]="true"
                        [hideSweepAndLabels]="true">
        </cb-address-new>
        <cb-checkbox name="extraToSaleAndPurchaseAgreement"
                     label="Show GST Exclusive"
                     [(ngModel)]="mappedItem.showGstExclusive">
        </cb-checkbox>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="createChangeForm.pristine || createChangeForm.invalid"
                (cbClick)="save()"
                [clickLimit]="1"> Save
        </button>
        <button mat-raised-button
                (click)="
                    cancel()">Cancel
        </button>
    </div>
</cb-dialog>
