import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProjectPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ProjectsLogicService } from '@app/logic/projects';
import { IProjectMappedItem } from '@app/logic/projects/interfaces/i.project.mapped';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ImageUploadDialogComponent } from '@app/shared/components/image-handlers/image-upload-dialog/image-upload-dialog.component';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { BehaviorSubject } from 'rxjs';
import { ManageProjectDetailsDialogComponent } from '../project-details/manage-project-details-dialog/manage-project-details-dialog.component';

@Component({
    selector: 'cb-manage-project-side-bar-card',
    templateUrl: './manage-project-side-bar-card.component.html',
    styleUrls: ['./manage-project-side-bar-card.component.scss']
})
export class ManageProjectSideBarCardComponent {
    @Input() public mappedItem: IProjectMappedItem;
    @Output() public readonly projectUpdated = new EventEmitter();
    public collapsed$ = new BehaviorSubject<boolean>(false);

    constructor(
        public projectPermissions: ProjectPermissions,
        public readonly navigationService: NavigationService,
        public readonly cbDialog: CbDialogService,
        public readonly cdRef: ChangeDetectorRef,
        private readonly projectsLogicService: ProjectsLogicService
    ) { }

    public canView(): boolean {
        return this.projectPermissions.canView();
    }

    public viewLead(): void {
        // this.navigationService.navigate([viewLeadUrl(this.lead.id)]);
    }

    public hasPermissionsEditProject(): boolean {
        return this.projectPermissions.canEdit();
    }

    public editProject(): void {
        const clone = this.mappedItem.$clone();
        this.cbDialog
            .open(ManageProjectDetailsDialogComponent, {
                data: { mappedItem: clone },
                minWidth: '80%',
            })
            .afterClosed()
            .subOnce(() => {
                this.projectUpdated.emit();
                this.cdRef.detectChanges();
            });
    }

    public completeProject(): void {
        const clone = this.mappedItem.$clone();
        this.cbDialog
            .open(ManageProjectDetailsDialogComponent, {
                data: { mappedItem: clone },
                minWidth: '80%',
            })
            .afterClosed()
            .subOnce(() => {
                this.projectUpdated.emit();
                this.cdRef.detectChanges();
            });
    }

    public cancelProject(): void {
        const clone = this.mappedItem.$clone();
        this.cbDialog
            .open(ManageProjectDetailsDialogComponent, {
                data: { mappedItem: clone },
                minWidth: '80%',
            })
            .afterClosed()
            .subOnce();
    }

    public get showDeleteButton(): boolean {
        return !isNullOrWhiteSpace(this.mappedItem.projectLogoUrl);
    }

    public uploadImage(): void {
        const dialogRef = this.cbDialog.open(ImageUploadDialogComponent, {
            data: {
                dialogHeading: 'Upload Image',
                documentName: 'Project Logo'
            }
        });

        dialogRef.componentInstance.submitClicked.subscribe(file => {
            this.projectsLogicService.updateImage(this.mappedItem.id, file)
                .subOnce((projectDetailDto) => {
                    this.mappedItem.$updateThisAndOriginal(projectDetailDto);
                    dialogRef.close();
                });
        });
    }

    public deleteImage(): void {
        this.projectsLogicService.deleteImage(this.mappedItem.id).subOnce((projectDetailDto) => {
            this.mappedItem.$updateThisAndOriginal(projectDetailDto);
        });
    }

}
