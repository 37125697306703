import { BuildingConsentActionBarComponent } from './building/action-bar/action-bar.component';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { CbElapsedTimeModule } from '@app/shared/components/elapsed-time/elapsed-time.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbGenericQuestionsModule } from '@app/shared/components/generic-questions/generic-questions.module';
import { CbTasksModule } from '@app/shared/components/tasks/tasks.module';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CommonModule } from '@angular/common';
import { ConsentCouncilReceievedDialogComponent } from './consent-council-received-dialog/consent-council-received-dialog.component';
import { ConsentDetailsComponent } from './consent-details/consent-details.component';
import { CouncilRfiListCardModule } from '../council-rfi/council-rfi-list-card/council-rfi-list-card.module';
import { CreateConsentDialogComponent } from './create-consent-dialog/create-consent-dialog.component';
import { EditBuildingConsentDialogComponent } from './building/edit-dialog/edit-dialog.component';
import { EditConsentNumberDialogComponent } from './edit-consent-number-dialog/edit-consent-number-dialog.component';
import { EditResourceConsentDialogComponent } from './resource/edit-dialog/edit-dialog.component';
import { FormsModule } from '@angular/forms';
import { GrantConsentDialogComponent } from './grant-consent-dialog/grant-consent-dialog.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LotConsentDocumentsComponent } from './lot-consent-documents/lot-consent-documents.component';
import { LotConsentListCardComponent } from './lot-consent-list-card/lot-consent-list-card.component';
import { LotConsentRfisComponent } from './lot-consent-rfis/lot-consent-rfis.component';
import { LotConsentsActionBarComponent } from './lot-consents-action-bar/lot-consents-action-bar.component';
import { LotConsentsListComponent } from './lot-consents-list/lot-consents-list.component';
import { LotConsentsMainComponent } from './lot-consents-main/lot-consents-main.component';
import { ManageBuildingConsentComponent } from './building/manage-building-consent/manage-building-consent.component';
import { ManageResourceConsentComponent } from './resource/manage-resource-consent/manage-resource-consent.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { ReassignBuildingConsentDialogComponent } from './building/reassign-dialog/reassign-dialog.component';
import { ReassignResourceConsentDialogComponent } from './resource/reassign-dialog/reassign-dialog.component';
import { RequestConsentDialogComponent } from './request-consent-dialog/request-consent-dialog.component';
import { ResourceConsentActionBarComponent } from './resource/action-bar/action-bar.component';
import { SubmittedToCouncilDilaogComponent } from './submitted-to-council-dilaog/submitted-to-council-dilaog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    declarations: [
        BuildingConsentActionBarComponent,
        EditBuildingConsentDialogComponent,
        ReassignBuildingConsentDialogComponent,
        ResourceConsentActionBarComponent,
        EditResourceConsentDialogComponent,
        ReassignResourceConsentDialogComponent,
        GrantConsentDialogComponent,
        RequestConsentDialogComponent,
        LotConsentsActionBarComponent,
        ConsentDetailsComponent,
        CreateConsentDialogComponent,
        ConsentCouncilReceievedDialogComponent,
        SubmittedToCouncilDilaogComponent,
        EditConsentNumberDialogComponent,
        LotConsentsListComponent,
        LotConsentListCardComponent,
        LotConsentsMainComponent,
        ManageBuildingConsentComponent,
        ManageResourceConsentComponent,
        LotConsentDocumentsComponent,
        LotConsentRfisComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        CbFormsModule,
        MatIconModule,
        MatCardModule,
        InfiniteScrollModule,
        MatButtonModule,
        MatDividerModule,
        MatCardModule,
        CbDisplayValueModule,
        MatTooltipModule,
        MatTabsModule,
        CbTasksModule,
        CbWaitForModule,
        CbDocumentsModule,
        MatMenuModule,
        CbUserDateTimeModule,
        CbDialogModule,
        CbClickModule,
        CbGenericQuestionsModule,
        CbDocumentsModule,
        CbElapsedTimeModule,
        CouncilRfiListCardModule,
        MatCheckboxModule,
    ],
    exports: [
        LotConsentsMainComponent
    ]
})

export class LotDesignConsentsModule { }
