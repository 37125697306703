<mat-card class="cb-margin-2x flex-col">
    <mat-card-header class="start-center">
        <span class="mat-body-strong">{{label}}</span>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content class="flex">
        <div class="center-center flex-row flex">
            <span class="mat-headline-2">
                {{value}}
            </span>
        </div>
    </mat-card-content>
</mat-card>
