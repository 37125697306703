<cb-dialog class="flex-col"
           *cbWaitFor="mappedItem"
           dialogHeading="Switch Release Stage - Lot {{mappedItem.lotNumber}}">
    <form class="flex-col flex"
          #form="ngForm">
        <div class="flex-row flex">
            <cb-select label="Project Releases"
                       name="selectedReleaseStageId"
                       [(ngModel)]="selectedReleaseStageId"
                       [options]="releaseStages"
                       [required]="true">
            </cb-select>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="form.pristine || form.invalid"
                (cbClick)="save()"
                [clickLimit]="1">Save Project
        </button>
        <button mat-raised-button
                (click)="cancel(form.dirty)">Cancel
        </button>
    </div>
</cb-dialog>
