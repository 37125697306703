<div class="flex-col flex">
    <div class="flex-row">
        <cb-alert-card-new *ngIf="lotDto.hasClientSale"
                           [type]="lotDto.hasClientSale ? 'success' : null"
                           [message]="'Client Sale - ' + CONTRACT_STATUS_ENUM[lotDto.contractStatus]">
        </cb-alert-card-new>
        <cb-alert-card-new *ngIf="lotDto?.clientSale?.sunsetDate"
                           type="success"
                           [message]="'Sunset Date of - ' + lotDto.clientSale.sunsetDate|cbDate">
        </cb-alert-card-new>
        <cb-alert-card-new [type]="lotDto.hasGrantedPrimaryBuildingConsent ? 'success' : null"
                           message="Building Consent Granted - ">
        </cb-alert-card-new>
        <cb-alert-card-new [type]="lotDto.qsConsentTakeoffGranted ? 'success' : null"
                           message="QS Consent To Build">
        </cb-alert-card-new>
        <cb-alert-card-new [type]="getUncofirmedActivitiesMilestoneComplete() ? 'success' : null"
                           [message]="getUnconfirmedActivityMilestoneLabel()">
        </cb-alert-card-new>
    </div>
    <div class="flex-row layoutGap-10">
        <div class="flex-col flex-80">
            <cb-lot-build-programme-view *cbWaitFor="lotDto$$ | async"
                                         [lotDto]="lotDto">
            </cb-lot-build-programme-view>
        </div>
        <div class="flex-col flex-20">
            <mat-card class="flex flex-col"
                      cbFillHeight>
                <mat-card-content>
                    <div>
                        <div class="cb-margin-bottom start-start flex-row">
                            <mat-icon class="cb-grey cb-margin-right">announcement</mat-icon>
                            <h1 class="mat-body-2">
                                <b>Alerts</b>
                            </h1>
                        </div>
                        <div class="block"
                             style="padding-bottom: 10px;">
                            <cb-alert-card-new *ngIf="lotDto.isPrimaryUnit"
                                               [message]="'Primary Lot in Block'"
                                               [type]="'info'">
                            </cb-alert-card-new>
                            <cb-alert-card-new *ngFor="let item of invalidItems"
                                               [message]="getItemMessage(item)"
                                               [type]="'warning'">
                            </cb-alert-card-new>
                            <cb-alert-card-new *ngIf="shouldDisplayNoAlertsMessage"
                                               [message]="'There are no alerts to display'"
                                               [type]="'info'">
                            </cb-alert-card-new>
                        </div>
                    </div>
                    <ng-container *cbWaitFor="buildProgramme">
                    <span *ngIf="buildProgramme &&
                                buildProgramme.id &&
                                buildProgramme.hasBuildProgrammeTemplateApplied">

                        <!-- Unit -->
                        <mat-divider class="cb-margin-bottom"></mat-divider>
                        <cb-lot-build-programme-steps [buildProgramme]="buildProgramme"
                                                      [lotDto]="lotDto"
                                                      (reloadLotMappedItem)="reloadLotMappedItem.emit()">
                        </cb-lot-build-programme-steps>
                    </span>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
