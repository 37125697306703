<div class="cb-margin-2x flex-col"
     *cbWaitFor="mappedItem">
    <cb-user-date-time-new label="Requested by"
                           [userFullName]="mappedItem.createdByName"
                           [date]="mappedItem.createdDate"
                           [floatingLabel]="true"
                           [displayTime]="false"></cb-user-date-time-new>
    <div class="flex-col">
        <cb-display-value-new label="Failed Review History">
            <div class="flex-row flex">
                <div class="flex-row">
                    <mat-divider [vertical]="true"></mat-divider>
                </div>
                <ul class="cb-list flex-col flex">
                    <li *ngFor="let item of mappedItem.failedReviewHistories">
                        <span>Rejected by {{item.updatedByName}} on {{item.updatedDate | date: 'dd MMM YYYY h:mma'
                            }}</span>
                        <p>&emsp;{{item.notes}}</p>
                    </li>
                </ul>
            </div>
        </cb-display-value-new>
    </div>
    <cb-display-value-new *ngIf="mappedItem.preConsentPlanRejectionHistories?.length > 0"
                          label="Rejection Histories">
        <ul class="list">
            <li class="flex-col"
                *ngFor="let history of mappedItem.preConsentPlanRejectionHistories">
                <cb-user-date-time-new label="Rejected by"
                                       [userFullName]="history.rejectedBy"
                                       [date]="history.rejectedDate"
                                       [floatingLabel]="false"
                                       [displayTime]="true"></cb-user-date-time-new>
                <div class="cb-margin-left flex-row flex">
                    <mat-divider [vertical]="true"></mat-divider>
                    <span class="cb-margin-left mat-body-2 flex">{{history.rejectionReason}}</span>
                </div>
            </li>
        </ul>
    </cb-display-value-new>
</div>
