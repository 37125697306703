<div class="cb-margin"
     *cbWaitFor="cacheLoaded$ | async">
    <mat-card class="cb-margin-bottom flex-col">
        <mat-card-content>
            <form #form="ngForm">
                <div class="start-center flex-row flex">
                    <mat-icon>search</mat-icon>
                    <cb-input type="text"
                              label="Search"
                              [(ngModel)]="searchFilters.data.query"
                              (ngModelChange)="searchFiltersChanged$.next($event)"
                              name="search"
                              class="cb-margin-left cb-margin-right-2x flex">
                    </cb-input>
                    <cb-select label="Order"
                               name="resultOrders"
                               [(ngModel)]="searchFilters.data.order"
                               [options]="resultOrders$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select class="cb-margin-right"
                               label="Direction"
                               [(ngModel)]="searchFilters.data.direction"
                               name="direction"
                               [options]="resultDirections$ | async">
                    </cb-select>
                </div>
            </form>
            <cb-table class="cb-margin-top flex"
                      label="Image Requests"
                      maxHeightOffset="280"
                      [infiniteScrollEnabled]="infiniteScrollEnabled"
                      [fetch]="fetchResults()"
                      [loaded]="loaded"
                      [queryUpdated]="searchFiltersChanged$"
                      [(results)]="results"
                      [(currentPage)]="currentPage">
                <thead cbTableHead>
                <tr>
                    <td>Job #</td>
                    <td>lot #</td>
                    <td>Project</td>
                    <td>Address</td>
                    <td>Client Account Name</td>
                    <td>Name</td>
                    <td>Design Scheme</td>
                    <td>Status</td>
                    <td>Exterior Image Date</td>
                    <td>Exterior Image Requestor</td>
                    <td>Floorplan Image Date</td>
                    <td>Floorplan Image Requestor</td>
                    <td></td>
                </tr>
                </thead>
                <tbody cbTableBody>
                <tr *ngFor="let concept of results">
                    <cb-td-text [value]="concept.jobNumber"></cb-td-text>
                    <cb-td-text [value]="concept.lotNumber"></cb-td-text>
                    <cb-td-text [value]="concept.projectName"></cb-td-text>
                    <cb-td-text [value]="getStreetAddress(concept)"></cb-td-text>
                    <cb-td-text [value]="concept.clientAccountName"></cb-td-text>
                    <cb-td-text [value]="concept.name"></cb-td-text>
                    <cb-td-text [value]="concept.designSchemeName"></cb-td-text>
                    <cb-td-text [value]="concept.status"></cb-td-text>
                    <cb-td-date [value]="concept.exteriorImageRequestedDate"></cb-td-date>
                    <cb-td-text [value]="concept.exteriorImageRequesterName"></cb-td-text>
                    <cb-td-date [value]="concept.floorPlanImageRequestedDate"></cb-td-date>
                    <cb-td-text [value]="concept.floorPlanImageRequesterName"></cb-td-text>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <cb-button icon="pageview"
                                   (clicked)="viewConcept(concept)">
                        </cb-button>
                    </td>
                </tr>
                </tbody>
            </cb-table>
        </mat-card-content>
    </mat-card>
</div>
