import { Component, HostBinding, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { SignalrService } from '@app/core/services/signalr/signalr.service';
import { INotificationDto, NotificationLogicService } from '@app/logic/notification';
import { Debounce } from '@app/shared/decorators/debounce.decorator';
import { NOTIFICATION_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { getPrimaryEntityType, simplifySecondaryEntityType } from './notification-routes';

interface IDialogData {
    navigationService: NavigationService;
    notificationHubService: SignalrService;
}

const DIALOG_PADDING_BOTTOM = 360;
const DIALOG_WIDTH = 360;

@Component({
    selector: 'cb-notifications-dialog',
    templateUrl: './notifications-dialog.component.html',
    styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent {

    public get items(): any {
        return this.data.notificationHubService.unreadNotifications || [];
    }

    constructor(
        public readonly dialogRef: MatDialogRef<NotificationsDialogComponent>,
        private readonly notificationLogicService: NotificationLogicService,
        private readonly navigationService: NavigationService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IDialogData) { }

    @HostBinding('class') public class = 'flex-row flex';

    @HostListener('window:resize')
    // debounce here because window resize will trigger this listener more than we need
    @Debounce()
    public resizeDialog(): void {
        this.dialogRef.updateSize(`${DIALOG_WIDTH}px`, `${window.innerHeight - DIALOG_PADDING_BOTTOM}px`);
    }

    public goToNotifications(event: MouseEvent): void {
        this.data.navigationService.navigate(['/notifications'], event).then(this.handleClose);
    }

    public close(): void {
        this.dialogRef.close();
    }

    public goToItem(notification: INotificationDto): void {
        let url = '/';
        if (!notification.primaryEntityType) {
            return;
        }
        url = getPrimaryEntityType(notification as any);

        if (notification.secondaryEntityType) {
            url += simplifySecondaryEntityType(notification as any);
        }

        this.close();
        this.notificationLogicService.markAsRead(notification.id).subOnce(() => {
            this.navigationService.navigate([url], undefined, {
                queryParams: { paramEntityId: notification.secondaryEntityId, qnsId: notification.tertiaryEntityId }
            });
        });
    }

    private readonly handleClose = (result): void => {
        if (result.success && !result.newTab) {
            this.close();
        }
    };

    public getIcon(notification: any): string {
        if (notification === undefined) {
            return;
        }

        if (notification.notificationType === NOTIFICATION_TYPE_ENUM.Info) {
            return 'info_outline';
        } else if (notification.notificationType === NOTIFICATION_TYPE_ENUM.Alert) {
            return 'alarm';
        } else if (notification.notificationType === NOTIFICATION_TYPE_ENUM.Action) {
            return 'update';
        }

        return 'alarm';
    }

}
