<button mat-mini-fab
        color="accent"
        class="collapse-button"
        (click)="collapsed = !collapsed"
        [matTooltip]="collapsed ? 'Expand' : 'Collapse'">
    <mat-icon>{{ collapsed ? 'chevron_right' : 'chevron_left ' }}</mat-icon>
</button>
<mat-card *ngIf="collapsed"
          class="collapsed-card flex-row">
    <mat-card-content>
        <span class="mat-headline-6 collapsed-title">My Leads</span>
    </mat-card-content>
</mat-card>
<ng-container *ngIf="!collapsed">
    <mat-card class="filter-card flex-col">
        <mat-card-content>
            <div class="flex-row">
                <span class="mat-headline-6 flex">My Leads</span>
                <button mat-icon-button
                        *ngIf="permissions.canCreate()"
                        (click)="createLead()"
                        matTooltip="Create Lead">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <form class="start-center flex-row"
                  #searchForm="ngForm">
                <cb-input class="cb-margin-right flex"
                          label="Search"
                          name="search"
                          [(ngModel)]="userCacheItem.data.query"></cb-input>
                <button mat-raised-button
                        [matMenuTriggerFor]="actionsMenu">
                    <mat-icon svgIcon="dots-horizontal"></mat-icon>
                    &nbsp;Filter
                </button>
                <mat-menu #actionsMenu>
                    <div class="cb-margin-2x flex-col">
                        <cb-selection-list label="Show my leads that are"
                                           name="status"
                                           [(ngModel)]="userCacheItem.data.status"
                                           [options]="leadStatuses"
                                           (click)="$event.stopPropagation()"
                                           checkboxPosition="before">
                        </cb-selection-list>
                    </div>
                </mat-menu>
            </form>
        </mat-card-content>
    </mat-card>
    <mat-card class="list-card scroll-container flex-col"
              [cbInfiniteScroll]="listContainer"
              [fetch]="fetchResults()"
              [queryUpdated]="queryUpdate"
              [enabled]="searchEnabled"
              [(results)]="results"
              [(currentPage)]="currentPage">
        <mat-card-content>
            <cb-info-message *ngIf="results.length < 1"
                             message="There are no Leads available to view"
                             iconClass="cb-accent"></cb-info-message>
            <div class="list-container flex-col"
                 #listContainer>
                <mat-list class="flex-col">
                    <mat-list-item *ngFor="let lead of results"
                                   class="lead-list-item"
                                   [ngClass]="{'selected-lead':currentLeadId == lead.id}">
                        <div class="follow-up-none flex-row flex"
                             [ngClass]="getFollowUpColour(lead)">
                            <div class="flex-col flex">
                                <h3 matListItemTitle
                                    class="item-line cb-no-margin-top"> {{ lead.name }} </h3>
                                <p matListItemLine
                                   class="item-line"> {{ getStatus(lead) }} </p>
                                <p matListItemLine
                                   [hidden]="!showFollowUp(lead)"
                                   class="item-line">
                                    Next follow up
                                    <strong class="follow-up-days">{{ getCallbackDays(lead.callbackDate) }}</strong>
                                </p>
                                <p matListItemLine
                                   class="item-line"> Last activity {{ lead.updatedDate | date }}</p>
                            </div>
                            <div class="center-center flex-col">
                                <button mat-icon-button
                                        [ngClass]="currentLeadId == lead.id ? 'list-icon-selected' : 'list-icon'"
                                        (click)="loadLead(lead.id)"
                                        [disabled]="viewLeadDisabled(lead.id)">
                                    <mat-icon>exit_to_app</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </mat-card-content>
    </mat-card>
</ng-container>
