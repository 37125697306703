<cb-dialog dialogHeading="Generate Build Programme">
    <form #form="ngForm">
        <cb-select *ngIf="canChangeTemplate.value"
                   label="Build Programme Template"
                   name="template"
                   [required]="true"
                   [(ngModel)]="lotDto.buildProgrammeTemplateId"
                   [options]="buildProgrammeTemplates"></cb-select>
        <div class="flex-row">
            <cb-datepicker label="Estimated Start Date"
                           name="startDate"
                           class="cb-margin-right flex"
                           [required]="true"
                           [(ngModel)]="lotDto.jobStart.estimate"
                           [maxDate]="lotDto.jobComplete.estimate"
                           [nonWorkingDays]="nonWorkingDays"
                           [pickerType]="'calendar'"></cb-datepicker>
            <cb-datepicker class="flex"
                           label="Estimated End Date"
                           name="endDate"
                           [required]="true"
                           [(ngModel)]="lotDto.jobComplete.estimate"
                           [minDate]="lotDto.jobStart.estimate"
                           [disabled]="!lotDto.jobStart.estimate"
                           [nonWorkingDays]="nonWorkingDays"
                           [pickerType]="'calendar'"></cb-datepicker>
        </div>
        <div class="start-center flex-row"
             *ngIf="lotDto.isPrimaryUnit">
            <mat-icon class="cb-warn">warning</mat-icon>
            &nbsp;
            <span class="mat-body-2">Generating this Build Programme will lock the order of all Units belonging to this
                {{LOT_TYPE_ENUM[lotDto.parentLotType]}}</span>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="saving || form.invalid"
                (cbClick)="generateBuildProgramme()"
                [clickLimit]="1">
            Generate Build Programme
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
