import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { LotsLogicService } from '@app/logic/lots';
import { NonWorkingDayLogicService } from '@app/logic/non-working-day';
import { PricingComplexityLogicService } from '@app/logic/pricing-complexity';
import { ITakeoffTaskMappedItem } from '@app/logic/takeoff-tasks/interfaces/i.takeoff-task-mapped';
import { ITakeoffTasksLogicService } from '@app/logic/takeoff-tasks/interfaces/i.takeoff-tasks.logic.service';
import { TakeoffTasksLogicService } from '@app/logic/takeoff-tasks/takeoff-tasks-logic-service';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { FormMode } from '@app/shared/enums/form';
import { IManageDialogData } from '@app/shared/interfaces/i.manage-dialog-data';
import { IPricingComplexityDto, ITakeOffTaskBaseDto, TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { find } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';


@Component({
    selector: 'cb-takeoff-task-dialog',
    templateUrl: './takeoff-task-dialog.component.html',
    styleUrls: ['./takeoff-task-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeoffTaskDialogComponent extends BaseDialogFormViewDirective<
ITakeOffTaskBaseDto,
ITakeoffTaskMappedItem,
ITakeoffTasksLogicService> implements OnInit, OnDestroy {
    public static readonly MIN_WIDTH = '500px';
    public readonly QS_TEAM = TEAM_CODES_CONST.QsTeamKey;
    private _subscriptions = new Subscription();
    private _lotId: number;

    public pricingComplexities$ = this.pricingComplexityLogicService.$getList();

    private _pricingComplexities$ = new BehaviorSubject<IPricingComplexityDto[]>(null);

    public qsTeamMembers$ = this
        .teamsLogicService.loadActiveMembersByKey(TEAM_CODES_CONST.QsTeamKey);
    constructor(
        private readonly teamsLogicService: TeamsLogicService,
        private readonly pricingComplexityLogicService: PricingComplexityLogicService,
        private readonly logicService: TakeoffTasksLogicService,
        private readonly lotLogicService: LotsLogicService,
        private readonly nonWorkingDaysLogicService: NonWorkingDayLogicService,
        public readonly toastSerivce: ToastService,
        private readonly cdRef: ChangeDetectorRef,
        public readonly dialogRef: MatDialogRef<TakeoffTaskDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<ITakeoffTaskMappedItem> & { mode: FormMode; lotId: number },
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        this._lotId = data.lotId;
        this.formMode$.next(data.mode);
        this._subscriptions.add(
            this.pricingComplexities$.subscribe(this._pricingComplexities$)
        );
    }

    public ngOnInit(): void {
        this.lotLogicService.$getItem(this._lotId).subOnce(returnedLot => {
            if (this.isAdd$$) {
                this.mappedItem = this.logicService.$createMappedItem({ lotId: returnedLot.id as number } as any);
                this.mappedItem.assignedToUserId = returnedLot.contacts?.quantitySurveyor?.id;
                this.cdRef.detectChanges();
            }
        });
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public pricingComplexityChanged(): void {
        const selectedItem = find(this._pricingComplexities$.value, { id: this.mappedItem.pricingComplexityId });
        if (selectedItem) {
            this.mappedItem.targetWorkHours = selectedItem.targetWorkHours;
            // eslint-disable-next-line eqeqeq
            const startDate = this.mappedItem.createdDate != undefined ? this.mappedItem.createdDate : moment().format();
            this._subscriptions.add(
                this.nonWorkingDaysLogicService
                    .getDueDate(startDate, selectedItem.targetCompletionHours)
                    .subscribe(dateResult => this.mappedItem.dueBy = dateResult)
            );
        }
    }
}
