<cb-dialog [dialogHeading]="'Edit Progress Payments'">
    <form #progressPaymentsForm="ngForm">
        <cb-table class="cb-margin-top"
                  label="Payments"
                  [loaded]="mappedItem !== undefined"
                  [minWidth]="750">
            <thead cbTableHead>
                <tr cbTableRow>
                    <td>Payment Amount</td>
                    <td>Event/Stage</td>
                    <td>New Amount</td>
                </tr>
            </thead>
            <tbody cbTableBody>
                <tr cbTableRow
                    *ngFor="let payment of mappedItem?.payments | cbSortByPipe: 'sortOrder' : 'asc'; let index = index;">
                    <td>{{ getOriginalAmountById(payment.id) | cbCurrency}}</td>
                    <cb-td-text *ngIf="payment.buildStageId"
                                [value]="payment.buildStageLabel">
                </cb-td-text>
                    <cb-td-enum *ngIf="!payment.buildStageId"
                                [value]="payment.trigger"
                                [enum]="BUILD_STAGE_EVENT_ENUM">
                </cb-td-enum>
                    <td>
                        <cb-currency [name]="payment.buildStage?.label + '_dollar_value_' + index"
                                     [ngModel]="payment.dollarValue | number:'1.2-2'"
                                     (ngModelChange)="payment.dollarValue=$event"
                                     [required]="true"
                                     label="New Amount"
                                     (change)="setPercentageFromDollarValue(payment)">
                    </cb-currency>
                    </td>
                </tr>
                <tr *ngIf="lotMappedItem.hasScheduleSpecTemplate"
                    cbTableRow
                    class="total-row">
                    <td>
                        <cb-display-value-new class="cb-margin-bottom-nil">
                        {{ balance | cbCurrency}}
                    </cb-display-value-new>
                    </td>
                    <cb-td-text value="Balance"></cb-td-text>
                    <td></td>
                </tr>
                <tr cbTableRow
                    class="total-row">
                    <td>
                        <cb-display-value-new class="cb-margin-bottom-nil">
                        {{ mappedItem?.contractPriceWithGst | cbCurrency}}
                    </cb-display-value-new>
                    </td>
                    <cb-td-text value="Contract Amount"></cb-td-text>
                    <td></td>
                </tr>
                <tr cbTableRow
                    class="total-row">
                    <td>
                        <cb-display-value-new class="cb-margin-bottom-nil">
                        {{ mappedItem?.acceptedVarianceTotalWithGst | cbCurrency}}
                    </cb-display-value-new>
                    </td>
                    <cb-td-text value="Accepted Variance Total"></cb-td-text>
                    <td></td>
                </tr>
                <tr cbTableRow
                    class="total-row">
                    <td>
                        <cb-display-value-new class="cb-margin-bottom-nil">
                        {{ (mappedItem?.contractPriceWithGst + mappedItem?.acceptedVarianceTotalWithGst)
                        | cbCurrency}}
                    </cb-display-value-new>
                    </td>
                    <cb-td-text value="Contract Total Inc. Variations"></cb-td-text>
                    <td></td>
                </tr>
            </tbody>
        </cb-table>
        <div class="cb-margin-top-3x start-center flex-row">
            <cb-input type="text"
                      name="editReason"
                      label="Reason for Editing Progress Payment Amounts"
                      [required]="true"
                      [maxlength]="200"
                      style="width: 100%;"
                      [readonly]="progressPaymentsForm.pristine || !amountsHaveBeenChanged"
                      [(ngModel)]="mappedItem.changeReasonDescription">
            </cb-input>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="progressPaymentsForm.pristine || progressPaymentsForm.invalid || !amountsHaveBeenChanged"
                (cbClick)="saveProgressPaymentsDetails()"
                [clickLimit]="1">
            Save
        </button>
        <button mat-raised-button
                (click)="cancelDialog()">Cancel</button>
    </div>
</cb-dialog>
