import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { IProductSearchDto } from '@app/logic/products/i.product-search.dto';

@Component({
    templateUrl: './product-input-find-dialog.component.html',
    styleUrls: ['./product-input-find-dialog.component.scss'],
})
export class ProductInputFindDialogComponent {
    public static readonly MIN_WIDTH = '95%';

    constructor(
        public readonly dialogRef: MatDialogRef<ProductInputFindDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            activeOnly: boolean;
            hideCompositeItems: boolean;
            restrictedCategories: number[];
            hasRestrictedCategory: boolean;
        },
    ) { }

    public productSelected(dto: IProductSearchDto): void {
        this.dialogRef.close(dto);
    }

    public close(): void {
        this.dialogRef.close(false);
    }
}
