<div *cbWaitFor="(lotMappedItem$$ | async) && (cacheLoaded$ | async)"
     class="cb-margin">
    <mat-card class="cb-margin-bottom">
        <mat-card-content>
            <cb-lot-summary-stepper [lotMappedItem]="lotMappedItem"
                                    [blockLevelProgress]="blockLevelProgress"
                                    [pinnedPanels]="pinnedPanels"
                                    [silentData]="silentData"
                                    silentDataKey="summaryCard"
                                    (togglePanelPinnedEvent)="togglePanelPinned($event)">
            </cb-lot-summary-stepper>
        </mat-card-content>
    </mat-card>
    <div class="layoutGap-20 flex-row">
        <form class="flex-col flex-33">
            <cb-lot-summary-images [lotMappedItem]="lotMappedItem">

            </cb-lot-summary-images>
            <mat-accordion multi>
                <cb-lot-summary-info [lotMappedItem]="lotMappedItem">

                </cb-lot-summary-info>
                <cb-lot-summary-website-publishing [lotMappedItem]="lotMappedItem">

                </cb-lot-summary-website-publishing>
            </mat-accordion>
        </form>
        <form class="flex-col flex-33">
            <mat-accordion multi>
                <cb-lot-summary-details [lotMappedItem]="lotMappedItem"
                                        [pinnedPanels]="pinnedPanels"
                                        [silentData]="silentData"
                                        silentDataKey="lotDetails"
                                        (togglePanelPinnedEvent)="togglePanelPinned($event)">
                </cb-lot-summary-details>
                <cb-lot-description [lotMappedItem]="lotMappedItem"
                                    [pinnedPanels]="pinnedPanels"
                                    [silentData]="silentData"
                                    silentDataKey="lotDetails"
                                    (togglePanelPinnedEvent)="togglePanelPinned($event)">
                </cb-lot-description>
                <cb-lot-summary-build-details [lotMappedItem]="lotMappedItem"
                                              [pinnedPanels]="pinnedPanels"
                                              [silentData]="silentData"
                                              silentDataKey="buildDetails"
                                              (togglePanelPinnedEvent)="togglePanelPinned($event)">
                </cb-lot-summary-build-details>
                <cb-lot-summary-classic-contacts [lotMappedItem]="lotMappedItem"
                                                 [pinnedPanels]="pinnedPanels"
                                                 [silentData]="silentData"
                                                 silentDataKey="classicContacts"
                                                 (togglePanelPinnedEvent)="togglePanelPinned($event)">
                </cb-lot-summary-classic-contacts>
                <cb-lot-summary-purchaser [lotMappedItem]="lotMappedItem"
                                          [pinnedPanels]="pinnedPanels"
                                          [silentData]="silentData"
                                          silentDataKey="purchaser"
                                          (togglePanelPinnedEvent)="togglePanelPinned($event)">
                </cb-lot-summary-purchaser>
                <cb-lot-summary-insurance-details [lotMappedItem]="lotMappedItem"
                                                  [pinnedPanels]="pinnedPanels"
                                                  [silentData]="silentData"
                                                  silentDataKey="insuranceDetails"
                                                  (togglePanelPinnedEvent)="togglePanelPinned($event)">
                </cb-lot-summary-insurance-details>
                <cb-lot-summary-classic-care [lotMappedItem]="lotMappedItem"
                                             [pinnedPanels]="pinnedPanels"
                                             [silentData]="silentData"
                                             silentDataKey="classicCare"
                                             (togglePanelPinnedEvent)="togglePanelPinned($event)">
                </cb-lot-summary-classic-care>
            </mat-accordion>
        </form>
        <form class="flex-col flex-33">
            <mat-accordion multi>
                <cb-lot-summary-alerts [lotMappedItem]="lotMappedItem"
                                       [blockLevelProgress]="blockLevelProgress">

                </cb-lot-summary-alerts>
                <cb-lot-summary-milestones [lotMappedItem]="lotMappedItem"
                                           [blockLevelProgress]="blockLevelProgress">
                </cb-lot-summary-milestones>
            </mat-accordion>
        </form>
    </div>
</div>
