import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IPreConsentPlanDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {ToastService} from '@app/core/services/toast/toast.service';
import {LookupService} from '@app/core/services/lookup/lookup.service';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {IPreConsentPlanMappedItem} from '@app/logic/pre-consent-plans';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {IPreConsentPlansLogicService} from '@app/logic/pre-consent-plans/interfaces/i.pre-consent-plans-logic.service';

@Component({
    selector: 'cb-finalise-preconsent-plan-dialog',
    templateUrl: './finalise-preconsent-plan-dialog.component.html',
    styleUrls: ['./finalise-preconsent-plan-dialog.component.scss']
})
export class FinalisePreconsentPlanDialogComponent extends BaseDialogFormViewDirective<IPreConsentPlanDto, IPreConsentPlanMappedItem, IPreConsentPlansLogicService>  {
    public static readonly MIN_WIDTH = '33%';
    public finaliseInProgress: boolean;
    public jobNumber: string;
    public mappedItem: IPreConsentPlanMappedItem;
    public designComplexities = this.lookups.DESIGN_COMPLEXITY.toLookup();

    constructor(
        public readonly dialogRef: MatDialogRef<FinalisePreconsentPlanDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: IPreConsentPlanMappedItem;
            jobNumber: string;
        },
        public toastService: ToastService,
        private readonly lookups: LookupService,
        public readonly cbDialog: CbDialogService,
    ) {
        super(dialogRef, toastService);
        this.finaliseInProgress = false;
        this.jobNumber = this.data.jobNumber;
        this.mappedItem = this.data.mappedItem;
    }

    public finalisePreConsentPlan(): void {
        this.finaliseInProgress = true;
        this.cbDialog.confirm({
            dialogHeading: 'Finalise Pre-Consent',
            message: 'Are you sure you want to finalise this Pre-Consent?',
            confirmed: () => {
                this.finaliseInProgress = false;
                this.dialogRef.close(this.mappedItem);
            },
            declined: () => {
                this.finaliseInProgress = false;
            }
        });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
