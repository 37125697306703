import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {CbDisplayValueModule} from '@app/shared/components/display-value/display-value.module';
import {ColourItemsComponent} from './colour-items.component';
import {ColourItemDialogComponent} from './dialog/colour-item-dialog.component';
import {DragulaModule} from 'ng2-dragula';
import {CbClickModule} from '@app/shared/directives/click/click.module';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule} from 'cb-hub-lib';
import {CbImageButtonModule} from '@app/shared/components/image-handlers/image-button/image-button.module';

@NgModule({
    declarations: [
        ColourItemsComponent,
        ColourItemDialogComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        DragulaModule.forRoot(),
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbImageButtonModule,
        CbFormsModule,
        CbDisplayValueModule,
        CbClickModule,
        CbHubTableModule,
        CbTableModule,
    ],
    exports: [
        ColourItemsComponent
    ]
})
export class ColourItemsSettingsModule { }
