<div class="flex-row flex"
     style="overflow: hidden; padding-top: 10px;">
    <div class="cb-margin-right-3x cb-padding-right-3x flex-col flex">
        <mat-form-field>
            <mat-label>Bedrooms</mat-label>
            <input matInput
                   type="number"
                   name="bedrooms"
                   required
                   [(ngModel)]="plan.amenities.bedrooms">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Bathrooms</mat-label>
            <input matInput
                   type="number"
                   name="bathrooms"
                   required
                   [(ngModel)]="plan.amenities.bathrooms">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Living Rooms</mat-label>
            <input matInput
                   type="number"
                   name="living"
                   required
                   [(ngModel)]="plan.amenities.living">
        </mat-form-field>
        <cb-select [required]="true"
                   name="garages"
                   label="Garages"
                   [(ngModel)]="plan.amenities.garages"
                   [options]="garageTypes">
        </cb-select>
        <mat-form-field>
            <mat-label>Floor Area</mat-label>
            <input matInput
                   type="number"
                   name="floorArea"
                   required
                   [(ngModel)]="plan.floorArea">
        </mat-form-field>
    </div>
    <div class="cb-margin-left-3x cb-padding-left-3x flex-col flex">
        <cb-select [required]="true"
                   name="category"
                   label="Category"
                   [options]="categories"
                   [valueProp]="WHOLE_OPTION_VALUE_PROP"
                   [(ngModel)]="plan.category">
        </cb-select>
        <cb-checkbox name="isPublished"
                     label="Published"
                     [(ngModel)]="plan.isPublished"></cb-checkbox>
    </div>
</div>
