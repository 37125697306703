import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAddressRegionMappedItem } from '@app/logic/address-regions/interfaces/i.address-region.mapped';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { AddressRegionMappedItem } from '@app/logic/address-regions';
import { IAddressRegionsLogicService } from '@app/logic/address-regions/interfaces/i.address-regions.logic.service';
import { IAddressRegionDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: AddressRegionMappedItem;
}
@Component({
    selector: 'cb-address-region-dialog',
    templateUrl: './address-region-dialog.component.html',
    styleUrls: ['./address-region-dialog.component.scss']
})
export class AddressRegionDialogComponent extends BaseDialogFormViewDirective<IAddressRegionDto, IAddressRegionMappedItem, IAddressRegionsLogicService> {

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<AddressRegionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
    }
}
