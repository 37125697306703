import { Component, Inject } from '@angular/core';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ENQUIRY_ORIGIN_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { LotsLogicService } from '@app/logic/lots';
import { IContactMappedItem } from '@app/logic/contacts/interfaces/i.contact.mapped';
import { NgForm } from '@angular/forms';

interface IData {
    mappedItem: ILeadMappedItem;
}

@Component({
    selector: 'cb-lead-enquiry-origin-edit-dialog',
    templateUrl: './lead-enquiry-origin-edit-dialog.component.html',
    styleUrls: ['./lead-enquiry-origin-edit-dialog.component.scss']
})
export class LeadEnquiryOriginEditDialogComponent extends BaseDialogFormViewDirective<any, any, any> {
    public static readonly MIN_WIDTH = '600px';

    public mappedItem: ILeadMappedItem;
    public form: NgForm;
    public showTitle?: boolean;

    public showHomes: any[];
    public enquiryOrigins = ENQUIRY_ORIGIN_ENUM.toLookup();
    public referredByContact: IContactMappedItem = {} as IContactMappedItem;
    public visitedAShowhome = false;

    constructor(
        public readonly dialogRef: MatDialogRef<LeadEnquiryOriginEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        private readonly lotsLogicService: LotsLogicService,
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.lotsLogicService.getShowhomes().subOnce(showHomes => this.showHomes = showHomes);
    }

    public referrerChanged(): void {
        let id: string;
        let name: string;
        if (this.referredByContact) {
            id = this.referredByContact.$id as string;
            name = `${this.referredByContact.firstName} ${this.referredByContact.lastName}`;
        }
        this.mappedItem.referredById = id;
        this.mappedItem.referredBy = name;
        this.mappedItem.referredByMobilePhone = undefined;
        this.mappedItem.referredByHomePhone = undefined;
    }

    public getReferredByName(): string {
        if (!this.mappedItem.referredById) {
            return '';
        }
        return this.mappedItem.referredBy;
    }

    public requirePhoneNumber(siblingPhoneNumber: string): boolean {
        return !this.mappedItem.referredById && this.mappedItem.referredBy && !siblingPhoneNumber;
    }

    public showHomeChanged(): void {
        if (this.mappedItem.showHomeId > 0) {
            this.mappedItem.otherShowHome = undefined;
        }
    }

}
