import { Component, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDocumentTypeMappedItem } from '@app/logic/document-types/interfaces/i.document-type.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IDocumentTypesLogicService } from '@app/logic/document-types/interfaces/i.document-types.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { DocumentTypeMappedItem } from '@app/logic/document-types/document-type.mapped';
import { FILE_TYPE_ENUM, DOCUMENT_TYPE_CODES_CONST, DocumentTypeCodesConstId, IDocumentTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { NgForm } from '@angular/forms';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
interface IData {
    mappedItem: DocumentTypeMappedItem;
}

@Component({
    selector: 'cb-document-type-dialog',
    templateUrl: './document-type-dialog.component.html',
    styleUrls: ['./document-type-dialog.component.scss']
})
export class DocumentTypeDialogComponent extends BaseDialogFormViewDirective<IDocumentTypeDto, IDocumentTypeMappedItem, IDocumentTypesLogicService> {
    public get mappedItem(): DocumentTypeMappedItem {
        return this.data.mappedItem;
    }

    public fileTypes = FILE_TYPE_ENUM.toLookup();
    private readonly fileTypeLabelIdIndex = this.fileTypes.reduce((prev, curr) => {
        prev[curr.label.toLowerCase()] = curr.id;
        return prev;
    }, {});

    public selectedFileTypes: any[];

    public readonly documentTypeCodes = DOCUMENT_TYPE_CODES_CONST.toLookup({
        transform: (lookup) => {
            lookup.unshift({
                id: '' as DocumentTypeCodesConstId,
                code: '',
                isActive: true,
                label: 'N/A',
            });
            return lookup;
        }
    });

    public originalData: IDocumentTypeDto;

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<DocumentTypeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly zone: NgZone,
    ) {
        super(dialogRef, toastSerivce);
        this.originalData = this.mappedItem.$getMappedDtoItem();
        this.loadFileTypes();
    }

    public showOldCode(): boolean {
        return !isNullOrWhiteSpace(this.originalData.codeName)
            && this.codeInvalid();
    }

    public codeInvalid(): boolean {
        return this.mappedItem?.codeName != null && this.documentTypeCodes.findIndex(x => x.id === this.mappedItem.codeName) < 0;
    }

    public isSaveDisabled(form: NgForm): boolean {
        return form.pristine
            || form.invalid
            || this.codeInvalid();
    }

    public save(): void {
        this.mappedItem.fileTypes = this.selectedFileTypes;
        super.save();
    }

    private loadFileTypes(): void {
        this.selectedFileTypes = this.mappedItem?.fileTypes?.filter(x => this.fileTypeLabelIdIndex[x] > 0).map(x => this.fileTypeLabelIdIndex[x]) ?? [];
    }
}
