<form #searchForm="ngForm">
    <mat-card class="start flex-col"
              infiniteScroll
              [infiniteScrollDistance]="1"
              [fromRoot]="true"
              [infiniteScrollContainer]="'div#mainContainer'"
              (scrolled)="doSearch()">
        <mat-card-content>
            <div class="end-center flex-row">
                <mat-icon>search</mat-icon>
                <mat-form-field class="cb-margin-left cb-margin-right-2x flex">
                    <mat-label>Search</mat-label>
                    <input matInput
                           type="text"
                           name="search"
                           [(ngModel)]="userCacheService.standardPlansSearch.data.query"
                           (ngModelChange)="searchQueryUpdate.next($event)"/>
                </mat-form-field>
                <div class="cb-margin-right-2x end-center flex-row">
                    <button mat-icon-button
                            class="cb-margin-right-2x"
                            [disabled]="false"
                            [matMenuTriggerFor]="menu">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                </div>
                <mat-menu #menu="matMenu">
                    <cb-standard-plan-search-filter *ngIf="costsAreasLoaded && paramsSetupComplete"
                                                    (click)="$event.stopPropagation()"
                                                    (emitEvent)="doSearch()"
                                                    [costs]="costs"
                                                    [areas]="areas"
                                                    [searchData]="userCacheService.standardPlansSearch.data">
                    </cb-standard-plan-search-filter>
                </mat-menu>
                <button mat-icon-button
                        (click)="addStandardPlan()">
                    <mat-icon>add_circle</mat-icon>
                </button>
            </div>
            <div *ngIf="paramsSetupComplete && anyFilterApplied()"
                 class="flex flex-row space-between-center">
                <mat-chip-set>
                    <mat-chip *ngIf="searchParams.query !== null && searchParams.query !== ''"
                              (removed)="remove('query')">
                        Query : {{ searchParams.query }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="searchParams.numberOfBedrooms > 0"
                              (removed)="remove('numberOfBedrooms')">
                        Bedrooms : {{ searchParams.numberOfBedrooms }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="searchParams.numberOfBathrooms > 0"
                              (removed)="remove('numberOfBathrooms')">
                        Bathrooms : {{ searchParams.numberOfBathrooms }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="searchParams.numberOfLivingRooms > 0"
                              (removed)="remove('numberOfLivingRooms')">
                        Living : {{ searchParams.numberOfLivingRooms }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="searchParams.numberOfGarages > 0"
                              (removed)="remove('numberOfGarages')">
                        Garage : {{ searchParams.numberOfGarages }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="showMinCostChip()"
                              (removed)="remove('costToBuild[0]')">
                        Min Cost : ${{ searchParams.costToBuild[0] }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="showMaxCostChip()"
                              (removed)="remove('costToBuild[1]')">
                        Max Cost : ${{ searchParams.costToBuild[1] }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="showMinAreaChip()"
                              (removed)="remove('floorArea[0]')">
                        Min Area : {{ searchParams.floorArea[0] }}m²
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="showMaxAreaChip()"
                              (removed)="remove('floorArea[1]')">
                        Max Area : {{ searchParams.floorArea[1] }}m²
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                </mat-chip-set>
                <button mat-stroked-button
                        style="border-radius: 16px;"
                        (click)="clearAll()">
                    <mat-icon>clear_all</mat-icon>
                    &nbsp;Clear Filter
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</form>
