<div class="flex-row flex"
     style="overflow-x: auto">
    <div class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex-100">
        <div class="start-center flex-row layoutGap-16">
            <h3 class="mat-headline-6 flex">
                Notification Templates
            </h3>
            <button mat-raised-button
                    (click)="onNotificationReportClicked()"
                    color="primary">
                Notification Report
            </button>
            <button *ngIf="permissionsPermissions.canEditSettings()"
                    mat-raised-button
                    (click)="onNotificationTemplatesUpdateClicked()"
                    color="primary">
                Update Notification Templates
            </button>
        </div>
        <mat-tab-group class="cb-margin-top">
            <mat-tab *ngFor="let category of notificationTemplateTree"
                     [label]="getNotificationTemplateLabel(category)">
                <div class="cb-table cb-margin-top">
                    <mat-table [dataSource]="category.children">
                        <ng-container matColumnDef="templateLabel">
                            <mat-header-cell *matHeaderCellDef>
                                Template
                            </mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                {{item.label}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="isActive">
                            <mat-header-cell *matHeaderCellDef>
                                Active
                            </mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <mat-icon class="cb-grey">
                                    {{item.isActive ? "check_box" : "check_box_outline_blank"}}
                                </mat-icon>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions"
                                      stickyEnd>
                            <mat-header-cell *matHeaderCellDef>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let item">
                                <button mat-icon-button
                                        (click)="editItemClicked(item)">
                                    <mat-icon>
                                        edit
                                    </mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                    <cb-info-message *ngIf="category.children && category.children.length === 0"
                                     [message]="'There are no Notification Templates to display'"
                                     [icon]="'info_circle'"></cb-info-message>
                    <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
