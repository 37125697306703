<cb-dialog dialogHeading="View History Item">
    <cb-display-value-new label="Event Type"
                          [value]="data.history.eventTypeDisplay"></cb-display-value-new>
    <cb-display-value-new label="Date"
                          [value]="data.history.date | date"></cb-display-value-new>
    <cb-display-value-new label="User"
                          [value]="data.history.contactName"></cb-display-value-new>
    <cb-display-value-new class="flex"
                          label="Details">
        <ul style="list-style: none">
            <li class="mat-body-2"
                *ngFor="let detail of details">{{detail}}</li>
        </ul>
    </cb-display-value-new>
</cb-dialog>
