import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IGlobalSettingsMappedItem } from '@app/logic/global-settings/interfaces/i.global-settings.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IGlobalSettingsDto, GlobalSettingsLogicService } from '@app/logic/global-settings';
import { IGlobalSettingsLogicService } from '@app/logic/global-settings/interfaces/i.global-settings.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { GlobalSettingsMappedItem } from '@app/logic/global-settings/global-settings.mapped';
import { NgForm } from '@angular/forms';

interface IData {
    mappedItem: GlobalSettingsMappedItem;
}

@Component({
    selector: 'cb-global-settings-dialog',
    templateUrl: './global-settings-dialog.component.html',
    styleUrls: ['./global-settings-dialog.component.scss'],
    providers: [GlobalSettingsLogicService]
})
export class GlobalSettingsDialogComponent extends BaseDialogFormViewDirective<IGlobalSettingsDto, IGlobalSettingsMappedItem, IGlobalSettingsLogicService> {
    public mappedItem: GlobalSettingsMappedItem;
    public key: string;
    @ViewChild('globalSettingsForm', { static: true }) public globalSettingsForm: NgForm;

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<GlobalSettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        protected readonly globalSettingsLogicService: GlobalSettingsLogicService
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        this.key = this.globalSettingsLogicService.getLabelFromEnum(this.mappedItem);
    }

    public getDisabled(): boolean {
        return !this.globalSettingsForm.dirty || !this.globalSettingsForm.valid;
    }
}
