<div class="flex-col cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x">
    <div class="flex-row start-center">
        <h3 class="flex mat-headline-6">Build Overheads</h3>
        <button mat-raised-button
                (click)="newItemClicked();"
                color="primary">
            New Overhead Cost
        </button>
    </div>
    <cb-table class="cb-margin-top"
              [stringColumns]="displayedColumns"
              [loaded]="searchResultsLoaded"
              [minWidth]="1000"
              [maxHeightOffset]="186">
        <tbody cbTableBody>
        <tr *ngFor="let ele of searchResults">
            <td>{{ele.id}}</td>
            <td>{{ele.name}}</td>
            <td>{{ele.description}}</td>
            <td>{{ele.financeCode}}</td>
            <cb-td-checkbox [value]="ele.isActive"></cb-td-checkbox>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        (click)="editItemClickedForDto(ele);">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </cb-table>
</div>
