import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { Observable } from 'rxjs';
import { RESOURCE_CONSENT_STATUS_ENUM, BUILDING_CONSENT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BuildingConsentMappedItem, IResourceConsentMappedItem, IBuildingConsentMappedItem } from '@app/logic/lot-consents';
import { NonWorkingDayLogicService, INonWorkingDayDto } from '@app/logic/non-working-day';
import { IPromised, toPromisedArray } from 'cb-hub-lib';

interface IData {
    mappedItem: any;
}

@Component({
    selector: 'cb-consent-council-received-dialog',
    templateUrl: './consent-council-received-dialog.component.html',
    styleUrls: ['./consent-council-received-dialog.component.scss']
})
export class ConsentCouncilReceievedDialogComponent extends BaseDialogFormViewDirective<any, any, any> {
    public static readonly MIN_WIDTH = '33%';
    public get mappedItem(): IResourceConsentMappedItem | IBuildingConsentMappedItem {
        return this.data.mappedItem;
    }

    public readonly isBuildingConsent = this.mappedItem instanceof BuildingConsentMappedItem;

    public lotNonWorkingDays: IPromised<INonWorkingDayDto[]>;

    constructor(
        public readonly dialogRef: MatDialogRef<ConsentCouncilReceievedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(NonWorkingDayLogicService) public nonWorkingDayLogic: NonWorkingDayLogicService,
    ) {
        super(dialogRef, toastService);
        this.lotNonWorkingDays = toPromisedArray(this.nonWorkingDayLogic.getNonWorkingDaysForLotLocation(this.mappedItem.lotId));
    }

    public receivedDateChanged(): void {
        this.lotNonWorkingDays.$promise.then(x =>
            this.mappedItem.consentDueDate = this.nonWorkingDayLogic.addWorkingDays(x, this.mappedItem.receivedByCouncilDate, 20)
        );
    }

    public saveMethod(): Observable<any> {
        if (this.isBuildingConsent) {
            this.mappedItem.consentStatus = BUILDING_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed;
        } else {
            this.mappedItem.consentStatus = RESOURCE_CONSENT_STATUS_ENUM.SubmittedCouncilConfirmed;
        }
        return this.mappedItem.$save();
    }

}
