import { Component, Inject, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IStandardPlanCostMappedItem } from '@app/logic/standard-plans/interfaces/i.standard-plan-cost.mapped';
import { IStandardPlanStyleDto } from '@app/logic/styles/interfaces/i.style.dto';
import { DesignImageType } from '@app/logic/standard-plans/enums/design.image.type.enums';
import { IAddressRegionDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-add-edit-standard-plan-cost-dialog',
    templateUrl: './add-edit-standard-plan-cost-dialog.html',
    styleUrls: ['./add-edit-standard-plan-cost-dialog.scss']
})
export class AddEditStandardPlanCostDialogComponent {

    public onSave = new EventEmitter();
    public exteriorStyles: IStandardPlanStyleDto[] = [];

    public formattedFloorArea = `${this.data.floorArea}  m²`;

    constructor(
        public readonly dialogRef: MatDialogRef<AddEditStandardPlanCostDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IData) {
        this.exteriorStyles = this.data.styles.filter(x => x.imageType === DesignImageType.Exterior);
    }

    public save(): void {
        this.data.cost.saveStandardPlanCost()
            .subOnce(cost => {
                this.onSave.emit(cost);
                this.dialogRef.close(cost);
            });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public calcCost(): void {
        this.data.cost.m2Cost = Number((this.data.cost.totalCost / this.data.floorArea).toFixed(2));
    }
}


export interface IData {
    cost: IStandardPlanCostMappedItem;
    isEdit: boolean;
    addressRegions: IAddressRegionDto[];
    styles: IStandardPlanStyleDto[];
    floorArea: number;
}

