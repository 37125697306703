<div class="cb-margin"
     *cbWaitFor="searchFiltersLoaded$ | async">
    <mat-card class="cb-margin-bottom flex-col">
        <mat-card-content>
            <form #form="ngForm">
                <div class="start-center flex-row flex">
                    <mat-icon>search</mat-icon>
                    <cb-input type="text"
                              label="Search"
                              [(ngModel)]="searchFilters.data.query"
                              name="search"
                              class="cb-margin-left cb-margin-right-2x flex"></cb-input>
                    <cb-select-list class="cb-margin-right-2x flex"
                                    label="Status"
                                    [allOptions]="statusList"
                                    [multiple]="true"
                                    [(value)]="searchFilters.data.status">
                    </cb-select-list>
                    <cb-select-list class="cb-margin-right-2x flex"
                                    label="Order Reason"
                                    [allOptions]="orderReasonList"
                                    [multiple]="true"
                                    [(value)]="searchFilters.data.reasons">
                    </cb-select-list>
                    <cb-select-list class="cb-margin-right-2x flex"
                                    label="Cost Nature"
                                    [allOptions]="costNatureList"
                                    [multiple]="true"
                                    [(value)]="searchFilters.data.costNatures">
                    </cb-select-list>
                </div>
            </form>
            <cb-table class="cb-margin-top flex"
                      maxHeightOffset="280"
                      label="Manual Orders"
                      [infiniteScrollEnabled]="infiniteScrollEnabled"
                      [fetch]="fetchResults()"
                      [loaded]="loaded"
                      [queryUpdated]="searchFiltersChanged$"
                      [(results)]="results"
                      [(currentPage)]="currentPage">
                <thead cbTableHead>
                <tr>
                    <td>Business Entity</td>
                    <td>Project</td>
                    <td>Job #</td>
                    <td>PO #</td>
                    <td>Order Date</td>
                    <td>Activity</td>
                    <td>Supplier</td>
                    <td>Amount - (Gst Exclusive)</td>
                    <td>Cost Nature</td>
                    <td>Manual Order Reason</td>
                    <td>Reason Description</td>
                    <td>Created By</td>
                    <td>Status</td>
                    <td></td>
                </tr>
                </thead>
                <tbody cbTableBody>
                <tr *ngFor="let element of results">
                    <cb-td-text [value]="element.businessEntityName"></cb-td-text>
                    <cb-td-text [value]="element.projectName + ' - ' + element.releaseStage"></cb-td-text>
                    <cb-td-text [value]="element.jobNumber"></cb-td-text>
                    <cb-td-text [value]="element.orderNumber"></cb-td-text>
                    <cb-td-date [value]="element.orderDate"></cb-td-date>
                    <cb-td-text [value]="element.activityCode + ' - ' + element.activityName"></cb-td-text>
                    <cb-td-text [value]="element.supplierName"></cb-td-text>
                    <cb-td-text [value]="element.amount | currency"></cb-td-text>
                    <cb-td-text [value]="element.costNatureLabel | cbDasher"></cb-td-text>
                    <cb-td-text [value]="element.manualOrderReason | cbDasher"></cb-td-text>
                    <cb-td-text [value]="element.reasonDescription | cbDasher"></cb-td-text>
                    <cb-td-text [value]="element.createdByName"></cb-td-text>
                    <cb-td-text [value]="PURCHASE_ORDER_STATUS_ENUM[element.statusId]"></cb-td-text>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <cb-button icon="open_in_browser"
                                   [disabled]="element.lotId < 1"
                                   (click)="viewLot(element);">
                        </cb-button>
                    </td>
                </tr>
                </tbody>
            </cb-table>
        </mat-card-content>
    </mat-card>
</div>
