import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { USER_TAG_CONSTANTS_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { TabNames } from '@app/views/settings/tabNames';

@Component({
    selector: 'cb-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {

    public currentTab = TabNames.AddressRegions;

    public settingTabs: any[];

    constructor(
        private readonly currentUser: CurrentUserService,
        private readonly route: ActivatedRoute,
        private readonly navigationService: NavigationService) {
    }

    public ngOnInit(): void {
        this.settingTabs = this.getSettingTabs();
    }

    public tabIndexChanged(url: string): void {
        const previousTab = this.currentTab;

        this.navigationService.navigateTo(`/settings/${url}`).then(result => {
            if (result !== false) {
                this.currentTab = url as TabNames;
            } else {
                this.currentTab = previousTab;
            }
        });
    }

    private canViewTab(tabName: string): boolean {
        switch (tabName) {
            case TabNames.ExternalSystemFields:
            case TabNames.ScheduleItemExternal:
                return this.currentUser.isQSTeam();
            case TabNames.FeatureToggles:
                return this.canViewFeatureToggles();
            case TabNames.ScheduleItemTags:
            case TabNames.ScheduleItems:
                return this.canViewScheduleItems();
            case TabNames.BuildMilestoneTemplates:
                return this.currentUser.isAdmin;
            default:
                return true;
        }
    }

    public getSettingTabs(): any[] {
        return Object.values(TabNames).map(tabName => this.createTabObject(tabName));
    }

    private createTabObject(tabName: string): any {
        const formattedName = this.formatTabName(tabName);
        return {
            name: formattedName,
            url: tabName,
            canView: this.canViewTab(tabName)
        };
    }

    private formatTabName(tabName: string): string {
        if (tabName === TabNames.QSWorkflow) {
            return 'QS Workflow';
        }
        return tabName.replace(/-/g, ' ')
            .replace(/\b\w/g, l => l.toUpperCase());
    }

    public canViewScheduleItems(): boolean {
        return this.currentUser.hasTag(USER_TAG_CONSTANTS_CONST.PROCUREMENT_MANAGER);
    }

    public canViewFeatureToggles(): boolean {
        return this.currentUser.hasTag(USER_TAG_CONSTANTS_CONST.SYSTEMADMIN);
    }
}

