import { Component, Input, OnInit } from '@angular/core';
import { DateFilterValue, WipFavoriteViewFilter } from '@app/logic/wip/wip.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule as MatSelectModule } from '@angular/material/select';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import * as _ from 'lodash';
import { CbSelectListModule } from 'cb-hub-lib';
import { LOT_CONTRACT_TYPE_ENUM, LOT_JOB_STATUS_ENUM, USER_TAG_CONSTANTS_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { SelectListItem } from '@app/core/services/enum/enum.service';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { UserSearchService } from '@app/core/services/search/user.search.service';
import { IUserSearchDto } from '@app/logic/users/interfaces/i.user-search.dto';
import { WipHelper } from '@app/views/wip/helpers/wipConstants';
import { DateFilterComponent } from '@app/views/wip/wip-favorite-views-page/date-component/date-filter.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'wip-filters',
    templateUrl: './wip-filters-tab.component.html',
    styleUrls: ['./wip-filters-tab.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        FormsModule,
        CbSelectListModule,
        CbFormsModule,
        DateFilterComponent,
        MatTooltipModule,
        MatIconModule,
    ],
    providers: [
        UserSearchService,
    ]
})
export class WipFiltersTabComponent implements OnInit {
    @Input() filters: WipFavoriteViewFilter[];
    @Input() displayedColumns: string[];

    public jobStatuses: SelectListItem[];
    public contractTypes: SelectListItem[];
    public _buildingConsultantModel = {} as IUserSearchDto;
    public readonly BUILDING_CONSULTANT = USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT;
    protected readonly WipHelper = WipHelper;

    constructor(private userSearchService: UserSearchService) {}

    public ngOnInit(): void {
        this.jobStatuses = _.orderBy(LOT_JOB_STATUS_ENUM.toSelectList(), 'id');
        this.contractTypes = LOT_CONTRACT_TYPE_ENUM.toSelectList();
        this.setBuildingConsultant();

        this.displayedColumns = this.displayedColumns.filter(x=>x === 'jobStatus' ||
            x === 'contractType' ||
            x === 'projectName' ||
            x === 'unconditionalDate' ||
            x === 'consentApprovedDate' ||
            x === 'consentLodgedDate' ||
            x === 'consentDueOutDate' ||
            x === 'settlementDate' ||
            x === 'floorDownDate' ||
            x === 'buildingConsultant');
    }

    public onDateValueChanged(newValue: DateFilterValue, filter: WipFavoriteViewFilter): void {
        filter.dateValue = newValue;
    }

    private setBuildingConsultant(): void {
        const filter = this.buildingConsultantFilter;
        if (filter && filter.filterValue) {
            this.userSearchService.getResults(filter.filterValue, 1).subscribe(
                model => {
                    // it should be better api for userService to get by name or Id
                    this._buildingConsultantModel = model[0];
                }
            );
        }
    }

    public get buildingConsultantFilter(): WipFavoriteViewFilter | undefined {
        return this.filters.find(filter => filter.columnName === 'buildingConsultant');
    }

    public get buildingConsultantModel(): IUserSearchDto {
        return this._buildingConsultantModel;
    }

    public set buildingConsultantModel(value: IUserSearchDto) {
        this._buildingConsultantModel = value;

        if (value && value.id) {
            this.buildingConsultantFilter.filterValue = value.name;
        }
    }

    public addFilter(): void {
        this.filters.push({
            columnName: '',
            filterValue: '',
            id: '00000000-0000-0000-0000-000000000000',
            wipFavoriteViewId: null,
            jobStatuses: [],
            contractTypes: [],
            sortOrder: this.filters.length + 1,
            dateValue: {
                type: 'relative',
                relative: {
                    direction: 'past',
                    value: 1,
                    unit: 'days',
                },
            },
        });
    }

    public removeFilter(index: number): void {
        this.filters.splice(index, 1);
    }
}
