import { Component, Inject, ViewChildren } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { NgForm } from '@angular/forms';
import { ToastService } from '@app/core/services/toast/toast.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColourItemMappedItem, IColourItemDto, IColourItemMappedItem, ColourItemsLogicService } from '@app/logic/colour-items';
import { IColourItemsLogicService } from '@app/logic/colour-items/interfaces/i.colour-items.logic.service';
import { FormMode } from '@app/shared/enums/form';

interface IData {
    mappedItem: ColourItemMappedItem;
}

@Component({
    selector: 'cb-colour-item-dialog',
    templateUrl: './colour-item-dialog.component.html',
    styleUrls: ['./colour-item-dialog.component.scss'],
    providers: [ColourItemsLogicService]
})
export class ColourItemDialogComponent extends
    BaseDialogFormViewDirective<IColourItemDto, IColourItemMappedItem, IColourItemsLogicService> {
    public get colourItemForm(): NgForm {
        return this.colourItemFormParent?.first;
    }
    @ViewChildren('colourItemForm') public colourItemFormParent;
    constructor(
        public readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<ColourItemDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly colourItemLogicService: ColourItemsLogicService
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        if (this.mappedItem.id) {
            this.formMode = FormMode.Edit;
        } else {
            this.formMode = FormMode.Add;
        }
    }

    public selectFile = (newFile: File & string) => {
        this.mappedItem.$colourItemImageFile = newFile;

        const reader = new FileReader();
        reader.onload = (e: any) => {
            this.mappedItem.colourItemImageFile = reader.result as string;
            this.mappedItem.imageUrl = '';
            this.mappedItem.removeImageOnSave = false;
            this.colourItemForm.form.markAsDirty();
        };

        if (newFile) {
            reader.readAsDataURL(newFile);
        }
    };

    public removeImage(): void {
        this.mappedItem.removeImageOnSave = true;
        this.mappedItem.imageUrl = '';
        this.mappedItem.$colourItemImageFile = undefined;
        this.mappedItem.colourItemImageFile = '';
        this.mappedItem.image = undefined;
        this.colourItemForm.form.markAsDirty();
    }

    public async save(): Promise<void> {
        this.saveInProgress = true;
        await this.saveMethod().toPromise();
        const colourItemUpdatedImage = await this.updateColourItemImage();
        this.saveInProgress = false;
        this.mappedItem.$updateThisAndOriginal(colourItemUpdatedImage);
        this.toastService.saveSuccess();
        this.dialogRef.close(this.mappedItem.$getMappedDtoItem());
    }

    public updateColourItemImage = async (): Promise<any> => {
        let returnedColourItem;
        if (this.mappedItem.removeImageOnSave) {
            returnedColourItem = await this.mappedItem.removeImage().toPromise();
            this.mappedItem.image = undefined;
        } else {
            returnedColourItem = await this.mappedItem.saveImage()?.toPromise();
            this.mappedItem.$colourItemImageFile = undefined;
            this.mappedItem.colourItemImageFile = '';
            this.mappedItem.imageUrl = '';
        }
        // perform $postLoad tasks on returned item so updated item in the list is correct
        const toReturn = this.mappedItem.$getMappedDtoItem();
        if (returnedColourItem) {
            toReturn.imageUrl = ColourItemMappedItem.buildImageUrl(returnedColourItem.image, returnedColourItem.id);
            toReturn.image = returnedColourItem.image;
            toReturn.colourItemImageFile = undefined;
            toReturn.$colourItemImageFile = undefined;
        }
        return toReturn;
    };
}
