<div class="start-center flex-row">
    <h3 class="mat-headline-6 flex">
        Supply Types
    </h3>
<button mat-raised-button
            class="cb-margin-right"
            color="primary"
            (click)="generateReport()">
        Supply Types Report
</button>
<button mat-raised-button
            color="primary"
            (click)="addSupplyType()"
            [disabled]="!permissions?.canEdit()"
            *ngIf="isFeatureToggleEnabled">
        Add Supply Types
    </button>
</div>
<form #filterForm="ngForm"
      class="cb-margin-top flex-row"
      *ngIf="isFeatureToggleEnabled">
    <cb-input class="filter-input"
              label="Filter Supply Types"
              name="filterSupplyTypes"
              [(ngModel)]="filterText"
              (keyup)="filterChanged()"></cb-input>
</form>
<cb-supply-types-table [supplyTypes]="filteredResults.value"
                       [loaded]="loaded"
                       [expanded]="expanded"
                       (reloadSupplyTypes)="loadSupplyTypes()"
                       *ngIf="isFeatureToggleEnabled">
</cb-supply-types-table>
<cb-alert-card-new *ngIf="!isFeatureToggleEnabled"
                   [type]="'warning'"
                   [message]="'Supply Types have not been enabled yet'">
</cb-alert-card-new>
