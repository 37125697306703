import { BuildingConsentLogicService, ResourceConsentLogicService } from './lot-consents';

import { NgModule } from '@angular/core';
import { ARProgressPaymentsLogicService } from '@app/logic/ar-progress-payments/ar-progress-payments.logic-service';
import { ARVariationLogicService } from '@app/logic/ar-variation/ar-variation.logic-service';
import { BuildProgrammeTemplateLogicService } from '@app/logic/build-programme-template';
import { BuildTypeLogicService } from '@app/logic/build-type';
import { ContactsLogicService } from '@app/logic/contacts';
import { CostxRateLibraryLogicService } from '@app/logic/costx-rate-library';
import { DocumentTypesLogicService } from '@app/logic/document-types';
import { DocumentGroupLogicService, DocumentsLogicService } from '@app/logic/documents';
import { GenericQuestionsLogicService } from '@app/logic/generic-questions';
import { LocationLogicService } from '@app/logic/location';
import { LotSpecScheduleItemLogicService } from '@app/logic/lot-spec-schedule-item';
import { NotesLogicService } from '@app/logic/notes';
import { NotificationTemplateLogicService } from '@app/logic/notification-template';
import { ProjectsLogicService } from '@app/logic/projects';
import { SpecTemplateLogicService } from '@app/logic/spec-template';
import { StandardPlanLogicService } from '@app/logic/standard-plans';
import { SystemLogsLogicService } from '@app/logic/system-logs';
import { TagsLogicService } from '@app/logic/tags';
import { TempLotsLogicService } from '@app/logic/temp-lots';
import { TradeTypesLogicService, TradeTypesTreeLogicService } from '@app/logic/trade-types';
import { SupplyTypesLogicService, SupplyTypesTreeLogicService } from '@app/logic/supply-types';
import { AddressRegionsLogicService } from './address-regions';
import { ManualOrdersLogicService } from './all-manual-orders/manual-orders.logic.service';
import { BankAccountsLogicService } from './bank-accounts';
import { BuildActivitiesLogicService } from './build-activities';
import { BuildProgrammeLogicService, SkinnyBuildProgrammeLogicService } from './build-programme';
import { BuildProgrammeActivityLogicService } from './build-programme-activity';
import { BuildStagesLogicService } from './build-stages';
import { BusinessAccountLogicService } from './business-accounts';
import { BusinessEntitiesLogicService } from './business-entity';
import { ChangeOptionLineLogicService } from './change-option-line/change-option-line.logic.service';
import { ChangeOptionLogicService } from './change-option/change-option.logic-service';
import { ChangeRecordLogicService } from './change-records/change-record.logic-service';
import { ClientAccountLogicService } from './client-account/client-account.logic.service';
import { ClientSaleLogicService } from './client-sale';
import { ColourItemsLogicService } from './colour-items';
import { ContractGenerationLogicService } from './contract-generation/contract-generation.logic-service';
import { CouncilRfiLogicService } from './council-rfis';
import { CouncilLogicService } from './councils';
import { DefaultSystemAreaDocumentTypesLogicService } from './default-system-area-document-types';
import { DesignComplexityLogicService } from './design-complexity';
import { DesignConceptsLogicService } from './design-concepts';
import { DesignSchemeNotificationStageLogicService } from './design-scheme-notification-stages';
import { DesignSchemesLogicService } from './design-schemes';
import { DocumentNotificationTriggerLogicService } from './document-notification-trigger';
import { DocumentTemplateLogicService } from './document-template';
import { ExternalSystemFieldsLogicService } from './external-system-fields/external-system-fields.logic-service';
import { FeatureToggleLogicService } from './feature-toggle';
import { HistoryLogicService } from './history';
import { IntegrationLogsLogicService } from './integration-logs';
import { LeadLogicService } from './leads';
import { LotConsentLogicService } from './lot-consents/lot-consent-logic.service';
import { LotSpecColourItemLogicService } from './lot-spec-colour-item';
import { LotSpecItemLogicService } from './lot-spec-item';
import { LotSpecScheduleLogicService } from './lot-spec-schedule';
import { LotSpecScheduleManageAttributeItemLogicService } from './lot-spec-schedule-item';
import { LotSpecItemColourYourDreamsLogicService, LotSpecLogicService } from './lot-spec/lot-spec.logic.service';
import { LotsLogicService } from './lots';
import { NonWorkingDayLogicService } from './non-working-day';
import { NotificationLogicService } from './notification';
import { PaymentTemplatesLogicService } from './payment-templates/payment-templates.logic.service';
import { PermissionLogicService } from './permissions/permission.logic.service';
import { PermissionsLogicService } from './permissions/permissions.logic.service';
import { PreConsentPlansLogicService } from './pre-consent-plans';
import { PreConsentsLogicService } from './pre-consents';
import { PricingComplexityLogicService } from './pricing-complexity';
import { PricingRevisionsLogicService } from './pricing-revisions/pricing-revisions.logic.service';
import { CategoryLogicService } from './product-categories';
import { ProductLogicService } from './products';
import { ProductCentreLogicService } from '@app/logic/product-centre/product-centre.logic.service';
import { PurchaseOrdersLogicService } from './purchase-orders';
import { QuoteLineLogicService, QuoteLogicService } from './quote';
import { ReleaseStagesLogicService } from './release-stages/release-stages.logic.service';
import { RolesLogicService } from './roles/roles.logic.service';
import { ScheduleTemplatesItemsLogicService } from './schedule-template-items/schedule-template-items.logic.service';
import { ScheduleTemplatesLogicService } from './schedule-templates/schedule-templates.logic.service';
import { SearchIndexesLogicService } from './search-indexes/search-indexes.logic.service';
import { SellingAgentsLogicService } from './selling-agents/sellingagents.logic.service';
import { SlotExternalSystemFieldValuesLogicService } from './slot-external-system-field-value/slot-external-system-field-value.logic-service';
import { SlotTagsLogicService } from './slot-tags/slot-tags.logic-service';
import { SlotsLogicService } from './slots/slots.logic-service';
import { SpecGroupsLogicService } from './spec-groups';
import { SsrsLogicService } from './ssrs';
import { StandardPlanCostLogicService } from './standard-plans/standard-plan-cost.logic.service';
import { StylesLogicService } from './styles/style.logic.service';
import { SystemAccessLogicService } from './system-access/system-access.logic.service';
import { SystemAreaLogicService } from './system-area';
import { TakeoffTasksLogicService } from './takeoff-tasks/takeoff-tasks-logic-service';
import { TakeoffsLogicService } from './takeoffs/takeoffs.logic.service';
import { TasksLogicService } from './tasks';
import { TeamsLogicService } from './teams/teams.logic.service';
import { UsersLogicService } from './users/';
import { VariationLogicService } from './variation/variation.logic-service';
import { VendorsLogicService } from './vendors/vendors.logic.service';
import { WorkingDrawingsLogicService } from './working-drawings';
import { DesignRequirementsLogicService } from '@app/logic/design-requirements';
import { BuildOverheadCostLogicService } from '@app/logic/build-overhead-costs';
import { LotBuildOverheadCostLogicService } from '@app/logic/lot-build-overhead-costs';
import { AiRateLogicService } from '@app/logic/ai-rate/ai-rate-logic.service';
import { ProductCentreFilterLogicService } from '@app/logic/product-centre/product-centre-filter.logic.service';
import { BuildMilestoneTemplateLogicService } from "@app/logic/build-milestone-templates/build-milestone-template.logic-service";

@NgModule({
    providers: [
        PricingComplexityLogicService,
        TakeoffTasksLogicService,
        RolesLogicService,
        AddressRegionsLogicService,
        SsrsLogicService,
        PurchaseOrdersLogicService,
        BuildActivitiesLogicService,
        BusinessEntitiesLogicService,
        PreConsentPlansLogicService,
        PreConsentsLogicService,
        SystemAccessLogicService,
        PermissionsLogicService,
        PermissionLogicService,
        PricingRevisionsLogicService,
        UsersLogicService,
        TeamsLogicService,
        TagsLogicService,
        SlotTagsLogicService,
        SlotsLogicService,
        BuildProgrammeLogicService,
        CategoryLogicService,
        ProductLogicService,
        ProductCentreLogicService,
        NonWorkingDayLogicService,
        HistoryLogicService,
        ClientSaleLogicService,
        TasksLogicService,
        ProjectsLogicService,
        BuildTypeLogicService,
        TradeTypesLogicService,
        SupplyTypesLogicService,
        ContactsLogicService,
        DocumentsLogicService,
        DocumentGroupLogicService,
        DesignConceptsLogicService,
        DocumentTypesLogicService,
        NotesLogicService,
        LocationLogicService,
        LotsLogicService,
        StandardPlanLogicService,
        StandardPlanCostLogicService,
        TempLotsLogicService,
        ReleaseStagesLogicService,
        StylesLogicService,
        DesignComplexityLogicService,
        DesignSchemeNotificationStageLogicService,
        DocumentNotificationTriggerLogicService,
        BusinessAccountLogicService,
        BuildStagesLogicService,
        LotSpecItemColourYourDreamsLogicService,
        BuildProgrammeTemplateLogicService,
        BuildProgrammeActivityLogicService,
        ManualOrdersLogicService,
        BankAccountsLogicService,
        DefaultSystemAreaDocumentTypesLogicService,
        DesignSchemesLogicService,
        WorkingDrawingsLogicService,
        CouncilRfiLogicService,
        LotConsentLogicService,
        LotSpecLogicService,
        VendorsLogicService,
        SellingAgentsLogicService,
        GenericQuestionsLogicService,
        ClientAccountLogicService,
        BuildingConsentLogicService,
        ResourceConsentLogicService,
        LeadLogicService,
        CouncilLogicService,
        PaymentTemplatesLogicService,
        TradeTypesLogicService,
        SupplyTypesLogicService,
        IntegrationLogsLogicService,
        DocumentTemplateLogicService,
        CostxRateLibraryLogicService,
        SearchIndexesLogicService,
        ScheduleTemplatesItemsLogicService,
        TradeTypesTreeLogicService,
        SupplyTypesTreeLogicService,
        SystemLogsLogicService,
        SkinnyBuildProgrammeLogicService,
        SpecGroupsLogicService,
        LotSpecItemLogicService,
        LotSpecColourItemLogicService,
        ColourItemsLogicService,
        FeatureToggleLogicService,
        ScheduleTemplatesLogicService,
        LotSpecScheduleItemLogicService,
        QuoteLogicService,
        QuoteLineLogicService,
        LotSpecScheduleLogicService,
        LotSpecScheduleManageAttributeItemLogicService,
        ChangeOptionLineLogicService,
        ChangeRecordLogicService,
        VariationLogicService,
        ARVariationLogicService,
        ARProgressPaymentsLogicService,
        ChangeOptionLogicService,
        NotificationTemplateLogicService,
        SpecTemplateLogicService,
        TakeoffsLogicService,
        SystemAreaLogicService,
        TakeoffsLogicService,
        NotificationLogicService,
        ExternalSystemFieldsLogicService,
        SlotExternalSystemFieldValuesLogicService,
        ContractGenerationLogicService,
        DesignRequirementsLogicService,
        BuildOverheadCostLogicService,
        LotBuildOverheadCostLogicService,
        AiRateLogicService,
        ProductCentreFilterLogicService,
        BuildMilestoneTemplateLogicService
    ]
})

export class LogicModule { }
