<mat-card class="cb-margin flex-col flex">
    <mat-card-content>
        <form class="flex-row flex">
            <cb-input label="Search"
                      name="query"
                      class="cb-margin-right-2x"
                      [(ngModel)]="filters.keywords"
                      (change)="refreshData()"></cb-input>
            <cb-select label="Sort Order"
                       name="accountType"
                       class="cb-margin-right-2x"
                       [(ngModel)]="filters.sort"
                       [options]="sortOrders"
                       (change)="refreshData()"></cb-select>
            <cb-select label="Sort Direction"
                       name="tradeType"
                       class="cb-margin-right-2x"
                       [(ngModel)]="filters.sortDirection"
                       [options]="sortDirections"
                       (change)="refreshData()"></cb-select>
        </form>
        <cb-table class="flex"
                  *cbWaitFor="isInitialised"
                  maxHeightOffset="255"
                  [loaded]="true"
                  [infiniteScrollEnabled]="infiniteScrollEnabled"
                  [fetch]="search()"
                  [queryUpdated]="refreshData$"
                  [(results)]="quotes"
                  [(currentPage)]="filters.currentpage">
            <thead cbTableHead>
            <tr>
                <td>Date</td>
                <td>Expiry</td>
                <td>Quote Number</td>
                <td>Job Number</td>
                <td>Description</td>
                <td style="text-align: right">Quote Total</td>
                <td style="text-align: center">Status</td>
                <td></td>
            </tr>
            </thead>
            <tbody cbTableBody>
            <tr *ngFor="let quote of quotes">
                <td>{{ quote.quoteDate | cbDate }}</td>
                <td>{{ quote.expiryDate | cbDate }}</td>
                <td>{{ quote.quoteNumber }}</td>
                <td>{{ quote.jobNumber }}</td>
                <td>{{ quote.description }}</td>
                <td style="text-align: right;">{{ quote.quoteTotal | cbCurrency }}</td>
                <td>{{ quote.statusName }}</td>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button mat-icon-button
                            [matMenuTriggerFor]="quoteMenu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #quoteMenu="matMenu"
                              xPosition="before">
                        <button mat-menu-item
                                (click)="downloadQuoteClicked(quote.quoteId)">
                            <mat-icon class="cb-margin-right-3x">file_download</mat-icon>
                            Download Quote
                        </button>
                        <button mat-menu-item
                                (click)="viewLotClicked(quote)">
                            <mat-icon class="cb-margin-right-3x">pageview</mat-icon>
                            View Lot
                        </button>
                        <button mat-menu-item
                                (click)="viewOrdersClicked(quote)">
                            <mat-icon class="cb-margin-right-3x">pageview</mat-icon>
                            View Orders
                        </button>
                    </mat-menu>
                </td>
            </tr>
            </tbody>
        </cb-table>
    </mat-card-content>
</mat-card>
