<mat-card class="cb-margin flex-col flex">
    <mat-card-content>
        <h3 class="mat-headline-6">House &amp; Land Lots for Sale - Website Entry</h3>
        <div class="start-center flex-row">
            <form #searchForm="ngForm"
                  class="cb-margin-right-3x flex">
                <cb-input class="flex"
                          type="text"
                          name="searchTextInput"
                          label="Search"
                          [(ngModel)]="searchText"
                          (ngModelChange)="searchTextChange()"></cb-input>
            </form>
            <button mat-raised-button
                    (click)="newItemClicked();"
                    [disabled]="!canEdit()"
                    color="primary">
                Add New Lot
            </button>
        </div>
        <div class="cb-table cb-margin-top">
            <mat-table [dataSource]="filteredResults">
                <ng-container matColumnDef="jobNumber">
                    <mat-header-cell *matHeaderCellDef> Job Number</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.jobNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="lotNumber">
                    <mat-header-cell *matHeaderCellDef> Lot Number</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.lotNumber }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="sellingPrice">
                    <mat-header-cell *matHeaderCellDef> Selling Price</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.sellingPrice | cbCurrency:'addGst' }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ LOT_EXTERNAL_STATUS_ENUM[element?.status?.id] | cbDasher }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="address">
                    <mat-header-cell *matHeaderCellDef> Address</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element?.lotAddress?.street }}
                        , {{ element?.lotAddress?.city }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="region">
                    <mat-header-cell *matHeaderCellDef> Region</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element?.lotAddress?.regionName }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="project">
                    <mat-header-cell *matHeaderCellDef> Project</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element?.project?.label }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="businessEntity">
                    <mat-header-cell *matHeaderCellDef> Business Entity</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element?.businessEntity?.label }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="isActive">
                    <mat-header-cell *matHeaderCellDef> Active</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element?.isActive | cbPrettyBool }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="isPublished">
                    <mat-header-cell *matHeaderCellDef> Published</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element?.isPublished | cbPrettyBool }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-icon-button
                                (click)="editItemClicked(element);"
                                [disabled]="!canEdit()">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button
                                matTooltip="Upload Brochure"
                                (click)="uploadBrochure(element);"
                                [disabled]="!canEdit()">
                            <mat-icon>file_upload</mat-icon>
                        </button>
                        <button mat-icon-button
                                matTooltip="Download Brochure"
                                *ngIf="element.canDownloadBrochure"
                                (click)="downloadBrochure(element);">
                            <mat-icon>file_download</mat-icon>
                        </button>
                        <button mat-icon-button
                                matTooltip="Delete Brochure"
                                *ngIf="element.canDeleteBrochure"
                                (click)="deleteBrochure(element);">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <cb-info-message *ngIf="searchResultsLoaded && filteredResults.length === 0"
                             [message]="'There are no Temp Lots to display'"
                             [icon]="'info_circle'"></cb-info-message>
            <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
        </div>
    </mat-card-content>
</mat-card>
