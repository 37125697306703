<cb-dialog dialogHeading="Manage Flyer / Create Schedule">
    <div class="cb-margin-bottom-2x end-center flex-row">
        <button mat-raised-button
                (click)="expandCollapseAll()">
            <span class="start-center flex-row"
                  *ngIf="!expandAll">
                <span class="flex">Expand All&nbsp;</span>
                <mat-icon>keyboard_arrow_down</mat-icon>
            </span>
            <span class="start-center flex-row"
                  *ngIf="expandAll">
                <span class="flex">Collapse All&nbsp;</span>
                <mat-icon>keyboard_arrow_up</mat-icon>
            </span>
        </button>
    </div>
    <form class="form-container flex-col flex"
          #specScheduleItemForm="ngForm">
        <mat-accordion [multi]="true"
                       *ngIf="finishedLoading">
            <mat-expansion-panel *ngFor="let group of groupWithExpansion | cbSortByPipe: 'sortOrder' : 'asc'"
                                 [(expanded)]="group.expanded">
                <mat-expansion-panel-header class="panel-heading">
                    <mat-panel-title class="start-center flex-row">
                        <mat-icon>list</mat-icon>
                        <div class="cb-margin-left-2x flex-col flex">
                            <h1 class="mat-body-strong">{{ group.name }} ({{ getItemsSizeOnThisGroup(group.name) }}
                                )</h1>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex-col">
                    <div class="standardTable">
                        <table>

                            <thead>
                            <tr>
                                <td>Name</td>
                                <td *ngIf="data.isLotSpecScheduleItem">Product Name/Description</td>
                                <td style="width: 160px;">Show in Flyer / Create Schedule</td>
                            </tr>
                            </thead>

                            <tbody *ngIf="data.isLotSpecScheduleItem"
                                   [id]="'buildStageId_' + group.id">
                            <tr *ngFor="let item of getItemsOnThisGroup(group.name)"
                                [id]="'buildProgrammeActivity_' + item.id">
                                <td>{{ item.slotName }}</td>
                                <td *ngIf="data.isLotSpecScheduleItem">{{ item.productDisplay }}</td>
                                <td style="width: 160px;"
                                    *ngIf="item.showInCreateSchedule !== undefined">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                                  (change)="onCreateScheduleFlagChange($event, item)"
                                                  [checked]="item.showInCreateSchedule">
                                    </mat-checkbox>
                                </td>
                            </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <cb-loading-spinner *ngIf="!finishedLoading"></cb-loading-spinner>
    </form>

    <div actions>
        <button *ngIf="canChangeSpecification()"
            mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!isFormEnabled()">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
