<cb-dialog [dialogHeading]="isEdit() ? 'Edit Filter' : 'New Filter'">
    <form #filterForm="ngForm">
        <div class="flex-row">
            <cb-input label="Filter Name"
                      name="filterName"
                      [(ngModel)]="mappedItem.name"
                      [required]="true"
                      [maxlength]="100"
                      [disabled]="reorderingEnabled"></cb-input>
            <cb-checkbox label="Active"
                         name="active"
                         [(ngModel)]="mappedItem.isActive"
                         [disabled]="reorderingEnabled"></cb-checkbox>
        </div>
        <cb-table label="Attributes"
                  [stringColumns]="attributeColumns"
                  [loaded]="true">
            <tbody cbTableBody
                   [dragula]="DRAG_NAME"
                   [(dragulaModel)]="dragulaModel"
                   class="drag-drop">
            <tr *ngFor="let attribute of mappedItem.attributes; let $index = index"
                class="drag-item">
                <td *ngIf="reorderingEnabled">
                    <mat-icon class="dragula-visible drag-handle" fontSet="material-symbols-outlined">drag_indicator</mat-icon>
                </td>
                <td class="dragula-visible">
                    <cb-td-icon svgValue="information-outline"
                                [toolTip]="attribute.description"
                                class="cb-grey">
                    </cb-td-icon>
                </td>
                <cb-td-text [value]="attribute.matchValue" class="dragula-visible"></cb-td-text>
                <cb-td-checkbox [value]="attribute.isRegex"></cb-td-checkbox>
                <cb-td-checkbox [value]="attribute.isActive"></cb-td-checkbox>
                <td>{{ getPrerequisiteFilterName(attribute.prerequisiteFilterMatchId) }}</td>
                <td>{{ attribute.prerequisiteFilterMatchValue ?? '' }}</td>
                <td cbTableStickyCol="end"
                    *ngIf="!reorderingEnabled"
                    cbTableShrinkCol>
                    <button class="cb-margin-right"
                            mat-icon-button
                            (click)="editAttribute(attribute)">
                        <mat-icon>
                            edit
                        </mat-icon>
                    </button>
                    <button mat-icon-button
                            (click)="removeFilterAttribute($index)">
                        <mat-icon>
                            remove_circle
                        </mat-icon>
                    </button>
                </td>
            </tr>
            <tr class="cb-table-footer-row">
                <td colspan="6">
                    <mat-slide-toggle
                            name="reorderingEnabled"
                            *ngIf="canReorderAttributes"
                            [checked]="reorderingEnabled"
                            (click)="reorderingEnabled = !reorderingEnabled">
                        Enable Reordering
                    </mat-slide-toggle>
                </td>
                <td cbTableStickyCol="end"
                    cbTableShrinkCol>
                    <button mat-icon-button
                            (click)="addAttribute()"
                            [disabled]="reorderingEnabled">
                        <mat-icon>
                            add
                        </mat-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </cb-table>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="filterForm.invalid || reorderingEnabled || (filterForm.pristine && !dataChanged)"
                (cbClick)="save()"
                [clickLimit]="1">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
