<div class=" flex-row">
    <div class="cb-margin-right-2x flex-col flex">
        <h4 class="mat-h4 cb-margin-top title-divider"><b>Submission</b></h4>
        <cb-display-value-new label="Date Submitted"
                              [value]="mappedItem.submittedToCouncilDate | date"></cb-display-value-new>
        <cb-display-value-new label="Submitted by"
                              [value]="mappedItem.submittedToCouncilByUserName"></cb-display-value-new>
        <h4 class="mat-h4 title-divider cb-margin-top"><b>Processing</b></h4>
        <cb-display-value-new label="Received by Council"
                              [value]="mappedItem.receivedByCouncilDate | date"></cb-display-value-new>
        <cb-display-value-new label="Original Consent Due Date"
                              [value]="mappedItem.consentDueDate | date"></cb-display-value-new>
        <cb-display-value-new label="Number of RFIs"
                              [value]="numberOfRfis"></cb-display-value-new>
        <cb-display-value-new label="Estimated Consent Due Date"
                              [value]="mappedItem.consentEstimatedDueDate | date"></cb-display-value-new>
    </div>
    <div class="flex-col flex">
        <h4 class="mat-h4 cb-margin-top title-divider"><b>Consent Granted</b></h4>
        <cb-display-value-new label="Consent Granted Date"
                              [value]="mappedItem.consentGrantedDate | date"></cb-display-value-new>
        <cb-display-value-new label="Working Days Since Submission"
                              [value]="workingDaysSinceSubmission"></cb-display-value-new>
        <cb-display-value-new *ngIf="isBuildingConsent"
                              label="Granted by"
                              [value]="buildingConsent.grantedByUserName"></cb-display-value-new>
        <cb-display-value-new label="Consent Expiry Date"
                              [value]="mappedItem.consentExpiryDate | date"></cb-display-value-new>
        <cb-display-value-new *ngIf="isBuildingConsent"
                              label="CCC Expiry Date"
                              [value]="buildingConsent.codeOfComplianceExpiryDate | date"></cb-display-value-new>
    </div>
</div>
