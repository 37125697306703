import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { LotBuildPermissions } from '@app/core/permissions';
import { IProductMappedItem, ProductLogicService } from '@app/logic/products';
import { PRODUCT_TYPE_ENUM, OFFERING_STATUS_ENUM, UNIT_OF_MEASURE_ENUM, IBundleTemplateDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { BundleTemplatesLogicService } from '@app/logic/bundle-templates';
import { ManageProductDialogComponent } from './manage-product-dialog.component';
import { TradeTypesLogicService } from '@app/logic/trade-types/trade-types.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
interface IData {
    mappedItem: IProductMappedItem;
}

@Component({
    templateUrl: './manage-product-dialog.component.html',
    styleUrls: ['./manage-product-dialog.component.scss'],
    providers: [
        LookupService,
        BundleTemplatesLogicService,
        ProductLogicService,
        TradeTypesLogicService
    ]
})
export class ManageBundleDialogComponent extends ManageProductDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '600px';
    public selectedCategory: any;
    public uom = UNIT_OF_MEASURE_ENUM.toLookup();
    public PRODUCT_TYPE_ENUM = PRODUCT_TYPE_ENUM;
    public OFFERING_STATUS_ENUM = OFFERING_STATUS_ENUM;
    public tradeTypes;
    public initialBundleTemplateId = 0;
    public bundleTemplates: Array<IBundleTemplateDto>;

    constructor(
        public readonly dialogRef: MatDialogRef<ManageProductDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(LookupService) public readonly lookupService: LookupService,
        @Inject(LotBuildPermissions) public readonly lotBuildPermissions: LotBuildPermissions,
        protected readonly bundleTemplatesLogicService: BundleTemplatesLogicService,
        protected readonly productsLogicService: ProductLogicService,
        protected readonly tradeTypesLogicService: TradeTypesLogicService,
        protected readonly cbDialog: CbDialogService,
    ) {
        super(
            dialogRef,
            data,
            toastService,
            lookupService,
            lotBuildPermissions,
            bundleTemplatesLogicService,
            productsLogicService,
            tradeTypesLogicService,
            cbDialog
        );
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.initialBundleTemplateId = this.mappedItem.bundleTemplateId;
        this.bundleTemplateChanged(undefined);
        this.bundleTemplatesLogicService.$getList().subOnce({
            next: this.handleBundleTemplates
        });
    }
    private readonly handleBundleTemplates = (templates: any): void => {
        if (this.mappedItem.bundleTemplateId != null || this.mappedItem.templateItems?.length > 0) {
            templates.unshift({ id: 0, name: 'No Change' } as any);
            const noneOptionLabel = 'Remove Template';
            templates.unshift({ id: 'noneOption', name: noneOptionLabel } as any);
        }
        this.bundleTemplates = templates;
    };

    public bundleTemplateChanged = (selectedItem: number | string): void => {
        if (selectedItem === 'noneOption') {
            this.mappedItem.bundleTemplateId = undefined;
            this.mappedItem.bundleTemplateName = undefined;
        }
        if (this.isEdit()) {
            this.mappedItem.isActive =
                this.mappedItem.bundleTemplateId == null ||
                this.mappedItem.bundleTemplateIsComplete();

            this.mappedItem.status = this.mappedItem.isActive ?
                OFFERING_STATUS_ENUM.Active : OFFERING_STATUS_ENUM.TemporarilyUnavailable;
        } else if (this.isAdd()) {
            this.mappedItem.isActive = true;
            this.mappedItem.status = 0;
        }
    };
}
