<div class="flex-row">
    <div class="flex-row flex"
         #infiniteScrollContainerBusinessEntities
         style="overflow-x: auto">
        <div *ngIf="isView()"
             class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex-100">
            <div class="end-center flex-row layoutGap-8">
                <button mat-raised-button
                        (click)="generateReport();"
                        color="primary">
                    Business Entity Report
                </button>
                <button mat-raised-button
                        (click)="newBusinessEntityClicked();"
                        color="primary">
                    New Business Entity
                </button>
            </div>
            <cb-table [loaded]="true"
                      [stringColumns]="businessEntitiesColumns"
                      tableLabel="Business Entities">
                <tbody cbTableBody>
                <tr *ngFor="let item of searchResults">
                    <td>
                        {{ item.name }}
                    </td>
                    <td>
                        <mat-icon class="cb-grey">
                            {{ item.isActive ? 'check_box' : 'check_box_outline_blank' }}
                        </mat-icon>
                    </td>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <button mat-icon-button
                                (click)="editClicked(item)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </tr>
                </tbody>
            </cb-table>
        </div>
        <div *ngIf="isEdit()"
             class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x flex-col flex">
            <div class="flex-row flex">
                <form class="flex-100"
                      #businessEntityForm="ngForm">
                    <mat-tab-group class="flex" mat-stretch-tabs="false">
                        <mat-tab label="Business Entity Details">
                            <cb-business-entity-details *ngIf="mappedItem && businessEntityForm"
                                                        [mappedItem]="mappedItem"
                                                        [businessEntityForm]="businessEntityForm">
                            </cb-business-entity-details>
                        </mat-tab>
                        <mat-tab label="Default Installers">
                            <cb-business-entity-default-installers class="flex-col"
                                                                   *ngIf="mappedItem && businessEntityForm"
                                                                   [mappedItem]="mappedItem"
                                                                   [businessEntityForm]="businessEntityForm">
                            </cb-business-entity-default-installers>
                        </mat-tab>
                        <mat-tab *ngIf="isSupplierTypesEnabled"
                                 label="Default Suppliers">
                            <cb-business-entity-default-suppliers class="flex-col"
                                                                   *ngIf="mappedItem && businessEntityForm"
                                                                   [mappedItem]="mappedItem"
                                                                   [businessEntityForm]="businessEntityForm">
                            </cb-business-entity-default-suppliers>
                        </mat-tab>
                        <mat-tab label="Contingency Configurations">
                            <cb-business-entity-build-contingency-records class="flex-col"
                                                                          *ngIf="mappedItem && businessEntityForm"
                                                                          [mappedItem]="mappedItem"
                                                                          [businessEntityForm]="businessEntityForm">
                            </cb-business-entity-build-contingency-records>
                        </mat-tab>
                    </mat-tab-group>
                </form>
            </div>
        </div>
    </div>
    <cb-side-bar class="flex-10"
                 *ngIf="isEdit() && mappedItem">
        <div class="flex-col flex">
            <h3 class="mat-headline-6">
                {{sideBarBusinessEntityCode}}
            </h3>
            <h3>
                {{sideBarBusinessEntityName}}
            </h3>
        <button class="cb-margin"
                mat-raised-button
                color="primary"
                (click)="generateReport();">
            Report
        </button>
        <button mat-raised-button
                class="cb-margin"
                color="primary"
                (click)="save()"
                [disabled]="!formDirty() || !formValid()">
            Save
        </button>
        <button mat-raised-button
                class="cb-margin"
                color="primary"
                (click)="goBack()"
                [disabled]="formDirty()">
            Back
        </button>
            <button mat-raised-button
                    class="cb-margin"
                    color="primary"
                    (click)="goBack()"
                    [disabled]="!formDirty()">
                Cancel
            </button>
        </div>
    </cb-side-bar>
</div>
