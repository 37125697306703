import { IDesignComplexityMappedItem } from '@app/logic/design-complexity/interfaces/i.design-complexity.mapped';
import { Component } from '@angular/core';
import { DesignComplexityLogicService } from '@app/logic/design-complexity/design-complexity.logic.service';
import { MatDialogRef } from '@angular/material/dialog';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { DesignComplexityMappedItem } from '@app/logic/design-complexity/design-complexity.mapped';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { DesignComplexityDialogComponent } from '../design-complexity-dialog/design-complexity-dialog.component';
import { IDesignComplexityDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-design-complexity',
    templateUrl: './design-complexity.component.html',
    styleUrls: ['./design-complexity.component.scss'],
    providers: [DesignComplexityLogicService, UsersLogicService]
})
export class DesignComplexityComponent extends BaseSimpleListViewDirective<IDesignComplexityDto, DesignComplexityLogicService> {
    public readonly columns = [
        'Complexity',
        'Target Work Hours',
        'Target Completion Hours',
        'Active',
        ''
    ];

    constructor(
        protected readonly designComplexityLogicService: DesignComplexityLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly cbDialog: CbDialogService,
    ) {
        super(
            cbDialog,
            designComplexityLogicService,
            DesignComplexityDialogComponent,
            'Design Complexity',
            DesignComplexityMappedItem,
            permissionsPermissions
        );
    }

    // overriding default dialog due to minWidth
    public openDialog(mappedItem: IDesignComplexityMappedItem, dialogHeading: string): MatDialogRef<any> {
        return this.cbDialog.open(DesignComplexityDialogComponent, {
            minWidth: '25%',
            data: {
                dialogHeading,
                mappedItem,
                ...(this.otherData && { otherData: this.otherData }),
            },
        });
    }

    public sortSearchResults(searchResults: Array<IDesignComplexityDto>): Array<IDesignComplexityDto> {
        return searchResults.sort((a, b) => a.complexity.localeCompare(b.complexity));
    }
}



