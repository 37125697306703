import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { IWipService } from '@app/logic/wip/IWipService';

export interface WipDocument {
    consentApprovedDate: string;
    consentDueOutDate: string;
    unconditionalDate: string;
    contractConditions: string;
    contractWorksExpiryDate?: string;
    settlementDate: string;
    floorDownDate: string;
    constructionManager: string;
    businessEntityId: number;
    jobNumber: string;
    jobStatus: string;
    lotNumber: number;
    lotId: number;
    releaseStage: string;
    projectName: string;
    businessEntity: string;
    address: string;
    contractType: string;
    district: string;
    contractTypeId: number;
    releaseStageId: number;
    buildType: string;
    client: string;
    buildingConsultant: string;
    titleDate?: string;
    titleStatus: string;
    unconditional: boolean;
    consentLodgedDate: string;
    id: string;
    editingSettlementDate?: boolean;
    editingFloorDownDate?: boolean;
    editingContractConditions?: boolean;
}

export interface WipFavoriteView {
    userId: string | null;
    name: string;
    isDefault: boolean;
    id: string | null;
    columns: string[];
    stickyColumns: string[];
    filters: WipFavoriteViewFilter[];
}

export interface DateFilterValue {
    type: 'absolute' | 'relative';
    fromDate?: Date;
    toDate?: Date;
    relative?: {
        direction: 'future' | 'past';
        value: number;
        unit: 'days' | 'weeks' | 'months' | 'quarters' | 'years';
    };
}


export interface WipFavoriteViewFilter {
    columnName: string;
    wipFavoriteViewId: string;
    sortOrder: number;
    filterValue: string | null;
    contractTypes: number[];
    jobStatuses: number[];
    dateValue?: DateFilterValue;
    id: string;
}


export interface WipSearchDto {
    currentPage: number;
    areas: number[];
    districts: number[];
    viewId: string;
    projectNames: string[];
    sortBy: string;
    sortDirection: string;
    buildingConsultants?: string[];
    releaseStages?: number[];
    businessEntities?: number[];
    contractTypes?: number[];
}

export interface UpdateDateDto {
    lotId: number;
    date: string;
}

export interface UpdateContractConditionsDto {
    lotId: number;
    contractConditions: string;
}

@Injectable({
    providedIn: 'root'
})
export class WipService implements IWipService{
    private baseUrl = 'wip';

    constructor(
        protected readonly $http: HttpWrapperService) { }

    public getAllWipDocuments(): Observable<WipDocument[]> {
        return this.$http.get<ISearchResult<WipDocument>>(this.baseUrl +'/search/getAll' ).pipe(
            map(result => result.items)
        );
    }

    public refreshDocuments(): Observable<boolean> {
        return this.$http.get<boolean>(this.baseUrl + '/refresh');
    }

    public loadMoreWipDocuments(criteria: WipSearchDto): Observable<WipDocument[]> {
        return this.$http.post<ISearchResult<WipDocument>>(this.baseUrl + '/search/get', criteria).pipe(
            map(result => result.items)
        );
    }

    public getFavoriteViewsForUser(): Observable<WipFavoriteView[]> {
        return this.$http.get<WipFavoriteView[]>(`${this.baseUrl}/favorite/getAll`);
    }

    public getFavoriteView(id: string): Observable<WipFavoriteView> {
        return this.$http.post<WipFavoriteView>(`${this.baseUrl}/favorite/get`, id);
    }

    public updateFavoriteView(dto: WipFavoriteView): Observable<WipFavoriteView> {
        return this.$http.post<WipFavoriteView>(`${this.baseUrl}/favorite/update`, dto);
    }

    public createFavoriteView(dto: WipFavoriteView): Observable<WipFavoriteView> {
        return this.$http.post<WipFavoriteView>(`${this.baseUrl}/favorite/create`, dto);
    }

    public deleteFavoriteView(id: string): Observable<void> {
        return this.$http.delete<void>(`${this.baseUrl}/favorite/delete/${id}`);
    }

    public updateSettlementDate(dto: UpdateDateDto): Observable<void> {
        return this.$http.post<void>(`${this.baseUrl}/updateSettlementDate`, dto);
    }

    public updateContractConditions(dto: UpdateContractConditionsDto): Observable<void> {
        return this.$http.post<void>(`${this.baseUrl}/updateContractConditions`, dto);
    }

    public updateFloorDownDate(dto: UpdateDateDto): Observable<void> {
        return this.$http.post<void>(`${this.baseUrl}/updateFloorDownDate`, dto);
    }
}
