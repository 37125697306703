import {PRODUCT_SEARCH_TYPE_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {takeOne} from 'cb-hub-lib';
import {Injectable} from '@angular/core';
import {SearchService} from './base.search.service';
import {ProductLogicService} from '@app/logic/products';
import {IProductSearchDto} from '@app/logic/products/i.product-search.dto';

interface IProductAutocompleteScrollerSearchParams {
    activeOnly: boolean;
    cat: number;
    incCategories: boolean;
    t: number;
    hideCompositeItems: boolean;
    st: boolean;
    sp: boolean;
}
class ProductAutocompleteScrollerSearchParams implements IProductAutocompleteScrollerSearchParams {
    constructor(
        public activeOnly: boolean,
        public cat: number,
        public incCategories: boolean,
        public t: number,
        public hideCompositeItems: boolean,
        // strict search
        public st: boolean,
        public sp: boolean
    ) { }
}

@Injectable()
export class ProductSearchService extends SearchService {
    public categoryId: number;
    public productType: number;
    public hideCompositeItems: boolean;
    public strictSearch: boolean;
    public includeCategories: boolean;
    public orderByStandardProduct: boolean;

    public get extraSearchParams(): ProductAutocompleteScrollerSearchParams {
        return new ProductAutocompleteScrollerSearchParams(
            true,
            // This line here causes the search to omit "&cat=0"
            // Which is what we want (0 causes no results)
            this.categoryId || undefined,
            this.includeCategories,
            this.productType || PRODUCT_SEARCH_TYPE_ENUM.All,
            this.hideCompositeItems,
            this.strictSearch,
            this.orderByStandardProduct
        );
    }

    constructor(
        public readonly logicService: ProductLogicService,
        public readonly dialog: MatDialog
    ) {
        super();
    }

    public getResults(query: string, currentPage: number): Observable<IProductSearchDto[]> {
        return new Observable<IProductSearchDto[]>((subscriber) => {
            if (!this.searchResultsLoaded) {
                this.searchResultsLoaded = true;
                const resultsObservable = this.logicService.$getSearchList({
                    query,
                    currentPage,
                    pageSize: this.pageSize,
                    ...this.extraSearchParams,
                });
                resultsObservable
                    .subOnce(x => {
                        this.setSearchResults<IProductSearchDto>(x);
                        subscriber.next(this.searchResults$.value as IProductSearchDto[]);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value as IProductSearchDto[]);
                subscriber.complete();
            }
        });
    }

    public readonly doSearch = (query: string, page: number): Observable<IProductSearchDto[]> => {
        this.searchResultsLoaded = false;
        return this.getResults(query, page).pipe(takeOne());
    };
}
