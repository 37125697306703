<form class="flex-col flex"
      #form="ngForm">
    <mat-card>
        <mat-card-header class="cb-margin-bottom-2x">
            <mat-card-title>
                <div class="start-center flex-row">
                    <mat-icon [ngClass]="getStatusIconClass()"
                              class="help-cursor cb-margin-right-2x"
                              [matTooltip]="getStatusIconTitle()">{{ getStatusIconIcon() }}
                    </mat-icon>
                    <span>Import #{{ mappedItem.id }}</span></div>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content class="cb-margin-bottom cb-margin-bottom">
            <div class="flex-col flex">
                <cb-user-date-time-new label="Imported"
                                       *ngIf="mappedItem?.createdDate && mappedItem?.createdByName"
                                       [date]="mappedItem?.createdDate"
                                       [userFullName]="mappedItem?.createdByName">
                </cb-user-date-time-new>
                <cb-user-date-time-new label="Updated"
                                       *ngIf="mappedItem?.updatedDate && mappedItem?.updatedByName"
                                       [date]="mappedItem?.updatedDate"
                                       [userFullName]="mappedItem?.updatedByName">
                </cb-user-date-time-new>
            </div>
        </mat-card-content>
        <mat-divider class="card-divider"></mat-divider>
        <mat-card-actions class="end-center flex-row layoutGap-16">
            <cb-button color="white"
                       [isStroked]="true"
                       icon="file_download"
                       buttonLabel="Download"
                       *ngIf="mappedItem.transferHistoryId"
                       (clicked)="getTransferHistoryFile()">
            </cb-button>
            <span class="flex"></span>
            <cb-button color="accent"
                       [isStroked]="true"
                       [disabled]="!canViewTakeOff"
                       *ngIf="mappedItem.transferHistoryId"
                       (clicked)="goToTakeOff()">
                Manage
            </cb-button>
        </mat-card-actions>
    </mat-card>
</form>
