<mat-card class="cb-margin flex-col flex"
          *cbWaitFor="searchFiltersLoaded$ | async">
    <mat-card-content>
        <form class="start-center flex-row"
              #form="ngForm">
            <ng-container *cbWaitFor="constructionManagers">
                <cb-select class="flex cb-margin-right-3x"
                           label="Construction Manager"
                           name="constructionManager"
                           [options]="constructionManagers"
                           [(ngModel)]="selectedConstructionManager"
                           (ngModelChange)="selectConstructionManager()"
                           [disabled]="searchIsLoading"
                           valueProp="id"
                           labelProp="label"></cb-select>
                <cb-select-list class="flex cb-margin-right-3x"
                                label="Invoice Status"
                                [allOptions]="invoiceStatusList"
                                [multiple]="false"
                                [disabled]="searchIsLoading"
                                [value]="searchFilters.data.invoiceReceived"
                                (selectChanged)="selectedInvoiceStatusChanged($event)">
                </cb-select-list>
                <cb-select class="flex cb-margin-right-2x"
                           label="Order By"
                           name="orderBy"
                           [options]="orders"
                           [ngModel]="searchFilters.data.order"
                           [disabled]="searchIsLoading"
                           (ngModelChangeDebounced)="searchFilters.data.order = $event"></cb-select>
            </ng-container>
        </form>
        <cb-table *ngIf="selectedConstructionManager"
                  class="cb-margin-top flex"
                  maxHeightOffset="280"
                  [loaded]="true"
                  [fetch]="search()"
                  [queryUpdated]="searchFiltersChanged$"
                  [infiniteScrollEnabled]="infiniteScrollEnabled"
                  [(results)]="results"
                  [(currentPage)]="currentPage">
            <thead cbTableHead>
            <tr>
                <td>
                    <button mat-icon-button
                            [matMenuTriggerFor]="checkedMenu"
                            (click)="$event.stopPropagation()">
                        <mat-icon>playlist_add_check</mat-icon>
                    </button>
                    <mat-menu #checkedMenu="matMenu">
                        <button mat-menu-item
                                (click)="toggleAllChecked($event)">
                            <mat-icon>done_all</mat-icon>
                            <span>&nbsp;{{ toggleAllLabel }}</span>
                        </button>
                        <button mat-menu-item
                                [disabled]="!isAnyPurchaseOrderSelected"
                                (click)="completeAllChecked()">
                            <mat-icon>playlist_add_check</mat-icon>
                            <span>&nbsp;{{ completeMultipleLabel }}</span>
                        </button>
                    </mat-menu>
                </td>
                <td>PO #</td>
                <td>Job Number</td>
                <td>Lot Number + Street</td>
                <td>Client</td>
                <td>Activity</td>
                <td>End Date</td>
                <td>Supplier</td>
                <td>Construction Manager</td>
                <td>PO Value</td>
                <td>Compliance</td>
                <td>Invoice Status</td>
                <td></td>
            </tr>
            </thead>
            <tbody cbTableBody>
            <tr *ngFor="let element of results">
                <td>
                    <mat-checkbox [(ngModel)]="checkedPurchaseOrderIds[element.id]"
                                  [ngModelOptions]="{ standalone: true}">
                    </mat-checkbox>
                </td>
                <td>{{ element.orderNumber }}</td>
                <td>{{ element.jobNumber }}</td>
                <td>{{ element.lotNumber + ', ' + element.lotStreet }}</td>
                <td>{{ element.clientName | cbDasher }}</td>
                <td>{{ element.activityName }}</td>
                <cb-td-date [value]="element.activityEndDate"></cb-td-date>
                <td>{{ element.supplierName }}</td>
                <td>{{ element.constructionManagerName }}</td>
                <td>{{ element.orderSubtotal | currency }}</td>
                <td>{{ element.complianceDocumentStatusLabel }}</td>
                <td>{{ element.hasMatchedInvoices ? 'Received' : 'Not Received' }}</td>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button [matMenuTriggerFor]="menu"
                            mat-icon-button>
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                                (click)="markComplete(element)">
                            <mat-icon>check</mat-icon>
                            &nbsp;Mark Complete
                        </button>
                        <button mat-menu-item
                                (click)="viewActivity(element, $event)">
                            <mat-icon>forward</mat-icon>
                            &nbsp;View Activity
                        </button>
                        <button mat-menu-item
                                (click)="downloadPo(element)">
                            <mat-icon>file_download</mat-icon>
                            &nbsp;Download PO
                        </button>
                    </mat-menu>
                </td>
            </tr>
            </tbody>
        </cb-table>
    </mat-card-content>
</mat-card>
