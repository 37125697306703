<mat-form-field class="flex"
                [ngClass]="{'cb-soft-required': softRequired && !required }"
                *ngIf="!readonly">
    <mat-label>{{ label }}</mat-label>

    <!-- Optional Prefix Icons -->
    <mat-icon [svgIcon]="svgIcon"
              *ngIf="svgIcon"
              matPrefix
              class="cb-margin-right"></mat-icon>
    <mat-icon *ngIf="icon"
              matPrefix
              class="cb-margin-right">{{icon}}</mat-icon>

    <!-- Input Field -->
    <input matInput
           class="input"
           [name]="internalName"
           [(ngModel)]="value"
           [ignoreDirty]="ignoreDirty"
           [type]="type"
           [min]="minimum"
           [max]="maximum"
           [required]="required"
           [email]="type === 'email'"
           [maxlength]="maxlength"
           [autocomplete]="autocomplete"
           [pattern]="pattern"
           [disabled]="disabled"
           [maskType]="maskType"
           (ngModelChange)="modelChanged ? modelChanged($event) : null"
           (keydown.enter)="enterPressed($event)"
           [ngStyle]="suffixWidth ? { 'padding-right': suffixWidth + 'px' } : null" />

    <!-- Suffix -->
    <span #suffixEle
          matSuffix
          class="suffix mat-body-1">{{suffix}}</span>
</mat-form-field>

<!-- Readonly Display -->
<ng-container *ngIf="readonly">
    <cb-display-value-new [label]="label">
        <div class="start-center flex-row">
            <!-- Optional Icons -->
            <div class="flex-col" *ngIf="icon || svgIcon">
                <mat-icon [svgIcon]="svgIcon"
                          *ngIf="svgIcon"
                          class="cb-margin-right"></mat-icon>
                <mat-icon *ngIf="icon"
                          class="cb-margin-right">{{icon}}</mat-icon>
            </div>
            <!-- Value and Suffix -->
            {{value}} <span class="suffix mat-body-1">{{suffix}}</span>
        </div>
    </cb-display-value-new>
</ng-container>
