import {Component, Inject, OnInit} from '@angular/core';
import {DocumentGroupCodesConstId, IDocumentTypeDto, SystemAreaEnumId} from '@classictechsolutions/hubapi-transpiled-enums';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DocumentTypesLogicService} from '@app/logic/document-types';
import { WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';
import {DocumentsLogicService} from '@app/logic/documents';
import _ from 'lodash';

interface IData {
    entityId: number;
    systemArea: SystemAreaEnumId;
    documentStubGroupCode: DocumentGroupCodesConstId;
    stubTypeForTitle: string;
}

@Component({
    selector: 'cb-document-stub-dialog',
    templateUrl: './document-stub-dialog.component.html',
    styleUrls: ['./document-stub-dialog.component.scss']
})
export class DocumentStubDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '500px';
    public readonly WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;
    public unusedDocumentTypes: IDocumentTypeDto[] = [];
    public documentTypesToEnsure: IDocumentTypeDto[] = [];

    public _selectedDocumentType: IDocumentTypeDto;

    constructor(
        public readonly dialogRef: MatDialogRef<DocumentStubDialogComponent>,
        private readonly documentTypesLogicService: DocumentTypesLogicService,
        private readonly documentsLogic: DocumentsLogicService,
        private readonly documentsLogicService: DocumentsLogicService,
        @Inject(MAT_DIALOG_DATA) public data: IData) {
    }

    public ngOnInit(): void {
        this.loadUnusedDocumentTypes();
    }

    private loadUnusedDocumentTypes(): void {
        this.documentTypesLogicService
            .getUnusedDocumenTypesForEntity(this.data.systemArea, this.data.entityId, this.data.documentStubGroupCode)
            .subOnce((result) => {
                this.unusedDocumentTypes = result;
            });
    }

    public addSelectedDocumentType(): void {
        if (this._selectedDocumentType.id > 0) {
            this.documentTypesToEnsure.push(this._selectedDocumentType);
            _.remove(this.unusedDocumentTypes, {id: this._selectedDocumentType.id}).pop();
            this._selectedDocumentType = undefined;
        }
    }

    public removeSelectedDocumentType(selectedDocumentType: IDocumentTypeDto): void {
        if (selectedDocumentType.id > 0) {
            _.remove(this.documentTypesToEnsure, {id: selectedDocumentType.id}).pop();
            this.unusedDocumentTypes.push(selectedDocumentType);
        }
    }

    public saveEnsureDocumentTypes(): void {
        this.documentsLogicService
            .ensureDocumentTypes(this.data.entityId,  this.data.systemArea, this.documentTypesToEnsure, this.data.documentStubGroupCode)
            .subOnce((result) => {
                this.dialogRef.close(true);
            });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
