import {
    Component,
    EventEmitter,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { WipConstants, WipHelper } from '@app/views/wip/helpers/wipConstants';
import {
    WipFavoriteView,
    WipFavoriteViewFilter,
    WipService,
} from '@app/logic/wip/wip.service';

import { CommonModule } from '@angular/common';
import { WipTableComponent } from '@app/views/wip/wip-table/wip-table.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { WipFiltersTabComponent } from '@app/views/wip/wip-favorite-views-page/wip-filters-tab/wip-filters-tab.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DragulaModule, DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

interface CombinedColumn {
    name: string;
    active: boolean;
    sticky: boolean;
}

@Component({
    selector: 'cb-wip-favorite-views-page',
    templateUrl: './wip-favorite-views-page.component.html',
    styleUrls: ['./wip-favorite-views-page.component.scss'],
    standalone: true,
    imports: [
        WipTableComponent,
        MatTabsModule,
        BrowserModule,
        FormsModule,
        CommonModule,
        MatInputModule,
        MatSidenavModule,
        MatListModule,
        MatSelectModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule,
        WipFiltersTabComponent,
        MatTooltipModule,
        DragulaModule,
    ],
})
export class WipFavoriteViewsPageComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public selectedViewInput: WipFavoriteView;
    @Input() public favoriteViews: WipFavoriteView[];

    @Output() public viewCreated = new EventEmitter<WipFavoriteView>();
    @Output() public viewUpdated = new EventEmitter<WipFavoriteView>();
    @Output() public viewSelected = new EventEmitter<WipFavoriteView>();
    @Output() public saveClicked = new EventEmitter<boolean>();
    @Output() public backToViewClicked = new EventEmitter<boolean>();

    public selectedView: WipFavoriteView | null = null;
    public isCreatingNewView = false;

    public displayedColumns: string[] = WipConstants.displayedColumns.map(col => col.key);

    public viewModel = {
        name: '',
        isDefault: false,
        columns: [] as string[],
        stickyColumns: [] as string[],
        filters: [] as WipFavoriteViewFilter[],
    };

    public combinedColumns: CombinedColumn[] = [];
    private readonly dropModelSubscription: Subscription;

    constructor(
        private wipService: WipService,
        private dragulaService: DragulaService,
    ) {
        this.dropModelSubscription = this.dragulaService.dropModel('COLUMNS_BAG').subscribe(({ targetModel }) => {
            this.combinedColumns = [...targetModel];
        });
    }

    public ngOnDestroy(): void {
        if (this.dropModelSubscription) {
            this.dropModelSubscription.unsubscribe();
        }
    }

    public ngOnInit(): void {}

    public ngOnChanges(): void {
        if (!this.selectedViewInput) {
            this.addNewView();
        } else {
            this.selectView(this.selectedViewInput);
        }
    }

    public selectView(view: WipFavoriteView): void {
        this.isCreatingNewView = false;
        this.selectedView = view;

        this.viewModel.name = view.name;
        this.viewModel.isDefault = view.isDefault;
        this.viewModel.filters = view.filters ? [...view.filters] : [];

        this.viewModel.columns = [...view.columns];
        this.viewModel.stickyColumns = [...view.stickyColumns];

        const stickySet = new Set(this.viewModel.stickyColumns);

        this.combinedColumns = this.viewModel.columns.map(name => ({
            name,
            active: true,
            sticky: stickySet.has(name),
        }));

        for (const colName of this.displayedColumns) {
            if (
                !this.viewModel.columns.includes(colName) &&
                !this.viewModel.stickyColumns.includes(colName)
            ) {
                this.combinedColumns.push({
                    name: colName,
                    active: false,
                    sticky: false,
                });
            }
        }

        this.viewSelected.emit(view);
    }

    public addNewView(): void {
        this.isCreatingNewView = true;
        this.selectedView = null;

        this.viewModel.name = 'New View';
        this.viewModel.isDefault = false;
        this.viewModel.columns = [];
        this.viewModel.stickyColumns = [];
        this.viewModel.filters = [];

        this.combinedColumns = this.displayedColumns.map(name => ({
            name,
            active: false,
            sticky: false,
        }));
    }

    public saveView(): void {
        if (this.isCreatingNewView) {
            this.createNew();
            return;
        }
        const favoriteView = this.buildWipFavoriteView(
            this.selectedView.id,
            this.selectedView.userId
        );
        this.wipService.updateFavoriteView(favoriteView).subscribe(updatedView => {
            this.viewUpdated.emit(updatedView);
            this.saveClicked.next(updatedView.isDefault);
        });
    }

    private createNew(): void {
        const favoriteView = this.buildWipFavoriteView(null, null);
        this.wipService.createFavoriteView(favoriteView).subscribe(newView => {
            this.isCreatingNewView = false;
            this.viewCreated.emit(newView);
            this.saveClicked.next(newView.isDefault);
        });
    }

    private buildWipFavoriteView(id: string | null, userId: string | null): WipFavoriteView {
        const columns = this.combinedColumns
            .filter(col => col.active)
            .map(col => col.name);

        const stickyColumns = this.combinedColumns
            .filter(col => col.active && col.sticky)
            .map(col => col.name);

        const favoriteView: WipFavoriteView = {
            id,
            userId,
            name: this.viewModel.name,
            isDefault: this.viewModel.isDefault,
            columns,
            stickyColumns,
            filters: this.viewModel.filters,
        };
        return favoriteView;
    }

    public backToView(): void {
        this.backToViewClicked.next(this.isCreatingNewView);
    }

    protected readonly WipHelper = WipHelper;
}
