<div class="cb-margin-2x flex-col flex">
    <form class="flex-col flex"
          #productRatesImportForm="ngForm">
        <div class="flex-col flex"
             *ngIf="!ratesUploadResults">
            <cb-business-accounts-input-find-cards label="Supplier"
                                                   [(ngModel)]="supplier"
                                                   name="selectedSupplier"
                                                   [text]="supplier?.tradingName"
                                                   (change)="supplierSelected()"
                                                   [required]="true"
                                                   [removeable]="true"
                                                   [searchParams]="businessAccountSearchParams">
            </cb-business-accounts-input-find-cards>
            <cb-drag-and-drop-file class="flex"
                                   [allowedFileTypes]="[FILE_TYPE_ENUM.Xlsx]"
                                   [file$]="file$"
                                   [isFileValidAndUploaded$]="isFileValidAndUploaded$"
                                   description="Rates Import">
            </cb-drag-and-drop-file>
            <div class="flex-row">
                <cb-datepicker name="dateForRates"
                               [(ngModel)]="dateForRates"
                               [required]="true"
                               label="Date new rates apply"
                               pickerType="calendar">
                </cb-datepicker>
                <span class="flex"></span>
                <button mat-raised-button
                        color="primary"
                        [buttonControl]="uploadButton"
                        [disabled]="uploadDisabled$ | async">
                    Upload
                </button>
            </div>
        </div>
        <div class="cb-margin center-center flex-row"
             *ngIf="isLoading$ | async">
            <h3 class="mat-headline-6 cb-margin-right-3x">Importing Rates...</h3>
            <mat-spinner></mat-spinner>
        </div>
    </form>
    <div class="flex-col flex"
         *ngIf="ratesUploadResults">
        <div class="start-center flex-row">
            <mat-icon class="cb-margin-right">playlist_add_check</mat-icon>
            <h3 class="mat-headline-6"
                style="margin-bottom: 0;">Import Finished</h3>
            <span class="flex"></span>
            <button mat-raised-button
                    color="primary"
                    (cbClick)="resetRates()">
                Start Over
            </button>
        </div>
        <div class="cb-margin-top end-center flex-row flex">
            <span class="cb-margin-right">Log Level:</span>
            <mat-radio-group name="closetask-outcome-followup"
                             [(ngModel)]="logLevel">
                <mat-radio-button [value]="1"
                                  class="cb-margin-right">Brief</mat-radio-button>
                <mat-radio-button [value]="0">Full</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="cb-margin flex-col">
            <div class="errorContainer"
                 *ngIf="ratesUploadErrors?.length > 0">
                <div class="start-center flex-row"
                     *ngFor="let record of ratesUploadErrors | cbCallback: logFilter; let index = index;"
                     [ngClass]="{ 'odd': index % 2 }">
                    <mat-icon *ngIf="record.logLevel === 3">warning</mat-icon>
                    <mat-icon *ngIf="record.logLevel !== 3"></mat-icon>
                    <span>{{record.message}}</span>
                    <span class="flex"></span>
                    <span *ngIf="record.row > 0"
                          class="rowCount">Row {{record.row}}</span>
                    <span *ngIf="record.row === 0"
                          class="rowCount">&nbsp;</span>
                </div>
            </div>
        </div>
        <div *ngFor="let item of ratesUploadSheets | keyvalue"
             class="cb-margin flex-col">
            <h4>{{item.key}}</h4>
            <div class="errorContainer cb-margin">
                <div class="start-center flex-row"
                     *ngFor="let record of item.value | cbCallback: logFilter; let index = index;"
                     [ngClass]="{ 'odd': index % 2 }">
                    <mat-icon class="mat-warn"
                              *ngIf="record.logLevel === 3">warning</mat-icon>
                    <mat-icon *ngIf="record.logLevel !== 3"></mat-icon>
                    <span>{{record.message}}</span>
                    <span class="flex"></span>
                    <span *ngIf="record.row > 0"
                          class="rowCount">Row {{record.row}}</span>
                    <span *ngIf="record.row === 0"
                          class="rowCount">&nbsp;</span>
                </div>
            </div>
        </div>
    </div>
</div>
