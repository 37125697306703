<cb-dialog class="flex-col"
           dialogHeading="{{mode === edit ? 'Edit' : 'Add'}} Item"
           (cancelDialog)="cancel(true)"
           *cbWaitFor="mode">
    <form class="flex-col flex"
          #form="ngForm">
        <div *cbWaitFor="mappedItem">
            <cb-input name="itemId"
                      label="Item #"
                      [(ngModel)]="tempItem.itemNumber"
                      [required]="true">
            </cb-input>
            <cb-input name="description"
                      label="Item Description"
                      [(ngModel)]="tempItem.description"
                      [required]="true">
            </cb-input>
        </div>
    </form>
    <div actions
         *cbWaitFor="form">
        <button mat-raised-button
                *ngIf="mode === add"
                [disabled]="form.pristine || form.invalid"
                color="primary"
                (cbClick)="saveDialogItem()">
            Save
        </button>
        <button mat-raised-button
                *ngIf="mode === edit"
                [disabled]="form.pristine || form.invalid"
                color="primary"
                (cbClick)="saveDialogEditItem()">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
