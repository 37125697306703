<div class="cb-margin flex-col">
    <mat-tab-group (selectedIndexChange)="onTabChanged($event);"
                   [selectedIndex]="selectedTabIndex"
                   cbTabUrlParam="cbTabRouteId2"
                   mat-stretch-tabs="false"
                   [cbTabRoute]="LOTS_ROUTE">
        <mat-tab *ngIf="canViewCostSummary"
                 label="Summary"
                 cbTabRouteId="summary"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-lot-costs-summary [lotMappedItem]="lotMappedItem">
                </cb-lot-costs-summary>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="canViewPriceRevisions"
                 label="Price Revisions"
                 cbTabRouteId="price-revisions"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <div *ngIf="!selectedPricingRevision">
                    <cb-pricing-revisions-search [lotId]="lotMappedItem?.id"
                                                 *cbWaitFor="lotMappedItem$$ | async"
                                                 (previousPricingRevisionSet)="setPreviousMappedItem($event)"
                                                 (pricingRevisionSelected)="pricingRevisionSelected($event)">
                    </cb-pricing-revisions-search>
                </div>
                <div *ngIf="selectedPricingRevision">
                    <cb-pricing-revision-view [mappedItem]="selectedPricingRevision"
                                              [lotMappedItem]="lotMappedItem"
                                              [previousMappedItem]="previousMappedItem"
                                              (goBack)="handleGoBack()"
                                              (reloadPricingRevisionMappedItem)="handleReloadSelectedPricingRevision()">
                    </cb-pricing-revision-view>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="canViewTakeoffs"
                 label="Takeoffs"
                 cbTabRouteId="takeoffs"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-takeoff-tasks-search [lotId]="lotMappedItem?.id"
                                         *cbWaitFor="lotMappedItem$$ | async"
                                         (reloadLotMappedItem)="handleReloadLotMappedItem()">
                </cb-takeoff-tasks-search>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="canViewConfirmedQuantities"
                 label="Confirmed Quantities"
                 cbTabRouteId="confirmed-quantities"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-lot-costs-confirmed-quantities [lotId]="lotMappedItem?.id"
                                                   *cbWaitFor="lotMappedItem$$ | async">
                </cb-lot-costs-confirmed-quantities>
            </ng-template>
        </mat-tab>
        <!-- Commented out to hide actual cost tab -->
        <!-- <mat-tab *ngIf="canViewActualCosts"
                 label="Actual"
                 cbTabRouteId="actual"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-lot-costs-actual [lotId]="lotMappedItem?.id"
                                     *cbWaitFor="lotMappedItem$$ | async">
                </cb-lot-costs-actual>
            </ng-template>
        </mat-tab> -->
    </mat-tab-group>
</div>
