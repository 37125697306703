import { Component, OnInit, Inject } from '@angular/core';
import { IBusinessAccountContactDto } from '@app/logic/business-accounts';
import { ContactsLogicService } from '@app/logic/contacts';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISkinnyLeadDto, LEAD_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { NavigationService } from '@app/core/services/navigation/navigation.service';

@Component({
    selector: 'cb-lead-list-dialog',
    templateUrl: './lead-list-dialog.component.html',
    styleUrls: ['./lead-list-dialog.component.scss']
})
export class LeadListDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '400px';

    public readonly displayedColumns: string[] = [
        'name',
        'status',
        'mainContact',
        'actions'
    ];

    public LEAD_STATUS_ENUM = LEAD_STATUS_ENUM;

    public resultList: ISkinnyLeadDto[];

    constructor(
        public readonly navigationService: NavigationService,
        public readonly contactsLogic: ContactsLogicService,
        public readonly dialogRef: MatDialogRef<LeadListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: string
    ) { }


    public ngOnInit(): void {
        this.contactsLogic.getLeadsList(this.data)
            .subOnce(leads => { this.resultList = leads; }
            );
    }

    public viewLead(account: IBusinessAccountContactDto, event: MouseEvent): void {
        this.dialogRef.close();
        this.navigationService.navigate([`leads/edit/${account.id}/summary`], event);
    }

}
