import { Component, EventEmitter, Output } from '@angular/core';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { ProjectsLogicService } from '@app/logic/projects';
import { ISpecTemplateFilter } from '@app/logic/spec-template';
import { cleanObjectDeep } from 'cb-hub-lib';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { IIdAndLabelDto, IProjectDetailsDto } from '@classictechsolutions/hubapi-transpiled-enums';
import _, { orderBy, map } from 'lodash';
import { PRODUCT_TIER_RANGE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/enums/ProductTierRange';
import { SelectListItem } from '@app/core/services/enum/enum.service';

@Component({
    selector: 'cb-spec-template-filters',
    templateUrl: './spec-template-filters.component.html',
    styleUrls: ['./spec-template-filters.component.scss']
})
export class SpecTemplateFiltersComponent {
    @Output() public readonly filtersChanged = new EventEmitter<ISpecTemplateFilter>();

    public businessEnitities: IBusinessEntityDto[];
    public projects: IIdAndLabelDto[];
    public tierRanges: SelectListItem[];

    public readonly selectedValues: ISpecTemplateFilter = {
        searchText: '',
        isActive: true,
        businessEntityId: -1,
        projectId: -1,
        tierId: -1
    };

    constructor(
        private readonly businessEntityLogic: BusinessEntitiesLogicService,
        private readonly projectsLogic: ProjectsLogicService,
    ) {
        this.businessEntityLogic.$getList().subOnce((businessEnitities: IBusinessEntityDto[]) => {
            businessEnitities.unshift({ id: -1, name: 'Any' } as IBusinessEntityDto);
            this.businessEnitities = businessEnitities;
        });
        this.projectsLogic.$getList().subOnce((projects: IProjectDetailsDto[]) => {
            projects = orderBy(projects, project => project.projectName);
            projects.unshift({ id: -1, projectName: 'Any' } as IProjectDetailsDto);
            this.projects = map(projects, (project: IProjectDetailsDto) => {
                return { id: project.id, label: project.projectName };
            });
        });
        this.tierRanges = _.orderBy(PRODUCT_TIER_RANGE_ENUM.toSelectList(), 'id');
        this.tierRanges.unshift( { id: -1, label: 'Any', checked: true, disabled: false });
    }

    public ngAfterViewInit(): void {
        this.emitFilterChanged();
    }

    public emitFilterChanged(): void {
        this.filtersChanged.emit(
            cleanObjectDeep(cloneDeepSafe(this.selectedValues), (value) => value === -1)
        );
    }
}
