<div class="cb-margin flex-col"
     *cbWaitFor="designConcepts$$ | async">
    <mat-card class="cb-margin-top">
        <mat-card-content>
            <form class="cb-margin-right-3x start-center flex-row">
                <div class="start-center flex-row">
                    <mat-icon class="cb-margin-right-2x">search</mat-icon>
                    <cb-input class="search-input cb-margin-right"
                              name="searchText"
                              label="Search">
                    </cb-input>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
    <div class="cb-margin-top">
        <div class="flex-wrap">
            <cb-design-concept-card class="md-33 lg-33 sm-50 xl-25 xs-100 flex-25"
                                    *ngFor="let designConcept of designConcepts;"
                                    [designConcept]="designConcept"
                                    (conceptSelected)="conceptSelected($event)">
            </cb-design-concept-card>
            <cb-info-message *ngIf="designConcepts?.length < 1"
                             message="There were no Concepts found">
            </cb-info-message>
        </div>
    </div>
</div>
