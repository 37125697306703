<cb-dialog [dialogHeading]="'Remove House Area'">
    <form class="flex-col flex"
          #form="ngForm">
        <h4 class="mat-headline-6">{{houseAreaName}}</h4>
        <div class="cb-margin-bottom flex-row">
            <button mat-raised-button
                    class="cb-margin-right"
                    (click)="selectAll()">Select All</button>
            <button mat-raised-button
                    (click)="deSelectAll()">Deselect All</button>
        </div>
        <div class="standardTable scroll-container">
            <table>
                <thead>
                    <tr>
                        <td></td>
                        <td>Name</td>
                        <td>Tag(s)</td>
                        <td>Code</td>
                        <td>Product Name/Description</td>
                        <td>Cost Type</td>
                        <td>Quantity</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of houseAreaItems; let index;">
                        <td class="actions">
                            <cb-checkbox [slim]="true"
                                         [name]="'checkbox_' + index"
                                         [(ngModel)]="selectedHouseAreaItems[item.id]"
                                         (change)="canSave.recompute()">
                            </cb-checkbox>
                        </td>
                        <cb-td-text [value]="item.slotName"></cb-td-text>
                        <cb-td-list [list]="item.tags"></cb-td-list>
                        <cb-td-text *ngIf="!lotSpecificationService.hasQuoteLineId(item)"
                                    [value]="item?.product?.productCode">
                        </cb-td-text>
                        <cb-td-text *ngIf="lotSpecificationService.hasQuoteLineId(item)"
                                    [value]="getQRef(item.quoteLineId)">
                        </cb-td-text>
                        <td class="start-center">
                            <span class="start-center">
                                <cb-td-text [value]="lotSpecificationService.getProductName(item)"></cb-td-text>
                                <cb-info-popup [tooltip]="item?.clientFriendlyDescription"></cb-info-popup>
                                <span *ngIf="item?.generatedByVariationNumber"
                                      id="variation"
                                      class="help-cursor"
                                      [matTooltip]="'Variation Number: ' + item?.generatedByVariationNumber">VO
                                    {{item?.generatedByVariationNumber}}
                                </span>
                            </span>
                        </td>
                        <td>
                            <cb-td-enum [value]="item.costType"
                                        [enum]="costTypeEnum">
                            </cb-td-enum>
                        </td>
                        <td>
                            <cb-td-text [value]="item.quantity"></cb-td-text>
                        </td>
                        <td class="check-mark-col">
                            <mat-icon title="Item Removed"
                                      color="primary"
                                      *ngIf="hasDeleted[item.id]">check_circle
                            </mat-icon>
                        </td>
                    </tr>
                    <tr *ngIf="houseAreaItems.length < 1">
                        <td colspan="6">
                            <span class="mat-body-2">
                                <mat-icon color="accent">info_circle</mat-icon>
                                {{'noHouseAreaItemsMessage'}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="!canSave.value"
                (click)="removeHouseAreaItems()">Remove</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
