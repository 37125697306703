import {MatDialog} from '@angular/material/dialog';
import {Injectable} from '@angular/core';
import {SearchService} from './base.search.service';
import {CategoryLogicService} from '@app/logic/product-categories';
import {takeOne} from 'cb-hub-lib';
import {Observable} from 'rxjs';

@Injectable()
export class ProductCategorySearchService extends SearchService {
    public get extraSearchParams(): { includeDeleted: boolean } {
        return { includeDeleted: false };
    }

    constructor(
        public readonly logicService: CategoryLogicService,
        public readonly dialog: MatDialog
    ) {
        super();
        this.pageSize = undefined;
    }

    public getResults(query: string, currentPage: number): Observable<any[]> {
        return new Observable<any[]>((subscriber) => {
            if (!this.searchResultsLoaded) {
                this.searchResultsLoaded = true;
                const resultsObservable = this.logicService.$getSearchList({
                    query,
                    currentPage,
                    ...this.extraSearchParams
                });
                resultsObservable
                    .subOnce(x => {
                        this.setSearchResults<any>(x);
                        subscriber.next(this.searchResults$.value);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.searchResults$.value);
                subscriber.complete();
            }
        });
    }

    public doSearch = (query: string, page: number): Observable<any[]> => {
        this.searchResultsLoaded = false;
        return this.getResults(query, page).pipe(takeOne());
    };
}
