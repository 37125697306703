<cb-dialog dialogHeading="{{isEdit() ? 'Edit' : 'Add New'}} Option">
    <form class="flex-col flex"
          #manageProductForm="ngForm">
        <div class="flex-row flex">
            <div class="flex-col flex">
                <cb-select [required]="true"
                           label="Category"
                           name="category"
                           [readonly]="isEdit()"
                           [(ngModel)]="attribute.category"
                           [options]="mappedItem.categoryPath">
                </cb-select>
                <cb-input label="Option Name"
                          name="optionName"
                          [(ngModel)]="attribute.name"
                          [readonly]="isEdit()"
                          [maxlength]="256">
                </cb-input>
            </div>
        </div>
        <div class="flex-row flex">
            <div class="flex-col flex"
                 *ngIf="isEdit()">
                <h4 class="mat-subhead">Current Values</h4>
                <cb-checkbox *ngFor="let option of attributeValues; let i = index"
                             class="checkbox-list"
                             label="{{option.name}}"
                             name="val{{i}}"
                             [(ngModel)]="attribute.selectedOptions[option.id]">
                </cb-checkbox>
            </div>
            <div class="flex-col flex">
                <h4 class="mat-subhead"
                    class="cb-margin-bottom">New Values</h4>
                <div class="flex-row flex"
                     *ngFor="let newVal of attribute.newValues">
                    <mat-icon class="cb-margin-right">check_box</mat-icon>
                    {{newVal}}
                </div>
                <div class="cb-margin-top flex-row flex">
                    <cb-input class="flex"
                              label="Value"
                              name="newValue"
                              [(ngModel)]="newValue"
                              (keypress)="($event.which === 13) ? addValue() : 0">
                    </cb-input>
                    <button mat-icon-button
                            matTooltip="Add"
                            [disabled]="!newValue"
                            color="accent"
                            (click)="addValue()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="manageProductForm.pristine || manageProductForm.invalid"
                (click)="save()">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
