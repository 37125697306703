import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupTipComponent } from './popup-tip/popup-tip.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    declarations: [PopupTipComponent],
    exports: [PopupTipComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
    ]
})

export class CbPopupTipModule { }
