import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProductCentreLogicService, ProductCentreSearchParams } from '@app/logic/product-centre/product-centre.logic.service';
import { IProductCentreCategoryDto } from '@app/logic/product-centre/i.product-centre-category.dto';
import { Subscription } from 'rxjs';
import { FormArray, FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { environment } from '@src/environments/environment';
import { SelectListItem } from '@app/core/services/enum/enum.service';
import {
    IActiveIdAndNameDto,
    PRODUCT_SEARCH_TYPE_ENUM,
    PRODUCT_TIER_RANGE_ENUM,
    ProductSearchTypeEnumId,
    ProductTierRangeEnumId,
    IProductCentreDocumentDto,
    IProductCentreTierDocumentDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { BusinessEntitiesLogicService } from '@app/logic/business-entity';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';

@Component({
    selector: 'cb-product-centre-tab',
    templateUrl: './product-centre-tab.component.html',
    styleUrls: ['./product-centre-tab.component.scss']
})
export class ProductCentreTabComponent implements OnInit, OnDestroy {

    protected readonly TIER_ENUM = PRODUCT_TIER_RANGE_ENUM;
    public readonly NATIONAL_BUSINESS_ENTITY_ID = 0;
    private readonly SEARCH_RESULTS_PAGE_SIZE = 50;
    private readonly AUTO_EXPAND_CATEGORY_LEVEL = 0;
    public productTypes: IEnumLookup<ProductSearchTypeEnumId>[];

    private apiUrl: string = environment.api;
    public slotCategories: IProductCentreCategoryDto[] = [];
    public tierRanges: SelectListItem[];
    public businessEntities: IActiveIdAndNameDto[];
    public dynamicFilterOptions: { key: string; values: any[] }[];

    // Component Properties
    public products: IProductCentreDocumentDto[] = [];
    public filteredProducts: IProductCentreDocumentDto[] = [];
    public displayedProducts: IProductCentreDocumentDto[] = [];

    public loading = false;

    public loadingMenu = false;
    public selectedCategory: IProductCentreCategoryDto = null;
    public collapsedFilters: { [id: string]: boolean } = {};

    public selectedTierRanges: Set<number> = new Set();
    public selectedBusinessEntities: Set<number> = new Set();


    private searchSubscription: Subscription;

    // Filters
    public filters = {
        search: new FormControl(''),
        houseArea: new FormArray([]),
        slot: new FormArray([]),
        dynamicCategories: {},
        activeOnly: true,
        productType: ProductSearchTypeEnumId.All
    };

    @ViewChild(MatPaginator) public paginator!: MatPaginator;
    private dynamicFilters: { [key: string]: Set<string> } = {};
    public searchInitialized = false;

    constructor(
        public readonly productCentreLogicService: ProductCentreLogicService,
        private readonly businessEntitiesLogicService: BusinessEntitiesLogicService,
        public readonly toastService: ToastService) {
    }

    public ngOnInit(): void {
        this.setupProductTypes();
        // Load all the static filters. These are filters that are present regardless of the search results
        this.loadAllSlotCategories();
        this.loadAllTierRanges();
        this.loadBusinessEntities();
    }

    public ngOnDestroy(): void {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
    }

    private setupProductTypes(): void {
        this.productTypes = PRODUCT_SEARCH_TYPE_ENUM
            .toLookup({
                transform: result => {
                    // We need to remove anything to do with bundles as well as rename the all enum
                    result.find(x => x.id === ProductSearchTypeEnumId.All).label = 'All';
                    return result.filter(cn =>
                        cn.id !== ProductSearchTypeEnumId.BundlesOnly &&
                        cn.id !== ProductSearchTypeEnumId.ExcludeBundles);
                }
            });
    }

    private loadAllSlotCategories(): void {
        // Load all the slot categories, so we can display the category tree when the page loads.
        // TODO: add error handling etc.
        this.productCentreLogicService.getAllSlotCategories().subOnce(
            (searchResults) => {
                this.slotCategories = searchResults;
                this.loadingMenu = false;
                this.initializeCategoryCollapsedState(this.slotCategories, 0);
            }
        );
    }

    private loadAllTierRanges(): void {
        this.tierRanges = PRODUCT_TIER_RANGE_ENUM
            .toSelectList()
            .filter(tier => tier.id !== 0) // Filter out "None" (id === 0)
            .sort((a, b) => a.id - b.id); // Sort by "id"
    }

    private loadBusinessEntities(): void {
        this.businessEntitiesLogicService.getSkinnyBusinessEntityDetails().subOnce(businessEntities => {
            this.businessEntities = businessEntities;
            this.businessEntities.unshift({ isActive: true, id: this.NATIONAL_BUSINESS_ENTITY_ID, name: 'National' });
        });
    }

    public performSearch(): void {
        this.searchInitialized = true;
        this.loading = true;
        this.selectedCategory = null;
        const params: ProductCentreSearchParams = {
            query: this.filters.search.value,
            ao: this.filters.activeOnly,
            t: this.filters.productType,
            be: [...this.selectedBusinessEntities],
            // TODO: still to implement
            cat: undefined,
            currentPage: undefined,
            pageSize: 10000
        };

        this.productCentreLogicService.$getSearchList(params)
            .subOnce((result) => {
                this.clearFilters(false, true);
                this.products = result.items;
                this.applyFilters();
            });
    }

    public tierMatchesBusinessEntity(productTier: IProductCentreTierDocumentDto): boolean {
        if (!this.selectedBusinessEntities.size) {
            return true;
        }

        const nationalIsSelected = this.selectedBusinessEntities.has(this.NATIONAL_BUSINESS_ENTITY_ID);
        if(nationalIsSelected && productTier.isNational) {
            return true;
        }

        return productTier.businessEntities.some(b =>  this.selectedBusinessEntities.has(b));
    }

    private initializeCategoryCollapsedState(items: IProductCentreCategoryDto[], levelCount: number): void {
        items.forEach((item) => {
            this.collapsedFilters[item.label + item.id] = levelCount === this.AUTO_EXPAND_CATEGORY_LEVEL;
            if (item.children) {
                this.initializeCategoryCollapsedState(item.children, levelCount + 1);
            }
        });
    }

    public toggleActiveOnly(isChecked: boolean): void{
        this.filters.activeOnly = isChecked;
        this.performSearch();
    }

    public toggleTierRange(tier: SelectListItem, isChecked: boolean): void {
        this.toggleFilter(this.selectedTierRanges, tier.id, isChecked);
    }

    public toggleBusinessEntity(id: number, isChecked: boolean): void {
        this.toggleFilter(this.selectedBusinessEntities, id, isChecked, false);
        this.performSearch();
    }

    private toggleFilter<T extends number | string>(
        // Collection we're toggling on
        collection: Set<T>,
        // The item that caused the toggle event
        item: T,
        isChecked: boolean,
        applyFilters = true
    ): void {
        if (isChecked) {
            collection.add(item);
        } else {
            collection.delete(item);
        }
        if (applyFilters) {
            this.applyFilters();
        }
    }

    // Filters
    public applyFilters(): void{
        this.searchInitialized = true;
        this.loading = true;

        setTimeout(() => {
            this.filteredProducts = this.products.filter((product: IProductCentreDocumentDto) => {
                return (
                    this.filterByCategory(product) &&
                    this.filterByTier(product) &&
                    this.filterByBusinessEntity(product) &&
                    this.filterByDynamicCategories(product)
                );
            });

            this.rePopulateDynamicFilters();

            // Update displayed products for pagination
            this.displayedProducts = this.filteredProducts.slice(0, this.SEARCH_RESULTS_PAGE_SIZE);
            this.loading = false;
            this.paginator.firstPage();
        }, 500);
    }

    private calculateBadgeCounts(filteredProducts: IProductCentreDocumentDto[]): void {
        // clear badge counts
        this.slotCategories?.forEach((item) => {
            item.badgeCount = 0;
            this.clearBadgeCount(item.children);
        });

        filteredProducts.forEach((product) => {

            const productCategories = product.categoryTree;
            for (const productCategory of productCategories) {
                this.incrementBadgeCount(productCategory, this.slotCategories);
            }
        });
    }

    private clearBadgeCount(currentMenu: any[]): void {
        for (const subMenu of currentMenu) {
            if (subMenu === undefined) {
                continue;
            }
            subMenu.badgeCount = 0;
            if (Array.isArray(subMenu.children)) {
                this.clearBadgeCount(subMenu.children);
            }
        }
    }

    private incrementBadgeCount(productCategory: number, currentMenu: any[]): void{

        if (currentMenu?.length > 0) {

            for (const subMenu of currentMenu) {
                if (subMenu === undefined) {
                    continue;
                }
                if (subMenu.badgeCount === undefined) {
                    subMenu.badgeCount = 0;
                }
                if (subMenu.id === productCategory) {
                    subMenu.badgeCount++;
                }
                if (Array.isArray(subMenu.children)) {
                    this.incrementBadgeCount(productCategory, subMenu.children);
                }
            }
        }

    }

    public getPillColour(tier: ProductTierRangeEnumId): string {
        /* This method is temporary until marketing comes back with how they want the tiers to look */
        const pillColourDefault = '#607d8b';
        const pillColourFoundation = '#D38700';
        const pillColourTraditional = '#B3B3B3';
        const pillColourPremium = '#F6D500';

        switch (tier) {
            case ProductTierRangeEnumId.Foundation:
                return pillColourFoundation;
            case ProductTierRangeEnumId.Traditional:
                return pillColourTraditional;
            case ProductTierRangeEnumId.Premium:
                return pillColourPremium;
            default:
                return pillColourDefault;
        }
    }

    public filterByCategory(product: IProductCentreDocumentDto): boolean {
        const categoryId = this.selectedCategory?.id;
        return (!categoryId || product.categoryTree.includes(categoryId));
    }

    public filterByTier(product: IProductCentreDocumentDto): boolean {
        if (!this.selectedTierRanges.size) {
            return true;
        }

        return product.tiers?.some(pt => this.selectedTierRanges.has(pt.tier)) ?? false;
    }

    public filterByBusinessEntity(product: IProductCentreDocumentDto): boolean {
        if (!this.selectedBusinessEntities.size) {
            return true;
        }
        const nationalIsSelected = this.selectedBusinessEntities.has(this.NATIONAL_BUSINESS_ENTITY_ID);

        // If we have a tier selected we should only search through business entity for the selected tiers
        // If not then we can search through all the tiers
        const tiersToSearch = this.selectedTierRanges.size
            ? product.tiers?.filter(productTier => this.selectedTierRanges.has(productTier.tier))
            : product.tiers;

        return tiersToSearch?.some(productTier =>
            productTier.businessEntities.some(entityId =>
                this.selectedBusinessEntities.has(entityId)) ||
            (nationalIsSelected && productTier.isNational)
        ) ?? false;
    }

    public filterByDynamicCategories(product: IProductCentreDocumentDto): boolean {
        const dynamicCategoryFilters = this.filters.dynamicCategories || {};

        return Object.keys(dynamicCategoryFilters).every((key) => {
            const selectedValues = dynamicCategoryFilters[key];
            const productValue = product.dynamicFilters ? product.dynamicFilters[key] : null;

            if (!selectedValues?.length) {
                return true;
            }

            return selectedValues.includes(productValue);
        });
    }

    public updateDynamicFilter(key: string, value: any, isChecked: boolean): void {
        if (!this.filters.dynamicCategories[key]) {
            this.filters.dynamicCategories[key] = [];
        }

        if (isChecked) {
            this.filters.dynamicCategories[key].push(value);
        } else {
            const index = this.filters.dynamicCategories[key].indexOf(value);
            if (index > -1) {
                this.filters.dynamicCategories[key].splice(index, 1);
            }
        }

        this.applyFilters();
    }

    public isSelected(filterType: string, option: any): boolean {
        if (filterType === 'dynamicCategories') {
            return this.filters.dynamicCategories[option.key]?.includes(option.value);
        } else {
            const formArray = this.filters[filterType] as FormArray;
            return formArray.value.includes(option);
        }
    }

    // Menu Interactions
    public selectItem(item: IProductCentreCategoryDto, event: Event): void {
        event.stopPropagation();
        if (this.selectedCategory === item) {
            // we're deselecting a category
            this.selectedCategory = null;
        } else {
            this.selectedCategory = item;
        }

        this.applyFilters();

    }

    public isSelectedMenu(item: any): boolean {
        return this.selectedCategory === item || this.isAncestorSelected(item);
    }

    private isAncestorSelected(item: any): boolean {
        if (!item.children) {
            return false;
        }
        return item.children.some((child: any) => this.isSelectedMenu(child));
    }

    public toggleCollapsedState(key: string, event?: Event): void{
        if (event){
            event.stopPropagation();
        }
        this.collapsedFilters[key] = !this.collapsedFilters[key];
    }

    // Pagination
    public paginate(event: PageEvent): void {
        const startIndex = event.pageIndex * event.pageSize;
        const endIndex = startIndex + event.pageSize;
        this.displayedProducts = this.filteredProducts.slice(startIndex, endIndex);
    }

    // Product Utilities
    public getProductImage(product: any): string {
        if (!product.isActive){
            return '/assets/img/inactive-placeholder.png';
        } else if (!product.hasImage) {
            return '/assets/img/no-image-placeholder.png';
        }else{
            return `${this.apiUrl}/${product.imageUrl}?w=600&h=400&scale=canvas`;
        }
    }

    private updateDynamicFilterOptions(): void {
        const filterOrder: string[] = ['tier', 'brand', 'range', 'style', 'size', 'shape'];

        const filterOptions = Object
            .entries(this.dynamicFilters)
            .map(([key, values]) => ({
                key,
                values: Array.from(values).sort() // Sort the filter options alphabetically since they are all strings
            }));

        this.dynamicFilterOptions = filterOptions.length > 0
            ? filterOptions.sort((a, b) =>
            {
                const indexA = filterOrder.indexOf(a.key);
                const indexB = filterOrder.indexOf(b.key);
                // Handle keys not in the sort order
                return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
            })
            : [];

        // Collapse all dynamic filters
        this.dynamicFilterOptions.forEach((key) => {
            if (this.collapsedFilters[key.key] === undefined) {
                this.collapsedFilters[key.key] = true; // true is collapsed, false is open
            }
        });
    }

    public trackById(index: number, product: any): number {
        return index;
    }

    private populateDynamicFilters(dynamicFilters: Record<string, string>): void {
        const categories: Record<string, string> = dynamicFilters || {};

        Object.keys(categories).forEach((key) => {
            const value = categories[key];
            const mustShow = value !== undefined;

            if (mustShow) {
                if (!this.dynamicFilters[key]) {
                    this.dynamicFilters[key] = new Set();
                }

                this.dynamicFilters[key].add(value);
            }
        });
    }

    protected resetFilters(): void {
        this.clearFilters(true, true);
        this.performSearch();
    }

    protected clearFilters(clearStaticFilters: boolean, clearDynamicFilters: boolean): void {
        if (clearStaticFilters) {
            this.selectedCategory = undefined;
            this.selectedTierRanges.clear();
            this.selectedBusinessEntities.clear();
            this.filters.productType = ProductSearchTypeEnumId.All;
            this.filters.activeOnly = true;
        }
        if (clearDynamicFilters === true) {
            this.filters.dynamicCategories = {};
        }
    }

    private rePopulateDynamicFilters(): void {
        this.dynamicFilters = {};

        this.filteredProducts = this.filteredProducts.map((product: IProductCentreDocumentDto) => {
            this.populateDynamicFilters(product.dynamicFilters);
            return product;
        });

        this.updateDynamicFilterOptions();
        this.displayedProducts = this.filteredProducts.slice(0, 50);
        this.calculateBadgeCounts(this.filteredProducts);
    }

    public copyProductCodeToClipboard(event: MouseEvent, productCode: string): void {
        event.stopPropagation();
        navigator?.clipboard?.writeText(productCode)
            .then(() => {
                this.toastService.showToast('Copied', 'Ok', 1000);
            });
    }

    public productSearchTypeChanged(): void {
        this.performSearch();
    }

    public viewProduct(productId: number): void {
        const url = `${environment.uri}/view-product/${productId}/details`;
        const newTab = window.open(url, '_blank');

        if (newTab) {
            newTab.focus();
        }
    }
}
