<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x flex-col flex">
    <div class="start-center flex-row layoutGap-16">
        <h3 class="mat-headline-6 flex">
            Build Stages
        </h3>
        <button mat-raised-button
                (click)="newItemClicked();"
                [disabled]="!canEdit()"
                color="primary">
            New Stage
        </button>
        <button mat-raised-button
                (click)="saveOrder();"
                [disabled]="!sortOrderChanged || !canEdit()"
                color="primary">
            Save Stage Order
        </button>
    </div>
    <div class="cb-table cb-margin-top">
        <mat-table [dataSource]="searchResults"
                   [dragula]="BUILD_STAGES"
                   [(dragulaModel)]="dragulaModel">
            <ng-container matColumnDef="code">
                <mat-header-cell *matHeaderCellDef>
                    Code
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.code}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="label">
                <mat-header-cell *matHeaderCellDef>
                    Stage
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.label}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="manager">
                <mat-header-cell *matHeaderCellDef>
                    Construction Manager
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{getConstructionManagerRole(element.constructionManagerRole)}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="active">
                <mat-header-cell *matHeaderCellDef>
                    Active
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-icon>
                        {{element.isActive ? "check_box" : "check_box_outline_blank"}}
                    </mat-icon>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="isPreconsentStage">
                <mat-header-cell *matHeaderCellDef>
                    Pre consent Stage
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-icon>
                        {{element.isPreconsentStage ? "check_box" : "check_box_outline_blank"}}
                    </mat-icon>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions"
                          stickyEnd>
                <mat-header-cell *matHeaderCellDef>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button
                            (click)="editItemClicked(element);"
                            [disabled]="!canEdit()">
                        <mat-icon>
                            edit
                        </mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <cb-info-message *ngIf="searchResultsLoaded && searchResults.length === 0"
                         [message]="'There are no Build Stages to display'"
                         [icon]="'info_circle'"></cb-info-message>
        <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
    </div>
</div>
