import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { ProjectPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IProjectSearchPageSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ProjectsLogicService } from '@app/logic/projects';
import { IProjectMappedItem } from '@app/logic/projects/interfaces/i.project.mapped';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { IAddressDto, IProjectDocumentDto, ISkinnyUserDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, map, Observable, Subject, Subscription } from 'rxjs';
import { ManageProjectDetailsDialogComponent } from '../project/project-details/manage-project-details-dialog/manage-project-details-dialog.component';

@Component({
    selector: 'cb-project-search',
    templateUrl: './project-search.component.html',
    styleUrls: ['./project-search.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class ProjectSearchComponent implements OnInit {
    public mappedItem: IProjectMappedItem;

    public readonly queryUpdate = new Subject();
    private readonly subscription$ = new Subscription();

    public get userCacheItem(): UserCacheItem<IProjectSearchPageSearch> {
        return this.userCacheService.projectSearch;
    }

    public currentPage: number;
    public searchEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public results: IProjectDocumentDto[] = [];

    constructor(
        public readonly userCacheService: UserCacheService,
        public readonly projectPermissions: ProjectPermissions,
        public readonly currentUser: CurrentUserService,
        public logicService: ProjectsLogicService,
        public readonly toastService: ToastService,
        public readonly navigationService: NavigationService,
        public readonly cbDialog: CbDialogService
    ) {
    }

    public fetchResults(): Observable<IProjectDocumentDto[]> {
        if (!this.searchEnabled.value) {
            return;
        }
        return this.logicService.$getSearchList(this.getQueryParams())
            .pipe(map(results => results.items));
    }


    public ngOnInit(): void {
        Promise.all([
            this.userCacheItem.init(),
            this.currentUser.$promise,
        ]).then(() => {
            this.searchEnabled.next(true);
            this.queryUpdated();
            this.subscription$.add(
                this.userCacheItem.updated$.subscribe({
                    next: this.queryUpdated
                })
            );
        });
    }

    public ngOnDestroy(): void {
        this.subscription$.unsubscribe();
    }

    public readonly queryUpdated = (): void => {
        if (!this.searchEnabled.value) {
            return;
        }
        this.currentPage = 1;
        this.queryUpdate.next(null);
    };

    public createProject(): void {
        this.mappedItem = this.logicService.$createMappedItem();
        this.mappedItem.address = {} as IAddressDto;
        this.mappedItem.teamMembers = [] as ISkinnyUserDto[];
        this.cbDialog
            .open(ManageProjectDetailsDialogComponent, {
                data: { mappedItem: this.mappedItem },
                minWidth: '80%',
            })
            .afterClosed()
            .subOnce((result) => {
                if (result) {
                    this.results.unshift(result);
                    this.viewProject(result.id);
                }
            });
    }

    public viewProject(projectId: number): void {
        this.navigationService.navigate([`projects/${projectId}/view`]);
    }

    public canCreate(): boolean {
        return this.projectPermissions.canCreate();
    }

    protected getQueryParams(): any {
        return {
            pageSize: 10,
            currentpage: this.currentPage,
            query: this.userCacheItem.data.query,
        };
    }

    public shouldShowNoResultsMessage(): boolean {
        return (!this.results || this.results.length < 1);
    }
}
