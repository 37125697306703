<div class="flex-row flex"
     style="overflow-x: auto">
    <div class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex">
        <div class="start-center flex-row">
            <h3 class="mat-headline-6 flex">
                Business Accounts
            </h3>
        </div>
        <div class="flex-col">
            <div class="flex-row flex layoutGap-16">
                <div class="flex-col flex"
                     id="account-types">
                    <cb-table [loaded]="true"
                              [stringColumns]="accountTypeColumns"
                              tableLabel="For Account Types"
                              class="cb-margin-bottom-2x flex">
                        <tbody cbTableBody>
                        <tr *ngFor="let documentType of requiredDocsForBusinessAccountTypes">
                            <!--  For this, we needed to use businessAccountType.name (for the previous logic),-->
                            <!--  but this field is not available on the backend or frontend,-->
                            <!--  and all our environments currently show a dash in this column. -->
                            <td> -</td>
                            <td>
                                <ng-container *ngIf="documentType.documentTypes?.length > 0; else noLabels">
                                    <ng-container *ngFor="let value of documentType.documentTypes">
                                        <li>{{ value?.label }}</li>
                                    </ng-container>
                                </ng-container>
                                <ng-template #noLabels>
                                    -
                                </ng-template>
                            </td>
                            <td cbTableShrinkCol
                                cbTableStickyCol="end">
                                <button mat-icon-button
                                        [disabled]="!canEdit()"
                                        (click)="editBizAccountType(documentType)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </cb-table>
                </div>

                <cb-table [loaded]="true"
                          [stringColumns]="tradeTypeColumns"
                          tableLabel="For Trade Types"
                          class="cb-margin-bottom-2x flex">
                    <tbody cbTableBody>
                    <ng-container *ngFor="let item of parentTradeTypes">
                        <!-- Parent Row -->
                        <tr (click)="expandedTradeTypeElement = expandedTradeTypeElement === item ? null : item"
                            [class.example-expanded-row]="expandedTradeTypeElement === item">
                            <td style="width: 250px">{{ item.label }}</td>
                            <td></td>
                            <td>
                                <mat-icon>
                                    {{ expandedTradeTypeElement === item ? 'arrow_drop_up' : 'arrow_drop_down' }}
                                </mat-icon>
                            </td>
                        </tr>
                        <!-- Expanded Detail Row -->
                        <tr *ngIf="expandedTradeTypeElement === item" class="example-detail-row">
                            <td colspan="3">
                                <div class="example-element-detail"
                                     [@detailExpand]="expandedTradeTypeElement === item ? 'expanded' : 'collapsed'">
                                    <cb-table [loaded]="true" style="width: 100%">
                                        <tbody cbTableBody>
                                        <tr *ngFor="let value of sortedDocsForTradeTypes[item.id]"
                                            class="cb-margin-left-2x">
                                            <td class="typeLabelClass">{{ value.tradeType.label }}</td>
                                            <td>
                                                <ng-container *ngIf="value.documentTypes?.length > 0; else noLabels">
                                                    <ng-container *ngFor="let documentType of value.documentTypes">
                                                        <li>{{ documentType?.label }}</li>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #noLabels>-</ng-template>
                                            </td>
                                            <td cbTableShrinkCol cbTableStickyCol="end">
                                                <button mat-icon-button [disabled]="!canEdit()"
                                                        (click)="editTradeType(value)">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </cb-table>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </cb-table>

                <cb-table *ngIf="isSupplierTypesEnabled"
                          [loaded]="true"
                          [stringColumns]="supplyTypeColumns"
                          tableLabel="For Supply Types"
                          class="cb-margin-bottom-2x flex">
                    <tbody cbTableBody>
                    <ng-container *ngFor="let item of parentSupplyTypes">
                        <!-- Parent Row -->
                        <tr (click)="expandedSupplyTypeElement = expandedSupplyTypeElement === item ? null : item"
                            [class.example-expanded-row]="expandedSupplyTypeElement === item">
                            <td>{{ item.label }}</td>
                            <td></td>
                            <td>
                                <mat-icon>
                                    {{ expandedSupplyTypeElement === item ? 'arrow_drop_up' : 'arrow_drop_down' }}
                                </mat-icon>
                            </td>
                        </tr>
                        <!-- Expanded Detail Row -->
                        <tr *ngIf="expandedSupplyTypeElement === item" class="example-detail-row">
                            <td colspan="3">
                                <div class="example-element-detail"
                                     [@detailExpand]="expandedSupplyTypeElement === item ? 'expanded' : 'collapsed'">
                                    <cb-table [loaded]="true" style="width: 100%">
                                        <tbody cbTableBody>
                                        <tr *ngFor="let sortedDocs of sortedDocsForSupplyTypes[item.id]"
                                            class="cb-margin-left-2x">
                                            <td>{{ sortedDocs.supplyType.label }}</td>
                                            <td>
                                                <ng-container
                                                    *ngIf="sortedDocs.documentTypes?.length > 0; else noLabels">
                                                    <ng-container *ngFor="let documentType of sortedDocs.documentTypes">
                                                        <li>{{ documentType?.label }}</li>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #noLabels>-</ng-template>
                                            </td>
                                            <td cbTableShrinkCol cbTableStickyCol="end">
                                                <button mat-icon-button [disabled]="!canEdit()"
                                                        (click)="editSupplyType(sortedDocs)">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </cb-table>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </cb-table>
            </div>
        </div>
    </div>
</div>
