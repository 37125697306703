<cb-dialog [dialogHeading]="'Edit Lot Contingency Amount'">
    <form #editContingencyAmountForm="ngForm">
        <div class="flex-row"
             *cbWaitFor="lotContingencyAmountDto">
            <div class="flex-col flex">
                <cb-currency label="Contingency Amount"
                             name="contingencyAmount"
                             [(ngModel)]="lotContingencyAmountDto.contingencyAmount"
                             [required]="true">
                </cb-currency>
            </div>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="editContingencyAmountForm.pristine || editContingencyAmountForm.invalid"
                (click)="save()">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
