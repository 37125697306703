<cb-dialog dialogHeading="Lot Quote" *cbWaitFor="mappedItem">
    <form class="flex-col flex"
          #quoteForm="ngForm">
        <div class="cb-margin-bottom-2x flex-row layoutGap-20 flex-100">
            <cb-info-message *ngIf="!isLotSpecLocked"
                             [message]="'This Lot is not currently locked.'"
                             [icon]="'warning'"
                             [iconClass]="'mat-warn'"></cb-info-message>
            <span class="flex"></span>
            <button mat-icon-button
                    (click)="downloadQuote()">
                <mat-icon>file_download</mat-icon>
            </button>
            <button *ngIf="canOpenEditDialog()"
                    mat-icon-button
                    (click)="editQuote()">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div class="cb-margin-bottom-2x flex-row flex-100">
            <div class="flex-col flex-50">
                <cb-display-value-new [value]="mappedItem.supplierTradingName"
                                      label="Supplier">
                </cb-display-value-new>
                <cb-display-value-new [value]="mappedItem.quoteNumber"
                                      label="Quote #">
                </cb-display-value-new>
                <cb-display-value-new [value]="mappedItem.description"
                                      label="Description">
                </cb-display-value-new>
                <cb-display-value-new label="Amount (GST Exclusive)">
                    {{ mappedItem.quoteTotal | cbCurrency }}
                </cb-display-value-new>
            </div>
            <div class="cb-margin-left-3x flex-col flex-50">
                <cb-display-value-new label="Quote Status">
                    {{ mappedItem.quoteStatus | cbEnumToLabel: QUOTE_STATUS_ENUM }}
                </cb-display-value-new>
                <cb-display-value-new label="Quote Date">
                    <span class="mat-body-2">&nbsp;{{ mappedItem.quoteDate | date }}</span>
                </cb-display-value-new>
                <cb-display-value-new *ngIf="mappedItem?.expiryDate != undefined"
                                      label="Expiry Date">
                    <div class="start-center">
                        <mat-icon *ngIf="hasExpired()"
                                  class="cb-error help-cursor"
                                  title="Expired"
                                  aria-hidden="false">date_range
                        </mat-icon>
                        {{ mappedItem?.expiryDate| date }}
                    </div>
                </cb-display-value-new>
                <cb-display-value-new label="Unallocated Amount (GST Exclusive)">
                    {{ unallocatedAmount() | cbCurrency }}
                </cb-display-value-new>
            </div>
        </div>
        <cb-table [loaded]="mappedItemLoaded"
                  class="cb-margin-bottom-2x">
            <thead cbTableHead>
            <tr>
                <td></td>
                <td>Ref</td>
                <td colspan="2"
                    *ngIf="mappedItem.isScheduleSpecTemplate">Product Description
                </td>
                <td *ngIf="!mappedItem.isScheduleSpecTemplate">Type</td>
                <td *ngIf="!mappedItem.isScheduleSpecTemplate">Item</td>
                <td>House Area</td>
                <td *ngIf="mappedItem.isScheduleSpecTemplate">Schedule Item</td>
                <td>Qty</td>
                <td *ngIf="!mappedItem.isScheduleSpecTemplate">UOM</td>
                <td style="text-align: right">Rate</td>
                <td>GST</td>
                <td></td>
            </tr>
            </thead>
            <tbody cbTableBody>
            <tr cbTableRow
                *ngFor="let quoteLine of mappedItem.quoteLines | cbSortByImpurePipe: 'reference': 'asc';"
                [ngClass]="{ 'strike-text table-warn-no-scrollbar': quoteLine.isRemoved }">
                <td>
                    <mat-icon class="help-cursor cb-grey cb-margin-right"
                              [title]="getCostNatureIconTitle(quoteLine.costNatureId)">
                        {{ getCostNatureIcon(quoteLine.costNatureId) }}
                    </mat-icon>
                    <mat-icon title="Deleted"
                              *ngIf="quoteLine.lotSpecItemIsDeleted">delete_forever
                    </mat-icon>
                    <mat-icon title="Finalised"
                              *ngIf="quoteLine.isFinalised">lock
                    </mat-icon>
                </td>
                <td>{{ quoteLine.reference }}</td>
                <td colspan="2"
                    *ngIf="mappedItem.isScheduleSpecTemplate">
                    <cb-info-popup [label]="quoteLine.description"
                                   [tooltip]="quoteLine?.clientFriendlyDescription"></cb-info-popup>
                </td>
                <td *ngIf="!mappedItem.isScheduleSpecTemplate">{{ quoteLine.costTypeLabel }}</td>
                <td *ngIf="!mappedItem.isScheduleSpecTemplate">
                        <span *ngIf="quoteLine.description">
                            {{ quoteLine?.description }}
                        </span>
                    <span *ngIf="quoteLine.offering && !quoteLine.description">
                            {{ quoteLine?.offering?.name }}
                        </span>
                </td>
                <td>{{ quoteLine.specGroupLabel }}</td>
                <td *ngIf="mappedItem.isScheduleSpecTemplate">{{ quoteLine?.slot?.name }}</td>
                <td>{{ quoteLine.quantity }}</td>
                <td *ngIf="!mappedItem.isScheduleSpecTemplate">{{ quoteLine?.offering?.uomDisplay }}</td>
                <td style="text-align: right">{{ quoteLine.allocatedAmount | cbCurrency }}</td>
                <cb-td-checkbox [value]="quoteLine.gstApplies"></cb-td-checkbox>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button *ngIf="quoteLine.reference"
                            mat-icon-button
                            class="cb-margin-right"
                            [cdkCopyToClipboard]="quoteLine.reference"
                            matTooltip="Copy Qref to clipboard">
                        <mat-icon svgIcon="clipboard-text-play-outline"></mat-icon>
                    </button>
                    <button *ngIf="quoteLine.isFinalised && !quoteLine.isRemoved"
                            mat-icon-button
                            class="cb-margin-right"
                            (click)="viewFinalised(quoteLine)">
                        <mat-icon>pageview</mat-icon>
                    </button>
                    <button *ngIf="mappedItem.quoteStatus !== QUOTE_STATUS_ENUM.Submitted && !quoteLine.isFinalised"
                            mat-icon-button
                            [matMenuTriggerFor]="addOptionMenu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #addOptionMenu="matMenu">
                        <button mat-menu-item
                                (click)="editQuoteLine(quoteLine, quoteForm.form)">
                            <mat-icon>edit</mat-icon>
                            Edit
                        </button>
                        <button mat-menu-item
                                (click)="removeQuoteLine(quoteLine, quoteForm.form)">
                            <mat-icon>remove_circle</mat-icon>
                            Remove
                        </button>
                        <button mat-menu-item
                                (click)="finaliseQuoteLine(quoteLine)"
                                [disabled]="quoteLine.id == undefined || quoteLine.id == 0">
                            <mat-icon>lock</mat-icon>
                            Finalise
                        </button>
                    </mat-menu>
                </td>
            </tr>
            <tr>
                <td colspan="2"></td>
                <td>
                    <strong>Total</strong>
                </td>
                <td colspan="4"></td>
                <td class="right-align">
                    <strong>{{ lineTotal() | cbCurrency }}</strong>
                </td>
                <td></td>
                <td>
                        <span *cbWaitFor="houseAreas && excludedSlotIds">
                            <button *ngIf="!quoteLineCreateDisabled()"
                                    mat-icon-button
                                    (click)="createQuoteLine(quoteForm.form)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </span>
                </td>
            </tr>
            </tbody>
        </cb-table>
        <cb-user-date-time-new label="Created"
                               [date]="mappedItem.createdDate"
                               [userFullName]="mappedItem.createdByName"
                               [floatingLabel]="true"
                               [displayTime]="true"></cb-user-date-time-new>
        <cb-user-date-time-new label="Updated"
                               [date]="mappedItem.updatedDate"
                               [userFullName]="mappedItem.updatedByName"
                               [floatingLabel]="true"
                               [displayTime]="true"></cb-user-date-time-new>
        <cb-display-value-new label="Does this quote require a change?">
            {{ mappedItem.changeRecordRequired | cbPrettyBool }}
            {{ isLotSpecLocked ? ' (Lot has been locked)' : '' }}
        </cb-display-value-new>
        <cb-display-value-new *ngIf="mappedItem.changeRecordRequired"
                              label="Does this quote need to be shown as multiple lines on the variation?">
            {{ mappedItem.createMultipleChangeRecords | cbPrettyBool }}
        </cb-display-value-new>
    </form>
    <div actions class="flex-row flex">
        <button mat-raised-button
                *ngIf="canAbandonQuote()"
                (click)="abandonQuote()">
            <mat-icon>
                delete_forever
            </mat-icon>
            Abandon
        </button>
        <button mat-raised-button
                *ngIf="mappedItem.changeRecordIds?.length > 0"
                (click)="viewChangeRecords()">
            View Change Records
        </button>
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                [disabled]="!quoteForm.valid || !quoteForm.dirty"
                (click)="saveQuote(quoteForm.form)">Save
        </button>
        <button mat-raised-button
                color="primary"
                *ngIf="mappedItem.quoteStatus === QUOTE_STATUS_ENUM.Pending && canSubmitQuote()"
                [disabled]="submitQuoteInProgress || mappedItem.quoteLines.length < 1"
                [clickLimit]="1"
                (cbClick)="submitQuote()">Submit
        </button>
        <button class="raised"
                mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>

</cb-dialog>
