import { CbInfoMessageModule } from 'cb-hub-lib';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbDasherModule } from 'cb-hub-lib';
import { CbDateDurationCalculatorModule } from '@app/shared/components/date-duration-calculator/date-duration-calculator.module';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbEnumToLabelModule } from '@app/shared/pipe/enum-to-label/enum-to-label.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbLoadingSpinnerModule, CbSelectListModule } from 'cb-hub-lib';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CommonModule } from '@angular/common';
import { EditContingencyAmountDialogComponent } from './edit-contingency-amount-dialog/edit-contingency-amount-dialog.component';
import { EditManualOrderDialogComponent } from './edit-manual-order-dialog/edit-manual-order-dialog.component';
import { EditManualOrderLineDialogComponent } from './edit-manual-order-line-dialog/edit-manual-order-line-dialog.component';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LotBuildOrdersSearchComponent } from './lot-build-orders-search/lot-build-orders-search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { NgModule } from '@angular/core';
import { ReviewManualOrderDialogComponent } from './review-manual-order-dialog/review-manual-order-dialog.component';
import { SelectVariationDialogComponent } from './select-variation/select-variation.component';
import { ViewManualOrderDialogComponent } from './view-manual-order-dialog/view-manual-order-dialog.component';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbSortByModule } from '@app/shared/pipe/sort-by/sort-by.module';

@NgModule({
    declarations: [
        LotBuildOrdersSearchComponent,
        ViewManualOrderDialogComponent,
        EditManualOrderDialogComponent,
        EditContingencyAmountDialogComponent,
        EditManualOrderLineDialogComponent,
        ReviewManualOrderDialogComponent,
        SelectVariationDialogComponent
    ],
    imports: [
        CommonModule,
        CbClickModule,
        FormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatTableModule,
        InfiniteScrollModule,
        CbLoadingSpinnerModule,
        MatIconModule,
        CbFormsModule,
        CbWaitForModule,
        FormsModule,
        MatButtonModule,
        MatDividerModule,
        MatMenuModule,
        CbCurrencyModule,
        CbDasherModule,
        CbDialogModule,
        CbDisplayValueModule,
        CbUserDateTimeModule,
        MatRadioModule,
        CbDateDurationCalculatorModule,
        CbDialogModule,
        CbSelectListModule,
        CbEnumToLabelModule,
        CbInfoMessageModule,
        CbSortByModule
    ],

    exports: [
        LotBuildOrdersSearchComponent
    ],

})

export class LotBuildOrdersModule { }
