import { Injectable, TemplateRef } from '@angular/core';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { BlockingDialogComponent } from './blocking-dialog/blocking-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SimpleMessageDialogComponent } from './simple-message-dialog/simple-message-dialog.component';
import { SimpleFormDialogComponent, SimpleFormInputType } from './simple-form-dialog/simple-form-dialog.component';

interface IConfirmDialogOpenConfig {
    confirmed?: () => any;
    declined?: () => any;
    message?: string;
    yesLabel?: string;
    noLabel?: string;
    dialogHeading?: string;
    // This indicates that clicking the close icon should not trigger the same event as when you click the 'decline' button
    // If you set this to true, clicking close will simply close the dialog and not trigger the decline event
    disableDeclineEventOnCloseIcon?: boolean;
}

interface IFormDialogOpenConfig {
    dialogHeading?: string;
    yesLabel?: string;
    noLabel?: string;
    value?: string;
    formInputConfig?: {
        type?: SimpleFormInputType;
        label?: string;
        maxlength?: number;
        required?: boolean;
    };
    confirmed?: (result: string) => void;
    declined?: () => void;
}


@Injectable({
    providedIn: 'root'
})
export class CbDialogService {

    constructor(
        private readonly matDialog: MatDialog,
    ) { }

    public confirm(config: IConfirmDialogOpenConfig = {
        message: 'Are you sure?',
        dialogHeading: '',
        yesLabel: 'Yes',
        noLabel: 'No',
        confirmed: () => null,
        declined: () => null,
        disableDeclineEventOnCloseIcon: false
    }): void {
        this.matDialog
            .open(ConfirmDialogComponent, {
                data: {
                    dialogHeading: config.dialogHeading,
                    message: config.message,
                    yesLabel: config.yesLabel,
                    noLabel: config.noLabel,
                    disableDeclineEventOnCloseIcon: config.disableDeclineEventOnCloseIcon
                },
                panelClass: 'cb-dialog-container',
                disableClose: true,
                autoFocus: false
            })
            .afterClosed()
            .subOnce({
                next: (res) => {
                    if (res) {
                        return config.confirmed && config.confirmed();
                    }else if (res === false || !config.disableDeclineEventOnCloseIcon) {
                        return config.declined && config.declined();
                    }
                    // Getting here means the close icon was clicked, and we were specifically told
                    // NOT to trigger the decline event. So just return.
                    return;
                }
            });
    }

    public open<DialogDataType = any, DialogResultType = any, DialogComponentType = any>(
        componentOrTemplateRef: (ComponentType<DialogComponentType> | TemplateRef<DialogComponentType>) & { MIN_WIDTH: string },
        config: MatDialogConfig<DialogDataType> & { fullWidth?: boolean } = {}
    ): MatDialogRef<DialogComponentType, DialogResultType> {
        const panelClass = config.panelClass ?? (config.fullWidth ? 'cb-full-width-dialog' : 'cb-dialog-container');
        return this.matDialog
            .open(
                componentOrTemplateRef,
                {
                    disableClose: true,
                    minWidth: componentOrTemplateRef.MIN_WIDTH,
                    ...config,
                    panelClass,
                }
            );
    }

    public block<R = any>(message?: string): MatDialogRef<BlockingDialogComponent, R> {
        return this.matDialog
            .open(
                BlockingDialogComponent,
                {
                    disableClose: true,
                    data: {
                        message
                    }
                }
            );
    }

    public simpleMessageDialog<R = any>(message?: string, dialogHeading?: string): MatDialogRef<SimpleMessageDialogComponent, R> {
        return this.matDialog
            .open(
                SimpleMessageDialogComponent,
                {
                    disableClose: false,
                    data: {
                        message,
                        dialogHeading
                    },
                    panelClass: 'cb-dialog-container',
                }
            );
    }

    public simpleFormDialog(config: IFormDialogOpenConfig = {
        dialogHeading: '',
        yesLabel: 'Yes',
        noLabel: 'No',
        value: '',
        formInputConfig: {
            type: SimpleFormInputType.Textarea,
            label: '',
            maxlength: 0,
            required: true,
        },
        confirmed: () => null,
        declined: () => null
    }): void {
        this.matDialog
            .open(
                SimpleFormDialogComponent,
                {
                    minWidth: '40%',
                    data: {
                        dialogHeading: config.dialogHeading,
                        yesLabel: config.yesLabel,
                        noLabel: config.noLabel,
                        value: config.value,
                        formInputConfig: config.formInputConfig,
                    },
                    panelClass: 'cb-dialog-container',
                    disableClose: true,
                    autoFocus: false
                }
            )
            .afterClosed()
            .subOnce((result: false | string) => {
                if (result) {
                    return config.confirmed && config.confirmed(result);
                }
                return config.declined && config.declined();
            });
    }

    public closeAll(): void {
        this.matDialog.closeAll();
    }
}
