import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BusinessEntitiesLogicService, IBusinessEntityDto} from '@app/logic/business-entity';
import {find} from 'lodash';
import {MatDialogRef} from '@angular/material/dialog';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {SpecTemplateDialogComponent} from '../spec-template-dialog/spec-template-dialog.component';
import {ISpecTemplateDto, SpecTemplateLogicService, SpecTemplateMappedItem} from '@app/logic/spec-template';
import {ProjectsLogicService} from '@app/logic/projects';
import {IProjectDetailsDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-spec-template-side-panel',
    templateUrl: './spec-template-side-panel.component.html',
    styleUrls: ['./spec-template-side-panel.component.scss']
})
export class SpecTemplateSidePanelComponent {
    @Input() public template: ISpecTemplateDto;
    @Input() public canEdit: boolean;
    @Input() public refresh$: BehaviorSubject<boolean>;

    @Output() public readonly saveClick = new EventEmitter();

    public collapsed$ = new BehaviorSubject<boolean>(false);

    private regions: IBusinessEntityDto[];
    private projects: IProjectDetailsDto[];

    constructor(
        private readonly cbDialog: CbDialogService,
        private readonly specTemplateLogicService: SpecTemplateLogicService,
        private readonly businessEntitiesLogic: BusinessEntitiesLogicService,
        private readonly projectsLogic: ProjectsLogicService,
    ) {
        this.businessEntitiesLogic.$getList().subOnce(result => this.regions = result ?? []);
        this.projectsLogic.$getList().subOnce(result => this.projects = result ?? []);
    }

    public getRegionsText(): string {
        let text = '';
        if (this.template.projectId) {
            const project = find(this.projects, { id: this.template.projectId });
            text = project ? `Project ( ${project.projectName} )` : '';
        } else if (this.template.regionIds?.length && this.regions?.length) {
            const regions = [];
            this.template.regionIds.forEach((regionId: number) => {
                const match = find(this.regions, { id: regionId });
                if (match) {
                    regions.push(match.name);
                }
            });
            text = regions.length ? regions.join(', ') : this.regions?.length ? 'Nationwide' : '';
        }
        return text;
    }

    public editItemClicked(): MatDialogRef<SpecTemplateDialogComponent> {
        const mappedItem = this.specTemplateLogicService.$createMappedItem(this.template, SpecTemplateMappedItem);
        const dialog = this.cbDialog.open(SpecTemplateDialogComponent, {
            data: {
                dialogHeading: 'Edit Specification Template',
                mappedItem,
            },
        });
        dialog.afterClosed().subOnce((item: SpecTemplateMappedItem) => this.refresh$.next(!this.refresh$.value));
        return dialog;
    }

    public generateReportClicked(): void {
        this.specTemplateLogicService.generateReport(this.template.id).subOnce();
    }
}
