import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {IWorkingDrawingMappedItem} from '@app/logic/working-drawings';
import {FormMode} from '@app/shared/enums/form';
import {provideParentForm} from '@app/shared/providers/provide-parent-form.provider';
import {IWorkingDrawingItemDto, WORKING_DRAWING_ITEM_STATUS_ENUM, WORKING_DRAWING_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {WorkingDrawingItemDialogComponent} from '../working-drawing-item-dialog/working-drawing-item-dialog.component';
import {remove} from 'lodash';

@Component({
    selector: 'cb-working-drawing-items',
    templateUrl: './working-drawing-items.component.html',
    styleUrls: ['./working-drawing-items.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class WorkingDrawingItemsComponent {
    @Input() public selectedTabIndex: number;
    @Input() public mappedItem: IWorkingDrawingMappedItem;
    @Input() public showHeaderAndAddButton = true;
    @Input() public showDeleteButton = true;
    @Input() public addAndSave = false;
    @Input() public isReadOnly = false;
    @Input() public parentForm: NgForm;

    public FormMode = FormMode;
    public WORKING_DRAWING_ITEM_STATUS_ENUM = WORKING_DRAWING_ITEM_STATUS_ENUM;

    constructor(
        public readonly matDialog: MatDialog,
        private readonly cdRef: ChangeDetectorRef) { }

    public addEditItem(formMode: FormMode, item: IWorkingDrawingItemDto, itemIndex = 0): void {

        let nextItemNumber = 1;

        if (this.mappedItem.items) {
            const itemNumbers = this.mappedItem.items.map(i => i.itemNumber);
            if (itemNumbers?.length > 0) {
                const highestItemNumber = Math.max(...itemNumbers);
                nextItemNumber = highestItemNumber + 1;
            }
        }

        if (!item) {
            item = { ...item, itemNumber: nextItemNumber, id: 0 } as IWorkingDrawingItemDto;
        }

        this.matDialog.open(WorkingDrawingItemDialogComponent, {
            data: {
                item,
                mappedItem: this.mappedItem,
                formMode,
                addAndSave: this.addAndSave,
                itemIndex
            },
            panelClass: 'cb-dialog-container',
            minWidth: '40%',
        })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.parentForm?.form.markAsDirty();
                    this.cdRef.detectChanges();
                }
            });
    }

    public deleteItem(mappedItem: IWorkingDrawingItemDto): void {
        mappedItem.isDeleted = true;

        // If this is a new item that has just been deleted, remove it.
        remove(this.mappedItem.items, item => item.isDeleted && item.id === 0);

        this.parentForm.form.markAsDirty();
        this.cdRef.detectChanges();
    }

    public isEditItemDisabled(): boolean {
        return this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Completed
            || this.mappedItem.statusId === WORKING_DRAWING_STATUS_ENUM.Cancelled;
    }

}
