<mat-card class="flex-row"
          *ngIf="canCreateConsent()">
    <mat-card-content class="flexible-gap">
        <button mat-raised-button
                class="cb-margin-right"
                aria-label="Add Consent"
                color="primary"
                [matMenuTriggerFor]="menu"
                [disabled]="!(lotDto?.councilId > 0)">
            <mat-icon>arrow_drop_down</mat-icon>
            Add
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item
                    [disabled]="!lotDto?.hasCompletedWorkingDrawing"
                    (click)="createBuildingConsent()">
                Building Consent
            </button>
            <button mat-menu-item
                    (click)="createResourceConsent()">
                Resource Consent
            </button>
        </mat-menu>
        <button mat-raised-button
                [disabled]="!lotDto?.hasCompletedWorkingDrawing || lotConsentViewService.hasPrimaryBuildingConsent"
                (click)="requestBuildingConsent()"
                color="primary">Request Building Consent
        </button>
    </mat-card-content>
</mat-card>
