import { AfterViewInit, ChangeDetectorRef, Directive, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchService } from '@app/core/services/search/base.search.service';
import { SellingAgentsLogicService } from '@app/logic/selling-agents/sellingagents.logic.service';
import { InputFindType } from '../../../input-find-type.enum';
import { BaseInputFindDialogDirective } from '../../base-input-find-dialog.component';

interface IData {
    mappedItem: any;
    searchService: SearchService;
    extraSearchParams: { [key: string]: any };
    dialogHeading: string;
    contentHeading: string;
    placeholder: string;
}

/**
 * Compose this inside a concrete inputFindCardComponent and a concrete InputFindTableComponent
 */
@Directive()
export class SellingAgentsInputFindDialogDirective extends BaseInputFindDialogDirective<SellingAgentsLogicService> implements AfterViewInit {
    constructor(
        public readonly dialogRef: MatDialogRef<SellingAgentsInputFindDialogDirective>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public logicService: SellingAgentsLogicService,
        public cdRef: ChangeDetectorRef
    ) {
        super(
            dialogRef,
            data,
            logicService,
            cdRef,
            InputFindType.SellingAgent,
        );
    }

    public ngAfterViewInit(): void {
        this.data.searchService.infiniteScrollContainer = this.scrollContainer;
        this.data.searchService.extraSearchParams = this.data.extraSearchParams;
        this.data.searchService.logicService = this.logicService;
        if (this.data.searchService.isPagination) {
            this.data.searchService.nextPage$.next({ addPage: 1 });
        } else {
            this.data.searchService.doSearchIfNoScrollBar();
        }
        this.cdRef.detectChanges();
    }
}

