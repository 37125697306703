import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ChangeRecordLogicService } from '@app/logic/change-records/change-record.logic-service';
import { LotsLogicService } from '@app/logic/lots/lots.logic.service';
import { IVariationLogicService } from '@app/logic/variation/interfaces/i.variation.logic-service';
import { IVariationMappedItem } from '@app/logic/variation/interfaces/i.variation.mapped';
import { VariationLogicService } from '@app/logic/variation/variation.logic-service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import {
    COST_NATURE_ENUM,
    IUnassignedChangeRecordDto,
    IVariationDto,
    IVariationLineDto,
    VARIATION_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { LotChangesVariationsDataService } from '../lot-changes-variations.data.service';

interface IData {
    lotId: number;
}

@Component({
    selector: 'cb-lot-generate-variation-dialog',
    templateUrl: './lot-generate-variation-dialog.component.html',
    styleUrls: ['./lot-generate-variation-dialog.component.scss'],
})
export class LotGenerateVariationDialogComponent extends BaseDialogFormViewDirective<IVariationDto, IVariationMappedItem, IVariationLogicService> {
    public static readonly MIN_WIDTH = '550px';
    public unassignedChangeRecords: IUnassignedChangeRecordDto[] = [];
    public unassignedChangeRecordsLoaded = false;
    public selectedChangeRecordIds: { [id: number]: boolean } = {};

    public readonly displayedColumns = [
        '',
        'Change#',
        'Description',
        'Amount',
    ];

    public COST_NATURE_ENUM = COST_NATURE_ENUM;

    public lotId: number;

    public waitingForSubmitReturn = false;

    constructor(
        public readonly dialogRef: MatDialogRef<LotGenerateVariationDialogComponent>,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ChangeRecordLogicService) public readonly changeRecordLogicService: ChangeRecordLogicService,
        @Inject(LotsLogicService) public readonly lotsLogicService: LotsLogicService,
        @Inject(VariationLogicService) public readonly variationLogicService: VariationLogicService,
        @Inject(LotChangesVariationsDataService) public lotChangesVariationsDataService
    ) {
        super(dialogRef, toastService);
        this.lotId = data.lotId;

        this.loadUnassignedChangeRecords();

    }

    public selectAll(): void {
        this.selectedChangeRecordIds = {};
        this.unassignedChangeRecords.forEach(change => {
            this.selectedChangeRecordIds[change.changeRecordId] = true;
        });
    }

    public deSelectAll(): void {
        this.selectedChangeRecordIds = {};
    }

    private loadUnassignedChangeRecords(): void {
        this.unassignedChangeRecordsLoaded = false;
        this.lotsLogicService.getUnassignedChangeRecordsForVariation(this.lotId, COST_NATURE_ENUM.ExtraToClient).subOnce( {
            next: (result) => {
                this.unassignedChangeRecords = result;
            },
            error: () => {
                this.unassignedChangeRecordsLoaded = true;
            },
            complete: () => {
                this.unassignedChangeRecordsLoaded = true;
            }
        });
    }

    public generateVariation(): void {

        this.waitingForSubmitReturn = true;
        let variationLines: IVariationLineDto[] = [];

        variationLines = Object.keys(this.selectedChangeRecordIds)
            .filter((id) => { return this.selectedChangeRecordIds[id]; })
            .map(Number)
            .map(id => ({
                changeRecordId: id,
            }) as IVariationLineDto);

        this.mappedItem = this.variationLogicService.$createMappedItem(
            {
                costNature: COST_NATURE_ENUM.ExtraToClient,
                variationStatus: VARIATION_STATUS_ENUM.Pending,
                lotId: this.lotId,
                lines: variationLines
            });

        this.variationLogicService.$saveCreate(this.mappedItem.$getMappedDtoItem()).subOnce(
            {
                next:
                    (result) => {
                        this.dialogRef.close(result);
                        this.lotChangesVariationsDataService.loadUnassignedChangeRecordsCount(this.lotId);
                    },
                error: () => {
                    this.waitingForSubmitReturn = false;
                }
            }
        );
    }


    public checkboxClicked($event, item: IUnassignedChangeRecordDto): void {
        if ($event) {
            this.selectedChangeRecordIds[item.changeRecordId] = true;
        } else {
            this.selectedChangeRecordIds[item.changeRecordId] = false;
        }
    }


    public isSaveDisabled(): boolean {
        const hasSelectedItem = Object.keys(this.selectedChangeRecordIds)
            .filter((id) => { return this.selectedChangeRecordIds[id]; }).length > 0;

        return !hasSelectedItem && !this.waitingForSubmitReturn;
    }


}

