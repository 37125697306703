<div class="flex-col"
     *ngIf="!readonly">
    <div *ngIf="!hideSweepAndLabels"
         class="cb-margin start-center flex-row">
        <h3 class="mat-headline-6 with-divider flex"
            *ngIf="addressTitle !== 'Address' && !hideHeading">
            {{addressTitle}}
        </h3>
        <span class="flex"></span>
        <button mat-icon-button
                matTooltip="Clear Address Fields"
                (click)="clearAllFields()">
            <mat-icon>delete_sweep</mat-icon>
        </button>
    </div>
    <mat-divider *ngIf="!hideHeading && addressTitle !== 'Address'"></mat-divider>
    <div *ngIf="value"
         class="cb-margin-top mat-body-2">
        <cb-input [name]="name + 'street'"
                  label="Street Address"
                  [required]="getIsRequired()"
                  [softRequired]="softRequired"
                  [maxlength]="150"
                  [disabled]="!edit"
                  [(ngModel)]="value.street">
        </cb-input>
        <cb-input [name]="name + 'suburb'"
                  label="Suburb"
                  [required]="getIsRequired()"
                  [softRequired]="softRequired"
                  [maxlength]="150"
                  [disabled]="!edit"
                  [(ngModel)]="value.suburb">
        </cb-input>
        <cb-input [name]="name + 'postCode'"
                  label="Post Code"
                  [required]="false"
                  [maxlength]="10"
                  type="number"
                  [disabled]="!edit"
                  [(ngModel)]="value.postCode">
        </cb-input>
        <cb-input [name]="name + 'city'"
                  label="City"
                  [required]="getIsRequired()"
                  [softRequired]="softRequired"
                  [maxlength]="150"
                  [disabled]="!edit"
                  [(ngModel)]="value.city">
        </cb-input>
        <cb-select [required]="getIsRequired()"
                   [softRequired]="softRequired"
                   label="Region"
                   [disabled]="!edit"
                   [name]="name + 'region'"
                   [(ngModel)]="value.region"
                   (change)="regionId$?.next(value.region);"
                   [options]="regions">
        </cb-select>
    </div>
</div>
<div class="flex-col"
     *ngIf="readonly">
    <div *ngIf="!hideHeading">
        <h3 class="mat-headline-6 with-divider flex">
            {{addressTitle || "Address"}}
        </h3>
    </div>
    <div *ngIf="value">
        <div *ngIf="!hideLabels">
            <cb-display-value-new label="Street Address">
                {{value?.street}}
            </cb-display-value-new>
            <cb-display-value-new label="Suburb">
                {{value?.suburb}}
            </cb-display-value-new>
            <cb-display-value-new label="Post Code">
                {{value?.postCode}}
            </cb-display-value-new>
            <cb-display-value-new label="City">
                {{value?.city}}
            </cb-display-value-new>
            <cb-display-value-new label="Region">
                {{regionName}}
            </cb-display-value-new>
        </div>
        <div *ngIf="hideLabels" class="mat-body-2">
            <div>{{value?.street}}</div>
            <div>{{value?.suburb}}</div>
            <div>{{value?.postCode}}</div>
            <div>{{value?.city}}</div>
            <div>{{regionName}}</div>
        </div>
    </div>
</div>
