import {Component, EventEmitter, Inject, Output} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


interface IData {
    dialogHeading: string;
    documentName: string;
}

@Component({
    selector: 'cb-image-upload-dialog',
    templateUrl: './image-upload-dialog.component.html',
    styleUrls: ['./image-upload-dialog.component.scss']
})
export class ImageUploadDialogComponent {

    @Output() public submitClicked = new EventEmitter<File>();

    public static readonly MIN_WIDTH = '600px';

    public isFileValidAndUploaded = false;
    public uploadedImage: File;

    public dialogHeading: string;
    public documentName: string;

    constructor(
        private readonly dialogRef: MatDialogRef<ImageUploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: IData,) {
        this.dialogHeading = data.dialogHeading;
        this.documentName = data.documentName;
    }

    public uploadFile(uploadedFile: File): void {
        this.isFileValidAndUploaded = false;
        if (this.isImageFile(uploadedFile[0])) {
            this.setDocument(uploadedFile[0]);
        }
    }

    public isImageFile(uploadedFile: File): boolean {
        return uploadedFile && uploadedFile.type.split('/')[0] === 'image';
    }

    private setDocument(uploadedFile: File): void {
        this.uploadedImage = uploadedFile;
        this.isFileValidAndUploaded = true;
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public onSubmit(): void {
        this.submitClicked.emit(this.uploadedImage);
    }

}
