import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IntegrationLogsComponent} from './integration-logs/integration-logs.component';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbTableModule} from 'cb-hub-lib';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {BrowserModule} from '@angular/platform-browser';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {MatTabsModule} from '@angular/material/tabs';
import {ExceptionLogsComponent} from './exception-logs/exception-logs.component';
import {ExceptionLogViewDialogComponent} from './exception-log-view-dialog/exception-log-view-dialog.component';
import {CbDisplayValueModule} from '@app/shared/components/display-value/display-value.module';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {MessageHistoryComponent} from './message-history/message-history.component';
import {AbmLogsComponent} from './abm-logs/abm-logs.component';
import {MessageViewDialogComponent} from './message-view-dialog/message-view-dialog.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import {ContactIntegrationExceptionLogsComponent} from './contact-integration-exception-logs/contact-integration-exception-logs.component';


@NgModule({
    declarations: [
        IntegrationLogsComponent,
        ExceptionLogsComponent,
        ExceptionLogViewDialogComponent,
        MessageHistoryComponent, AbmLogsComponent,
        MessageViewDialogComponent,
        ContactIntegrationExceptionLogsComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        CbHubTableModule,
        CbTableModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        CbFormsModule,
        MatTabsModule,
        CbDisplayValueModule,
        CbDialogModule,
        MatChipsModule,
        MatMenuModule,
    ],
    exports: [
        IntegrationLogsComponent
    ]
})
export class IntegrationLogsModule { }
