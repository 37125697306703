import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SlotSearchService } from '@app/core/services/search/slot-search.service';
import { ISlotSearchFilters } from '@app/core/services/user-cache/user-cache-areas';
import { SlotsLogicService } from '@app/logic/slots/slots.logic-service';
import { ICategoryDto, ISlotDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { map, Observable, Subscription } from 'rxjs';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { AutocompleteScrollerComponent, getBaseAutocompleteScrollerProvider } from '../../autocomplete-scroller.component';
import { SlotHouseAreaLabelDecorator } from './label-decorator/slot-house-area-label-decorator';
import { SlotNameLabelDecorator } from './label-decorator/slot-name-label-decorator';
import { SlotParentLabelDecorator } from './label-decorator/slot-parent-label-decorator';

@Component({
    selector: 'cb-slot-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(SlotAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(SlotAutocompleteScrollerComponent),
        SlotSearchService,
        SlotsLogicService
    ]
})
export class SlotAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent<any> // TODO: The results of the slot autocomplete scroller should be typed
    implements OnInit, OnDestroy {

    public results: any[] = [];
    public searchResultsLoaded = false;
    @HostBinding('class') class = 'flex-row flex';
    @Input() public readonly shouldShowParentInLabel: boolean = true;
    @Input() public readonly isActive = true;
    @Input() public readonly shouldShowHouseAreaInLabel: boolean = false;

    @Input() public set excludeParents(x: boolean) {
        this.searchService.extraSearchParams = {
            ...this.searchService.extraSearchParams,
            excludeParents: x,
        } as unknown as ISlotSearchFilters;
        this.reperformSearch();
    }

    @Input() public set excludeChildren(x: boolean) {
        this.searchService.extraSearchParams = {
            ...this.searchService.extraSearchParams,
            excludeChildren: x,
        } as unknown as ISlotSearchFilters;
        this.reperformSearch();
    }

    private _excludeIds: number[];
    @Input() public set excludeIds(x: number[]) {
        this._excludeIds = x;
        this.searchService.extraSearchParams = {
            ...this.searchService.extraSearchParams,
            excludeIds: x,
        } as unknown as ISlotSearchFilters;
        this.reperformSearch();
    }
    public get excludeIds(): number[] {
        return this._excludeIds;
    }


    private _specGroupId: number;
    @Input() public set specGroupId(x: number) {
        this._specGroupId = x;
        this.searchService.extraSearchParams = {
            ...this.searchService.extraSearchParams,
            specgroupId: x,
        } as unknown as ISlotSearchFilters;
        this.reperformSearch();
    }
    public get specGroupId(): number {
        return this._specGroupId;
    }

    /**
     * Pasing LotId as Search param will return a list of items already present on the Lot Specification
     */
    private _lotId: number;
    @Input() public set lotId(id: number) {
        this._lotId = id;
        this.searchService.extraSearchParams = {
            ...this.searchService.extraSearchParams,
            usedForLotId: id,
        } as unknown as ISlotSearchFilters;
        this.reperformSearch();
    }
    public get lotId(): number {
        return this._lotId;
    }

    private resultsToInclSub$ = new Subscription();
    private resultsToInclArr = [] as ISlotDto[];
    /** extra results to incl in search results */
    @Input() public set resultsToIncl(x: Observable<ISlotDto[]>) {
        this.resultsToInclSub$.unsubscribe();
        this.resultsToInclArr = [];
        if (x) {
            this.resultsToInclSub$ = x.subscribe((results) => {
                this.resultsToInclArr = results;
                this.reperformSearch();
            });
        }
    }

    private resultsToExcl$ = new Subscription();
    private resultsToExclArr = [] as ISlotDto[];
    /** results to exclude from search results */
    @Input() public set resultsToExcl(x: Observable<ISlotDto[]>) {
        this.resultsToExcl$.unsubscribe();
        this.resultsToExclArr = [];
        if (x) {
            this.resultsToExcl$ = x.subscribe((results) => {
                this.resultsToExclArr = results;
                this.reperformSearch();
            });
        }
    }

    constructor(
        public readonly slotsLogicService: SlotsLogicService,
        public readonly dialog: MatDialog,
        public readonly searchService: SlotSearchService
    ) {
        super(dialog, searchService);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.searchService.extraSearchParams = { ...this.searchService.extraSearchParams, isActive: this.isActive };

        this.filteredLookups$ = this.filteredLookups$.pipe(
            map((results: ISlotDto[]) => {
                const withExtras = [
                    ...this.resultsToInclArr.filter(extra =>
                        extra.houseArea.id === this.specGroupId
                        && extra.name.toLowerCase().includes(this.valueToLowerCase())
                        && !results.map(x => x.id).includes(extra.id)
                        && !this.excludeIds.includes(extra.id)
                    ),
                    ...results
                ];
                return withExtras.filter(result => !this.resultsToExclArr.map(x => x.id).includes(result.id));
            })
        );
    }

    public valueToLowerCase(): string {
        if (typeof (this.value) === 'string' || typeof (this.value) === 'number') {
            return this.value.toString().toLowerCase();
        } else if (this.value?.name) {
            return this.value.name.toString().toLowerCase();
        }
        return '';
    }

    public ngOnDestroy(): void {
        this.resultsToInclSub$.unsubscribe();
        this.resultsToExcl$.unsubscribe();
    }

    public displayWith = (item: any): string => {
        if (!item) {
            return;
        }

        let label = new SlotNameLabelDecorator().writeLabel(item, null);

        if (this.shouldShowParentInLabel) {
            label = new SlotParentLabelDecorator().writeLabel(item, label);
        }

        if (this.shouldShowHouseAreaInLabel) {
            label = new SlotHouseAreaLabelDecorator().writeLabel(item, label);
        }

        return label;
    };
}



