import { IUserDto } from './../../../../../../logic/users/interfaces/i.user.dto';
import { takeOne } from 'cb-hub-lib';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { UsersLogicService } from './../../../../../../logic/users/users.logic.service';
import { UsersInputFindDialogTableComponent } from './../../dialog/extensions/users-input-find-dialogs/users-input-find-dialog-table.component';
import { NavigationService } from './../../../../../../core/services/navigation/navigation.service';
import { BaseInputFindDirective } from '../../base-input-find.directive';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';


@Component({
    selector: 'cb-users-input-find-table',
    templateUrl: '../../base-input-find.directive.html',
    styleUrls: ['../../base-input-find.directive.scss'],
    providers: [
        getBaseFormComponentDirectiveProvider(UsersInputFindTableComponent),
        UsersLogicService,
        provideParentForm()
    ]
})
export class UsersInputFindTableComponent extends BaseInputFindDirective {
    protected _myDialog = UsersInputFindDialogTableComponent;
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public readonly usersLogicService: UsersLogicService,
        public readonly navigationService: NavigationService,) {
        super();
    }

    public clear(): void {
        super.clear();
        this.text = '';
    }

    public openSearchDialog = (): void => {
        this.dialogRef.open(
            this._myDialog,
            {
                minWidth: '80%',
                data: {
                    extraSearchParams: this.searchParams,
                    placeholder: 'Type First Name, Last Name, or Email',
                    dialogHeading: 'Lookup User',
                    contentHeading: 'User Search',
                },
                panelClass: 'cb-dialog-container'
            }
        )
            .afterClosed()
            .pipe(takeOne())
            .subscribe({
                next: (user: IUserDto) => {
                    if (user) {
                        this.text = `${user.firstName} ${user.lastName}`;
                        this.value = user.id;
                    }
                }
            });
    };
}
