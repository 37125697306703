<cdk-virtual-scroll-viewport #viewport
                             cbFillHeight
                             [ngClass]="fullscreen ?'fullscreen' : '' "
                             id="viewport"
                             [itemSize]="50"
                             [minBufferPx]="viewport?.elementRef?.nativeElement?.clientHeight"
                             [maxBufferPx]="viewport?.elementRef?.nativeElement?.clientHeight">
    <mat-accordion [multi]="true"
                   class="accordion cb-padding-bottom">
        <mat-expansion-panel class="panel"
                             *ngFor="let houseArea of availableHouseAreas | cbSortByImpurePipe: 'sortOrder' : 'asc'"
                             [id]="HOUSE_AREA_PIN_PREFIX + houseArea.id"
                             [(expanded)]="expandedPanels[houseArea.id]"
                             (expandedChange)="expandedChange()">
            <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                <mat-panel-title class="start-center flex-row">
                    <span class="mat-body-strong cb-margin-top">{{getHouseAreaDisplay(houseArea.id)}}</span>
                </mat-panel-title>
                <mat-panel-description class="panel-description end-center flex-row">
                    <button mat-icon-button
                            (click)="$event.stopPropagation()"
                            [matMenuTriggerFor]="optionMenu">
                        <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #optionMenu="matMenu">
                        <button mat-menu-item
                                [disabled]="lotSpec.isLocked"
                                (click)="openRemoveHouseAreaDialog(houseArea)">
                            <mat-icon>remove_circle</mat-icon>
                            <span>Remove House Area</span>
                        </button>
                    </mat-menu>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <cb-lot-spec-schedule-items-table *cbWaitFor="houseArea.id && allHouseAreaIdsWithItems"
                                              [houseAreaIdWithItems]="{ houseAreaId: houseArea.id, mappedItems: allHouseAreaIdsWithItems[houseArea.id] }"
                                              [isExpandCollapseAllClicked]="isExpandCollapseAllClicked"
                                              [lotId]="lotId"
                                              [isSkinnyView]="isSkinnyView">
            </cb-lot-spec-schedule-items-table>
        </mat-expansion-panel>
    </mat-accordion>
</cdk-virtual-scroll-viewport>
