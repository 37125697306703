import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ISearchResult } from '@app/core/services/user-cache/user-cache-areas';
import { USER_CACHE_AREA } from 'cb-hub-lib';
import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { CLIENT_ACCOUNT_STATUS_ENUM, IClientAccountDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ClientAccountLogicService } from '@app/logic/client-account/client-account.logic.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { PermissionsPermissions } from '@app/core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';

@Component({
    selector: 'cb-client-account-search',
    templateUrl: './client-account-search.component.html',
    styleUrls: ['./client-account-search.component.scss']
})
export class ClientAccountSearchComponent extends BaseSearchViewDirective<ISearchResult> implements AfterViewInit, OnDestroy {

    public CLIENT_ACCOUNT_STATUS_ENUM = CLIENT_ACCOUNT_STATUS_ENUM;
    public searchResults: IClientAccountDto[] = [];

    constructor(
        public readonly navigationService: NavigationService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly matDialog: MatDialog,
        public readonly clientAccountLogicService: ClientAccountLogicService,
        public readonly toastService: ToastService,
        public readonly userCacheService: UserCacheService,
    ) {
        super(
            clientAccountLogicService,
            toastService,
            userCacheService,
            USER_CACHE_AREA.AccountSearch
        );

    }

    public ngAfterViewInit(): void {
        this.loadSearchParams();
    }

    protected getSearchParams(): ISearchResult {
        const data = this.userCacheItem.copyData();
        return {
            query: data.query,
        };
    }

    public viewAccount(account: IClientAccountDto, event: MouseEvent): void {
        this.navigationService.navigate([`accounts/${account.id}/summary`], event);
    }

}
