import { Injectable } from '@angular/core';
import { FEATURE_TOGGLE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BaseFeatureToggleStatesService } from './base-feature-toggle-state.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureToggleStatesService extends BaseFeatureToggleStatesService {

    public get isLotSpecsUseScheduleItemsEnabled(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.LotSpecsUseScheduleItems);
    }

    public get isScheduleItemSpecTemplatesEnabled(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.ScheduleItemSpecTemplates);
    }

    public get isWorkingDrawingRejectionsEnabled(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.WorkingDrawingRequestRejects);
    }

    public get isJobBuildReportGenerationEnabled(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.JobBuildReportGeneration);
    }

    public get isClientSaleSummarExtendedReportEnabled(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.SalesDashboardV2);
    }

    public get isPreConsentPlanViewEnabled(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.PreConsentPlanView);
    }

    public get isProductExceptionRateSearchEnabled(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.ProductRateExceptionReport);
    }

    public get isTakeOffBudgetImportEnabled(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.TakeOffIncludesBudgetAmounts);
    }

    public get isPricingForcedToUseLatestSpecVersion(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.AlwaysRequestPricingUsingLatestSpecVersion);
    }

    public get isSupplierTypesEnabled(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.SupplierTypes);
    }

    public get isAiRateSystemEnabled(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.AiRateExplanationSystem);
    }

    public get isAutoSplitBuildActivitiesEnabled(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.AutoSplitBuildProgrammeActivities);
    }

    public get isProductCentreEnabled(): boolean {
        return this.getState(FEATURE_TOGGLE_ENUM.ProductCentre);
    }
}
