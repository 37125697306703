<mat-card class="flex-col"
          [ngClass]="{ 'cb-current-lot-council-rfi': rfiNo === councilRfiDocumentDto.id }">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon"
             [matTooltip]="status.value.title">
            <mat-icon [ngClass]="status.value.class">{{ status.value.icon }}</mat-icon>
        </div>
        <mat-card-title>RFI #{{ councilRfiDocumentDto.id }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex-col cb-margin-bottom">
        <div class="flex-row">
            <cb-display-value-new class="flex"
                                  label="Received from"
                                  [value]="councilRfiDocumentDto?.receivedFrom"></cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Working Drawing Revision"
                                  [value]="councilRfiDocumentDto?.workingDrawingNumber"></cb-display-value-new>
        </div>
        <cb-display-value-new label="External Reference"
                              [value]="councilRfiDocumentDto?.externalReference"></cb-display-value-new>
        <div class="flex-row">
            <cb-display-value-new class="flex"
                                  label="RFI Date"
                                  [value]="councilRfiDocumentDto?.rfiDate| date"></cb-display-value-new>
            <cb-display-value-new class="flex"
                                  label="Due Date"
                                  [value]="councilRfiDocumentDto?.rfiDueDate | date"></cb-display-value-new>
        </div>
        <cb-display-value-new label="Assigned To">
            {{ councilRfiDocumentDto?.assignedToTeamName }} >
            {{ councilRfiDocumentDto?.assignedToUserName }}
        </cb-display-value-new>
    </mat-card-content>
    <mat-divider class="card-divider"></mat-divider>
    <mat-card-actions>
        <div class="flex flex-row">
            <button mat-flat-button
                    (click)="downloadLatestRfiDocument()">
                <mat-icon>file_download</mat-icon>
                &nbsp;Download
            </button>
            <span class="flex"></span>
            <button mat-stroked-button
                    (click)="view()"
                    color="accent">View
            </button>
        </div>
    </mat-card-actions>
</mat-card>