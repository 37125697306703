import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CbDialogService } from './cb-dialog.service';
import { BlockingDialogComponent } from './blocking-dialog/blocking-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SimpleMessageDialogComponent } from './simple-message-dialog/simple-message-dialog.component';
import { FormsModule } from '@angular/forms';
import { CbDialogContainerModule } from './dialog-container/dialog-container.module';
import { CbSimpleFormDialogModule } from './simple-form-dialog/simple-form-dialog.module';
import { CbLoadingSpinnerModule } from '@classichub/cb-hub-lib';

const COMPONENTS = [
    BlockingDialogComponent,
    ConfirmDialogComponent,
    SimpleMessageDialogComponent,
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        CbLoadingSpinnerModule,
        FormsModule,
        CbDialogContainerModule,
    ],
    providers: [
        CbDialogService,
    ],
    exports: [
        ...COMPONENTS,
        CbDialogContainerModule
    ]
})

export class CbDialogModule { }
