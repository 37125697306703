<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <mat-card-title>{{getDisplayName()}}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex">
        <ng-container>
        </ng-container>
    </mat-card-content>
    <mat-card-actions class="end-center flex-row">
        <button mat-stroked-button
                color="accent"
                (click)="selectionChosen(dto)">
            Add Selling Agent
        </button>
    </mat-card-actions>
</mat-card>