<div class="flex-row">
    <cb-side-panel
        (viewSelected)="onViewSelected($event)"
        (modeChanged)="onModeChanged($event)"
        [favoriteViews] = favoriteViews
        [isEditMode] = isEditMode
        [selectedId]="selectedView?.id ?? ''"
        (addNewClicked)="onAddNewClicked()"
        (deleteView)="onDeleteView($event)"
    >
    </cb-side-panel>
    <div class="table-container" *ngIf="!isEditMode">
        <cb-wip-table
            [selectedView]="selectedView"
            [buildingConsultants]="buildingConsultants"
            [businessEntitiesDict]="businessEntitiesDict"
            [projects]="projects"
            [districts]="districts"
            [contractTypes]="contractTypes"
        >
        </cb-wip-table>
    </div>
    <cb-wip-favorite-views-page
        *ngIf="isEditMode"
        class="flex"
        [selectedViewInput]="selectedView"
        [favoriteViews] = favoriteViews
        (viewCreated)="onViewCreated($event)"
        (viewUpdated)="onViewUpdated($event)"
        (viewSelected)="onViewSelected($event)"
        (saveClicked)="onSaveClicked($event)"
        (backToViewClicked)="backToViewClicked($event)"
    >
    </cb-wip-favorite-views-page>
</div>
