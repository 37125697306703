import {AfterContentChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild,} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {ITableColumn} from "../../../../interfaces/ITableColumn";

@Component({
    selector: 'cb-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterContentChecked {

    public static readonly NO_STYLE = 'none';
    /** @deprecated use [infiniteScrollEnabled] instead */
    @ViewChild('infiniteScrollContainer', {static: false}) public infiniteScrollContainer: ElementRef;

    /** [cbInfiniteScroll] - the observable that emits and handles the search results */
    @Input() public fetch: Observable<any>;
    /** [cbInfiniteScroll] - when this Observable emits, a fetch will be triggered */
    @Input() public queryUpdated: Observable<any>;
    /** [cbInfiniteScroll] - infinite scroll will commence once this is set to true */
    @Input() public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    /** [cbInfiniteScroll] - currentPage of query, two-way binding */
    @Input() public currentPage: number;
    @Output() public readonly currentPageChange = new EventEmitter<number>();
    /** [cbInfiniteScroll] - search results, two-way binding */
    @Input() public results: any[];
    @Output() public readonly resultsChange = new EventEmitter<any[]>();

    @Input() public hideNoResultsMessage = false;
    @Input() public fetchInProgress = false;
    @Output() public readonly fetchInProgressChange = new EventEmitter<boolean>();

    /**
     * Accepts an array of ITableColumn, each defining a table column with customizable properties
     * such as `label`, `textAlign`, `width`, `minWidth`, and `maxWidth`.
     * Use this input when more complex column configurations are needed.
     */
    @Input() public objectColumns: Array<ITableColumn> | null = null;

    /**
     * Accepts an array of strings, each representing a simple table column label.
     * Use this input when only text labels are needed for each column, without additional styling.
     */
    @Input() public stringColumns: Array<string> | null = null;

    @Input() public label: string;
    @Input() public emptyMessage: string | null;
    @Input() public hideDefaultTable = false;
    @Input() public tableLabel: string;

    private _loaded = false;
    @Input()
    public set loaded(v: boolean) {
        this.zone.run(() => {
            this._loaded = !!v;
        });
    }

    public get isStringTypeColumn(): boolean {
        return this.stringColumns != null;
    }

    public get loaded(): boolean {
        return this._loaded;
    }

    private maxHeightStyle = TableComponent.NO_STYLE;

    @Input()
    public set maxHeight(v: number | string) {
        this.zone.run(() => {
            if (Number(v) > 0) {
                this.maxHeightStyle = `${v}px`;
            } else {
                this.maxHeightStyle = TableComponent.NO_STYLE;
            }
        });
    }

    private maxHeightOffsetStyle = TableComponent.NO_STYLE;

    @Input()
    public set maxHeightOffset(v: number | string) {
        this.zone.run(() => {
            if (Number(v) > 0) {
                this.maxHeightOffsetStyle = `calc(100vh - ${v}px)`;
            } else {
                this.maxHeightOffsetStyle = TableComponent.NO_STYLE;
            }
        });
    }

    public get getMaxHeight(): string {
        return this.maxHeightOffsetStyle !== TableComponent.NO_STYLE ? this.maxHeightOffsetStyle : this.maxHeightStyle;
    }

    public minWidthStyle = TableComponent.NO_STYLE;

    @Input()
    public set minWidth(v: number | string) {
        this.zone.run(() => {
            if (Number(v) > 0) {
                this.minWidthStyle = `${v}px`;
            } else {
                this.minWidthStyle = TableComponent.NO_STYLE;
            }
        });
    }

    public get headContentEmpty(): boolean {
        return this.tableHeadRef == null || this.tableHeadRef?.children?.length < 1;
    }

    public get bodyContentEmpty(): boolean {
        return this.tableBodyRef == null || this.tableBodyRef?.children?.length < 1;
    }

    private tableHeadRef: HTMLElement | null;
    private tableBodyRef: HTMLElement | null;

    @ViewChild('cbTableRef')
    public set cbTableRef(v: ElementRef<HTMLTableElement> | HTMLTableElement) {
        const tableRef = ((v as ElementRef<HTMLTableElement>)?.nativeElement ?? v as HTMLTableElement);
        this.tableHeadRef = tableRef?.querySelector('[cbTableHead]');
        this.tableBodyRef = tableRef?.querySelector('[cbTableBody]');
    }

    constructor(private readonly cdref: ChangeDetectorRef, private readonly zone: NgZone) {
    }

    ngOnInit(): void {
        // Validate that only one input is set at a time: either `objectColumns` or `stringColumns`
        if (this.objectColumns && this.stringColumns) {
            throw new Error("You can only provide either 'objectColumns' or 'stringColumns', not both.");
        }
    }

    public ngAfterContentChecked(): void {
        this.zone.run(() => {
            this.cdref.detectChanges();
        });
    }

    public displayNoResultsMessage(tableBodyRow: ElementRef<HTMLTableSectionElement> | HTMLTableSectionElement): boolean {
        tableBodyRow = (tableBodyRow as ElementRef<HTMLTableSectionElement>)?.nativeElement ?? tableBodyRow as HTMLTableSectionElement;
        return this.loaded && this.bodyContentEmpty && tableBodyRow?.children?.length < 1 && !this.fetchInProgress && !this.hideNoResultsMessage;
    }
}
