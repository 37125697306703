<mat-table matSort
           class="cb-table w-100"
           [dataSource]="sortedTemplates"
           (matSortChange)="sortTemplates()">
    <ng-container matColumnDef="templateName">
        <mat-header-cell mat-sort-header
                         *matHeaderCellDef>
            Template Name
            <cb-sortable-header-cell [sort]="sort"
                                     [cell]="columnsEnum.TemplateName"></cb-sortable-header-cell>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="visibility">
        <mat-header-cell mat-sort-header
                         *matHeaderCellDef>
            Visibility
            <cb-sortable-header-cell [sort]="sort"
                                     [cell]="columnsEnum.Visibility"></cb-sortable-header-cell>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{getVisibility(element)}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="tierRange">
        <mat-header-cell mat-sort-header
                         *matHeaderCellDef>
            Tier
            <cb-sortable-header-cell [sort]="sort"
                                     [cell]="columnsEnum.TierRange"></cb-sortable-header-cell>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.tierRange | cbEnumToLabel: ProductTierRangeEnumId}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="createdBy">
        <mat-header-cell mat-sort-header
                         *matHeaderCellDef>
            Created By
            <cb-sortable-header-cell [sort]="sort"
                                     [cell]="columnsEnum.CreatedBy"></cb-sortable-header-cell>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.createdByName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="updatedBy">
        <mat-header-cell mat-sort-header
                         *matHeaderCellDef>
            Updated By
            <cb-sortable-header-cell [sort]="sort"
                                     [cell]="columnsEnum.UpdatedBy"></cb-sortable-header-cell>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.updatedByName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastUpdated">
        <mat-header-cell mat-sort-header
                         *matHeaderCellDef>
            Last Updated
            <cb-sortable-header-cell [sort]="sort"
                                     [cell]="columnsEnum.LastUpdated"></cb-sortable-header-cell>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.updatedDate | cbDate}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="isActive">
        <mat-header-cell *matHeaderCellDef>Is Active</mat-header-cell>
        <mat-cell *matCellDef="let element">
            <mat-icon class="cb-grey">
                {{element.isActive ? "check_box" : "check_box_outline_blank"}}
            </mat-icon>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
            <button class="cb-margin-right"
                    mat-icon-button
                    (click)="viewTemplate.emit(element)">
                <mat-icon>pageview</mat-icon>
            </button>
            <button class="cb-margin-right"
                    mat-icon-button
                    [disabled]="!canEdit(element)"
                    (click)="editTemplate.emit(element)">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button
                    [disabled]="!canDuplicate(element)"
                    (click)="duplicateTemplate.emit(element)">
                <mat-icon>library_add</mat-icon>
            </button>
        </mat-cell>
    </ng-container>
    <tr mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"></tr>
</mat-table>
