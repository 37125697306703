import {Component, Inject} from '@angular/core';
import {BaseDialogFormViewDirective} from "@app/shared/base-views/base-dialog-form-view.directive";
import {ToastService} from "@app/core/services/toast/toast.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IBuildMilestoneTemplateDto} from "@classictechsolutions/hubapi-transpiled-enums";
import {BuildMilestoneTemplateMappedItem, IBuildMilestoneTemplateLogicService, IBuildMilestoneTemplateMappedItem} from "@app/logic/build-milestone-templates";

interface IData {
    mappedItem: BuildMilestoneTemplateMappedItem
}

@Component({
  selector: 'cb-build-milestone-template-dialog',
  templateUrl: './build-milestone-template-dialog.component.html',
  styleUrls: ['./build-milestone-template-dialog.component.scss']
})
export class BuildMilestoneTemplateDialogComponent extends BaseDialogFormViewDirective<IBuildMilestoneTemplateDto, IBuildMilestoneTemplateMappedItem, IBuildMilestoneTemplateLogicService> {
    public get mappedItem(): BuildMilestoneTemplateMappedItem {
        return this.data.mappedItem;
    }

    constructor(
        public readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<BuildMilestoneTemplateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastService);
    }
}
