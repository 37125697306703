import {CLIENT_SALE_STATUS_ENUM, IClientSaleLostDto, JOB_LOST_REASON_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {ClientSalePermissions} from '@app/core/permissions';
import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {ToastService} from '@app/core/services/toast/toast.service';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {IClientSaleMappedItem} from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

interface IData {
    clientSale: IClientSaleMappedItem;
}

@Component({
    selector: 'cb-lost-or-stalled-dialog',
    templateUrl: './lost-or-stalled-dialog.component.html',
    styleUrls: ['./lost-or-stalled-dialog.component.scss'],
    providers: [
        ClientSalePermissions
    ]
})
export class LostOrStalledDialogComponent extends BaseDialogFormViewDirective<IClientSaleLostDto, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '400px';

    public readonly jobLostReasonLookup = JOB_LOST_REASON_ENUM.toLookup();
    public CLIENT_SALE_STATUS_ENUM = CLIENT_SALE_STATUS_ENUM;

    public confirmLost = false;
    public saleLostDto: IClientSaleLostDto;
    public isPendingLost = false;

    constructor(
        public readonly dialogRef: MatDialogRef<LostOrStalledDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        public readonly permissions: ClientSalePermissions,
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.saleLostDto = {
            jobLostReason: this.data.clientSale.jobLostReason,
            jobLostComments: this.data.clientSale.jobLostComments
        } as IClientSaleLostDto;

        this.isPendingLost = this.data.clientSale.statusId === CLIENT_SALE_STATUS_ENUM.StalledSale;
        this.confirmLost = this.permissions.canConfirmSaleLost() && this.isPendingLost;
    }

    public save(): void {
        if (this.confirmLost && this.permissions.canConfirmSaleLost()) {
            this.cbDialog.confirm({
                message: 'Are you sure you want to confirm this Client Sale as Lost? This cannot be undone.',
                confirmed: () => this.data.clientSale.confirmSaleLost(this.saleLostDto)
                    .subOnce({
                        next: this.handleNext,
                        error: this.handleError
                    })
            });
        } else {
            this.cbDialog.confirm({
                message: 'Are you sure you want to set this Client Sale as Stalled?',
                confirmed: () => this.data.clientSale.setSalePendingLost(this.saleLostDto)
                    .subOnce({
                        next: this.handleNext,
                        error: this.handleError
                    })
            });
        }
    }
}
