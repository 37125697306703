import { Component, OnInit, Inject } from '@angular/core';
import { BUILD_STAGE_EVENT_ENUM, PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { FormMode } from '@app/shared/enums/form';
import { BuildStagesLogicService, IBuildStageDto } from '@app/logic/build-stages';
import { IPaymentTemplateStageDto } from '@app/logic/payment-templates/interface/i.payment-template.dto';
import { PaymentTemplatesLogicService } from '@app/logic/payment-templates/payment-templates.logic.service';
import { cloneDeep } from 'lodash';

enum TriggerType {
    BuildStage = 1,
    Event = 2
}

@Component({
    templateUrl: './payment-template-stage-dialog.component.html',
    styleUrls: ['./payment-template-stage-dialog.component.scss'],
    providers: [
        BuildStagesLogicService,
        PaymentTemplatesLogicService,
    ]
})
export class PaymentTemplateStageDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '45%';

    public triggerType: TriggerType = TriggerType.BuildStage;
    public readonly TRIGGER_TYPE = TriggerType;
    public readonly PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM = PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM;
    public paymentTemplateStage: IPaymentTemplateStageDto;
    public buildStages: IBuildStageDto[];
    public buildStageId: number;
    public readonly buildStageEvents = BUILD_STAGE_EVENT_ENUM.toLookup();

    constructor(
        public readonly dialogRef: MatDialogRef<PaymentTemplateStageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            existingStages: IBuildStageDto[];
            paymentTemplateStage: IPaymentTemplateStageDto;
        },
        public readonly toastService: ToastService,
        private readonly buildStageLogic: BuildStagesLogicService,
        private readonly paymentTemplatesLogic: PaymentTemplatesLogicService,
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.formMode = FormMode.Edit;

        this.buildStageLogic.getAllNonPreConsentStageBuildStages().subOnce((buildStages) => {
            this.buildStages = buildStages.filter(stage => this.data.existingStages?.findIndex(x => x.id === stage.id) < 0);
        });

        this.paymentTemplateStage = cloneDeep(this.data?.paymentTemplateStage ?? this.createStage());
        this.paymentTemplateStage.paymentAmount = this.paymentTemplateStage.paymentAmount || 0;

        if (this.paymentTemplateStage.buildStage != null && this.paymentTemplateStage.buildStage.id) {
            this.buildStageId = this.paymentTemplateStage.buildStage.id;
        }

        if (this.paymentTemplateStage.trigger) {
            this.triggerType = TriggerType.Event;
        }
    }

    public save(): void {
        this.paymentTemplateStage.buildStage = undefined;
        if (this.buildStageId) {
            this.paymentTemplateStage.buildStage = this.buildStages.find(x => x.id === this.buildStageId);
        }
        this.dialogRef.close(this.paymentTemplateStage);
    }

    public clearDropdowns(): void {
        this.paymentTemplateStage.trigger = undefined;
        this.buildStageId = undefined;
    }

    private createStage(): IPaymentTemplateStageDto {
        this.formMode = FormMode.Add;
        return { amountType: PAYMENT_TEMPLATE_AMOUNT_TYPE_ENUM.Percentage, buildStage: {} } as IPaymentTemplateStageDto;
    }
}
