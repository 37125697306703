import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { AddEditStandardPlanCostDialogComponent } from '../add-edit-standard-plan-cost-dialog/add-edit-standard-plan-cost-dialog';
import { FormMode } from './../../../shared/enums/form';
import { IStandardPlanCostDto } from '@app/logic/standard-plans/interfaces/i.standard-plan-cost.dto';
import { IStandardPlanCostMappedItem } from '@app/logic/standard-plans/interfaces/i.standard-plan-cost.mapped';
import { IStandardPlanMappedItem } from '@app/logic/standard-plans/interfaces/i.standard-plan.mapped';
import { StandardPlanCostLogicService } from '@app/logic/standard-plans/standard-plan-cost.logic.service';
import { IAddressRegionDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-standard-plan-costs',
    templateUrl: './standard-plan-costs.component.html',
    styleUrls: ['./standard-plan-costs.component.scss']
})
export class StandardPlanCostsComponent implements OnInit {

    @Input() public plan: IStandardPlanMappedItem;
    @Input() public addressRegions: IAddressRegionDto[];
    @Input() public formMode: FormMode;

    @ViewChild(MatSort, { static: true }) public sort: MatSort;

    public dataSource = new MatTableDataSource<IStandardPlanCostDto>([]);
    public costsLoaded = false;

    public readonly displayedColumns: string[] = ['regionName', 'styleName', 'totalCost', 'm2Cost', 'isActive', 'actions'];

    public readonly readdisplayedColumns: string[] = ['regionName', 'styleName', 'totalCost', 'm2Cost', 'isActive'];

    public isEdit(): boolean {
        return this.formMode === FormMode.Edit;
    }

    constructor(
        public readonly dialog: MatDialog,
        public standardPlanCostLogicService: StandardPlanCostLogicService,
    ) {

    }

    public ngOnInit(): void {
        this.refreshDataSource();
    }

    public addCost(): void {
        const dialogRef = this.dialog
            .open(
                AddEditStandardPlanCostDialogComponent,
                {
                    data: {
                        cost: this.standardPlanCostLogicService.createMappedCostItem('standardplans', this.plan.id),
                        isEdit: false,
                        addressRegions: this.addressRegions,
                        styles: this.plan.stylesWithUrl,
                        floorArea: this.plan.floorArea
                    },
                    panelClass: 'cb-dialog-container',
                    minWidth: 400,
                    width: '400px',
                }
            );

        const subscription = dialogRef.componentInstance.onSave.subscribe((data) => {
            const planData = this.plan.$getMappedDtoItem();
            planData.planCosts.push(data);
            this.plan.$updateThisAndOriginal(planData);
            this.refreshDataSource();
        });

        dialogRef.afterClosed().subscribe(() => {
            subscription.unsubscribe();
        });

    }

    public editCost(cost: IStandardPlanCostMappedItem): void {
        const mappedItem = this.standardPlanCostLogicService.createMappedCostItem('standardplans', this.plan.id, cost);
        const dialogRef = this.dialog.open(AddEditStandardPlanCostDialogComponent,
            {
                data: {
                    cost: mappedItem,
                    isEdit: true,
                    addressRegions: this.addressRegions,
                    styles: this.plan.stylesWithUrl,
                    floorArea: this.plan.floorArea
                },
                panelClass: 'cb-dialog-container',
                minWidth: 400,
                width: '400px',
            }
        );


        const subscription = dialogRef.componentInstance.onSave.subscribe((data) => {
            const planData = this.plan.$getMappedDtoItem();
            planData.planCosts = planData.planCosts.map(p => p.id === data.id ? data : p);
            this.plan.$updateThisAndOriginal(planData);
            this.refreshDataSource();
        });

        dialogRef.afterClosed().subscribe(() => {
            subscription.unsubscribe();
        });

    }

    public refreshDataSource(): void {
        this.costsLoaded = false;
        this.dataSource.data = this.plan.planCosts;
        this.dataSource.sort = this.sort;
        this.costsLoaded = true;
    }

}
