import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

interface IDialogData {
    message?: string;
}

@Component({
    templateUrl: './dialog-loading-spinner.component.html',
    styleUrls: ['./dialog-loading-spinner.component.scss']
})
export class DialogLoadingSpinnerComponent {
    public readonly message: string = 'Please wait, loading...';

    constructor(
        public readonly dialogRef: MatDialogRef<DialogLoadingSpinnerComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IDialogData
    ) {
        if (data.message) {
            this.message = data.message;
        }
    }
}
