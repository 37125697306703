<cb-dialog>
    <form class="flex-col flex"
          #tagForm="ngForm">
        <h1 class="mat-headline-6">
            {{ mappedItem.id > 0 ? 'Edit' : 'New' }} Tag
        </h1>
        <cb-input type="text"
                  name="name"
                  label="Tag Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.name"></cb-input>
        <cb-select *ngIf="mappedItem.isSystemTag"
                   label="Key"
                   name="key"
                   [(ngModel)]="mappedItem.key"
                   [options]="userTags"
                   [required]=true
                   [disabled]="isSystemTag"></cb-select>
        <cb-checkbox name="isSystemTag"
                     label="Is System Tag"
                     [disabled]="isSystemTag"
                     [(ngModel)]="mappedItem.isSystemTag"></cb-checkbox>
        <cb-checkbox label="Is Active"
                     name="isActive"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
    </form>
    <div actions>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!tagForm.dirty || !tagForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
