<div class="cb-margin"
     *cbWaitFor="searchFiltersLoaded$ | async">
    <mat-card class="cb-margin-bottom flex-col">
        <mat-card-content>
            <form #form="ngForm">
                <div class="start-center flex-row flex">
                    <mat-icon>search</mat-icon>
                    <cb-input type="text"
                              label="Search"
                              [(ngModel)]="searchFilters.data.query"
                              (ngModelChange)="searchFiltersChanged$.next($event)"
                              name="search"
                              class="cb-margin-left cb-margin-right-2x flex">
                    </cb-input>
                    <cb-select label="Status"
                               name="statusId"
                               [(ngModel)]="searchFilters.data.statusId"
                               [options]="buildProgrammeStatusesOptions$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select label="Framing Manager"
                               name="framingManagerId"
                               [(ngModel)]="searchFilters.data.framingManagerId"
                               [options]="framingManagersOptions$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select label="Foundation Manager"
                               name="foundationManagerId"
                               [(ngModel)]="searchFilters.data.foundationManagerId"
                               [options]="foundationManagersOptions$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select label="Order"
                               name="resultOrders"
                               [(ngModel)]="searchFilters.data.order"
                               [options]="resultOrders$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select class="cb-margin-right"
                               label="Direction"
                               [(ngModel)]="searchFilters.data.direction"
                               name="direction"
                               [options]="resultDirections$ | async">
                    </cb-select>
                </div>
            </form>
            <cb-table class="cb-margin-top flex"
                      label="BuildProgrammes"
                      maxHeightOffset="280"
                      [infiniteScrollEnabled]="infiniteScrollEnabled"
                      [fetch]="fetchResults()"
                      [loaded]="loaded"
                      [queryUpdated]="searchFiltersChanged$"
                      [(results)]="results"
                      [(currentPage)]="currentPage">
                <thead cbTableHead>
                <tr>
                    <td>Job #</td>
                    <td>Region</td>
                    <td>Lot</td>
                    <td>Project</td>
                    <td>Release</td>
                    <td>Framing Manager</td>
                    <td>Foundation Manager</td>
                    <td>Status</td>
                    <td>Estimated End Date</td>
                    <td></td>
                </tr>
                </thead>
                <tbody cbTableBody>
                <tr *ngFor="let buildprogramme of results">
                    <cb-td-text [value]="buildprogramme.jobNumber"></cb-td-text>
                    <cb-td-text [value]="buildprogramme.region"></cb-td-text>
                    <cb-td-text [value]="buildprogramme.lotNumber"></cb-td-text>
                    <cb-td-text [value]="buildprogramme.projectName"></cb-td-text>
                    <cb-td-text [value]="buildprogramme.releaseName"></cb-td-text>
                    <cb-td-text [value]="buildprogramme.framingManager"></cb-td-text>
                    <cb-td-text [value]="buildprogramme.foundationManager"></cb-td-text>
                    <cb-td-text [value]="buildprogramme.statusDisplay"></cb-td-text>
                    <cb-td-date [value]="buildprogramme.estimatedEndDate"></cb-td-date>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <cb-button (clicked)="viewBuildProgramme(buildprogramme.lotId)"
                                   icon="pageview">
                        </cb-button>
                    </td>
                </tr>
                </tbody>
            </cb-table>
        </mat-card-content>
    </mat-card>
</div>
