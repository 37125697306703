<cb-dialog dialogHeading="Set Activity Supplier">
    <form class="flex-col flex"
          #supplierForm="ngForm">
        <span class="mat-body-strong">
            {{mappedItem.activity?.name}}
        </span>
        <div class="start-center flex-row">
            <cb-business-account-autocomplete-scroller class="cb-margin-right flex"
                                                       label="Supplier"
                                                       name="supplierSearch"
                                                       [(ngModel)]="selectedBusinessAccount"
                                                       [required]="true"
                                                       [requireMatch]="true"
                                                       [matchProps]="['id']"
                                                       (searchTextChanged)="businessAccountSet()"
                                                       (optionSelected)="businessAccountSet()"
                                                       [status]="statusQuery"
                                                       [searchType]="searchTypeQuery">
            </cb-business-account-autocomplete-scroller>
            <cb-business-accounts-input-find-cards label="Supplier"
                                                   #businessAccountInput
                                                   [(ngModel)]="selectedBusinessAccount"
                                                   name="selectedSupplier"
                                                   [buttonOnly]="true"
                                                   (change)="businessAccountSet()"
                                                   [searchParams]="businessAccountFindParams">
            </cb-business-accounts-input-find-cards>
        </div>
        <div class="start-center flex-row cb-margin-top">
            <cb-checkbox *ngIf="displayAssignToAllCheckbox"
                         [slim]="true"
                         [label]="labelCheckbox"
                         [(ngModel)]="reassignIncompleteActivities"
                         (change)="reassignIncompleteActivitiesCheckboxClicked($event)"
                         name="reassignIncompleteActivities"></cb-checkbox>
        </div>
        <div *ngIf="mustShowAffectActivityList()">
            <div *ngIf="foundAffectedActivities()">
                The following activities will also be updated to this supplier
                <cb-table class="cb-margin-bottom"
                          [stringColumns]="displayedColumns"
                          [minWidth]="350"
                          [maxHeightOffset]="186"
                          [loaded]="true">
                    <tbody cbTableBody>
                    <tr *ngFor="let activity of affectedActivitiesForSupplierChange">
                        <td *ngIf="activity.isBlockActivity">
                            <mat-icon class="cb-help-cursor"
                                      svgIcon='page-layout-header'
                                      ngClass='cb-grey'
                                      matTooltip='Block level Activity'></mat-icon>
                        </td>
                        <td *ngIf="!activity.isBlockActivity">{{activity.lotNumber > 0 ? 'Lot '+activity.lotNumber : '-'}}</td>
                        <td>{{activity.stage}}</td>
                        <td>{{activity.activityCode}}</td>
                        <td>{{activity.activity}}</td>
                        <td>{{activity.currentSupplier}}</td>
                    </tr>
                    </tbody>
                </cb-table>
            </div>
            <cb-info-message *ngIf="!foundAffectedActivities()"
                             message="No other activities will be affected"
                             icon="info_circle"
                             class="cb-margin"></cb-info-message>
        </div>
        <div class="start-center flex-row cb-margin-top">
            <cb-checkbox *ngIf="data.lotIsPrimaryUnit"
                         [slim]="true"
                         label="Apply this Supplier to same activity on the other units?"
                         [(ngModel)]="assignSameSupplierToSecondaryLot"
                         name="assignSameSupplierToSecondaryLot"></cb-checkbox>
        </div>
        <cb-select *ngIf="displayTradeTypesSelect"
                   label="Trade Type"
                   name="tradeType"
                   [(ngModel)]="selectedTradeType"
                   (change)="setLabel()"
                   [options]="tradeTypesInSupplierAndActivity"
                   [required]="true"></cb-select>
        <cb-select *ngIf="displaySupplyTypesSelect && featureToggleService.isSupplierTypesEnabled"
                   label="Supply Type"
                   name="supplyType"
                   [(ngModel)]="selectedSupplyType"
                   (change)="setLabel()"
                   [options]="supplyTypesInSupplierAndActivity"
                   [required]="true"></cb-select>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="saveInProgress || setSupplierDisabled(supplierForm)"
                (click)="setSupplier()">Confirm</button>
        <button mat-raised-button
                [disabled]="saveInProgress"
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
