import { Inject, Component, ViewChild, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { Dictionary } from 'lodash';
import {
    ChangeDirectionEnumId,
    CHANGE_DIRECTION_ENUM,
    COST_TYPE_ENUM,
    ICategoryDto,
    IChangeOptionLineDto,
    IIdAndNameDto,
    ISlotDto,
    ISpecGroupDto,
} from '@classictechsolutions/hubapi-transpiled-enums';
import { FormMode } from '@app/shared/enums/form';
import { IChangeOptionLineLogicService } from '@app/logic/change-option-line/interfaces/i.change-option-line.logic.service';
import { IChangeOptionLineMappedItem } from '@app/logic/change-option-line/interfaces/i.change-option-line.mapped';
import { SlotsLogicService } from '@app/logic/slots/slots.logic-service';
import { ProductLogicService } from '@app/logic/products/product.logic.service';
import { IProductSearchDto } from '@app/logic/products/i.product-search.dto';

interface IData {
    mappedItem: IChangeOptionLineMappedItem;
    houseAreas?: ISpecGroupDto[];
    lotId: number;
    formMode: FormMode;
    slotIdListByGroupId: Dictionary<number[]>;
    excludedSlotIds: number[];
}


@Component({
    selector: 'cb-change-option-line-dialog',
    templateUrl: './change-option-line-dialog.component.html',
    styleUrls: ['./change-option-line-dialog.component.scss']
})
export class ChangeOptionLineDialogComponent extends BaseDialogFormViewDirective<IChangeOptionLineDto, IChangeOptionLineMappedItem, IChangeOptionLineLogicService> {

    public static readonly MIN_WIDTH = '40%';
    public selectedSlot: ISlotDto;
    public mappedItem: IChangeOptionLineMappedItem;
    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public changeOptionLineCostTypes = COST_TYPE_ENUM.toSelectList().filter(x => x.id !== COST_TYPE_ENUM.None && x.id !== COST_TYPE_ENUM.Quote);
    public productSearchText: string;
    public readonly houseAreas: ISpecGroupDto[];
    public maximumQuantity: number;

    @ViewChild('itemForm') public itemForm: any;
    @ViewChildren('productAutocomplete') public productAutocomplete;

    constructor(
        public readonly dialogRef: MatDialogRef<ChangeOptionLineDialogComponent>,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(SlotsLogicService) public readonly slotsLogicService: SlotsLogicService,
        @Inject(ProductLogicService) public readonly productLogicService: ProductLogicService,
        private readonly cdRef: ChangeDetectorRef
    ) {
        super(dialogRef, toastService, cbDialog);
        this.mappedItem = this.data.mappedItem;
        this.mappedItem.lotId = this.data.lotId;
        this.houseAreas = this.data.houseAreas;
        this.formMode$.next(this.data.formMode);
    }

    public ngOnInit(): void {
        if (this.isAdd$$) {
            this.setAddingDefaults();
        }

        // Set the Current Lot Spec Item for Edit mode
        if (this.isEdit$$ && this.mappedItem.slotDto?.id > 0) {
            this.slotsLogicService.$getItem(this.mappedItem.slotDto.id).subOnce(item => {
                this.selectedSlot = item;
                this.cdRef.detectChanges();
            });
            this.setDescription();
            this.setCurrentLotSpecItem();
            this.setCategoryPath();
        }
    }

    public isActual(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Actual;
    }

    public isDescriptiveOnly(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.DescriptiveOnly;
    }

    public isEstimated(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Estimate;
    }

    public isProvisional(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Provisional;
    }

    public isNotDescriptive(): boolean {
        return this.mappedItem?.costType === COST_TYPE_ENUM.Actual ||
            this.mappedItem?.costType === COST_TYPE_ENUM.Provisional ||
            this.mappedItem?.costType === COST_TYPE_ENUM.Estimate ||
            this.mappedItem?.costType === COST_TYPE_ENUM.NoCharge ||
            this.mappedItem?.costType === COST_TYPE_ENUM.Quote ||
            this.mappedItem?.costType === COST_TYPE_ENUM.OwnersCare;
    }

    public isAddItem(): boolean {
        return this.mappedItem?.changeDirection === CHANGE_DIRECTION_ENUM.Add;
    }

    public isCreditItem(): boolean {
        return this.mappedItem?.changeDirection === CHANGE_DIRECTION_ENUM.Credit;
    }

    public isHouseAreaDisabled(): boolean {
        return this.isEdit$$ || this.isCreditItem();
    }

    public setCurrentLotSpecItem(): void {
        this.mappedItem.getExistingScheduleItem().subOnce(changeOptionLine => {
            if (changeOptionLine != null) {
                this.mappedItem.currentLotSpecItem = changeOptionLine?.itemDetails;
                this.setMaximumQuantity(changeOptionLine?.quantity, this.mappedItem?.changeDirection, changeOptionLine?.itemDetails);
            }
        });
    }

    public setCategoryPath(): void {
        this.slotsLogicService.getScheduleItemCategoryPath(this.mappedItem.slotDto?.id).subOnce(categoryPathDto => {
            this.mappedItem.category = { id: categoryPathDto.id, name: categoryPathDto.categoryPath } as IIdAndNameDto;
            this.mappedItem.slotCategory = categoryPathDto;
        });
    }

    public setDescription(): void {
        if (!this.isProductSelected() && this.mappedItem.itemDetails !== null) {
            const selectedProductDto = {
                id: null,
                name: this.mappedItem.itemDetails
            } as IIdAndNameDto;

            this.mappedItem.selectedProduct = selectedProductDto;
            this.productSearchText = this.mappedItem.itemDetails;

        }
    }

    public setAddingDefaults(): void {
        this.mappedItem.showInClientSpecification = true;
        this.mappedItem.showInColourYourDreams = true;
    }

    public dialogHeading(): string {
        const formMode = FormMode[this.formMode$$];
        const changeDirection = CHANGE_DIRECTION_ENUM[this.mappedItem.changeDirection];

        return `${formMode} Item to ${changeDirection}`;
    }

    public specGroupSelected(specGroupId: number): void {
        if (specGroupId > 0) {
            this.mappedItem.specGroupName = this.houseAreas.find(x => x.id === specGroupId)?.label;
            this.mappedItem.slotDto = null;
        }
    }

    public scheduleItemSelected(slot: ISlotDto): void {
        if (slot) {

            this.selectedSlot = slot;
            this.mappedItem.slotDto = slot;

            if (this.isAddItem()) {

                this.mappedItem.slotCategory = slot?.categories?.[0];

                if (this.mappedItem.slotCategory?.id > 0) {
                    this.mappedItem.category = {
                        id: this.mappedItem.slotCategory.id,
                        name: this.mappedItem.slotCategory.categoryPath
                    } as IIdAndNameDto;
                } else {
                    this.mappedItem.category = {} as ICategoryDto;
                    this.mappedItem.slotCategory = {} as ICategoryDto;
                }

                this.mappedItem.costType = slot?.costType;
                const defaultQuantity = 1;
                this.mappedItem.quantity = defaultQuantity;

                //  get existing item
                this.mappedItem.getExistingScheduleItem().subOnce(changeOptionLine => {
                    if (changeOptionLine !== null) {
                        this.setMaximumQuantity(changeOptionLine?.quantity, this.mappedItem?.changeDirection, changeOptionLine?.itemDetails);
                        this.mappedItem.currentLotSpecItem = changeOptionLine?.itemDetails;
                        this.mappedItem.itemToCredit = changeOptionLine;
                        this.mappedItem.costType = changeOptionLine.costType;
                    } else {
                        this.mappedItem.currentLotSpecItem = null;
                        this.mappedItem.itemToCredit = null;
                    }
                });
            }

            if (this.isCreditItem()) {
                this.mappedItem.getExistingScheduleItem().subOnce(changeOptionLine => {
                    if (changeOptionLine !== null) {

                        this.mappedItem.$updateThisAndOriginal(changeOptionLine);

                        if (changeOptionLine?.selectedProduct?.id > 0) {
                            this.mappedItem.selectedProduct = changeOptionLine?.selectedProduct;
                        } else {
                            const selectedProductDto = {
                                id: null,
                                name: changeOptionLine?.itemDetails
                            } as IIdAndNameDto;

                            this.mappedItem.selectedProduct = selectedProductDto;
                            this.productSearchText = changeOptionLine?.itemDetails;
                        }

                        this.setMaximumQuantity(changeOptionLine?.quantity, this.mappedItem?.changeDirection, changeOptionLine?.itemDetails);

                        /*
                        *  the New Product / Description won't reflect the changes until set the value manually
                        *  because the changes only triggers on KeyUp on the AutocompleteScrollerComponent
                        */
                        this.productAutocomplete?.first?.setValue(changeOptionLine.selectedProduct);

                        if (this.isProductSelected()) {
                            this.getCostAmount();
                        }

                    }
                });
            }

        }
    }

    public clearProductForCostType(): void {
        if (this.isDescriptiveOnly()) {
            this.mappedItem.selectedProduct = {} as IIdAndNameDto;
            this.productUpdate();
            this.productSearchTextChanged(null);
            this.updateQuantityAndRate();
        }

        else if (this.isActual() && !this.isProductSelected()) {
            this.mappedItem.selectedProduct = {} as IIdAndNameDto;
            this.productUpdate();
            this.productSearchTextChanged(null);
            this.updateQuantityAndRate();
        }
    }

    private updateQuantityAndRate(): void {
        this.mappedItem.quantity = 0;
        this.mappedItem.quantity = 0;
        this.mappedItem.costAmount = 0;
    }

    public productSearchTextChanged(str: string): void {
        if (str?.length > 249) {
            str = str?.substring(0, 249);
        }
        if (str != null && str !== undefined && str.length > 0) {
            this.productSearchText = str;
        }
    }

    public descriptionTextChanged(): void {
        this.mappedItem.selectedProduct = {} as IIdAndNameDto;
    }

    public productUpdate = (product?: IIdAndNameDto | null): void => {
        if (product != null && product !== undefined) {

            this.productSearchText = product.name;

            if (this.isProductSelected()) {
                this.mappedItem.itemDetails = null;

                // Get Rate and populate
                this.getRateAndPopulateRateAmount();

            }

        } else if (!this.mappedItem.selectedProduct) {
            if (!this.isDescriptiveOnly()) {
                this.productSearchText = null;
            }
        }
    };

    public productSelected = (product?: IProductSearchDto | null): void => {

        let selectedProductDto = {} as IIdAndNameDto;

        if (product?.id > 0) {
            selectedProductDto = {
                id: product.id,
                name: product.name
            } as IIdAndNameDto;

            this.mappedItem.selectedProduct = selectedProductDto;

            if (this.isProductSelected()) {
                this.mappedItem.itemDetails = null;

                this.getRateAndPopulateRateAmount();
            }

        }

    };

    public getRateAndPopulateRateAmount = (): void => {
        this.productLogicService.getRateForLot(this.mappedItem?.selectedProduct?.id, this.mappedItem?.lotId)
            .subOnce(rateDto => {
                this.mappedItem.rate = rateDto?.value || 0;

                // Recalculate Amount
                this.getCostAmount();

            });
    };

    private isProductSelected(): boolean {
        return (this.mappedItem.selectedProduct as IIdAndNameDto)?.id > 0;
    }

    private setMaximumQuantity(quantity: number, changeDirection: ChangeDirectionEnumId, itemDetails?: string): void {
        // If there is an item set then itemDetails will be set to the description of the item
        // In which case we want to enforce the quantity maximum.
        // If there is no item on the lot, then we do not need to set a maximum
        this.maximumQuantity = (changeDirection && changeDirection === ChangeDirectionEnumId.Credit && itemDetails) ? quantity : -1;
    }

    public updateQuantity(): void {
        this.getCostAmount();
        if (this.isCreditItem()) {
            if (this.maximumQuantity === -1 || this.mappedItem.quantity <= this.maximumQuantity) {
                this.itemForm?.form?.controls?.quantity.setErrors(null);
            } else {
                this.itemForm?.form?.controls?.quantity.setErrors({ invalid: true });
            }
        }
    }

    public getCostAmount(): void {
        this.mappedItem.costAmount = this.mappedItem.quantity * this.mappedItem.rate;
    }

    public isAddDisabled(): boolean {
        return this.itemForm.invalid
            || this.itemForm.pristine
            || (this.isActual() && this.isAddItem() && !this.isProductSelected());
    }


    public addOrUpdateItem(): void {
        if (this.isAddDisabled()) {
            return;
        }

        if (!this.isProductSelected()) {
            this.mappedItem.selectedProduct = {} as IIdAndNameDto;
        }

        if (!this.isDescriptiveOnly() && !this.isProductSelected()) {
            this.mappedItem.itemDetails = this.productSearchText;
        }
        this.dialogRef.close({ changeOptionLineDto: this.mappedItem.$getMappedDtoItem(), itemToCredit: this.mappedItem.itemToCredit });

    }


}
