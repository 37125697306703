import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SelectListItem } from '@app/core/services/enum/enum.service';
import { IPropertySearch } from '@app/core/services/user-cache/user-cache-areas';
import { LocationLogicService } from '@app/logic/location';
import {
    ILocationDto,
    LotFieldToSearchEnumId,
    LotTypeEnumId,
    LOT_FIELD_TO_SEARCH_ENUM,
    LOT_JOB_STATUS_ENUM,
    LOT_TYPE_ENUM,
    LOT_CONTRACT_TYPE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { ISelectListItem } from '@classictechsolutions/typescriptenums';
import _ from 'lodash';
import { debounceTime, Subject } from 'rxjs';

interface IFilterChip {
    id: string;
    text: string;
}

@Component({
    selector: 'cb-lot-search-filter',
    templateUrl: './lot-search-filter.component.html',
    styleUrls: ['./lot-search-filter.component.scss']
})
export class LotSearchFilterComponent implements OnInit {

    private _searchData: IPropertySearch;
    @Input() public set searchData(searchData: IPropertySearch) {
        this._searchData = searchData;
        this.updateFilterChips();
    }

    public get searchData(): IPropertySearch {
        return this._searchData;
    }

    @Input() public floorAreas: number[];
    @Input() public fetchInProgress: boolean;

    public searchQueryUpdated$: Subject<void> = new Subject();

    public lotTypes: ISelectListItem<LotTypeEnumId>[];
    public lotSearchFields: ISelectListItem<LotFieldToSearchEnumId>[];
    public jobStatuses: SelectListItem[];
    public contractTypes: SelectListItem[];
    public regions: ILocationDto[];
    public filterChips: IFilterChip[];


    constructor(
        private readonly locationLogicService: LocationLogicService) {

        this.jobStatuses = _.orderBy(LOT_JOB_STATUS_ENUM.toSelectList(), 'id');
        this.contractTypes = _.orderBy(LOT_CONTRACT_TYPE_ENUM.toSelectList(), 'label');
        this.lotTypes = LOT_TYPE_ENUM.toSelectList();
        this.lotSearchFields = _.orderBy(LOT_FIELD_TO_SEARCH_ENUM.toSelectList(), 'id');
        this.lotTypes.unshift({ id: undefined, label: 'Any' } as ISelectListItem<LotTypeEnumId>);

        const searchDebounceTime = 500;

        this.searchQueryUpdated$.pipe(
            debounceTime(searchDebounceTime))
            .subscribe((value) => {
                this.updateFilterChips();
            });
    }

    public ngOnInit(): void {
        this.locationLogicService.getLocationList(0).subOnce(result => {
            this.regions = result;
            this.regions.unshift({ id: undefined, name: 'Any' } as ILocationDto);
        });
    }

    public onEnter($event: any, searchQuery: string): void {
        $event.stopPropagation();
        $event.preventDefault();
        this.searchData.query = searchQuery;
        this.searchQueryUpdated$.next();
    }

    public selectedJobStatusesChanged(selectMultipleElement: UntypedFormControl): void {
        this.searchData.lotStatuses = selectMultipleElement.value;
        this.searchQueryUpdated$.next(selectMultipleElement.value);
    }

    public selectedContractTypeChanged(selectMultipleElement: UntypedFormControl): void {
        this.searchData.contractTypes = selectMultipleElement.value;
        this.searchQueryUpdated$.next(selectMultipleElement.value);
    }

    public removeFilterChip(chipId: string): void {
        _.remove(this.filterChips, { id: chipId });
        this.searchData[chipId] = null;
        this.searchQueryUpdated$.next();
    }

    private updateFilterChips(): void {
        this.filterChips = [];

        if (this.searchData.query !== '' && this.searchData.query !== undefined && this.searchData.query !== null) {
            this.filterChips.push({ id: 'query', text: 'Query - ' + this.searchData.query });
        }

        if (this.searchData.locationId) {
            const currentRegion = _.find(this.regions, { id: this.searchData.locationId });

            if (currentRegion) {
                this.filterChips.push({ id: 'locationId', text: 'Region - ' + currentRegion.name });
            }
        }

        if (this.searchData.floorAreaMin) {
            this.filterChips.push({ id: 'floorAreaMin', text: 'Min Floor Area - ' + this.searchData.floorAreaMin });
        }
        if (this.searchData.floorAreaMax) {
            this.filterChips.push({ id: 'floorAreaMax', text: 'Max Floor Area - ' + this.searchData.floorAreaMax });
        }

        if (this.searchData.numberOfBathrooms) {
            this.filterChips.push({ id: 'numberOfBathrooms', text: 'Bath Rooms - ' + this.searchData.numberOfBathrooms });
        }

        if (this.searchData.numberOfBedrooms) {
            this.filterChips.push({ id: 'numberOfBedrooms', text: 'Bed Rooms - ' + this.searchData.numberOfBedrooms });
        }

        if (this.searchData.numberOfLivingRooms) {
            this.filterChips.push({ id: 'numberOfLivingRooms', text: 'Living Rooms - ' + this.searchData.numberOfLivingRooms });
        }

        if (this.searchData.numberOfGarages) {
            this.filterChips.push({ id: 'numberOfGarages', text: 'Garages - ' + this.searchData.numberOfGarages });
        }

        if (this.searchData.lotType) {
            const lotType = _.find(this.lotTypes, { id: this.searchData.lotType });

            if (lotType !== undefined) {
                this.filterChips.push({ id: 'lotType', text: 'Lot Type - ' + lotType.label });
            }
        }

        if (this.searchData.lotStatuses) {
            const statusLabels = this.searchData.lotStatuses.map(status => LOT_JOB_STATUS_ENUM[status]).filter(x => !!x);
            if (statusLabels.length > 0) {
                this.filterChips.push({ id: 'lotStatuses', text: 'Lot Status - ' + statusLabels.join(', ') });
            }
        }

        if (this.searchData.contractTypes) {
            const contractLabels = this.searchData.contractTypes.map(contractType => LOT_CONTRACT_TYPE_ENUM[contractType]).filter(x => !!x);
            if (contractLabels.length > 0) {
                this.filterChips.push({ id: 'contractTypes', text: 'Contract Type - ' + contractLabels.join(', ') });
            }
        }

        if (this.searchData.isSpecBuild) {
            this.filterChips.push({ id: 'isSpecBuild', text: 'Spec Build' });
        }

        if (this.searchData.isShowHome) {
            this.filterChips.push({ id: 'isShowHome', text: 'Show Home' });
        }


        if (this.searchData.myLotsOnly) {
            this.filterChips.push({ id: 'myLotsOnly', text: 'Only My Lots' });
        }

        if (this.searchData.lotFieldToSearch) {
            const filterBy = _.find(this.lotSearchFields, { id: this.searchData.lotFieldToSearch });
            this.filterChips.push({ id: 'lotFieldToSearch', text: 'Filter by ' + filterBy.label });
        }
    }
}
