<cb-dialog dialogHeading="Complete Working Drawing"
           *cbWaitFor="mappedItem">
    <form #completeWorkingDrawingForm="ngForm">
        <div class="cb-margin flex-row">
            <div class="flex-col flex-40">
                <span class="cb-title">{{mappedItem.name}}</span>
                <cb-display-value-new label="Status"
                                      [value]="mappedItem.statusLabel"></cb-display-value-new>
                <div class="flex-col">
                    <div class="flex-row flex">
                        <cb-display-value-new class="flex-50"
                                              label="Land Area">
                            <div class="start-center">
                                <mat-icon class="cb-grey">border_style</mat-icon>
                                <span class="mat-body-2">&nbsp;{{mappedItem?.amenities?.landArea | cbDasher}}m²</span>
                            </div>
                        </cb-display-value-new>
                        <cb-display-value-new class="flex-50"
                                              label="Floor Area">
                            <div class="start-center">
                                <mat-icon svgIcon="floor-plan"></mat-icon>
                                <span class="mat-body-2">&nbsp;{{mappedItem?.amenities?.floorArea | cbDasher}}m²</span>
                            </div>
                        </cb-display-value-new>
                    </div>
                    <div class="flex-row flex">
                        <cb-display-value-new class="flex-50"
                                              label="Bedroom">
                            <div class="start-center">
                                <mat-icon>hotel</mat-icon>
                                <span class="mat-body-2">&nbsp;{{mappedItem?.amenities?.noBedrooms | cbDasher}}</span>
                            </div>
                        </cb-display-value-new>
                        <cb-display-value-new class="flex-50"
                                              label="Bathroom">
                            <div class="start-center">
                                <mat-icon>wc</mat-icon>
                                <span class="mat-body-2">&nbsp;{{mappedItem?.amenities?.noBathrooms | cbDasher}}</span>
                            </div>
                        </cb-display-value-new>
                    </div>
                    <div class="flex-row flex">
                        <cb-display-value-new class="flex-50"
                                              label="Living">
                            <div class="start-center">
                                <mat-icon>weekend</mat-icon>
                                <span class="mat-body-2">&nbsp;{{mappedItem?.amenities?.noLivingRooms |
                                    cbDasher}}</span>
                            </div>
                        </cb-display-value-new>
                        <cb-display-value-new class="flex-50"
                                              label="Garage">
                            <div class="start-center">
                                <mat-icon>directions_car</mat-icon>
                                <span class="mat-body-2">&nbsp;{{mappedItem?.amenities?.noGarages | cbDasher}}</span>
                            </div>
                        </cb-display-value-new>
                    </div>
                </div>
            </div>
            <div class="complete-working-dialog-right-panel flex-col flex-60">
                <div>
                    <span class="mat-body-strong">Items & Changes</span>
                    <cb-working-drawing-items *cbWaitFor="mappedItem"
                                              [mappedItem]="mappedItem"
                                              [showHeaderAndAddButton]="false"
                                              [showDeleteButton]="false"
                                              [addAndSave]="false"
                                              [isReadOnly]="true">
                    </cb-working-drawing-items>
                </div>
                <div style="margin-top:6px">
                    <span class="mat-body-strong">Working Drawing Documents for Review</span>
                    <cb-document *cbWaitFor="mappedItem && WORKING_DRAWING_DOC_GROUP"
                                 [entity]="mappedItem"
                                 [hideDeletedToggle]="true"
                                 [edit]="false"
                                 [documentGroupCode]="WORKING_DRAWING_DOC_GROUP"
                                 [systemArea]="systemArea"
                                 style="margin: -8px;"></cb-document>
                </div>
            </div>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (cbClick)="acceptWorkingDrawing()"
                [clickLimit]="1">Accept Working Drawing</button>
        <button mat-raised-button
                color="warn"
                (cbClick)="rejectWorkingDrawing()"
                [clickLimit]="1">Reject Working Drawing</button>
        <button mat-raised-button
                (click)="cancel()">Close</button>
    </div>
</cb-dialog>
