import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { ContactsLogicService, IContactSearchDto } from '@app/logic/contacts';
import { CouncilLogicService } from '@app/logic/councils';
import { ICouncilDto } from '@app/logic/councils/interfaces/i.council.dto';
import { ICreateLeadDto, LeadLogicService } from '@app/logic/leads';
import { ILotSearchDto, LotsLogicService } from '@app/logic/lots';
import { IUserDto } from '@app/logic/users';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ILookupWithStringId } from '@app/shared/interfaces/i.lookup.dto';
import { CreateContactDialogComponent } from '@app/views/contacts/create-contact-dialog/create-contact-dialog.component';
import {
    CONTACT_STATUS_ENUM,
    ENQUIRY_ORIGIN_ENUM, IBuildTypeDto, IContactDto, ILocationDto, ILotDto, LotContractTypeEnumId, LOT_CONTRACT_TYPE_ENUM,
    LOT_TYPE_ENUM,
    USER_TAG_CONSTANTS_CONST
} from '@classictechsolutions/hubapi-transpiled-enums';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

export interface ICreateLeadDialogData {
    contact: IContactSearchDto | IContactDto;
    lotData: { id: number; displayText: string; contractType: LotContractTypeEnumId };
}

@Component({
    selector: 'cb-create-lead-dialog',
    templateUrl: './create-lead-dialog.component.html',
    styleUrls: ['./create-lead-dialog.component.scss']
})
export class CreateLeadDialogComponent implements OnDestroy {
    public static readonly MIN_WIDTH = '40%';

    public regionId$ = new BehaviorSubject<number>(undefined);
    public regionId: number;

    public createLeadDto = {
        lotContractType: LOT_CONTRACT_TYPE_ENUM.None,
        enquiryDate: new Date().toJSON(),
        designAndBuildDetails: {
            address: {}
        }
    } as ICreateLeadDto;

    public primaryContact = {} as ILookupWithStringId;
    public secondaryContact: ILookupWithStringId;
    public buildingConsultant = {} as IUserDto;
    public location = {} as ILocationDto;
    public hlLot = {} as ILotSearchDto;
    public designAndLandLot = {} as ILotSearchDto;
    public dbDetailsKnown = false;

    public readonly LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;
    public readonly ENQUIRY_ORIGIN_ENUM = ENQUIRY_ORIGIN_ENUM;
    public readonly enquiryOrigins = ENQUIRY_ORIGIN_ENUM.toLookup();
    public readonly BUILDING_CONSULTANT_TAG = [USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT];
    public showhomes = [] as ILotDto[];
    public councils = [] as ICouncilDto[];
    public buildTypes: IBuildTypeDto[];
    private readonly onDestroy = new Subject();

    constructor(
        public readonly dialogRef: MatDialogRef<CreateLeadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: ICreateLeadDialogData,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(ContactsLogicService) public readonly contactLogic: ContactsLogicService,
        @Inject(LeadLogicService) public readonly leadLogic: LeadLogicService,
        @Inject(LotsLogicService) public readonly lotLogic: LotsLogicService,
        @Inject(CurrentUserService) public readonly currentUser: CurrentUserService,
        @Inject(CouncilLogicService) public readonly councilLogic: CouncilLogicService,
        @Inject(LookupService) public readonly lookupService: LookupService,
    ) {
        this.loadInitData();
        this.lookupService.BUILD_TYPES.toLookup().$promise.then((result: any) => {
            this.buildTypes = (result as IBuildTypeDto[]).filter(x => x.lotType.indexOf(LOT_TYPE_ENUM.Standalone) > -1);
        });
        this.regionId$.pipe(takeUntil(this.onDestroy)).subscribe({
            next: regionId => {
                this.location = undefined;
                this.regionId = regionId;
            }
        });
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(null);
        this.onDestroy.complete();
    }

    private loadInitData = (): void => {
        const contactDto = (this.data.contact as IContactDto);
        if (this.data?.contact != null) {
            this.primaryContact = {
                id: this.data.contact.id,
                label: (this.data.contact as IContactSearchDto)?.name ?? `${contactDto.firstName} ${contactDto.lastName}`,
                isActive: contactDto.status === CONTACT_STATUS_ENUM.Active,
            } as ILookupWithStringId;
        }

        this.initLoadLotData();

        this.lotLogic.getShowhomes().subOnce((showhomes) => {
            showhomes.unshift({ id: 0, label: 'Other' } as any);
            this.showhomes = showhomes;
        });

        if (this.currentUser.hasBuildingConsultant()) {
            this.buildingConsultant = {
                id: this.currentUser.guid,
                label: this.currentUser.profile.name,
            } as IUserDto;
        }
    };

    private initLoadLotData(): void {
        if (this.data?.lotData?.contractType == null) {
            return;
        }

        if (this.data.lotData.contractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
            this.createLeadDto.lotContractType = LOT_CONTRACT_TYPE_ENUM.HouseAndLand;
            this.hlLot = {
                id: this.data.lotData.id,
                displayText: this.data.lotData.displayText,
            } as ILotSearchDto;
        } else if (this.data.lotData.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            this.createLeadDto.lotContractType = LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
            this.designAndLandLot = {
                id: this.data.lotData.id,
                displayText: this.data.lotData.displayText,
            } as ILotSearchDto;
        }
    }

    public createPrimaryContact(): void {
        this.createContact()
            .afterClosed()
            .subOnce((result: false | IContactDto | IContactSearchDto) => {
                if (result) {
                    this.primaryContact = this.mapContactLookup(result);
                }
            });
    }

    public createSecondaryContact(): void {
        this.createContact()
            .afterClosed()
            .subOnce((result: false | IContactDto | IContactSearchDto) => {
                if (result) {
                    this.secondaryContact = this.mapContactLookup(result);
                }
            });
    }

    private preCreateMapping(): void {
        if (this.createLeadDto.lotContractType !== LOT_CONTRACT_TYPE_ENUM.HouseAndLand) {
            this.createLeadDto.houseAndLandLotId = null;
        } else if (this.hlLot?.id) {
            this.createLeadDto.houseAndLandLotId = this.hlLot?.id;
        }

        if (this.createLeadDto.lotContractType !== LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            this.createLeadDto.designAndLandLotId = null;
        } else if (this.designAndLandLot?.id) {
            this.createLeadDto.designAndLandLotId = this.designAndLandLot?.id;
        }

        if (this.createLeadDto.lotContractType !== LOT_CONTRACT_TYPE_ENUM.DesignAndBuild || !this.dbDetailsKnown) {
            this.createLeadDto.designAndBuildDetails = null;
        } else {
            this.createLeadDto.designAndBuildDetails.locationId = this.location.id;
        }

        if (this.createLeadDto.enquiryOrigin !== ENQUIRY_ORIGIN_ENUM.Showhome) {
            this.createLeadDto.showHomeId = null;
            this.createLeadDto.otherShowHome = null;
        } else if (this.createLeadDto.showHomeId > 0) {
            this.createLeadDto.otherShowHome = null;
        } else {
            this.createLeadDto.showHomeId = null;
        }

        this.createLeadDto.primaryContactId = this.primaryContact.id;
        this.createLeadDto.secondaryContactId = this.secondaryContact?.id;
        this.createLeadDto.buildingConsultantId = this.buildingConsultant.id;
    }

    public submitCreate(): void {
        this.preCreateMapping();
        this.leadLogic.createlead(this.createLeadDto).subOnce((result) => {
            this.dialogRef.close(result);
        });
    }

    public cancel(formDirty = false): void {
        if (formDirty) {
            this.cbDialog.confirm({
                dialogHeading: 'Confirm Cancel',
                message: 'Are you sure you want to cancel? Your changes will be lost.',
                confirmed: () => this.dialogRef.close(false),
            });
        } else {
            this.dialogRef.close(false);
        }
    }

    public reloadBuidlingConsentCouncils(): void {
        if (!this.location?.id) {
            return;
        }
        this.councilLogic.getByLocation(this.location?.id).subOnce((results) => {
            this.councils = results.filter(x => x.isActive);
        });
    }

    private createContact(): MatDialogRef<CreateContactDialogComponent> {
        return this.cbDialog.open(CreateContactDialogComponent, {
            data: {},
            minWidth: '60%',
        });
    }

    private mapContactLookup(result: IContactDto | IContactSearchDto): ILookupWithStringId {
        return {
            id: result.id,
            label: (result as IContactSearchDto).name ? (result as IContactSearchDto).name : `${(result as IContactDto).firstName} ${(result as IContactDto).lastName ?? ''}`,
            isActive: result.status === CONTACT_STATUS_ENUM.Active,
        } as ILookupWithStringId;
    }
}
