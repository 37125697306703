import { Component, OnInit } from '@angular/core';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ProductCentreFilterLogicService } from '@app/logic/product-centre/product-centre-filter.logic.service';
import { ProductCentreFilterMappedItem } from '@app/logic/product-centre/product-centre-filter.mapped';
import { ProductCentreFilterDialogComponent } from '@app/views/settings/pages/product-centre-configuration/product-centre-filter-dialog/product-centre-filter-dialog.component';
import { IProductCentreFilterDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';

@Component({
    selector: 'cb-product-centre-configuration',
    templateUrl: './product-centre-configuration.component.html',
    styleUrls: ['./product-centre-configuration.component.scss']
})
export class ProductCentreConfigurationComponent extends BaseSimpleListViewDirective<IProductCentreFilterDto, ProductCentreFilterLogicService>
    implements OnInit{

    public readonly filterColumns = [
        'Filter Name',
        'Attributes Configured',
        'Is Active',
        ''
    ];

    public productCentreIsEnabled = false;

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        protected readonly productCentreFilterLogicService: ProductCentreFilterLogicService,
        public readonly featureToggleService: FeatureToggleStatesService,
    ) {
        super(
            cbDialog,
            productCentreFilterLogicService,
            ProductCentreFilterDialogComponent,
            'Product Centre Filter Configuration',
            ProductCentreFilterMappedItem,
            permissionsPermissions
        );
        this.productCentreIsEnabled = this.featureToggleService.isProductCentreEnabled;
    }

    public ngOnInit(): void{
        if (!this.productCentreIsEnabled){
            return;
        }
        this.otherData = this.searchResults;
    }

    public addNewFilter(): void {
        this.otherData = this.searchResults;
        this.newItemClicked();
    }

    public editFilter(dto: IProductCentreFilterDto): void {
        this.otherData = this.searchResults.filter(filter => filter.id !== dto.id);
        this.editItemClickedForDto(dto);
    };

    public deleteItem(filterConfiguration: IProductCentreFilterDto): void {
        this.deleteItemClicked(filterConfiguration,
            {
                heading: 'Filter Configuration',
                message: `Are you sure you want to delete all '${filterConfiguration.name}' product centre filters?`
            });
    }
}
