import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {ILotMappedItem} from '@app/logic/lots';
import {IWorkingDrawingMappedItem, WorkingDrawingsLogicService} from '@app/logic/working-drawings';
import {IWorkingDrawingsLogicService} from '@app/logic/working-drawings/interfaces/i.working-drawings-logic.service';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
import {IManageDialogData} from '@app/shared/interfaces/i.manage-dialog-data';
import {IWorkingDrawingDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {Observer} from 'rxjs';

@Component({
    selector: 'cb-request-working-drawing-dialog-component',
    templateUrl: './request-working-drawing-dialog-component.component.html',
    styleUrls: ['./request-working-drawing-dialog-component.component.scss']
})
// eslint-disable-next-line max-len
export class RequestWorkingDrawingDialogComponentComponent extends BaseDialogFormViewDirective<IWorkingDrawingDto, IWorkingDrawingMappedItem, IWorkingDrawingsLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '50%';
    public lotMappedItem: ILotMappedItem;
    public mode: FormMode;
    public selectedTabStep = 0;
    constructor(
        private readonly toastService: ToastService,
        private readonly logicService: WorkingDrawingsLogicService,
        public readonly dialogRef: MatDialogRef<RequestWorkingDrawingDialogComponentComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<IWorkingDrawingMappedItem> & { lotMappedItem: ILotMappedItem; mode: FormMode },
        public readonly cbDialog: CbDialogService
    ) {

        super(dialogRef, toastService, cbDialog);
        this.mappedItem = data.mappedItem;
        this.lotMappedItem = data.lotMappedItem;
        this.mode = data.mode;

    }

    public ngOnInit(): void {
        this.formMode$.next(FormMode.Add);
    }

    public next(): void {
        this.selectedTabStep++;
    }

    public back(): void {
        this.selectedTabStep--;
    }

    public canGoNext(): boolean {
        return this.selectedTabStep < 6;
    }

    public canGoBack(): boolean {
        return this.selectedTabStep > 0;
    }

    public isSaveDisabled(step1Form: UntypedFormGroup): boolean {
        return (step1Form.invalid || step1Form.pristine || !this.hasItems());
    }

    public canSeeSave(): boolean {
        return this.selectedTabStep === 0;
    }

    public requestWorkingDrawing(): void {
        const loadingDialog = this.cbDialog.block('Please wait... your request is being processed');
        const observer = {
            next: _ => {
                loadingDialog.close();
                this.toastService.saveSuccess();
                this.dialogRef.close(true);
            },
            error: error => {
                loadingDialog.close();
                this.toastService.saveError(error.message ?? 'Something went wrong...');
                this.dialogRef.close(false);
            }
        } as Observer<any>;
        this.mappedItem.lotId = this.lotMappedItem.id;
        this.mappedItem.request().subOnce(observer);
    }

    public isStepComplete(
        step1Form: UntypedFormGroup): boolean {
        switch (this.selectedTabStep) {
            case 0:
                return step1Form.valid && this.hasItems();
                break;
            // case 1:
            //     return step2Form.valid;
            //     break;
            default:
                return false;
        }
    }

    public hasItems(): boolean {
        return this.mappedItem?.items?.length > 0;
    }

}
