import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BusinessAccountLogicService } from '@app/logic/business-accounts';
import { IReleaseStageMappedItem } from '@app/logic/release-stages/interfaces/i.release-stage.mapped';
import { ReleaseStagesLogicService } from '@app/logic/release-stages/release-stages.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { FormMode } from '@app/shared/enums/form';
import {
    BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM,
    BUSINESS_ACCOUNT_STATUS_ENUM,
    IBusinessAccountContactAreaDto,
    IProjectReleaseStageDefaultSupplierDto,
    ISupplierProductDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';


export interface IDefaultInstallersDialogData {
    mappedItem: IReleaseStageMappedItem;
    item: IProjectReleaseStageDefaultSupplierDto;
}

@Component({
    selector: 'cb-release-default-installer-dialog',
    templateUrl: './release-default-installer-dialog.component.html',
    styleUrls: ['./release-default-installer-dialog.component.scss']
})
export class ReleaseDefaultInstallerDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '50%';
    public loaded = false;
    public mappedItem: IReleaseStageMappedItem;
    public item: IProjectReleaseStageDefaultSupplierDto;
    public availableTrades: any[];
    public availableContacts: any[];
    public selectedTradeTypeId: number;
    public selectedContactId: number;
    public selectedSupplier: any = {};
    public businessAccountSearchParams: { [param: string]: any };
    public invalidSupplier: boolean;

    constructor(
        public readonly dialogRef: MatDialogRef<ReleaseDefaultInstallerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IDefaultInstallersDialogData,
        private readonly releaseStagesLogicService: ReleaseStagesLogicService,
        private readonly toastService: ToastService,
        private readonly businessAccountLogicService: BusinessAccountLogicService,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.item = data.item;
    }

    public ngOnInit(): void {
        super.ngOnInit();
        if (!this.item) {
            this.formMode = FormMode.Add;
        }
        if (this.isEdit()) {
            this.selectedTradeTypeId = this.item.tradeType.id;
            this.selectedSupplier = { id: this.item.supplier.id, tradingName: this.item.supplier.label };
            this.loadInstallerContacts(this.selectedSupplier).then(() => {
                this.selectedContactId = this.item.contact.id;
            });
        } else {

            this.releaseStagesLogicService.getAvailableTrades(this.mappedItem.id).subOnce((trades) => {
                this.availableTrades = trades;
            });
        }
        this.setBusinessAccountSearchParams();
    }

    public saveMethod(): Observable<IProjectReleaseStageDefaultSupplierDto> {
        const payload = {
            contact: { id: this.selectedContactId, label: undefined },
            projectReleaseStageId: this.mappedItem.$id,
            supplier: { id: this.selectedSupplier.id, label: this.selectedSupplier.tradingName },
            tradeType: { id: this.selectedTradeTypeId, label: undefined }
        } as IProjectReleaseStageDefaultSupplierDto;


        return this.releaseStagesLogicService.updateDefaultInstaller(this.mappedItem.$id as number, payload);
    }

    public get modeString(): string {
        return this.isEdit() ? 'Edit' : 'Add';
    }

    public selectedTradeTypeChange(): void {
        this.resetForm();
        this.setBusinessAccountSearchParams();
    }

    public changeSupplier(): void {
        if (this.item !== undefined) {
            this.item.supplier = {
                id: this.selectedSupplier.id,
                label: this.selectedSupplier.tradingName
            };
        }
        this.loadInstallerContacts(this.selectedSupplier);
    }

    public isSaveDisabled(): boolean {
        return !(this.selectedTradeTypeId && this.selectedSupplier.id && !this.invalidSupplier);
    }

    private resetForm(): void {
        this.invalidSupplier = false;
        this.selectedSupplier = {};
        this.availableContacts = undefined;
        this.selectedContactId = undefined;
    }

    private loadInstallerContacts(supplier: ISupplierProductDto): Promise<void> {
        this.invalidSupplier = false;
        return new Promise((resolve, reject) => {
            this.businessAccountLogicService.getAccountContacts(supplier.id).subOnce((contacts) => {
                const installerContacts = contacts.filter(
                    (c, idx: number) => {
                        c.areas = c.areas.filter((a: IBusinessAccountContactAreaDto) => {
                            return a.tradeTypeId === this.selectedTradeTypeId
                                && (!this.mappedItem.projectLocation
                                    || a.location.region.id === this.mappedItem.projectLocation.region.id);
                        });
                        return c.areas &&
                            c.areas.length > 0;
                    });
                if (installerContacts.length > 0) {
                    installerContacts.forEach((con) => {
                        con.id = con.areas[0].id;
                    });
                    this.availableContacts = installerContacts;
                    // We just want to select any available contact as we no longer require
                    // users to select a contact when setting up a default installer (all suppliers in this list has already been
                    // filtered for the lot's location)
                    this.selectedContactId = installerContacts[0].id;
                } else {
                    this.invalidSupplier = true;
                    this.availableContacts = [];
                }
                resolve();
            });
        });
    }

    private setBusinessAccountSearchParams(): void {
        this.businessAccountSearchParams = {
            reg: this.mappedItem.projectLocation ? [this.mappedItem.projectLocation.region.id] : undefined,
            ro: false, // not rates only
            s: [BUSINESS_ACCOUNT_STATUS_ENUM.Active, BUSINESS_ACCOUNT_STATUS_ENUM.Overridden],
            t: BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM.InstallerOnly,
            tt: this.selectedTradeTypeId
        };
    }

}
