<cb-dialog dialogHeading="Review Design Scheme"
           *cbWaitFor="mappedItem">
    <form #form="ngForm">
        <div class="cb-margin flex-row">
            <div class="flex-col flex-40">
                <span class="cb-title">{{mappedItem.name}}</span>
                <cb-display-value-new label="Status"
                                      [value]="mappedItem.status"></cb-display-value-new>
                <cb-display-value-new label="Build Type"
                                      [value]="mappedItem.buildType"></cb-display-value-new>
                <div class="flex-col">
                    <div class="flex-row flex">
                        <cb-display-value-new class="flex-50"
                                              label="Land Area">
                            <div class="start-center">
                                <mat-icon class="cb-grey">border_style</mat-icon>
                                <span class="mat-body-2">&nbsp;{{mappedItem?.amenities?.landArea | cbDasher}}m²</span>
                            </div>
                        </cb-display-value-new>
                        <cb-display-value-new class="flex-50"
                                              label="Floor Area">
                            <div class="start-center">
                                <mat-icon svgIcon="floor-plan"></mat-icon>
                                <span class="mat-body-2">&nbsp;{{mappedItem?.amenities?.floorArea | cbDasher}}m²</span>
                            </div>
                        </cb-display-value-new>
                    </div>
                    <div class="flex-row flex">
                        <cb-display-value-new class="flex-50"
                                              label="Bedroom">
                            <div class="start-center">
                                <mat-icon>hotel</mat-icon>
                                <span class="mat-body-2">&nbsp;{{mappedItem?.amenities?.noBedrooms | cbDasher}}</span>
                            </div>
                        </cb-display-value-new>
                        <cb-display-value-new class="flex-50"
                                              label="Bathroom">
                            <div class="start-center">
                                <mat-icon>wc</mat-icon>
                                <span class="mat-body-2">&nbsp;{{mappedItem?.amenities?.noBathrooms | cbDasher}}</span>
                            </div>
                        </cb-display-value-new>
                    </div>
                    <div class="flex-row flex">
                        <cb-display-value-new class="flex-50"
                                              label="Living">
                            <div class="start-center">
                                <mat-icon>weekend</mat-icon>
                                <span class="mat-body-2">&nbsp;{{mappedItem?.amenities?.noLivingRooms |
                                    cbDasher}}</span>
                            </div>
                        </cb-display-value-new>
                        <cb-display-value-new class="flex-50"
                                              label="Garage">
                            <div class="start-center">
                                <mat-icon>directions_car</mat-icon>
                                <span class="mat-body-2">&nbsp;{{mappedItem?.amenities?.noGarages | cbDasher}}</span>
                            </div>
                        </cb-display-value-new>
                    </div>
                </div>
                <cb-checkbox label="Resource Consent Required"
                             name="consentRequired"
                             [(ngModel)]="mappedItem.resourceConsentRequired"
                             [readonly]="true"></cb-checkbox>
                <ng-container *ngIf="mappedItem.hasPricingRevisionInfo">
                    <cb-display-value-new label="Pricing Revision"
                                          [value]="mappedItem.pricingRevisionName"></cb-display-value-new>
                    <cb-display-value-new label="Build Price"
                                          [value]="mappedItem.pricingRevisionBuildPrice | cbCurrency">
                    </cb-display-value-new>
                </ng-container>
            </div>
            <div class="cb-complete-design-scheme-dialog-right-panel flex-col flex-60">
                <span class="mat-body-strong">Items & Changes</span>
                <div class="cb-margin flex-col"
                     style="border: 1px solid #f0f0f0; border-radius: 2px;">
                    <ng-container *ngIf="mappedItem.designRequirementItems?.length > 0">
                        <div class="flex-col"
                             *ngFor="let item of filterDeletedRequirements(mappedItem.designRequirementItems)">
                            <div class="cb-margin-top cb-margin-bottom start-start flex-row">
                                <span class="mat-body-strong cb-margin-left-2x">Item #{{item.itemId}}</span>
                                <div class="cb-margin-left-2x flex-col flex">
                                    <div style="white-space: pre-line"
                                         class="force-wrap flex-col flex">
                                        {{item.description}}
                                    </div>
                                    <div class="start-start flex-row"
                                         *ngIf="item.comments.length > 0">
                                        <div class="center-center flex-row"
                                             style="margin-top:18px;margin-right: 10px;">
                                            <mat-icon>message</mat-icon>
                                        </div>
                                        <cb-text-area name="{{item.itemId}}_requirementComments"
                                                      [(ngModel)]="item.comments"
                                                      [maxlength]="1024"
                                                      [readonly]="true"></cb-text-area>
                                    </div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="mappedItem.designSchemeLines?.length > 0">
                        <div class="flex-col"
                             *ngFor="let item of filterDeletedLines(mappedItem.designSchemeLines)">
                            <div class="cb-margin-top cb-margin-bottom start-start flex-row">
                                <span class="mat-body-strong cb-margin-left-2x">Change #{{item.changeNumber}}</span>
                                <div class="cb-margin-left-2x flex-col flex">
                                    <div style="white-space: pre-line"
                                         class="force-wrap flex-col flex">
                                        {{item.changeRecordDetails}}
                                    </div>
                                    <div class="start-start flex-row"
                                         *ngIf="item.comments.length > 0">
                                        <div class="center-center flex-row"
                                             style="margin-top:18px;margin-right: 10px;">
                                            <mat-icon>message</mat-icon>
                                        </div>
                                        <cb-text-area name="{{item.changeNumber}}_changeComments"
                                                      [(ngModel)]="item.comments"
                                                      [maxlength]="1024"
                                                      [readonly]="true"></cb-text-area>
                                    </div>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </div>
                    </ng-container>
                </div>
                <span class="mat-body-strong">Scheme Documents for Review</span>
                <cb-document *cbWaitFor="mappedItem && DESIGN_SCHEME_DOC_GROUP"
                             [entity]="mappedItem"
                             [permissions]="getDocumentPermissions()"
                             [hideDeletedToggle]="true"
                             [edit]="false"
                             [documentGroupCode]="DESIGN_SCHEME_DOC_GROUP"
                             [systemArea]="systemArea"
                             style="margin: -8px;"></cb-document>
            </div>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (cbClick)="acceptScheme()"
                [clickLimit]="1">Accept Scheme</button>
        <button mat-raised-button
                color="warn"
                (cbClick)="rejectScheme()"
                [clickLimit]="1">Reject Scheme</button>
        <button mat-raised-button
                (click)="cancel()">Close</button>
    </div>
</cb-dialog>
