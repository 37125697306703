<cb-dialog dialogHeading="Edit Lot Details">
    <form class="cb-margin"
          #lotDetailsForm="ngForm">
        <div class="flex-row layoutGap-30 flex">
            <div class="flex-col flex-50">
                <h3 class="mat-headline-6">
                    Build Details
                </h3>
                <cb-input name="jobNumber"
                          label="Job Number"
                          [(ngModel)]="mappedItem.jobNumber"
                          [readonly]="!jobNumberField.isEditable">
                </cb-input>
                <cb-select label="Build Programme Template"
                           name="template"
                           [(ngModel)]="mappedItem.buildProgrammeTemplateId"
                           [options]="buildProgrammeTemplates"
                           [readonly]="!canEditTemplate()"></cb-select>
                <cb-datepicker label="Estimated Start Date"
                               name="startDate"
                               class="cb-margin-right"
                               [(ngModel)]="mappedItem.jobStart.estimate"
                               [maxDate]="mappedItem.jobComplete.estimate"
                               [nonWorkingDays]="nonWorkingDays"
                               [pickerType]="'calendar'"
                               [readonly]="mappedItem.hasUnits || mappedItem.hasBuildProgrammeTemplateApplied">
                </cb-datepicker>
                <cb-datepicker label="Estimated End Date"
                               name="endDate"
                               [(ngModel)]="mappedItem.jobComplete.estimate"
                               [minDate]="mappedItem.jobStart.estimate"
                               [nonWorkingDays]="nonWorkingDays"
                               [pickerType]="'calendar'"
                               [readonly]="mappedItem.hasUnits || mappedItem.hasBuildProgrammeTemplateApplied">
                </cb-datepicker>
                <h3 class="mat-headline-6">
                    Lot Details
                </h3>
                <cb-input name="lotNumber"
                          label="Lot Number"
                          type="number"
                          [(ngModel)]="mappedItem.lotNumber"
                          [required]="lotNumberField.isRequired"
                          [disabled]="!lotNumberField.isEditable"
                          *ngIf="lotNumberField.isVisible">
                </cb-input>
                <cb-select label="Road Direction"
                           name="roadDIrection"
                           [(ngModel)]="mappedItem.roadDirection"
                           [options]="COMPASS_DIRECTION_TYPE_ENUM.toLookup()">
                </cb-select>
                <div class="cb-checkbox-list flex-col">
                    <cb-checkbox label="Is Showhome"
                                 name="isOwned"
                                 [(ngModel)]="mappedItem.isShowHome"></cb-checkbox>
                    <cb-checkbox label="Is Spec Build"
                                 name="isSpecBuild"
                                 [(ngModel)]="mappedItem.isSpecBuild"></cb-checkbox>
                    <cb-checkbox label="Lot has Title"
                                 name="hasTitle"
                                 [(ngModel)]="mappedItem.hasTitle"></cb-checkbox>
                </div>
                <cb-input name="dpNumber"
                          label="DP #"
                          [(ngModel)]="mappedItem.dpNumber">
                </cb-input>
                <cb-datepicker *ngIf="!mappedItem.hasTitle"
                               [required]="!mappedItem.hasTitle"
                               label="Actual / Expected Title Date"
                               name="expectedTitleDate"
                               [(ngModel)]="mappedItem.expectedTitleDate"
                               [pickerType]="'calendar'">
                </cb-datepicker>
                <ng-container *ngIf="!mappedItem.hasUnits">
                    <h3 class="mat-headline-6">
                        Sale Details
                    </h3>
                    <cb-currency label="Lot Holding Deposit"
                                 name="lotHoldingDeposit"
                                 [(ngModel)]="mappedItem.lotHoldingDeposit"
                                 [inclGst]="true"
                                 [readonly]="mappedItem.hasClientSale">
                    </cb-currency>
                </ng-container>
                <h3 class="mat-headline-6">
                    Lot Description
                </h3>
                <cb-text-area name="lotHeading"
                              label="Header"
                              [(ngModel)]="mappedItem.lotHeading"
                              [maxlength]="1000"></cb-text-area>
                <cb-text-area label="Description"
                              name="lotDescription"
                              [(ngModel)]="mappedItem.lotDescription"
                              [maxlength]="2000"></cb-text-area>
            </div>
            <div class="flex-col flex-50">
                <cb-address-new [(ngModel)]="mappedItem.lotAddress"
                                addressTitle="Lot Address"
                                name="lotAddress"
                                [lockRegion]="true"
                                [required]="lotNumberField.isRequired"
                                [readonly]="false"
                                class="cb-margin-bottom">
                </cb-address-new>
                <h3 class="mat-headline-6">
                    Website Publishing
                </h3>
                <cb-select label="Market Status"
                           name="selectedMarketStatus"
                           [options]="MARKET_STATUS_ENUM.toLookup()"
                           [(ngModel)]="mappedItem.marketStatus"
                           [required]="true">
                </cb-select>
                <div class="cb-checkbox-list flex-col">
                    <cb-checkbox name="isWithheldFromWebsite"
                                 label="Withhold from website"
                                 [(ngModel)]="mappedItem.isWithheldFromWebsite">
                    </cb-checkbox>
                    <cb-checkbox name="isKiwibuild"
                                 label="Kiwibuild"
                                 [(ngModel)]="mappedItem.isKiwibuild">
                    </cb-checkbox>
                    <cb-checkbox name="isGrowhome"
                                 label="Grow home"
                                 [(ngModel)]="mappedItem.isGrowhome">
                    </cb-checkbox>
                    <cb-checkbox name="isKitset"
                                 label="Kitset"
                                 [(ngModel)]="mappedItem.isKitset">
                    </cb-checkbox>
                    <cb-checkbox name="isTransportable"
                                 label="Transportable"
                                 [(ngModel)]="mappedItem.isTransportable">
                    </cb-checkbox>
                    <cb-price-options *ngIf="lotPermissions.canEditLotPriceOption() && ShowPriceOptions()" [mappedItem]="mappedItem"></cb-price-options>
                </div>
            </div>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="lotDetailsForm.pristine || lotDetailsForm.invalid || saveInProgress"
                (click)="save()">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
