<cb-dialog>
    <form #form="ngForm">
        <cb-text-area label="Description"
                      name="description"
                      [maxlength]="1000"
                      [required]="true"
                      [(ngModel)]="description"></cb-text-area>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                [disabled]="!form.dirty || !form.valid || !canSave()"
                (click)="onSaveClicked()">Save</button>
        <button mat-raised-button
                (click)="dialogRef.close()">Cancel</button>
    </div>
</cb-dialog>
