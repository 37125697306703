<div class="cb-margin cb-padding-top-2x flex-col flex">
    <div class="mat-headline-6">Connected Users</div>
    <mat-card>
        <mat-card-content>
            <form>
                <cb-input label="Type in a message to broadcast to all the connected users"
                          name="message"
                          [(ngModel)]="message"></cb-input>
                <button mat-raised-button
                        color="primary"
                        (click)="broadcastMessage()">
                    Broadcast Message
                </button>
            </form>
        </mat-card-content>
    </mat-card>
    <mat-card class="cb-margin-top-2x flex-row flex"
              *ngFor="let user of users; let i = index">
        <mat-card-header class="start-center flex-row flex-50">
            <mat-icon mat-card-avatar
                      class="user-icon">account_circle
            </mat-icon>
            <mat-card-title>
                <small>{{ user.firstName }} {{ user.lastName }}</small>
            </mat-card-title>
            <mat-card-subtitle class="cb-no-margin">Connections : {{ user.connections }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="start-center flex-50">
            <form class="flex-row">
                <div class="cb-margin-right"
                     style="width: calc(50vw - 150px)">
                    <cb-input label="Type a message"
                              name="message{{i}}"
                              [(ngModel)]="user.message"></cb-input>
                </div>
                <div>
                    <button mat-raised-button
                            color="primary"
                            (click)="sendMessage(user, user.message)">
                        Send Message
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
