<div class="uploadfilecontainer start-center flex-row mat-body-2"
     cbDragDrop
     (onFileDropped)="uploadFile($event)">
    <input hidden
           type="file"
           #fileInput
           (change)="uploadFile($event.target.files)"
           (click)="$event.target.value = null"/>
    <div layout-padding
         class="cb-margin-3x flex-col flex"
         *ngIf="!isFileValidAndUploaded">
        <div>
            <h4 class="cb-padding-bottom">{{documentEntityMappedItem?.documentGroup?.name}} -
                {{documentEntityMappedItem?.documentType?.label}}</h4>
            <div class="cb-padding-bottom"> {{ documentEntityMappedItem?.documentType?.description }}</div>
            <div class="cb-padding-bottom"
                 *ngIf="documentEntityMappedItem?.documentType?.maximumFileSizeInMegaBytes">
                <strong>Max
                    Size</strong>
                :
                {{documentEntityMappedItem?.documentType?.maximumFileSizeInMegaBytes}}MB
            </div>
            <h4 *ngIf="documentEntityMappedItem?.documentType?.fileTypes">Allowed File Types</h4>
            <ul>
                <li *ngFor="let type of documentEntityMappedItem?.documentType?.fileTypes">
                    {{ type }}
                </li>
            </ul>
        </div>
    </div>
    <div layout-padding
         class="cb-margin-3x flex-row flex"
         *ngIf="isFileValidAndUploaded">
        <h4> {{documentEntityMappedItem?.document?.name}}</h4>
    </div>
    <div class="cb-margin-right"
         (click)="fileInput.click()">
        <button style="width: 60px;height: 60px;"
                mat-icon-button
                color="accent">
            <mat-icon>attach_file_outline</mat-icon>
        </button>
    </div>
</div>
<div class="flex-col mat-body-2">
    <div *ngIf="!isFileExtensionValid"
         class="icon-text">
        <mat-icon color="warn"
                  class="cb-margin-right-2x">report_problem</mat-icon>
        <span> The file you have selected has the wrong extension .{{fileExtension}}. Please select a file with one
            of the extensions: {{ documentEntityMappedItem?.documentType?.fileTypes }}</span>
    </div>
    <div *ngIf="!isFilesizeValid"
         class="icon-text">
        <mat-icon color="warn"
                  class="cb-margin-right-2x">report_problem</mat-icon>
        <span> The file you have selected is {{fileSizeFormatted}}. This is too large. Please select a file under
            {{documentEntityMappedItem?.documentType?.maximumFileSizeInMegaBytes}}MB. </span>
    </div>
</div>
