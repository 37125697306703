import { Component, Inject, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { capitalize, find, map, some } from 'lodash';
import {
    DocumentTemplateLogicService, DocumentTemplateMappedItem,
    IDocumentTemplateDto, IDocumentTemplateLogicService, IDocumentTemplateMappedItem, IDocumentTemplateTreeDto
} from '@app/logic/document-template';
import { DOCUMENT_TEMPLATE_CODES_CONST, FileTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { NgForm } from '@angular/forms';


interface IData {
    mappedItem: DocumentTemplateMappedItem;
    documentTemplateTree: IDocumentTemplateTreeDto[];
    isUploadOnly: boolean;
}

@Component({
    selector: 'cb-document-template-dialog',
    templateUrl: './document-template-dialog.component.html',
    styleUrls: ['./document-template-dialog.component.scss']
})
export class DocumentTemplateDialogComponent extends BaseDialogFormViewDirective<IDocumentTemplateDto, IDocumentTemplateMappedItem, IDocumentTemplateLogicService> {
    @ViewChild('documentTemplateForm') public documentTemplateForm: NgForm;

    public static readonly MIN_WIDTH = '75%';
    public mappedItem: DocumentTemplateMappedItem;
    public categories: any[];
    public file$ = new BehaviorSubject<File>(undefined);
    public isFileValidAndUploaded$ = new BehaviorSubject<boolean>(false);
    public allowedFileTypes = [FileTypeEnumId.Docx, FileTypeEnumId.Pdf, FileTypeEnumId.Xlsm, FileTypeEnumId.Xlsx];
    public documentTemplateCodes = DOCUMENT_TEMPLATE_CODES_CONST.toSelectList().sort((a, b) => a.label.localeCompare(b.label));

    private file: File;
    private isFileValidAndUploaded = false;

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<DocumentTemplateDialogComponent>,
        protected readonly documentTemplateLogicService: DocumentTemplateLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        this.categories = map(data.documentTemplateTree, (item: IDocumentTemplateTreeDto, index: number) => {
            return {
                id: index,
                category: item.category,
                label: capitalize(item.category)
            };
        });
        if (data.mappedItem.id) {
            this.documentTemplateLogicService.$getItem(data.mappedItem.id).subOnce((result: IDocumentTemplateDto) => {
                Object.assign(this.mappedItem, result);
            });
        }
        if (!data.mappedItem.id || data.isUploadOnly) {
            this.file$?.subscribe((result: File) => this.file = result);
            this.isFileValidAndUploaded$.subscribe((result: boolean) => this.isFileValidAndUploaded = result);
        }
    }

    public getDefaultCategory(): number {
        const match: any = find(this.categories ?? [], { category: this.mappedItem.category });
        return match ? match.id : undefined;
    }

    public onCategoryChanged(categoryId: number): void {
        const match: any = find(this.categories ?? [], { id: categoryId });
        if (match) {
            this.mappedItem.category = match.category;
        }
    }

    public isCodeDuplicated(): boolean {
        return some(this.data.documentTemplateTree, (item: IDocumentTemplateTreeDto) =>
            some(item.children, (document: IDocumentTemplateDto) =>
                document.code === this.mappedItem.code && document.id !== this.mappedItem.id));
    }

    public canSave(): boolean {
        if (this.data.isUploadOnly) {
            return this.isFileValidAndUploaded;
        } else if (this.mappedItem.id) {
            return this.documentTemplateForm?.dirty && this.documentTemplateForm?.valid;
        } else {
            return this.documentTemplateForm?.dirty && this.documentTemplateForm?.valid && !this.isCodeDuplicated() && this.isFileValidAndUploaded;
        }
    }

    public onSaveClick(): void {
        if (!this.mappedItem.id || this.data.isUploadOnly) {
            if (this.file && this.isFileValidAndUploaded) {
                const reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onload = () => {
                    const contentArr: string[] = (reader.result as string).split(',');
                    const content: string = contentArr[contentArr.length - 1];

                    this.mappedItem.filename = this.file.name;
                    this.mappedItem.fileSize = this.file.size;
                    this.mappedItem.file = {
                        content,
                        contentType: this.file.type,
                        filename: this.file.name
                    };
                    super.save();
                };
            }
        } else {
            super.save();
        }
    }
}
