import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from './button/button.component';
import { CbPopupTipModule } from '../popup-tip/popup-tip.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';

const COMPONENTS = [
    ButtonComponent
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        CbPopupTipModule,
        CbClickModule,
        MatMenuModule
    ],
    exports: COMPONENTS
})
export class CbButtonModule { }
