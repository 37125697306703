import { Component, OnInit, Inject, ViewChildren } from '@angular/core';
import { ICategoryAttributeDto, IProductCategoryDto, IAttributeOptionDto } from '@app/logic/product-categories/interfaces/i.product-category.dto';
import { IProductCategoryMappedItem } from '@app/logic/product-categories/interfaces/i.product-category.mapped';
import { FormMode } from '@app/shared/enums/form';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IProductCategoriesLogicService } from '@app/logic/product-categories/interfaces/i.product-categories.logic.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManageProductDialogComponent } from '../../product-catalogue/product-catalogue-item-dialog/manage-product-dialog.component';
import { ToastService } from '@app/core/services/toast/toast.service';
import { CategoryLogicService } from '@app/logic/product-categories';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';

export interface IData {
    attribute: ICategoryAttributeDto;
    mappedItem: IProductCategoryMappedItem;
}

@Component({
    selector: 'cb-manage-category-attribute-dialog',
    templateUrl: './manage-category-attribute-dialog.component.html',
    styleUrls: ['./manage-category-attribute-dialog.component.scss']
})
export class ManageCategoryAttributeDialogComponent
    extends BaseDialogFormViewDirective<IProductCategoryDto, IProductCategoryMappedItem, IProductCategoriesLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '50%';

    public newOption = ''; // cleared out each option added
    public mappedItem: IProductCategoryMappedItem;
    public attribute: ICategoryAttributeDto;
    public get manageCategoryAttributeForm(): NgForm {
        return this.manageCategoryAttributeFormParent.first;
    }
    @ViewChildren('manageCategoryAttributeForm') public manageCategoryAttributeFormParent;
    constructor(
        public readonly dialogRef: MatDialogRef<ManageProductDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        public readonly categoryLogicService: CategoryLogicService) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.attribute = data.attribute;
    }

    public ngOnInit(): void {
        this.formMode = FormMode.Add;
        if (this.attribute) {
            this.formMode = FormMode.Edit;
        } else {
            this.attribute = this.mappedItem.newAttribute();
        }
    }

    public removeOptionFromAttribute(option: IAttributeOptionDto): void {
        this.attribute.options = this.attribute.options.filter(opt => opt.name !== option.name);
        this.manageCategoryAttributeForm.form.markAsDirty();
    }

    public addOptionToAttribute($event: Event, option: string): void {
        const newOption = this.mappedItem.newAttributeOption();
        newOption.name = option;
        this.attribute.options.push(newOption);
        this.newOption = '';
        this.manageCategoryAttributeForm.form.markAsDirty();
    }

    public saveMethod(): Observable<any> {
        if (this.formMode === FormMode.Add) {
            return this.mappedItem.saveAttribute(this.attribute);
        } else {
            return this.mappedItem.updateAttribute(this.attribute);
        }
    }

}
