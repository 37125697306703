<cb-dialog>
    <h3 class="mat-headline-6">{{data.contentHeading}}</h3>
    <form class="flex-col flex"
          #userSearchForm="ngForm">
        <cb-input class="flex-100"
                  [label]="data.placeholder"
                  name="userInput"
                  [required]="true"
                  [maxlength]="150"
                  name="buildingConsultant"
                  [disabled]="false"
                  [(ngModel)]="data.searchService.query"
                  (change)="data.searchService.handleInputChange()">
        </cb-input>
    </form>
    <div class="cb-table cb-margin-top-2x cb-margin-bottom"
         #infiniteScrollContainer>
        <mat-table [dataSource]="data.searchService.searchResults$ | async"
                   infiniteScroll
                   [immediateCheck]="false"
                   [fromRoot]="true"
                   [infiniteScrollContainer]="'div#mainContainer'"
                   (scrolled)="data.searchService.nextPage$.next({addPage: 1})">
            <ng-container matColumnDef="userName">
                <mat-header-cell *matHeaderCellDef> Username</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.username | cbDasher}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.name | cbDasher}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="job">
                <mat-header-cell *matHeaderCellDef> Job </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.jobTitle | cbDasher}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.email | cbDasher}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="primaryBusinessEntity">
                <mat-header-cell *matHeaderCellDef> Primary Business Entity</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.primaryBusinessEntity | cbDasher}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="active">
                <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="element.isActive">
                        check_box
                    </mat-icon>
                    <mat-icon *ngIf="!element.isActive">
                        check_box_outline_blank
                    </mat-icon>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions"
                          stickyEnd>
                <mat-header-cell *matHeaderCellDef>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button
                            (click)="itemSelected(element)">
                        <mat-icon>pageview</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <cb-loading-spinner *ngIf="!data.searchService.searchResultsLoaded"></cb-loading-spinner>
    </div>
    <div actions>
        <div class="end-center flex-row flex"
             *ngIf="data.searchService.isPagination">
            Page {{data.searchService.currentPage}} of {{data.searchService.numPages}}
            <button mat-icon-button
                    *ngIf="data.searchService.currentPage > 1"
                    (click)="data.searchService.prev()">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <button #nextBtn
                    mat-icon-button
                    *ngIf="data.searchService.currentPage < data.searchService.numPages"
                    (click)="data.searchService.next()">
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
        <span class="flex"></span>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
