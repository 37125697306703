<mat-drawer-container class="setting-container"
                      autosize>
    <mat-drawer #drawer
                class="setting-sidenav"
                mode="side"
                opened="true">

        <mat-list>
            <ng-container *ngFor="let setting of settingTabs">
                <mat-list-item
                    *ngIf="setting?.canView"
                    (click)="tabIndexChanged(setting.url)"
                    [class.selected]="currentTab === setting.url">
                    {{ setting.name }}
                </mat-list-item>
            </ng-container>
        </mat-list>

    </mat-drawer>

    <div class="cb-margin-top">
        <router-outlet>

        </router-outlet>
    </div>
</mat-drawer-container>
