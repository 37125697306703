<cb-dialog>
    <form class="flex-col flex"
          #payemntFrequencyForm="ngForm">
        <h1 class="mat-headline-6">
            Edit Payment Frequency
        </h1>
        <cb-display-value-new label="Payment Frequency"
                              [value]="mappedItem.label"></cb-display-value-new>
        <mat-form-field>
            <mat-label>Activity Schedule Description</mat-label>
            <textarea matInput
                      type="text"
                      name="activityScheduleDescription"
                      [maxlength]="1000"
                      [(ngModel)]="mappedItem.activityScheduleDescription">
            </textarea>
        </mat-form-field>
    </form>
    <div actions>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!payemntFrequencyForm.dirty || !payemntFrequencyForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
