import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IBusinessEntityDto, BusinessEntitiesLogicService } from '@app/logic/business-entity';
import {
    IBuildProgrammeTemplateDto, IBuildProgrammeTemplateMappedItem, IBuildProgrammeTemplateLogicService,
    BuildProgrammeTemplateMappedItem
} from '@app/logic/build-programme-template';

interface IData {
    mappedItem: BuildProgrammeTemplateMappedItem;
    baseTemplateName: string;
}

enum TemplateType {
    Nationwide,
    Regional,
}

@Component({
    selector: 'cb-build-programme-template-dialog',
    templateUrl: './build-programme-template-dialog.component.html',
    styleUrls: ['./build-programme-template-dialog.component.scss']
})
export class BuildProgrammeTemplateDialogComponent
    extends BaseDialogFormViewDirective<IBuildProgrammeTemplateDto, IBuildProgrammeTemplateMappedItem, IBuildProgrammeTemplateLogicService> {
    public static readonly MIN_WIDTH = '50%';
    public mappedItem: BuildProgrammeTemplateMappedItem;
    public businessEnitities: IBusinessEntityDto[];
    public templateTypeEnum = TemplateType;
    public templateType = TemplateType.Nationwide;

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<BuildProgrammeTemplateDialogComponent>,
        private readonly businessEntityLogic: BusinessEntitiesLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;

        this.businessEntityLogic.$getList().subOnce((businessEnitities: IBusinessEntityDto[]) => {
            this.businessEnitities = businessEnitities;
        });

        if (!this.mappedItem.id) {
            this.mappedItem.isActive = true;
        }

        if (!this.mappedItem.regions?.length) {
            this.templateType = TemplateType.Nationwide;
        } else if (this.mappedItem.regions?.length) {
            this.templateType = TemplateType.Regional;
        }
    }

    public isRegionChecked(regionId: number): boolean {
        return this.mappedItem.regions && this.mappedItem.regions.indexOf(regionId) > -1;
    }

    public onRegionChanged(regionId: number, isChecked: boolean): void {
        this.mappedItem.regions = this.mappedItem.regions && this.mappedItem.regions.length ? this.mappedItem.regions : [];
        if (isChecked) {
            if (this.mappedItem.regions.indexOf(regionId) === -1) {
                this.mappedItem.regions.push(regionId);
            }
        } else {
            const index = this.mappedItem.regions.indexOf(regionId);
            if (index > -1) {
                this.mappedItem.regions.splice(index, 1);
            }
        }
    }

    public onSaveClicked(): void {
        if (this.templateType === TemplateType.Nationwide) {
            this.mappedItem.regions = [];
        }
        super.save();
    }
}
