<div class="button-row end-center flex-row">
    <button class="cb-margin-right"
            *ngIf="reorderEnabled"
            mat-raised-button
            color="primary"
            [disabled]="!sortOrderChanged"
            (click)="saveOrder()">Save Order</button>
    <mat-slide-toggle *ngIf="reorderingAllowed.value"
                      [checked]="reorderEnabled"
                      (change)="toggleReorder($event)">Enable Reordering
    </mat-slide-toggle>
</div>
<div class="cb-table cb-margin-top">
    <mat-table [dataSource]="lots"
               [dragula]="LOT_UNITS"
               [(dragulaModel)]="dragulaModel">
        <ng-container matColumnDef="lotNumber">
            <mat-header-cell *matHeaderCellDef> Lot # </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.lotNumber}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.lotAddress?.street}}
                <br/>
                {{element.lotAddress?.suburb}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lotType">
            <mat-header-cell *matHeaderCellDef> Lot Type </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{LOT_TYPE_ENUM[element.lotType]}}
                <span *ngIf="lotMappedItem.buildType.label">({{element.buildType?.code}})</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="buildLink">
            <mat-header-cell *matHeaderCellDef> Build Link </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.lotBuildTypeLink}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="floorArea">
            <mat-header-cell *matHeaderCellDef> Floor Area </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{element.amenities?.floorArea}}m
                <sup>2</sup>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="landPrice">
            <mat-header-cell *matHeaderCellDef> Land Price </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.financials?.landPrice | cbCurrency:'addGst'}} (Inc Gst)
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sellPrice">
            <mat-header-cell *matHeaderCellDef> Sell Price </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.financials?.sellPrice | cbCurrency:'addGst'}} (Inc Gst)
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="contractPrice">
            <mat-header-cell *matHeaderCellDef> Contract Price </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.financials?.contractPrice | cbCurrency:'addGst'}} (Inc Gst)
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="jobNumber">
            <mat-header-cell *matHeaderCellDef> Job # </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.jobNumber}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="jobStatus">
            <mat-header-cell *matHeaderCellDef> Job Status </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{LOT_JOB_STATUS_ENUM[element.jobStatus]}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="bed">
            <mat-header-cell *matHeaderCellDef> Bed </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div class="start-center flex-row">
                    <mat-icon>hotel</mat-icon>
                    <span class="mat-body-2 cb-margin-left">{{element.amenities?.noBedrooms}}</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="bath">
            <mat-header-cell *matHeaderCellDef> Bath </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div class="start-center flex-row">
                    <mat-icon>wc</mat-icon>
                    <span class="mat-body-2 cb-margin-left">{{element.amenities?.noBathrooms}}</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="living">
            <mat-header-cell *matHeaderCellDef> Living </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div class="start-center flex-row">
                    <mat-icon>weekend</mat-icon>
                    <span class="mat-body-2 cb-margin-left">{{element.amenities?.noLivingRooms}}</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="garage">
            <mat-header-cell *matHeaderCellDef> Garage </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div class="start-center flex-row">
                    <mat-icon>directions_car</mat-icon>
                    <span class="mat-body-2 cb-margin-left">{{element.amenities?.noGarages}}</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="marketStatus">
            <mat-header-cell *matHeaderCellDef> Mkt Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.marketStatus === MARKET_STATUS_ENUM.Sold">monetization_on</mat-icon>
                <mat-icon *ngIf="element.marketStatus === MARKET_STATUS_ENUM.NotAvailable">block_black
                </mat-icon>
                <mat-icon *ngIf="element.marketStatus === MARKET_STATUS_ENUM.Available">attach_money
                </mat-icon>
                <mat-icon *ngIf="element.marketStatus === MARKET_STATUS_ENUM.PreRelease">info_black</mat-icon>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions"
                      stickyEnd>
            <mat-header-cell *matHeaderCellDef>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <button mat-icon-button
                        (click)="viewLot($event, element.id);">
                    <mat-icon>pageview</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <cb-info-message *ngIf="lotsLoaded && lots.length === 0"
                     [message]="'There are no Units to display'"
                     [icon]="'info_circle'"></cb-info-message>
    <cb-loading-spinner *ngIf="!lotsLoaded"></cb-loading-spinner>
</div>
