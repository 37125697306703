<div class="flex-col flex">
    <div class="end-center flex-row">
        <button mat-icon-button
                (click)="goToNotifications($event)">
            <mat-icon>view_list</mat-icon>
        </button>
        <button mat-icon-button
                (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <cdk-virtual-scroll-viewport *ngIf="items.length > 0"
                                 class="notifications-viewport flex"
                                 [itemSize]="125">
        <div *cdkVirtualFor="let item of items; let i = index"
             class="item">
            <div class="item-content start-center flex-row flex"
                 (click)="goToItem(item)">
                <mat-icon class="item-icon">{{getIcon(item)}}</mat-icon>
                <div class="flex-col">
                    <h4>{{item.subject}}</h4>
                    <span class="item-body mat-body-2">{{item.body}}</span>
                    <span class="mat-caption">
                        {{item.date | date:'dd MMM yyyy h:mm a'}}
                    </span>
                </div>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
    <div class="center-center flex-col flex"
         *ngIf="items.length < 1">
        <div class="start-center flex-row">
            <mat-icon>notifications_none</mat-icon>
            <span class="mat-body-2">&nbsp;You have no unread notifications</span>
        </div>
    </div>
</div>
