<cb-dialog>
    <form class="flex-col flex"
          #tempLotForm="ngForm">
        <div class="flex-row flex">
            <div class="cb-margin-right-2x flex-col flex">
                <cb-input type="number"
                          name="lotNumber"
                          label="Lot Number"
                          [required]="true"
                          [(ngModel)]="mappedItem.lotNumber"></cb-input>
                <cb-input type="text"
                          name="jobNumber"
                          label="Job Number"
                          [required]="true"
                          [(ngModel)]="mappedItem.jobNumber"></cb-input>
                <cb-select label="Lot Status"
                           name="lotStatus"
                           [(ngModel)]="mappedItem.status.id"
                           [options]="lotStatusLookup"></cb-select>
                <cb-currency name="sellingPrice"
                             label="Selling Price"
                             [inclGst]="true"
                             [(ngModel)]="mappedItem.sellingPrice"></cb-currency>
                <cb-input class="cb-margin-top-2x"
                          type="text"
                          name="streetAddress"
                          label="Street Address"
                          [required]="true"
                          [(ngModel)]="mappedItem.lotAddress.street"></cb-input>
                <cb-input type="text"
                          name="suburbAddress"
                          label="Suburb Address"
                          [required]="true"
                          [(ngModel)]="mappedItem.lotAddress.suburb"></cb-input>
                <cb-input type="text"
                          name="cityAddress"
                          label="City"
                          [required]="true"
                          [(ngModel)]="mappedItem.lotAddress.city"></cb-input>
                <cb-select label="Region"
                           name="regionAddress"
                           [(ngModel)]="mappedItem.lotAddress.region"
                           (ngModelChange)="addressRegionChanged()"
                           [options]="regions"
                           [required]="true"></cb-select>
                <cb-input type="number"
                          name="postCodeAddress"
                          label="Postcode"
                          [required]="false"
                          [(ngModel)]="mappedItem.lotAddress.postCode"></cb-input>
                <cb-select class="cb-margin-top-2x"
                           label="Business Entity"
                           name="businessEntity"
                           [disabled]="!mappedItem?.lotAddress?.region"
                           [(ngModel)]="mappedItem.businessEntity.id"
                           [options]="businessEntities"
                           [required]="true"></cb-select>
                <cb-input class="cb-margin-top-2x"
                          type="number"
                          name="numBedrooms"
                          label="Bedrooms"
                          [required]="true"
                          [(ngModel)]="mappedItem.amenities.noBedrooms"></cb-input>
                <cb-input type="number"
                          name="numBathrooms"
                          label="Bathrooms"
                          [required]="true"
                          [(ngModel)]="mappedItem.amenities.noBathrooms"></cb-input>
                <cb-input type="number"
                          name="numLivingRooms"
                          label="Living Areas"
                          [required]="true"
                          [(ngModel)]="mappedItem.amenities.noLivingRooms"></cb-input>
                <cb-select class="cb-margin-top-2x"
                           label="Garages"
                           name="noGarages"
                           [(ngModel)]="mappedItem.amenities.noGarages"
                           [options]="garages"
                           [required]="true"></cb-select>
                <cb-input type="number"
                          name="floorArea"
                          label="Floor Area"
                          [required]="true"
                          [(ngModel)]="mappedItem.amenities.floorArea"></cb-input>
                <cb-input type="number"
                          name="landArea"
                          label="Land Area"
                          [required]="true"
                          [(ngModel)]="mappedItem.amenities.landArea"></cb-input>
                <cb-checkbox class="cb-margin-top-2x"
                             name="kiwibuild"
                             label="Kiwibuild"
                             [(ngModel)]="mappedItem.isKiwiBuild"></cb-checkbox>
                <cb-checkbox name="growHome"
                             label="Grow Home"
                             [(ngModel)]="mappedItem.isGrowhome"></cb-checkbox>
                <cb-checkbox name="kitset"
                             label="Kitset"
                             [(ngModel)]="mappedItem.isKitSet"></cb-checkbox>
                <cb-checkbox name="transportable"
                             label="Transportable"
                             [(ngModel)]="mappedItem.isTransportable"></cb-checkbox>
                <cb-select label="Project"
                           name="project"
                           [(ngModel)]="mappedItem.project.id"
                           (ngModelChange)="projectChanged()"
                           [options]="projects"
                           [required]="true"
                           labelProp="projectName"></cb-select>
                <cb-select class="cb-margin-top-2x"
                           label="Project Release Stage"
                           name="projectRelease"
                           [(ngModel)]="mappedItem.projectReleaseStage.id"
                           (ngModelChange)="releaseStageChanged()"
                           [disabled]="!mappedItem?.project?.id"
                           [options]="projectReleases"
                           [required]="true"></cb-select>
                <div class="cb-margin-top-3x">
                    <label class="required">Lot Contract Type</label>
                    <mat-radio-group class="cb-margin-top-2x flex-col"
                                     aria-label="Lot Contract Type"
                                     name="lotInterestType"
                                     [(ngModel)]="mappedItem.lotContractType"
                                     [required]="true">
                        <mat-radio-button
                            *ngIf="mappedItem?.projectReleaseStage?.allowedLotContractTypes?.includes(LOT_CONTRACT_TYPE_ENUM?.HouseAndLand)"
                            [value]="LOT_CONTRACT_TYPE_ENUM.HouseAndLand">
                            House &amp; Land
                        </mat-radio-button>
                        <mat-radio-button
                            *ngIf="mappedItem?.projectReleaseStage?.allowedLotContractTypes?.includes(LOT_CONTRACT_TYPE_ENUM?.DesignAndLand)"
                            [value]="LOT_CONTRACT_TYPE_ENUM.DesignAndLand">
                            Design &amp; Land
                        </mat-radio-button>
                        <mat-radio-button
                            *ngIf="mappedItem?.projectReleaseStage?.allowedLotContractTypes?.includes(LOT_CONTRACT_TYPE_ENUM?.DesignAndBuild)"
                            [value]="LOT_CONTRACT_TYPE_ENUM.DesignAndBuild">
                            Design &amp; Build
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <cb-checkbox class="cb-margin-top-2x"
                             name="isActive"
                             label="Active"
                             [(ngModel)]="mappedItem.isActive"></cb-checkbox>
                <cb-checkbox name="isPublished"
                             label="Publish on Website"
                             [(ngModel)]="mappedItem.isPublished"></cb-checkbox>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-col flex">
                <span class="mat-body-strong">Render Image</span>
                <div class="lot-image-container">
                    <img *ngIf="newRenderImageBase64 || mappedItem.renderImageUrl"
                         [src]="newRenderImageBase64 || mappedItem.renderImageUrl"
                         class="lot-image">
                </div>
                <input hidden
                       type="file"
                       #renderInput
                       (click)="$event.target.value = null"
                       (change)="uploadRender($event.target.files[0], tempLotForm)"
                       accept=".jpeg, .png, .jpg"/>
                <div class="cb-margin-top flex-row">
                    <button mat-raised-button
                            (click)="renderInput.click()">Choose File
                    </button>
                    <button mat-raised-button
                            (click)="removeRenderImage(tempLotForm)">Remove Image
                    </button>
                </div>
                <span class="mat-body-strong cb-margin-top-2x">Floor Plan Image</span>
                <div class="lot-image-container">
                    <img *ngIf="newFloorPlanImageBase64 || mappedItem.floorPlanUrl"
                         [src]="newFloorPlanImageBase64 || mappedItem.floorPlanUrl"
                         class="lot-image">
                </div>
                <input hidden
                       type="file"
                       #floorPlanInput
                       (click)="$event.target.value = null"
                       (change)="uploadFloorPlan($event.target.files[0], tempLotForm)"
                       accept=".jpeg, .png, .jpg"/>
                <div class="cb-margin-top flex-row">
                    <button mat-raised-button
                            (click)="floorPlanInput.click()">Choose File
                    </button>
                    <button mat-raised-button
                            (click)="removeFloorPlanImage(tempLotForm)">Remove Image
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="saveInProgress || tempLotForm.pristine || tempLotForm.invalid || !mappedItem.lotContractType">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
