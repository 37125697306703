import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ILotMappedItem } from '@app/logic/lots';
import { IWorkingDrawingMappedItem } from '@app/logic/working-drawings';
import { IWorkingDrawingsLogicService } from '@app/logic/working-drawings/interfaces/i.working-drawings-logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IWorkingDrawingDto, LotTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { WorkingDrawingDialogComponent } from '../working-drawing-dialog/working-drawing-dialog.component';

@Component({
    selector: 'cb-finalise-working-drawing-dialog',
    templateUrl: './finalise-working-drawing-dialog.component.html',
    styleUrls: ['./finalise-working-drawing-dialog.component.scss']
})
export class FinaliseWorkingDrawingDialogComponent extends BaseDialogFormViewDirective<IWorkingDrawingDto, IWorkingDrawingMappedItem, IWorkingDrawingsLogicService> {

    public static readonly MIN_WIDTH = '66%';

    public lotMappedItem: ILotMappedItem;
    public finaliseInProgress: boolean;

    public get mappedItem(): IWorkingDrawingMappedItem {
        return this.data.mappedItem;
    }

    public designComplexities = this.lookups.DESIGN_COMPLEXITY.toLookup();

    constructor(
        public readonly dialogRef: MatDialogRef<WorkingDrawingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: IWorkingDrawingMappedItem;
            lotTypeEnumId: LotTypeEnumId;
            lotMappedItem: ILotMappedItem;
        },
        public toastService: ToastService,
        private readonly lookups: LookupService,
        public readonly cbDialog: CbDialogService,
    ) {
        super(dialogRef, toastService);
        this.lotMappedItem = data.lotMappedItem;
        this.finaliseInProgress = false;
    }

    public finaliseWorkingDrawing(): void {
        this.finaliseInProgress = true;
        this.cbDialog.confirm({
            dialogHeading: 'Finalise working drawing',
            message: 'Are you sure you want to finalise this working drawing?',
            confirmed: () => {
                this.finaliseInProgress = false;
                this.dialogRef.close(this.mappedItem);
            },
            declined: () => {
                this.finaliseInProgress = false;
            }
        });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

}
