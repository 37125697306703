<cb-dialog dialogHeading="{{(isEdit$ | async) ? 'Edit Pricing Revision' : 'Create Pricing Revision'}}">
    <form class="flex-col flex"
          #form="ngForm">
        <div *cbWaitFor="(quantitySurveyors$ | async) && mappedItem">
            <cb-lot-autocomplete-scroller *ngIf="!(creatingFromLot$ | async) && !(isEdit$ | async)"
                                          label="Lot"
                                          name="selectedLot"
                                          [(ngModel)]="selectedLot"
                                          (optionSelected)="lotChanged()"
                                          [availableOnly]="true"
                                          [requireMatch]="true"
                                          [matchProps]="['id']">
            </cb-lot-autocomplete-scroller>
            <cb-display-value-new label="Lot"
                                  [value]="selectedLot.lotDescription"
                                  *ngIf="(creatingFromLot$ | async) || (isEdit$ | async)">
            </cb-display-value-new>
            <cb-display-value-new label="Concept M2"
                                  [value]="mappedItem.amenities?.floorArea"
                                  *ngIf="mappedItem.amenities?.floorArea">
            </cb-display-value-new>
            <cb-display-value-new label="Pricing Revision"
                                  [value]="mappedItem.revisionNumber"
                                  *ngIf="!(isAdd$ | async)">
            </cb-display-value-new>
            <cb-select *ngIf="mappedItem && (pricingComplexities$ | async)"
                       [required]="true"
                       label="Pricing Complexity"
                       [disabled]="!mappedItem.lotId"
                       labelProp="complexity"
                       name="pricingComplexityId"
                       [(ngModel)]="mappedItem.pricingComplexityId"
                       [options]="pricingComplexities$ | async"
                       (change)="pricingComplexityChanged()">
            </cb-select>
            <cb-input type="number"
                      name="targetWorkHours"
                      label="Estimated Hours"
                      [(ngModel)]="mappedItem.targetWorkHours">
            </cb-input>
            <cb-datepicker name="dueByDate"
                           [(ngModel)]="mappedItem.dueBy"
                           pickerType="calendar"
                           label="Due By">
            </cb-datepicker>
            <cb-user-autocomplete-scroller [team]="QS_TEAM"
                                           label="Assign To"
                                           name="selectedAssignee"
                                           [(ngModel)]="selectedAssignee"
                                           [requireMatch]="true"
                                           [matchProps]="['id']"
                                           [required]="mappedItem.id > 0"
                                           [active]="true"
                                           (change)="updateId(selectedAssignee.id, 'assignedToUserId')">
            </cb-user-autocomplete-scroller>
            <cb-checkbox name="reviewRequired"
                         label="Revision Review Required"
                         (change)="reviewRequiredChanged($event)"
                         [(ngModel)]="mappedItem.reviewRequired">
            </cb-checkbox>
            <cb-user-autocomplete-scroller *ngIf="mappedItem.reviewRequired"
                                           [team]="QS_TEAM"
                                           label="Review By"
                                           name="selectedReviewer"
                                           [(ngModel)]="selectedReviewer"
                                           [requireMatch]="true"
                                           [matchProps]="['id']"
                                           [required]="mappedItem.id > 0"
                                           [active]="true"
                                           (change)="updateId(selectedReviewer.id, 'reviewerId')">
            </cb-user-autocomplete-scroller>
        </div>
    </form>
    <div actions class="flex flex-row center-right">
        <cb-button *ngIf="isEdit$ | async"
                   [clickLimit]="1"
                   color="white"
                   class="cb-margin-right"
                   (clicked)="cancelPricingRevision()">
            <mat-icon>delete</mat-icon>
            Delete Revision
        </cb-button>
        <span class="flex"></span>
        <cb-button [disabled]="form.pristine || form.invalid || !(mappedItem.designSchemeId > 0)"
                   [clickLimit]="1"
                   class="cb-margin-right"
                   (clicked)="save()">
            Save
        </cb-button>
        <cb-button (clicked)="cancel()">
            Cancel
        </cb-button>
    </div>
</cb-dialog>
