<cb-dialog dialogHeading="Security roles">
    <form class="flex-col flex"
          #form="ngForm">
        <div class="mat-headline-6">Security Groups</div>
        <cb-table [loaded]="true">
            <thead cbTableHead>
                <tr cbTableRow>
                    <td></td>
                    <td>Role</td>
                </tr>
            </thead>
            <tbody cbTableBody>
                <tr cbTableRow>
                    <td>
                        <cb-checkbox name="selectAll"
                                     [(ngModel)]="isSelectAll"
                                     [ngModelOptions]="{standalone: true}"
                                     (change)="onSelectAllChanged()">
                        </cb-checkbox>
                    </td>
                    <td>All</td>
                </tr>
                <tr cbTableRow
                    *ngFor="let role of roleOptions | cbSortByPipe: 'name': 'asc'">
                    <td>
                        <cb-checkbox name="roleId{{role.id}}"
                                     [(ngModel)]="selectedRoleIds[role.id]"
                                     [ngModelOptions]="{standalone: true}"
                                     (change)="onSelectItemChanged()">
                        </cb-checkbox>
                    </td>
                    <td>{{role.name}}</td>
                </tr>
            </tbody>
        </cb-table>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (cbClick)="generateRolePermissionReport()"
                [clickLimit]="1">
            Download Report
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
