<cb-stepper class="flex-row align-items-center">
    <cb-step label="Scheme"
             [active]="hasFirstScheme()"
             [complete]="hasFirstAcceptedScheme()"
             [showCard]="showSchemeCard()  && stepCardExpanded">
        <div cbStepCard>
            <p>
                {{getSchemeCardText()}}
            </p>
            <p>
                {{getPricingRevisionCardText()}}
            </p>
        </div>
    </cb-step>
    <cb-step label="Concept"
             [active]="conceptStarted()"
             [complete]="conceptComplete()"
             [warning]="conceptWarning()"
             [showCard]="showConceptCard() && stepCardExpanded">
        <div cbStepCard>
            {{getConceptCardText()}}
        </div>
    </cb-step>
    <cb-step label="Pre-Consent"
             [active]="hasPreconsentDocument()"
             [complete]="hasCompletedAPreConsentPlan()"
             [showCard]="showStepCard(LOT_JOB_STATUS_ENUM.PreConsent) && stepCardExpanded">
        <div cbStepCard>
            <p>
                {{getPreConsentStatusCardText()}}
            </p>
            <p>
                {{getPreConsentDocumentCountCardText()}}
            </p>
        </div>
    </cb-step>
    <cb-step label="Working Drawings"
             [active]="hasCreatedFirstWorkingDrawing()"
             [complete]="hasApprovedWorkingDrawing()"
             [showCard]="showStepCard(LOT_JOB_STATUS_ENUM.WorkingDrawings)  && stepCardExpanded">
        <div cbStepCard>
            {{getWorkingDrawingsCardText()}}
        </div>
    </cb-step>
    <cb-step label="Consent"
             [active]="hasPrimaryBuildingConsent()"
             [warning]="isConsentWarning()"
             [complete]="hasGrantedPrimaryBuildingConsent()"
             [showCard]="showStepCard(LOT_JOB_STATUS_ENUM.Consent)  && stepCardExpanded">
        <div cbStepCard>
            <p>
                {{getConsentStatusCardText()}}
            </p>
            <p>
                {{getRfiCardText()}}
            </p>
        </div>
    </cb-step>
    <cb-step label="Build"
             [active]="buildPillActive()"
             [complete]="buildPillComplete()">
    </cb-step>
    <cb-step label="Handover"
             [active]="handoverPillActive()"
             [complete]="handoverPillComplete()">
    </cb-step>
    <button *ngIf="stepCardExpanded || silentData[silentDataKey]"
            mat-icon-button
            (click)="togglePanelPinned($event, silentDataKey)">
        <mat-icon svgIcon="pin"
                  [ngClass]="silentData[silentDataKey] ? 'cb-accent' : 'cb-grey'">
        </mat-icon>
    </button>
    <button mat-icon-button
            class="cb-margin-left-2x"
            (click)="stepCardExpandCollapse($event)">
        <mat-icon *ngIf="!stepCardExpanded">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="stepCardExpanded">keyboard_arrow_up</mat-icon>
    </button>
</cb-stepper>
