<div class="flex-col">
    <div class="flex-row">
        <span class="mat-body-strong cb-margin-top-3x cb-margin-bottom-3x flex">Add Specific Items that the Designer needs to complete as part of this Pre-Consent</span>
        <div>
            <button mat-raised-button
                    color="primary"
                    (cbClick)="addPreConsentPlanItems()"
                    class="cb-margin-top-3x cb-margin-bottom-3x">
                Add Item
            </button>
        </div>
    </div>
    <div style="position: relative;">
        <mat-divider></mat-divider>
    </div>
    <div *ngFor="let item of mappedItem.items">
        <div *ngIf="!item.isDeleted">
            <cb-pre-consent-plan-item [designerView]="false"
                                      [item]="item"
                                      [(mappedItem)]="mappedItem">
            </cb-pre-consent-plan-item>
            <div style="position: relative;">
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
    <cb-info-message class="cb-margin"
                     *ngIf="!mappedItem.items || mappedItem.items.length < 1"
                     message="Add at least one Item for this Pre-Consent"
                     icon="warning"
                     iconClass="cb-warn">
    </cb-info-message>
</div>
