import {Component, HostBinding, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { IDesignSchemesLogicService } from '@app/logic/design-schemes/interfaces/i.design-schemes-logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IDesignSchemeDto, LotTypeEnumId } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-design-scheme-dialog',
    templateUrl: './design-scheme-dialog.component.html',
    styleUrls: ['./design-scheme-dialog.component.scss']
})
export class DesignSchemeDialogComponent extends BaseDialogFormViewDirective<IDesignSchemeDto, IDesignSchemeMappedItem, IDesignSchemesLogicService> {
    @HostBinding('class') class = 'flex-row flex';
    public static readonly MIN_WIDTH = '66%';

    public mode: 'Create' | 'Manage' = 'Create';

    public get mappedItem(): IDesignSchemeMappedItem {
        return this.data.mappedItem;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<DesignSchemeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: IDesignSchemeMappedItem;
            lotTypeEnumId: LotTypeEnumId;
        },
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);

        if (this.mappedItem?.id > 0) {
            this.mode = 'Manage';
        }
    }

}
