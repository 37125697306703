<cb-dialog dialogHeading="Change Concept">
    <form class="flex-col flex"
          #designConceptForm="ngForm">
        <mat-form-field>
            <mat-label>Design Concept</mat-label>
            <mat-select required="true"
                        name="selectedConcept"
                        [(ngModel)]="selectedConcept">
                <mat-option *ngFor="let concept of designConcepts | async"
                            [value]="concept">
                    {{concept.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
    <div actions>
        <button color="primary"
                mat-raised-button
                [disabled]="!designConceptForm.dirty || !designConceptForm.valid"
                (click)="save()">
            Apply Concept
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
