import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { OpenPdfComponent } from './open-pdf.component';

@NgModule({
    declarations: [
        OpenPdfComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        PdfViewerModule,
        MatTooltipModule,
        RouterModule.forRoot([
            {
                component: OpenPdfComponent,
                path: 'open-pdf',
                data: {
                    breadcrumb: 'View PDF',
                }
            },
        ]),
    ]
})
export class OpenPdfModule { }
