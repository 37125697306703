<button mat-mini-fab
        color="accent"
        class="collapse-button"
        (click)="collapsed = !collapsed"
        [matTooltip]="collapsed ? 'Expand' : 'Collapse'">
    <mat-icon>
        {{ collapsed ? 'chevron_right' : 'chevron_left ' }}
    </mat-icon>
</button>
<mat-card *ngIf="collapsed"
          class="collapsed-card">
    <mat-card-content>
        <span class="mat-headline-6 collapsed-title">Variations List</span>
    </mat-card-content>
</mat-card>
<ng-container *ngIf="!collapsed">
    <mat-card class="filter-card">
        <mat-card-content>
            <form class="cb-margin-right-3x start-center flex-row flex">
                <mat-icon class="cb-margin-right-2x">search</mat-icon>
                <cb-input class="flex-100"
                          name="searchText"
                          label="Type in Variation Details"
                          [(ngModel)]="searchQuery"
                          (change)="setFilteredVariationss()">
                </cb-input>
            </form>
        </mat-card-content>
    </mat-card>
    <mat-card class="list-card scroll-container">
        <mat-card-content>
            <cb-info-message *ngIf="filteredVariations.length < 1"
                             message="There were no Variations found"
                             iconClass="cb-accent"></cb-info-message>
            <div class="list-container flex"
                 #listContainer>
                <mat-list class="flex flex-col">
                    <mat-list-item *ngFor="let variation of filteredVariations;"
                                   [ngClass]="{'selected-change-record': currentVariationId === variation.id, 'change-record-list-item': true}">
                        <div class="flex-row flex">
                            <div class="cb-margin-right center-center flex-col">
                                <mat-icon [ngClass]="getStatusIconClass(variation)"
                                          class="help-cursor"
                                          [title]="getStatusIconTitle(variation)">
                                    {{ getStatusIconIcon(variation) }}
                                </mat-icon>
                            </div>
                            <div class="cb-margin-left cb-margin-right flex-col flex">
                                <div matListItemTitle
                                     class="item-line cb-margin-bottom-2x">
                                <span>
                                    <strong>VO#{{ variation.variationNumber }}</strong>
                                </span>
                                    <span *ngIf="showCostChangeAmountGST(variation)">
                                    &nbsp;&nbsp;{{ variation.variationTotal | cbCurrency:'addGst' }} (Inc Gst)
                                </span>
                                    <span *ngIf="!showCostChangeAmountGST(variation)">
                                    &nbsp;&nbsp;{{ variation.variationTotal | cbCurrency }}
                                </span>
                                </div>
                                <div matListItemLine
                                     class="item-line">
                                    <mat-icon class="help-cursor cb-grey cb-margin-right"
                                              [title]="getCostNatureIconTitle(variation.costNature)">
                                        {{ getCostNatureIcon(variation.costNature) }}
                                    </mat-icon>
                                    <span>
                                    {{ variation.costNature | cbEnumToLabel: COST_NATURE_ENUM }}
                                </span>
                                    <mat-icon class="cb-grey">chevron_right</mat-icon>
                                    <span>
                                    {{ variation.variationStatusName }}
                                </span>
                                </div>
                            </div>
                            <div class="center-center flex-row">
                                <button mat-icon-button
                                        (click)="selectVariation(variation)">
                                    <mat-icon>exit_to_app</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </mat-card-content>
    </mat-card>
</ng-container>
