<mat-card>
    <mat-card-content class="layoutGap-8" >
        <button *ngIf="canAddChange()"
                mat-raised-button
                color="primary"
                (click)="createChange()"
                [disabled]="currentUserTeams.length < 1">
            Add Change
        </button>
        <span *ngIf="numberofPendingChangeRecords > 0">
        <button *ngIf="canSubmitChangeForPricing()"
                (click)="submitChanges()"
                mat-raised-button
                color="primary">
            Submit For Pricing ({{ numberofPendingChangeRecords }})
        </button>
    </span>
        <button *ngIf="canCreateVariation()"
                [disabled]="!canGenerateVariations()"
                mat-raised-button
                color="primary"
                (click)="openGenerateVariationDialog($event)">
            Generate Variation ({{ $numberofUnassignedChangeRecords }})
        </button>
        <button *ngIf="canGenerateChangesSignOffDocument()"
                [disabled]="!isGenerateChangesSignOffDocumentEnabled()"
                mat-raised-button
                color="primary"
                (click)="generateChangesSignOffDocument()">
            Generate Changes Signoff Document
        </button>
    </mat-card-content>
</mat-card>
