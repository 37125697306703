<div class="flex-row flex"
     style="overflow-x: auto">
    <div class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex-100">
        <div class="start-center flex-row layoutGap-16">
            <h3 class="mat-headline-6 flex">
                Location
            </h3>
            <form class="header-button">
                <cb-checkbox label="Active Only"
                             name="showActiveOnly"
                             [(ngModel)]="showActiveOnly"
                             (change)="refreshSearchResults()"></cb-checkbox>
            </form>
            <button mat-raised-button
                    (click)="onExportExcelClicked()"
                    color="primary">
                Export Excel
            </button>
            <button mat-raised-button
                    (click)="onNewItemClicked()"
                    color="primary">
                Add Location
            </button>
        </div>
        <div class="cb-table cb-margin-top">
            <mat-table [dataSource]="searchResults">
                <ng-container matColumnDef="region">
                    <mat-header-cell *matHeaderCellDef>
                        Region
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <span *ngIf="item.locationType===1"
                              [ngSwitch]="item.expanded">
                            <button *ngSwitchDefault
                                    mat-icon-button
                                    (click)="expand(item)">
                                <mat-icon>
                                    expand_more
                                </mat-icon>
                            </button>
                            <button *ngSwitchCase="true"
                                    mat-icon-button
                                    (click)="collapse(item)">
                                <mat-icon>
                                    expand_less
                                </mat-icon>
                            </button>
                            <span class="cb-padding-left">
                                {{item.region?.label || item.label}}
                            </span>
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="district">
                    <mat-header-cell *matHeaderCellDef>
                        District
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item"
                              [ngSwitch]="item.expanded">
                        <span *ngIf="item.locationType===2">
                            <button *ngSwitchDefault
                                   mat-icon-button
                                    (click)="expand(item)">
                                <mat-icon>
                                    expand_more
                                </mat-icon>
                            </button>
                            <button *ngSwitchCase="true"
                                    mat-icon-button
                                    (click)="collapse(item)">
                                <mat-icon>
                                    expand_less
                                </mat-icon>
                            </button>
                            <span class="cb-padding-left">
                                {{item.district?.label || item.label}}
                            </span>
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="area">
                    <mat-header-cell *matHeaderCellDef>
                        Area
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <span *ngIf="item.locationType===3">
                            {{item.area?.label || item.label}}
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isActive">
                    <mat-header-cell *matHeaderCellDef>
                        Active
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <mat-icon class="cb-grey">
                            {{item.isActive ? "check_box" : "check_box_outline_blank"}}
                        </mat-icon>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="businessEntity">
                    <mat-header-cell *matHeaderCellDef>
                        Business Entity
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.businessEntity?.label}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="addressRegion">
                    <mat-header-cell *matHeaderCellDef>
                        Address Region
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.addressRegion?.label}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <button mat-icon-button
                                (click)="editItemClicked(item)">
                            <mat-icon>
                                edit
                            </mat-icon>
                        </button>
                        <button *ngIf="item.locationType!==3"
                                mat-icon-button
                                (click)="onNewItemClicked(item.locationType+1, item.id)">
                            <mat-icon>
                                add
                            </mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <cb-info-message *ngIf="searchResultsLoaded && searchResults.length === 0"
                             [message]="'There are no Locations to display'"
                             [icon]="'info_circle'"></cb-info-message>
            <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
        </div>
    </div>
</div>
