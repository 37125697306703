import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IIncompleteBuildActivitySearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { BuildProgrammeActivityLogicService } from '@app/logic/build-programme-activity';
import { IPurchaseOrderSearchDto, PurchaseOrdersLogicService } from '@app/logic/purchase-orders';
import { IPurchaseOrderSearchParams } from '@app/logic/purchase-orders/purchase-orders.logic.service';
import { SsrsLogicService } from '@app/logic/ssrs';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { ISearchResult } from '@app/shared/components/search/i.search';
import {
    CompleteMultipleActivitiesDialogComponent
} from '@app/views/teams/all-teams/incomplete-build-activities/complete-multiple-activities-dialog/complete-multiple-activities-dialog.component';
import { INVOICE_STATUS_ENUM, IStringIdAndLabelDto, IUserDetailsDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { USER_CACHE_AREA } from 'cb-hub-lib';
import { Observable, Subject, Subscription, BehaviorSubject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';

@Component({
    selector: 'cb-incomplete-build-activities',
    templateUrl: './incomplete-build-activities.component.html',
    styleUrls: ['./incomplete-build-activities.component.scss']
})
export class IncompleteBuildActivitiesComponent extends BaseSearchViewDirective<IIncompleteBuildActivitySearch> implements OnInit, OnDestroy {

    public results: IPurchaseOrderSearchDto[] = [];
    public currentPage: number;
    public constructionManagerFullInfoList: IUserDetailsDto[];
    public constructionManagers: IStringIdAndLabelDto[];
    public selectedConstructionManager: string;
    public readonly searchFiltersChanged$ = new Subject();
    public searchFiltersLoaded$ = new Subject();
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    /** checked document entity ids */
    public checkedPurchaseOrderIds: { [purchaseOrderId: number]: boolean } = {};
    public allChecked = false;
    public searchEnabled = false;
    public loaded = false;
    private _subscriptions = new Subscription();

    public get searchFilters(): UserCacheItem<IIncompleteBuildActivitySearch> {
        return this.userCacheService.incompleteBuildActivitySearch;
    }
    public readonly invoiceStatusList = INVOICE_STATUS_ENUM.toSelectList().map((x) => ({
        checked: false,
        disabled: false,
        // Have to map 0 and 1 to false and true because endpoint needs bool values
        id: x.id >= 0 ? x.id === 1 : null,
        label: x.label,
    }));
    public readonly orders = [
        {
            id: 0,
            label: 'PO Number (Smallest > Largest)'
        },
        {
            id: 'enddate',
            label: 'Activity End Date (Closest >  Furthest)'
        }
    ];

    public readonly displayedColumns: string[] = [
        'PO #',
        'Job Number',
        'Lot Number + Street',
        'Client',
        'Activity',
        'End Date',
        'Supplier',
        'Construction Manager',
        'PO Value',
        'Compliance',
        'Invoice Status',
        '',
    ];

    constructor(
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly cbDialog: CbDialogService,
        public readonly purchaseOrderLogic: PurchaseOrdersLogicService,
        public readonly toastService: ToastService,
        public readonly userCacheService: UserCacheService,
        private readonly navigationService: NavigationService,
        private readonly currentUser: CurrentUserService,
        private readonly ssrLogic: SsrsLogicService,
        private readonly buildProgrammeActivityLogic: BuildProgrammeActivityLogicService,
        private readonly teamsLogic: TeamsLogicService,
        private readonly cdRef: ChangeDetectorRef
    ) {
        super(
            purchaseOrderLogic,
            toastService,
            userCacheService,
            USER_CACHE_AREA.IncompleteBuildActivities
        );
    }

    public ngOnInit(): void {
        this.searchFilters.init().then(() => {
            this.loadConstructionManagers().subOnce((userDetailDtoList) => {
                this.constructionManagerFullInfoList = userDetailDtoList;
                this.constructionManagers = userDetailDtoList
                    .map(x => ({
                        id: x.id,
                        label: x.firstName + ' ' + x.lastName,
                    }));

                this.selectedConstructionManager = null;
                if (this.constructionManagers?.length > 0) {
                    // If our user is present in the list, then set us as the selected user
                    // Otherwise, default to the first user in the list
                    const selectedIndex = this.constructionManagers.findIndex(x => x.id === this.currentUser.guid);
                    this.selectedConstructionManager = selectedIndex > -1
                        ? this.constructionManagers[selectedIndex].id
                        : this.constructionManagers[0].id;
                }

                this.searchFilters.data.constructionManagerId = [this.selectedConstructionManager];
                this.searchFilters.data.businessEntityId = null;
                this.searchFiltersLoaded$.next(true);
                this.searchEnabled = true;
                this._subscriptions.add(
                    this.searchFilters.updated$.subscribe({
                        next: this._onSearchFiltersChanged
                    })
                );
            });
        });
    }

    public search(): Observable<ISearchResult<IPurchaseOrderSearchDto>> {
        if (!this.searchEnabled){
            return;
        }
        this.loaded = true;
        return this.purchaseOrderLogic.$getSearchList(this.getSearchParams());
    }

    protected getSearchParams(): IPurchaseOrderSearchParams & IIncompleteBuildActivitySearch {
        return {
            ...this.searchFilters.copyData(),
            constructionManagerId: this.searchFilters.data.constructionManagerId,
            constructionManagerTags: this.getConstructionManagerTagList(),
            businessEntityId: this.getConstructionManagerBusinessEntityList(),
            invoiceReceived: this.searchFilters.data.invoiceReceived,
            currentPage: this.currentPage
        };
    }

    public markComplete(purchaseOrder: IPurchaseOrderSearchDto): void {
        this.cbDialog.confirm({
            dialogHeading: 'Complete Activity',
            message: 'Are you sure you want to complete this PO?',
            confirmed: () => {
                if (purchaseOrder.isManualOrder) {
                    this.buildProgrammeActivityLogic
                        .completeManualOrder(purchaseOrder.buildProgrammeActivityId)
                        .subOnce(() => this.removePurchaseOrderResult(purchaseOrder.id));
                } else {
                    this.ssrLogic
                        .completeSsr(purchaseOrder.ssrId)
                        .subOnce(() => this.removePurchaseOrderResult(purchaseOrder.id));
                }
            }
        });
    }

    public viewActivity(purchaseOrder: IPurchaseOrderSearchDto, event: MouseEvent): void {
        this.navigationService.navigate([`/lots/${purchaseOrder.lotId}/build/programme`], event, {
            queryParams: {
                buildProgrammeActivityId: purchaseOrder.buildProgrammeActivityId,
            }
        });
    }

    public downloadPo(purchaseOrder: IPurchaseOrderSearchDto): void {
        if (purchaseOrder.isManualOrder) {
            this.purchaseOrderLogic
                .downloadManualPO(purchaseOrder.id)
                .subOnce();
        } else {
            this.purchaseOrderLogic
                .downloadSsrPO(purchaseOrder.ssrId)
                .subOnce();
        }
    }

    public readonly removePurchaseOrderResult = (purchaseOrderId: number): void => {
        const index = this.results.findIndex(x => x.id === purchaseOrderId);
        if (index > -1) {
            this.results.splice(index, 1);
            this.cdRef.detectChanges();
        }
    };

    public selectConstructionManager(): void {
        this.searchFilters.data.constructionManagerId = [this.selectedConstructionManager];
        this.checkedPurchaseOrderIds ={};
        this.allChecked = false;
    }

    private loadConstructionManagers(): Observable<IUserDetailsDto[]> {
        // Get the list of allowed construction managers we can see
        // Depending on the tags your user has, this list varies greatly
        return this.teamsLogic.getConstructionManagersAllowedByUser(this.currentUser.guid);
    }

    private getConstructionManagerTagList(): string[] {
        if (!this.constructionManagerFullInfoList) {
            return [];
        }

        const constructionManagerId = (this.searchFilters.data.constructionManagerId)
            ? this.searchFilters.data.constructionManagerId.toString()
            : [];

        const index = this.constructionManagerFullInfoList.findIndex(x => x.id === constructionManagerId);
        if (index === -1) {
            return [];
        }

        return this.constructionManagerFullInfoList[index].selectedTags.map(x => x.code);
    }

    private getConstructionManagerBusinessEntityList(): number[] {
        if (!this.constructionManagerFullInfoList) {
            return [];
        }

        const constructionManagerId = (this.searchFilters.data.constructionManagerId)
            ? this.searchFilters.data.constructionManagerId.toString()
            : [];

        const index = this.constructionManagerFullInfoList.findIndex(x => x.id === constructionManagerId);
        if (index === -1) {
            return [];
        }

        return this.constructionManagerFullInfoList[index].selectedBusinessEntities;
    }

    public get completeMultipleLabel(): string {
        return 'Complete Selected';
    }

    public get isAnyPurchaseOrderSelected(): boolean {
        return Object.values(this.checkedPurchaseOrderIds)?.some(x => x);
    }

    public get toggleAllLabel(): string {
        return this.allChecked ? 'Deselect All' : 'Select All';
    }

    public toggleAllChecked(event: MouseEvent | null, value = !this.allChecked): void {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.allChecked = value;
        if (this.allChecked) {
            this.results.forEach(x => this.checkedPurchaseOrderIds[x.id] = true);
        } else {
            this.results.forEach(x => this.checkedPurchaseOrderIds[x.id] = false);
        }
    }

    public completeAllChecked(): void {
        const dialog = this.cbDialog.open(CompleteMultipleActivitiesDialogComponent, {
            data: {
                purchaseOrders: this.results.filter(x => this.checkedPurchaseOrderIds[x.id]),
                prefixColumns: this.displayedColumns,
            },
            minWidth: 980,
            maxWidth: '95%',
        });

        dialog.afterClosed().subOnce(() => {
            this.searchFilters.data.constructionManagerId = null;
            this.selectConstructionManager();
        });
    }

    public selectedInvoiceStatusChanged(selectSingleElement: UntypedFormControl): void {
        this.searchFilters.data.invoiceReceived = selectSingleElement.value;
    }

    private readonly _onSearchFiltersChanged = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.searchFiltersChanged$.next(null);
    };

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }
}
