import {Component, HostBinding, Input} from '@angular/core';
import {
    IBuildProgrammeActivityMappedItem,
} from '@app/logic/build-programme-activity';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import {
    EditPredecessorBuildProgrammeActivityDialogComponent
} from '../edit-predecessor-build-programme-activity-dialog/edit-predecessor-build-programme-activity-dialog.component';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { throwError } from 'rxjs';
import { IBuildProgrammeActivityRelationshipsDto } from '@classictechsolutions/hubapi-transpiled-enums/build/module';
import { IBuildProgrammeActivityRelatedActivityDto } from '@classictechsolutions/hubapi-transpiled-enums/build/module/lib/dtos/BuildProgrammeActivityRelatedActivityDto';

@Component({
    selector: 'cb-build-programme-activity-relationships',
    templateUrl: './build-programme-activity-relationships.component.html',
    styleUrls: ['./build-programme-activity-relationships.component.scss'],
    viewProviders: [
        provideParentForm(),
    ]
})
export class BuildProgrammeActivityRelationshipsComponent {

    /** Clone */
    @Input() public readonly buildProgrammeActivity: IBuildProgrammeActivityMappedItem;
    @Input() public readonly activityRelationships: IBuildProgrammeActivityRelationshipsDto;
    @HostBinding('class') class = 'flex-row';

    public readonly successorColumns: string[] = ['Lot Number', 'Code', 'Activity Name', 'Relationship Type', 'Lag Days', 'Locked'];

    constructor(
        private readonly cbDialog: CbDialogService,
    ) { }

    public addPredecessor(): void {
        this.editPredecessor();
    }

    public editPredecessor(oldPredecessorBuildProgrammeActivity?: IBuildProgrammeActivityRelatedActivityDto): void {
        this.cbDialog
            .open(EditPredecessorBuildProgrammeActivityDialogComponent,
                {
                    data: {
                        descendantBuildProgrammeActivity: this.buildProgrammeActivity,
                        oldPredecessorBuildProgrammeActivity
                    },
                    minWidth: '40%',
                })
            .afterClosed()
            .subOnce((result: IBuildProgrammeActivityRelatedActivityDto) => {
                if (result?.id > 0) {
                    this.activityRelationships.predecessors[0] = result;
                }
            });
    }

    public clearPredecessor(predecessorId: number): void {
        this.cbDialog.confirm({
            dialogHeading: 'Clear Predecessor Relationship',
            message: 'Are you sure you want to clear the Predecessor Relationship?',
            confirmed: () => {
                this.buildProgrammeActivity
                    .clearPredecessorRelationship(predecessorId)
                    .subOnce({
                        next: () => {
                            this.activityRelationships.predecessors = [];
                        },
                        error: throwError,
                    });
            }
        });
    }
}
