import { BaseMappedItem } from '../base/base-mapped-item';
import { DtoProp } from '../base/dto-prop.decorator';
import {IBuildMilestoneTemplateMappedItem} from "@app/logic/build-milestone-templates/interfaces/i.build-milestone-template.mapped";
import {IBuildMilestoneTemplateLogicService} from "@app/logic/build-milestone-templates/interfaces/i.build-milestone-template.logic-service";
import {IBuildMilestoneTemplateDto} from "@classictechsolutions/hubapi-transpiled-enums";

export class BuildMilestoneTemplateMappedItem
    extends BaseMappedItem<IBuildMilestoneTemplateDto, IBuildMilestoneTemplateMappedItem, IBuildMilestoneTemplateLogicService>
    implements IBuildMilestoneTemplateMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public code: string;
    @DtoProp public name: string;
    @DtoProp public formula: string;
    @DtoProp public isActive: boolean;

    constructor(
        sourceData: IBuildMilestoneTemplateDto,
        logicService: IBuildMilestoneTemplateLogicService
    ) {
        super(sourceData, logicService, BuildMilestoneTemplateMappedItem);
    }


}
