<mat-tab-group *ngIf="!lotMappedItem.hasUnits" mat-stretch-tabs="false">
    <mat-tab label="Exterior">
        <div class="flex-row flex"
             [ngClass]="{'cb-image-button': isValidExteriorImage}"
             (click)="viewImage(isValidExteriorImage, lotMappedItem.exteriorImageUrl, 'Exterior Image')">
            <img #exteriorImage
                 mat-card-image
                 [src]="lotMappedItem.exteriorImageUrl"
                 cbHandleInvalidImage
                 (error)="handleInvalidExteriorImage()"
                 alt="Exterior Image"/>
            <div *ngIf="isValidExteriorImage"
                 class="bottom-right end-center flex-row">
                <mat-icon class="cb-image-button-icon">zoom_in</mat-icon>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Floor Plan">
        <div class="flex-row flex"
             [ngClass]="{'cb-image-button': isValidFloorPlanImage}"
             (click)="viewImage(isValidFloorPlanImage, lotMappedItem.floorPlanImageUrl, 'Floor Plan Image')">
            <img #floorPlanImage
                 mat-card-image
                 [src]="lotMappedItem.floorPlanImageUrl"
                 cbHandleInvalidImage
                 (error)="handleInvalidFloorPlanImage()"
                 alt="Floor Plan Image"/>
            <div *ngIf="isValidFloorPlanImage"
                 class="bottom-right end-center flex-row">
                <mat-icon class="cb-image-button-icon">zoom_in</mat-icon>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
