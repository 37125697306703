<mat-card class="flex-row layoutGap-10">
    <mat-card-content class="flexible-gap">
        <button mat-raised-button
                (click)="backToWorkingDrawings()">
            <mat-icon>arrow_back</mat-icon>
            &nbsp;Back to Working Drawings
        </button>
        <button mat-raised-button
                color="primary"
                (click)="reviewWorkingDrawing()"
                [disabled]="!canReviewWorkingDrawing()">
            <mat-icon>person</mat-icon>
            Review
        </button>
        <button mat-raised-button
                color="primary"
                (click)="manageWorkingDrawing()"
                [disabled]="!(canManageWorkingDrawing() && canEditWorkingDrawing())">
            <mat-icon>edit</mat-icon>
            Manage
        </button>
        <div *ngIf="canResubmit()">
            <button mat-raised-button
                    color="primary"
                    (click)="resubmit()"
                    [disabled]="!isResubmitWorkingDrawingsEnabled">
                <mat-icon>keyboard_return</mat-icon>
                Resubmit
            </button>
        </div>
        <div *ngIf="!canResubmit()">
            <button mat-raised-button
                    color="primary"
                    [matMenuTriggerFor]="statusMenu"
                    [disabled]="!canManageWorkingDrawing()"
                    (click)="loadDocuments()">
                <mat-icon>arrow_drop_down</mat-icon>
                Working Drawing Status
            </button>
        </div>
        <mat-menu #statusMenu="matMenu">
            <button mat-menu-item
                    (click)="setStatusAndSave(WORKING_DRAWING_STATUS_ENUM.InQueue)"
                    [disabled]="!canChangeWorkingDrawingStatus()">
                <mat-icon color="accent">timelapse</mat-icon>
                In Queue
            </button>
            <button mat-menu-item
                    (click)="setStatusAndSave(WORKING_DRAWING_STATUS_ENUM.InProgress)"
                    [disabled]="!canSetWorkingDrawingInProgress()">
                <mat-icon color="warn">check</mat-icon>
                In Progress
            </button>
            <button mat-menu-item
                    (click)="setStatusAndSave(WORKING_DRAWING_STATUS_ENUM.OnHold)"
                    [disabled]="!canChangeWorkingDrawingStatus()">
                <mat-icon color="primary">pause</mat-icon>
                On Hold
            </button>
            <div *ngIf="featureToggleStateService.isWorkingDrawingRejectionsEnabled">
                <button mat-menu-item
                        (click)="rejectWorkingDrawing()"
                        [disabled]="cannotRejectWorkingDrawing() || !canRejectWorkingDrawingRequest()">
                    <mat-icon color="primary">keyboard_return</mat-icon>
                    Reject
                </button>
            </div>
            <button mat-menu-item
                    (click)="finaliseWorkingDrawing()"
                    [disabled]="!canFinaliseWorkingDrawing()">
                <mat-icon color="primary">send</mat-icon>
                Finalise
            </button>
            <button mat-menu-item
                    (click)="cancelWorkingDrawing()"
                    [disabled]="!canCancelWorkingDrawing()">
                <mat-icon color="primary">cancel</mat-icon>
                Cancel
            </button>
        </mat-menu>
        <button *ngIf="canRequestTakeoff()"
                mat-raised-button
                color="primary"
                (click)="requestTakeoff()">
            <mat-icon>monetization_on</mat-icon>
            Request Takeoff
        </button>
        <button mat-raised-button
                color="primary"
                (click)="downloadConsentDocuments()"
                [disabled]="false">
            <mat-icon>insert_drive_file</mat-icon>
            Download Consent Documents
        </button>
    </mat-card-content>
</mat-card>
