import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import * as moment from 'moment';

@Component({
    selector: 'cb-user-date-time-new',
    templateUrl: './user-date-time-new.component.html',
    styleUrls: ['./user-date-time-new.component.scss']
})
export class UserDateTimeNewComponent implements OnInit, OnChanges {

    @Input() public label: string;
    @Input() public date: string;
    @Input() public displayTime = false;
    @Input() public userFullName: string;
    @Input() public format: string;
    @Input() public floatingLabel = false;
    @Input() public textClass = 'mat-body-2';

    private readonly defaultFormat = 'DD MMM YYYY';
    private readonly timeFormat = 'h:mma';

    public readonly userFullNameDiplay = new ComputedProperty(() => {
        let returnValue;
        if (this.date && this.userFullName) {
            returnValue = `${this.userFullName} on `;
        }
        return returnValue;
    });

    public readonly formattedDate = new ComputedProperty(() => {
        if (!this.date) {
            return '-';
        }
        let theDate = '';
        const format = this.format || this.defaultFormat;
        if (this.displayTime) {
            theDate = moment(this.dateAsDate).format(`${format} ${this.timeFormat}`);
        } else {
            theDate = moment(this.dateAsDate).format(format);
        }
        return theDate.toString();
    });

    private get dateAsDate(): Date {
        return typeof (this.date) === 'string' ? new Date(this.date) : this.date;
    }

    public ngOnInit(): void {
        if (this.displayTime === undefined) {
            this.displayTime = true;
        }
    }

    public ngOnChanges(): void {
        this.recomputeProps();
    }

    private recomputeProps(): void {
        this.userFullNameDiplay.recompute();
        this.formattedDate.recompute();
    }
}
