import { Component, OnInit, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { LotBuildPermissions } from '@app/core/permissions';
import { ISsrMappedItem } from '@app/logic/ssrs';
import { IBuildProgrammeActivityMappedItem } from '@app/logic/build-programme-activity';
import { ISsrDataLineDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/SsrDataLineDto';

interface IData {
    lineItem: ISsrDataLineDto;
    mappedItem: ISsrMappedItem;
    buildProgramActivity: IBuildProgrammeActivityMappedItem;
}

@Component({
    templateUrl: './edit-ssr-line-dialog.component.html',
    styleUrls: ['./edit-ssr-line-dialog.component.scss'],
})
export class EditSsrLineDialogComponent
    extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '420px';

    public get mappedItem(): ISsrMappedItem {
        return this.data.mappedItem;
    }

    public get lineItem(): ISsrDataLineDto {
        return this.data.lineItem;
    }

    public lineItemQuantity = 0;
    public calculatedUnallocatedQuantity: number;
    public totalQuantityAvailable = 0;

    constructor(
        public readonly dialogRef: MatDialogRef<EditSsrLineDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(LotBuildPermissions) public readonly lotBuildPermissions: LotBuildPermissions,
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.lineItemQuantity = this.lineItem.quantity;
        this.calculatedUnallocatedQuantity = this.lineItem.unallocatedQuantity;
        this.totalQuantityAvailable = this.lineItemQuantity + this.calculatedUnallocatedQuantity;
    }

    public saveQuantity(): void {
        if (!this.canUpdateQuantity()) {
            return;
        }
        this.saveInProgress = true;
        this.mappedItem
            .updateLineItemQuantity(this.lineItem.id, this.lineItemQuantity)
            .subOnce((ssrLineItem) => {
                this.saveInProgress = false;
                this.data.buildProgramActivity.$reload().subOnce();
                this.dialogRef.close(ssrLineItem);
            });
    }

    public canUpdateQuantity(): boolean {
        return this.lineItemQuantity >= 0
            && this.lineItemQuantity !== this.lineItem.quantity
            && this.lineItemQuantity >= this.lineItem.minimumQuantityToRetain;
    }

    public validateQuantityChanged = (newValue: number): number => {
        this.calculatedUnallocatedQuantity = this.totalQuantityAvailable - Number(newValue) ?? 0;
        if (this.calculatedUnallocatedQuantity < 0) {
            this.calculatedUnallocatedQuantity = 0;
            return this.totalQuantityAvailable;
        }
        return newValue;
    };
}
