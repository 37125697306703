<cb-dialog dialogHeading="Sale Details">
    <form class="flex-col flex"
          #saleDetailsForm="ngForm">
        <cb-user-autocomplete-scroller *ngIf="isDesignAndBuild.value || isThirdPartyHouseAndLand.value"
                                       class="cb-margin-top flex"
                                       label="Building Consultant"
                                       name="buildingConsultant"
                                       [(ngModel)]="selectedBuildingConsultant"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [active]="true"
                                       [tag]="BUILDING_CONSULTANT"
                                       [required]="true"></cb-user-autocomplete-scroller>
        <cb-select label="Purchase Reason"
                   name="purchaseReason"
                   [required]="true"
                   [(ngModel)]="clientSaleDetails.purchaseReasonId"
                   [options]="purchaseReasonLookup">
        </cb-select>
        <cb-input type="text"
                  label="Insured Party Name"
                  [softRequired]="true"
                  name="insuredPartyName"
                  [(ngModel)]="clientSaleDetails.insuredPartyName"></cb-input>
        <cb-input type="email"
                  label="Insured Party Email"
                  [softRequired]="true"
                  name="insuredPartyEmail"
                  [(ngModel)]="clientSaleDetails.insuredPartyEmail"></cb-input>
        <cb-checkbox name="allowNominee"
                     label="Allow Nominee"
                     [(ngModel)]="clientSaleDetails.allowNominee"></cb-checkbox>
        <cb-input type="number"
                  class="cb-margin-top"
                  label="Build Duration (Weeks)"
                  name="buildDuration"
                  [(ngModel)]="clientSaleDetails.buildDuration"></cb-input>
        <cb-datepicker *ngIf="isHouseAndLand || isDesignAndLand"
                       label="Sunset Date"
                       name="sunsetDate"
                       [(ngModel)]="clientSaleDetails.sunsetDate"></cb-datepicker>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="!saleDetailsForm.dirty || !saleDetailsForm.valid"
                (click)="save()">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
