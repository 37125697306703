<div class="flex">
    <div class="dashboard-logo"></div>
    <div class="dashboard-container flex-row flex">
        <div *ngIf="widgetsLoaded && !widgets.length"
             class="cb-margin no-widgets-message start-center flex-row">
            <mat-icon class="mat-accent">info_circle</mat-icon>
            <span class="cb-margin-left">You do not have any widgets configured</span>
        </div>
        <div *ngFor="let column of columns"
             class="dashboard-columns flex-col flex">
            <mat-card *ngFor="let widget of getWidgetsInColumn(column)"
                      class="dashboard-widget"
                      [ngStyle]="{'min-height': widget + 'px'}">
                <mat-card-content>
                    <button *ngIf="isEditing$.value"
                            mat-icon-button
                            aria-label="Remove Widget"
                            color="warn"
                            class="remove-widget-button cb-error-bg"
                            (click)="onRemoveWidgetClicked(widget)">
                        <mat-icon>close</mat-icon>
                    </button>
                    <cb-dashboard-widget-document-list *ngIf="widget.type === DASHBOARD_WIDGET.DocumentsRequiringAction"
                                                       [widget]="widget">
                    </cb-dashboard-widget-document-list>
                    <cb-dashboard-widget-grouped-action-list
                        *ngIf="widget.type === DASHBOARD_WIDGET.PreconsentDocumentsRequiringAction"
                        [widget]="widget"
                        [label]="'Outstanding Preconsent Documents'">
                    </cb-dashboard-widget-grouped-action-list>
                    <cb-dashboard-widget-action-list
                        *ngIf="widget.type === DASHBOARD_WIDGET.DesignSchemesRequiringAction"
                        [widget]="widget"
                        [label]="'Design Schemes'">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list
                        *ngIf="widget.type === DASHBOARD_WIDGET.PricingRevisionsRequiringAction"
                        [widget]="widget"
                        [label]="'Pricing Revisions'">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list
                        *ngIf="widget.type === DASHBOARD_WIDGET.WorkingDrawingsRequiringAction"
                        [widget]="widget"
                        [label]="'Working Drawings'">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list
                        *ngIf="widget.type === DASHBOARD_WIDGET.ChangeRecordsRequiringAction"
                        [widget]="widget"
                        [label]="'Change Records'">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list *ngIf="widget.type === DASHBOARD_WIDGET.CouncilRfisRequiringAction"
                                                     [widget]="widget"
                                                     [label]="'Council RFIs'">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list
                        *ngIf="widget.type === DASHBOARD_WIDGET.BusinessAccountsRequiringAction"
                        [widget]="widget"
                        [label]="'Business Accounts'">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list *ngIf="widget.type === DASHBOARD_WIDGET.QuestionsRequiringAction"
                                                     [widget]="widget"
                                                     [label]="'Questions'"
                                                     [displayStatus]="false">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list
                        *ngIf="widget.type === DASHBOARD_WIDGET.TakeOffTasksRequiringAction"
                        [widget]="widget"
                        [label]="'Takeoffs'"
                        [displayStatus]="true">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list *ngIf="widget.type === DASHBOARD_WIDGET.BaseTasksRequiringAction"
                                                     [widget]="widget"
                                                     [label]="'Tasks'"
                                                     [displayStatus]="false"
                                                     [showDueDate]="true">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list
                        *ngIf="widget.type === DASHBOARD_WIDGET.ManualPurchaseOrdersRequiringApproval"
                        [widget]="widget"
                        [label]="'Manual Orders Requiring Approval'"
                        [overrideLabel]="true"
                        [displayStatus]="false">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list *ngIf="widget.type === DASHBOARD_WIDGET.OutstandingLotVariations"
                                                     [widget]="widget"
                                                     [label]="'Outstanding Lot Variations'"
                                                     [overrideLabel]="true"
                                                     [displayStatus]="false">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list *ngIf="widget.type === DASHBOARD_WIDGET.RequiredQuotesAndPCSums"
                                                     [widget]="widget"
                                                     [label]="'Required Quotes and Unallocated PC Sums'"
                                                     [overrideLabel]="true"
                                                     [displayStatus]="false">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list
                        *ngIf="widget.type === DASHBOARD_WIDGET.LotDesignConceptsRequiringAction"
                        [widget]="widget"
                        [label]="'Concepts Requiring Action'"
                        [overrideLabel]="true"
                        [displayStatus]="false">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list *ngIf="widget.type === DASHBOARD_WIDGET.LotConsentsRequiringAction"
                                                     [widget]="widget"
                                                     [label]="'Consents Requiring Action'"
                                                     [overrideLabel]="true"
                                                     [displayStatus]="false">
                    </cb-dashboard-widget-action-list>
                    <cb-dashboard-widget-action-list *ngIf="widget.type === DASHBOARD_WIDGET.PreconsentsRequiringAction"
                                                     [widget]="widget"
                                                     [label]="'Preconsents Requiring Action'"
                                                     [overrideLabel]="true"
                                                     [displayStatus]="false">
                    </cb-dashboard-widget-action-list>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="dashboard-controls end-end flex-row flex">
            <div class="start-end flex-row flex"
                 *ngIf="isEditing$.value">
                <div *ngIf="hasNoWidget"
                     class="dashboard-tooltips add">Add Widget
                </div>
                <button mat-mini-fab
                        (click)="onAddWidgetClicked()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="start-end flex-col flex"
                 style="width: 70px;">
                <div *ngIf="hasNoWidget"
                     class="dashboard-tooltips info">
                    <div *ngIf="!isEditing$.value">Configure your widgets!</div>
                    <div *ngIf="isEditing$.value">Finish configuring your widgets</div>
                </div>
                <button mat-fab
                        color="accent"
                        (click)="isEditing$.next(!isEditing$.value)">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
