<div class="flex-row flex"
     *cbWaitFor="mappedItem">
    <div class="flex-col flex">
        <div class="flex-row">
            <div class="cb-margin-right-2x flex-col flex">
                <cb-select name="designComplexity"
                           label="Design Complexity"
                           [options]="designComplexities"
                           [required]="true"
                           [ngModel]="designComplexityId"
                           (ngModelChange)="updatedDesignComplexity($event)"></cb-select>
            </div>
            <div class="flex-col flex">
                <cb-datepicker label="Due Date"
                               name="dueBy"
                               pickerType="calendar"
                               [required]="true"
                               [(ngModel)]="mappedItem.dueBy"></cb-datepicker>
            </div>
        </div>
        <cb-input type="number"
                  class="cb-margin-top"
                  label="Target Hours"
                  name="targetWorkHours"
                  [(ngModel)]="mappedItem.targetWorkHours"></cb-input>
        <cb-user-autocomplete-scroller [team]="DESIGN_TEAM"
                                       label="Assigned To"
                                       name="assignedToUserId"
                                       class="form-line flex-initial"
                                       [(ngModel)]="assignedUser"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [required]="mappedItem.id > 0"
                                       [active]="true"
                                       (change)="assignedChanged()"></cb-user-autocomplete-scroller>
        <cb-checkbox name="designReviewRequired"
                     label="Design Review Required"
                     [(ngModel)]="mappedItem.reviewRequired"></cb-checkbox>
        <cb-user-autocomplete-scroller *ngIf="mappedItem.reviewRequired"
                                       [tag]="DESIGN_SUPERVISOR"
                                       label="Reviewer"
                                       name="reviewerId"
                                       class="flex-initial"
                                       [(ngModel)]="reviewerUser"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [required]="true"
                                       [active]="true"
                                       (change)="reviewerChanged()"></cb-user-autocomplete-scroller>
        <span class="flex"></span>
    </div>
    <mat-divider class="cb-margin"
                 [vertical]="true"></mat-divider>
    <div class="cb-margin flex-col flex">
        <div class="flex-row flex">
            <div class="cb-margin flex-col flex">
                <cb-select name="buildTypeId"
                           label="Build Type"
                           [options]="buildTypes"
                           [required]="true"
                           [(ngModel)]="mappedItem.buildTypeId"></cb-select>
            </div>
            <div class="cb-margin flex-col flex">
                <cb-standard-plan-autocomplete-scroller
                    #autocompleteControl
                    label="Search Standard Plans"
                    [(ngModel)]="selectedStandardPlan"
                    (optionSelected)="standardPlanChanged()"
                    name="categories"></cb-standard-plan-autocomplete-scroller>
            </div>
        </div>
        <div class="flex-row flex">
            <div class="cb-margin flex-col flex">
                <cb-input class="flex-100"
                          name="floorArea"
                          [label]="'Floor Area'"
                          type="number"
                          [required]="true"
                          [suffix]="'m²'"
                          [(ngModel)]="mappedItem.amenities.floorArea">
                </cb-input>
            </div>
            <div class="cb-margin flex-col flex">
                <cb-input class="flex-100"
                          name="landArea"
                          [label]="'Land Area'"
                          [suffix]="'m²'"
                          type="number"
                          [required]="true"
                          [(ngModel)]="mappedItem.amenities.landArea">
                    m²
                </cb-input>
            </div>
        </div>
        <div class="flex-row flex">
            <div class="cb-margin flex-col flex">
                <cb-input class="flex-100"
                          name="noBedrooms"
                          [label]="'Bedrooms'"
                          [pattern]="positiveIntegerPattern"
                          type="number"
                          [required]="true"
                          [(ngModel)]="mappedItem.amenities.noBedrooms">
                </cb-input>
            </div>
            <div class="cb-margin flex-col flex">
                <cb-input class="flex-100"
                          name="noBathrooms"
                          [label]="'Bathrooms'"
                          [pattern]="positiveIntegerPattern"
                          type="number"
                          [required]="true"
                          [(ngModel)]="mappedItem.amenities.noBathrooms">
                </cb-input>
            </div>
        </div>
        <div class="flex-row flex">
            <div class="cb-margin flex-col flex">
                <cb-input class="flex-100"
                          name="noLivingRooms"
                          [label]="'Living Rooms'"
                          [pattern]="positiveIntegerPattern"
                          type="number"
                          [required]="true"
                          [(ngModel)]="mappedItem.amenities.noLivingRooms">
                </cb-input>
            </div>
            <div class="cb-margin flex-col flex">
                <cb-garage-select name="garages"
                                  label="Garages"
                                  [(ngModel)]="mappedItem.amenities.noGarages"
                                  [required]="true"></cb-garage-select>
            </div>
        </div>
        <div class="flex-row flex">
            <div class="cb-margin flex-col flex-50">
                <cb-input name="noStudy"
                          [label]="'Study'"
                          [pattern]="positiveIntegerPattern"
                          type="number"
                          [required]="true"
                          [(ngModel)]="mappedItem.amenities.noStudy">
                </cb-input>
            </div>
        </div>
        <div class="flex-row flex">
            <div class="cb-margin flex-col flex">
                <cb-checkbox name="resourceConsentRequired"
                             label="Resource Consent Required"
                             [(ngModel)]="mappedItem.resourceConsentRequired"></cb-checkbox>
            </div>
            <div class="cb-margin flex-col flex">
                <cb-user-autocomplete-scroller [tag]="approverTags"
                                               label="Approver"
                                               name="approverUserId"
                                               [(ngModel)]="approverUser"
                                               [requireMatch]="true"
                                               [matchProps]="['id']"
                                               [required]="true"
                                               [active]="true"
                                               (change)="approverChanged()">
                </cb-user-autocomplete-scroller>
            </div>
        </div>
    </div>
</div>
