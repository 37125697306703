import { Component, OnInit, Inject } from '@angular/core';
import { SsrStateEnumId, SSR_STATE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LotBuildProgrammeEventService } from '@app/views/lot/build/services/lot-build-programme-event.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { BlockingDialogComponent } from '@app/shared/components/dialog/blocking-dialog/blocking-dialog.component';
import { IBuildProgrammeActivityMappedItem } from '@app/logic/build-programme-activity';
import { Observable } from 'rxjs';

interface IData {
    targetSsrState: SsrStateEnumId;
    buildStageId: number;
}

@Component({
    templateUrl: './complete-build-programme-activities-dialog.component.html',
    styleUrls: ['./complete-build-programme-activities-dialog.component.scss']
})
export class CompleteBuildProgrammeActivitiesDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '40%';
    public static readonly BLOCK_MESSAGE = 'Please wait... your data is being processed';

    public activities: IBuildProgrammeActivityMappedItem[] = [];
    public selectedActivities: { [id: number]: boolean } = {};
    public hasSaved: { [id: number]: boolean } = {};
    public blockingRef: MatDialogRef<BlockingDialogComponent>;
    public readonly canSave = new ComputedProperty(() => {
        return Object.values(this.selectedActivities).some(x => x === true);
    });

    public config = new ComputedProperty(() => {
        const config = {
            canCreate: false,
            canConfirm: false,
            canComplete: false,
        } as {
            title: string;
            subTitle: string;
            noActivitiesMessage: string;
            canCreate: boolean;
            canConfirm: boolean;
            canComplete: boolean;
        };
        switch (this.data.targetSsrState) {
            case SSR_STATE_ENUM.Draft:
                config.title = 'Create SSRs';
                config.subTitle = 'Select the Activities for which you want to create SSRs';
                config.noActivitiesMessage = 'There are no Build Programme Actitivites to have SSRs created';
                config.canCreate = true;
                break;
            case SSR_STATE_ENUM.Confirmed:
                config.title = 'Confirm SSRs';
                config.subTitle = 'Select the Activities you want to Confirm';
                config.noActivitiesMessage = 'There are no Build Programme Activities to be set as Confirmed';
                config.canConfirm = true;
                break;
            case SSR_STATE_ENUM.Completed:
                config.title = 'Complete Build Programme Activities';
                config.subTitle = 'Select the Activities you want to Complete';
                config.noActivitiesMessage = 'There are no Build Programme Activities to be set as Completed';
                config.canComplete = true;
                break;
        }
        return config;
    });


    constructor(
        public readonly dialogRef: MatDialogRef<CompleteBuildProgrammeActivitiesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(LotBuildProgrammeEventService) public readonly lotBuildProgrammeEvents: LotBuildProgrammeEventService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
    ) {
    }

    public ngOnInit(): void {
        this.loadActivities();
        this.canSave.recompute();
    }

    private loadActivities(): void {
        if (this.data.buildStageId) {
            this.activities = this.lotBuildProgrammeEvents
                ?.activities[this.data.buildStageId]
                ?.filter(x => this.shouldAdd(x) && this.selectActivity(x));
        } else {
            this.activities = Object.values(this.lotBuildProgrammeEvents?.activityIndex)
                ?.filter(this.shouldAdd);
        }
    }

    public getStageLabel(buildStageId): string {
        return this.lotBuildProgrammeEvents?.stages.find(stage => stage.buildStageId == buildStageId)?.buildStageLabel;
    }

    private readonly selectActivity = (activity: IBuildProgrammeActivityMappedItem): true => {
        this.selectedActivities[activity.id] = true;
        return true;
    };

    private readonly shouldAdd = (activity: IBuildProgrammeActivityMappedItem): boolean => {
        return (activity.rules.canCompleteSsr && this.data.targetSsrState === SSR_STATE_ENUM.Completed)
            || (activity.rules.canGenerateSsr && this.data.targetSsrState === SSR_STATE_ENUM.Draft)
            || (activity.rules.canConfirmSsr && this.data.targetSsrState === SSR_STATE_ENUM.Confirmed);
    };

    public completeSsrs(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Set All SSR State to Completed',
            message: 'Are you sure you want to Set SSR State to Completed for all selected Activities?',
            confirmed: () => {
                this.blockingRef = this.cbDialog.block(CompleteBuildProgrammeActivitiesDialogComponent.BLOCK_MESSAGE);
                this.completeSsr(0);
            }
        });
    }

    public confirmSsrs(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Set All SSR State to Confirmed',
            message: 'Are you sure you want to Set SSR State to Confirmed for all selected Activities?',
            confirmed: () => {
                this.blockingRef = this.cbDialog.block(CompleteBuildProgrammeActivitiesDialogComponent.BLOCK_MESSAGE);
                this.confirmSsr(0);

            }
        });
    }

    public generateSsrs(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Create SSRs',
            message: 'Are you sure you want to create SSRs for all selected Activities?',
            confirmed: () => {
                this.blockingRef = this.cbDialog.block(CompleteBuildProgrammeActivitiesDialogComponent.BLOCK_MESSAGE);
                this.generateSsr(0);
            }
        });
    }


    public completeSsr(index: number): void {
        const id = this.activities[index]?.id;
        if (this.activities.length <= index) {
            return this.cancel();
        }
        if (!this.selectedActivities[id]) {
            return this.completeSsr(index + 1);
        }

        let observable: Observable<any>;

        if (this.activities[index].isFromManualPurchaseOrder) {
            observable = this.activities[index].completeManualOrder();
        } else {
            observable = this.activities[index].completeSsr();
        }

        observable.subOnce({
            next: () => {
                this.hasSaved[id] = true;
                this.completeSsr(index + 1);
            },
            error: () => {
                this.blockingRef.close();
            }
        });
    }

    public confirmSsr(index: number): void {
        const id = this.activities[index]?.id;
        if (this.activities.length > index) {
            if (this.selectedActivities[id]) {
                this.activities[index].confirmSsr().subOnce({
                    next: () => {
                        this.hasSaved[id] = true;
                        this.confirmSsr(index + 1);
                    },
                    error: () => {
                        this.blockingRef.close();
                    }
                });
            } else {
                this.confirmSsr(index + 1);
            }
        } else {
            this.cancel();
        }
    }

    public generateSsr(index: number): void {
        const id = this.activities[index]?.id;
        if (this.activities.length > index) {
            if (this.selectedActivities[id]) {
                this.activities[index].createSsr().subOnce({
                    next: () => {
                        this.hasSaved[id] = true;
                        this.generateSsr(index + 1);
                    },
                    error: () => {
                        this.blockingRef.close();
                    }
                });
            } else {
                this.generateSsr(index + 1);
            }
        } else {
            this.cancel();
        }
    }

    public checkboxName(index: number): string {
        return `checkbox_${index}`;
    }

    public selectAll(): void {
        this.activities.forEach(activity => {
            this.selectedActivities[activity.id] = true;
        });
        this.canSave.recompute();
    }

    public deSelectAll(): void {
        this.selectedActivities = {};
        this.canSave.recompute();
    }

    public cancel(): void {
        this.deSelectAll();
        this.blockingRef?.close();
        this.dialogRef.close();
    }
}
