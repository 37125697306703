<cb-dialog dialogHeading="Change Contract Type">
    <form class="flex-col flex"
          #changeContractType="ngForm">
        <mat-radio-group class="flex-col flex"
                         aria-label="Select an option"
                         name="contractType"
                         [(ngModel)]="selectedContractType">
            <mat-radio-button *ngFor="let option of contractTypes"
                              [value]="option.id">{{ option.label }}
            </mat-radio-button>
        </mat-radio-group>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="!changeContractType.dirty || !changeContractType.valid"
                (click)="save()">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
