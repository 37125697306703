<div>
    <h3 class="mat-headline-6">
        Price Option For Website
    </h3>
    <cb-select label="Price Options"
               name="selectedPriceOptionStatus"
               [options]="priceOptions"
               [(ngModel)]="mappedItem.priceOptionId">
    </cb-select>
    <ng-container *ngIf="mappedItem.priceOptionId && getCaptureAltPrice(mappedItem.priceOptionId)">
        <h3 class="mat-headline-6">
            {{priceOptionSet.get(mappedItem.priceOptionId).textTemplate}}
        </h3>
        <cb-currency [(ngModel)]="mappedItem.alternativePrice"
                     [inclGst]="false"
                     [label]="priceOptionSet.get(mappedItem.priceOptionId).textTemplate"
                     [name]="priceOptionSet.get(mappedItem.priceOptionId).textTemplate"
                     [readonly]="false">
        </cb-currency>
    </ng-container>
</div>
