<cb-dialog dialogHeading="Lead Lost">
    <form class="flex-col flex"
          #leadLostForm="ngForm">
        <p class="cb-margin-bottom-2x">Please comment on why the Lead was Lost</p>
        <cb-select label="Select Reason"
                   name="reason"
                   [(ngModel)]="data.reason"
                   [options]="lostReasons"
                   [required]="true">
        </cb-select>
        <cb-input *ngIf="isShowCompany()"
                  type="text"
                  label="Other Company"
                  name="company"
                  [(ngModel)]="data.company">
        </cb-input>
        <cb-input type="text"
                  label="Comment"
                  name="comment"
                  [(ngModel)]="data.comment">
        </cb-input>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="leadLostForm.pristine || leadLostForm.invalid"
                (cbClick)="save()"
                [clickLimit]="1">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
