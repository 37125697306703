<div class="cb-margin-bottom icon-text flex-row">
    <mat-icon style="margin-right: 3px"
              class="cb-grey">assignment</mat-icon>
    <h1 class="mat-body-2">
        <b>Build Programme Steps</b>
    </h1>
</div>
<div class="flex-col flex">
    <div class="flex-row">
        <div class="center-center flex-col">
            <span class="mat-body-2">1</span>
            <div class="flex-row flex">
                <mat-divider vertical
                             style="height:100px"></mat-divider>
            </div>
        </div>
        <div class="flex-col flex">
            <span class="mat-body-strong"
                  style="margin-left: 8px;">
                Your Build Programme
            </span>
            <div class="flex-row"
                 *ngIf="canGenerateBuildProgrammeDocument()">
                <div class="flex-row">
                    <button mat-stroked-button
                            color="accent"
                            [disabled]="buildProgrammeDocumentDisabled()"
                            (click)="generateBuildProgrammeDocument()"
                            class="cb-margin no-wrap">
                        Generate Build Programme
                    </button>
                </div>
            </div>
            <div class="flex-col flex"
                 *ngIf="lotDto.hasYourBuildProgramme">
                <div class="cb-margin start-center flex-row">
                    <mat-icon class="mat-primary cb-margin-right">
                        check_circle
                    </mat-icon>
                    <span>Construction Plan Generated</span>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-row">
        <div class="center-center flex-col">
            <span class="mat-body-2">2</span>
            <div class="flex-row flex">
                <mat-divider vertical
                             style="height:100px"></mat-divider>
            </div>
        </div>
        <div class="flex-col flex">
            <span class="mat-body-strong"
                  style="margin-left: 8px;">Set QS Takeoff Approval
            </span>
            <div class="flex-row"
                 *ngIf="canSetQSApproval()">
                <div class="flex-row">
                    <button mat-stroked-button
                            color="accent"
                            (click)="confirmGrantQSConsentTakeoffGranted()"
                            [disabled]="!canGrantQSConsentTakeoff()"
                            class="cb-margin no-wrap">
                        Set QS Consent Approval
                    </button>
                </div>
            </div>
            <div class="start-center flex-row flex"
                 *ngIf="lotDto.qsConsentTakeoffGranted">
                <div class="cb-margin start-center flex-row">
                    <mat-icon class="mat-primary cb-margin-right">
                        check_circle
                    </mat-icon>
                    <cb-display-value-new label="QS takeoff Consent Granted by">
                        {{lotDto.qsConsentedTakeoffGrantedBy | cbDasher}} on {{lotDto.qsConsentedTakeoffGrantedByDate |
                        cbDate}}
                    </cb-display-value-new>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-row">
        <div class="center-center flex-col">
            <span class="mat-body-2">3</span>
            <div class="flex-row flex">
                <mat-divider vertical
                             style="height:100px"></mat-divider>
            </div>
        </div>
        <div class="flex-col flex">
            <span class="mat-body-strong"
                  style="margin-left: 8px;margin-bottom: 15px">
                Construction Manager
            </span>
            <div class="flex-col flex"
                 *ngIf="buildProgramme.constructionManager != undefined">
                <div class="cb-margin start-center flex-row">
                    <mat-icon class="mat-primary cb-margin-right">
                        check_circle
                    </mat-icon>
                    <span>{{buildProgramme.constructionManager}} - {{getConstructionManagerRole()}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-row">
        <div class="center-center flex-col">
            <span class="mat-body-2">4</span>
            <div class="flex-row flex">
                <mat-divider vertical
                             style="height:100px"></mat-divider>
            </div>
        </div>
        <div class="flex-col flex">
            <span class="mat-body-strong"
                  style="margin-left: 8px;">
                Floor Down
            </span>
            <div class="flex-row">
                <div class="flex-row">
                    <button mat-stroked-button
                            color="accent"
                            (click)="constructionManagerChanged()"
                            [disabled]="lotDto.hasFloorDown || !lotDto.hasBuildProgrammeTemplateApplied"
                            class="cb-margin no-wrap">
                        Floor Down Completed
                    </button>
                </div>
            </div>
            <div class="start-center flex-row flex"
                 *ngIf="lotDto.hasFloorDown">
                <div class="cb-margin start-center flex-row">
                    <mat-icon class="mat-primary cb-margin-right">
                        check_circle
                    </mat-icon>
                    <cb-display-value-new label="Floor Down Completed">
                        {{lotDto.floorDownUserName | cbDasher}} on {{lotDto.hasFloorDownDate |
                        cbDate}}
                    </cb-display-value-new>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-row">
        <div class="center-center flex-col">
            <span class="mat-body-2">5</span>
            <div class="flex-row flex">
                <mat-divider vertical
                             style="height:100px"></mat-divider>
            </div>
        </div>
        <div class="flex-col flex">
            <span class="mat-body-strong"
                  style="margin-left: 8px;">
                Practical Completion
            </span>
            <div class="flex-row">
                <div class="flex-row">
                    <button mat-stroked-button
                            color="accent"
                            (click)="confirmGenerateCPC()"
                            [disabled]="!canGenerateCPC()"
                            class="cb-margin no-wrap">
                        Generate CPC
                    </button>
                </div>
            </div>
            <div class="flex-row"
                 *ngIf="lotDto.hasPracticalCompletion">
                <div class="cb-margin start-center flex-row">
                    <mat-icon class="mat-primary cb-margin-right">
                        check_circle
                    </mat-icon>
                    <span>CPC Generated</span>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-row">
        <div class="center-center flex-col">
            <span class="mat-body-2">6</span>
            <div class="flex-row flex">
                <mat-divider vertical
                             style="height:100px"></mat-divider>
            </div>
        </div>
        <div class="flex-col flex">
            <span class="mat-body-strong"
                  style="margin-left: 8px;">
                Handover
            </span>
            <div class="flex-row">
                <div class="flex-row">
                    <button mat-stroked-button
                            color="accent"
                            (click)="setLotHandedOver()"
                            [disabled]="!lotDto.canHandover"
                            class="cb-margin no-wrap">
                        Handover Complete
                    </button>
                </div>
            </div>
            <div class="flex-col flex"
                 *ngIf="lotDto.isHandedOver">
                <div class="cb-margin start-center flex-row">
                    <mat-icon class="mat-primary cb-margin-right">
                        check_circle
                    </mat-icon>
                    <span>Handover Completed</span>
                </div>
            </div>
        </div>
    </div>
</div>
