<cb-dialog>
    <form class="flex-col flex"
          #buildContingencyRecordForm="ngForm">
        <span class="cb-field-label cb-margin-bottom cb-required">
            Value Type
        </span>
        <mat-radio-group aria-label="Value Type"
                         [(ngModel)]="mappedItem.valueTypeId"
                         name="type"
                         class="cb-margin-bottom-2x"
                         required="true">
            <mat-radio-button class="cb-margin-right"
                              [value]="NOTIFIABLE_VALUE_TYPE_ENUM.Dollars">
                Dollars
            </mat-radio-button>
            <mat-radio-button [value]="NOTIFIABLE_VALUE_TYPE_ENUM.Percentage">
                Percentage
            </mat-radio-button>
        </mat-radio-group>
        <span class="cb-field-label cb-margin-bottom cb-required">
            Scale
        </span>
        <mat-radio-group aria-label="Scale"
                         [(ngModel)]="mappedItem.scaleId"
                         name="scale"
                         class="cb-margin-bottom-2x"
                         required="true">
            <mat-radio-button class="cb-margin-right"
                              [value]="NOTIFIABLE_VALUE_SCALE_ENUM.SingleOrder">
                Single Order
            </mat-radio-button>
            <mat-radio-button [value]="NOTIFIABLE_VALUE_SCALE_ENUM.EntireJob">
                Entire Job
            </mat-radio-button>
        </mat-radio-group>
        <div class="flex-row flex">
            <div class="cb-margin-right-2x flex-col">
                <mat-label class="cb-field-label cb-margin-bottom cb-required">
                    Direction
                </mat-label>
                <mat-radio-group aria-label="Direction"
                                 [(ngModel)]="mappedItem.valueDirectionId"
                                 name="direction"
                                 class="cb-margin-bottom-2x"
                                 required="true">
                    <mat-radio-button class="cb-margin-right"
                                      [value]="NOTIFIABLE_VALUE_DIRECTION_ENUM.GreaterThan">
                        Greater Than
                    </mat-radio-button>
                    <mat-radio-button [value]="NOTIFIABLE_VALUE_DIRECTION_ENUM.LessThan">
                        Less Than
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <cb-input class="flex"
                      name="amount"
                      label="Amount"
                      type="number"
                      [required]="true"
                      [(ngModel)]="mappedItem.amount">
            </cb-input>
        </div>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="buildContingencyRecordForm.invalid || buildContingencyRecordForm.pristine">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
