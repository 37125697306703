import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ILotSpecScheduleItemMappedItem} from '@app/logic/lot-spec-schedule-item/interfaces/i.lot-spec-schedule-item.mapped';
import {COST_TYPE_ENUM, IQuoteLineRefDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    templateUrl: './lot-spec-schedule-quote-refs-dialog.component.html',
    styleUrls: ['./lot-spec-schedule-quote-refs-dialog.component.scss']
})
export class LotSpecScheduleQuoteRefsDialogComponent {
    public static readonly MIN_WIDTH = '75%';
    public readonly columns = [
        'Qref',
        'Cost Type',
        'Line Item',
        'Subtotal',
        'Supplier',
        'Quote Date',
        'Quote #',
        'CR #',
        'VO #',
        '',
    ];
    public readonly item: ILotSpecScheduleItemMappedItem;
    public quoteLine: IQuoteLineRefDto;
    public readonly COST_TYPE_ENUM = COST_TYPE_ENUM;
    public isRequestFinished: boolean;

    constructor(
        public readonly dialogRef: MatDialogRef<LotSpecScheduleQuoteRefsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            lotSpecItem: ILotSpecScheduleItemMappedItem;
        }
    ) {
        this.item = this.data.lotSpecItem;
        this.data.lotSpecItem.getQuoteLine().subOnce(ql => {
            this.quoteLine = ql;
            this.isRequestFinished = true;
        });
    }

    public copyToClipboardClicked(): void {
        navigator?.clipboard?.writeText(this.item.qRef);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
