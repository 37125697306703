import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectListComponent} from './select-list.component';
import {SelectCheckAllComponent} from './select-check-all/select-check-all.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

const COMPONENTS = [
    SelectCheckAllComponent,
    SelectListComponent,
];

@NgModule({
    declarations: COMPONENTS,
    exports: COMPONENTS,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatFormFieldModule,
    ]
})
export class CbSelectListModule {
}
