import { IAiRateResponseDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IAiRateMappedItem } from '@app/logic/ai-rate/interfaces/i.ai-rate.mapped';
import { IAiRateLogicService } from '@app/logic/ai-rate/interfaces/i.ai-rate-logic.service';

interface IData {
    answer: IAiRateMappedItem;
    buildActivityName: string;
    productCode: string;
    productName: string;
}

@Component({
    selector: 'cb-ai-rate-response-dialog',
    templateUrl: './ai-rate-response-dialog.component.html',
    styleUrls: ['./ai-rate-response-dialog.component.scss']
})
export class AiRateResponseDialogComponent
    extends BaseDialogFormViewDirective<IAiRateResponseDto, IAiRateMappedItem, IAiRateLogicService>
    implements OnInit {
    public static readonly MIN_WIDTH = '40%';

    public collapsed = true;

    public get mappedItem(): IAiRateMappedItem {
        return this.data.answer;
    }

    public get requestId(): string{
        return this.data.answer.requestId;
    }

    public get activityName(): string{
        return this.data.buildActivityName;
    }

    public get productCode(): string{
        return this.data.productCode;
    }

    public get productName(): string{
        return this.data.productName;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<AiRateResponseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService) {
        super(dialogRef, toastService);
        this.mappedItem.fullExplanation = this.getFullExplanation();

        this.collapsed = !!this.mappedItem.answer;
    }

    public getFullExplanation(): string {
        return this.mappedItem.fullExplanation;
    }

    public getRoundedNumber(numberToConvert: number): string {
        return (Math.round((numberToConvert + Number.EPSILON) * 100) / 100).toString() + ' secs';
    }

    public getRoundedCurrency(numberToConvert: number): string {
        return '$' + (Math.round((numberToConvert + Number.EPSILON) * 1000) / 1000).toString();
    }
}
