import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CbSelectListModule} from 'cb-hub-lib';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MatInputModule} from '@angular/material/input';
import {ContractGenerationComponent} from './contract-generation.component';

@NgModule({
    declarations: [
        ContractGenerationComponent,
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        CbFormsModule,
        CbSelectListModule,
        MatInputModule,
        RouterModule.forRoot([
            {
                component: ContractGenerationComponent,
                path: 'contract-generation',
                data: { breadcrumb: 'Contract Generation' },
            },
        ]),
    ]
})

export class ContractGenerationModule { }
