<div class="flex" *cbWaitFor="isFeatureTogglesStateServiceInitialized">
    <mat-tab-group class="flex"
                   #tabGroup
                   cbDefaultTabId="catalogue"
                   cbTabUrlParam="cbTabRouteId"
                   mat-stretch-tabs="false"
                   [cbTabRoute]="'products/:cbTabRouteId/:cbTabRouteId2'">
        <mat-tab label="Product Centre"
                 *ngIf="CanViewProductCentre()"
                 cbTabRouteId="product-centre"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-product-centre-tab style="position: fixed"></cb-product-centre-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="Catalogue"
                 cbTabRouteId="catalogue"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-product-catalogue-tab></cb-product-catalogue-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="Categories"
                 cbTabRouteId="categories"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-product-categories-tab></cb-product-categories-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="Import"
                 cbTabRouteId="import"
                 cbNestedTabLevels="1"
                 [disabled]="!canPerformImport()">
            <ng-template matTabContent>
                <cb-product-import-tab></cb-product-import-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="Export"
                 cbTabRouteId="export"
                 cbNestedTabLevels="0"
                 [disabled]="!canExportCatalogue()">
            <ng-template matTabContent>
                <cb-product-export-tab></cb-product-export-tab>
            </ng-template>
        </mat-tab>
        <mat-tab label="History"
                 cbTabRouteId="history"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-product-history-tab></cb-product-history-tab>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="featureToggleStatesService.isProductExceptionRateSearchEnabled"
                 label="Hub Rates Exceptions"
                 cbTabRouteId="rateexceptions"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-product-rate-exception-tab></cb-product-rate-exception-tab>
            </ng-template>
        </mat-tab>
        <mat-tab
                 label="Build Overhead Costs"
                 cbTabRouteId="buildoverheadcosts"
                 cbNestedTabLevels="0"
                 [disabled]="!canManageBuildOverheadCosts()">
            <ng-template matTabContent>
                <cb-product-build-overhead-costs></cb-product-build-overhead-costs>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
