import { Component, Input, OnInit } from '@angular/core';
import { LotPermissions } from '@app/core/permissions';
import { ProjectReleaseStagePermissions } from '@app/core/permissions/project-release-stage.permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { DesignSchemesLogicService } from '@app/logic/design-schemes';
import { ILotSpecMappedItem } from '@app/logic/lot-spec/interfaces/i.lot-spec.mapped';
import { LotSpecLogicService } from '@app/logic/lot-spec/lot-spec.logic.service';
import { LotsLogicService, ILotMappedItem } from '@app/logic/lots';
import { IReleaseStageMappedItem } from '@app/logic/release-stages/interfaces/i.release-stage.mapped';
import { ReleaseStagesLogicService } from '@app/logic/release-stages/release-stages.logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import {
    ILotSwitchReleaseStageDialogData,
    LotSwitchReleaseStageDialogComponent
} from '@app/views/lot/shared/lot-switch-release-stage-dialog/lot-switch-release-stage-dialog.component';
import {
    DESIGN_SCHEME_STATUS_ENUM,
    IProjectReleaseLotDesignSummaryDto,
    IProjectReleaseLotDto, IProjectReleaseStageDto, LotTypeEnumId,
    LOT_CONTRACT_TYPE_ENUM, LOT_JOB_STATUS_ENUM,
    LOT_TYPE_ENUM, MARKET_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { filter } from 'lodash';
import { ProjectReleaseStageEditLotDialogComponent } from '../summary/edit/edit-lot-dialog.component';
import { IRequestConceptImagesDialogData, RequestConceptImagesDialogComponent } from './dialogs/request-concept-images/request-concept-images.component';
import { IRequestPricingDialogData, RequestPricingDialogComponent } from './dialogs/request-pricing-dialog/request-pricing-dialog.component';
import { RequestSchemesDialogComponent } from './dialogs/request-schemes-dialog/request-schemes-dialog.component';
import { SelectLotTemplateDialogComponent } from './dialogs/select-lot-template-dialog/select-lot-template-dialog.component';

export interface ICustomProjectReleaseLotDesignSummaryDto extends IProjectReleaseLotDesignSummaryDto {
    $shouldRequestDesignScheme: boolean;
    $shouldApplyConcept: boolean;
}

@Component({
    selector: 'cb-manage-project-release-stage-lots-design-tab',
    templateUrl: './release-lots-design.component.html',
    styleUrls: ['./release-lots-design.component.scss']
})
export class ManageReleaseLotsDesignTabComponent implements OnInit {
    @Input() public mappedItem: IReleaseStageMappedItem;
    @Input() public projectReleases: IProjectReleaseStageDto[];
    public selectedTabIndex = 0;
    public lots: ICustomProjectReleaseLotDesignSummaryDto[];
    public filteredPricingableLots: ICustomProjectReleaseLotDesignSummaryDto[];
    public filteredSchemeableLots: ICustomProjectReleaseLotDesignSummaryDto[];
    public loaded = false;
    public MARKET_STATUS_ENUM = MARKET_STATUS_ENUM;
    public LOT_JOB_STATUS_ENUM = LOT_JOB_STATUS_ENUM;
    public LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;
    public LOT_TYPE_ENUM = LOT_TYPE_ENUM;
    public DESIGN_SCHEME_STATUS_ENUM = DESIGN_SCHEME_STATUS_ENUM;
    public spec: ILotSpecMappedItem;
    constructor(
        private readonly releaseStagesLogicService: ReleaseStagesLogicService,
        public readonly lotPermissions: LotPermissions,
        public readonly projectReleaseStagePermissions: ProjectReleaseStagePermissions,
        public readonly cbDialog: CbDialogService,
        private readonly navigationService: NavigationService,
        private readonly toastService: ToastService,
        private readonly lotsLogicService: LotsLogicService,
        private readonly lotSpecLogicService: LotSpecLogicService,
        private readonly designSchemesLogicService: DesignSchemesLogicService,
    ) { }

    public ngOnInit(
    ): void {
        this.loadLots();
    }

    public editLot(lot: IProjectReleaseLotDto): void {
        this.lotsLogicService.$getMappedItem(lot.id).subOnce(LotMappedItem => {
            this.cbDialog
                .open(ProjectReleaseStageEditLotDialogComponent, {
                    data: { mappedItem: LotMappedItem, isReadonly: false },
                    minWidth: '80%',
                })
                .afterClosed()
                .subOnce((result: ILotMappedItem) => {
                    if (result) {
                        const index = result?.id != null && this.lots.findIndex(x => x.id === result.id);
                        if (index >= 0) {
                            Object.assign(this.lots[index], result);
                        }
                    }
                });
        });
    }

    public viewLotDetails(lot: IProjectReleaseLotDto): void {
        this.lotsLogicService.$getMappedItem(lot.id).subOnce(LotMappedItem => {
            this.cbDialog
                .open(ProjectReleaseStageEditLotDialogComponent, {
                    data: { mappedItem: LotMappedItem, isReadonly: true },
                    minWidth: '80%',
                })
                .afterClosed()
                .subOnce();
        });
    }

    public viewLot(lot: IProjectReleaseLotDto): void {
        this.navigationService.navigate([`lots/${lot.id}/summary`]);
    }

    public getAmenitiesDisplay(noAmenities: number, lotType: LotTypeEnumId): string {
        return this.isBlockLotType(lotType) ? '-' : noAmenities.toString();
    }

    public isBlockLotType(value: LotTypeEnumId): boolean {
        return value === LOT_TYPE_ENUM.ApartmentBlock || value === LOT_TYPE_ENUM.TerracedBlock;
    }

    public requestScheme(lot: ICustomProjectReleaseLotDesignSummaryDto): void {
        this.designSchemesLogicService.requestDesignScheme(lot.id).subOnce(result => {
            if (result?.statusId !== DESIGN_SCHEME_STATUS_ENUM.Completed) {
                // New scheme was requested successfully - and is incomplete
                lot.designSchemeStatus = result.statusId;
                lot.hasIncompletedDesignSchemes = true;
                this.toastService.showToast('Request Scheme Successful');
            } else {
                this.toastService.showToast('Request Scheme Unsuccessful');
            }
        });
    }

    public canRequestRender(lot: ICustomProjectReleaseLotDesignSummaryDto): boolean {
        return !lot.hasClientSale && lot.hasConceptRevisions;
    }

    public canEditLot(lot: ICustomProjectReleaseLotDesignSummaryDto): boolean {
        return !lot.hasSchemes;
    }

    public canSelectSpecTemplate(lot: IProjectReleaseLotDto): boolean {
        return !lot.hasClientSale && !lot.specTemplateId;
    }

    public applyTemplate(lot: ICustomProjectReleaseLotDesignSummaryDto): void {
        this.cbDialog
            .open(SelectLotTemplateDialogComponent, {
                data: { lotId: lot.id },
                minWidth: '1000px',
            })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.loadLots();
                }
            });
    }

    public switchReleaseStage(lot: ICustomProjectReleaseLotDesignSummaryDto): void {
        this.lotsLogicService.$getMappedItem(lot.id).subOnce(mappedItem => {
            this.cbDialog
                .open<ILotSwitchReleaseStageDialogData>(
                LotSwitchReleaseStageDialogComponent,
                {
                    data:
                        {
                            mappedItem
                        }
                })
                .afterClosed()
                .subOnce(result => {
                    if (result) {
                        this.loadLots();
                    }
                });
        });
    }

    public requestRender(lot: ICustomProjectReleaseLotDesignSummaryDto): void {
        this.cbDialog.simpleMessageDialog('Request Render is Not yet Implemented');
    }

    public requestFloorPlan(lot: ICustomProjectReleaseLotDesignSummaryDto): void {
        this.cbDialog.simpleMessageDialog('Request Floor Plan is Not yet Implemented');
    }

    public canRequestFloorPlan(lot: ICustomProjectReleaseLotDesignSummaryDto): boolean {
        return !lot.hasClientSale && lot.hasConceptRevisions;
    }

    public canSwitchReleaseStage(lot: IProjectReleaseLotDto): boolean {
        return !lot.hasClientSale && this.projectReleases?.length > 1;
    }

    private loadLots = (): void => {
        this.loaded = false;
        this.releaseStagesLogicService.getProjectReleaseStageDesignSummaryLots(this.mappedItem.id).subOnce((result) => {
            this.lots = result;
            this.loaded = true;
            this.filterPriceableLots();
            this.filterDesignSchemebleLots();
        });
    };

    private filterPriceableLots(): void {
        this.filteredPricingableLots = filter(this.lots, (lot) => {
            if (this.canRequestPricingToDesignScheme(lot)) {
                lot.$shouldApplyConcept = false;
            }
            return this.canRequestPricingToDesignScheme(lot);
        });
    }

    private filterDesignSchemebleLots(): void {
        this.filteredSchemeableLots = filter(this.lots, (lot) => {
            if (this.canRequestDesignScheme(lot)) {
                lot.$shouldRequestDesignScheme = false;
            }
            return this.canRequestDesignScheme(lot);
        });
    }

    public makeUnavailableForSale(lot: IProjectReleaseLotDto): void {
        const ids: string[] = [lot.id.toString()];
        this.lotsLogicService.updateMarketStatus(ids, MARKET_STATUS_ENUM.NotAvailable).subOnce(
            (results) => {
                if (results) {
                    this.toastService.saveSuccess();
                    this.loadLots();
                }
            });
    }

    public requestImagesDialog(): void {
        this.cbDialog
            .open<IRequestConceptImagesDialogData, boolean>(
            RequestConceptImagesDialogComponent,
            {
                data: { lots: this.lots },
                minWidth: '1000px',
            }
        )
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.loadLots();
                }
            });
    }

    public requestPricing(lot: ICustomProjectReleaseLotDesignSummaryDto): void {
        this.cbDialog.confirm({
            dialogHeading: 'Confirm Request Pricing',
            message: 'Are you sure you want to Request Pricing for this Design Scheme?',
            confirmed: () => {
                this.designSchemesLogicService.requestPricingForLot(lot.id, false).subOnce(result => {
                    lot.designSchemeStatus = result.statusId;
                });
            }
        });
    }

    public requestSchemeDialog(): void {
        this.cbDialog
            .open(RequestSchemesDialogComponent, {
                data: { lots: this.filteredSchemeableLots },
                minWidth: '1000px',
            })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.loadLots();
                }
            });
    }

    public requestPricingDialog(): void {
        this.cbDialog
            .open<IRequestPricingDialogData, boolean>(RequestPricingDialogComponent, {
            data: { lots: this.filteredPricingableLots }
        })
            .afterClosed()
            .subOnce(result => {
                if (result) {
                    this.loadLots();
                }
            });
    }

    public hasLotsToRequestPricingOn(): boolean {
        return this.filteredPricingableLots?.length > 0;
    }

    public hasLotsToRequestSchemesOn(): boolean {
        return this.filteredSchemeableLots !== undefined && this.filteredSchemeableLots.length > 0;
    }

    public canRequestPricingToDesignScheme(lot: ICustomProjectReleaseLotDesignSummaryDto): boolean {
        return lot.canRequestDesignSchemePricing;
    }

    public canRequestPricing(lot: ICustomProjectReleaseLotDesignSummaryDto): boolean {
        return lot.canRequestDesignSchemePricing;
    }

    public canRequestDesignScheme(lot: ICustomProjectReleaseLotDesignSummaryDto): boolean {
        return this.hasSpecTemplate(lot) && !lot.hasClientSale && !lot.hasIncompletedDesignSchemes;
    }

    public hasSpecTemplate(lot: ICustomProjectReleaseLotDesignSummaryDto): boolean {
        return !!lot.specTemplateId;
    }

}
