
import { IProductMappedItem } from './i.product.mapped';
import { IProductAttributeDto } from '@app/logic/product-attributes/interfaces/i.product-attribute.dto';
import { IUserDto } from '@app/logic/users';

export interface IProductDto {
    id: number;
    isActive: boolean;
    name: string;
    code: string;
    rates: IRateDto[];
    shouldMaintainRatesForThisProduct: boolean;
    images: { id: string; extension: string; sortOrder: number }[];
    productType: number;
    products: IProductBundleProductDto[];
    uom: number;
    uomDisplay: string;
    restrictions: any[];
    categoryPath: any[];
    tradeTypeId: number;
    tradeType: any;
    isStandardProduct: boolean;
    offeringCategoryId: number;
    compositeItemId: number;
    isCompositeItem: boolean;
    componentItems: Array<IProductDto>;
    createdDate: string;
    createdBy: IUserDto;
    updatedBy: IUserDto;
    updatedDate: string;
    offerType?: number; // used in create
    showInClientSpecs: boolean;
    showInColourYourDreams: boolean;
    clientSpecDescription: string;
    status: number;
    unavailableStartDate: any;
    unavailableEndDate: any;
    statusComment: string;
    replacementOfferingId: number;
    replacementOffering: IProductMappedItem;
    unavailableReason: number;
    manualColourEntryRequired: boolean;
    bundleTemplateId: number;
    bundleTemplateName: string;
    bundleTemplateChanged: boolean;
    templateItems: IOfferingBundleTemplateItemDto[];
    notes: string;
    mainCategory: string;
    aggregateRates: any;
    nationalRate: number;
    hasImage: boolean;
    inSpec: boolean;
}

export interface IOfferingBundleTemplateItemDto {
    id: number;
    name: string;
    isRequired: boolean;
    isLocked: boolean;
    categoryId: number;
    categoryPath: string;
    offeringBundleId: number;
    createdDate: string;
    createdByName: string;
    updatedDate: string;
    updatedByName: string;
}

export interface IProductBundleProductDto {
    id: number;
    quantity: number;
    isChangeable: boolean;
    offeringId: number;
    offering: string;
    offeringBundleId: number;
    isDeleted: boolean;
    isActive: boolean;
    hasImage: boolean;
    inSpec: boolean;
    status: number;
    bundleTemplateItemId: number;
}

export interface IRateDto {

    id: number;
    businessAccountId: number;
    sku: string;
    value: number;
    locationId: number;
    projectId: number;
    releaseStageId: number;
    isActive: boolean;
    isParentAccount: boolean;
    isRatesOnlyAccount: boolean;
    appliedRatesFrom: any;
    inactivatedDate: any;
    offeringId: number;
    // taken from skinny dto so should be removed on save
    areaPath: any[];
    isDeleted: boolean;
    updatedBy: any;
    updatedDate: any;
    vendor: string;

    projectTitle: string;
    appliedRatesFromObject: any;

}

export const emptyAttribute = (): IProductAttributeDto => ({
    id: undefined,
    attributeId: undefined,
    category: undefined,
    name: undefined,
    categoryId: undefined,
    newValues: [],
    options: [],
    selectedOptions: [],
    isLocked: false,
    attribValues: [],
});
