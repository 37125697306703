<cb-dialog dialogHeading="Confirm Request Design Scheme Pricing">
    <form class="flex-col"
          #form="ngForm">
        <span class="cb-title"> Request Pricing for Scheme {{mappedItem.revisionNumber}}</span>
        <span class="mat-body-2">{{dialogBody}}</span>
        <cb-checkbox *ngIf="!isPricingLockedToLatestSpecVersion"
                     class="cb-margin-top"
                     name="useLatestLotSpecVersion"
                     [label]="versionLabel"
                     [(ngModel)]="useLatestLotSpecVersion"
                     (change)="versionChanged()"
                     [slim]="true"></cb-checkbox>
        <cb-agree-to-condition name="finalConfirmation"
                               [(ngModel)]="finalConfirmation"
                               [label]="confirmationLabel"
                               [disabled]="!useLatestLotSpecVersion">
        </cb-agree-to-condition>
        <cb-table class="cb-margin"
                  *ngIf="documentTypes.length > 0"
                  [loaded]="true"
                  [stringColumns]="columns">
            <tr cbTableRow
                *ngFor="let documentType of documentTypes">
                <td>
                    <mat-icon matTooltip="Requirement Fufilled"
                              class="cb-help-cursor cb-primary"
                              *ngIf="files[documentType.id]">
                        check_circle</mat-icon>
                    <mat-icon matTooltip="Not Required"
                              class="cb-help-cursor cb-grey"
                              *ngIf="!files[documentType.id]">
                        info
                    </mat-icon>
                </td>
                <td>
                    <span class="mat-body-2">{{documentType.label}}</span>
                    <dl class="cb-error">
                        <dd *ngFor="let error of documentTypeFileErrors[documentType.id]">
                            {{error}}
                        </dd>
                    </dl>
                </td>
                <td>{{documentType.fileTypesLabel}}</td>
                <td>{{getFileName(documentType) | cbDasher}}</td>
                <td cbTableStickyCol="end">
                    <button mat-icon-button
                            (click)="fileInput.click()">
                        <mat-icon>file_upload</mat-icon>
                    </button>
                    <input #fileInput
                           style="display: none;"
                           type="file"
                           id="{{documentType.fileInputId}}"
                           accept="{{documentType.accepts}}"
                           (click)="$event.target.value = null"
                           (change)="fileUploaded(fileInput, documentType, $event.target.files[0])"/>
                    <button mat-icon-button
                            [disabled]="!files[documentType.id]"
                            (click)="removeFile(fileInput, documentType)">
                        <mat-icon>clear</mat-icon>
                    </button>
                </td>
            </tr>
        </cb-table>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="isSaveDisabled()"
                (cbClick)="submit()"
                [clickLimit]="1">
            Submit
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
