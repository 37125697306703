<form #userSearchForm="ngForm">
    <div class="cb-margin">
        <mat-card class="top-panel cb-margin-bottom flex-col">
            <mat-card-content>
                <div class="start-center flex-row flex">
                    <mat-icon>search</mat-icon>
                    <cb-input type="text"
                              label="Type in First Name, Last Name or Phone Number to Search"
                              [(ngModel)]="searchFiltersCache.data.query"
                              (ngModelChange)="searchFiltersChanged$.next($event)"
                              name="search"
                              class="cb-margin-left cb-margin-right-2x flex">
                    </cb-input>
                    <cb-select label="Active Status"
                               name="activeOnly"
                               [(ngModel)]="selectedIsActiveOnlyStatus"
                               (change)="onIsActiveOnlyValueChanged($event)"
                               [options]="isActiveOnlyStatusOptions"
                               labelProp="label"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select label="User Role Tags"
                               name="userRoleTags"
                               [(ngModel)]="userRoleTagSelectedValue"
                               (change)="onUserRoleTagsValueChanged($event)"
                               [options]="userRoleTagsOptions$ | async"
                               [valueProp]="WHOLE_OPTION_VALUE_PROP"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <span class="flex"></span>
                    <cb-button class="cb-margin"
                               (clicked)="generateUserSecurityReport()">
                        User Security Report
                    </cb-button>
                    <cb-button class="cb-margin"
                               (clicked)="generateUserDetailsReport()">
                        User Details Report
                    </cb-button>
                    <cb-button class="cb-margin"
                               (clicked)="createNewUser()">
                        Create User
                    </cb-button>
                </div>
            </mat-card-content>
        </mat-card>
        <div class="scroll-container"
             [cbInfiniteScroll]="listContainer"
             [fetch]="fetchResults()"
             [queryUpdated]="searchFiltersChanged$"
             [enabled]="searchEnabled"
             [(fetchInProgress)]="fetchInProgress"
             [(noMoreResults)]="noMoreResults"
             [(results)]="results"
             [(currentPage)]="currentPage">
            <cb-info-message *ngIf="(!results || results.length < 1)"
                             message="There are no Users available for your search criteria"
                             iconClass="cb-accent">
            </cb-info-message>
            <div #listContainer
                 class="list-container flex-wrap xs-column flex">
                <div class="md-33 sm-50 xs-100 flex-25"
                     *ngFor="let user of results">
                    <cb-user-search-card [dto]="user"></cb-user-search-card>
                </div>
                <div class="start-center flex-row"
                     *ngIf="noMoreResults && results && results.length">
                    <mat-icon>info_outline</mat-icon>
                    &nbsp;
                    <span class="mat-body-2 flex">There are no more results for your search criteria</span>
                </div>
            </div>
        </div>
    </div>
</form>
