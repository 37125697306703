<cb-dialog dialogHeading="Reassign Building Consent">
    <form class="flex-col flex"
          #reassignConsentForm="ngForm">
        <cb-user-autocomplete-scroller label="Assigned To"
                                       name="assignedTo"
                                       [(ngModel)]="assignedTo"
                                       [required]="true"
                                       [tag]="BUILDING_COORDINATOR"
                                       [team]="DESIGN_TEAM"
                                       [tagOrTeam]="true"
                                       [active]="true"></cb-user-autocomplete-scroller>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="reassignConsentForm.invalid || reassignConsentForm.pristine"
                (cbClick)="save()"
                [clickLimit]="1">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
