import {NgModule} from '@angular/core';
import {PriceOptionsComponent} from '@app/views/settings/pages/price-options/price-options.component';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {PriceOptionsRoutingModule} from '@app/views/settings/pages/price-options/price-options-routing.module';

@NgModule({
    declarations: [
        PriceOptionsComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        MatTableModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule,
        MatButtonModule
    ],
    providers: [],
    exports: [
        PriceOptionsComponent
    ]
})
export class PriceOptionsComponentModule { }
