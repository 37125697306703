import { Component } from '@angular/core';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { NotificationTemplateDialogComponent } from './notification-template-dialog/notification-template-dialog.component';
import {
    NotificationTemplateLogicService, NotificationTemplateMappedItem,
    INotificationTemplateDto, INotificationTemplateTreeDto, INotificationTemplateMappedItem
} from '@app/logic/notification-template';
import { filter, find } from 'lodash';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'cb-notification-templates',
    templateUrl: './notification-templates.component.html',
    styleUrls: ['./notification-templates.component.scss'],
})
export class NotificationTemplatesComponent extends BaseSimpleListViewDirective<INotificationTemplateDto, NotificationTemplateLogicService> {
    public readonly displayedColumns: string[] = ['templateLabel', 'isActive', 'actions'];
    public notificationTemplateTree: INotificationTemplateTreeDto[];

    constructor(
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        protected readonly notificationTemplateLogicService: NotificationTemplateLogicService,
    ) {
        super(
            cbDialog,
            notificationTemplateLogicService,
            NotificationTemplateDialogComponent,
            'Notification Template',
            NotificationTemplateMappedItem,
            permissionsPermissions,
            false,
        );
        this.loadNotificationTemplates();
    }

    public getNotificationTemplateLabel(notificationTemplate: INotificationTemplateTreeDto): string {
        return `${notificationTemplate.label} (${notificationTemplate.children?.length ?? 0})`;
    }

    public onNotificationReportClicked(): void {
        this.notificationTemplateLogicService.export().subOnce();
    }

    public onNotificationTemplatesUpdateClicked(): void {
        this.notificationTemplateLogicService.seedNotificationTemplates().subOnce(() => {
            // Reload the notification templates so you can see any new ones
            this.loadNotificationTemplates();
        });
    }

    public openDialog(mappedItem: INotificationTemplateMappedItem, dialogHeading: string): MatDialogRef<any> {
        return this.cbDialog.open(NotificationTemplateDialogComponent, {
            minWidth: '75%',
            data: {
                dialogHeading,
                mappedItem,
                notificationTemplateTree: this.notificationTemplateTree,
            } as any,
        });
    }

    public handleEditItem = (rawItem: INotificationTemplateMappedItem): void => {
        if (rawItem) {
            this.notificationTemplateLogicService.$getItem(rawItem.id).subOnce((dbItem: INotificationTemplateDto) => {
                const category = find(this.notificationTemplateTree, { id: dbItem.templateCategoryId });
                if (category) {
                    const match = find(category.children, { id: dbItem.id });
                    if (match) {
                        Object.assign(match, dbItem);
                    } else {
                        category.children = [...category.children, dbItem];
                        const needToCleanCategory = find(this.notificationTemplateTree, node =>
                            find(node.children, item => item.id === rawItem.id && item.templateCategoryId !== rawItem.templateCategoryId));
                        if (needToCleanCategory) {
                            needToCleanCategory.children = filter(needToCleanCategory.children.slice(), item => item.id !== rawItem.id);
                        }
                    }
                    category.children.sort((a, b) => a.label.localeCompare(b.label));
                }
            });
        }
    };

    private loadNotificationTemplates(): void {
        this.notificationTemplateLogicService.getTree().subOnce((result: INotificationTemplateTreeDto[]) => {
            result.forEach((item: INotificationTemplateTreeDto) => {
                item.children = item.children.sort((a, b) => a.label.localeCompare(b.label));
            });
            this.notificationTemplateTree = result;
            this.searchResultsLoaded = true;
        });
    }
}
