import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IScheduleItemExternalReferenceSearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { SlotExternalSystemFieldValuesLogicService } from '@app/logic/slot-external-system-field-value/slot-external-system-field-value.logic-service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { EXTERNAL_SYSTEM_ENUM, ISlotExternalSystemFieldValueDocumentDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { ISearchResult } from '@app/shared/components/search/i.search';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { IOption } from '@app/shared/components/forms/select/select.component';
import { SlotExternalSystemFieldValueDialogComponent } from '../slot-external-system-field-value-dialog/slot-external-system-field-value-dialog.component';
import { ExternalSystemFieldsLogicService } from '@app/logic/external-system-fields/external-system-fields.logic-service';
import { FormMode } from '@app/shared/enums/form';

@Component({
    selector: 'cb-slot-external-system-field-value',
    templateUrl: './slot-external-system-field-value.component.html',
    styleUrls: ['./slot-external-system-field-value.component.scss']
})
export class SlotExternalSystemFieldValueComponent implements OnInit, OnDestroy {

    public EXTERNAL_SYSTEM_ENUM = EXTERNAL_SYSTEM_ENUM;

    public externalSystemTypes = EXTERNAL_SYSTEM_ENUM.toLookup();

    public simStatuses: IOption[] = [{
        id: 1,
        name: 'active',
        label: 'Active',
    },
    {
        id: 0,
        name: 'inactive',
        label: 'InActive',
    }];

    public externalFieldOptions: IOption[];

    public searchResults: ISlotExternalSystemFieldValueDocumentDto[] = [];

    public get userCacheItem(): UserCacheItem<IScheduleItemExternalReferenceSearch> {
        return this.userCacheService.scheduleItemExternalReferenceSearch;
    }
    public currentPage = 1;
    public searchEnabled = false;
    public readonly subscriptions$ = new Subscription();
    public readonly queryUpdate = new Subject();
    public infiniteScrollEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
        public readonly navigationService: NavigationService,
        public readonly toastSerivce: ToastService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: MatDialog,
        public readonly userCacheService: UserCacheService,
        public readonly slotExternalSystemFieldValuesLogicService: SlotExternalSystemFieldValuesLogicService,
        public readonly externalSystemFieldsLogicService: ExternalSystemFieldsLogicService,
        public readonly cbDialog: CbDialogService,
    ) { }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    public ngOnInit(): void {
        this.initSearch();
    }

    public loadExternalSystemFields(): void {
        this.externalSystemFieldsLogicService.getListBySystemId(this.userCacheItem.data.externalSystemId)
            .subOnce(result => this.externalFieldOptions = result.map(x => ({
                id: x.id,
                name: '',
                label: x.fieldName,
            })));
    }

    public initSearch(): void {
        this.userCacheItem.init().then(() => {
            this.searchEnabled = true;
            this.queryUpdated();
            this.subscriptions$.add(
                this.userCacheItem.updated$.subscribe({
                    next: this.queryUpdated
                })
            );
        });
    }

    public readonly queryUpdated = (): void => {
        if (!this.searchEnabled) {
            return;
        }
        this.currentPage = 1;
        this.queryUpdate.next(null);
    };

    public fetchResults(): Observable<ISearchResult<ISlotExternalSystemFieldValueDocumentDto>> {
        if (!this.searchEnabled) {
            return;
        }
        const params = {
            currentpage: this.currentPage,
            ...this.userCacheItem.copyData()
        };

        return this.slotExternalSystemFieldValuesLogicService.$getSearchList(params);
    }

    public addItem(): void {
        this.cbDialog.open(SlotExternalSystemFieldValueDialogComponent, {
            data: {
                mappedItem: this.slotExternalSystemFieldValuesLogicService.$createMappedItem(),
                title: 'Add Value',
                mode: FormMode.Add
            }
        })
            .afterClosed()
            .subOnce((result) => {
                if (result?.id > 0) {
                    this.searchResults.unshift(result);
                }
            });
    }

    public editItem(item: ISlotExternalSystemFieldValueDocumentDto): void {
        this.slotExternalSystemFieldValuesLogicService.$getMappedItem(item.id).subOnce((result) => {
            this.cbDialog.open(SlotExternalSystemFieldValueDialogComponent, {
                data: {
                    mappedItem: result,
                    title: 'Edit Value',
                    mode: FormMode.Edit
                }
            }).afterClosed()
                .subOnce((result) => {
                    if (result?.id > 0) {
                        const elementsIndex = this.searchResults.findIndex(element => element.id === result.id);
                        if (elementsIndex > -1) {
                            this.searchResults[elementsIndex] = result;
                        }
                    }
                });
        });
    }

    public deleteItem(item: ISlotExternalSystemFieldValueDocumentDto): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete External Reference',
            message: `Are you sure you want to delete value: ${item.fieldValue} ?`,
            confirmed: this.handleDeleteItem.bind(this, item),
        });
    }

    private readonly handleDeleteItem = (item: ISlotExternalSystemFieldValueDocumentDto): void => {
        this.slotExternalSystemFieldValuesLogicService.$deleteItem(item.id).subOnce((result) => {
            if (result) {
                const index = this.searchResults.findIndex(x => x.id === item.id);
                this.searchResults.splice(index, 1);
            }
        });
    };
}
