<cb-dialog dialogHeading="Qualify Lead">
    <form class="flex-row flex"
          #leadQualifyDesignBuildForm="ngForm">
        <div class="cb-margin-right-3x flex-col flex">
            <h1 class="cb-margin-bottom-2x mat-headline-6">Qualify Lead - Design &amp; Build</h1>
            <div class="cb-margin-bottom-2x start-center flex-row">
                <mat-icon class="mat-accent">
                    info
                </mat-icon>
                <span class="mat-body-2 cb-margin-left">
                    Search for an existing Client Account to qualify this Lead against. Create a new Client Account if
                    there
                    is no existing Account
                </span>
            </div>
            <cb-client-accounts-input-find-cards *ngIf="!data.createNewClientAccount"
                                                 label="Select Existing Client Account"
                                                 [(ngModel)]="selectedAccount"
                                                 name="selectedAccount"
                                                 [text]="selectedAccount?.name"
                                                 [removeable]="true"
                                                 [required]="!data.createNewClientAccount"
                                                 (change)="selectedAccountChanged()">
            </cb-client-accounts-input-find-cards>
            <cb-checkbox label="Create New Client Account"
                         name="createNewClientAccount"
                         (change)="createClientAccountChange()"
                         [(ngModel)]="data.createNewClientAccount">
            </cb-checkbox>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="leadQualifyDesignBuildForm.pristine || leadQualifyDesignBuildForm.invalid"
                (cbClick)="save()"
                [clickLimit]="1">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
