<div #infiniteScrollContainer
     class="cb-padding-left-2x cb-padding-right-2x cb-padding-bottom-2x cb-padding-top flex-row flex">
    <div class="flex-col flex-100">
        <div class="flex-row flex-100">
            <div class="cb-margin-right-3x flex-col flex-33">
                <h4 class="cb-title cb-margin-top-2x cb-margin-bottom-2x">Account Info</h4>
                <cb-input type="text"
                          name="firstName"
                          label="Name"
                          [maxlength]="100"
                          [required]="true"
                          [(ngModel)]="account.name"></cb-input>
                <cb-input type="text"
                          name="legalName"
                          label="Legal Name"
                          [maxlength]="100"
                          [required]="true"
                          [(ngModel)]="account.legalName"></cb-input>
                <cb-select [required]="true"
                           name="accountType"
                           label="Account Type"
                           [disabled]="account.status === CLIENT_ACCOUNT_STATUS_ENUM.Locked"
                           [options]="accountTypesLookup"
                           [(ngModel)]="account.accountType">
                </cb-select>
                <cb-input type="text"
                          name="irdNumber"
                          label="IRD Number"
                          [disabled]="account.status === CLIENT_ACCOUNT_STATUS_ENUM.Locked"
                          [required]="true"
                          [(ngModel)]="account.irdNumber"
                          [maskType]="'IRD'"
                          [maxlength]="11"></cb-input>
                <cb-input type="text"
                          name="solicitor"
                          label="Solicitor"
                          [maxlength]="100"
                          [required]="true"
                          [(ngModel)]="account.solicitor"></cb-input>
                <cb-input type="email"
                          name="solicitorEmail"
                          label="Solicitor Email"
                          [maxlength]="100"
                          [required]="true"
                          [(ngModel)]="account.solicitorEmail"></cb-input>
                <cb-input type="text"
                          name="abmCode"
                          label="ABM Code"
                          [maxlength]="100"
                          [required]="true"
                          [(ngModel)]="account.abmCode"></cb-input>
                <mat-checkbox name="isGstRegistered"
                              style="height: 25px;"
                              class="cb-margin-bottom"
                              [disabled]="account.status === CLIENT_ACCOUNT_STATUS_ENUM.Locked"
                              [(ngModel)]="account.isGstRegistered">Is GST Registered</mat-checkbox>
                <cb-select [required]="true"
                           name="status"
                           label="Account Status"
                           [options]="accountStatusLookup"
                           [(ngModel)]="account.status">
                </cb-select>
            </div>
            <div class="cb-margin-right-3x flex-col flex-33">
                <cb-address-new [(ngModel)]="account.physicalAddress"
                                addressTitle="Physical Address"
                                name="physicalAddress">
                </cb-address-new>
            </div>
            <div class="cb-margin-right-3x flex-col flex-33">
                <cb-address-new [(ngModel)]="account.postalAddress"
                                addressTitle="Postal Address"
                                name="postalAddress"
                                [required]="!allowPostalAddressBlank(account.postalAddress)">
                </cb-address-new>
            </div>
        </div>
        <div class="flex-row flex-100">
            <div class="flex-col flex-100">
                <div class="flex-col">
                    <div class="flex-row">
                        <h3 class="mat-headline-6 flex"
                            style="margin: 0;">Contacts
                        </h3>
                        <span class="flex"></span>
                        <button mat-raised-button
                                color="primary"
                                class="cb-margin-bottom-2x cb-margin-right-2x"
                                (click)="addContact()">
                            Add Contact
                        </button>
                    </div>
                </div>
                <div class="cb-table cb-margin-top">
                    <mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="isMainContact">
                            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <mat-checkbox name="isMainContact"
                                              style="height: 0;"
                                              [disabled]="element.id === account.mainContactId"
                                              [checked]="element.id === account.mainContactId"
                                              (change)="changeOption($event, element.id)"
                                              class="cb-margin-bottom"></mat-checkbox>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="title">
                            <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{SALUTATION_ENUM[element.title]}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{getFullName(element)}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef>Email </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{element.email }} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="phoneHome">
                            <mat-header-cell *matHeaderCellDef> Home Phone </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.phoneHome}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="phoneWork">
                            <mat-header-cell *matHeaderCellDef> Work Phone </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.phoneWork}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="phoneMobile">
                            <mat-header-cell *matHeaderCellDef> Mobile </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.phoneMobile}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="address">
                            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.address}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="isStaffMember">
                            <mat-header-cell *matHeaderCellDef> Staff </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <mat-checkbox [disabled]="true"
                                              name="getsSurveys"
                                              style="height: 0;"
                                              [(ngModel)]="element.isStaffMember"
                                              class="cb-margin-bottom"></mat-checkbox>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{CONTACT_STATUS_ENUM[element.status]}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions"
                                      stickyEnd>
                            <mat-header-cell *matHeaderCellDef>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <button *ngIf="canRemoveContact(element.id)"
                                        mat-icon-button
                                        (click)="removeContact(element.id, $event)">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                    <cb-info-message *ngIf=" contacts.length === 0"
                                     [message]="'Please add a Contact for this Account.'"
                                     [icon]="'warning'"
                                     [iconClass]="'mat-warn'"></cb-info-message>
                </div>
            </div>
        </div>
    </div>
</div>
