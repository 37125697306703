<mat-form-field class="flex"
                *ngIf="!readonly"
                [ngClass]="{'cb-soft-required': softRequired && !required }">
    <mat-label>{{ label }}</mat-label>
    <input matInput
           [name]="internalName"
           [(ngModel)]="value"
           [ignoreDirty]="ignoreDirty"
           [disabled]="disabled"
           [required]="required"
           readonly
           [min]="minDate"
           [max]="maxDate"
           [owlDateTime]="dt1"
           [owlDateTimeTrigger]="dt1"
           [owlDateTimeFilter]="dateFilter"/>
    <mat-icon *ngIf="!readonly" matSuffix class="calendar-icon">event</mat-icon>
    <owl-date-time #dt1 [pickerType]="pickerType"></owl-date-time>
</mat-form-field>

<div class="flex" *ngIf="readonly">
    <cb-display-value-new class="flex" [label]="label">
        {{ value | cbDate }}
    </cb-display-value-new>
</div>
