import {Component, Inject, OnInit} from '@angular/core';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {FormMode} from '@app/shared/enums/form';
import {ToastService} from '@app/core/services/toast/toast.service';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IManageDialogData} from '@app/shared/interfaces/i.manage-dialog-data';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {cloneDeepSafe} from '@app/shared/utils/clone-object.util';
import {IPreConsentPlanMappedItem} from '@app/logic/pre-consent-plans';
import {IPreConsentPlansLogicService} from '@app/logic/pre-consent-plans/interfaces/i.pre-consent-plans-logic.service';
import {IPreConsentPlanItemDto} from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/PreConsentPlanItemDto';
import {IPreConsentPlanDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-request-pre-consent-edit-item-dialog',
    templateUrl: './request-pre-consent-edit-item-dialog.component.html',
    styleUrls: ['./request-pre-consent-edit-item-dialog.component.scss']
})
export class RequestPreConsentEditItemDialogComponent extends
    BaseDialogFormViewDirective<IPreConsentPlanDto, IPreConsentPlanMappedItem, IPreConsentPlansLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '900px';

    public mappedItem: IPreConsentPlanMappedItem;
    public mode: FormMode;
    public itemId: number;
    public description: string;
    public designerView: boolean;

    public item: IPreConsentPlanItemDto;
    public tempItem: IPreConsentPlanItemDto;

    public edit = FormMode.Edit;
    public add = FormMode.Add;

    constructor(
        private readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<RequestPreConsentEditItemDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<IPreConsentPlanMappedItem> &
        { item: IPreConsentPlanItemDto; mode: FormMode; designerView: boolean },
        public readonly cbDialog: CbDialogService
    ) {
        super(dialogRef, toastService, cbDialog);
        this.mappedItem = data.mappedItem;
        this.item = data.item;
        this.mode = data.mode;
        this.designerView = data.designerView;
    }

    public ngOnInit(): void {
        this.tempItem = cloneDeepSafe(this.item);
    }

    public saveDialogItem(): void {

        if (!this.mappedItem.items) {
            this.mappedItem.items = [];
        }

        this.mappedItem.items.push(this.tempItem);

        this.cancel();
    }

    public saveDialogEditItem(): void {

        const index = this.mappedItem.items.findIndex(item => item === this.item);

        // Replace the item by index.
        this.mappedItem.items.splice(index, 1, this.tempItem);

        this.cancel();
    }

}
