import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IDesignConceptMappedItem} from '@app/logic/design-concepts';

interface IData {
    mappedItem: IDesignConceptMappedItem;
    showClientSaleLostWarning: boolean;
}

@Component({
    selector: 'app-design-concept-sell-price-dialog',
    templateUrl: './design-concept-sell-price-dialog.component.html',
    styleUrls: ['./design-concept-sell-price-dialog.component.scss']
})
export class DesignConceptSellPriceDialogComponent {

    public static readonly MIN_WIDTH = '400px';

    constructor(
        public readonly dialogRef: MatDialogRef<DesignConceptSellPriceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData
    ) { }

    public save(): void {
        this.data.mappedItem.$save()
            .subOnce(result => {
                this.data.mappedItem.$updateThisAndOriginal(result);
                this.dialogRef.close(result);
            });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
