import { Component, OnInit, Inject } from '@angular/core';
import { BuildTypeLogicService } from '@app/logic/build-type';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IPaymentTemplateDto } from '@app/logic/payment-templates/interface/i.payment-template.dto';
import { LotContractTypeEnumId, LOT_CONTRACT_TYPE_ENUM, IBuildTypeDto, IPaymentTemplateBuildTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { PaymentTemplatesLogicService } from '@app/logic/payment-templates/payment-templates.logic.service';

@Component({
    templateUrl: './payment-template-build-type-dialog.component.html',
    styleUrls: ['./payment-template-build-type-dialog.component.scss'],
    providers: [
        BuildTypeLogicService,
    ]
})
export class PaymentTemplateBuildTypeDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '45%';

    public availableBuildTypes: IBuildTypeDto[];
    public availableContractTypes = LOT_CONTRACT_TYPE_ENUM.toLookup().filter(x => x.id !== LOT_CONTRACT_TYPE_ENUM.None);
    public selectedBuildTypeId: number;
    public selectedContractTypeId: LotContractTypeEnumId;
    public paymentTemplateBuildType: IPaymentTemplateBuildTypeDto;
    public isDefaultPaymentBuildType = false;
    public defaultTemplate: IPaymentTemplateDto;

    private allBuildTypes: IBuildTypeDto[];


    constructor(
        public readonly dialogRef: MatDialogRef<PaymentTemplateBuildTypeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            existingBuildTypes: IPaymentTemplateBuildTypeDto[];
            selectedContractTypeId: LotContractTypeEnumId;
            selectedBuildTypeId: number;
            isDefaultPaymentBuildType: boolean;
            paymentTemplateId: number;
        },
        public readonly toastService: ToastService,
        private readonly buildTypeLogic: BuildTypeLogicService,
        public readonly paymentTemplateLogic: PaymentTemplatesLogicService,
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.buildTypeLogic.$getList()
            .subOnce((buildTypes) => {
                this.allBuildTypes = buildTypes;
                this.filterAvailableBuildTypes();

                if (this.data.selectedContractTypeId) {
                    this.selectedContractTypeId = this.data.selectedContractTypeId;
                }

                if (this.data.selectedBuildTypeId) {
                    this.selectedBuildTypeId = this.data.selectedBuildTypeId;
                }

                this.isDefaultPaymentBuildType = this.data.isDefaultPaymentBuildType;

                if (this.selectedContractTypeId && this.selectedBuildTypeId) {
                    this.getDefaultPaymentTemplatesByContractTypeAndBuildType();
                }
            });
    }

    public getDefaultPaymentTemplatesByContractTypeAndBuildType(): void {
        this.paymentTemplateLogic
            .getDefaultByContractTypeAndBuildType(this.selectedContractTypeId, this.selectedBuildTypeId)
            .subOnce((results) => {
                this.defaultTemplate = results;
            });
    }


    public filterAvailableBuildTypes(): void {
        this.availableBuildTypes = this.allBuildTypes
            .filter(buildType =>
                this.data.existingBuildTypes?.findIndex(existing => existing.buildTypeId === buildType.id && existing.lotContractType === this.selectedContractTypeId) < 0
            );
    }

    public save(): void {
        const selectedBuildType = this.availableBuildTypes.find(x => x.id === this.selectedBuildTypeId);
        const selectedContractType = this.availableContractTypes.find(x => x.id === this.selectedContractTypeId);
        if (!selectedBuildType) {
            this.selectedBuildTypeId = null;
            return;
        }
        if (!selectedContractType) {
            this.selectedContractTypeId = null;
            return;
        }
        this.paymentTemplateBuildType = {
            lotContractType: selectedContractType.id,
            buildTypeId: selectedBuildType.id,
            code: selectedBuildType.code,
            isDefault: this.isDefaultPaymentBuildType,
            label: selectedBuildType.label,
            isActive: selectedBuildType.isActive,
        };
        this.dialogRef.close(this.paymentTemplateBuildType);
    }
}
