<mat-card>
    <mat-card-content>
        <button *ngIf="canCreateVariation()"
                [disabled]="!canGenerateVariations()"
                mat-raised-button
                color="primary"
                (click)="openGenerateVariationDialog()">
            Generate Variation ({{ $numberofUnassignedChangeRecords }})
        </button>
    </mat-card-content>
</mat-card>