import { ClientSalePermissions } from '@app/core/permissions';
import { Component, Input } from '@angular/core';
import { BaseClientSaleCardDirective } from '../base-client-sale-card';
import { MatDialog } from '@angular/material/dialog';
import { ChangeDesignConceptDialogComponent } from '../../dialogs/change-design-concept-dialog/change-design-concept-dialog.component';
import { ClientSaleDocumentsService } from '../../documents-service/client-sale-documents.service';
import { LotsLogicService } from '@app/logic/lots';
import { takeOne } from 'cb-hub-lib';
import { DocumentTemplateLogicService } from '@app/logic/document-template';
import { DOCUMENT_TEMPLATE_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { IClientSaleMappedItem } from '@app/logic/client-sale';

@Component({
    selector: 'cb-client-sale-design-card',
    templateUrl: './design-card.component.html',
    styleUrls: ['./design-card.component.scss'],
    providers: [
        DocumentTemplateLogicService,
    ]
})
export class ClientSaleDesignCardComponent extends BaseClientSaleCardDirective {

    @Input() public readonly stepNumber: number;

    @Input() public readonly clientSale: IClientSaleMappedItem;

    public get isComplete(): boolean {
        return this.clientSale?.stepCompletion?.design;
    }

    constructor(
        public readonly permissions: ClientSalePermissions,
        public readonly dialog: MatDialog,
        public readonly clientSaleDocuments: ClientSaleDocumentsService,
        public readonly lotsLogicService: LotsLogicService,
        public readonly documentTemplateLogicService: DocumentTemplateLogicService,
    ) {
        super(clientSaleDocuments);
    }

    public generateSiteEvaluation(): void {
        this.lotsLogicService.generateSiteEvaluation(this.clientSale.lot.id).pipe(takeOne()).subscribe();
    }

    public downloadPricingTemplate(): void {
        this.documentTemplateLogicService.downloadDocumentTemplate(DOCUMENT_TEMPLATE_CODES_CONST.LotPricingTemplate).subOnce();
    }

    public changeConcept(): void {
        this.dialog
            .open(ChangeDesignConceptDialogComponent, {
                data: { clientSale: this.clientSale.$clone() },
                panelClass: 'cb-dialog-container',
                minWidth: 400
            })
            .afterClosed()
            .subOnce({ next: this.recomputeIconProps });
    }
}
