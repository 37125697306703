import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILocationLogicService } from '@app/logic/location/interfaces/i.location.logic.service';
import { LocationMappedItem } from '@app/logic/location/location.mapped';
import { IBusinessEntityDto } from '@app/logic/business-entity';
import { IAddressRegionDto, IIdAndLabelDto, ILocationDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { map, some, find, filter } from 'lodash';
import { ILocationMappedItem } from '@app/logic/location/interfaces/i.location.mapped';

interface IData {
    mappedItem: LocationMappedItem;
    locations: ILocationDto[];
    businessEntities: IBusinessEntityDto[];
    addressRegions: IAddressRegionDto[];
}

@Component({
    selector: 'cb-location-dialog',
    templateUrl: './location-dialog.component.html',
    styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent extends BaseDialogFormViewDirective<ILocationDto, ILocationMappedItem, ILocationLogicService> {
    public static readonly MIN_WIDTH = '50%';
    public mappedItem: LocationMappedItem;
    public parents: IIdAndLabelDto[];

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<LocationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        this.mappedItem.locationType = data.mappedItem.locationType ?? 1;
        this.mappedItem.businessEntityId = data.mappedItem.businessEntity?.id ?? undefined;
        this.mappedItem.addressRegionId = data.mappedItem.addressRegion?.id ?? undefined;
        this.parents = map(filter(data.locations, (item: ILocationDto) => item.id !== this.mappedItem.id && item.locationType !== 3),
            (item: ILocationDto) => {
                const parentName = item.parentId ? find(data.locations, { id: item.parentId })?.name : undefined;
                return {
                    id: item.id,
                    label: parentName ? [parentName, item.name].join(' > ') : item.name
                };
            }).sort((a, b) => a.label.localeCompare(b.label));
    }

    public isCodeDuplicated(): boolean {
        return some(this.data.locations, (item: ILocationDto) =>
            item.locationType === 1 && item.code === this.mappedItem.code && item.id !== this.mappedItem.id);
    }
}
