<cb-dialog dialogHeading="{{data.isEdit ? 'Edit Document Details' : 'Document Information'}}">
    <form class="flex-col"
          #documentInfoForm="ngForm">
        <h1 *ngIf="data.isEdit"
            class="mat-subtitle-1">Edit Details for {{data.documentEntityMappedItem.documentType.label}}</h1>
        <mat-form-field [class.no-line]="!data.isEdit">
            <mat-label>Document Name</mat-label>
            <input matInput
                   type="text"
                   name="name"
                   [disabled]="!data.isEdit"
                   [(ngModel)]="data.documentEntityMappedItem.document.name"
                   required/>
        </mat-form-field>
        <mat-form-field *ngIf="!data.isEdit"
                        [class.no-line]="!data.isEdit">
            <mat-label>Document Type</mat-label>
            <input matInput
                   type="text"
                   name="type"
                   disabled
                   [(ngModel)]="data.documentEntityMappedItem.documentType.label"/>
        </mat-form-field>
        <mat-form-field class="flex flex-row"
                        *ngIf="!data.isEdit && data.documentEntityMappedItem.document.description"
                        [class.no-line]="!data.isEdit">
            <mat-label>Document Description</mat-label>
            <textarea class="readonly-textarea"
                      #description
                      matInput
                      type="text"
                      name="description"
                      [maxlength]="500"
                      [disabled]="!data.isEdit"
                      [(ngModel)]="data.documentEntityMappedItem.document.description">
                </textarea>
        </mat-form-field>
        <mat-form-field class="flex flex-row"
                        *ngIf="data.isEdit">
            <mat-label>Document Description</mat-label>
            <textarea #description
                      matInput
                      type="text"
                      name="description"
                      [maxlength]="500"
                      [disabled]="!data.isEdit"
                      [(ngModel)]="data.documentEntityMappedItem.document.description">
                </textarea>
            <mat-hint *ngIf="data.isEdit"
                      align="end">{{description.value.length}} / 500</mat-hint>
        </mat-form-field>
        <mat-form-field *ngIf="!data.isEdit && data.documentEntityMappedItem.document.filename"
                        [class.no-line]="!data.isEdit">
            <mat-label>File Name</mat-label>
            <input matInput
                   type="text"
                   name="filename"
                   disabled
                   [(ngModel)]="data.documentEntityMappedItem.document.filename"/>
        </mat-form-field>
        <mat-form-field *ngIf="!data.isEdit"
                        [class.no-line]="!data.isEdit">
            <mat-label>File Size</mat-label>
            <input matInput
                   type="text"
                   name="fileSize"
                   disabled
                   [(ngModel)]="data.documentEntityMappedItem.document.fileSize"/>
        </mat-form-field>
        <mat-form-field *ngIf="!data.isEdit"
                        [class.no-line]="!data.isEdit">
            <mat-label>Created</mat-label>
            <input matInput
                   type="text"
                   name="createdDate"
                   disabled
                   [ngModel]="getUserDateUpdatedInfo()"/>
        </mat-form-field>
        <mat-form-field *ngIf="!data.isEdit"
                        [class.no-line]="!data.isEdit">
            <mat-label>Updated</mat-label>
            <input matInput
                   type="text"
                   name="updatedDate"
                   disabled
                   [ngModel]="getUserDateCreatedInfo()"/>
        </mat-form-field>
    </form>
    <div actions>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="onSubmit()"
                *ngIf="data.isEdit"
                [disabled]="!(documentInfoForm.dirty ) || !documentInfoForm.valid ">Save</button>
        <button mat-raised-button
                (click)="cancel()">Close</button>
    </div>
</cb-dialog>
