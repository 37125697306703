<cb-dialog class="flex-col"
           dialogHeading="View System Log">
    <cb-display-value-new label="System Datetime"
                          [value]="data.log.eventDate | date:'dd/MM/yyyy hh:mm:ss'"></cb-display-value-new>
    <cb-display-value-new label="Instance ID"
                          [value]="data.log.instanceId"></cb-display-value-new>
    <cb-display-value-new label="Instance Private IP"
                          [value]="data.log.instancePrivateIp"></cb-display-value-new>
    <cb-display-value-new label="Source"
                          [value]="data.log.source"></cb-display-value-new>
    <cb-display-value-new label="Exception"
                          [value]="data.log.logError"></cb-display-value-new>
    <cb-display-value-new class="cb-margin-top flex"
                          label="System Details"
                          [value]="data.log.eventMessage">
    </cb-display-value-new>
    <div actions>
        <button mat-raised-button
                (click)="close()">
            Close
        </button>
    </div>
</cb-dialog>
