<div class="cb-margin-left-2x cb-margin-top flex-col">
    <div class="cb-title">{{lotMappedItem?.jobNumber}}</div>
    <mat-divider class="cb-margin-bottom"></mat-divider>
    <p class="cb-margin-bottom">
        <b>Lot {{lotMappedItem?.lotNumber}}</b>
    </p>
    <cb-display-value-new class="flex"
                          label="Client Account"
                          [value]="lotMappedItem?.clientAccount?.legalName"
                          [showBtn]="lotMappedItem?.clientAccount != null"
                          [btnHref]="clientRoute">
    </cb-display-value-new>
    <cb-display-value-new label="Lot Address">
        <span>
            {{lotMappedItem?.lotAddress?.street}}
            <br/>
            {{lotMappedItem?.lotAddress?.suburb}}
            <br/>
            {{lotMappedItem?.lotAddress?.city}}
        </span>
    </cb-display-value-new>
    <cb-display-value-new class="flex"
                          label="Lot Type">
        <span> {{lotMappedItem?.lotType | cbEnumToLabel: LOT_TYPE_ENUM}}</span>
    </cb-display-value-new>
    <cb-display-value-new class="flex"
                          label="Lot Contract Type">
        <span> {{lotMappedItem?.contractType | cbEnumToLabel: LOT_CONTRACT_TYPE_ENUM}} </span>
    </cb-display-value-new>
    <cb-display-value-new class="flex"
                          label="DP Number"
                          [value]="lotMappedItem?.dpNumber">
    </cb-display-value-new>
    <cb-display-value-new class="flex"
                          *ngIf="!lotMappedItem.hasUnits"
                          label="Building Consent"
                          [value]="lotMappedItem?.buildingConsent?.consentNumber">
    </cb-display-value-new>
    <cb-display-value-new class="flex"
                          *ngIf="!lotMappedItem.hasUnits"
                          label="Applied Concept"
                          [value]="lotMappedItem?.appliedConceptName">
    </cb-display-value-new>
    <cb-display-value-new class="flex"
                          *ngIf="!lotMappedItem.hasUnits"
                          label="Finalised Design Scheme"
                          [value]="lotMappedItem?.latestCompleteDesignSchemeNumber">
    </cb-display-value-new>
    <div class="flex-row flex">
        <cb-display-value-new class="flex"
                              label="Floor Area">
            <div class="start-center">
                <mat-icon class="cb-grey"
                          svgIcon="floor-plan"></mat-icon>
                <span class="mat-body-2">&nbsp;{{
                    lotMappedItem.amenities.floorArea | cbDasher
                    }}m²</span>
            </div>
        </cb-display-value-new>
        <cb-display-value-new class="flex"
                              label="Land Area">
            <div class="start-center">
                <mat-icon class="cb-grey">border_style</mat-icon>
                <span class="mat-body-2">&nbsp;{{
                    lotMappedItem.amenities.landArea | cbDasher
                    }}m²</span>
            </div>
        </cb-display-value-new>
    </div>
    <cb-amenities *ngIf="!lotMappedItem.hasUnits"
                  [amenities]="amenities"></cb-amenities>
    <div class="flex-row flex">
        <cb-display-value-new class="flex"
                              label="Carpark">
            <div class="start-center">
                <mat-icon class="cb-grey">directions_car</mat-icon>
                <span class="mat-body-2">&nbsp;{{lotMappedItem.amenities?.noCarparks | cbDasher}}</span>
            </div>
        </cb-display-value-new>
        <cb-display-value-new class="flex"
                              label="Study">
            <div class="start-center">
                <mat-icon class="cb-grey">import_contacts</mat-icon>
                <span class="mat-body-2">&nbsp;{{lotMappedItem.amenities?.noStudy | cbDasher}}</span>
            </div>
        </cb-display-value-new>
    </div>
    <mat-divider class="cb-margin-bottom"></mat-divider>
    <cb-display-value-new class="flex"
                          *ngIf="lotMappedItem.parentId > 0"
                          [label]="'Parent - '+ LOT_TYPE_ENUM[lotMappedItem?.parentLotType]"
                          [value]="lotMappedItem?.parentJobNumber"
                          [showBtn]="true"
                          [btnHref]="parentRoute">
    </cb-display-value-new>
    <cb-display-value-new class="flex"
                          *ngIf="lotMappedItem?.project?.id"
                          label="Project"
                          [value]="lotMappedItem?.project?.label"
                          [showBtn]="true"
                          [btnHref]="projectRoute">
    </cb-display-value-new>
    <cb-display-value-new class="flex"
                          *ngIf="lotMappedItem?.projectReleaseStage?.id"
                          label="Release Stage"
                          [value]="lotMappedItem?.projectReleaseStage?.label"
                          [showBtn]="true"
                          [btnHref]="releaseStageRoute">
    </cb-display-value-new>
    <div class="cb-margin center-stretch flex-col">
        <button mat-raised-button
                color="primary"
                class="cb-margin-bottom"
                [disabled]="!canEditLotDetails()"
                (click)="editLot()">
            Edit Lot
        </button>
        <button *ngIf="lotPermissions.canManageClassicContacts()"
                mat-raised-button
                color="primary"
                class="cb-margin-bottom"
                (click)="editClassicContacts()">
            Classic Contacts
        </button>
        <button *ngIf="lotMappedItem?.hasClientSale"
                mat-raised-button
                color="primary"
                class="cb-margin-bottom"
                (click)="viewClientSale()">
            View Client Sale
        </button>
    </div>
</div>
