import { Component, Inject } from '@angular/core';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IScheduleTemplateMappedItem } from '@app/logic/schedule-templates/interfaces/i.schedule-template.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import {
    CostTypeEnumId,
    COST_TYPE_ENUM,
    IBundleTemplateItemDto,
    IIdAndLabelDto,
    IProductOfferingDto,
    IScheduleTemplateItemDto,
    ISlotDto,
    ISpecGroupDto,
    LOT_SPEC_TEMPLATE_COST_TYPE_ENUM,
    UNIT_OF_MEASURE_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IScheduleTemplateItemMappedItem } from '@app/logic/schedule-template-items/interfaces/i.schedule-template-item.mapped';
import { IScheduleTemplateItemsLogicService } from '@app/logic/schedule-template-items/interfaces/i.schedule-template-items.logic-service';
import { IManageDialogData } from '@app/shared/interfaces/i.manage-dialog-data';
import { toPromisedArray } from 'cb-hub-lib';
import { SpecGroupsLogicService } from '@app/logic/spec-groups';
import { map } from 'rxjs';
import { find, forEach, orderBy } from 'lodash';
import { IProductMappedItem, ProductLogicService } from '@app/logic/products';
import { SlotsLogicService } from '@app/logic/slots/slots.logic-service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'cb-manage-schedule-template-item-dialog',
    templateUrl: './manage-schedule-template-item-dialog.component.html',
    styleUrls: ['./manage-schedule-template-item-dialog.component.scss']
})
export class ManageScheduleTemplateItemDialogComponent
    extends BaseDialogFormViewDirective<
    IScheduleTemplateItemDto, IScheduleTemplateItemMappedItem, IScheduleTemplateItemsLogicService> {
    public static readonly MIN_WIDTH = '60%';

    public mappedItem: IScheduleTemplateItemMappedItem;
    public parentMappedItem: IScheduleTemplateMappedItem;
    public selectedHouseArea: ISpecGroupDto;
    public uploadingImage = false;
    public selectedArea: ISpecGroupDto;
    public houseAreas = toPromisedArray(this.specGroupsLogicService.$getList().pipe(
        map(results => orderBy(results, 'sortOrder', 'asc'))));
    public COST_TYPE_ENUM = COST_TYPE_ENUM;
    public scheduleTemplateCostTypes = LOT_SPEC_TEMPLATE_COST_TYPE_ENUM.toLookup();
    public isBundleItem: boolean;
    public bundleTemplateItem: IBundleTemplateItemDto;
    public selectedProduct: IProductMappedItem | string | any;
    public offeringCategoryId: number;
    public productSearchText: string;
    public productUom: string;
    public slot: ISlotDto;
    public productOptions: any = {};
    public categoryLoaded: boolean;
    public suppliers: IIdAndLabelDto[];
    public hasRecievedStartupEmission = false;

    constructor(
        private readonly toastService: ToastService,
        private readonly slotService: SlotsLogicService,
        private readonly specGroupsLogicService: SpecGroupsLogicService,
        public dialogRef: MatDialogRef<ManageScheduleTemplateItemDialogComponent>,
        public readonly productLogicService: ProductLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<IScheduleTemplateItemMappedItem> & { parentMappedItem: IScheduleTemplateMappedItem },
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.parentMappedItem = data.parentMappedItem;

        this.slotService.$getItem(this.mappedItem.slotId).subOnce(slot => {
            this.slot = slot;
            this.offeringCategoryId = this.slot?.categories[0]?.id;
            this.categoryLoaded = true;
        });

        if (this.mappedItem?.product?.id) {
            this.productLogicService.$getMappedItem(this.mappedItem.product.id).subOnce(product => {
                this.selectedProduct = product;
                this.setSelectedProductImage();
            });
        } else {
            if (this.mappedItem.productOther) {
                this.selectedProduct = this.mappedItem.productOther;
                this.productSearchText = this.mappedItem.productOther;
                this.selectedProduct = { name: this.mappedItem.productOther };
            }
        }
    }

    public get shouldHideQuantity(): boolean {
        return this.mappedItem.costType === COST_TYPE_ENUM.Provisional || !this.mappedItem.costType;
    }

    public isSupplierViewable = (): boolean => {
        const currentCostType = find(this.scheduleTemplateCostTypes, { id: this.mappedItem.costType });
        if (!currentCostType || this.parentMappedItem.isNational) {
            return false;
        }
        return (this.mappedItem.costType === COST_TYPE_ENUM.Actual && this.selectedProduct !== undefined);
    };

    public productSearchTextChanged(str: string): void {
        // Stop inital emission of '' from clearing the product
        if (this.hasRecievedStartupEmission) {
            this.productSearchText = str;
            this.mappedItem.product = null;
            this.productUom = null;
        }
        this.hasRecievedStartupEmission = true;
    }

    public productSelectedFromAutoComplete = (data?: IProductOfferingDto | null): void => {
        if (data != null) {
            if (data.id) {
                this.productLogicService.$getMappedItem(data.id).subOnce(res => {
                    this.selectedProduct = res;
                    this.setSelectedProductImage();
                    this.setProductValues(data);
                });
            } else {
                this.selectedProduct = data;
            }

            if (this.selectedProduct?.images && this.selectedProduct?.images[0]?.id) {
                this.mappedItem.hasProductImage = true;
            } else {
                this.mappedItem.hasProductImage = false;
            }
            this.setProductValues(data);
        }
    };

    public onCostTypeChange(costType: CostTypeEnumId): void {
        this.mappedItem.product = undefined;
        this.selectedProduct = undefined;
        this.productSearchText = undefined;
        if (costType === COST_TYPE_ENUM.Actual) {
            this.mappedItem.productOther = undefined;
        }
        if (costType === COST_TYPE_ENUM.Estimate || costType === COST_TYPE_ENUM.Provisional) {
            this.mappedItem.rateSnapshot = 0;
        }
    }

    public shouldShowQuoteRequired = (): boolean => {
        return this.mappedItem.costType === COST_TYPE_ENUM.Provisional
            || this.mappedItem.costType === COST_TYPE_ENUM.Estimate
            || this.mappedItem.costType === COST_TYPE_ENUM.NoCharge;
    };

    private setSelectedProductImage(): void {
        if (this.selectedProduct?.images[0]?.id) {
            this.selectedProduct.baseImageUrl += `${this.selectedProduct.images[0].id}.${this.selectedProduct.images[0].extension}`;
        } else {
            this.selectedProduct.baseImageUrl = undefined;
        }
    }

    public removeImage(form: UntypedFormGroup): void {
        this.mappedItem.$shouldRemoveImageOnSave = true;
        this.mappedItem.scheduleItemImageUrl = undefined;
        form.markAsDirty();
    }

    private setProductValues(data: IProductOfferingDto): void {
        if (this.selectedProduct.images?.length) {
            this.mappedItem.productImageUrl = this.selectedProduct.baseImageUrl;
        }
        this.productSearchText = data.name;
        this.productUom = UNIT_OF_MEASURE_ENUM[data.uom];
        if (this.selectedProduct && this.mappedItem?.product?.id !== this.selectedProduct?.id) {
            this.mappedItem.productOther = null;
            this.loadProduct();
        }
    }

    public clearProductForDescriptive(): void {
        if (this.mappedItem.costType === COST_TYPE_ENUM.DescriptiveOnly) {
            this.selectedProduct = undefined;
            this.selectedProduct = null;
            this.mappedItem.hasProductImage = false;
            this.mappedItem.product = null;
            if (this.mappedItem?.costType !== COST_TYPE_ENUM.DescriptiveOnly) {
                this.productSearchText = null;
            }
        }
    }

    public getRateLabel(): string {
        const costType = find(this.scheduleTemplateCostTypes, { id: this.mappedItem.costType });
        if (costType) {
            return costType.label + ' Amount';
        }
        return 'Amount';
    }

    public presave(): void {

        if (this.shouldHideQuantity) {
            // quantity is hidden so default to 1;
            this.mappedItem.quantity = 1;
        }

        if (this.mappedItem.supplierId === 0) {
            delete this.mappedItem.supplierId;
        }
    }

    public save(): void {
        this.saveInProgress = true;
        this.presave();
        this.saveMethod().subOnce({
            next: (result) => {
                this.handleNext(this.handleSaveSuccess(result));
                this.saveInProgress = false;
            },
            error: (error) => {
                this.handleError(this.handleSaveError(error));
                this.saveInProgress = false;
            }
        });
    }

    public selectFile = (newFile: File, form: UntypedFormGroup): void => {
        if (this.uploadingImage) {
            return;
        }
        this.mappedItem.$scheduleItemImageFile = newFile;

        const reader = new FileReader();
        reader.addEventListener(
            'load', () => {
                this.mappedItem.scheduleItemImageUrl = reader.result as any;
                this.mappedItem.$shouldRemoveImageOnSave = false;
                form.markAsDirty();
            },
            false
        );

        if (newFile) {
            reader.readAsDataURL(newFile);
        }
    };

    public loadProduct = (): void => {
        if (!this.selectedProduct) {
            return;
        }

        this.suppliers = [{ id: 0, label: 'Not Specified' }];
        forEach(this.selectedProduct.rates, (rate) => {
            if (rate.businessAccountId && !find(this.suppliers, { id: rate.businessAccountId })) {
                this.suppliers.push({ id: rate.businessAccountId, label: rate.vendor });
            }
        });

        if (!this.mappedItem?.product) {
            this.mappedItem.product = { id: 0, name: '' };
        }
        if (this.selectedProduct.hasProductImage && this.selectedProduct?.images?.length) {
            this.mappedItem.productImageUrl = this.selectedProduct.images[0];
        } else {
            this.mappedItem.productImageUrl = null;
        }
        this.mappedItem.product.id = (this.selectedProduct as IProductOfferingDto)?.id;
        this.mappedItem.product.name = (this.selectedProduct as IProductOfferingDto)?.name;
        this.productUom = UNIT_OF_MEASURE_ENUM[(this.selectedProduct as IProductOfferingDto)?.uom];
    };
}
