import { BaseLogicService } from '@app/logic/base/base-logic.service';
import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { Observable } from 'rxjs';

import { IProductCentreFilterMappedItem } from '@app/logic/product-centre/interfaces/i.product-centre-filter.mapped';
import { IProductCentreFilterLogicService } from '@app/logic/product-centre/interfaces/i.product-centre-filter.logic.service';
import { ProductCentreFilterMappedItem } from '@app/logic/product-centre/product-centre-filter.mapped';
import { IProductCentreFilterDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class ProductCentreFilterLogicService
    extends BaseLogicService<IProductCentreFilterDto, IProductCentreFilterMappedItem>
    implements IProductCentreFilterLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super('product-centre/filters', ProductCentreFilterMappedItem);
    }

    public updateFilter(dto: IProductCentreFilterDto): Observable<IProductCentreFilterDto> {
        return this.$http.put<IProductCentreFilterDto>(this.$baseUri, dto);
    }

    public createFilter(dto: IProductCentreFilterDto): Observable<IProductCentreFilterDto> {
        return this.$http.post<IProductCentreFilterDto>(this.$baseUri, dto);
    }

    public deleteFilter(id: string): Observable<void> {
        return this.$http.delete<void>(`${this.$baseUri}/${id}`);
    }
}
