import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import {
    ICostxRateLibraryDto,
    ICostxRateLibraryMappedItem,
    CostxRateLibraryLogicService,
    ICostxRateLibraryLogicService
} from '@app/logic/costx-rate-library';

@Component({
    templateUrl: './costx-export-date-dialog.component.html',
    styleUrls: ['./costx-export-date-dialog.component.scss']
})
export class CostxExportDateDialogComponent
    extends BaseDialogFormViewDirective<ICostxRateLibraryDto, ICostxRateLibraryMappedItem, ICostxRateLibraryLogicService>
    implements OnInit {
    public static readonly MIN_WIDTH = '40%';
    public effectiveDate = new Date();
    public includeCompositeItems = false;

    constructor(
        public readonly dialogRef: MatDialogRef<CostxExportDateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: { rateLibraryId?: number },
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CostxRateLibraryLogicService) public readonly costxRateLibraryLogic: CostxRateLibraryLogicService,
    ) {
        super(dialogRef, toastService);
    }

    public confirm(): void {
        if (this.data?.rateLibraryId > 0) {
            this.costxRateLibraryLogic
                .downloadCostXRateLibraryExport(this.data.rateLibraryId, this.effectiveDate.toJSON(), this.includeCompositeItems)
                .subOnce((result) => this.dialogRef.close(result));
        } else {
            this.costxRateLibraryLogic
                .downloadAllCostXRateLibraryExport(this.effectiveDate.toJSON(), this.includeCompositeItems)
                .subOnce((result) => this.dialogRef.close(result));
        }
    }
}
