<cb-dialog [dialogHeading]="dialogHeading.value">
    <form #createConsentForm="ngForm">
        <cb-input type="text"
                  name="consentFor"
                  label="Consent For"
                  [(ngModel)]="data.mappedItem.consentFor"
                  [required]="true"></cb-input>
        <cb-user-autocomplete-scroller label="Assigned To"
                                       name="assignedTo"
                                       [(ngModel)]="assignedTo"
                                       [tag]="BUILDING_COORDINATOR"
                                       [team]="DESIGN_TEAM"
                                       [tagOrTeam]="true"
                                       [active]="true"></cb-user-autocomplete-scroller>
        <cb-checkbox *ngIf="data.canAddPrimaryBuildingConsent"
                     label="Is Primary Building Consent"
                     name="primaryConsent"
                     [(ngModel)]="buildingConsent.isPrimary"></cb-checkbox>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (cbClick)="create()"
                [disabled]="createConsentForm.invalid"
                [clickLimit]="1">Create
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
