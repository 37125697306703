import { Component } from '@angular/core';
import { ProductPermissions } from '@app/core/permissions';
import { FeatureToggleStatesService } from '@app/core/services/feature-toggle-states/feature-toggle-states.service';
import { CurrentUserService } from '@app/core/authentication/current.user';

@Component({
    selector: 'cb-product-tabs',
    templateUrl: './product-tabs.component.html',
    styleUrls: ['./product-tabs.component.scss']
})
export class ProductTabsComponent {
    public isFeatureTogglesStateServiceInitialized = false;

    constructor(
        private readonly productPermissions: ProductPermissions,
        public readonly featureToggleStatesService: FeatureToggleStatesService,
        private readonly currentUser: CurrentUserService,
    ) {
        this.featureToggleStatesService.init().then(() => {
            this.isFeatureTogglesStateServiceInitialized = true;
        });
    }

    public canPerformImport = (): boolean => this.productPermissions && this.productPermissions.canPerformImport();
    public canExportCatalogue = (): boolean => this.productPermissions && this.productPermissions.canExportCatalogue();
    public canManageBuildOverheadCosts = (): boolean => this.productPermissions && this.productPermissions.canManageBuildOverheadCosts();

    public CanViewProductCentre(): boolean {
        return this.featureToggleStatesService.isProductCentreEnabled;
    }
}
