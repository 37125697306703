import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

interface IData {
    clientSale: IClientSaleMappedItem;
}

@Component({
    selector: 'cb-set-unconditional-dialog',
    templateUrl: './set-unconditional-dialog.component.html',
    styleUrls: ['./set-unconditional-dialog.component.scss'],
})
export class SetUnconditionalDialogComponent extends BaseDialogFormViewDirective<any, any, any> {

    public date = new Date();
    public now = new Date();

    constructor(
        public readonly dialogRef: MatDialogRef<SetUnconditionalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public save(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Set Lot as Unconditional',
            message: `Please ensure that any changes to the Lot Specification that need to be included in the client \
contract have been made BEFORE setting the Sale as unconditional.

Once a sale is unconditional, the Lot Specification is LOCKED and changes can be made via change options only.`,
            confirmed: this.handleSetUnconditional
        });
    }

    public handleSetUnconditional = (): void => {
        this.data.clientSale
            .setUnconditional(this.date)
            .subOnce({
                next: this.handleNext,
                error: this.handleError
            });
    };
}
