import { CbDasherModule } from 'cb-hub-lib';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AlertCardComponent } from './alert-card/alert-card.component';
import { CbDisplayValueModule } from '../display-value/display-value.module';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    declarations: [
        AlertCardComponent
    ],
    imports: [
        CommonModule,
        CbDisplayValueModule,
        MatIconModule,
        MatCardModule,
        CbDasherModule
    ],
    exports: [
        AlertCardComponent
    ]
})
export class CbAlertCardModule { }
