import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CbDialogModule} from '@app/shared/components/dialog/dialog.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CbFormsModule} from '@app/shared/components/forms/forms.module';
import {SearchIndexesComponent} from '@app/views/settings/pages/search-indexes/search-indexes.component';
import {CbHubTableModule} from '@app/shared/components/table/table.module';
import {CbInfoMessageModule, CbInfoPopupModule, CbLoadingSpinnerModule, CbTableModule} from 'cb-hub-lib';

@NgModule({
    declarations: [
        SearchIndexesComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbHubTableModule,
        CbTableModule,
        CbInfoPopupModule
    ],
    exports: [
        SearchIndexesComponent
    ]
})
export class SearchIndexesSettingsModule { }
