import { Component, OnInit, Inject } from '@angular/core';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { ILookupDto, LEAD_LOST_REASON_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { ILeadLostVMDto } from '@app/logic/leads/interfaces/i.lead.lost.vm.dto';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { FormMode } from '@app/shared/enums/form';
import { ILeadDto } from '@app/logic/leads';
import { Observable } from 'rxjs';
import { orderBy } from 'lodash';

interface IData {
    mappedItem: ILeadMappedItem;
}

@Component({
    selector: 'app-lead-lost-dialog',
    templateUrl: './lead-lost-dialog.component.html',
    styleUrls: ['./lead-lost-dialog.component.scss']
})
export class LeadLostDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '40%';

    public mappedItem: ILeadMappedItem;
    public lostReasons: ILookupDto[];
    public data: ILeadLostVMDto;

    constructor(
        public readonly dialogRef: MatDialogRef<LeadLostDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly dta: IData,
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
        this.formMode = FormMode.Edit;
        this.mappedItem = dta.mappedItem;
        this.lostReasons = LEAD_LOST_REASON_ENUM.toLookup({
            transform: (reasons) => {
                return orderBy(reasons, [(reason: any) => reason.label.toLowerCase()], 'asc');
            }
        });
    }

    public ngOnInit(): void {
        this.data = {
            comment: '',
            company: undefined,
            reason: 0,
        } as ILeadLostVMDto;
    }

    public isShowCompany(): boolean {
        return this.data?.reason === LEAD_LOST_REASON_ENUM.BuiltWithOthers;
    }

    public searchTextChangeCompany(text): void {
        this.data.company = text;
    }

    public selectedItemChangeCompany(item): void {
        if (item == undefined) {
            this.data.company = '';
        } else {
            this.data.company = item.name;
        }
    }

    public saveMethod(): Observable<ILeadDto> {
        return this.mappedItem.lost(this.data);
    }

}
