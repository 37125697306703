import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IReleaseStageMappedItem } from '@app/logic/release-stages/interfaces/i.release-stage.mapped';
import { ReleaseStagesLogicService } from '@app/logic/release-stages/release-stages.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { UtilFiles } from '@app/shared/utils/file.util';
import { PROJECT_RELEASE_STAGE_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { environment } from '@src/environments/environment';
import { stringifyReadableError } from 'cb-hub-lib';

interface IData {
    projectReleaseStageMappedItem: IReleaseStageMappedItem;
}

@Component({
    selector: 'cb-import-lots-dialog',
    templateUrl: './import-lots-dialog.component.html',
    styleUrls: ['./import-lots-dialog.component.scss']
})
export class ImportLotsDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '70%';
    public excelRegEx = /^(?:\w?\s?\-?\(?\)?)+\.(xlsx|xlsm|csv)$/i;
    public errorMessage: string;
    public projectReleaseStageMappedItem: IReleaseStageMappedItem;
    public document: any = {};
    public importCustomJobNumbers = false;
    public lotTemplateUrl: string;
    public importButtonClicked = false;
    constructor(
        public readonly dialogRef: MatDialogRef<ImportLotsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        private readonly toastService: ToastService,
        private readonly releaseLogicService: ReleaseStagesLogicService,
    ) {
        super(dialogRef, toastService);
        this.projectReleaseStageMappedItem = data.projectReleaseStageMappedItem;
    }

    public ngOnInit(): void {
        if (this.projectReleaseStageMappedItem.type === PROJECT_RELEASE_STAGE_TYPE_ENUM.DesignAndBuild) {
            this.lotTemplateUrl = environment.api + '/content/lots_dab.csv';
        } else {
            this.lotTemplateUrl = environment.api + '/content/lots_hal.csv';
        }
    }

    public disabled(form: UntypedFormGroup): boolean {
        return form.pristine || form.invalid || !this.document?.file || !!this.errorMessage || this.importButtonClicked;
    }

    public selectFile(uploadedFile: File, form: UntypedFormGroup): void {
        this.errorMessage = undefined;
        if (!uploadedFile) {
            return;
        }

        this.document.file = uploadedFile[0];
        this.checkFileExtension();
        form.markAsDirty();
    }

    public uploadFile(form: UntypedFormGroup, fileInput: any): any {
        this.importButtonClicked = true;
        if (this.checkFileExtension()) {
            this.releaseLogicService.importLots(
                this.projectReleaseStageMappedItem.id,
                this.document.file,
                this.importCustomJobNumbers)
                .subOnce({
                    next: result => {
                        this.importButtonClicked = false;
                        this.toastSerivce.saveSuccess();
                        this.dialogRef.close(result);
                    },
                    error: err => {
                        if (fileInput?.value) {
                            fileInput.value = '';
                        }
                        this.importButtonClicked = false;
                        this.errorMessage = stringifyReadableError(err.error);
                        this.document = {};
                        form.markAsPristine();
                        form.markAsUntouched();
                    }
                });
        }
    }

    private checkFileExtension(): boolean {
        if (this.document.file.name.match(UtilFiles.isExcelFile)) {
            return true;
        } else {
            this.errorMessage = 'The file you have selected has the wrong file type. Please select a .xlsm, .xlsx, or .csv file.';
            return false;
        }
    }

}
