import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { FormsModule } from '@angular/forms';

const MATERIAL_MODULES = [
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
];

@NgModule({
    declarations: [
        UserSettingsComponent
    ],
    imports: [
        ...MATERIAL_MODULES,
        CommonModule,
        FormsModule,
        CbFormsModule,
        RouterModule.forRoot([
            {
                component: UserSettingsComponent,
                path: 'user-settings',
                data: {
                    breadcrumb: 'User Settings'
                }
            }
        ], {}),
    ]
})
export class UserSettingsModule { }
