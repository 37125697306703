import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IStandardPlanStyleDto } from '@app/logic/styles/interfaces/i.style.dto';
import { DialogLoadingSpinnerComponent } from 'cb-hub-lib';
import { IStandardPlanMappedItem } from '@app/logic/standard-plans';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'cb-upload-image-dialog',
    templateUrl: './upload-image-dialog.component.html',
    styleUrls: ['./upload-image-dialog.component.scss']
})
export class UploadImageDialogComponent {
    public static readonly MIN_WIDTH = '400px';

    public isFilesizeValid = true;
    public isFileExtensionValid = true;

    public fileSize: number;
    public fileSizeFormatted: string;
    public fileExtension: string;

    public isFileValidAndUploaded = false;


    constructor(
        public readonly dialogRef: MatDialogRef<UploadImageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IData,
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar,
    ) { }

    public onSubmit(): void {

        const message = 'Uploading Document...';
        const loadingDialog = this.dialog.open(
            DialogLoadingSpinnerComponent, {
                data: { message },
                width: '400px',
                height: '100px',
                panelClass: 'cb-dialog-translucent-bg'
            });

        this.data.plan.postImage(this.data.plan.uploadedImage, this.data.style).subOnce(response => {

            let responseImage;

            if (this.data.style.id > 0) {
                responseImage = response.images.find(x => x.styleId === this.data.style.id);  // Image for Plan Styles
            } else {
                responseImage = response.images.find(x => x.styleId === null);  // Image for Floor Plan
            }

            const oldImageIndex = this.data.plan.stylesWithUrl.findIndex(x => x.id === this.data.style.id);
            const newUrl = `${environment.api}/${responseImage.path}/images/${responseImage.id}.${responseImage.extension}`;

            if (oldImageIndex !== -1) {
                this.data.plan.stylesWithUrl[oldImageIndex].url = newUrl.toLocaleLowerCase();
            }

            loadingDialog.close();
            this.cancel();
            this.openSnackBar('Image Uploaded', 'OK');
        });

    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    private openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['snackbar']
        });
    }


    public uploadFile(uploadedFile: File): void {
        this.isFileValidAndUploaded = false;
        if (this.isImageFile(uploadedFile[0])) {
            this.setDocument(uploadedFile[0]);
        }
    }

    public isImageFile(uploadedFile: File): boolean {
        return uploadedFile && uploadedFile.type.split('/')[0] === 'image';
    }

    private setDocument(uploadedFile: File): void {
        this.data.plan.uploadedImage = uploadedFile;
        this.isFileValidAndUploaded = true;
    }

}


export interface IData {
    plan: IStandardPlanMappedItem;
    style: IStandardPlanStyleDto;
}
