import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from '@app/core/services/search/base.search.service';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';
import { BuildProgrammeActivityStatusEnumId, IBuildProgrammeActivityLookupDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { takeOne } from 'cb-hub-lib';
import { map, Observable } from 'rxjs';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { AutocompleteScrollerComponent, getBaseAutocompleteScrollerProvider } from '../../autocomplete-scroller.component';

@Component({
    selector: 'cb-build-programme-activity-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(BuildProgrammeActivityAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(BuildProgrammeActivityAutocompleteScrollerComponent),
        SearchService,
    ]
})
export class BuildProgrammeActivityAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent<IBuildProgrammeActivityLookupDto> {

    private _lotId: number;
    public get lotId(): number {
        return this._lotId;
    }
    @Input() public set lotId(newVal: number) {
        this.lotIdHasChanged = this._lotId !== newVal;
        this._lotId = newVal;
    }

    private _buildProgrammeId: number;
    public get buildProgrammeId(): number {
        return this._buildProgrammeId;
    }
    @Input() public set buildProgrammeId(newVal: number) {
        this.buildProgrammeIdHasChanged = this._buildProgrammeId !== newVal;
        this._buildProgrammeId = newVal;
    }

    @Input() public inclStatuses: BuildProgrammeActivityStatusEnumId[] = [];
    @Input() public exclStatuses: BuildProgrammeActivityStatusEnumId[] = [];
    @Input() public exclIds: number[] = [];
    /** null means this filter will be ignored. \
     * boolean means this will filter results accordingly.
     */
    @Input() public isControlledByParentLot: boolean | null;

    /** @returns string can contain html tags, it will be rendered/bound using [innerHtml] */
    @Input() public itemLabel: (item: IBuildProgrammeActivityLookupDto | null) => string;

    public results: IBuildProgrammeActivityLookupDto[] = [];
    private resultsLoaded = false;
    private lotIdHasChanged = false;
    private buildProgrammeIdHasChanged = false;

    constructor(
        public readonly buildProgrammeLogicService: BuildProgrammeLogicService,
        public readonly dialog: MatDialog,
        public readonly searchService: SearchService
    ) {
        super(dialog, searchService);
        this.searchService.doSearch = this.doSearch;
    }

    public doSearch = (query: string, page: number): Observable<IBuildProgrammeActivityLookupDto[]> => {
        return this.getResults()
            .pipe(takeOne(), map((results) => {
                const take = 10;
                const skip = page > 0 ? (page - 1) * take : 0;

                const filtered = results
                    .filter(x =>
                        (this.isControlledByParentLot != null ? x.isControlledByParentLot === this.isControlledByParentLot : true)
                        && (this.inclStatuses.length < 1 || this.inclStatuses.includes(x.statusId))
                        && (this.exclStatuses.length < 1 || !this.exclStatuses.includes(x.statusId))
                        && (this.exclIds.length < 1 || !this.exclIds.includes(x.id))
                        && ((this.displayWith(x) || '').toLowerCase().indexOf(query.toLowerCase()) > -1)
                    );

                return filtered.slice(skip, skip + take);
            }));
    };

    /** @returns string can contain html tags, it will be rendered/bound using [innerHtml] */
    public displayWith(item: any): string {
        return this.itemLabel != null ? this.itemLabel(item) : (item ? item.label : null);
    }

    /** Reloads results if applicable */
    private getResults(): Observable<IBuildProgrammeActivityLookupDto[]> {
        return new Observable<IBuildProgrammeActivityLookupDto[]>((subscriber) => {
            if ((this.lotId || this.buildProgrammeId) && (!this.resultsLoaded || this.lotIdHasChanged || this.buildProgrammeIdHasChanged)) {
                this.resultsLoaded = true;
                this.lotIdHasChanged = false;
                this.buildProgrammeIdHasChanged = false;

                const resultsObservable = this.buildProgrammeId > 0
                    ? this.buildProgrammeLogicService.getBuildProgrammeActivitiesLookup(this.buildProgrammeId)
                    : this.buildProgrammeLogicService.getBuildProgrammeActivitiesLookupByLotId(this.lotId);

                resultsObservable
                    .subOnce(x => {
                        this.results = x;
                        subscriber.next(this.results);
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this.results);
                subscriber.complete();
            }
        });
    }
}
