<mat-card class="cb-margin flex-col">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon">
            <mat-icon [ngClass]="getStatusIconClass()"
                      class="help-cursor"
                      [matTooltip]="getStatusIconTitle()">{{ getStatusIconIcon() }}
            </mat-icon>
        </div>
        <mat-card-title> {{ designConcept.name }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="cb-margin-bottom-nil">
        <div class="flex-col">
            <cb-display-value-new *ngIf="designConcept?.designScheme?.id > 0"
                                  label="Design Scheme"
                                  [value]=" designConcept?.designScheme?.name"></cb-display-value-new>
            <cb-display-value-new *ngIf="designConcept?.workingDrawing?.id > 0"
                                  label="Working Drawing"
                                  [value]="designConcept?.workingDrawing?.name"></cb-display-value-new>
            <cb-display-value-new label="Lot Specification Version"
                                  [value]="designConcept.lotSpecVersion"></cb-display-value-new>
            <cb-display-value-new label="Pricing Revision">
                <span>{{ designConcept?.pricingRevision?.name }}</span>
                <span *ngIf="designConcept.pricingRevision?.isEstimatedPrice"> (Estimated)</span>
            </cb-display-value-new>
            <cb-display-value-new label="Status"
                                  [value]="designConcept.statusId | cbEnumToLabel: DESIGN_CONCEPT_STATUS_ENUM">
            </cb-display-value-new>
        </div>
    </mat-card-content>
    <mat-divider class="card-divider"></mat-divider>
    <mat-card-actions>
            <div class="cb-margin flex center-right flex-row">
                <button mat-stroked-button
                        color="accent"
                        (click)="viewDesignConcept()">
                    View
                </button>
            </div>
    </mat-card-actions>
</mat-card>
