<mat-form-field class="flex" style="width: 100%;"
                [ngClass]="{'cb-soft-required': softRequired && !required }"
                *ngIf="!readonly">
    <mat-label>{{ label }}</mat-label>
    <span matPrefix
          *ngIf="value"
          [ngClass]="{'is-credit': getIsCreditClass()}">
        $
    </span>
    <input matInput
           #inputRef
           cbTwoDp
           [name]="internalName"
           type="text"
           [(ngModel)]="value"
           [ignoreDirty]="ignoreDirty"
           [required]="required"
           [maxlength]="maxlength + valueFormatCharCount"
           [pattern]="pattern"
           autocomplete="off"
           [disabled]="disabled"
           [ngClass]="{'is-credit': getIsCreditClass() }"/>
</mat-form-field>
<div *ngIf="readonly && value && (displayCreditBrackets() || displayCredit() || displayNonCredit())">
    <cb-display-value-new *ngIf="displayCreditBrackets()"
                          [label]="label"
                          class="is-credit">
        ({{-cleanCurrencyValue(value) | cbCurrency}})
    </cb-display-value-new>
    <cb-display-value-new *ngIf="displayCredit()"
                          [label]="label"
                          class="is-credit">
        {{cleanCurrencyValue(value) | cbCurrency}}
    </cb-display-value-new>
    <cb-display-value-new *ngIf="displayNonCredit()"
                          [label]="label">
        {{cleanCurrencyValue(value) | cbCurrency}}
    </cb-display-value-new>
</div>
