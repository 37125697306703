import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactsLogicService } from '@app/logic/contacts';
import { CONTACT_STATUS_ENUM, ACCOUNT_TYPE_ENUM, IBusinessAccountDetailsDto, IContactAccountDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { NavigationService } from '@app/core/services/navigation/navigation.service';

@Component({
    selector: 'cb-account-list-dialog',
    templateUrl: './account-list-dialog.component.html',
    styleUrls: ['./account-list-dialog.component.scss']
})
export class AccountListDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '400px';

    public readonly displayedColumns: string[] = [
        'abmCode',
        'status',
        'name',
        'type',
        'actions'
    ];

    public CONTACT_STATUS_ENUM = CONTACT_STATUS_ENUM;
    public ACCOUNT_TYPE_ENUM = ACCOUNT_TYPE_ENUM;

    public resultList: IContactAccountDto[];

    constructor(
        public readonly navigationService: NavigationService,
        public readonly contactsLogic: ContactsLogicService,
        public readonly dialogRef: MatDialogRef<AccountListDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: string
    ) { }

    public ngOnInit(): void {
        this.contactsLogic.getClientAccountsList(this.data)
            .subOnce(leads => { this.resultList = leads; }
            );
    }

    public viewAccount(account: IBusinessAccountDetailsDto, event: MouseEvent): void {
        this.dialogRef.close();
        this.navigationService.navigate([`accounts/${account.id}/summary`], event);
    }
}
