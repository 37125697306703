<div class="cb-margin flex-col flex">
    <div *ngIf="canCreateRFI()"
         class="cb-margin-bottom end-center flex-row">
        <span class="start-center flex-row flex"
              *ngIf="councilRfis?.length < 1">
            <mat-icon class="mat-accent">info</mat-icon>
            <span class="mat-body-2">&nbsp;There are no RFIs for this
                Consent</span>
        </span>
        <button mat-raised-button
                color="primary"
                (click)="addCouncilRfi()"
                [disabled]="!mappedItem?.canAddCouncilRfisToConsent">Add Council RFI</button>
    </div>
    <cb-council-rfi-list-card class="cb-margin-bottom"
                              *ngFor="let rfi of councilRfis"
                              [councilRfiDto]="rfi"
                              (viewCouncilRfi)="view($event)"
                              [redirect]="true"></cb-council-rfi-list-card>
</div>
