<mat-card *cbWaitFor="mappedItem?.id > 0"
          class="cb-margin-right flex-col flex">
    <mat-card-header>
        <mat-card-title>Pricing Revision - {{ mappedItem.revisionNumber }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="flex-col flex">
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Status"
                                      [value]="mappedItem.status"></cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Margin %">{{ mappedItem.margin | number: '1.2' }}
                </cb-display-value-new>
            </div>
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Price Complexity"
                                      [value]="mappedItem?.complexityCode"></cb-display-value-new>
                <cb-currency class="flex"
                             label="Land Price"
                             name="landPrice"
                             [(ngModel)]="mappedItem.landPrice"
                             [readonly]="true"></cb-currency>
            </div>
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Due By"
                                      [value]="mappedItem.dueBy | cbDate"></cb-display-value-new>
                <cb-currency class="flex"
                             label="Build Cost"
                             name="buildCost"
                             [(ngModel)]="mappedItem.buildCost"
                             [readonly]="true"></cb-currency>
            </div>
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Assigned To"
                                      [value]="mappedItem.assignedToUserName"></cb-display-value-new>
                <cb-currency class="flex"
                             label="Build Price"
                             name="buildPrice"
                             [(ngModel)]="mappedItem.lotBuildPrice"
                             [readonly]="true"></cb-currency>
            </div>
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Elapsed Time"
                                      [value]="durationText"></cb-display-value-new>
                <cb-currency class="flex"
                             label="Build Price - (Gst Inclusive)"
                             name="buildPriceGstIncl"
                             [inclGst]="true"
                             [(ngModel)]="mappedItem.lotBuildPrice"
                             [readonly]="true"></cb-currency>
            </div>
            <div class="flex-row">
                <cb-checkbox class="flex"
                             label="Resource Consent Required"
                             name="resourceConsentRequired"
                             [(ngModel)]="mappedItem.reviewRequired"
                             [readonly]="true"></cb-checkbox>
                <cb-checkbox class="flex"
                             label="Estimated Pricing"
                             name="estimatedPricing"
                             [(ngModel)]="mappedItem.isEstimatedPrice"
                             [readonly]="true"></cb-checkbox>
            </div>
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Reviewer"
                                      [value]="mappedItem.reviewerName"></cb-display-value-new>
            </div>
            <div class="flex-row">
                <cb-display-value-new class="flex"
                                      label="Comments and Assumptions"
                                      [value]="mappedItem.qsNotes"></cb-display-value-new>
            </div>
            <mat-divider class="cb-margin-bottom"></mat-divider>
            <div class="flex-row">
                <cb-user-date-time-new label="Created by"
                                       [floatingLabel]="true"
                                       [date]="mappedItem.createdDate"
                                       [userFullName]="mappedItem.createdByName"
                                       [displayTime]="true"></cb-user-date-time-new>
            </div>
            <div class="flex-row">
                <cb-user-date-time-new [floatingLabel]="true"
                                       label="Finalised by"
                                       [date]="mappedItem.finalisedDate"
                                       [userFullName]="mappedItem.finalisedByUserName"
                                       [displayTime]="true"></cb-user-date-time-new>
            </div>
        </div>
    </mat-card-content>
</mat-card>