<cb-dialog [dialogHeading]="getTitle()">
    <form class="flex-col flex"
          #addHouseLandLeadForm="ngForm">
        <cb-leads-input-find-cards label="Select Lead"
                                   [(ngModel)]="mappedItem"
                                   name="selectedLead"
                                   [text]="mappedItem?.name"
                                   [removeable]="true"
                                   #selectedLead="ngModel"
                                   (change)="validateSelectedLead()"
                                   [required]="true"
                                   [searchParams]="leadSearchParams">
        </cb-leads-input-find-cards>
        <div class="flex-col"
             style="margin-top: -16px;">
            <span class="mat-caption cb-error"
                  *ngIf="mappedItem != null && !selectedLead.valid">This House &amp; Land Lot Interest already exists on
                the selected Lead</span>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (cbClick)="saveLeadInterest()"
                [disabled]="!addHouseLandLeadForm.dirty || !addHouseLandLeadForm.valid"
                [clickLimit]="1"
        >
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
