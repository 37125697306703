<div #infiniteScrollContainer
     class="cb-padding-left-2x cb-padding-right-2x cb-padding-bottom-2x cb-padding-top flex-col flex">
    <mat-card class="flex-col flex-100">
        <mat-card-content>
            <div class="flex-col flex-100">
                <div class="flex-col">
                    <h3 class="mat-headline-6 flex"
                        style="margin: 0;">Client Accounts
                    </h3>
                    <mat-divider class="cb-margin-bottom"></mat-divider>
                </div>
                <div class="cb-table cb-margin-top">
                    <mat-table [dataSource]="clientAccounts">
                        <ng-container matColumnDef="abmCode">
                            <mat-header-cell *matHeaderCellDef> ABM Code</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.account }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ CLIENT_ACCOUNT_STATUS_ENUM[element.status] }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.legalName }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ ACCOUNT_TYPE_ENUM[element.accountType] }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="lots">
                            <mat-header-cell *matHeaderCellDef> No. Lots</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.noLots }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="address">
                            <mat-header-cell *matHeaderCellDef> Address</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.address }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="city">
                            <mat-header-cell *matHeaderCellDef> City</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.city }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="region">
                            <mat-header-cell *matHeaderCellDef> Region</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.region }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions"
                                      stickyEnd>
                            <mat-header-cell *matHeaderCellDef>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <button mat-icon-button
                                        (click)="viewClientAccount(element, $event)">
                                    <mat-icon>pageview</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="
                    clientAccountColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: clientAccountColumns;"></mat-row>
                    </mat-table>
                    <div class="cb-margin flex-col">
                        <cb-info-message *ngIf="clientAccountsLoaded && clientAccounts.length === 0"
                                         [message]="'No Client Accounts Found'"
                                         [icon]="'info_circle'"></cb-info-message>
                        <cb-loading-spinner *ngIf="!clientAccountsLoaded"></cb-loading-spinner>
                    </div>
                </div>
            </div>
            <div class="flex-col flex-100">
                <div class="flex-col">
                    <h3 class="mat-headline-6 cb-padding-top-2x flex"
                        style="margin: 0;">Business Accounts
                    </h3>
                    <mat-divider class="cb-margin-bottom"></mat-divider>
                </div>
                <div class="cb-table cb-margin-top">
                    <mat-table [dataSource]="businessAccounts">
                        <ng-container matColumnDef="abmCode">
                            <mat-header-cell *matHeaderCellDef> ABM Code</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.abmCode }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="tradingName">
                            <mat-header-cell *matHeaderCellDef>Trading Name</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.tradingName }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="legalName">
                            <mat-header-cell *matHeaderCellDef>Legal Name</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.legalName }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef> Type</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.type }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ CLIENT_ACCOUNT_STATUS_ENUM[element.status] }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions"
                                      stickyEnd>
                            <mat-header-cell *matHeaderCellDef>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <button mat-icon-button
                                        (click)="viewBusinessAccount(element, $event)">
                                    <mat-icon>pageview</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="businessAccountColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: businessAccountColumns;"></mat-row>
                    </mat-table>
                    <div class="cb-margin flex-col">
                        <cb-info-message *ngIf="businessAccountsLoaded && businessAccounts.length === 0"
                                         [message]="'No Business Accounts Found'"
                                         [icon]="'info_circle'"></cb-info-message>
                        <cb-loading-spinner *ngIf="!businessAccountsLoaded"></cb-loading-spinner>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
