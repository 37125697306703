<form class="flex-col flex"
      #productRateExceptionSearchForm="ngForm">
    <div class="center-center flex-row flex">
        <mat-card class="flex-row layoutGap-20 flex">
            <mat-card-content class="mat-card-content-row">
                <cb-business-accounts-input-find-cards class="flex-25"
                                                       label="Supplier"
                                                       [(ngModel)]="userCacheService.productRateExceptionSearch.data.selectedSupplier"
                                                       name="businessAccount"
                                                       [text]="userCacheService.productRateExceptionSearch.data.selectedSupplier?.tradingName"
                                                       [removeable]="false"
                                                       [readonly]="searchIsLoading"
                                                       [searchParams]="productUsageBusinessAccountSearchParams">
                </cb-business-accounts-input-find-cards>
                <cb-input type="text"
                          name="searchFilter"
                          class="cb-margin-right-2x flex"
                          label="Job #"
                          [maxlength]="25"
                          [disabled]="searchIsLoading"
                          [(ngModel)]="userCacheService.productRateExceptionSearch.data.query">
                </cb-input>
                <cb-input type="number"
                          name="purchaseOrderNumber"
                          class="cb-margin-right-2x flex"
                          label="PO #"
                          [maxlength]="20"
                          [disabled]="searchIsLoading"
                          [(ngModel)]="userCacheService.productRateExceptionSearch.data.purchaseOrderNumber">
                </cb-input>
                <cb-datepicker name="activityStartDate"
                               class="cb-margin-right flex"
                               [(ngModel)]="userCacheItem.data.activityStartDate"
                               [required]="false"
                               label="Activity Start"
                               [disabled]="searchIsLoading"
                               pickerType="calendar">
                </cb-datepicker>
                <cb-datepicker name="activityEndDate"
                               class="cb-margin-right flex"
                               [(ngModel)]="userCacheItem.data.activityEndDate"
                               [required]="false"
                               label="Activity End"
                               [disabled]="searchIsLoading"
                               pickerType="calendar">
                </cb-datepicker>
                <div class="end-center flex-row cb-margin-right">
                    <button mat-icon-button
                            [matMenuTriggerFor]="popUpSearchMenu"
                            [disabled]="searchIsLoading">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                </div>
                <mat-menu #popUpSearchMenu="matMenu"
                          class="mat-menu-panel">
                    <div class="cb-margin-top cb-margin-bottom start flex-col">

                        <!-- Created From Date -->
                        <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row">
                            <mat-icon class="cb-grey">date_range</mat-icon>
                            <cb-datepicker name="createdFromDate"
                                           class="cb-margin-right flex"
                                           [(ngModel)]="userCacheItem.data.createdFromDate"
                                           [required]="false"
                                           label="Created From"
                                           [disabled]="searchIsLoading"
                                           pickerType="calendar">
                            </cb-datepicker>
                        </div>

                        <!-- Created To Date -->
                        <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row">
                            <mat-icon class="cb-grey">date_range</mat-icon>
                            <cb-datepicker name="createdToDate"
                                           class="cb-margin-right flex"
                                           [(ngModel)]="userCacheItem.data.createdToDate"
                                           [required]="false"
                                           label="Created To"
                                           [disabled]="searchIsLoading"
                                           pickerType="calendar">
                            </cb-datepicker>
                        </div>

                        <!-- Product -->
                        <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row">
                            <mat-icon class="cb-grey">shopping_bag</mat-icon>
                            <cb-product-autocomplete-scroller class="flex"
                                                              label="Product"
                                                              name="altProduct"
                                                              [displayCategories]="true"
                                                              [requireMatch]="false"
                                                              (click)="$event.stopPropagation()"
                                                              [(ngModel)]="userCacheService.productRateExceptionSearch.data.selectedProduct"
                                                              [hideCompositeItems]="true">
                            </cb-product-autocomplete-scroller>
                        </div>

                        <!-- CB Business Entity -->
                        <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x start-center flex-row">
                            <mat-icon class="cb-grey">supervisor_account</mat-icon>
                            <cb-select-list class="cb-margin-right-2x flex"
                                            label="CB Business Entity"
                                            [allOptions]="businessEntities"
                                            [multiple]="true"
                                            [disabled]="searchIsLoading"
                                            (click)="$event.stopPropagation()"
                                            (selectChanged)="selectedBusinessEntity($event)"
                                            [value]="userCacheService.productRateExceptionSearch.data.selectedBusinessEntity">
                            </cb-select-list>
                        </div>
                        <div class="flex-row">
                            <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x end-center flex-col">
                                <button mat-stroked-button
                                        [disabled]="noSearchResultsFound"
                                        (click)="exportToCsv()">
                                    <mat-icon>file_download</mat-icon>
                                    Export Results
                                </button>
                            </div>
                            <div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-2x end-center flex-col">
                                <button mat-stroked-button
                                        [disabled]="!filterChips || filterChips.length == 0"
                                        (click)="clearAll()">
                                    <mat-icon>clear_all</mat-icon>
                                    &nbsp;Clear Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-menu>
                <div class="end-center flex-row">
                    <button mat-raised-button
                            [disabled]="searchIsLoading"
                            color="primary"
                            (click)="performSearch()"
                            class="cb-margin-right-2x">
                        Search
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="flex-row"
         *ngIf="filterChips && filterChips.length > 0">
        <mat-card class="flex-100">
            <mat-card-content>
                <mat-chip-set>
                    <mat-chip *ngFor="let chip of filterChips; let index = index;"
                              (removed)="removeChip(chip)">
                        <strong class="mat-body-2">{{ chip.text }}</strong>
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                </mat-chip-set>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="flex-row flex"
         *ngIf="!searchIsLoading">
        <div class="flex-col flex">
            <div class="cb-table cb-margin-top">
                <mat-table [dataSource]="searchResults"
                           matSort
                           #poTbSort="matSort"
                           #exceptionTable
                           (matSortChange)="sortExceptionList($event)">

                    <!-- Checkbox Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.Checkbox"
                                  sticky>
                        <mat-header-cell *matHeaderCellDef
                                         class="select-actions-col">
                            <button mat-icon-button
                                    [matMenuTriggerFor]="checkedMenu"
                                    matTooltip="Perform bulk action"
                                    class="checkbox-menu"
                                    (click)="$event.stopPropagation()"
                                    [disabled]="noSearchResultsFound">
                                <mat-icon>playlist_add_check</mat-icon>
                            </button>
                            <mat-menu #checkedMenu="matMenu">
                                <button mat-menu-item
                                        (click)="toggleAllChecked($event)">
                                    <mat-icon>done_all</mat-icon>
                                    <span>&nbsp;{{ toggleAllLabel() }}</span>
                                </button>
                                <button mat-menu-item
                                        (click)="reconfirmPurchaseOrders()"
                                        [disabled]="!anyPurchaseOrdersChecked()">
                                    <mat-icon>rotate_right</mat-icon>
                                    <span>&nbsp;{{ reconfirmTooltipLabel() }}</span>
                                </button>
                            </mat-menu>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"
                                  class="select-actions-col">
                            <div class="center-center flex-fill">
                                <mat-checkbox [(ngModel)]="checkedPurchaseOrders[element.purchaseOrderNumber]"
                                              [ngModelOptions]="{ standalone: true}">
                                </mat-checkbox>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <!-- Supplier Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.Supplier">
                        <mat-header-cell *matHeaderCellDef
                                         mat-sort-header>
                            {{ COLUMN_NAMES.Supplier }}
                            <cb-sortable-header-cell [sort]="sort"
                                                     [cell]="COLUMN_NAMES.Supplier"></cb-sortable-header-cell>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <cb-info-popup [label]="element.supplier">
                            </cb-info-popup>
                        </mat-cell>
                    </ng-container>

                    <!-- JobNumber Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.JobNumber">
                        <mat-header-cell *matHeaderCellDef
                                         mat-sort-header>
                            {{ COLUMN_NAMES.JobNumber }}
                            <cb-sortable-header-cell [sort]="sort"
                                                     [cell]="COLUMN_NAMES.JobNumber"></cb-sortable-header-cell>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <cb-info-popup [label]="element.jobNumber">
                            </cb-info-popup>
                        </mat-cell>
                    </ng-container>

                    <!-- PurchaseOrderNumber Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.PurchaseOrderNumber">
                        <mat-header-cell *matHeaderCellDef
                                         mat-sort-header>
                            {{ COLUMN_NAMES.PurchaseOrderNumber }}
                            <cb-sortable-header-cell [sort]="sort"
                                                     [cell]="COLUMN_NAMES.PurchaseOrderNumber"></cb-sortable-header-cell>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <cb-info-popup [label]="element.purchaseOrderNumber">
                            </cb-info-popup>
                        </mat-cell>
                    </ng-container>

                    <!-- ProductCode Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.ProductCode">
                        <mat-header-cell *matHeaderCellDef
                                         mat-sort-header>
                            {{ COLUMN_NAMES.ProductCode }}
                            <cb-sortable-header-cell [sort]="sort"
                                                     [cell]="COLUMN_NAMES.ProductCode"></cb-sortable-header-cell>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.productCode }}
                        </mat-cell>
                    </ng-container>

                    <!-- ItemName Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.ItemName">
                        <mat-header-cell *matHeaderCellDef
                                         mat-sort-header>
                            {{ COLUMN_NAMES.ItemName }}
                            <cb-sortable-header-cell [sort]="sort"
                                                     [cell]="COLUMN_NAMES.ItemName"></cb-sortable-header-cell>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.itemName }}
                        </mat-cell>
                    </ng-container>

                    <!-- Rate PO Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.JobCost">
                        <mat-header-cell *matHeaderCellDef
                                         mat-sort-header>
                            {{ COLUMN_NAMES.JobCost }}
                            <cb-sortable-header-cell [sort]="sort"
                                                     [cell]="COLUMN_NAMES.JobCost"></cb-sortable-header-cell>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.jobCost | currency }}
                        </mat-cell>
                    </ng-container>

                    <!-- Rate Hub Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.CurrentRate">
                        <mat-header-cell *matHeaderCellDef
                                         mat-sort-header>
                            {{ COLUMN_NAMES.CurrentRate }}
                            <cb-sortable-header-cell [sort]="sort"
                                                     [cell]="COLUMN_NAMES.CurrentRate"></cb-sortable-header-cell>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.currentRate | currency }}
                            <mat-icon *ngIf="element.currentRate > 0"
                                      class="cb-small-icon"
                                      [matTooltip]="getHubRateTooltip(element)">info_circle
                            </mat-icon>
                        </mat-cell>
                    </ng-container>

                    <!-- Variance Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.Variance">
                        <mat-header-cell *matHeaderCellDef
                                         mat-sort-header>
                            {{ COLUMN_NAMES.Variance }}
                            <cb-sortable-header-cell [sort]="sort"
                                                     [cell]="COLUMN_NAMES.Variance"></cb-sortable-header-cell>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ getVariance(element) | currency }}
                        </mat-cell>
                    </ng-container>

                    <!-- Quantity Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.Quantity">
                        <mat-header-cell *matHeaderCellDef
                                         mat-sort-header>
                            {{ COLUMN_NAMES.Quantity }}
                            <cb-sortable-header-cell [sort]="sort"
                                                     [cell]="COLUMN_NAMES.Quantity"></cb-sortable-header-cell>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.quantity }}
                        </mat-cell>
                    </ng-container>

                    <!-- StartDate Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.StartDate">
                        <mat-header-cell *matHeaderCellDef
                                         mat-sort-header>
                            {{ COLUMN_NAMES.StartDate }}
                            <cb-sortable-header-cell [sort]="sort"
                                                     [cell]="COLUMN_NAMES.StartDate"></cb-sortable-header-cell>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.startDate | date }}
                        </mat-cell>
                    </ng-container>

                    <!-- EndDate Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.EndDate">
                        <mat-header-cell *matHeaderCellDef
                                         mat-sort-header>
                            {{ COLUMN_NAMES.EndDate }}
                            <cb-sortable-header-cell [sort]="sort"
                                                     [cell]="COLUMN_NAMES.EndDate"></cb-sortable-header-cell>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.endDate | date }}
                        </mat-cell>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container [matColumnDef]="COLUMN_NAMES.Actions"
                                  stickyEnd>
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span>
                                <button [matMenuTriggerFor]="menu"
                                        mat-icon-button
                                        disabled="false">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item
                                            disabled="true">
                                        <mat-icon>mode_edit</mat-icon>
                                        <span>To be implemented</span>
                                    </button>
                                    <button mat-menu-item
                                            disabled="true">
                                        <mat-icon>help_outline</mat-icon>
                                        <span>To be implemented</span>
                                    </button>
                                </mat-menu>
                            </span>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row [class.table-pink]="row.manualOrder"
                             *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
            </div>
            <cb-info-message message="No Product Rate Exceptions found"
                             *ngIf="!searchIsLoading && noSearchResultsFound">
            </cb-info-message>
        </div>
    </div>
    <cb-loading-spinner *ngIf="searchIsLoading"></cb-loading-spinner>
</form>
