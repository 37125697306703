import {AfterViewChecked, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CurrentUserService} from '@app/core/authentication/current.user';
import {ToastService} from '@app/core/services/toast/toast.service';
import {LotsLogicService} from '@app/logic/lots';
import {IPricingRevisionMappedItem} from '@app/logic/pricing-revisions/interfaces/i.pricing-revision.mapped';
import {IPricingRevisionsLogicService} from '@app/logic/pricing-revisions/interfaces/i.pricing-revisions.logic.service';
import {PricingRevisionsLogicService} from '@app/logic/pricing-revisions/pricing-revisions.logic.service';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
import {IPricingRevisionDto, IPricingRevisionGrossMarginDto, LOT_CONTRACT_TYPE_ENUM, PRICING_REVISION_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {BehaviorSubject, combineLatest, map, mergeMap, skipWhile, startWith, Subscription, switchMap} from 'rxjs';


@Component({
    selector: 'cb-finalise-pricing-revision-dialog',
    templateUrl: './finalise-pricing-revision-dialog.component.html',
    styleUrls: ['./finalise-pricing-revision-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalisePricingRevisionDialogComponent extends BaseDialogFormViewDirective<
IPricingRevisionDto,
IPricingRevisionMappedItem,
IPricingRevisionsLogicService> implements OnInit, AfterViewChecked, OnDestroy {
    public static readonly MIN_WIDTH = '800px';
    public minDate: Date = new Date();
    @ViewChild(NgForm) public finaliseFormLiteral: NgForm;
    private _isFormInitialised = false;

    public _canFinaliseBehaviorSubject$ = new BehaviorSubject<boolean>(null);
    public isReprice$ = new BehaviorSubject<boolean>(null);
    public finaliseForm$ = new BehaviorSubject<NgForm>(null);
    public currentMappedItem$ = new BehaviorSubject<IPricingRevisionMappedItem>(null);
    private _subscriptions = new Subscription();

    public grossMargin: number;
    public grossMarginPercent: number;

    public set currentMappedItem(mappedItem: IPricingRevisionMappedItem) {
        this.currentMappedItem$?.next(mappedItem);
    }

    public get currentMappedItem(): IPricingRevisionMappedItem {
        return this.currentMappedItem$?.value;
    }

    public previousMappedItem$ = new BehaviorSubject<IPricingRevisionMappedItem>(null);

    public set previousMappedItem(mappedItem: IPricingRevisionMappedItem) {
        this.previousMappedItem$?.next(mappedItem);
    }

    public get previousMappedItem(): IPricingRevisionMappedItem {
        return this.previousMappedItem$?.value;
    }

    public set isReprice(value: boolean) {
        this.isReprice$?.next(value);
    }

    public get isReprice(): boolean {
        return this.isReprice$?.value;
    }

    @ViewChild(NgForm) public set finaliseForm(form: NgForm) {
        this.finaliseForm$?.next(form);
    }

    public get finaliseForm(): NgForm {
        return this.finaliseForm$?.value;
    }

    public lot$ = this.currentMappedItem$.asObservable().pipe(
        skipWhile(currentMappedItem => !currentMappedItem),
        mergeMap(currentMappedItem => this
            .lotLogicService.$getItem(currentMappedItem.lotId)
        )
    );

    public canEditLandPrice$ = this.lot$.pipe(
        skipWhile(lot => !lot),
        map(lot => {
            return lot.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild ||
                lot.contractType === LOT_CONTRACT_TYPE_ENUM.ThirdPartyHouseAndLand ?
                false :
                true;
        })
    );

    public canSubmitForReview$ = this.currentMappedItem$.pipe(
        map(currentMappedItem => {
            return currentMappedItem.reviewRequired
                && currentMappedItem.reviewerId !== undefined
                && currentMappedItem.reviewerId !== null
                && currentMappedItem.assignedToUserId === this.currentUser.guid;
        })
    );

    private _getIsSaveDisabled = (): boolean => {
        const isQSTeam = this.currentUser?.isQSTeam();
        const invalid = this.finaliseForm?.invalid;
        const assignedToCurrentUser = this
            .currentMappedItem?.assignedToUserId === this.currentUser?.guid;
        const reviewIsGood = (!this.currentMappedItem?.reviewRequired ||
            this.currentMappedItem?.reviewerId === this.currentUser?.guid);
        return !(isQSTeam &&
            !invalid &&
            assignedToCurrentUser &&
            reviewIsGood);
    };

    public isSaveDisabled$ = this.finaliseForm$.asObservable().pipe(
        skipWhile(form => !form),
        switchMap(form => form.valueChanges),
        map(this._getIsSaveDisabled)
    );

    public canFinalise$ = this.finaliseForm$.asObservable().pipe(
        skipWhile(form => !form),
        switchMap(form => form.valueChanges),
        map(_ => !this.isReprice &&
            Number(this.currentMappedItem.buildCost) > 0
            && (this.currentMappedItem.reviewRequired ?
                this.currentMappedItem.reviewerId === this.currentUser.guid :
                this.currentMappedItem.assignedToUserId === this.currentUser.guid))
    );

    public buildCostOrLandPriceChanged$ = this.finaliseForm$.asObservable().pipe(
        skipWhile(form => !form),
        switchMap(form => combineLatest([
            form.form?.controls?.buildCost?.valueChanges.pipe(
                startWith(this.currentMappedItem.buildCost)
            ),
            form.form?.controls?.landPrice?.valueChanges.pipe(
                startWith(this.currentMappedItem.landPrice)
            ),
        ])),
        map(([buildCost, landPrice]) => {
            this.currentMappedItem.buildPrice = buildCost + landPrice;
        })
    );

    constructor(
        private readonly lotLogicService: LotsLogicService,
        private readonly pricingRevisionsLogicService: PricingRevisionsLogicService,
        public readonly toastSerivce: ToastService,
        public readonly dialogService: CbDialogService,
        public readonly currentUser: CurrentUserService,
        public readonly dialogRef: MatDialogRef<FinalisePricingRevisionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            currentMappedItem: IPricingRevisionMappedItem;
            previousMappedItem: IPricingRevisionMappedItem;
            isReprice: boolean;
        },
    ) {
        super(dialogRef, toastSerivce);
        this.previousMappedItem = data.previousMappedItem;
        this.currentMappedItem = data.currentMappedItem;
        this.isReprice = data.isReprice;
    }

    public async ngOnInit(): Promise<void> {
        this.formMode$.next(FormMode.Edit);
        this.currentMappedItem.buildPrice = this.currentMappedItem.buildCost + this
            .currentMappedItem.landPrice;
        this.pricingRevisionsLogicService.getGrossMargin(this.currentMappedItem.id).subOnce(gm => {
            this.grossMargin = gm;
            this.updateGrossMarginPercent();
        });
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public ngAfterViewChecked(): void {
        if (Object.keys(this.finaliseFormLiteral?.controls)
            .length > 0 && !this._isFormInitialised) {
            this.finaliseForm = this.finaliseFormLiteral;
            this._isFormInitialised = true;
            this._subscriptions.add(
                this.canFinalise$.subscribe(this._canFinaliseBehaviorSubject$)
            );
            this._subscriptions.add(
                this.buildCostOrLandPriceChanged$
                    .subscribe()
            );
        }
    }

    public updateBuildCost(buildCost: number): void {
        this.currentMappedItem.buildCost = buildCost;
        this.updateGrossMarginPercent();
    }

    public updateGrossMargin(grossMargin: number): void {
        this.grossMargin = grossMargin;
        this.updateGrossMarginPercent();
    }

    public updateGrossMarginPercent(): void {
        let val = 0.00;
        if (this.grossMargin > 0 && this.currentMappedItem.buildPrice > 0) {
            val = (this.grossMargin / this.currentMappedItem.buildCost) * 100;
        }
        this.grossMarginPercent = Number(val.toFixed(2));
    }

    public submitForReview(): void {
        if (this.canSubmitForReview) {
            this.currentMappedItem.statusId = PRICING_REVISION_STATUS_ENUM
                .WaitingForReview;
            this.currentMappedItem.submitForReview().subOnce(this.handleSubmit);
        }
    }

    public get canSubmitForReview(): boolean {
        return this.currentMappedItem.reviewRequired
            && this.currentMappedItem.reviewerId !== undefined
            && this.currentMappedItem.reviewerId !== null
            && this.currentMappedItem.assignedToUserId === this.currentUser.guid;
    }

    public reprice(): void {
        if (this.currentMappedItem.canReprice) {
            this.currentMappedItem.reprice().subOnce(this.handleSubmit);
        }
    }

    public confirmFinaliseRevision = (): void => {
        this.dialogService.confirm({
            dialogHeading: 'Finalise Pricing Revision',
            message: 'Are you sure you want to Finalise this Pricing Revision?',
            confirmed: () => {
                this.finalise();
            }
        });
    };

    public finaliseClicked(): void {
        if (this.currentMappedItem.finalisedDate) {
            this.reprice();
        } else {
            this.confirmFinaliseRevision();
        }
    }

    public finalise(): void {
        if (this._canFinaliseBehaviorSubject$.value) {
            this.currentMappedItem.statusId = this
                .currentMappedItem.isEstimatedPrice ?
                PRICING_REVISION_STATUS_ENUM.Estimated :
                PRICING_REVISION_STATUS_ENUM.Completed;
            this.currentMappedItem.finalise().subOnce(this.handleSubmit);
        }
    }

    private readonly handleSubmit = (result: IPricingRevisionDto): void => {
        if (this.grossMargin) {
            this.pricingRevisionsLogicService.updateGrossMargin(
                this.currentMappedItem.id,
                {grossMargin: this.grossMargin} as IPricingRevisionGrossMarginDto, false
            ).subOnce({
                next: () => {
                    this.dialogRef.close(result);
                },
                error: () => {
                    this.dialogRef.close(result);
                }
            });
        } else {
            this.dialogRef.close(result);
        }
    };
}
