import { ToastService } from '@app/core/services/toast/toast.service';
import { UtilFiles } from '@app/shared/utils/file.util';
import { IUpdateBundleProductDto, OFFERING_STATUS_ENUM, OFFERING_UNAVAILABLE_REASON_ENUM, PRODUCT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { environment } from '@src/environments/environment';
import { find, forEach, indexOf, orderBy, pickBy, sortBy } from 'lodash';
import moment from 'moment';
import { Observable, of, tap } from 'rxjs';
import { BaseMappedItem } from '../base/base-mapped-item';
import { Computed } from '../base/computed-prop.decorator';
import { DepInject } from '../base/dep-inject.decorator';
import { DtoProp } from '../base/dto-prop.decorator';
import { IProductAttributeDto } from '../product-attributes/interfaces/i.product-attribute.dto';
import { CategoryLogicService } from '../product-categories';
import { IUserDto } from '../users';
import { IOfferingBundleTemplateItemDto, IProductBundleProductDto, IProductDto, IRateDto } from './interfaces/i.product.dto';
import { IProductLogicService } from './interfaces/i.product.logic.service';
import { IProductMappedItem } from './interfaces/i.product.mapped';

export class ProductMappedItem
    extends BaseMappedItem<IProductDto, IProductMappedItem, IProductLogicService>
    implements IProductMappedItem {

    @DtoProp public readonly id: number;
    @DtoProp public isActive: boolean;
    @DtoProp public name: string;
    @DtoProp public code: string;
    @DtoProp public rates: IRateDto[];
    @DtoProp public shouldMaintainRatesForThisProduct: boolean;
    @DtoProp public images: { id: string; extension: string; sortOrder: number }[];
    @DtoProp public productType: number;
    @DtoProp public products: IProductBundleProductDto[];
    @DtoProp public uom: number;
    @DtoProp public restrictions: any[];
    @DtoProp public categoryPath: any[];
    @DtoProp public tradeTypeId: number;
    @DtoProp public tradeType: any;
    @DtoProp public isStandardProduct: boolean;
    @DtoProp public offeringCategoryId: number;
    @DtoProp public isCompositeItem: boolean;
    @DtoProp public compositeItemId: number;
    @DtoProp public offerType?: number; // used in create
    @DtoProp public showInClientSpecs: boolean;
    @DtoProp public showInColourYourDreams: boolean;
    @DtoProp public clientSpecDescription: string;
    @DtoProp public status: number;
    @DtoProp public unavailableStartDate: any;
    @DtoProp public unavailableEndDate: any;
    @DtoProp public statusComment: string;
    @DtoProp public notes: string;
    @DtoProp public replacementOfferingId: number;
    @DtoProp public replacementOffering: IProductMappedItem;
    @DtoProp public unavailableReason: number;
    @DtoProp public manualColourEntryRequired: boolean;
    @DtoProp public uomDisplay: string;
    @DtoProp public componentItems: Array<IProductMappedItem>;
    @DtoProp public createdDate: string;
    @DtoProp public createdBy: IUserDto;
    @DtoProp public updatedBy: IUserDto;
    @DtoProp public updatedDate: string;
    @DtoProp public bundleTemplateId: number;
    @DtoProp public bundleTemplateName: string;
    @DtoProp public mainCategory: string;
    @DtoProp public bundleTemplateChanged: boolean;
    @DtoProp public templateItems: IOfferingBundleTemplateItemDto[];
    @DtoProp public aggregateRates: any;
    @DtoProp public nationalRate: number;
    @DtoProp public hasImage: boolean;
    @DtoProp public inSpec: boolean;
    @DtoProp public hasProductImage: boolean;

    public unavailableStartDateObject: Date;
    public unavailableEndDateObject: Date;
    public baseImageUrl: string;
    public imageUrls: any[];

    @Computed() public get isCompositeItemWithoutAssignedItems(): boolean {
        return this.isCompositeItem && !(this.componentItems?.length > 0);
    }

    @Computed() public get isCompositeItemWithAssignedItems(): boolean {
        return this.isCompositeItem && this.componentItems?.length > 0;
    }

    @DepInject(CategoryLogicService) private readonly categoryLogicService: CategoryLogicService;
    @DepInject(ToastService) private readonly toastService: ToastService;

    constructor(
        sourceData: IProductDto,
        logicService: IProductLogicService
    ) {
        super(sourceData, logicService, ProductMappedItem);
    }

    public bundleTemplateIsComplete(): boolean {
        if (this.bundleTemplateChanged) {
            return false;
        }
        let complete = true;
        const templateItems = this.templateItems;
        if (this.bundleTemplateId !== undefined && templateItems && templateItems.length) {
            templateItems.forEach((item) => {
                if (item.isRequired && this.bundleTemplateItemProduct(item.id) === undefined) {
                    complete = false;
                }
            });
        }
        return complete;
    }

    public uploadCatalog(file: File, dateForCatalogueItems: Date): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('dateForCatalogueItems', moment(dateForCatalogueItems).format());
        return this.$logicService.uploadCatalog(formData);
    }

    public uploadImageFile(file: File): Observable<IProductDto | string> {

        if (!file) {
            return of('image-file-none');
        } else if (file.size > 50 * 1024 * 1024) {
            return of('image-file-size');
        } else if (!file.name.match(UtilFiles.isImageFile)) {
            return of('image-file-type');
        }

        const fd = new FormData();
        fd.append('file', file);

        return this.$logicService.uploadImage(this.id, fd);
    }

    public getOrderedRates(): IRateDto[] {
        const rates = sortBy(this.rates, (rate) => {

            const sortString: string[] = [];
            if (rate.areaPath) {

                rate.areaPath.forEach((area) => {
                    sortString.push(area.label);
                });
            }

            if (rate.projectTitle) {
                sortString.push(rate.projectTitle);
            }


            return sortString.join(',');
        });
        return rates;
    }

    public isNotBundle(): boolean {
        return this.productType !== PRODUCT_TYPE_ENUM.Bundle;
    }

    public isProduct = (): boolean => {
        return this.productType === PRODUCT_TYPE_ENUM.Product;
    };

    public isLabour = (): boolean => {
        return this.productType === PRODUCT_TYPE_ENUM.Labour;
    };

    public isBundle = (): boolean => {
        return this.productType === PRODUCT_TYPE_ENUM.Bundle;
    };

    public isProductOrLabourItem = (): boolean => {
        return this.isLabour() || this.isProduct();
    };

    public saveBundleProduct(bundleProduct: IUpdateBundleProductDto): Observable<IProductDto> {
        return this.$logicService.saveBundleProduct(this.id, bundleProduct.id, bundleProduct);
    }

    public bundleTemplateItemProduct(templateItemId: number): IProductBundleProductDto {
        const products = this.products;
        if (products && products.length > 0) {
            return find(this.products, { bundleTemplateItemId: templateItemId });
        }
    }

    protected $postLoad(): void {

        this.unavailableStartDateObject = this.unavailableStartDate ? new Date(this.unavailableStartDate) : new Date();
        this.unavailableEndDateObject = this.unavailableEndDate ? new Date(this.unavailableEndDate) : new Date();

        // add any changes to data from server

        if (this.productType === PRODUCT_TYPE_ENUM.Labour) {
            this.tradeTypeId = this.tradeType?.id;
        }
        if (this.replacementOfferingId > 0) {
            this.$logicService.$getItem(this.replacementOfferingId).subOnce({
                next: (item) => {
                    this.replacementOffering = item as any;
                    this.replacementOffering = item as any;
                }
            });
        }

        this.baseImageUrl = `${environment.api}/products/${this.id}/images/`;
        this.imageUrls = [];
        if (this.images !== undefined) {
            this.images.forEach((x) => {
                this.imageUrls.push(`${this.baseImageUrl}${x.id}.${x.extension}`);
            });
        }
        if (this.rates !== undefined) {
            this.rates = orderBy(this.rates, ['businessAccountId', 'areaPath'], ['desc', 'asc']);
        }

        this.bundleTemplateChanged = false;

    }

    protected $preSave(toSave: IProductDto): void {
        toSave.unavailableStartDate = (this.unavailableStartDateObject) ?
            moment(this.unavailableStartDateObject).format() : undefined;
        delete this.unavailableStartDateObject;

        toSave.unavailableEndDate = (this.unavailableEndDateObject) ?
            moment(this.unavailableEndDateObject).format() : undefined;
        delete this.unavailableEndDateObject;
        delete toSave.replacementOffering;

        switch (toSave.status) {
            case OFFERING_STATUS_ENUM.Active:
                toSave.unavailableReason = OFFERING_UNAVAILABLE_REASON_ENUM.None;
                toSave.unavailableEndDate = undefined;
                toSave.unavailableStartDate = undefined;
                toSave.replacementOfferingId = undefined;
                break;
            case OFFERING_STATUS_ENUM.TemporarilyUnavailable:
                toSave.unavailableReason = OFFERING_UNAVAILABLE_REASON_ENUM.None;
                break;
            case OFFERING_STATUS_ENUM.PermanentlyUnavailable:
                toSave.unavailableEndDate = undefined;
                break;

            default:
        }
        if (!this.$id) {
            toSave.offerType = toSave.productType;
            delete toSave.productType;
            delete toSave.uomDisplay;
            delete toSave.categoryPath;
        }

        delete toSave.productType;
        delete toSave.uomDisplay;
        delete toSave.categoryPath;
        delete toSave.aggregateRates;
        delete toSave.restrictions;
        delete toSave.rates;
        delete toSave.componentItems;
    }

    public saveAttribute = (attribute: IProductAttributeDto, observer: any): Observable<any> => {
        if (!attribute.id) {
            return of(this.saveNewProductAttribute(attribute).subOnce(observer));
        } else {
            return this.categoryLogicService.getAttributeValues(attribute.category.id ? attribute.category.id : attribute.category, attribute.attributeId)
                .pipe(tap((attributeValues) => {
                    if (attribute.id) {
                        this.saveProductAttribute(attribute, attributeValues).subOnce(observer);
                    }
                }));
        }
    };

    public getUserFullName(user: IUserDto): string {
        return (user) ? `${user.firstName} ${user.lastName}` : '';
    }

    private saveNewProductAttribute(productAttribute: IProductAttributeDto): Observable<any> {
        const payload = {
            categoryId: productAttribute.category,
            label: productAttribute.name,
            values: productAttribute.newValues
        };
        return this.$logicService.addAttribute(this.id, payload);
    }

    private saveProductAttribute(productAttribute: IProductAttributeDto, categoryAttributes): Observable<any> {
        const payload = [];
        forEach(pickBy(productAttribute.selectedOptions, value => value), (value, key) => {
            const option = find(categoryAttributes, { id: +key });
            payload.push(option);
        });
        forEach(productAttribute.newValues, (newVal) => { payload.push({ name: newVal }); });

        const idParams = { attribId: productAttribute.attributeId, id: this.id };

        return this.$logicService.updateAttributes(idParams.id, idParams.attribId, payload);
    }

    private saveRate(rate: IRateDto): Observable<IRateDto> {
        if (!rate.id) {
            return this.$logicService.createRate(this.id, rate);
        }

        return this.$logicService.saveRate(this.id, rate.id, rate);
    }

    public saveProductRate(rate: IRateDto, observer): Observable<IRateDto> | any {
        return this.saveRate(rate).subOnce({
            next: (result) => {
                const index = indexOf(this.rates, find(this.rates, { id: result.id }));
                if (index >= 0) {
                    this.rates.splice(index, 1, result);
                }
                observer(result);
            }
        });
    }

    public deleteImage(imageId: string): Observable<IProductDto> {
        return this.$logicService.deleteImage(this.id, imageId);
    }

}
