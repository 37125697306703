import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule as MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { CbAlertCardModule } from '@app/shared/components/alert-card/alert-card.module';
import { CbButtonModule } from '@app/shared/components/button/button.module';
import { CbDateDurationCalculatorModule } from '@app/shared/components/date-duration-calculator/date-duration-calculator.module';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbUserDateTimeModule } from '@app/shared/components/user-date-time/user-date-time.module';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { CbFillHeightModule } from '@app/shared/directives/fill-height/fill-height.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { CbDasherModule, CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule } from 'cb-hub-lib';
import { DragulaModule } from 'ng2-dragula';
import { AddSsrLinesDialogComponent } from './components/add-ssr-lines-dialog/add-ssr-lines-dialog.component';
import { BuildProgrammeActivityRelationshipsComponent } from './components/build-programme-activity-relationships/build-programme-activity-relationships.component';
import { CompleteBuildProgrammeActivitiesDialogComponent } from './components/complete-build-programme-activities-dialog/complete-build-programme-activities-dialog.component';
import { ConvertToManualOrderDialogComponent } from './components/convert-to-manual-order-dialog/convert-to-manual-order-dialog.component';
import { EditBuildProgrammeActivityDialogComponent } from './components/edit-build-programme-activity-dialog/edit-build-programme-activity-dialog.component';
import { EditBuildProgrammeActivityComponent } from './components/edit-build-programme-activity/edit-build-programme-activity.component';
import { EditBuildProgrammeSsrDialogComponent } from './components/edit-build-programme-ssr-dialog/edit-build-programme-ssr-dialog.component';
import {
    EditPredecessorBuildProgrammeActivityDialogComponent
} from './components/edit-predecessor-build-programme-activity-dialog/edit-predecessor-build-programme-activity-dialog.component';
import { EditSsrLineDialogComponent } from './components/edit-ssr-line-dialog/edit-ssr-line-dialog.component';
import { MinQuantityValidatorDirective } from './components/edit-ssr-line-dialog/min-quantity.directive';
import { FullscreenBuildProgrammeComponent } from './components/fullscreen-build-programme/fullscreen-build-programme.component';
import { GenerateBuildProgrammeDialogComponent } from './components/generate-build-programme-dialog/generate-build-programme-dialog.component';
import { LotBuildProgrammeActionBarComponent } from './components/lot-build-programme-action-bar/lot-build-programme-action-bar.component';
import { LotBuildProgrammeActivityIconsComponent } from './components/lot-build-programme-activity-icons/lot-build-programme-activity-icons.component';
import { LotBuildProgrammeActivityMenuComponent } from './components/lot-build-programme-activity-menu/lot-build-programme-activity-menu.component';
import { LotBuildProgrammeActivityRowComponent } from './components/lot-build-programme-activity-row/lot-build-programme-activity-row.component';
import { LotBuildProgrammeActivityTableComponent } from './components/lot-build-programme-activity-table/lot-build-programme-activity-table.component';
import { LotBuildReportsMenuComponent } from './components/lot-build-programme-reports/lot-build-programme-reports-menu.component';
import { LotBuildProgrammeStageListComponent } from './components/lot-build-programme-stage-list/lot-build-programme-stage-list.component';
import { LotBuildProgrammeStepsComponent } from './components/lot-build-programme-steps/lot-build-programme-steps.component';
import { LotBuildProgrammeViewComponent } from './components/lot-build-programme-view/lot-build-programme-view.component';
import { LotBuildProgrammeComponent } from './components/lot-build-programme/lot-build-programme.component';
import { PoDocumentHistoryComponent } from './components/po-document-history/po-document-history.component';
import { SetBuildProgrammeActivitySupplierDialogComponent } from './components/set-build-programme-activity-supplier-dialog/set-build-programme-activity-supplier-dialog.component';
import { CbSortByImpureModule } from '@app/shared/pipe/sort-by-impure/sort-by-impure.module';

const MAT_IMPORTS = [
    MatCardModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatTabsModule,
    MatChipsModule,
    ScrollingModule,
    MatRadioModule,
    MatTooltipModule,
    MatDividerModule,
    MatButtonModule,
];

@NgModule({
    declarations: [
        LotBuildProgrammeComponent,
        LotBuildProgrammeViewComponent,
        LotBuildProgrammeActionBarComponent,
        LotBuildReportsMenuComponent,
        LotBuildProgrammeStageListComponent,
        LotBuildProgrammeActivityTableComponent,
        LotBuildProgrammeActivityIconsComponent,
        LotBuildProgrammeActivityMenuComponent,
        EditBuildProgrammeActivityDialogComponent,
        EditBuildProgrammeActivityComponent,
        BuildProgrammeActivityRelationshipsComponent,
        CompleteBuildProgrammeActivitiesDialogComponent,
        SetBuildProgrammeActivitySupplierDialogComponent,
        EditBuildProgrammeSsrDialogComponent,
        EditSsrLineDialogComponent,
        AddSsrLinesDialogComponent,
        EditPredecessorBuildProgrammeActivityDialogComponent,
        MinQuantityValidatorDirective,
        FullscreenBuildProgrammeComponent,
        GenerateBuildProgrammeDialogComponent,
        ConvertToManualOrderDialogComponent,
        LotBuildProgrammeActivityRowComponent,
        LotBuildProgrammeStepsComponent,
        PoDocumentHistoryComponent
    ],
    imports: [
        ...MAT_IMPORTS,
        FormsModule,
        CommonModule,
        BrowserModule,
        DragulaModule,
        CbFormsModule,
        CbLoadingSpinnerModule,
        CbInfoMessageModule,
        CbDialogModule,
        CbDisplayValueModule,
        CbDateDurationCalculatorModule,
        CbHubTableModule,
        CbTableModule,
        CbDasherModule,
        CbCurrencyModule,
        CbFillHeightModule,
        CbClickModule,
        CbWaitForModule,
        CommonModule,
        CbAlertCardModule,
        CbButtonModule,
        CbUserDateTimeModule,
        CbDatePipeModule,
        CbSortByImpureModule,
        CbInfoMessageModule
    ],
    exports: [
        LotBuildProgrammeViewComponent,
        LotBuildProgrammeComponent
    ],
})

export class LotBuildProgrammeModule { }
