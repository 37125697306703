<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon"
             [matTooltip]="tooltipMessage.value">
            <mat-icon [ngClass]="cardIconClass.value">{{cardIcon.value}}</mat-icon>
        </div>
        <mat-card-title [ngClass]="cardIconClass.value">{{stepNumber}}. Unconditional</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex-col flex">
        <div class="flex-col"
             *ngIf="!clientSale?.isDesignAndBuild">
            <span class="mat-body-strong">Signed Sale &amp; Purchase Agreement</span>
            <cb-display-value-new label="Uploaded"
                                  [value]="uploadLabels.value.spAgreement">
            </cb-display-value-new>
            <cb-display-value-new label="Reviewed"
                                  [value]="clientSale?.documentInfo?.spAgreementReviewedDate | date">
            </cb-display-value-new>
        </div>
        <div class="flex-col cb-margin-bottom-2x"
             *ngIf="clientSale?.isDesignAndBuild">
            <span class="mat-body-strong">Signed Build Contract</span>
            <cb-display-value-new label="Uploaded"
                                  [value]="clientSale?.documentInfo?.buildContractUploadDate | date">
            </cb-display-value-new>
            <cb-display-value-new label="Reviewed"
                                  [value]="clientSale?.documentInfo?.buildContractReviewedDate | date">
            </cb-display-value-new>
        </div>

        <span class="mat-body-strong">Unconditional</span>
        <cb-display-value-new>
            {{clientSale?.isUnconditional ? (clientSale?.unconditionalDate | date) : '-'}}
        </cb-display-value-new>
        <div *ngIf="!clientSale?.isDesignAndBuild && !clientSale?.documentInfo?.outstandingDocumentsHaveBeenReviewedOrUploaded"
             class="cb-error cb-margin-bottom-2x">
            <mat-icon class="cb-error"
                      style="vertical-align: middle;">error</mat-icon>
            There are outstanding documents requiring upload or review
        </div>
    </mat-card-content>
    <mat-card-actions class="cb-padding-left cb-padding-right mat-card-actions">
        <button mat-stroked-button
                class="cb-margin-bottom full-width"
                *ngIf="!clientSale?.isDesignAndBuild"
                [disabled]="!clientSaleDocuments?.documents?.spAgreement?.entity?.canUpload"
                (click)="clientSaleDocuments?.documents?.spAgreement?.openUploadDialog()"
                color="accent">
            <mat-icon>file_upload</mat-icon>
            &nbsp;Upload S&amp;P Agreement
        </button>
        <button class="cb-no-margin full-width"
                mat-raised-button
                color="primary"
                *ngIf="permissions?.canEdit()"
                [disabled]="!clientSale?.canSetUnconditional"
                (click)="setUnconditional()">
            Set Unconditional
        </button>
    </mat-card-actions>
</mat-card>
