<cb-dialog>
    <form class="flex-col flex"
          #styleForm="ngForm">
        <cb-input type="text"
                  name="name"
                  label="Style Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.name"></cb-input>
        <cb-checkbox name="isActive"
                     label="Is Active"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (click)="save()"
                [disabled]="!styleForm.dirty || !styleForm.valid ">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
