import {Injectable} from '@angular/core';
import {CurrentUserService} from '@app/core/authentication/current.user';
import {ToastService} from '@app/core/services/toast/toast.service';
import {MsalService} from '@azure/msal-angular';
import {PublicClientApplication} from '@azure/msal-browser';
import {INotificationDto} from '@classictechsolutions/hubapi-transpiled-enums';
import * as signalR from '@microsoft/signalr';
import {environment} from '@src/environments/environment';
import {findIndex} from 'lodash';
import {NavigationService} from '../navigation/navigation.service';
import {SIGNALR_CONNECTIONS, SIGNALR_EVENT_LISTENERS} from './signalr-event-listeners.enum';

@Injectable({
    providedIn: 'root'
})

export class SignalrService {

    public connection: signalR.HubConnection;
    public connectedUsers: any[] = [];
    public unreadNotifications: INotificationDto[] = [];
    private readonly hubNotYetInitializedMessage = 'Client Build Version not yet initialised';

    constructor(
        private readonly toastService: ToastService,
        private readonly currentUserService: CurrentUserService,
        private readonly navigationService: NavigationService,
        private readonly msalService: MsalService,
    ) {}

    // #region Initializers

    public initiateSignalRConnection(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(`${environment.api}/signalr`, {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets
                })
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: retryContext => {
                        if (retryContext.elapsedMilliseconds < 300000) {
                            /* If we've been reconnecting for less than 5 minutes so far,
                            wait 10 seconds before the next reconnect attempt.*/
                            return 10000;
                        } else {
                            // If we've been attempting to reconnect for more than 5 mins, stop reconnecting.
                            return null;
                        }
                    }
                })
                .build();

            // SIGNALR_CONNECTION_CREATED
            this.registerEventListeners();

            this.connection
                .start()
                .then(() => {
                    console.log(`%c SignalR connection success! Connection State: ${this.connection.state}`, 'color: #00FF00');
                    resolve();
                    this.invokeSignalRSIGNALR_CONNECTIONSuccess();
                })
                .catch((error) => {
                    console.error(`%c SignalR connection error: ${error}`, 'background: #222; color: #FF0000');
                    reject();
                });
        });
    }

    private invokeSignalRSIGNALR_CONNECTIONSuccess(): void {
        this.connection
            .invoke(SIGNALR_CONNECTIONS.RegisterForNotifications, this.currentUserService.guid)
            .then(this.registerForNotifications)
            .catch((error) => {
                console.error(`Invocation of ${SIGNALR_CONNECTIONS.RegisterForNotifications} failed. Error: ${error}`);
            });

        this.connection
            .invoke(SIGNALR_CONNECTIONS.RegisterForClientBuildVersionUpdates, this.currentUserService.guid)
            .then(this.registerForClientBuildVersionUpdates)
            .catch((error) => {
                console.error(`Invocation of ${SIGNALR_CONNECTIONS.RegisterForClientBuildVersionUpdates} failed. Error: ${error}`);
            });
    }

    // #endregion Initializers


    // #region Listeners & Event Handlers

    public registerEventListeners(): void {
        this.connection.on(SIGNALR_EVENT_LISTENERS.SendNotification, this.receiveNotification);
        this.connection.on(SIGNALR_EVENT_LISTENERS.ConfirmNotificationAsRead, this.confirmNotificationAsRead);
        this.connection.on(SIGNALR_EVENT_LISTENERS.ConfirmMultipleNotificationsAsRead, this.confirmMultipleNotificationsAsRead);
        this.connection.on(SIGNALR_EVENT_LISTENERS.SendConnectedUsers, this.getConnectedUsers);
        this.connection.on(SIGNALR_EVENT_LISTENERS.SendSimpleMessage, this.receiveSimplemessage);
        this.connection.on(SIGNALR_EVENT_LISTENERS.ClientVersionUpdated, this.clientBuildVersionUpdated);
    }


    private readonly receiveNotification = (notification: INotificationDto): void => {
        if (!notification) {
            return;
        }
        this.unreadNotifications.unshift(notification);
        this.notificationRecievedToast(notification.subject);
    };

    private notificationRecievedToast(notificationSubject = 'New Notification'): void {
        this.toastService.showToast(
            notificationSubject,
            'VIEW',
            5000,
            this.viewNotifications
        );
    }

    private readonly viewNotifications = (): void => {
        this.navigationService.navigate(['notifications']);
    };

    private readonly confirmNotificationAsRead = (notificationId: number): void => {
        const index = findIndex(this.unreadNotifications, (notificaton) => notificaton.id === notificationId);
        this.unreadNotifications.splice(index, 1);
    };

    private readonly confirmMultipleNotificationsAsRead = (notificationIds: number[]): void => {
        notificationIds.forEach(id => {
            const index = findIndex(this.unreadNotifications, (notificaton) => notificaton.id === id);
            this.unreadNotifications.splice(index, 1);
        });
    };

    private readonly getConnectedUsers = (users): void => {
        this.connectedUsers = users;
    };

    private readonly receiveSimplemessage = (message: string): void => {
        this.toastService.showToast(message);
    };

    private readonly registerForNotifications = (result: INotificationDto[]): void => {
        this.unreadNotifications = result;
    };

    private readonly registerForClientBuildVersionUpdates = (result: string): void => {
        console.log(`%c Registered for client build version update: ${result}`, 'color: #00FF00');
        this.clientBuildVersionUpdated(result);
    };

    private readonly clientBuildVersionUpdated = (latestBuildVersion: string): void => {

        console.log(`%c Latest HUB Build Version: ${latestBuildVersion}\n Current Client Build Version: ${environment.buildVersion}`, 'color: #00FF00');

        const hubHasBeenUpdated = (latestBuildVersion !== this.hubNotYetInitializedMessage) &&
            this.getDateFromBuildVersion(latestBuildVersion) > this.getDateFromBuildVersion(environment.buildVersion);

        if (hubHasBeenUpdated) {
            console.log('%c A new version of Hub has been detected! Informing user', 'color: #00FF00');
            this.toastService.showToast(
                'Hub has been updated. Please press F5 to refresh.',
                'OK',
                Infinity,
            );
        } else {
            this.toastService.clearAll('client-build-version-updated-toast');
        }
    };

    private getDateFromBuildVersion(buildString: string): Date {
        const lastIndex = buildString.lastIndexOf('.');
        if (lastIndex <= 0) {
            return undefined;
        }

        // Replaces all instance of . with -
        const dateString = buildString.split('.').join('-')
            // Take substring up to the last . (where the hour starts) and add a space
            .substring(0, lastIndex).concat(' ')
            // Add the time and append 00 minutes
            .concat(buildString.substring(lastIndex + 1)).concat(':00');

        return new Date(Date.parse(dateString));
    }

    private readonly reloadClassicHub = (): void => {
        const msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;
        // eslint-disable-next-line @typescript-eslint/dot-notation
        msalInstance['browserStorage'].clear();
        localStorage.clear();
        location.reload();
    };

    // #endregion Listeners & Event Handlers



}
