import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'cb-simple-message-dialog',
    templateUrl: './simple-message-dialog.component.html',
    styleUrls: ['./simple-message-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleMessageDialogComponent {

    constructor(
        public readonly dialogRef: MatDialogRef<SimpleMessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: { message: string; dialogHeading?: string }
    ) { }

    public close(): void {
        this.dialogRef.close(true);
    }

}
