import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {IQuoteLogicService, IQuoteMappedItem, QuoteLogicService} from '@app/logic/quote';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {COST_NATURE_ENUM, IQuoteDocumentDto, IQuoteDto, IVariationDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'app-lot-quote-variation-orders-dialog',
    templateUrl: './lot-quote-variation-orders-dialog.component.html',
    styleUrls: ['./lot-quote-variation-orders-dialog.component.scss']
})
export class LotQuoteVariationOrdersDialogComponent extends BaseDialogFormViewDirective<IQuoteDto, IQuoteMappedItem, IQuoteLogicService> {

    public static readonly MIN_WIDTH = '600px';
    public COST_NATURE_ENUM = COST_NATURE_ENUM;

    public variationOrders: IVariationDto[];
    public isLoaded: boolean;

    constructor(
        public readonly dialogRef: MatDialogRef<LotQuoteVariationOrdersDialogComponent>,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IQuoteDocumentDto,
        @Inject(QuoteLogicService) public readonly logicService: QuoteLogicService
    ) {

        super(dialogRef, toastService, cbDialog);
        this.logicService.getVariationOrders(data.id).subOnce((result) => {
            this.variationOrders = result;
            this.isLoaded = true;
        });
    }


}
