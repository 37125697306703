<div class="center-center flex-row"
     *cbWaitFor="mappedItem">
    <form #houseLandDetailsForm="ngForm"
          class="cb-padding flex-col flex">
        <div class="flex-row">
            <div class="flex-col flex">
                <div class="start-center flex-row"
                     style="padding: 10px 0">
                    <h1 class="cb-title cb-margin-right">Lot {{mappedItem?.lotNumber}}</h1>
                    <span class="mat-subhead">{{mappedItem?.project.label}}</span>
                </div>
                <div style="position: relative;">
                    <mat-divider class="cb-padding"></mat-divider>
                </div>
                <div class="flex-row">
                    <div class="flex-col flex">
                        <div class="amenities-row start-center">
                            <mat-icon class="amenities-icon cb-grey">hotel</mat-icon>
                            <div class="flex-col flex">
                                <cb-input type="text"
                                          name="noBedrooms"
                                          label="Bedrooms"
                                          [readonly]="true"
                                          class="amenities"
                                          [(ngModel)]="mappedItem.amenities.noBedrooms">
                                </cb-input>
                            </div>
                        </div>
                        <div class="amenities-row start-center">
                            <mat-icon class="amenities-icon cb-grey">weekend</mat-icon>
                            <div class="flex-col flex">
                                <cb-input type="text"
                                          name="noLivingRooms"
                                          label="Living Rooms"
                                          [readonly]="true"
                                          class="amenities"
                                          [(ngModel)]="mappedItem.amenities.noLivingRooms">
                                </cb-input>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col flex">
                        <div class="amenities-row start-center">
                            <mat-icon class="amenities-icon cb-grey">hot_tub</mat-icon>
                            <div class="flex-col flex">
                                <cb-input type="text"
                                          name="noBathrooms"
                                          label="Bathrooms"
                                          class="amenities"
                                          [readonly]="true"
                                          [(ngModel)]="mappedItem.amenities.noBathrooms">
                                </cb-input>
                            </div>
                        </div>
                        <div class="amenities-row start-center">
                            <mat-icon class="amenities-icon cb-grey">directions_car</mat-icon>
                            <div class="flex-col flex">
                                <cb-input type="text"
                                          name="noGarages"
                                          label="Garages"
                                          [readonly]="true"
                                          class="amenities"
                                          [(ngModel)]="mappedItem.amenities.noGarages">
                                </cb-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="start-center flex-row">
                    <mat-icon class="amenities-icon cb-grey"
                              svgIcon="floor-plan"
                              title="Floor Area"></mat-icon>
                    <div class="flex-col flex">
                        <cb-input type="text"
                                  name="floorArea"
                                  label="Floor Area"
                                  class="amenities"
                                  [readonly]="true"
                                  [(ngModel)]="mappedItem.amenities.floorArea">
                        </cb-input>
                    </div>
                    <mat-icon class="amenities-icon cb-grey">border_style</mat-icon>
                    <div class="flex-col flex">
                        <cb-input type="text"
                                  name="landArea"
                                  label="Land Area"
                                  class="amenities"
                                  [readonly]="true"
                                  [(ngModel)]="mappedItem.amenities.landArea">
                        </cb-input>
                    </div>
                </div>
                <div class="cb-margin-top-2x flex-row">
                    <div class="flex-col flex">
                        <cb-display-value-new class="flex"
                                              *ngIf="salePrice"
                                              label="Selling Price">
                            <div class="start-center">
                                <span class="mat-body-2">&nbsp;{{salePrice | cbCurrency: "addGst"}}</span>
                            </div>
                        </cb-display-value-new>
                        <cb-display-value-new label="Selling Price"
                                              *ngIf="!salePrice">
                            TBA
                        </cb-display-value-new>
                        <cb-input type="text"
                                  name="numberOfInterests"
                                  label="Lot Interests"
                                  [readonly]="true"
                                  [(ngModel)]="mappedItem.numberOfInterests">
                        </cb-input>
                        <cb-display-value-new label="Lot Contract Type">
                            {{LOT_CONTRACT_TYPE_ENUM[mappedItem.contractType]}}
                        </cb-display-value-new>
                    </div>
                    <div class="flex-col flex">
                        <cb-input type="text"
                                  name="jobNumber"
                                  label="Job Number"
                                  [readonly]="true"
                                  [(ngModel)]="mappedItem.jobNumber">
                        </cb-input>
                        <cb-display-value-new class="flex"
                                              label="Contract Generation Fee">
                            <div class="start-center">
                                <span class="mat-body-2">&nbsp;{{mappedItem.contractGenerationFee | cbCurrency: "addGst"}}</span>
                            </div>
                        </cb-display-value-new>
                    </div>
                </div>
            </div>
            <span class="cb-padding"></span>
            <div class="flex-col md-40 lg-40 sm-50 xl-33 xs-50">
                <cb-address-new [(ngModel)]="mappedItem.lotAddress"
                                addressTitle="Address "
                                [readonly]="true"
                                name="lotAddress">
                </cb-address-new>
                <h1 class="cb-title"
                    style="margin-top: 12px; margin-bottom: 12px">Additional Info</h1>
                <div style="position: relative;">
                    <mat-divider></mat-divider>
                </div>
                <cb-display-value-new label="Lot Status">
                    {{jobStatusDisplay()}}
                </cb-display-value-new>
                <cb-display-value-new label="Estimated Completion Date">
                    {{mappedItem?.jobComplete.estimate | date}}
                </cb-display-value-new>
                <cb-display-value-new label="Land Title">
                    {{landTitleDisplay()}}
                </cb-display-value-new>
                <cb-display-value-new label="Market Release Date"
                                      *ngIf="isPreRelease()">
                    {{mappedItem?.projectReleaseStage.marketReleaseDate | date}}
                </cb-display-value-new>
                <cb-display-value-new label="Payment Options"
                                      *cbWaitFor="paymentTemplates">
                    <div class="flex-col flex">
                        <span class="mat-body-2"
                              *ngFor="let options of paymentTemplates">{{options?.label}}</span>
                        <span class="mat-body-2"
                              *ngIf="!mappedItem?.buildType || paymentTemplates?.length < 1">-</span>
                    </div>
                </cb-display-value-new>
            </div>
        </div>
    </form>
</div>
