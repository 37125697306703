<cb-dialog dialogHeading="Quote Refs">
    <cb-table [loaded]="true"
              [stringColumns]="columns">
        <tbody cbTableBody>
            <tr *ngFor="let quoteLine of quoteLines">
                <td>Q{{quoteLine.id}}</td>
                <cb-td-text [value]="COST_TYPE_ENUM[quoteLine.costType]"></cb-td-text>
                <cb-td-text [value]="quoteLine.lineItem"></cb-td-text>
                <cb-td-currency [value]="quoteLine.subTotal"></cb-td-currency>
                <cb-td-text [value]="quoteLine.supplier"></cb-td-text>
                <cb-td-date [value]="quoteLine.quoteDate"></cb-td-date>
                <cb-td-text [value]="quoteLine.quoteNumber"></cb-td-text>
                <cb-td-text [value]="quoteLine.changeNumber"></cb-td-text>
                <cb-td-text [value]="quoteLine.variationNumber"></cb-td-text>
                <td cbTableShrinkCol
                    cbTableStickyCol="end">
                    <button *ngIf="quoteLine.id"
                            mat-icon-button
                            matTooltip="Copy Qref to clipboard"
                            (click)="copyToClipboardClicked(quoteLine.id)">
                        <mat-icon svgIcon="clipboard-text-play-outline"></mat-icon>
                    </button>
                </td>
            </tr>
            <tr *ngIf="quoteLines.$resolved && quoteLines.length < 1 ">
                <td colspan="10">
                    <mat-icon class="mat-accent">info</mat-icon>
                    There are no Quote References that include this Lot Spec Item.
                </td>
            </tr>
            <tr *ngIf="!quoteLines.$resolved">
                <td colspan="10">
                    <cb-loading-spinner></cb-loading-spinner>
                </td>
            </tr>
        </tbody>
    </cb-table>
    <div actions>
        <button mat-raised-button
                (click)="cancel()">Close</button>
    </div>
</cb-dialog>
