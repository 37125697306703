import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './stepper/stepper.component';
import { StepComponent } from './step/step.component';
import { MatCardModule } from '@angular/material/card';
import { StepCardHeightDirective } from './step/step-card-height.directive';
import { ApplyClassIfHasContentModule } from '@app/shared/directives/apply-class-if-has-content/apply-class-if-has-content.module';
import { StepWrapperMaxWidthDirective } from './step/step-wrapper-max-width.directive';

const COMPONENTS = [
    StepperComponent,
    StepComponent,
];

@NgModule({
    declarations: [
        ...COMPONENTS,
        StepCardHeightDirective,
        StepWrapperMaxWidthDirective,
    ],
    imports: [
        CommonModule,
        MatDividerModule,
        MatIconModule,
        MatCardModule,
        ApplyClassIfHasContentModule,
    ],
    exports: COMPONENTS,
})
export class CbStepperModule { }
