import { getBaseAutocompleteScrollerProvider, AutocompleteScrollerComponent } from '../../autocomplete-scroller.component';
import { Component, Input, OnChanges } from '@angular/core';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';
import { MatDialog } from '@angular/material/dialog';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { ClientAccountSearchService } from '@app/core/services/search/client-account-search.service';
import { IClientAccountSearchParams } from '@app/logic/client-account/interfaces/i.client-account-search-params';
import { IClientAccountDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-client-account-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(ClientAccountAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(ClientAccountAutocompleteScrollerComponent),
        ClientAccountSearchService,
    ]
})
export class ClientAccountAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent<IClientAccountDto> implements OnChanges {

    @Input() public readonly searchParams: IClientAccountSearchParams;

    public results: IClientAccountDto[] = [];

    constructor(
        public readonly buildProgrammeLogicService: BuildProgrammeLogicService,
        public readonly dialog: MatDialog,
        public readonly searchService: ClientAccountSearchService
    ) {
        super(dialog, searchService);
    }

    public ngOnChanges(): void {
        this.searchService.extraSearchParams = {
            ...this.searchParams,
        };
    }
}
