<cb-dialog dialogHeading="Lot Details">
    <form class="flex-col flex"
          #lotDetailsForm="ngForm">
        <cb-input *ngIf="data.clientSale.isDesignAndBuild"
                  type="number"
                  label="Lot Number"
                  name="lotNumber"
                  [pattern]="positiveIntegerPattern"
                  [(ngModel)]="clientSaleLotDetails.lotNumber"></cb-input>
        <cb-checkbox *ngIf="data.clientSale.isHouseAndLand"
                     name="allow-nominee"
                     label="Is Showhome"
                     [(ngModel)]="clientSaleLotDetails.isShowHome"></cb-checkbox>
        <cb-checkbox label="Lot has Title"
                     name="lotHasTitle"
                     [(ngModel)]="clientSaleLotDetails.hasTitle">
        </cb-checkbox>
        <cb-datepicker label="Actual / Expected Title Date"
                       [required]="!clientSaleLotDetails.hasTitle"
                       name="expectedTitleDate"
                       [(ngModel)]="clientSaleLotDetails.expectedTitleDate"
                       *ngIf="!clientSaleLotDetails.hasTitle"
                       [pickerType]="'calendar'">
        </cb-datepicker>
        <cb-select
            *ngIf="clientSaleLotDetails && buildTypes && (data.clientSale.isDesignAndBuild || data.clientSale.isThirdPartyHouseAndLand)"
            [required]="true"
            name="buildType"
            label="Build Type"
            [(ngModel)]="clientSaleLotDetails.buildTypeId"
            [options]="buildTypes">
        </cb-select>
        <cb-select *ngIf="data.clientSale.isDesignAndBuild || data.clientSale.isThirdPartyHouseAndLand"
                   label="Road Direction"
                   name="roadDIrection"
                   [(ngModel)]="clientSaleLotDetails.roadDirectionId"
                   [options]="COMPASS_DIRECTION_TYPE_ENUM">
        </cb-select>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="lotDetailsForm.pristine || lotDetailsForm.invalid"
                (click)="save()">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
