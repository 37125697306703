import { Component, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IResourceConsentMappedItem, IBuildingConsentMappedItem, BuildingConsentMappedItem } from '@app/logic/lot-consents';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConsentCouncilReceievedDialogComponent } from '../consent-council-received-dialog/consent-council-received-dialog.component';
import { ToastService } from '@app/core/services/toast/toast.service';
import { Observable } from 'rxjs';
import { BUILDING_CONSENT_STATUS_ENUM, RESOURCE_CONSENT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: any;
}

@Component({
    selector: 'cb-submitted-to-council-dilaog',
    templateUrl: './submitted-to-council-dilaog.component.html',
    styleUrls: ['./submitted-to-council-dilaog.component.scss']
})
export class SubmittedToCouncilDilaogComponent extends BaseDialogFormViewDirective<any, any, any> {
    public static readonly MIN_WIDTH = '33%';
    public get mappedItem(): IResourceConsentMappedItem | IBuildingConsentMappedItem {
        return this.data.mappedItem;
    }

    public readonly isBuildingConsent = this.mappedItem instanceof BuildingConsentMappedItem;

    constructor(
        public readonly dialogRef: MatDialogRef<ConsentCouncilReceievedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
    ) {
        super(dialogRef, toastService);
    }

    public saveMethod(): Observable<any> {
        if (this.isBuildingConsent) {
            this.mappedItem.consentStatus = BUILDING_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation;
        } else {
            this.mappedItem.consentStatus = RESOURCE_CONSENT_STATUS_ENUM.SubmittedAwaitingConfirmation;
        }
        return this.mappedItem.$save();
    }

}
