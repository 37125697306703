import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HistoryViewDialogComponent } from './history-view-dialog/history-view-dialog.component';
import { HistoryListComponent } from './history-list/history-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CbDialogModule } from '../dialog/dialog.module';
import { CbLoadingSpinnerModule } from 'cb-hub-lib';
import { CbInfoMessageModule } from 'cb-hub-lib';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbHubTableModule } from '../table/table.module';
import { CbTableModule } from 'cb-hub-lib';

const COMPONENTS = [
    HistoryListComponent,
    HistoryViewDialogComponent,
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CbDialogModule,
        CommonModule,
        MatTableModule,
        InfiniteScrollModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        CbLoadingSpinnerModule,
        CbInfoMessageModule,
        CbDisplayValueModule,
        MatCardModule,
        CbHubTableModule,
        CbTableModule,
    ],
    exports: COMPONENTS
})

export class CbHistoryModule { }
