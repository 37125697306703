import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { CbDasherModule } from 'cb-hub-lib';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbHubTableModule } from '@app/shared/components/table/table.module';
import { CbTableModule } from 'cb-hub-lib';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { VendorInputFindCardComponent } from './vendor-input-find-card.component';

@NgModule({
    declarations: [
        VendorInputFindCardComponent
    ],
    exports: [
        VendorInputFindCardComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        MatTableModule,
        MatIconModule,
        CbDialogModule,
        CbDasherModule,
        CommonModule,
        FormsModule,
        MatInputModule,
        CbDisplayValueModule,
        MatListModule,
        CbWaitForModule,
        MatSelectModule,
        MatDatepickerModule,
        MatCardModule,
        MatIconModule,
        MatCheckboxModule,
        InfiniteScrollModule,
        MatTableModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        CbHubTableModule,
        CbTableModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule
    ]
})

export class CbVendorInputFindCardModule { }

