import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { IBusinessEntityMappedItem } from '@app/logic/business-entity/interfaces/i.business-entity.mapped';
import { BusinessAccountLogicService } from '@app/logic/business-accounts';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IBuildContingencyRecordMappedItem } from '@app/logic/build-contingency-record/interfaces/i.build-contingency-record.mapped';
import { IBuildContingencyRecordsLogicService } from '@app/logic/build-contingency-record/interfaces/i.build-contingency-records.logic.service';
import { IBuildContigencyRecordDto, NOTIFIABLE_VALUE_DIRECTION_ENUM, NOTIFIABLE_VALUE_SCALE_ENUM, NOTIFIABLE_VALUE_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: IBuildContingencyRecordMappedItem;
    otherData: { businessEntityId: number };
}

@Component({
    selector: 'cb-business-entity-contingency-dialog',
    templateUrl: './business-entity-contingency-dialog.component.html',
    styleUrls: ['./business-entity-contingency-dialog.component.scss'],
    providers: [BusinessAccountLogicService, BusinessEntitiesLogicService]
})
export class BuildContingencyRecordDialogComponent
    extends BaseDialogFormViewDirective<
    IBuildContigencyRecordDto,
    IBuildContingencyRecordMappedItem,
    IBuildContingencyRecordsLogicService
    > implements OnInit {
    public businessEntities: IBusinessEntityDto[];
    public mappedItem: IBuildContingencyRecordMappedItem;
    public region: IBusinessEntityMappedItem;
    public readonly NOTIFIABLE_VALUE_DIRECTION_ENUM = NOTIFIABLE_VALUE_DIRECTION_ENUM;
    public readonly NOTIFIABLE_VALUE_SCALE_ENUM = NOTIFIABLE_VALUE_SCALE_ENUM;
    public readonly NOTIFIABLE_VALUE_TYPE_ENUM = NOTIFIABLE_VALUE_TYPE_ENUM;

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<BuildContingencyRecordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        this.mappedItem.businessEntityId = data.otherData.businessEntityId;
    }
}
