<cb-dialog class="flex-col"
           dialogHeading="{{modeString}} Default Supplier">
    <form class="flex-col flex"
          #form="ngForm">
        <cb-select *ngIf="isAdd()"
                   [required]="true"
                   name="selectedSupplyTypeId"
                   label="Supply Type"
                   [(ngModel)]="selectedSupplyTypeId"
                   (change)="selectedSupplyTypeChange()"
                   [options]="availableSupplyTypes">
        </cb-select>
        <cb-display-value-new *ngIf="isEdit()"
                              label="Supply Type"
                              [value]="item?.supplyType?.label">
        </cb-display-value-new>
        <cb-business-accounts-input-find-cards label="Business Account"
                                               [(ngModel)]="selectedSupplier"
                                               name="selectedSupplier"
                                               [text]="selectedSupplier?.tradingName"
                                               [searchParams]="businessAccountSearchParams"
                                               [required]="true"
                                               [canCreate]="false"
                                               (change)="changeSupplier()">
        </cb-business-accounts-input-find-cards>
        <div class="flex-row"
             *ngIf="invalidSupplier">
            <mat-card class="cb-error-card">
                <mat-card-content>Sorry there is no Supplier contact for this Business Account</mat-card-content>
            </mat-card>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="isSaveDisabled()"
                (cbClick)="save()"
                [clickLimit]="1">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
