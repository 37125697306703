<form class="flex-col flex"
      #schemeSearchForm="ngForm">
    <mat-card class="cb-margin end flex">
        <mat-card-content>
            <div class="start-center flex-row flex">
                <mat-icon class="cb-grey">search</mat-icon>
                <mat-form-field class="cb-margin-left cb-margin-right flex">
                    <mat-label>Search Working Drawings</mat-label>
                    <input matInput
                           type="text"
                           name="search"
                           [(ngModel)]="userCacheItem.data.query"/>
                </mat-form-field>
                <span class="flex"></span>
                <button mat-raised-button
                        color="primary"
                        *ngIf="canRequestWorkingDrawing"
                        [disabled]="isRequestWorkingDrawingDisabled"
                        class="cb-margin-right-2x"
                        (click)="requestWorkingDrawingSteps()">
                    Request Working Drawing
                </button>
                <button mat-raised-button
                        color="primary"
                        *ngIf="canCreateWorkingDrawing"
                        class="cb-margin-right-2x"
                        (click)="createWorkingDrawing()">
                    Create Working Drawing
                </button>
            </div>
        </mat-card-content>
    </mat-card>
    <div class="scroll-container"
         [cbInfiniteScroll]="listContainer"
         [fetch]="fetchResults()"
         [queryUpdated]="queryUpdate"
         [enabled]="searchEnabled"
         [(results)]="results"
         (resultsChange)="resultsChange($event)"
         [(currentPage)]="currentPage">
        <div #listContainer
             class="list-container flex-wrap xs-column flex">
            <div class="flex-col xs-100 flex-25"
                 *ngFor="let result of results">
                <cb-working-drawing-card class="flex"
                                         [mappedItem]="result"
                                         (viewClick)="viewWorkingDrawing($event)">
                </cb-working-drawing-card>
            </div>
        </div>
    </div>
</form>
