<cb-dialog dialogHeading="Building Consultants With Lot Interest">
    <form class="flex-col flex"
          #createLotForm="ngForm">
        <cb-display-value-new class="flex"
                              label="Building Consultants With Lot Interest">
            <div *ngFor="let buildingConsultant of buildingConsultantsWithLotInterest">
                <span class="mat-body-2">
                    {{ getBuildingConsultantText(buildingConsultant) }}
                </span>
            </div>
        </cb-display-value-new>
    </form>
    <div actions>
        <button mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
