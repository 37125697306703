import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IResourceConsentMappedItem, IBuildingConsentMappedItem, BuildingConsentMappedItem } from '@app/logic/lot-consents';
import { IBaseMappedItem } from '@app/logic/base/interfaces/i.base-mapped-item';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { USER_TAG_CONSTANTS_CONST, TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';

export interface ICreateConsentDialogData {
    canAddPrimaryBuildingConsent: boolean;
    mappedItem: IBuildingConsentMappedItem | IResourceConsentMappedItem;
}

@Component({
    selector: 'cb-create-consent-dialog',
    templateUrl: './create-consent-dialog.component.html',
    styleUrls: ['./create-consent-dialog.component.scss']
})
export class CreateConsentDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '33%';

    public assignedTo = {} as { name: string; id: string };
    public readonly DESIGN_TEAM = [TEAM_CODES_CONST.DesignTeamKey];
    public readonly BUILDING_COORDINATOR = [USER_TAG_CONSTANTS_CONST.BUILDING_COORDINATOR];

    public get buildingConsent(): IBuildingConsentMappedItem {
        return this.data.mappedItem as IBuildingConsentMappedItem;
    }

    public readonly dialogHeading = new ComputedProperty(() => {
        return this.data?.mappedItem instanceof BuildingConsentMappedItem ? 'Create Building Consent' : 'Create Resource Consent';
    });

    constructor(
        public readonly dialogRef: MatDialogRef<CreateConsentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: ICreateConsentDialogData,
    ) {
    }

    public ngOnInit(): void {
        this.dialogHeading.recompute();
    }

    public create(): void {
        this.data.mappedItem.assignedToUserId = this.assignedTo?.id;
        (this.data.mappedItem as IBaseMappedItem<any, any, any>)
            .$save()
            .subOnce((result) => {
                this.dialogRef.close(result);
            });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
