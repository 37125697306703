import { Component, Inject } from '@angular/core';
import { IContactSearchDto } from '@app/logic/contacts';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { CreateContactDialogComponent } from '../create-contact-dialog/create-contact-dialog.component';

@Component({
    selector: 'cb-create-contact-select-existing-dialog',
    templateUrl: './create-contact-select-existing-dialog.component.html',
    styleUrls: ['./create-contact-select-existing-dialog.component.scss']
})
export class CreateContactSelectExistingDialogComponent {
    public static readonly MIN_WIDTH = '50%';

    public readonly displayedColumns = [
        'Name',
        'Email Address',
        'Mobile Phone',
        ''
    ];

    constructor(
        public readonly dialogRef: MatDialogRef<CreateContactSelectExistingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            createContactDialogRef: MatDialogRef<CreateContactDialogComponent>;
            existingContacts: IContactSearchDto[];
        },
        private readonly cbDialog: CbDialogService,
    ) { }

    public selectContact(contact: IContactSearchDto): void {
        this.cbDialog.confirm({
            dialogHeading: 'Confirm Contact Selection',
            message: 'Are you sure you want to select this contact? Your create contact progress in the previous dialog will be lost.',
            confirmed: () => {
                this.data.createContactDialogRef.close(contact);
                this.dialogRef.close(true);
            }
        });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
