import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CbDocumentsModule } from '@app/shared/components/documents/documents.module';
import { CbTabRouteModule } from '@app/shared/directives/tab-route/tab-route.module';
import { CbWaitForModule } from '@app/shared/directives/wait-for/wait-for.module';
import { LotConceptsModule } from './concepts/concepts.module';
import { LotDesignConsentsModule } from './consents/consents.module';
import { LotDesignCouncilRfiModule } from './council-rfi/council-rfi.module';
import { DesignRequirementsComponent } from './design-requirements/design-requirements.component';
import { LotDesignAreaComponent } from './lot-design-area/lot-design-area.component';
import { LotPreConsentModule } from './pre-consent/pre-consent.module';
import { LotDesignSchemesModule } from './schemes/schemes.module';
import { LotWorkingDrawingsModule } from './working-drawings/working-drawings.module';
@NgModule({

    declarations: [
        LotDesignAreaComponent,
        DesignRequirementsComponent
    ],

    imports: [
        CommonModule,
        MatTabsModule,
        CbTabRouteModule,
        LotDesignSchemesModule,
        LotConceptsModule,
        LotPreConsentModule,
        LotWorkingDrawingsModule,
        CbWaitForModule,
        LotDesignConsentsModule,
        LotDesignCouncilRfiModule,
        CbDocumentsModule,
    ],
    exports: [
        LotDesignAreaComponent
    ]
})
export class LotDesignModule { }
