<cb-dialog dialogHeading="Standard Plan Cost">
    <form class="flex-col"
          #standardPlanCostForm="ngForm">
        <cb-select [required]="true"
                   name="region"
                   label="Region"
                   [options]="data.addressRegions"
                   [(ngModel)]="data.cost.regionId">
        </cb-select>
        <mat-form-field>
            <mat-label>Floor Area</mat-label>
            <input matInput
                   type="text"
                   name="floorArea"
                   disabled
                   [value]="formattedFloorArea"/>
        </mat-form-field>
        <cb-select [required]="true"
                   name="style"
                   label="Style"
                   [options]="exteriorStyles"
                   [(ngModel)]="data.cost.styleId">
        </cb-select>
        <cb-currency name="totalCost"
                     label="Total Cost"
                     [(ngModel)]="data.cost.totalCost"
                     (change)="calcCost()"></cb-currency>
        <cb-display-value-new label="M² Cost">
            {{ data.cost.m2Cost | cbCurrency }}
        </cb-display-value-new>
        <cb-checkbox name="isActive"
                     label="Active"
                     [(ngModel)]="data.cost.isActive"></cb-checkbox>
        <cb-display-value-new label="Created by"
                              *ngIf="data.cost.createdByName">
            {{ data.cost.createdByName }} on {{ data.cost.createdDate | date }}
        </cb-display-value-new>
        <cb-display-value-new label="Updated by"
                              *ngIf="data.cost.updatedByName">
            {{ data.cost.updatedByName }} on {{ data.cost.updatedDate | date }}
        </cb-display-value-new>
    </form>
    <div actions>
        <button mat-raised-button
                [disabled]=" !standardPlanCostForm.dirty || !standardPlanCostForm.valid"
                (click)="save()">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
