<button mat-mini-fab
        color="accent"
        class="collapse-button"
        (click)="collapsed = !collapsed"
        [matTooltip]="collapsed ? 'Expand' : 'Collapse'">
    <mat-icon>
        {{ collapsed ? 'chevron_right' : 'chevron_left ' }}
    </mat-icon>
</button>
<mat-card *ngIf="collapsed"
          class="collapsed-card">
    <mat-card-content><span class="mat-headline-6 collapsed-title">Changes List</span></mat-card-content>
</mat-card>
<ng-container *ngIf="!collapsed">
    <mat-card class="filter-card">
        <mat-card-content>
            <form class="cb-margin-right-3x start-center flex-row flex">
                <mat-icon class="cb-margin-right-2x">search</mat-icon>
                <cb-input class="flex-100"
                          name="searchText"
                          label="Type in Change Details"
                          [(ngModel)]="searchQuery"
                          (change)="setFilteredChangeRecords()">
                </cb-input>
            </form>
            <div class="flex-row">
                <mat-chip-set #chipList
                              aria-label="Fruit selection">
                    <mat-chip *ngFor="let id of filterredChangeIds"
                              (removed)="removeFilterChip(id)">
                        #{{ id }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                </mat-chip-set>
            </div>
            <div class="flex-row layoutGap-8">
                <cb-select-list class="flex-33"
                                label="Change Type"
                                [allOptions]="costNatures"
                                [multiple]="true"
                                [value]="userCacheItem?.silentData?.selectedCostNatures"
                                (selectChanged)="selectedCostNaturesChanged($event)">
                </cb-select-list>
                <cb-select-list class="flex-33"
                                label="Status"
                                [allOptions]="changeStatus"
                                [multiple]="true"
                                [value]="userCacheItem?.silentData?.selectedStatus"
                                (selectChanged)="selectedStatusChanged($event)">
                </cb-select-list>
                <cb-select-list class="flex-30"
                                label="Variation"
                                [allOptions]="variationsSelectList"
                                [multiple]="true"
                                [value]="userCacheItem?.silentData?.selectedVariations"
                                (selectChanged)="selectedVariationsChanged($event)">
                </cb-select-list>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="list-card scroll-container">
        <mat-card-content>
            <cb-info-message *ngIf="changeRecordMappedItems.length < 1"
                             message="There were no Changes found"
                             iconClass="cb-accent"></cb-info-message>
            <div class="list-container flex"
                 #listContainer>
                <mat-list>
                    <mat-list-item *ngFor="let changeRecord of filteredchangeRecords;"
                                   [ngClass]="{'selected-change-record': currentChangeId === changeRecord.id, 'change-record-list-item': true}">
                        <div class="flex-row flex list-line">
                            <div class="cb-margin-right center-center flex-col">
                                <mat-icon [ngClass]="getStatusIconClass(changeRecord)"
                                          class="help-cursor"
                                          [title]="getStatusIconTitle(changeRecord)">
                                    {{ getStatusIconIcon(changeRecord) }}
                                </mat-icon>
                            </div>
                            <div class="cb-margin-left cb-margin-right flex-col flex">
                                <div matListItemTitle
                                     class="item-line">
                                    #{{ changeRecord.changeNumber }} -
                                    {{ changeRecord.changeDetails }}
                                </div>
                                <div matListItemLine
                                     class="item-line">
                                    {{ changeRecord.assignedTeam ? changeRecord.assignedTeam + " Team" : "No Team" }}
                                    <mat-icon class="cb-grey">chevron_right</mat-icon>
                                    {{ changeRecord.changeStatus | cbEnumToLabel: CHANGE_STATUS_ENUM }}
                                    <span *ngIf="showVariationNumber(changeRecord)">
                                    &nbsp;&nbsp;(VO#{{ changeRecord.variationNumber }})
                                </span>
                                </div>
                                <div matListItemLine
                                     class="item-line">
                                    <mat-icon class="help-cursor cb-grey cb-margin-right"
                                              [title]="getCostNatureIconTitle(changeRecord.costNature)">
                                        {{ getCostNatureIcon(changeRecord.costNature) }}
                                    </mat-icon>
                                    <span>
                                    {{ changeRecord.costNature | cbEnumToLabel: COST_NATURE_ENUM }}
                                </span>
                                    <span *ngIf="showCostChangeAmountGST(changeRecord)"
                                          [class.is-credit]="changeRecord.costChangeAmount < 0">
                                    &nbsp;&nbsp;{{ changeRecord.costChangeAmount | cbCurrency:'addGst' }}
                                        (Inc Gst)
                                </span>
                                    <span *ngIf="showCostChangeAmount(changeRecord)"
                                          [class.is-credit]="changeRecord.costChangeAmount < 0">
                                    &nbsp;&nbsp;{{ changeRecord.costChangeAmount | cbCurrency }}
                                </span>
                                </div>
                            </div>
                            <div class="center-center flex-row">
                                <mat-icon class="help-cursor mat-warn cb-margin-right"
                                          matTooltip="Has unanswered question"
                                          svgIcon="comment-question-outline"
                                          *ngIf="changeRecord.hasUnansweredQuestion">
                                </mat-icon>
                                <button mat-icon-button
                                        (click)="changeRecordClicked(changeRecord)">
                                    <mat-icon>exit_to_app</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </mat-card-content>
    </mat-card>
</ng-container>
