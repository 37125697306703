<mat-card class="cb-margin-top-2x cb-margin-right-2x cb-margin-bottom"
          [style.height]="'-webkit-fill-available'">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon">
            <mat-icon [ngClass]="getStatusIconClass()"
                      class="help-cursor"
                      [matTooltip]="getStatusIconTitle()">{{ getStatusIconIcon() }}
            </mat-icon>
        </div>
        <mat-card-title> {{ getHeading() }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="cb-margin-bottom-nil flex-row layoutGap-50 mat-card-content-row">
        <div class="flex-col">
            <cb-display-value-new label="Design Complexity"
                                  [value]="mappedItem?.designComplexity?.complexity"></cb-display-value-new>
            <cb-display-value-new label="Estimated Hours"
                                  [value]="mappedItem?.estimatedHours"></cb-display-value-new>
            <cb-display-value-new label="Due Date"> {{ mappedItem?.dueDate | date: "dd MMM yyyy" }}
            </cb-display-value-new>
        </div>
        <div class="flex-col">
            <cb-display-value-new label="Assigned To"
                                  [value]="mappedItem?.assignedToUser?.label"></cb-display-value-new>
            <cb-display-value-new label="Elapsed Hours"
                                  [value]="elapsedTime"></cb-display-value-new>
            <div class="start-center flex-row">
                <cb-display-value-new label="Status"
                                      [value]="mappedItem?.preConsentPlanStatus?.label">
                </cb-display-value-new>
                <cb-info-popup *ngIf="mappedItem?.preConsentPlanStatus?.id == PRECONSENT_PLAN_STATUS_ENUM.Rejected"
                               [tooltip]="mappedItem?.rejectionReason">
                </cb-info-popup>
            </div>
        </div>
    </mat-card-content>
    <mat-divider class="card-divider"></mat-divider>
    <mat-card-actions>
        <div class="flex flex-row">
            <button mat-stroked-button
                    color="accent"
                    (click)="review()"
                    *ngIf="mappedItem?.preConsentPlanStatus?.id == PRECONSENT_PLAN_STATUS_ENUM.WaitingForReview">
                Review
            </button>
            <span class="flex"></span>
            <button mat-stroked-button
                    color="accent"
                    (click)="viewPreConsentPlan()">
                View
            </button>
        </div>
    </mat-card-actions>
</mat-card>
