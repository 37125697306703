<div class="flex-col">
    <div class="flex-col">
        <h4>{{contact?.firstName}} {{contact?.lastName}}</h4>
        <form #contactForm="ngForm">
            <cb-address-new [(ngModel)]="contact.physicalAddress"
                            addressTitle="Physical Address"
                            [readonly]="true"
                            [hideHeading]="true"
                            name="physicalAddress">
            </cb-address-new>
        </form>
        <cb-display-value-new class="cb-padding-top"
                              label="Email"
                              [value]="contact?.email"
                              [ngClass]="isEmailPreferred() ? 'cb-accent' : 'cb-grey'">
        </cb-display-value-new>
    </div>
    <div class="flex-col">
        <cb-display-value-new class="cb-padding-top"
                              label="Home Phone"
                              [value]="contact.phoneHome"
                              *ngIf="hasPhoneHome()"
                              [ngClass]="isHomePreferred() ? 'cb-accent' : 'cb-grey'">
        </cb-display-value-new>
        <cb-display-value-new class="cb-padding-top"
                              label="Work Phone"
                              [value]="contact?.phoneWork"
                              *ngIf="hasPhoneWork()"
                              [ngClass]="isWorkPreferred() ? 'cb-accent' : 'cb-grey'">
        </cb-display-value-new>
        <cb-input class="cb-padding-top"
                  name="mobilePhone"
                  label="Mobile Phone"
                  maskType="LANDLINE"
                  [readonly]="true"
                  [ngClass]="isMobilePreferred() ? 'cb-accent' : 'cb-grey'"
                  *ngIf="hasPhoneMobile()"
                  [(ngModel)]="contact.phoneMobile">
        </cb-input>
    </div>
</div>
