<form #searchForm="ngForm"
      *ngIf="paramsSetupComplete">
    <mat-card class="start flex-col"
              infiniteScroll
              [infiniteScrollDistance]="1"
              [fromRoot]="true"
              [infiniteScrollContainer]="'div#mainContainer'"
              (scrolled)="doSearch()">
        <mat-card-content>
            <div class="start-center flex-row">
                <div class="cb-margin-right-2x start-center flex-row">
                    <mat-icon>search</mat-icon>
                    <mat-form-field class="cb-margin-left cb-margin-right-2x flex">
                        <mat-label>Search</mat-label>
                        <input matInput
                               type="text"
                               name="search"
                               [(ngModel)]="searchParams.query"
                               (ngModelChange)="searchQueryUpdate.next($event)"/>
                    </mat-form-field>
                </div>
                <div class="cb-margin-right-2x end-center flex-row">
                    <mat-form-field>
                        <mat-label>Region</mat-label>
                        <mat-select name="regionSelect"
                                    [(ngModel)]="searchParams.locationId"
                                    (ngModelChange)="searchQueryUpdate.next($event)">
                            <mat-option *ngFor="let type of locations"
                                        [value]="type.id">
                                {{ type.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="cb-margin-right-2x end-center flex-row">
                    <mat-form-field>
                        <mat-label>Sort By</mat-label>
                        <mat-select name="sortSelect"
                                    [(ngModel)]="searchParams.order"
                                    (ngModelChange)="searchQueryUpdate.next($event)">
                            <mat-option *ngFor="let type of sortingOptions"
                                        [value]="type.id">
                                {{ type.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="cb-margin-right-2x end-center flex-row">
                    <mat-form-field>
                        <mat-label>Max Results in Column</mat-label>
                        <mat-select name="resultSizeSelect"
                                    [(ngModel)]="searchParams.resultSize"
                                    (ngModelChange)="resultSizeChanged($event)">
                            <mat-option *ngFor="let type of cardSizeOptions"
                                        [value]="type.value">
                                {{ type.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <span class="flex"></span>
                <div class="cb-margin-right-2x end-center flex-row">
                    <button mat-icon-button
                            class="cb-margin-right-2x"
                            [disabled]="false"
                            [matMenuTriggerFor]="menu">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                </div>
                <mat-menu #menu="matMenu">
                    <app-house-and-land-search-filter *ngIf="costsAreasLoaded && paramsSetupComplete"
                                                      (click)="$event.stopPropagation()"
                                                      (emitEvent)="queryUpdated()"
                                                      [costs]="costs"
                                                      [floorAreas]="floorAreas"
                                                      [landAreas]="landAreas"
                                                      [searchData]="userCacheService.houseLandSearch.data">
                    </app-house-and-land-search-filter>
                </mat-menu>
            </div>
            <div *ngIf="anyFilterApplied()" class="flex flex-row space-between-center">
                <mat-chip-set>
                    <mat-chip *ngIf="searchParams.query && searchParams.query !== ''"
                              (removed)="remove('query')">
                        Query : {{ searchParams.query }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="searchParams.numberOfBedrooms > 0"
                              (removed)="remove('numberOfBedrooms')">
                        Bedrooms : {{ searchParams.numberOfBedrooms }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="searchParams.numberOfBathrooms > 0"
                              (removed)="remove('numberOfBathrooms')">
                        Bathrooms : {{ searchParams.numberOfBathrooms }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="searchParams.numberOfLivingRooms > 0"
                              (removed)="remove('numberOfLivingRooms')">
                        Living : {{ searchParams.numberOfLivingRooms }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="searchParams.numberOfGarages > 0"
                              (removed)="remove('numberOfGarages')">
                        Garage : {{ searchParams.numberOfGarages }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="showMinFloorAreaChip()"
                              (removed)="remove('floorAreaMin')">
                        Floor Area Min: {{ searchParams.floorAreaMin }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="showMaxFloorAreaChip()"
                              (removed)="remove('floorAreaMax')">
                        Floor Area Max: {{ searchParams.floorAreaMax }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="showMinLandAreaChip()"
                              (removed)="remove('landAreaMin')">
                        Land Area Min: {{ searchParams.landAreaMin }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="showMaxLandAreaChip()"
                              (removed)="remove('landAreaMax')">
                        Land Area Max: {{ searchParams.landAreaMax }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="showMinPriceChip()"
                              (removed)="remove('priceMin')">
                        Price Min: {{ searchParams.priceMin }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="showMaxPriceChip()"
                              (removed)="remove('priceMax')">
                        Price Max: {{ searchParams.priceMax }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <mat-chip *ngIf="searchParams.locationId > 0"
                              (removed)="remove('locationId')">
                        Region: {{ getRegionChipText(searchParams.locationId) }}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                </mat-chip-set>
                <button mat-stroked-button
                        style="border-radius: 16px;"
                        (click)="clearAll()">
                    <mat-icon>clear_all</mat-icon>
                    &nbsp;Clear Filter
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</form>
