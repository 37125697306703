import { Component } from '@angular/core';
import {USER_TAG_CONSTANTS_CONST} from "@classictechsolutions/hubapi-transpiled-enums";

@Component({
    selector: 'cb-minor-components',
    templateUrl: './minor.components.html',
    styleUrls: ['./minor.components.scss']
})
export class MinorComponents {
    isLocked: boolean = false;

    public options = [
        { id: '0', label: 'Any' },
        { id: '1', label: 'Option for the test' },
        { id: '2', label: 'Another option for the test' },
    ];

    public readonly BUILDING_CONSULTANT_TAG = [USER_TAG_CONSTANTS_CONST.BUILDING_CONSULTANT];
}
