import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BusinessAccountLogicService, IBusinessAccountMappedItem } from '@app/logic/business-accounts';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM, ITradeTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { cloneDeep, find, orderBy } from 'lodash';
import { BusinessAccountContactRoleDialogComponent } from '../business-account-contact-role-dialog/business-account-contact-role-dialog.component';
import { IBusinessAccountContactDetailsDto } from '@classictechsolutions/hubapi-transpiled-enums/build/module';
import { IBusinessAccountContactAreaDto } from '@classictechsolutions/hubapi-transpiled-enums/build/module/lib/dtos/BusinessAccountContactAreaDto';

interface IData {
    account: IBusinessAccountMappedItem;
    contact: IBusinessAccountContactDetailsDto;
}

@Component({
    selector: 'cb-business-account-contact-roles-dialog',
    templateUrl: './business-account-contact-roles-dialog.component.html',
    styleUrls: ['./business-account-contact-roles-dialog.component.scss']
})
export class BusinessAccountContactRolesDialogComponent {
    public static readonly MIN_WIDTH = '80%';
    public BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM = BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM;
    public contact: IBusinessAccountContactDetailsDto;
    public accountsAreas: IBusinessAccountContactAreaDto[];
    public healthAndSafetyAreas: IBusinessAccountContactAreaDto[];
    public installationAreas: IBusinessAccountContactAreaDto[];
    public ordersAreas: IBusinessAccountContactAreaDto[];
    public salesAreas: IBusinessAccountContactAreaDto[];
    public tradeTypes: ITradeTypeDto[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly dialogRef: MatDialogRef<BusinessAccountContactRolesDialogComponent>,
        private readonly navigationService: NavigationService,
        private readonly lookupService: LookupService,
        private readonly cbDialog: CbDialogService,
        private readonly businessAccountLogicService: BusinessAccountLogicService,
        private readonly toastService: ToastService,
    ) {
        this.contact = data.contact;
        this.lookupService.getLookup('tradeTypes').then(tradeTypes => {
            this.tradeTypes = tradeTypes as unknown as ITradeTypeDto[];
            this.refreshData(data.contact);
        });
    }

    public onViewClicked(): void {
        this.navigationService.navigate([`contacts/${this.contact.contactId}/summary`]);
        this.dialogRef.close();
    }

    public onAddEditClicked(contactType: number, contactLocation?: any): void {
        const contractTypeLabel = BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM[contactType];
        const dialog = this.cbDialog.open(BusinessAccountContactRoleDialogComponent, {
            data: {
                dialogHeading: !contactLocation ? `Assign ${contractTypeLabel} Location` : `Edit ${contractTypeLabel} Location`,
                account: this.data.account,
                contact: this.contact,
                contactType,
                contactLocation
            },
        });
        dialog.afterClosed().subOnce((contact: IBusinessAccountContactDetailsDto) => {
            if (contact?.id) {
                this.toastService.showToast(`${contractTypeLabel} Area saved successful.`);
                this.refreshData(contact);
            }
        });
    }

    public onDeleteClicked(location: IBusinessAccountContactAreaDto): void {
        const contractTypeLabel = BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM[location.contactType];
        this.cbDialog.confirm({
            dialogHeading: `Remove ${contractTypeLabel} Area`,
            message: `Are you sure you want to remove this Area from the ${contractTypeLabel} Role?`,
            confirmed: (): void => {
                this.businessAccountLogicService.deleteContactLocation(this.data.account.id, this.contact.id, location.id).subOnce(contact => {
                    this.toastService.showToast(`${contractTypeLabel} Area has been deleted.`);
                    this.refreshData(contact);
                });
            }
        });
    }

    private refreshData(contact: IBusinessAccountContactDetailsDto): void {
        this.contact = cloneDeep(contact);

        this.accountsAreas = this.contact.areas?.filter(area => area.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Accounts);
        this.healthAndSafetyAreas = this.contact.areas?.filter(area => area.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.HealthAndSafety);
        this.installationAreas = this.contact.areas?.filter(area => area.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Installation);
        this.ordersAreas = this.contact.areas?.filter(area => area.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Orders);
        this.salesAreas = this.contact.areas?.filter(area => area.contactType === BUSINESS_ACCOUNT_CONTACT_TYPE_ENUM.Sales);

        this.accountsAreas = orderBy(this.accountsAreas, 'id', 'desc');
        this.healthAndSafetyAreas = orderBy(this.healthAndSafetyAreas, 'id', 'desc');
        this.installationAreas = orderBy(this.installationAreas, 'id', 'desc');
        this.ordersAreas = orderBy(this.ordersAreas, 'id', 'desc');
        this.salesAreas = orderBy(this.salesAreas, 'id', 'desc');
    }

    public getTradeTypeLabel(tradeTypeId: number): string {
        return find(this.tradeTypes || [], { id: tradeTypeId })?.label;
    }
}
