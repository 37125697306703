<cb-dialog>
    <div class="flex-col flex">
        <p class="mat-body-2">{{data.message}}</p>
    </div>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                class="yes-button"
                color="primary"
                (click)="yes()">{{data.yesLabel}}</button>
        <button mat-raised-button
                (click)="no()">{{data.noLabel}}</button>
    </div>
</cb-dialog>
