<div #infiniteScrollContainer
     class="cb-padding-left-2x cb-padding-right-2x cb-padding-bottom-2x cb-padding-top flex-col flex">
    <mat-card class="flex-col flex-100">
        <mat-card-content>
            <div class="flex-col flex-100">
                <div class="flex-col">
                    <div class="flex-row">
                        <h3 class="mat-headline-6 flex"
                            style="margin: 0;">Projects
                        </h3>
                    </div>
                    <mat-divider class="cb-margin-bottom"></mat-divider>
                </div>
                <div class="cb-table cb-margin-top">
                    <mat-table [dataSource]="accountProjects">
                        <ng-container matColumnDef="code">
                            <mat-header-cell *matHeaderCellDef> Code</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.code }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.projectName }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ PROJECT_STATUS_ENUM[element.status] }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="stage">
                            <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.stage }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="lot">
                            <mat-header-cell *matHeaderCellDef> Lot</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.lot }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="dpNumber">
                            <mat-header-cell *matHeaderCellDef> DP Number</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.dpNumber }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions"
                                      stickyEnd>
                            <mat-header-cell *matHeaderCellDef>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <button mat-icon-button
                                        (click)="viewProject(element.id, $event)">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                    <div class="cb-margin flex-col">
                        <cb-info-message *ngIf="accountProjectsLoaded && accountProjects.length === 0"
                                         [message]="'No Projects Found'"
                                         [icon]="'info_circle'"></cb-info-message>
                        <cb-loading-spinner *ngIf="!accountProjectsLoaded"></cb-loading-spinner>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
