<cb-dialog [dialogHeading]="getTitle()">
    <div class="flex-col flex">
        <mat-tab-group class="flex"
                       mat-stretch-tabs="false"
                       *ngIf="mappedItem?.exteriorImageUrl || mappedItem?.floorPlanImageUrl">
            <mat-tab label="Render"
                     *ngIf="mappedItem?.exteriorImageUrl">
                <div class="mat-padding image-tab flex-row center-center">
                    <cb-image-button class="flex-row main-img"
                                     [fullImage]=true
                                     [baseUrl]="mappedItem?.exteriorImageUrl">
                    </cb-image-button>
                </div>
            </mat-tab>
            <mat-tab label="Floor Plan"
                     *ngIf="mappedItem?.floorPlanImageUrl">
                <div class="mat-padding image-tab flex-row center-center">
                    <cb-image-button class="flex-row main-img"
                                     [fullImage]=true
                                     [baseUrl]="mappedItem?.floorPlanImageUrl">
                    </cb-image-button>
                </div>
            </mat-tab>
            <mat-tab label="Split View"
                     *ngIf="mappedItem?.exteriorImageUrl && mappedItem?.floorPlanImageUrl">
                <div class="mat-padding image-tab flex-row center-center">
                    <div class="flex-col flex">
                        <cb-image-button [fullImage]=true
                                         class="flex-row split-img"
                                         [baseUrl]="mappedItem?.exteriorImageUrl">
                        </cb-image-button>
                    </div>
                    <div class="flex-col flex">
                        <cb-image-button [fullImage]=true
                                         class="flex-row split-img"
                                         [baseUrl]="mappedItem?.floorPlanImageUrl">
                        </cb-image-button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <mat-tab-group mat-stretch-tabs="false">
            <mat-tab label="Details">
                <cb-view-house-land-details [mappedItem]="mappedItem"></cb-view-house-land-details>
            </mat-tab>
            <mat-tab label="Specification"
                     [disabled]="!mappedItem?.currentDesignScheme">
                <ng-template matTabContent>
                    <cb-view-house-land-specification class="flex"
                                                      [mappedItem]="lotSpecVersionMappedItem"
                                                      *ngIf="lotSpecVersionMappedItem">
                    </cb-view-house-land-specification>
                    <cb-loading-spinner *ngIf="!lotSpecVersionMappedItem"
                                        class="cb-margin flex"
                                        style="height: 300px;">
                    </cb-loading-spinner>
                </ng-template>
            </mat-tab>
            <mat-tab label="Documents"
                     *ngIf="documents && documents.length > 0">
                <div *cbWaitFor="mappedItem">
                    <cb-document [entity]="mappedItem"
                                 [permissions]="documentPermissions"
                                 [includeLinked]="true"
                                 [hideUploadButton]="true"
                                 [systemArea]="systemArea">
                    </cb-document>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="flex-col flex"
         actions>
        <div position="relative">
            <mat-divider></mat-divider>
        </div>
        <div class="footer-buttons flex-row flex">
            <button mat-stroked-button
                    color="accent"
                    (cbClick)="viewLotInterests($event)"
                    [disabled]="!lotInterestsEnabled()">Lot Interests</button>
            <span class="flex"></span>
            <button mat-stroked-button
                    color="accent"
                    (cbClick)="addToLead()"
                    *ngIf="lotInterestCanAdd()">Add to Existing Lead</button>
            <button mat-stroked-button
                    color="accent"
                    (cbClick)="createLead()"
                    *ngIf="canCreate()">Create Lead</button>
            <button mat-stroked-button
                    color="accent"
                    (cbClick)="createSale()"
                    *ngIf="canCreateSale()">Create Client Sale</button>
            <button mat-stroked-button
                    (click)="cancel()">Close</button>
        </div>
    </div>
</cb-dialog>
