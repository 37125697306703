import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LotPermissions } from '@app/core/permissions';
import { ToastService } from '@app/core/services/toast/toast.service';
import { DesignSchemesLogicService, IDesignSchemeMappedItem } from '@app/logic/design-schemes';
import { IDesignSchemesLogicService } from '@app/logic/design-schemes/interfaces/i.design-schemes-logic.service';
import { DocumentsLogicService } from '@app/logic/documents';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { IManageDialogData } from '@app/shared/interfaces/i.manage-dialog-data';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import {
    IDesignRequirementItemDto,
    IDesignSchemeDto,
} from '@classictechsolutions/hubapi-transpiled-enums';
@Component({
    selector: 'cb-request-schemes-edit-item-dialog',
    templateUrl: './request-schemes-edit-item-dialog.component.html',
    styleUrls: ['./request-schemes-edit-item-dialog.component.scss']
})
export class RequestDesignSchemesEditItemDialogComponent extends
    BaseDialogFormViewDirective<IDesignSchemeDto, IDesignSchemeMappedItem, IDesignSchemesLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '900px';

    public mappedItem: IDesignSchemeMappedItem;
    public mode: FormMode;
    public itemId: number;
    public description: string;
    public designerView: boolean;

    public item: IDesignRequirementItemDto;
    public tempItem: IDesignRequirementItemDto;

    public edit = FormMode.Edit;
    public add = FormMode.Add;

    constructor(
        private readonly toastService: ToastService,
        private readonly logicService: DesignSchemesLogicService,
        public readonly documentsLogicService: DocumentsLogicService,
        public readonly lotPermissions: LotPermissions,
        private readonly dialog: MatDialog,
        public readonly dialogRef: MatDialogRef<RequestDesignSchemesEditItemDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<IDesignSchemeMappedItem> &
        { item: IDesignRequirementItemDto; mode: FormMode; designerView: boolean },
        public readonly cbDialog: CbDialogService
    ) {
        super(dialogRef, toastService, cbDialog);
        this.mappedItem = data.mappedItem;
        this.item = data.item;
        this.mode = data.mode;
        this.designerView = data.designerView;
    }

    public ngOnInit(): void {
        this.tempItem = cloneDeepSafe(this.item);
    }

    public saveDialogItem(): void {

        if (!this.mappedItem.designRequirementItems) {
            this.mappedItem.designRequirementItems = [];
        }

        this.mappedItem.designRequirementItems.push(this.tempItem);

        this.cancel();
    }

    public saveDialogEditItem(): void {

        const index = this.mappedItem.designRequirementItems.findIndex(item => item.itemId === this.item.itemId);

        // Replace the item by index.
        this.mappedItem.designRequirementItems.splice(index, 1, this.tempItem);

        this.cancel();
    }

}
