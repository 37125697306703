import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { MatIconModule } from '@angular/material/icon';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { DashboardComponent } from './dashboard.component';
import { DashboardAddWidgetDialogComponent } from './dashboard-add-widget-dialog/dashboard-add-widget-dialog.component';
import { DashboardWidgetDocumentListComponent } from './dashboard-widget-document-list/dashboard-widget-document-list.component';
import { DashboardWidgetGroupedAcitonListComponent } from './dashboard-widget-grouped-action-list/dashboard-widget-grouped-action-list.component';
import { DashboardWidgetAcitonListComponent } from './dashboard-widget-action-list/dashboard-widget-action-list.component';

@NgModule({
    declarations: [
        DashboardComponent,
        DashboardAddWidgetDialogComponent,
        DashboardWidgetDocumentListComponent,
        DashboardWidgetGroupedAcitonListComponent,
        DashboardWidgetAcitonListComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCardModule,
        CbFormsModule,
        MatIconModule,
        CbDialogModule,
        CbDatePipeModule,
        RouterModule.forRoot([
            {
                component: DashboardComponent,
                path: '',
            }
        ]),
    ],
})
export class DashboardModule { }
