<cb-dialog dialogHeading="Manage Resource Consent">
    <form class="flex-col flex"
          #editConsentForm="ngForm">
        <cb-input type="text"
                  label="Council"
                  name="councilName"
                  [(ngModel)]="mappedItem.councilName"
                  [readonly]="true"></cb-input>
        <cb-input type="text"
                  label="Consent For"
                  name="consentFor"
                  [(ngModel)]="mappedItem.consentFor"
                  [required]="true"></cb-input>
        <cb-datepicker label="Received by Council"
                       name="receivedByCouncilDate"
                       pickerType="calendar"
                       [(ngModel)]="mappedItem.receivedByCouncilDate"
                       [required]="true"
                       [disabled]="!mappedItem.hasBeenReceived"></cb-datepicker>
        <cb-datepicker label="Consent Due Date"
                       name="dueDate"
                       pickerType="calendar"
                       [(ngModel)]="mappedItem.consentDueDate"
                       [required]="true"
                       [disabled]="!mappedItem.hasBeenReceived"></cb-datepicker>
        <cb-input type="text"
                  label="Consent Number"
                  name="consentNumber"
                  [(ngModel)]="mappedItem.consentNumber"
                  [softRequired]="true"
                  [disabled]="!mappedItem.hasBeenReceived">
                </cb-input>
        <cb-user-autocomplete-scroller label="Assigned To"
                                       name="assignedTo"
                                       [(ngModel)]="assignedTo"
                                       [required]="true"
                                       [tag]="BUILDING_COORDINATOR"
                                       [team]="DESIGN_TEAM"
                                       [active]="true"
                                       [tagOrTeam]="true"></cb-user-autocomplete-scroller>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="editConsentForm.invalid || editConsentForm.pristine"
                (cbClick)="save()"
                [clickLimit]="1">Save
                </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
