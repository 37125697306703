import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IComplianceRegistrationMappedItem } from '@app/logic/compliance-registration/interfaces/i.compliance-registration.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IComplianceRegistrationLogicService } from '@app/logic/compliance-registration/interfaces/i.compliance-registration.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ComplianceRegistrationMappedItem } from '@app/logic/compliance-registration/compliance-registration.mapped';
import { IComplianceRegistrationDto, IDocumentTypeDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: ComplianceRegistrationMappedItem;
}

@Component({
    selector: 'cb-compliance-registration-dialog',
    templateUrl: './compliance-registration-dialog.component.html',
    styleUrls: ['./compliance-registration-dialog.component.scss']
})
export class ComplianceRegistrationDialogComponent extends BaseDialogFormViewDirective<IComplianceRegistrationDto, IComplianceRegistrationMappedItem, IComplianceRegistrationLogicService> {
    public mappedItem: ComplianceRegistrationMappedItem;
    public selectedItem: IDocumentTypeDto;
    public selectedDocumentTypeModel: IDocumentTypeDto;
    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<ComplianceRegistrationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
    }

    public itemSelected(item: IDocumentTypeDto): void {
        this.selectedItem = item;
    }

    public displayFn(item): string | undefined {
        return item ? item.label ? item.label : '' : '';
    }
}
