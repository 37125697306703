<cb-dialog [dialogHeading]="data.dialogHeading || ''">
    <div class="start-center flex-row">
        <span class="flex">{{data.message}}</span>
    </div>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                (click)="close()">Close</button>
    </div>
</cb-dialog>
