<cb-dialog>
    <form class="flex-col flex"
          #locationForm="ngForm">
        <cb-select *ngIf="mappedItem.locationType!==1"
                   label="Parent"
                   name="parent"
                   [options]="parents"
                   [(ngModel)]="mappedItem.parentId"></cb-select>
        <cb-input type="text"
                  name="name"
                  label="Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.name"></cb-input>
        <cb-input *ngIf="mappedItem.locationType===1"
                  type="text"
                  name="code"
                  label="Code"
                  [required]="true"
                  [(ngModel)]="mappedItem.code"></cb-input>
        <div *ngIf="isCodeDuplicated()"
             class="cb-padding-bottom-2x">
            <mat-error>
                <small>
                    Code needs to be unique
                </small>
            </mat-error>
        </div>
        <cb-select label="Business Entity"
                   name="businessEntity"
                   [options]="data.businessEntities"
                   [(ngModel)]="mappedItem.businessEntityId"></cb-select>
        <cb-select label="Address Region"
                   name="addressRegion"
                   [options]="data.addressRegions"
                   [(ngModel)]="mappedItem.addressRegionId"></cb-select>
        <cb-checkbox label="Active"
                     name="isActive"
                     class="cb-margin-top-2x"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!locationForm.dirty || !locationForm.valid || isCodeDuplicated()">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
