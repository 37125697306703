import { NotesLogicService } from './../../../../logic/notes/notes-logic.service';
import { NoteDialogComponent } from './../note-dialog/note-dialog.component';
import { ComputedProperty } from './../../../utils/computed-property.util';
import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { INoteMappedItem } from '@app/logic/notes';
import { INotePermissions } from '@app/core/permissions/base.permissions';
import { MatDialog } from '@angular/material/dialog';
import { IBaseMappedItem } from '@app/logic/base/interfaces/i.base-mapped-item';
import { IBaseLogicService } from '@app/logic/base/interfaces/i.base-logic.service';
import { Subscription } from 'rxjs';
import { INoteEntity } from '@app/logic/standard-plans';

@Component({
    selector: 'cb-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnChanges, OnDestroy {

    @Input() public readonly permissions: INotePermissions;
    @Input() public readonly edit: boolean;
    @Input() public readonly entityName: string;
    @Input() public readonly entity: IBaseMappedItem<any, any, IBaseLogicService<any, any>> & INoteEntity<string | number>;

    public notes: INoteMappedItem[] = [];
    public notesLoaded = false;
    public searchText = new UntypedFormControl();

    public filteredNotes = new ComputedProperty(
        () => this.notes && this.notes.filter(note => this.filterNotes(note)),
        this.notes
    );

    public canAddNote = new ComputedProperty(
        () => this.permissions && this.edit && this.permissions.canCreateNote(),
        false
    );

    public readonly subscription = new Subscription();

    constructor(
        public readonly matDialog: MatDialog,
        public readonly notesLogicService: NotesLogicService
    ) { }

    public ngOnInit(): void {
        this.notesLogicService.getMappedNotes(this.entity.noteEntityUri, this.entity.noteEntityId)
            .subOnce(result => {
                this.notes = result;
                this.filteredNotes.recompute();
                this.notesLoaded = true;
            });

        this.subscription.add(
            this.searchText.valueChanges.subscribe(_ => this.filteredNotes.recompute())
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngOnChanges(): void {
        this.canAddNote.recompute();
    }

    public createNote(): void {
        this.matDialog.open(NoteDialogComponent, {
            data: {
                dialogHeading: 'Add Note',
                note: this.notesLogicService.createMappedNoteItem(this.entity.noteEntityUri, this.entity.noteEntityId)
            },
            panelClass: 'cb-dialog-container',
            minWidth: 400
        })
            .afterClosed()
            .subOnce(note => {
                this.notes.unshift(note);
                this.filteredNotes.recompute();
            });
    }

    public filterNotes(value: INoteMappedItem): boolean {
        if (!value) {
            return false;
        }

        if (!this.searchText.value || this.searchText.value.length === 0) {
            return true;
        }

        const lowerCaseSearch = this.searchText.value.toLowerCase();
        return value.body.toLowerCase().indexOf(lowerCaseSearch) > -1 ||
            value.subject.toLowerCase().indexOf(lowerCaseSearch) > -1;
    }

}
