import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ICouncilRfiMappedItem } from '@app/logic/council-rfis';
import { ICouncilRfiLogicService } from '@app/logic/council-rfis/interfaces/i.council-rfi-logic.service';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { IUserDto } from '@app/logic/users';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ICouncilRfiDto, ITeamDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from 'cb-hub-lib';
import { sortBy } from 'lodash';
import { map, Observable, tap } from 'rxjs';


interface IData {
    councilRfi: ICouncilRfiMappedItem;
}

@Component({
    selector: 'cb-reassign-council-rfi-dialog',
    templateUrl: './reassign-council-rfi-dialog.component.html',
    styleUrls: ['./reassign-council-rfi-dialog.component.scss']
})
export class ReassignCouncilRfiDialogComponent extends BaseDialogFormViewDirective<ICouncilRfiDto, ICouncilRfiMappedItem, ICouncilRfiLogicService> {
    public static readonly MIN_WIDTH = '33%';
    public get mappedItem(): ICouncilRfiMappedItem {
        return this.data.councilRfi;
    }

    public teams: ITeamDto[];
    public selectedTeam: ITeamDto;
    public assignedToUser: IUserDto;

    constructor(
        public readonly dialogRef: MatDialogRef<ReassignCouncilRfiDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        public readonly teamLogic: TeamsLogicService,
    ) {
        super(dialogRef, toastService);
        this.teamLogic
            .$getList()
            .pipe(
                map((teams) => sortBy(teams, (team) => team.name)),
                tap(teams => {
                    this.teamChanged(this.mappedItem.assignedToTeamId, teams);
                })
            ).subOnce((x) => {
                this.teams = x;
            });
    }

    public teamChanged(id: number, teams = this.teams): void {
        this.selectedTeam = teams.find(x => x.id === id);
        this.mappedItem.assignedToUserId = null;
        this.assignedToUser = null;
    }

    public saveDisabled(reassignForm: NgForm): boolean {
        return reassignForm.pristine
            || reassignForm.invalid
            || isNullOrWhiteSpace(this.assignedToUser?.id)
            || !this.mappedItem?.assignedToTeamId;
    }

    public saveMethod(): Observable<ICouncilRfiDto> {
        this.mappedItem.assignedToUserId = this.assignedToUser.id;
        return this.mappedItem.$save();
    }
}
