<cb-dialog dialogHeading="Reupload Approval Document">
    <span class="mat-body-2 cb-margin-bottom">
        Replace the existing variation approval document with a new document.
    </span>
    <cb-document-upload class="cb-margin-bottom"
                        [(documentEntityMappedItem)]="document"
                        [hideName]="true"
                        [hideDescription]="true"></cb-document-upload>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="!document?.document.name"
                (cbClick)="submit()">Submit
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
