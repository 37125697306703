<cb-dialog dialogHeading="{{isEdit() ? 'Edit Attribute' : 'Create Attribute'}}">
    <form class="flex-col flex"
          #manageCategoryAttributeForm="ngForm">
        <cb-input type="text"
                  [readonly]="true"
                  label="Category"
                  name="categoryName"
                  [(ngModel)]="mappedItem.name">
        </cb-input>
        <cb-input type="text"
                  [required]="true"
                  label="Attribute Name"
                  name="attributeName"
                  [maxlength]="256"
                  [(ngModel)]="attribute.name">
        </cb-input>
        <cb-checkbox name="isActive"
                     label="Is Active"
                     [(ngModel)]="attribute.isActive">
        </cb-checkbox>
        <cb-checkbox name="isLocked"
                     label="Is Locked"
                     [(ngModel)]="attribute.isLocked">
        </cb-checkbox>
        <div class="flex-row">
            <div class="flex-col flex">
                <div class="standardTable">
                    <table>
                        <thead>
                        <tr>
                            <td>Options</td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let option of attribute.options">
                            <td>{{ option.name }}</td>
                            <td class="actions">
                                <button mat-icon-button
                                        (click)="removeOptionFromAttribute(option)">
                                    <mat-icon>
                                        remove_circle
                                    </mat-icon>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <cb-input type="text"
                                          label="New Option Name"
                                          name="newOption"
                                          [maxlength]="256"
                                          [(ngModel)]="newOption">
                                </cb-input>
                            </td>
                            <td class="actions">
                                <button mat-icon-button
                                        (click)="addOptionToAttribute($event,newOption)"
                                        [disabled]="!newOption">
                                    <mat-icon>add
                                    </mat-icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (cbClick)="save()"
                [clickLimit]="1"
                [disabled]="!manageCategoryAttributeForm.dirty || !manageCategoryAttributeForm.valid">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
