<ng-container *cbWaitFor="lotMappedItem$$ | async">
    <form #designSchemeCardForm="ngForm"
          *ngIf="!selectedPreConsentPlan"
          class="manage-preconsent-container">
        <mat-card class="start-end flex-row"
                  *cbWaitFor="lotMappedItem$$ | async">
            <mat-card-content class="mat-card-content-row">
                <span class="flex"> </span>
                <cb-button *ngIf="canGeneratePreConsent()"
                           [disabled]="!canGeneratePreconsentValueFromApi"
                           (clicked)="generatePreConsent()"
                           (popupClick)="goToLotSpecification()"
                           style="z-index: 1;"
                           [popup]="{
                        description: preconsentBlockingReason,
                        buttonText: lotSpecHasIncompleteItems ? 'Go to Lot Specification' : '',
                        horizontalPosition: 'left',
                        verticalPosition: 'bottom',
                        cssPosition: 'absolute',
                        disabled: lotSpecHasIncompleteItems,
                        buttonIsHidden: !lotSpecHasIncompleteItems
                    }">
                    Request Pre-consent
                </cb-button>
                <cb-button *ngIf="canCreatePreConsent()"
                           [disabled]="isCreatePreConsentDisabled()"
                           [ngClass]="!canGeneratePreconsentValueFromApi ? 'cb-margin-left-4x' : 'cb-margin-left-2x'"
                           color="primary"
                           (clicked)="createPreConsentPlan()">
                    Create Pre-Consent Plan
                </cb-button>
            </mat-card-content>
        </mat-card>
        <div class="flex-row">
            <div class="flex-wrap">
                <cb-pre-consent-card class="flex-col"
                                     *ngFor="let plan of mappedItem?.preConsentPlans"
                                     [lotMappedItem]="lotMappedItem"
                                     (lotMappedItemChange)="notifyLotMappedItemChanged()"
                                     [preConsentPlan]="plan"
                                     (viewPreConsentClicked)="handleViewPreConsent($event)">
                </cb-pre-consent-card>
            </div>
        </div>
    </form>
    <cb-manage-pre-consent-view *ngIf="selectedPreConsentPlan"
                                (goBack)="handleGoBack()"
                                [mappedItem]="selectedPreConsentPlan"
                                [lotMappedItem]="lotMappedItem">
    </cb-manage-pre-consent-view>
</ng-container>
