import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { CouncilRfiLogicService, ICouncilRfiMappedItem } from '@app/logic/council-rfis';
import { ICouncilRfiLogicService } from '@app/logic/council-rfis/interfaces/i.council-rfi-logic.service';
import { DocumentGroupLogicService, DocumentsLogicService, IDocumentEntityDto } from '@app/logic/documents';
import { IDocumentUploadDto } from '@app/logic/documents/interfaces/i.document.dto';
import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { ILotConsentDto } from '@app/logic/lot-consents/interfaces/i.lot-consent.dto';
import { LotConsentLogicService } from '@app/logic/lot-consents/lot-consent-logic.service';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { IUserDto, UsersLogicService } from '@app/logic/users';
import { WorkingDrawingsLogicService } from '@app/logic/working-drawings';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import {
    COUNCIL_RFI_STATUS_ENUM,
    DOCUMENT_GROUP_CODES_CONST,
    DOCUMENT_TYPE_CODES_CONST,
    ICouncilRfiDto,
    IIdAndNameDto, ITeamDto,
    RESOURCE_CONSENT_STATUS_ENUM,
    RFI_ASPECT_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IPromised, toPromisedArray } from 'cb-hub-lib';
import { sortBy } from 'lodash';
import { Observable, tap } from 'rxjs';

interface IData {
    councilRfi: ICouncilRfiMappedItem;
    dialogHeading: string;
    consentId: number;
}

@Component({
    selector: 'cb-edit-council-rfi-dialog',
    templateUrl: './edit-council-rfi-dialog.component.html',
    styleUrls: ['./edit-council-rfi-dialog.component.scss']
})
export class EditCouncilRfiDialogComponent extends BaseDialogFormViewDirective<ICouncilRfiDto, ICouncilRfiMappedItem, ICouncilRfiLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '40%';
    public get mappedItem(): ICouncilRfiMappedItem {
        return this.data.councilRfi;
    }

    public assigneeTeams: ITeamDto[] = [];
    public assignedToTeamCode: string;
    public assignee: any;
    public consentsRequiringConsentNumber: ILotConsentDto[] = [];
    public availableConsents: ILotConsentDto[] = [];
    public document: IDocumentEntityMappedItem;
    public readonly rFIAspects = RFI_ASPECT_ENUM.toLookup();
    public isReadonly = false;
    public teams: IPromised<ITeamDto[]>;
    public workingDrawings: IIdAndNameDto[] = [];

    constructor(
        public readonly dialogRef: MatDialogRef<EditCouncilRfiDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(DocumentsLogicService) public readonly documentsLogic: DocumentsLogicService,
        @Inject(DocumentGroupLogicService) public readonly documentGroupLogic: DocumentGroupLogicService,
        @Inject(CurrentUserService) public readonly currentUser: CurrentUserService,
        @Inject(LotConsentLogicService) public readonly lotConsentLogic: LotConsentLogicService,
        @Inject(UsersLogicService) public readonly userLogic: UsersLogicService,
        @Inject(TeamsLogicService) public readonly teamLogic: TeamsLogicService,
        @Inject(CouncilRfiLogicService) public readonly councilRfiLogic: CouncilRfiLogicService,
        @Inject(NavigationService) public readonly navigationService: NavigationService,
        @Inject(WorkingDrawingsLogicService) public readonly workingDrawingLogic: WorkingDrawingsLogicService
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.lotConsentLogic.getByLot(this.mappedItem.lotId).subOnce((result) => {
            this.availableConsents = this.filterAvailableConsents(result);
            this.consentsRequiringConsentNumber = this.filterConsentsRequiringConsentNumber(result);
        });
        this.workingDrawingLogic.getLookupByLot(this.mappedItem.lotId).subOnce((result) => {
            this.workingDrawings = result;
        });

        if (this.mappedItem.id > 0) {
            this.assignee = {
                id: this.mappedItem.assignedToUserId,
                name: this.mappedItem.assignedToUserName
            };
            this.isReadonly = this.mappedItem.statusId === COUNCIL_RFI_STATUS_ENUM.Complete;
        } else {
            this.assignee = {
                id: this.currentUser.guid,
                name: this.currentUser.profile.name,
            };
        }

        if (this.data.consentId) {
            this.mappedItem.relatedConsentIds = [this.data.consentId];
        }

        this.teams = toPromisedArray(this.teamLogic.$getList());
        this.assigneeChanged();
        this.loadDocumentEntityStub();
    }

    public relatedConsentLabel(lotConsent: ILotConsentDto): string {
        return `${lotConsent.consentTypeLabel} - ${lotConsent.consentNumber}`;
    }

    public downloadLatestRfiDocument(): void {
        this.councilRfiLogic.downloadLatestRfiDocument(this.mappedItem.id).subOnce();
    }

    public filterAvailableConsents(availableConsents: ILotConsentDto[]): ILotConsentDto[] {
        return availableConsents.filter(
            consent => {
                if (/* consent.isComplete ||*/ !!consent.grantedById || consent.isDeleted || !consent.consentNumber) {
                    return false;
                }

                if (consent.consentTypeLabel === 'Resource Consent') {
                    if (consent.consentStatus === RESOURCE_CONSENT_STATUS_ENUM.Pending ||
                        consent.consentStatus === RESOURCE_CONSENT_STATUS_ENUM.Approved) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            });
    }

    public filterConsentsRequiringConsentNumber(availableConsents: ILotConsentDto[]): ILotConsentDto[] {
        return availableConsents.filter(consent => !(/* consent.isComplete  ||*/ !!consent.grantedById || consent.isDeleted || consent.consentNumber));
    }

    public assigneeChanged(): void {
        if (!this.assignee?.id) {
            return;
        }

        this.teams.$promise.then(() => {
            this.userLogic
                .$getItem(this.assignee.id)
                .subOnce(this.setAssigneeTeams);
        });
    }

    public onViewAssignToClicked(): void {
        this.navigationService.navigate([`users/view/${this.assignee.id}/details`]).then(() => {
            this.dialogRef.close();
        });
    }

    private readonly setAssigneeTeams = (user: IUserDto): void => {
        this.assigneeTeams = this.teams.filter((team) => user.selectedTeams.includes(team.id));

        if (this.assigneeTeams.length > 0 && !this.mappedItem.assignedToTeamId) {
            this.mappedItem.assignedToTeamId = this.assigneeTeams[0].id;
        }

        this.assignedToTeamCode = this.assigneeTeams.find(x => x.id === this.mappedItem.assignedToTeamId)?.key;

        this.assigneeTeams = sortBy(this.assigneeTeams, (team) => {
            return team.name;
        });
    };

    private loadDocumentEntityStub(): void {
        this.documentGroupLogic.getByCode(DOCUMENT_GROUP_CODES_CONST.COUNCIL_RFI_DOC_GROUP).subOnce((group) => {
            const type = group.types.find(groupType => groupType.documentType.codeName === DOCUMENT_TYPE_CODES_CONST.COUNCIL_RFI);

            // If we're creating a new RFI, then create a blank document
            // Otherwise load the latest from the existing RFI
            if (!this.mappedItem?.id) {
                this.document = this.documentsLogic.$createMappedItem({
                    documentGroup: group,
                    documentType: type.documentType,
                    document: {},
                } as IDocumentEntityDto);
            } else {
                this.councilRfiLogic.getLatestRfiDocument(this.mappedItem.id).subOnce((rfiDocument) => {
                    this.document = rfiDocument;
                });
            }
        });
    }

    public saveMethod(): Observable<ICouncilRfiDto> {
        this.mappedItem.assignedToUserId = this.assignee.id;
        return this.mappedItem
            .$save()
            .pipe(
                tap((result) => {
                    if (result && this.document?.document?.uploadedFile) {
                        const uploadedFile = {
                            documentTypeId: this.document.documentType?.id,
                            documentGroupId: this.document.documentGroup?.id,
                            documentEntityId: this.document.id,
                            file: this.document.document.uploadedFile,
                            name: this.document.document.name,
                        } as IDocumentUploadDto;

                        // For new files (no id) upload the RFI, but for existing RFIs
                        // allow the document to be reuploaded (replaced)
                        if (!this.document?.document?.id) {
                            this.documentsLogic.uploadDocumentEntity(this.mappedItem.documentEntityUri, this.mappedItem.documentEntityId, uploadedFile).subOnce();

                        } else {
                            this.documentsLogic.reUploadDocumentEntity(uploadedFile).subOnce();
                        }
                    }
                })
            );
    }

    public saveDisabled(editCouncilRfiForm: NgForm): boolean {
        return editCouncilRfiForm.pristine
            || editCouncilRfiForm.invalid
            || (!this.mappedItem?.id && !this.document?.document?.uploadedFile);
    }
}
