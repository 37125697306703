import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { BUILD_STAGE_EVENT_ENUM, IPaymentTemplateBuildTypeDto, LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { PaymentTemplateStageDialogComponent } from '../payment-template-stage-dialog/payment-template-stage-dialog.component';
import { PaymentTemplateBuildTypeDialogComponent } from '../payment-template-build-type-dialog/payment-template-build-type-dialog.component';
import { IPaymentTemplateStageDto } from '@app/logic/payment-templates/interface/i.payment-template.dto';
import { ToastService } from '@app/core/services/toast/toast.service';
import { Subscription, Observable } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { IPaymentTemplateMappedItem } from '@app/logic/payment-templates/interface/i.payment-template.mapped';
import { FormMode } from '@app/shared/enums/form';
import { orderBy } from 'lodash';

const DRAGULA_IGNORE = 'cb-table-footer-row';

@Component({
    templateUrl: './payment-template-dialog.component.html',
    styleUrls: ['./payment-template-dialog.component.scss'],
    providers: [
    ]
})
export class PaymentTemplateDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnDestroy, OnInit {
    public static readonly MIN_WIDTH = '40%';

    public readonly BUILD_STAGE_EVENT_ENUM = BUILD_STAGE_EVENT_ENUM;
    public readonly LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;

    private readonly _headerRowCount = 1;

    public readonly STAGES_DND = 'STAGES_DND';
    public subscriptions$ = new Subscription();
    public dragulaModel: IPaymentTemplateStageDto[] = [];
    public dataChanged = false;

    public readonly stageColumns = [
        'Trigger',
        'Payment Amount',
        'Amount Type',
        '',
    ];

    public readonly buildTypeColumns = [
        { label: 'Contract Type' },
        { label: 'Code' },
        { label: 'Build Type' },
        { label: 'Active', width: 100 },
        { label: 'Default', width: 100 },
        { label: '' }
    ];

    public get mappedItem(): IPaymentTemplateMappedItem {
        return this.data.mappedItem;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<PaymentTemplateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: IPaymentTemplateMappedItem;
            existingBuildTypes: IPaymentTemplateBuildTypeDto[];
        },
        public readonly toastService: ToastService,
        private readonly cbDialog: CbDialogService,
        @Inject(DragulaService) private readonly dragulaService: DragulaService,
    ) {
        super(dialogRef, toastService);
        this.setupDragula();
    }

    public ngOnInit(): void {
        if (this.mappedItem.id > 0) {
            this.formMode = FormMode.Edit;
        } else {
            this.formMode = FormMode.Add;
        }
        // Back end seems to send this reversed so just sort once on startup
        // Then again in this.dragulaService.dropModel.subscribe
        this.mappedItem.stages = orderBy(this.mappedItem.stages, 'sortOrder');
    }

    public ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        this.dragulaService.destroy(this.STAGES_DND);
    }

    public addPaymentStage(): void {
        this.managePaymentStage(this.newPaymentTemplateStage())
            .subOnce((result) => {
                if (result) {
                    this.mappedItem.stages.push(result);
                    this.dataChanged = true;
                }
            });
    }

    public editPaymentStage(paymentTemplateStage: IPaymentTemplateStageDto): void {
        this.managePaymentStage(paymentTemplateStage)
            .subOnce((result) => {
                if (result) {
                    Object.assign(paymentTemplateStage, result);
                    this.dataChanged = true;
                }
            });
    }

    private managePaymentStage(paymentTemplateStage: IPaymentTemplateStageDto): Observable<any> {
        const data = {
            paymentTemplateStage,
            existingStages: this.mappedItem.stages
        };
        return this.cbDialog
            .open(PaymentTemplateStageDialogComponent, {
                data,
                minWidth: '45%',
            })
            .afterClosed();
    }

    public removePaymentStage(index: number): void {
        this.mappedItem.stages.splice(index, 1);
        this.dataChanged = true;
    }

    public addBuildType(): void {
        this.mappedItem.buildTypes = this.mappedItem.buildTypes || [];
        const data = {
            existingBuildTypes: this.mappedItem.buildTypes,
            paymentTemplateBuildType: this.newPaymentTemplateBuildType(),
        };
        this.cbDialog.open(PaymentTemplateBuildTypeDialogComponent, {
            data,
            minWidth: '45%',
        })
            .afterClosed()
            .subOnce((result) => {
                if (result) {
                    this.mappedItem.buildTypes.push(result);
                    this.dataChanged = true;
                }
            });
    }

    public editBuildType(buildType: IPaymentTemplateBuildTypeDto, $index: number): void {
        this.mappedItem.buildTypes = this.mappedItem.buildTypes || [];
        const data = {
            existingBuildTypes: this.mappedItem.buildTypes,
            paymentTemplateBuildType: this.newPaymentTemplateBuildType(),
            selectedContractTypeId: buildType.lotContractType,
            selectedBuildTypeId: buildType.buildTypeId,
            isDefaultPaymentBuildType: buildType.isDefault,
            paymentTemplateId: this.mappedItem.id
        };

        this.cbDialog.open(PaymentTemplateBuildTypeDialogComponent, {
            data,
            minWidth: '45%',
        })
            .afterClosed()
            .subOnce((result) => {
                if (result) {
                    this.mappedItem.buildTypes[$index] = result;
                    this.dataChanged = true;
                }
            });
    }

    public removeBuildType(index): void {
        if (this.mappedItem.buildTypes[index].isDefault) {
            this.cbDialog.confirm({
                message: 'This template is set as the default for this Build Type. If you remove it you must set the new default in the build type settings',
                yesLabel: 'Delete',
                noLabel: 'Cancel',
                confirmed: () => {
                    this.mappedItem.buildTypes.splice(index, 1);
                    this.dataChanged = true;
                }
            });
        } else {
            this.mappedItem.buildTypes.splice(index, 1);
            this.dataChanged = true;
        }
    }

    private newPaymentTemplateStage(): IPaymentTemplateStageDto {
        return {
            amountType: 1,
            buildStage: {
            },
        } as IPaymentTemplateStageDto;
    }

    private newPaymentTemplateBuildType(): IPaymentTemplateBuildTypeDto {
        return {} as IPaymentTemplateBuildTypeDto;
    }

    /** Creates dragula group for address regions table and suscribes to dragula observables */
    private setupDragula(): void {
        this.dragulaService.createGroup(
            this.STAGES_DND,
            {
                removeOnSpill: false,
                accepts: (el: Element) => !el.classList.contains(DRAGULA_IGNORE), // do not allow move of mat-header-row
                moves: (el: Element) => !el.classList.contains(DRAGULA_IGNORE), // do not allow move of mat-header-row
                revertOnSpill: true
            }
        );

        this.subscriptions$.add(
            this.dragulaService.dropModel(this.STAGES_DND)
                .subscribe(
                    ({ el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
                        if (sourceIndex === targetIndex) {
                            return;
                        }
                        // This takes care of the hard work or ordering the whole
                        // array, moving every element to its new position.
                        moveItemInArray(this.mappedItem.stages, sourceIndex, targetIndex);

                        // So now the stages array is in the correct order
                        // - lets use that correct order to set the stage.sortOrder property,
                        // taking into account the table header row.
                        this.mappedItem.stages.forEach((value, index) => {
                            value.sortOrder = index + this._headerRowCount;
                        });
                        this.mappedItem.stages = orderBy(this.mappedItem.stages, 'sortOrder');
                        this.dataChanged = true;
                        this.setDragulaModel();
                    }
                )
        );
    }

    /** The dragula model can just be the table array. Account for the header row
     *  by using [this._headerRowCount]
     */
    private setDragulaModel(): void {
        this.dragulaModel = this.mappedItem.stages;
    }
}
