<mat-card class="cb-margin-2x flex-col flex">
    <mat-card-content>
        <form class="flex-col"
              #productCatalogueSearchForm="ngForm">
            <div class="flex-row flex">
                <div class="flex">
                    <cb-year-month-picker name="datedate"
                                          [(ngModel)]="date">

                    </cb-year-month-picker>
                </div>
                <div class="end-center flex">
                    <button mat-raised-button
                            color="primary"
                            (click)="exportToCsv()">
                        Download Monthly Design Revision Report
                    </button>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>
