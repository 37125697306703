import { AutocompleteScrollerComponent, getBaseAutocompleteScrollerProvider } from '../../autocomplete-scroller.component';
import { Component, Input, OnInit } from '@angular/core';

import { CategoryLogicService } from '@app/logic/product-categories';
import { MatDialog } from '@angular/material/dialog';
import { ProductCategorySearchService } from '@app/core/services/search/product-category.search.service';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';

@Component({
    selector: 'cb-product-category-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(ProductCategoryAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(ProductCategoryAutocompleteScrollerComponent),
        ProductCategorySearchService,
        CategoryLogicService
    ]
})
export class ProductCategoryAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent<any> // TODO: The results of the product category autocomplete scroller should be typed
    implements OnInit {
    @Input() public readonly displayCategories: boolean;
    @Input() public readonly categoryId: number;
    @Input() public readonly productCategoryType: number;

    public results: any[] = [];
    public searchResultsLoaded = false;

    constructor(
        public readonly productCategoryLogicService: CategoryLogicService,
        public readonly dialog: MatDialog,
        public readonly searchService: ProductCategorySearchService
    ) {
        super(dialog, searchService);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public displayWith = (item: any): string => {
        let displayText = '';
        if (item) {
            displayText += item.treeDisplay;
        }
        return displayText;
    };
}
