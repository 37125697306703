<cb-dialog class="non-working-day-location-dialog">
    <form class="flex-col flex"
          #nonWorkingDayForm="ngForm">
        <div class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex-100">
            <div class="start-center flex-row">
                <h3 class="mat-headline-6 flex">
                    Non-Working Days
                </h3>
                <cb-checkbox label="Active Only"
                             name="showActiveOnly"
                             [(ngModel)]="showActiveOnly"
                             (change)="getDataSource()"></cb-checkbox>
            </div>
        </div>
        <div class="cb-table cb-margin-top">
            <mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="region">
                    <mat-header-cell *matHeaderCellDef>
                        Region
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.region?.label || item.label}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isActive">
                    <mat-header-cell *matHeaderCellDef>
                        Active
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <mat-icon>
                            {{item.isActive ? "check_box" : "check_box_outline_blank"}}
                        </mat-icon>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="businessEntity">
                    <mat-header-cell *matHeaderCellDef>
                        Business Entity
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.businessEntity?.label}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="addressRegion">
                    <mat-header-cell *matHeaderCellDef>
                        Address Region
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        {{item.addressRegion?.label}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        Select
                    </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <cb-checkbox [name]="'nonWorkingDay'+item.id"
                                     [(ngModel)]="item.isSelected"></cb-checkbox>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <cb-info-message *ngIf="dataSource && dataSource.length === 0"
                             [message]="'There are no Non-Working Days per Regions to display'"
                             [icon]="'info_circle'"></cb-info-message>
            <cb-loading-spinner *ngIf="!dataSource"></cb-loading-spinner>
        </div>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="onSaveClicked()"
                [disabled]="!nonWorkingDayForm.dirty || !nonWorkingDayForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
