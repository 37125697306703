import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import { ILotAmenitiesDto } from '@classictechsolutions/hubapi-transpiled-enums';

export enum LotAmenitiesDialogRequiredMode {
    None = 0,
    All = 1,
    Existing = 2,
}

export interface ILotAmenitiesDialogData {
    amenities: ILotAmenitiesDto;
    required?: LotAmenitiesDialogRequiredMode | ILotAmenitiesDialogRequired;
}

export interface ILotAmenitiesDialogRequired {
    floorArea: boolean;
    landArea: boolean;
    noBedrooms: boolean;
    noBathrooms: boolean;
    noLivingRooms: boolean;
    noGarages: boolean;
    areaOverFrame: boolean;
}

export class LotAmenitiesDialogExistingRequired implements ILotAmenitiesDialogRequired {
    public floorArea: boolean;
    public landArea: boolean;
    public noBedrooms: boolean;
    public noBathrooms: boolean;
    public noLivingRooms: boolean;
    public noGarages: boolean;
    public areaOverFrame: boolean;
    constructor(_amenities: ILotAmenitiesDto | null, _override?: boolean) {
        this.floorArea = _override ?? _amenities?.floorArea != null;
        this.landArea = _override ?? _amenities?.landArea != null;
        this.noBedrooms = _override ?? _amenities?.noBedrooms != null;
        this.noBathrooms = _override ?? _amenities?.noBathrooms != null;
        this.noLivingRooms = _override ?? _amenities?.noLivingRooms != null;
        this.noGarages = _override ?? _amenities?.noGarages != null;
        this.areaOverFrame = _override ?? _amenities?.areaOverFrame != null;
    }
}

@Component({
    templateUrl: './amenities-dialog.component.html',
    styleUrls: ['./amenities-dialog.component.scss']
})
export class LotAmenitiesDialogComponent {
    public static readonly MIN_WIDTH = '40%';

    public amenities: ILotAmenitiesDto;
    public required: ILotAmenitiesDialogRequired;

    constructor(
        private readonly dialogRef: MatDialogRef<LotAmenitiesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: ILotAmenitiesDialogData,
    ) {
        this.amenities = cloneDeepSafe(this.data.amenities);
        delete this.data.amenities;
        this.setupRequiredFields();
    }

    private setupRequiredFields(): void {
        switch (this.data.required) {
            case LotAmenitiesDialogRequiredMode.All:
                this.required = new LotAmenitiesDialogExistingRequired(null, true);
                break;
            case LotAmenitiesDialogRequiredMode.Existing:
                this.required = new LotAmenitiesDialogExistingRequired(this.data.amenities);
                break;
            default:
                this.required = new LotAmenitiesDialogExistingRequired(null, false);
                break;
        }
    }

    public save(): void {
        this.dialogRef.close(this.amenities);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
