<div class="cb-margin flex-col">
    <mat-tab-group class="flex"
                   mat-stretch-tabs="false"
                   (selectedIndexChange)="onTabChanged($event);"
                   [selectedIndex]="selectedTabIndex"
                   cbTabUrlParam="cbTabRouteId2"
                   [cbTabRoute]="LOTS_ROUTE">
        <mat-tab label="Changes"
                 cbTabRouteId="changes"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-lot-changes [lotMappedItem]="lotMappedItem"></cb-lot-changes>
            </ng-template>
        </mat-tab>
        <mat-tab label="Variations"
                 cbTabRouteId="variations"
                 cbNestedTabLevels="0">
            <ng-template matTabContent>
                <cb-lot-variations [lotMappedItem]="lotMappedItem"></cb-lot-variations>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
