import { Component, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { LotsLogicService } from '@app/logic/lots';
import { IVariationLogicService } from '@app/logic/variation/interfaces/i.variation.logic-service';
import { IVariationMappedItem } from '@app/logic/variation/interfaces/i.variation.mapped';
import { VariationLogicService } from '@app/logic/variation/variation.logic-service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IUnassignedChangeRecordDto, IVariationDto, IVariationLineDto } from '@classictechsolutions/hubapi-transpiled-enums';
import _ from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';


interface IData {
    mappedItem: IVariationMappedItem;
}

enum VariationLineFate {
    Remove = 0,
    Keep = 1,
    Add = 2
}

@Component({
    selector: 'app-lot-variation-changes-dialog',
    templateUrl: './lot-variation-changes-dialog.component.html',
    styleUrls: ['./lot-variation-changes-dialog.component.scss']
})
export class LotVariationChangesDialogComponent extends BaseDialogFormViewDirective<IVariationDto, IVariationMappedItem, IVariationLogicService> {
    public static readonly MIN_WIDTH = '650px';
    public unassignedChangeRecords: IUnassignedChangeRecordDto[] = [];

    public variationLines: IVariationLineDto[] = [];
    public variationLinesFate: { [changeRecordId: number]: number } = {};

    public dragulaGroup = 'VARIATION_CHANGES';
    public DRAGULA_IGNORE = 'cb-table-footer-row';
    public reorderingEnabled = true;
    public lineOrderIsPristine = true;
    public isSaving = false;

    /** newLines are variation lines that have been added to the variation during this dialog instance;
     * they will remain in this dictionary even if set to 'VariationLineFate.Removed' as a record of their origin
     */
    public newLines: { [changeRecordId: number]: boolean } = {};
    public dragulaModel: number[] = [];

    public selectedChangeToAdd: number;

    private readonly $subs = new Subscription();
    @Output() public readonly sortOrderChanged = new EventEmitter<{ [collectionItemId: number]: number }>();

    constructor(
        public readonly dialogRef: MatDialogRef<LotVariationChangesDialogComponent>,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(LotsLogicService) public readonly lotsLogicService: LotsLogicService,
        @Inject(VariationLogicService) public readonly variationLogicService: VariationLogicService,
        public readonly dragulaService: DragulaService,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.variationLines = this.mappedItem.lines.sort((a, b) => a.sortOrder - b.sortOrder);
        this.loadunassignedChangeRecords();

        this.mappedItem.lines.forEach(x => {
            this.variationLinesFate[x.changeRecordId] = VariationLineFate.Keep;
        });
        this.setDragulaModel();
        this.setupDragula();

    }

    public saveDisabled(): boolean {
        return this.isSaving;
    }

    private setDragulaModel(): void {
        this.dragulaModel = this.variationLines?.sort(x => x.sortOrder).map(x => x.changeRecordId) ?? [];
    }

    private setupDragula(): void {
        this.dragulaService.destroy(this.dragulaGroup);
        this.dragulaService.createGroup(
            this.dragulaGroup,
            {
                removeOnSpill: false,
                accepts: (el: Element) => this.reorderingEnabled && !el.classList.contains(this.DRAGULA_IGNORE),
                moves: (el: Element) => this.reorderingEnabled && !el.classList.contains(this.DRAGULA_IGNORE),
                revertOnSpill: true
            });

        this.$subs.add(
            this.dragulaService.dropModel(this.dragulaGroup)
                .subscribe(
                    ({ el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
                        if (sourceIndex === targetIndex) {
                            return;
                        }
                        this.lineOrderIsPristine = false;
                        moveItemInArray(this.variationLines, sourceIndex, targetIndex);
                        const sortOrders: { [collectionItemId: number]: number } = {};
                        this.variationLines.forEach((x, i) => {
                            x.sortOrder = i;
                            sortOrders[x.id] = x.sortOrder;
                        });
                        this.setDragulaModel();
                        this.sortOrderChanged.emit(sortOrders);
                    }
                )
        );
    }


    private loadunassignedChangeRecords(): void {
        this.lotsLogicService.getUnassignedChangeRecordsForVariation(this.mappedItem.lotId, this.mappedItem.costNature).subOnce((result) => {
            this.unassignedChangeRecords = result;
        });
    }

    public variationLineIsRemoved(line: IVariationLineDto): boolean {
        return this.variationLinesFate[line.changeRecordId] === VariationLineFate.Remove;
    }

    public variationLineStyle(line: IVariationLineDto): string {
        switch (this.variationLinesFate[line.changeRecordId]) {
            case VariationLineFate.Remove:
                return 'strike-text table-warn-no-scrollbar';
            case VariationLineFate.Add:
                return 'table-green-no-scrollbar';
            default:
                return '';
        }
    }

    public removeVariationLine(variationLine: IVariationLineDto): void {
        this.variationLinesFate[variationLine.changeRecordId] = VariationLineFate.Remove;
    }

    public keepVariationLine(variationLine: IVariationLineDto): void {
        this.variationLinesFate[variationLine.changeRecordId] = this.newLines[variationLine.changeRecordId] ? VariationLineFate.Add : VariationLineFate.Keep;
    }

    public onUnassignedChangeRecordSelected($event): void {
        this.selectedChangeToAdd = $event;
    }

    public addVariationLine(): void {
        if (!this.selectedChangeToAdd) {
            return;
        }

        const changeRecord = _.remove(this.unassignedChangeRecords, { changeRecordId: this.selectedChangeToAdd }).pop();

        if (!changeRecord) {
            return;
        }


        const maxSortOrder = Math.max(...this.variationLines.map(x => x.sortOrder));

        const variationLine: IVariationLineDto = {
            changeDetails: changeRecord.changeRecordDetails,
            changeRecordId: changeRecord.changeRecordId,
            changeRecordNumber: changeRecord.changeNumber,
            lineCost: changeRecord.costChangeAmount,
            lineCostWithGst: changeRecord.costChangeAmountWithGst,
            sortOrder: maxSortOrder + 1,
        } as IVariationLineDto;

        this.variationLinesFate[changeRecord.changeRecordId] = VariationLineFate.Add;
        this.newLines[changeRecord.changeRecordId] = true;

        this.variationLines.push(variationLine);
        this.selectedChangeToAdd = undefined;
    }

    public saveChanges(): void {
        if (this.isSaving) {
            return;
        }
        this.isSaving = false;
        const variationLineIdsToRemove = [];
        const changeRecordIdsToAdd = [];

        let isAddComplete = true;
        let isRemoveComplete = true;
        let isSortComplete = this.lineOrderIsPristine;

        this.forEachVariationLine(
            (line, fate) => {
                if (fate === VariationLineFate.Add) {
                    changeRecordIdsToAdd.push(line.changeRecordId);
                } else if (line.id && fate === VariationLineFate.Remove && !this.newLines[line.changeRecordId]) {
                    variationLineIdsToRemove.push(line.id);
                }

            },
            [VariationLineFate.Add, VariationLineFate.Remove]
        );

        if (changeRecordIdsToAdd.length > 0) {
            isAddComplete = false;
            this.isSaving = true;
            this.variationLogicService.addVariationlines(this.mappedItem.id, changeRecordIdsToAdd).subOnce(() => {
                isAddComplete = true;
                if (isSortComplete === false) {
                    this.variationLogicService.updateVariationLineSortOrder(this.mappedItem.id, this.mappedItem.lines).subOnce(() => {
                        isSortComplete = true;
                        this.lineOrderIsPristine = true;
                        this.handlePotentionalClose(isAddComplete, isRemoveComplete, isSortComplete);

                    });
                } else {
                    this.handlePotentionalClose(isAddComplete, isRemoveComplete, isSortComplete);
                }
            });
        }
        else if (!isSortComplete) {
            this.isSaving = true;
            this.variationLogicService.updateVariationLineSortOrder(this.mappedItem.id, this.mappedItem.lines).subOnce(() => {
                isSortComplete = true;
                this.handlePotentionalClose(isAddComplete, isRemoveComplete, isSortComplete);
            });
        }

        if (variationLineIdsToRemove.length > 0) {
            isRemoveComplete = false;
            this.isSaving = true;
            this.variationLogicService.removeVariationlines(this.mappedItem.id, variationLineIdsToRemove).subOnce(() => {
                isRemoveComplete = true;
                this.handlePotentionalClose(isAddComplete, isRemoveComplete, isSortComplete);
            });
        }



    }


    private forEachVariationLine(callback: (line: IVariationLineDto, fate: number) => void, fates?: VariationLineFate[]): void {
        for (const key in this.variationLines) {
            if (!this.variationLines[key]) { continue; }
            const line = this.variationLines[key];
            const fate = this.variationLinesFate[line.changeRecordId];
            if (fate < 0 || (fates && fates.indexOf(fate) < 0)) { continue; }
            callback(line, fate);
        }
    }

    private handlePotentionalClose(isAddComplete: boolean, isRemoveComplete: boolean, isSortComplete: boolean): void {
        if (isAddComplete && isRemoveComplete && isSortComplete) {
            this.isSaving = false;
            this.dialogRef.close(true);

        }
    }

}
