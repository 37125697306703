import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { FormMode } from '@app/shared/enums/form';
import {
    ICostxRateLibraryDto,
    ICostxRateLibraryMappedItem,
    CostxRateLibraryLogicService,
    ICostxRateLibraryLogicService
} from '@app/logic/costx-rate-library';
import { toPromisedArray } from 'cb-hub-lib';
import { map } from 'rxjs';
import { orderBy } from 'lodash';

@Component({
    templateUrl: './manage-costx-export-dialog.component.html',
    styleUrls: ['./manage-costx-export-dialog.component.scss']
})
export class ManageCostxExportDialogComponent
    extends BaseDialogFormViewDirective<ICostxRateLibraryDto, ICostxRateLibraryMappedItem, ICostxRateLibraryLogicService>
    implements OnInit {
    public static readonly MIN_WIDTH = '40%';

    public readonly title = new ComputedProperty(() => {
        return this.formMode === FormMode.Add ? 'CostX - Add Export' : 'CostX - Manage Export';
    });

    public rateLocations = toPromisedArray(
        this.costxRateLibraryLogic
            .getAvailableLocations()
            .pipe(
                map(x => orderBy(x, 'code')))
    );

    public get mappedItem(): ICostxRateLibraryMappedItem {
        return this.data.mappedItem;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<ManageCostxExportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: { mappedItem: ICostxRateLibraryMappedItem },
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CostxRateLibraryLogicService) public readonly costxRateLibraryLogic: CostxRateLibraryLogicService,
    ) {
        super(dialogRef, toastService);
        this.title.recompute();
    }

    public ngOnInit(): void {
        this.rateLocations.$promise.then(this.unshiftExistingLocation);
    }

    private readonly unshiftExistingLocation = (): void => {
        if (!this.mappedItem.locationId) {
            return;
        }
        this.rateLocations.unshift({
            id: this.mappedItem.locationId,
            label: this.mappedItem.locationName,
            code: this.mappedItem.locationPath,
            isActive: true,
        });
    };
}
