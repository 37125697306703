<cb-dialog dialogHeading="Classic Care">
    <form class="flex-col flex"
          #classicCareForm="ngForm"
          ngForm="classicCareForm">
        <cb-checkbox name="enableClassicCare"
                     label="Enable Classic Care"
                     [(ngModel)]="classicCareDto.enableClassicCare"></cb-checkbox>
        <cb-contact-autocomplete-scroller class="cb-margin-top flex"
                                          label="Contact"
                                          name="contact-search"
                                          [(ngModel)]="selectedContact"
                                          [requireMatch]="true"
                                          [matchProps]="['id']"
                                          [active]="true"
                                          (optionSelected)="contactSelected($event)"
                                          [required]="true"></cb-contact-autocomplete-scroller>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="!classicCareForm.dirty || !classicCareForm.valid"
                (click)="save()">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
