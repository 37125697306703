<cb-dialog dialogHeading="Consent Received by Council">
    <form class="flex-col flex"
          #grantConsentForm="ngForm">
        <cb-input type="text"
                  label="Consent Number"
                  name="consentNumber"
                  [(ngModel)]="mappedItem.consentNumber"
                  [softRequired]="true"
                  autocomplete="off"></cb-input>
        <cb-datepicker label="Date Consent Received"
                       name="receivedByCouncilDate"
                       pickerType="calendar"
                       [(ngModel)]="mappedItem.receivedByCouncilDate"
                       [required]="true"
                       (change)="receivedDateChanged()"></cb-datepicker>
        <cb-datepicker label="Consent Due Date"
                       name="dueDate"
                       pickerType="calendar"
                       [(ngModel)]="mappedItem.consentDueDate"
                       [required]="true"></cb-datepicker>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="grantConsentForm.invalid"
                (cbClick)="save()"
                [clickLimit]="1">Submit
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
