<form class="flex-col flex"
      #workingDrawingCardForm="ngForm">
    <mat-card class="cb-margin flex-col flex">
        <mat-card-header>
            <div class="start-start flex-row flex">
                <div class="cb-margin-right-2x flex-row"
                     style="margin-top: 5px;">
                    <mat-icon [ngClass]="getStatusIconClass()"
                              class="help-cursor"
                              [matTooltip]="getStatusIconTitle()">{{ getStatusIconIcon() }}
                    </mat-icon>
                </div>
                <span class="mat-headline-6">{{ mappedItem.name }}</span>
                <span class="flex"></span>
                <mat-icon *ngIf="mappedItem.$hasUnansweredQuestion"
                          class="help-cursor mat-warn"
                          matTooltip="Has unanswered question"
                          svgIcon="comment-question-outline">
                </mat-icon>
            </div>
        </mat-card-header>
        <mat-card-content class="flex">
            <div class="flex-row flex">
                <div class="flex-col flex">
                    <cb-display-value-new label="Prepared by"
                                          [value]="mappedItem.createdByName">
                    </cb-display-value-new>
                    <cb-user-date-time-new label="Created Date"
                                           [floatingLabel]="true"
                                           [date]="mappedItem.createdDate">
                    </cb-user-date-time-new>
                    <div class="flex-row flex">
                        <cb-display-value-new class="flex"
                                              label="Assigned Changes"
                                              [value]=" 0">
                        </cb-display-value-new>
                    </div>
                    <div class="flex-row flex">
                        <cb-display-value-new class="flex"
                                              label="Assigned To"
                                              [value]="mappedItem.assignedToName">
                        </cb-display-value-new>
                        <cb-display-value-new class="flex"
                                              label="Reviewer"
                                              [value]="mappedItem.reviewerName">
                        </cb-display-value-new>
                    </div>
                    <div class="flex-row flex">
                        <cb-display-value-new class="flex"
                                              label="Status">
                            {{ WORKING_DRAWING_STATUS_ENUM[mappedItem.statusId] }}
                        </cb-display-value-new>
                    </div>
                </div>
            </div>
        </mat-card-content>
        <mat-divider class="card-divider"></mat-divider>
        <mat-card-actions class="end-center flex-row">
            <button *ngIf="canCompleteWorkingDrawing"
                    mat-stroked-button
                    color="accent"
                    (click)="completeWorkingDrawing()">
                Complete
            </button>
            <span class="flex"></span>
            <button mat-stroked-button
                    color="accent"
                    (click)="viewWorkingDrawing()">
                View
            </button>
        </mat-card-actions>
    </mat-card>
</form>
