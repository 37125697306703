import { getBaseAutocompleteScrollerProvider, AutocompleteScrollerComponent } from '../../autocomplete-scroller.component';
import { Component, Input, OnChanges } from '@angular/core';
import { BuildProgrammeLogicService } from '@app/logic/build-programme';
import { MatDialog } from '@angular/material/dialog';
import { IContactSearchDto, IContactSearchParams } from '@app/logic/contacts';
import { ContactsSearchService } from '@app/core/services/search/contacts-search.service';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';

@Component({
    selector: 'cb-contact-autocomplete-scroller',
    templateUrl: '../../autocomplete-scroller.component.html',
    providers: [
        ...getBaseFormComponentDirectiveProvider(ContactAutocompleteScrollerComponent),
        getBaseAutocompleteScrollerProvider(ContactAutocompleteScrollerComponent),
        ContactsSearchService,
    ]
})
export class ContactAutocompleteScrollerComponent
    extends AutocompleteScrollerComponent<IContactSearchDto> implements OnChanges {

    @Input() public readonly active: boolean;
    @Input() public readonly searchParams: IContactSearchParams;

    public results: IContactSearchDto[] = [];

    constructor(
        public readonly buildProgrammeLogicService: BuildProgrammeLogicService,
        public readonly dialog: MatDialog,
        public readonly searchService: ContactsSearchService
    ) {
        super(dialog, searchService);
    }

    public ngOnChanges(): void {
        this.searchService.extraSearchParams = {
            active: this.active,
            ...this.searchParams,
        };
    }
}
