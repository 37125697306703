import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { MinorComponents } from '@app/views/xpath-helper-page/minor-components/minor.components';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {FormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {CbButtonModule} from "@app/shared/components/button/button.module";
import {MatListModule} from "@angular/material/list";
import {LotBuildModule} from "@app/views/lot/build/build.module";
import {MatTabsModule} from "@angular/material/tabs";
import {CbSelectListModule} from "cb-hub-lib";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";

@NgModule({
    declarations: [
        MinorComponents
    ],
    imports: [
        CommonModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatRadioModule,
        MatIconModule,
        MatMenuModule,
        CbFormsModule,
        RouterModule.forRoot(
            [
                {
                    component: MinorComponents,
                    path: 'xpath/helper',
                    data: {breadcrumb: 'xpath helper'},
                }
            ],
            {
                onSameUrlNavigation: 'reload'
            }
        ),
        FormsModule,
        MatCheckboxModule,
        CbButtonModule,
        MatListModule,
        LotBuildModule,
        MatTabsModule,
        CbSelectListModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        MatInputModule,
    ],
})
export class XpathHelperModule { }
