import {Component, Inject} from '@angular/core';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {
    DOCUMENT_GROUP_CODES_CONST,
    IPreConsentPlanDto, PRECONSENT_PLAN_STATUS_ENUM, SYSTEM_AREA_ENUM,
} from '@classictechsolutions/hubapi-transpiled-enums';
import {IPreConsentPlanMappedItem} from '@app/logic/pre-consent-plans';
import {IPreConsentPlansLogicService} from '@app/logic/pre-consent-plans/interfaces/i.pre-consent-plans-logic.service';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ILotMappedItem} from '@app/logic/lots';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {ToastService} from '@app/core/services/toast/toast.service';
import {DesignSchemePermissions} from '@app/core/permissions';
import {IDocumentPermissions} from '@app/core/permissions/base.permissions';
import {SimpleFormInputType} from '@app/shared/components/dialog/simple-form-dialog/simple-form-dialog.component';

@Component({
    selector: 'cb-review-preconsent-plan-dialog',
    templateUrl: './review-preconsent-plan-dialog.component.html',
    styleUrls: ['./review-preconsent-plan-dialog.component.scss']
})
export class ReviewPreconsentPlanDialogComponent extends BaseDialogFormViewDirective<IPreConsentPlanDto, IPreConsentPlanMappedItem, IPreConsentPlansLogicService> {
    public static readonly MIN_WIDTH = '80%';

    public readonly DESIGN_PRE_CONSENT_GROUP = DOCUMENT_GROUP_CODES_CONST.DESIGN_PRE_CONSENT;
    public readonly systemArea = SYSTEM_AREA_ENUM.PreConsent;
    private readonly documentPermissions: IDocumentPermissions;

    public get mappedItem(): IPreConsentPlanMappedItem {
        return this.data.mappedItem;
    }

    public get lotMappedItem(): ILotMappedItem {
        return this.data.lot;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<ReviewPreconsentPlanDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            mappedItem: IPreConsentPlanMappedItem;
            lot: ILotMappedItem;
        },
        private readonly designSchemePermissions: DesignSchemePermissions,
        private readonly cbDialog: CbDialogService,
        toastService: ToastService
    ) {
        super(dialogRef, toastService, cbDialog);
        this.documentPermissions = this.designSchemePermissions.getDocumentPermissions();
        this.mappedItem.$reload().subOnce();
    }

    public getDocumentPermissions(): IDocumentPermissions {
        return this.documentPermissions;
    }

    public getHeading(): string {
        return this.mappedItem.planName ? `Pre-consent Plan (${this.mappedItem.planName})` : 'Pre-consent Plan';
    }

    public acceptPreConsentPlan(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Accept Pre-Consent',
            message: 'Are you sure you want to accept this Pre-Consent?',
            yesLabel: 'Confirm',
            noLabel: 'Cancel',
            confirmed: () => {
                this.mappedItem.preConsentPlanStatus.id = PRECONSENT_PLAN_STATUS_ENUM.Completed;
                this.mappedItem.$save().subOnce(result => {
                    this.dialogRef.close(result);
                });
            }
        });
    }

    public rejectPreConsentPlan(): void {
        this.cbDialog.simpleFormDialog({
            dialogHeading: 'Reject Pre-Consent',
            yesLabel: 'Confirm',
            noLabel: 'Close',
            value: '',
            formInputConfig: {
                type: SimpleFormInputType.Textarea,
                label: 'Reason for Rejection',
                required: true,
            },
            confirmed: (confirmedResult) => {
                this.mappedItem.rejectionReason = confirmedResult;
                const preconsentPlanDto = this.mappedItem.$getMappedDtoItem();
                this.mappedItem.reject(this.mappedItem.id, preconsentPlanDto).subOnce((result) => {
                    this.dialogRef.close(result);
                });
            }
        });
    }
}
