import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IIntegrationMessageSearchDto } from '@app/logic/integration-logs';

interface IData {
    message: IIntegrationMessageSearchDto;
}

@Component({
    templateUrl: './message-view-dialog.component.html',
    styleUrls: ['./message-view-dialog.component.scss'],
})
export class MessageViewDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '40%';

    public details: string[];

    public bodyJson: Object;

    constructor(
        public readonly dialogRef: MatDialogRef<MessageViewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData
    ) { }

    public ngOnInit(): void {
        this.bodyJson = JSON.parse(this.data.message.body);
    }

    public close(): void {
        this.dialogRef.close();
    }
}
