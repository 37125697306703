import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {ChangeRecordLogicService} from '@app/logic/change-records/change-record.logic-service';
import {IChangeRecordLogicService} from '@app/logic/change-records/interfaces/i.change-record.logic-service';
import {IChangeRecordMappedItem} from '@app/logic/change-records/interfaces/i.change-record.mapped';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {COST_NATURE_ENUM, IChangeRecordDto} from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    pendingChanges: IChangeRecordMappedItem[];
}


@Component({
    selector: 'app-lot-submit-changes-dialog',
    templateUrl: './lot-submit-changes-dialog.component.html',
    styleUrls: ['./lot-submit-changes-dialog.component.scss']
})
export class LotSubmitChangesDialogComponent extends BaseDialogFormViewDirective<IChangeRecordDto, IChangeRecordMappedItem, IChangeRecordLogicService> {
    public static readonly MIN_WIDTH = '600px';
    public pendingChanges: IChangeRecordMappedItem[];

    public readonly displayedColumns = [
        '',
        'Change#',
        'Description',
        'Payer',
    ];

    public COST_NATURE_ENUM = COST_NATURE_ENUM;

    constructor(
        public readonly dialogRef: MatDialogRef<LotSubmitChangesDialogComponent>,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ChangeRecordLogicService) public readonly changeRecordLogicService: ChangeRecordLogicService,
    ) {
        super(dialogRef, toastService);
        this.pendingChanges = data.pendingChanges;
    }

    public submitChangesForPricing(): void {
        const selectedSubmitForPricing = this.pendingChanges.filter((p) => p.isSelectedSubmitForPricing).map(({ id }) => id);

        this.changeRecordLogicService.submitForPricing(selectedSubmitForPricing).subOnce((results) => {
            this.dialogRef.close(selectedSubmitForPricing);
        });
    }

    public isSubmitEnabled(): boolean {
        return this.pendingChanges.filter((p) => p.isSelectedSubmitForPricing)?.length > 0;
    }

}

