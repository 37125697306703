import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IContactSearchDto } from '@app/logic/contacts';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';
import { IClientSaleClassicCareUpdateDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    clientSale: IClientSaleMappedItem;
}

@Component({
    selector: 'cb-classic-care-edit-dialog',
    templateUrl: './classic-care-edit-dialog.component.html',
    styleUrls: ['./classic-care-edit-dialog.component.scss']
})
export class ClassicCareEditDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {

    public classicCareDto: IClientSaleClassicCareUpdateDto;

    public selectedContact: IContactSearchDto | { id: string; name: string };

    public testValue;

    constructor(
        public readonly dialogRef: MatDialogRef<ClassicCareEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public toastService: ToastService
    ) {
        super(dialogRef, toastService);
    }

    public ngOnInit(): void {
        this.classicCareDto = {
            contactId: this.data.clientSale.classicCareContactId,
            enableClassicCare: this.data.clientSale.classicCareEnbaled
        };
        this.selectedContact = {
            id: this.classicCareDto.contactId,
            name: this.data.clientSale.classicCareContactName
        };
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public save(): void {
        this.data.clientSale.updateClassicCareDetails(this.classicCareDto)
            .subOnce({
                next: this.handleNext,
                error: this.handleError
            });
    }

    public contactSelected(value: IContactSearchDto | undefined): void {
        if (!value) {
            return;
        }

        this.classicCareDto.contactId = value.id;
    }
}
