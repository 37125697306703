import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {IChangeRecordLogicService} from '@app/logic/change-records/interfaces/i.change-record.logic-service';
import {IChangeRecordMappedItem} from '@app/logic/change-records/interfaces/i.change-record.mapped';
import {TeamsLogicService} from '@app/logic/teams/teams.logic.service';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import { CHANGE_STATUS_ENUM, IChangeRecordDto, IClassicUserDocumentDto, ITeamDto, TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import {Observable} from 'rxjs';
import {LotChangeReassignDialogComponent} from '../lot-change-reassign-dialog/lot-change-reassign-dialog.component';


@Component({
    selector: 'app-lot-change-reassign-approval-dialog',
    templateUrl: './lot-change-reassign-approval-dialog.component.html',
    styleUrls: ['./lot-change-reassign-approval-dialog.component.scss']
})
export class LotChangeReassignApprovalDialogComponent extends BaseDialogFormViewDirective<IChangeRecordDto, IChangeRecordMappedItem, IChangeRecordLogicService> {
    public static readonly MIN_WIDTH = '33%';
    public get mappedItem(): IChangeRecordMappedItem {
        return this.data;
    }

    public teams: ITeamDto[];
    public assignedUser: IClassicUserDocumentDto;
    public assignedTeam: ITeamDto;
    public approval = 'owner';


    constructor(
        public readonly dialogRef: MatDialogRef<LotChangeReassignDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IChangeRecordMappedItem,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(TeamsLogicService) private readonly teamsLogicService: TeamsLogicService,
    ) {
        super(dialogRef, toastService);
        this.loadTeams();
        this.loadAssignedUser();
        this.loadAssignedTeam();
    }

    private loadTeams(): void {
        this.teamsLogicService.$getList().subOnce((teams) => {
            this.teams = teams.filter(te => {
                return te.key === TEAM_CODES_CONST.DesignTeamKey ||
                    te.key === TEAM_CODES_CONST.QsTeamKey ||
                    te.key === TEAM_CODES_CONST.SalesTeamKey;
            });
        });

    }

    private loadAssignedUser(): void {
        this.assignedUser = {
            id: this.mappedItem.assignedToId,
            name: this.mappedItem.assignedTo
        } as IClassicUserDocumentDto;
    }

    private loadAssignedTeam(): void {
        this.assignedTeam = {
            id: this.mappedItem.assignedTeamId,
            name: this.mappedItem.assignedTeam
        } as ITeamDto;
    }

    public isTeamAndUserRequired(): boolean {
        return this.approval === 'someoneElse';
    }

    public teamSelected(): void {
        this.assignedTeam = this.teams.find(x => x.id === this.mappedItem.assignedTeamId);
    }

    public saveMethod(): Observable<IChangeRecordDto> {
        if (this.approval === 'owner') {
            this.mappedItem.assignedTo = this.mappedItem.owner;
            this.mappedItem.assignedToId = this.mappedItem.ownerId;
            this.mappedItem.changeStatus = CHANGE_STATUS_ENUM.ResponseRequired;
        } else {
            this.mappedItem.assignedTo = this.assignedUser.name;
            this.mappedItem.assignedToId = this.assignedUser.id;
        }

        this.mappedItem.assignedTeamId = this.assignedTeam.id;
        this.mappedItem.assignedTeam = this.assignedTeam.name;


        return this.mappedItem.$save();
    }

}
