import { ComputedProperty } from './../../../utils/computed-property.util';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITaskMappedItem } from '@app/logic/tasks';
import { taskTypesSelect } from '@app/logic/task-outcomes/task.constants';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { Observable } from 'rxjs';
import { UsersLogicService } from '@app/logic/users';
import { ProjectsLogicService } from '@app/logic/projects/projects.logic.service';
import { ITeamDto, TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { ITaskType } from '@app/logic/tasks/interfaces/i.Task.dto';

interface IData {
    taskTypes?: Array<ITaskType>;
    isProjectRelease: boolean;
    task: ITaskMappedItem;
}

interface IUserOption {
    firstName: string;
    lastName: string;
    id: string;
}

@Component({
    selector: 'cb-task-dialog',
    templateUrl: './task-dialog.component.html',
    styleUrls: ['./task-dialog.component.scss'],
    providers: [
        TeamsLogicService,
        UsersLogicService,
        ProjectsLogicService
    ]
})
export class TaskDialogComponent implements OnInit {
    public taskTypes = this.data?.taskTypes || taskTypesSelect;
    public teams: Observable<ITeamDto[]>;
    public users: Observable<IUserOption[]>;

    public userRequired = new ComputedProperty(() => {
        return this.data.isProjectRelease
            || !this.data.task.assignedToTeamId
            || this.data.task.assignedToTeamKey === TEAM_CODES_CONST.SalesTeamKey;
    });

    constructor(
        public readonly dialogRef: MatDialogRef<TaskDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly teamsLogicService: TeamsLogicService,
        public readonly userLogic: UsersLogicService,
        public readonly projectsLogic: ProjectsLogicService
    ) { }

    public ngOnInit(): void {
        this.teams = this.teamsLogicService.$getList();
        this.loadUsers();
    }

    public saveItem(): void {
        this.data.task.saveTask()
            .subOnce(() => {
                this.dialogRef.close(this.data.task);
            });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public loadUsers(): void {
        if (this.data.isProjectRelease) {
            this.users = this.projectsLogic.getProjectTeamMembersByProjectRelease(this.data.task.entityId as number);
        } else if (this.data.task.assignedToTeamId) {
            this.loadUsersForTeam();
        } else {
            this.users = this.userLogic.$getList();
        }
    }

    private loadUsersForTeam(): void {
        this.teams
            .subOnce({
                next: teams => {
                    const team = teams.find(y => y.id === this.data.task.assignedToTeamId);
                    if (team) {
                        this.users = this.teamsLogicService.loadActiveMembersByKey(team.key);
                    }
                }
            });
    }
}
