<cb-dialog>
    <form class="flex-col flex"
          #taskOutcomeForm="ngForm">
        <h1 class="mat-headline-6">
            {{ mappedItem.id > 0 ? 'Edit' : 'New' }} Task Outcome
        </h1>
        <cb-input type="text"
                  name="name"
                  label="Task Outcome Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.name"></cb-input>
        <cb-select [required]="true"
                   label="Task Type"
                   name="taskTypes"
                   [options]="taskTypes"
                   [(ngModel)]="mappedItem.taskType"></cb-select>
        <mat-hint>
            System Areas
        </mat-hint>
        <div *ngFor="let area of systemAreas">
            <mat-checkbox class="example-margin"
                          (change)="onChange($event)"
                          [checked]="area.isSelected"
                          [value]="area">
                {{ area.label }}
            </mat-checkbox>
        </div>
        <br/>
        <cb-input type="text"
                  name="code"
                  label="Code"
                  [disabled]="mappedItem.id > 0"
                  [(ngModel)]="mappedItem.code"></cb-input>
        <cb-checkbox name="isActive"
                     label="Is Active"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
    </form>
    <div actions>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!(taskOutcomeForm.dirty || systemAreaChanged) || !taskOutcomeForm.valid ">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
