import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IChangeRecordLogicService } from '@app/logic/change-records/interfaces/i.change-record.logic-service';
import { IChangeRecordMappedItem } from '@app/logic/change-records/interfaces/i.change-record.mapped';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IChangeRecordDto, IClassicUserDocumentDto, ITeamDto, TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-lot-change-reassign-dialog',
    templateUrl: './lot-change-reassign-dialog.component.html',
    styleUrls: ['./lot-change-reassign-dialog.component.scss']
})
export class LotChangeReassignDialogComponent extends BaseDialogFormViewDirective<IChangeRecordDto, IChangeRecordMappedItem, IChangeRecordLogicService> {
    public static readonly MIN_WIDTH = '33%';
    public get mappedItem(): IChangeRecordMappedItem {
        return this.data;
    }

    public teams: ITeamDto[];
    public assignedUser: IClassicUserDocumentDto;
    public assignedTeam: ITeamDto;


    constructor(
        public readonly dialogRef: MatDialogRef<LotChangeReassignDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IChangeRecordMappedItem,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(TeamsLogicService) private readonly teamsLogicService: TeamsLogicService,
    ) {
        super(dialogRef, toastService);
        this.loadTeams();
        this.loadAssignedUser();
        this.loadAssignedTeam();
    }

    private loadTeams(): void {
        this.teamsLogicService.$getList().subOnce((teams) => {
            this.teams = teams.filter(te => {
                return te.key === TEAM_CODES_CONST.DesignTeamKey ||
                    te.key === TEAM_CODES_CONST.QsTeamKey ||
                    te.key === TEAM_CODES_CONST.SalesTeamKey;
            });
        });
    }

    private loadAssignedUser(): void {
        this.assignedUser = {
            id: this.mappedItem.assignedToId,
            name: this.mappedItem.assignedTo
        } as IClassicUserDocumentDto;
    }

    private loadAssignedTeam(): void {
        this.assignedTeam = {
            id: this.mappedItem.assignedTeamId,
            name: this.mappedItem.assignedTeam
        } as ITeamDto;
    }


    public teamSelected(): void {
        this.assignedTeam = this.teams.find(x => x.id === this.mappedItem.assignedTeamId);
    }

    private _preSave(): void {
        this.mappedItem.assignedTo = this.assignedUser.name;
        this.mappedItem.assignedToId = this.assignedUser.id;
        this.mappedItem.assignedTeamId = this.assignedTeam.id;
        this.mappedItem.assignedTeam = this.assignedTeam.name;

    }

    public saveMethod(): Observable<IChangeRecordDto> {
        this._preSave();
        return this.mappedItem.$save();
    }

}
