<section class="main-container" (scroll)="onScroll($event)">
    <table class="scroll-container mat-elevation-z8" mat-table [dataSource]="dataSource" >
        <ng-container *ngFor="let column of displayedColumns"
                      [matColumnDef]="column.key"
                      [sticky]="isSticky(column.key)">
            <th mat-header-cell *matHeaderCellDef>
                <div class="center no-wrap" [ngSwitch]="column.filterType">
                    <ng-container *ngSwitchCase="'custom'">
                        <div [ngSwitch]="column.key">

                            <ng-container *ngSwitchCase="'projectName'">
                                <mat-form-field class="flex">
                                    <mat-label class="flex center no-wrap">{{ column.label }}</mat-label>
                                    <mat-select [formControl]="filter.projectNames"
                                                (selectionChange)="onProjectChange()"
                                                multiple>
                                        <mat-option *ngFor="let project of getProject()" [value]="project">
                                            {{ project }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngSwitchCase="'releaseStage'">
                                <mat-form-field class="flex">
                                    <mat-label class="flex center no-wrap">{{ column.label }}</mat-label>
                                    <mat-select [formControl]="filter.releaseStages"
                                                (selectionChange)="onSelectionChange()"
                                                multiple>
                                        <mat-option *ngFor="let release of getReleases()" [value]="release">
                                            {{ release.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngSwitchCase="'contractType'">
                                <mat-form-field class="flex">
                                    <mat-label class="flex center no-wrap">{{column.label}}</mat-label>
                                    <mat-select
                                        [formControl]="filter.contractTypes"
                                        (selectionChange)="onSelectionChange()"
                                        multiple>
                                        <mat-option *ngFor="let contract of getContractTypes()"
                                                    [value]="contract.id">{{ contract.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngSwitchCase="'buildingConsultant'">
                                <mat-form-field class="flex">
                                    <mat-label class="flex center no-wrap">{{column.label}}</mat-label>
                                    <mat-select
                                        [formControl]="filter.buildingConsultants"
                                        multiple
                                        (selectionChange)="onSelectionChange()">
                                        <mat-option
                                            *ngFor="let option of getBuildingConsultants()"
                                            [value]="option.label"
                                        >
                                            {{ option.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngSwitchCase="'businessEntity'">
                                <mat-form-field class="flex">
                                    <mat-label class="flex center no-wrap">{{column.label}}</mat-label>
                                    <mat-select
                                        [formControl]="filter.businessEntities"
                                        multiple
                                        (selectionChange)="onSelectionChange()">
                                        <mat-option *ngFor="let option of this.getBusinessEntitiesNames()"
                                                    [value]="option">{{ businessEntitiesDict[option] }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>

                            <ng-container *ngSwitchCase="'district'">
                                <mat-form-field class="flex">
                                    <mat-label class="flex center no-wrap">{{column.label}}</mat-label>
                                    <mat-select
                                        [formControl]="filter.districts"
                                        (selectionChange)="onDistrictChange()"
                                        multiple>
                                        <mat-option *ngFor="let district of getDistricts()"
                                                    [value]="district.id">{{ district.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchCase="'area'">
                                <mat-form-field class="flex">
                                    <mat-label class="flex center no-wrap">{{column.label}}</mat-label>
                                    <mat-select
                                        [formControl]="filter.areas"
                                        (selectionChange)="onSelectionChange()"
                                        multiple>
                                        <mat-option *ngFor="let area of this.areas"
                                                    [value]="area.id">{{ area.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{ column.label }}
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'sortable'">
                        <div class="sortable-header flex flex-row center-center" (click)="changeSortOrder(column.key)">
                            <div>
                                {{column.label}}
                            </div>
                            <mat-icon class="sort-arrow" [ngClass]="{ 'active': isSorted(column.key) }">
                                {{ getSortIcon(column.key) }}
                            </mat-icon>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        {{ column.label }}
                    </ng-container>
                </div>
            </th>

            <td mat-cell *matCellDef="let element">
                <div class="center" [ngSwitch]="column.type">
                    <ng-container *ngSwitchCase="'date'">
                        {{ element[column.key] | date:'d MMM y' }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'text'">
                        <div
                            title="{{ element[column.key] }}"
                        >
                            {{ element[column.key] | cbTruncate:maxLength }}
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'custom'">
                    <div [ngSwitch]="column.key">
                        <ng-container *ngSwitchCase="'settlementDate'">
                            <ng-container *ngIf="!element.editingSettlementDate; else editSettlementTemplate">
                                <div
                                    class="center editable-field editable-block"
                                    (click)="element.editingSettlementDate = true"
                                >
                                    {{ element.settlementDate ? (element.settlementDate | date:'d MMM y') : 'Click to add' }}
                                </div>
                            </ng-container>

                            <ng-template #editSettlementTemplate>
                                <div class="center-center datePicker editable-block">
                                    <mat-form-field appearance="outline">
                                        <mat-label>MM/DD/YYYY</mat-label>
                                        <input
                                            matInput
                                            [matDatepicker]="settlementPicker"
                                            [(ngModel)]="element.settlementDate"
                                            (dateChange)="onSettlementDateChange(element)"
                                        />
                                        <mat-datepicker-toggle matSuffix [for]="settlementPicker"></mat-datepicker-toggle>
                                        <mat-datepicker #settlementPicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngSwitchCase="'floorDownDate'">
                            <ng-container *ngIf="!element.editingFloorDownDate; else editFloorDownTemplate">
                                <div
                                    class="center editable-field editable-block"
                                    (click)="element.editingFloorDownDate = true"
                                >
                                    {{ element.floorDownDate ? (element.floorDownDate | date:'d MMM y') : 'Click to add' }}
                                </div>
                            </ng-container>

                            <ng-template #editFloorDownTemplate>
                                <div class="center-center datePicker editable-block" >
                                    <mat-form-field appearance="outline">
                                        <mat-label>MM/DD/YYYY</mat-label>
                                        <input
                                            matInput
                                            [matDatepicker]="floorDownPicker"
                                            [(ngModel)]="element.floorDownDate"
                                            (dateChange)="onFloorDownDateChange(element)"
                                        />
                                        <mat-datepicker-toggle matSuffix [for]="floorDownPicker"></mat-datepicker-toggle>
                                        <mat-datepicker #floorDownPicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngSwitchCase="'contractConditions'">
                            <ng-container *ngIf="!element.editingContractConditions; else editContractConditionsTemplate">
                                <div
                                    class="center editable-field editable-block"
                                    (click)="startEditingContractConditions(element)"
                                    [title]="element.contractConditions || 'Click to add...'"
                                >
                                    {{ isEmpty(element.contractConditions) ? (element.contractConditions | cbTruncate: 50) : 'Click to add' }}
                                </div>
                            </ng-container>

                            <ng-template #editContractConditionsTemplate>
                                <div class="center-center editable-block">
                                    <mat-form-field appearance="outline">
                                        <textarea class="text-block"
                                            matInput
                                            [(ngModel)]="element.contractConditions"
                                            (blur)="onContractConditionsChange(element)"
                                            maxlength="300"
                                        ></textarea>
                                    </mat-form-field>
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngSwitchCase="'businessEntity'">
                            <div class="center">{{ getBusinessEntitiesName(element.businessEntityId) }}</div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'jobNumber'">
                            <div class="center">
                                <a (click)="onJobNumberClick(element.id)" class="job-number-link">{{ element.jobNumber }}</a>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                            Custom column for {{ column.key }} not yet implemented
                        </ng-container>
                    </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{ element[column.key] }}
                    </ng-container>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnKeys; sticky:true" class="header"></tr>
        <tr mat-row *matRowDef="let row; columns: columnKeys;" (click)="onRowClick($event, row)"></tr>
    </table>
</section>
