<mat-card class="cb-margin flex-row flex">
    <mat-card-content class="start-start flex-col flex"
                      style="width: 100%">
        <h3 class="mat-headline-6"
            style="margin-bottom: 0">
            Lots
        </h3>
        <mat-tab-group class="flex"
                       [(selectedIndex)]="selectedTabIndex"
                       [cbTabRoute]="RELEASE_LOTS_ROUTE"
                       mat-stretch-tabs="false"
                       style="width: 100%">
            <mat-tab label="Summary"
                     cbTabRouteId="summary">
                <ng-template matTabContent>
                    <cb-manage-project-release-stage-lots-summary-tab *cbWaitFor="mappedItem && projectReleases"
                                                                      [mappedItem]="mappedItem"
                                                                      [projectReleases]="projectReleases">
                    </cb-manage-project-release-stage-lots-summary-tab>
                </ng-template>
            </mat-tab>
            <mat-tab label="Design Summary"
                     cbTabRouteId="design-summary">
                <ng-template matTabContent>
                    <cb-manage-project-release-stage-lots-design-tab *cbWaitFor="mappedItem"
                                                                     [mappedItem]="mappedItem"
                                                                     [projectReleases]="projectReleases">
                    </cb-manage-project-release-stage-lots-design-tab>
                </ng-template>
            </mat-tab>
            <!--We decided to hide it because it looks like nobody uses it.-->
            <!--<mat-tab label="Cost Summary"
                     cbTabRouteId="cost-summary">
                <ng-template matTabContent>
                    <cb-release-lots-financials *cbWaitFor="mappedItem"
                                                [releaseId]="mappedItem.id">
                    </cb-release-lots-financials>
                </ng-template>
            </mat-tab>-->
        </mat-tab-group>
    </mat-card-content>
</mat-card>
