import { MatSelectionList } from '@angular/material/list';
import { Directive, Input, ViewContainerRef, OnDestroy } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: 'mat-selection-list[cbRequireSelectionListCheckedValidator][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: RequireSelectionListCheckedValidatorDirective, multi: true }
    ]
})
export class RequireSelectionListCheckedValidatorDirective implements OnDestroy {

    @Input() public cbRequireSelectionListCheckedValidator: boolean;

    public matSelectionList: MatSelectionList;

    public subscriptions = new Subscription();

    constructor(public viewContainerRef: ViewContainerRef) { }

    public validate(control: AbstractControl): ValidationErrors | null {
        if (!this.cbRequireSelectionListCheckedValidator) {
            return null;
        }

        this.loadMatSelectionList(control);

        let hasSelection = false;

        try {
            hasSelection = this.matSelectionList.selectedOptions != null;
        } catch (_) {
            // swallow this error - don't care if it fails
        }

        if (!this.matSelectionList || hasSelection) {
            return null;
        }

        return { selectionRequired: 'cb-selection-list requires an option to be selected' };
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private loadMatSelectionList(control: AbstractControl): void {
        if (this.matSelectionList) {
            return;
        }
        this.matSelectionList = this.viewContainerRef.injector.get(MatSelectionList);

        this.subscriptions.add(
            this.matSelectionList.selectionChange.subscribe(x => {
                control.updateValueAndValidity();
            })
        );
    }
}
