import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbFormsModule } from '@app/shared/components/forms/forms.module';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { CbInfoMessageModule, CbLoadingSpinnerModule, CbTableModule } from 'cb-hub-lib';
import { ProductCentreConfigurationComponent } from '@app/views/settings/pages/product-centre-configuration/product-centre-configuration.component';
import { ProductCentreFilterDialogComponent } from '@app/views/settings/pages/product-centre-configuration/product-centre-filter-dialog/product-centre-filter-dialog.component';
import { CbClickModule } from '@app/shared/directives/click/click.module';
import { ProductCentreFilterAttributeDialogComponent } from '@app/views/settings/pages/product-centre-configuration/product-centre-filter-attribute-dialog/product-centre-filter-attribute-dialog.component';
import { DragulaModule } from 'ng2-dragula';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
    declarations: [
        ProductCentreConfigurationComponent,
        ProductCentreFilterDialogComponent,
        ProductCentreFilterAttributeDialogComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        CbDialogModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        CbInfoMessageModule,
        CbLoadingSpinnerModule,
        CbFormsModule,
        CbDatePipeModule,
        CbTableModule,
        CbClickModule,
        CbInfoMessageModule,
        DragulaModule,
        MatSlideToggleModule,
    ],
    exports: [
        ProductCentreConfigurationComponent
    ]
})
export class ProductCentreConfigurationModule { }
