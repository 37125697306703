<cb-dialog>
    <form class="flex-col flex"
          #specTemplateForm="ngForm">
        <cb-select label="Template Section"
                   name="specGroupId"
                   [required]="true"
                   [options]="houseAreas"
                   [(ngModel)]="data.item.specGroupId"></cb-select>
        <cb-select label="Project"
                   name="colourItem"
                   [required]="true"
                   [options]="colourItems"
                   [(ngModel)]="data.item.colourItem.id"></cb-select>
        <cb-input label="Colour"
                  name="name"
                  [(ngModel)]="data.item.name"></cb-input>
        <cb-checkbox label="Include in Flyer / Create Schedule"
                     name="showInClientSpecification"
                     class="cb-margin-top-2x"
                     [(ngModel)]="data.item.colourItem.showInClientSpecification"></cb-checkbox>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="saveClicked()"
                [disabled]="!specTemplateForm.dirty || !specTemplateForm.valid">Save</button>
        <button mat-raised-button
                (click)="cancelClicked()">Cancel</button>
    </div>
</cb-dialog>
