<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <div mat-card-avatar
             class="cb-card-avatar-icon"
             [matTooltip]="tooltipMessage.value">
            <mat-icon [ngClass]="cardIconClass.value">{{cardIcon.value}}</mat-icon>
        </div>
        <mat-card-title [ngClass]="cardIconClass.value">{{stepNumber}}. Build Contract</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex-col flex">
        <cb-display-value-new label="Contract Downloaded Date"
                              [value]="clientSale.documentInfo.buildContractDownloadDate | date"></cb-display-value-new>
        <cb-display-value-new label="Contract Uploaded Date"
                              [value]="uploadLabels.value.buildContract"></cb-display-value-new>
        <div *ngIf="!clientSale?.stepCompletion?.contractPricing"
             class="cb-error cb-margin-bottom-2x">
            <mat-icon class="cb-error"
                      style="vertical-align: middle;">error</mat-icon>
            Pricing Details need to be set prior to being able to download the Build Contract
        </div>
        <div *ngIf="!clientSale?.isLeadClientDetailsComplete"
             class="cb-error cb-margin-bottom-2x">
            <mat-icon class="cb-error"
                      style="vertical-align: middle;">error</mat-icon>
            Please complete the Lead Client Details prior to being able to download the Build Contract
        </div>
        <div *ngIf="getBuildContractAlert()"
             class="cb-error cb-margin-bottom-2x">
            <mat-icon class="cb-error"
                      style="vertical-align: middle;">error</mat-icon>
            {{getBuildContractAlert()}}
        </div>
        <div *ngIf="getCreateScheduleAlert()"
             class="cb-error cb-margin-bottom-2x">
            <mat-icon class="cb-error"
                      style="vertical-align: middle;">error</mat-icon>
            {{getCreateScheduleAlert()}}
        </div>
        <div *ngIf="!isComplete && buildContractReviewed && createScheduleReviewed"
             class="cb-error cb-margin-bottom-2x">
            <mat-icon class="cb-error"
                      style="vertical-align: middle;">error</mat-icon>
            There are outstanding documents requiring upload or review
        </div>
    </mat-card-content>
    <mat-card-actions class="cb-margin mat-card-actions">
        <button class="cb-margin full-width"
                mat-stroked-button
                [disabled]="!clientSale?.canDownloadBuildContract"
                color="accent"
                (click)="downloadBuildContract()">
            <mat-icon>file_download</mat-icon>
            Download Build Contract
        </button>
        <button class="cb-margin-bottom full-width"
                [disabled]="!clientSale?.canUploadBuildContract || !clientSaleDocuments?.documents?.buildContract?.entity?.canUpload"
                mat-stroked-button
                (click)="clientSaleDocuments?.documents?.buildContract?.openUploadDialog()"
                color="accent">
            <mat-icon>file_upload</mat-icon>
            Upload Signed Build Contract
        </button>
        <button class="cb-margin-bottom full-width"
                mat-stroked-button
                [disabled]="!clientSale?.canDownloadClientSpecAndSpInfo || (isLotSpecScheduleTemplate() && clientSale.isUnconditional)"
                color="accent"
                (click)="downloadCreateSchedule()">
            <mat-icon>file_download</mat-icon>
            Download Create Schedule
        </button>
        <button mat-stroked-button class="full-width"
                [disabled]="!clientSaleDocuments?.documents?.clientSpec?.entity?.canUpload || (isLotSpecScheduleTemplate() && clientSale.isUnconditional)"
                (click)="clientSaleDocuments?.documents?.clientSpec?.openUploadDialog()"
                color="accent">
            <mat-icon>file_upload</mat-icon>
            Upload Signed Create Schedule
        </button>
    </mat-card-actions>
</mat-card>
