<cb-dialog class="flex-col"
           [dialogHeading]="isEdit() ? 'Edit Supply Type' : 'Add Supply Type'"
           (cancelDialog)="cancel()">
    <form #supplyTypeEditForm="ngForm">
        <cb-input label="Supply Type"
                  type="text"
                  name="supplyType"
                  [(ngModel)]="mappedItem.label"
                  [readonly]="isView()"
                  [required]="true"
                  [maxlength]="100"></cb-input>
        <cb-select *ngIf="mappedItem?.mappedChildren?.length < 1"
                   label="Parent"
                   name="parentSelect"
                   [(ngModel)]="mappedItem.parentId"
                   [readonly]="isView()"
                   [options]="availableSupplyTypes"></cb-select>
    </form>
    <form #supplyTypeTableForm="ngForm">
        <cb-table *ngIf="mappedItem.parentId"
                  class="cb-margin-top"
                  label="Compliance Registrations"
                  [stringColumns]="columns"
                  [loaded]="loaded">
            <tbody cbTableBody>
            <tr *ngFor="let compReg of mappedItem.requiredComplianceRegistrations">
                <td>
                    {{compReg.label}}
                </td>
                <td>
                    <button mat-icon-button
                            (click)="removeCompReg(compReg.id)">
                        <mat-icon>
                            remove_circle
                        </mat-icon>
                    </button>
                </td>
            </tr>
            <tr>
                <td>
                    <cb-autocomplete-scroller class="flex"
                                              *cbWaitFor="!autocompleteLoading"
                                              label="Compliance Registration"
                                              name="selectComplianceReg"
                                              [(ngModel)]="selectedCompReg"
                                              [requireMatch]="true"
                                              [matchProps]="['id']"
                                              [querySearch]="querySearch"></cb-autocomplete-scroller>
                </td>
                <td>
                    <button mat-icon-button
                            (click)="addCompReg()"
                            [disabled]="disableAddCompReg()">
                        <mat-icon>
                            add
                        </mat-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </cb-table>
    </form>
    <form #supplyTypeEditForm2="ngForm"
          class="cb-checkbox-list flex-col">
        <cb-checkbox *ngIf="!activeChildren || isAdd()"
                     label="Active"
                     name="isActive"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="!(supplyTypeEditForm.valid && supplyTypeEditForm2.valid && (complianceRegsChanged || supplyTypeEditForm.dirty || supplyTypeEditForm2.dirty))"
                (cbClick)="save()">
            {{isEdit() ? 'Save' : 'Add'}}
        </button>
        <button mat-raised-button
                (cbClick)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
