import { AfterViewInit, Component, OnDestroy, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PermissionsPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IBuildActivitySearch } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { BuildActivitiesLogicService } from '@app/logic/build-activities/build-activities-logic.service';
import { IBuildActivityMappedItem } from '@app/logic/build-activities/interfaces/i.build-activity.mapped';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { BaseSearchViewDirective } from '@app/shared/base-views/base-search-view.directive';
import { FormMode } from '@app/shared/enums/form';
import { USER_CACHE_AREA } from 'cb-hub-lib';
import { Observable } from 'rxjs';
import { IBuildActivityDto, ISkinnyBuildActivityDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-build-activities',
    templateUrl: './build-activities.component.html',
    styleUrls: ['./build-activities.component.scss'],
    providers: [BuildActivitiesLogicService, UsersLogicService]
})
export class BuildActivitiesComponent extends BaseSearchViewDirective<IBuildActivitySearch> implements AfterViewInit, OnDestroy {
    public formMode: FormMode;
    public isView: () => boolean;
    public isEdit: () => boolean;
    public searchResults: ISkinnyBuildActivityDto[] = [];
    public selectedActivity: ISkinnyBuildActivityDto;
    public regions: IBusinessEntityDto[];
    public mappedItem: IBuildActivityMappedItem;
    public get buildActivityForm(): NgForm {
        return this.buildActivityFormParent.first;
    }
    public sideBarBuildActivityCode: string;
    public sideBarBuildActivityName: string;
    @ViewChild('infiniteScrollContainerBuildActivities', { static: true }) public infiniteScrollContainer: { nativeElement: { scrollHeight: number } };
    @ViewChildren('buildActivityForm') public buildActivityFormParent;
    public readonly displayedColumns: string[] = [
        'code',
        'activity',
        'version',
        'active',
        'compliance',
        'showDraftActivityToSupplier',
        'retained',
        'actions'
    ];

    constructor(
        public readonly navigationService: NavigationService,
        public readonly toastService: ToastService,
        public readonly permissionsPermissions: PermissionsPermissions,
        public readonly dialog: MatDialog,
        private readonly businessEntitiesLogicService: BusinessEntitiesLogicService,
        private readonly buildActivitiesLogicService: BuildActivitiesLogicService,
        public readonly userCacheService: UserCacheService,
    ) {
        super(
            buildActivitiesLogicService,
            toastService,
            userCacheService,
            USER_CACHE_AREA.BuildActivitySearch,
        );
        this.formMode = FormMode.View;
        this.businessEntitiesLogicService.$getList().subOnce(x => {
            this.regions = x.filter(y => y.isActive);
        });
    }

    public ngAfterViewInit(): void {
        this.loadSearchParams();
    }

    public formDirty = (): boolean => {
        if (!this.buildActivityForm) {
            return false;
        } else {
            return this.buildActivityForm.dirty;
        }
    };

    public formValid = (): boolean => {
        if (!this.buildActivityForm) {
            return false;
        } else {
            return this.buildActivityForm.valid;
        }
    };

    protected getSearchParams(): IBuildActivitySearch {
        return this.userCacheItem.copyData();
    }

    public goBack(): void {
        this.mappedItem = undefined;
        this.buildActivityForm.form.reset();
        this.formMode = FormMode.View;
    }

    public newActivityClicked(): void {
        this.formMode = FormMode.Edit;
        this.mappedItem = this.buildActivitiesLogicService.$createMappedItem();
        this.sideBarBuildActivityCode = '';
        this.sideBarBuildActivityName = '';
    }

    public generateReport(): void {
        if (this.mappedItem?.id) {
            this.buildActivitiesLogicService.generateActivityReport(this.mappedItem.id).subOnce({ next: undefined, error: this.handleError });
        } else {
            this.buildActivitiesLogicService.generateReport().subOnce({ next: undefined, error: this.handleError });
        }
    }

    protected handleError = (err): void => {
        console.error(err);
    };

    public saveMethod(): Observable<IBuildActivityDto> {
        this.mappedItem.tradeTypes = this.mappedItem?.tradeTypesList?.map(tt => tt.id) ?? [];
        this.mappedItem.supplyTypes = this.mappedItem?.supplyTypesList?.map(st => st.id) ?? [];
        this.mappedItem.labourRates = this.mappedItem?.labourRatesList?.map(rate => rate.id) ?? [];
        return super.saveMethod();
    }

    public handleSaveSuccess = (result: IBuildActivityDto): void => {
        this.searchResults.forEach(skinnyBuildActivityDto => {
            if (skinnyBuildActivityDto.id === result.id) {
                Object.assign(skinnyBuildActivityDto, this.mapFullBuildActivityToSkinny(result));
            }
        });
        this.buildActivityForm.form.markAsPristine();
        this.sideBarBuildActivityCode = result.code;
        this.sideBarBuildActivityName = result.name;
    };

    public mapFullBuildActivityToSkinny(buildActivity: IBuildActivityDto): ISkinnyBuildActivityDto {
        return {
            id: buildActivity.id,
            activityDurationDays: buildActivity.activityDurationDays,
            canPerformBeforeQsConsentTakeOffCheck: buildActivity.canPerformBeforeQsConsentTakeOffCheck,
            canPerformPriorToBuildingConsent: buildActivity.canPerformPriorToBuildingConsent,
            code: buildActivity.code,
            excludeFromPracticalCompletion: buildActivity.excludeFromPracticalCompletion,
            hasCompliance: buildActivity.hasCompliance,
            isActive: buildActivity.isActive,
            name: buildActivity.name,
            retain: buildActivity.retain,
            shouldExcludeRequiredComplianceForDuplicates: buildActivity.shouldExcludeRequiredComplianceForDuplicates,
            version: buildActivity.version,
            showDraftActivityToSupplier: buildActivity.showDraftActivityToSupplier
        } as ISkinnyBuildActivityDto;
    }

    public editClicked(buildActivity: ISkinnyBuildActivityDto): void {
        this.selectedActivity = buildActivity;

        this.buildActivitiesLogicService
            .$getItem(this.selectedActivity.id)
            .subOnce(buildActivity => {
                this.mappedItem = this.buildActivitiesLogicService
                    .$createMappedItem(buildActivity);
                this.sideBarBuildActivityCode = buildActivity.code;
                this.sideBarBuildActivityName = buildActivity.name;
            });
        this.formMode = FormMode.Edit;
    }
}
