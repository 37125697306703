import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LotBuildProgrammeEventService} from '@app/views/lot/build/services/lot-build-programme-event.service';
import {ILotDto} from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    lotDto: ILotDto;
}

@Component({
    templateUrl: './fullscreen-build-programme.component.html',
    styleUrls: ['./fullscreen-build-programme.component.scss']
})
export class FullscreenBuildProgrammeComponent {
    public static readonly MIN_WIDTH = '100%';

    constructor(
        public readonly dialogRef: MatDialogRef<FullscreenBuildProgrammeComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(LotBuildProgrammeEventService) public readonly lotBuildProgrammeEvents: LotBuildProgrammeEventService,
    ) { }


}
