import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IVariationLogicService } from '@app/logic/variation/interfaces/i.variation.logic-service';
import { IVariationMappedItem } from '@app/logic/variation/interfaces/i.variation.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IClassicUserDocumentDto, IVariationDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { isNullOrWhiteSpace } from 'projects/cb-hub-lib/src/lib/utils/string.util';
import { Observable } from 'rxjs';

interface IData {
    mappedItem: IVariationMappedItem;
}


@Component({
    selector: 'cb-lot-variation-details-dialog',
    templateUrl: './lot-variation-details-dialog.component.html',
    styleUrls: ['./lot-variation-details-dialog.component.scss']
})
export class LotVariationDetailsDialogComponent extends BaseDialogFormViewDirective<IVariationDto, IVariationMappedItem, IVariationLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '500px';
    public selectedVariationFromUser: IClassicUserDocumentDto;

    constructor(
        public readonly dialogRef: MatDialogRef<LotVariationDetailsDialogComponent>,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.loadSelectedVariationFromUser();
    }



    private loadSelectedVariationFromUser(): void {
        if (!isNullOrWhiteSpace(this.mappedItem.variationRaisedByUserId)) {
            this.selectedVariationFromUser = {
                id: this.mappedItem.variationRaisedByUserId,
                name: this.mappedItem.variationRaisedByUser
            } as IClassicUserDocumentDto;
        }
    }

    public saveMethod(): Observable<IVariationDto> {
        this.mappedItem.variationRaisedByUserId = this.selectedVariationFromUser.id;
        this.mappedItem.variationRaisedByUser = this.selectedVariationFromUser.name;

        return this.mappedItem.$save();
    }

}
