<cb-dialog [dialogHeading]="isEdit() ? 'Edit Payment Template' : 'New Payment Template'">
    <form #paymentTemplateForm="ngForm">
        <cb-input label="Template Name"
                  name="templateName"
                  [(ngModel)]="mappedItem.label"
                  [required]="true"
                  [maxlength]="100"></cb-input>
        <cb-checkbox label="Active"
                     name="active"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
        <cb-table label="Stages"
                  [stringColumns]="stageColumns"
                  [loaded]="true">
            <tbody cbTableBody
                   [dragula]="STAGES_DND"
                   [(dragulaModel)]="dragulaModel">
                <tr *ngFor="let stage of mappedItem.stages; let $index = index">
                    <td>
                        <span *ngIf="stage?.buildStage?.label != null">
                            {{stage.buildStage.label}}
                        </span>
                        {{BUILD_STAGE_EVENT_ENUM[stage.trigger]}}
                    </td>
                    <cb-td-text [value]="stage.paymentAmount"></cb-td-text>
                    <cb-td-text [value]="stage.amountType === 1 ? '%' : '$'"></cb-td-text>
                    <td cbTableStickyCol="end"
                        cbTableShrinkCol>
                        <button class="cb-margin-right"
                                mat-icon-button
                                (click)="editPaymentStage(stage)">
                            <mat-icon>
                                edit
                            </mat-icon>
                        </button>
                        <button mat-icon-button
                                (click)="removePaymentStage($index)">
                            <mat-icon>
                                remove_circle
                            </mat-icon>
                        </button>
                    </td>
                </tr>
                <tr class="cb-table-footer-row">
                    <td colspan="3"></td>
                    <td cbTableStickyCol="end"
                        cbTableShrinkCol>
                        <button mat-icon-button
                                (click)="addPaymentStage()">
                            <mat-icon>
                                add
                            </mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </cb-table>
        <cb-table class="cb-margin-top-2x"
                  label="Build Types"
                  [objectColumns]="buildTypeColumns"
                  [loaded]="true">
            <tr cbTableRow
                *ngFor="let buildType of mappedItem.buildTypes; let $index = index">
                <cb-td-enum [enum]="LOT_CONTRACT_TYPE_ENUM"
                            [value]="buildType.lotContractType"></cb-td-enum>
                <cb-td-text [value]="buildType.code"></cb-td-text>
                <cb-td-text [value]="buildType.label"></cb-td-text>
                <cb-td-checkbox [value]="buildType.isActive"></cb-td-checkbox>
                <cb-td-checkbox [value]="buildType.isDefault"></cb-td-checkbox>
                <td cbTableStickyCol="end"
                    cbTableShrinkCol>
                    <button class="cb-margin-right"
                            mat-icon-button
                            (click)="editBuildType(buildType, $index)">
                        <mat-icon>
                            edit
                        </mat-icon>
                    </button>
                    <button mat-icon-button
                            (click)="removeBuildType($index)">
                        <mat-icon>
                            remove_circle
                        </mat-icon>
                    </button>
                </td>
            </tr>
            <tr cbTableRow>
                <td colspan="5"></td>
                <td cbTableStickyCol="end"
                    cbTableShrinkCol>
                    <button mat-icon-button
                            (click)="addBuildType()">
                        <mat-icon>
                            add
                        </mat-icon>
                    </button>
                </td>
            </tr>
        </cb-table>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="paymentTemplateForm.invalid || (paymentTemplateForm.pristine && !dataChanged)"
                (cbClick)="save()"
                [clickLimit]="1">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
