import { IDocumentEntityMappedItem } from '@app/logic/documents/interfaces/i.document.mapped';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentService } from '../../services/document.service';
import { DOCUMENT_REVIEW_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-document-review-dialog',
    templateUrl: './document-review-dialog.html',
    styleUrls: ['./document-review-dialog.scss']
})
export class DocumentReviewDialogComponent {
    public static readonly MIN_WIDTH = '400px';
    public readonly DOCUMENT_REVIEW_STATUS_ENUM = DOCUMENT_REVIEW_STATUS_ENUM;

    public get approvalStatus() {
        return this.data.document.approvalStatuses[0];
    }

    constructor(
        public readonly dialogRef: MatDialogRef<DocumentReviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDocumentEntityMappedItem,
        private readonly documentService: DocumentService,
    ) { }

    public isRejecting(): boolean {
        return this.data.document.approvalStatuses[0].documentReviewStatus === DOCUMENT_REVIEW_STATUS_ENUM.Rejected;
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public onSubmit(): void {
        this.data.reviewDocument()
            .subOnce((response) => {
                this.dialogRef.close(response);
                this.documentService.documentReviewed.next(this.data);
            });
    }

}
