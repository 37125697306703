import { Component } from '@angular/core';
import {BaseSimpleListViewDirective} from "@app/shared/base-views/base-simple-list-view.directive";
import {IBuildMilestoneTemplateDto} from "@classictechsolutions/hubapi-transpiled-enums";
import {CbDialogService} from "@app/shared/components/dialog/cb-dialog.service";
import {PermissionsPermissions} from "@app/core/permissions";
import {BuildMilestoneTemplateLogicService, BuildMilestoneTemplateMappedItem} from "@app/logic/build-milestone-templates";
import {BuildMilestoneTemplateDialogComponent} from "@app/views/settings/pages/build-milestone-templates/build-milestone-template-dialog/build-milestone-template-dialog.component";

@Component({
  selector: 'cb-build-milestone-templates',
  templateUrl: './build-milestone-templates.component.html',
  styleUrls: ['./build-milestone-templates.component.scss']
})
export class BuildMilestoneTemplatesComponent  extends BaseSimpleListViewDirective<IBuildMilestoneTemplateDto, BuildMilestoneTemplateLogicService> {
    public readonly displayedColumns: string[] = [
        'code',
        'name',
        'formula',
        'isActive',
        'actions'
    ];

    constructor(
        public readonly cbDialog: CbDialogService,
        protected readonly buildMilestoneTemplateLogicService: BuildMilestoneTemplateLogicService,
        public readonly permissionsPermissions: PermissionsPermissions,
    ) {
        super(
            cbDialog,
            buildMilestoneTemplateLogicService,
            BuildMilestoneTemplateDialogComponent,
            'Build Milestone Templates',
            BuildMilestoneTemplateMappedItem,
            permissionsPermissions
        );
    }

    openFormulaDialog(formula: IBuildMilestoneTemplateDto): void {
      this.cbDialog.simpleMessageDialog(formula.formula, `${formula.name} - Formula Preview`)
    }



    public processBuildMilestones(): void {
        this.buildMilestoneTemplateLogicService.updateBuildMilestones().subOnce()
    }

    public deleteItem(item: IBuildMilestoneTemplateDto): void {
        this.deleteItemClicked(item, { heading: 'Build Milestone Template', message: `Are you sure you want to delete ${item.name} Build Milestone Template?` });
    }

}
