<cb-dialog dialogHeading="{{isEdit() ? 'Edit' : 'Create'}} Item">
    <form #manageProductForm="ngForm">
        <div class="flex-row flex">
            <div class="flex-col flex">
                <h4 class="mat-headline-6 start-center flex"
                    [ngClass]="mappedItem.isActive ? 'cb-primary' : 'cb-warn'"
                    *ngIf="isEdit()">
                    <mat-icon layout-padding
                              [ngClass]="mappedItem.isActive ? 'cb-primary' : 'cb-warn'">
                        label
                    </mat-icon>
                    {{PRODUCT_TYPE_ENUM[mappedItem.productType]}}
                    {{mappedItem.isActive ? '(Active)' : '(Inactive)'}}
                </h4>
                <h4 class="cb-title cb-primary start-center flex"
                    *ngIf="isAdd()">
                    <mat-icon class="cb-primary">
                        label
                    </mat-icon>
                    {{PRODUCT_TYPE_ENUM[mappedItem.productType]}}
                    <span class="cb-primary">&nbsp;(Active)</span>
                </h4>
                <cb-input class="flex"
                          type="text"
                          label="Product ID"
                          [(ngModel)]="mappedItem.code"
                          [readonly]="true"
                          *ngIf="isEdit()"
                          name="code">
                </cb-input>
                <cb-product-autocomplete-scroller class="cb-margin-top"
                                                  #autocompleteControl
                                                  label="Composite Item"
                                                  name="addToCompositeItem"
                                                  [displayCategories]="true"
                                                  [productType]="4"
                                                  [requireMatch]="true"
                                                  [matchProps]="['id']"
                                                  (searchTextChanged)="clearProduct()"
                                                  [(ngModel)]="selectedProductModel"
                                                  (optionSelected)="productSelected($event)"
                                                  *ngIf="isProductOrLabourItem(mappedItem) && !mappedItem.isCompositeItem">
                </cb-product-autocomplete-scroller>
                <cb-input class="flex"
                          type="text"
                          label="Product Name"
                          [maxlength]="256"
                          [(ngModel)]="mappedItem.name"
                          name="name"
                          [required]="true">
                </cb-input>
                <cb-select class="flex"
                           label="UOM"
                           [required]="true"
                           name="uomControl"
                           [options]="uom"
                           [(ngModel)]="mappedItem.uom"
                           [disabled]="mappedItem.productType === PRODUCT_TYPE_ENUM.Bundle">
                </cb-select>
                <cb-trade-type-autocomplete-scroller *ngIf="mappedItem.productType === PRODUCT_TYPE_ENUM.Labour"
                                                     class="cb-margin-top flex"
                                                     label="Select Trade Type"
                                                     name="tradeType"
                                                     [required]="true"
                                                     [(ngModel)]="selectedTradeTypeModel"
                                                     (optionSelected)="tradeTypeSelected($event)"
                                                     [ignoreDirty]="false"
                                                     [disabled]="tradeTypeSelectDisabled.value">
                </cb-trade-type-autocomplete-scroller>
                <div class="start-start flex-row"
                     *ngIf="mappedItem.productType === PRODUCT_TYPE_ENUM.Bundle">
                    <cb-select class="flex"
                               label="Bundle Template"
                               name="selectedTemplateId"
                               [options]="bundleTemplates"
                               [(ngModel)]="mappedItem.bundleTemplateId"
                               style="margin-right: 12px; min-width:120px"
                               (change)="bundleTemplateChanged($event)">
                    </cb-select>
                    <cb-input class="flex"
                              type="text"
                              label="Current Bundle Template"
                              [(ngModel)]="mappedItem.bundleTemplateName"
                              name="bundleTemplateName"
                              [readonly]="true">
                    </cb-input>
                </div>
                <cb-product-category-autocomplete-scroller class="cb-margin-top"
                                                           #autocompleteControl
                                                           label="Categories"
                                                           name="categories"
                                                           [required]="true"
                                                           [(ngModel)]="selectedProductCategoryModel"
                                                           [requireMatch]="true"
                                                           [matchProps]="['id']"
                                                           (optionSelected)="productCategorySelected($event)">
                </cb-product-category-autocomplete-scroller>
                <cb-input class="flex"
                          type="text"
                          label="Notes"
                          [maxlength]="1024"
                          [(ngModel)]="mappedItem.notes"
                          name="notes">
                </cb-input>
                <cb-checkbox class="flex"
                             name="isCompositeItem"
                             label="Composite Item"
                             [(ngModel)]="mappedItem.isCompositeItem"
                             *ngIf="isProductOrLabourItem(mappedItem) && !mappedItem.compositeItemId"
                             [readonly]="isEdit()"
                             [slim]="true">
                </cb-checkbox>
                <cb-checkbox class="flex"
                             name="shouldMaintainRatesForThisProduct"
                             label="Rates are Maintained for this Item"
                             [(ngModel)]="mappedItem.shouldMaintainRatesForThisProduct"
                             *ngIf="isProductOrLabourItem(mappedItem) && !mappedItem.isCompositeItem"
                             [slim]="true">
                </cb-checkbox>
                <cb-checkbox name="showInClientSpecs"
                             label="Include in Flyer / Create Schedule"
                             [(ngModel)]="mappedItem.showInClientSpecs"
                             *ngIf="!mappedItem.isCompositeItem"
                             [slim]="true">
                </cb-checkbox>
                <cb-checkbox name="showInColourYourDreams"
                             label="Include in Colour Schedule"
                             [(ngModel)]="mappedItem.showInColourYourDreams"
                             *ngIf="!mappedItem.isCompositeItem"
                             [slim]="true">
                </cb-checkbox>
                <cb-checkbox name="manualColourEntryRequired"
                             label="Manual Colour Entry Required"
                             [(ngModel)]="mappedItem.manualColourEntryRequired"
                             [disabled]="inheritedColourAttribute"
                             *ngIf="!mappedItem.isCompositeItem"
                             [slim]="true">
                </cb-checkbox>
                <cb-checkbox name="isStandardProduct"
                             label="Standard Product"
                             [(ngModel)]="mappedItem.isStandardProduct"
                             [slim]="true">
                </cb-checkbox>
                <cb-text-area label="Text for Specification"
                              name="clientSpecDescription"
                              [(ngModel)]="mappedItem.clientSpecDescription"
                              [maxlength]="1500">
                </cb-text-area>
            </div>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="manageProductForm.pristine || manageProductForm.invalid"
                (cbClick)="save()"
                [clickLimit]="1">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
