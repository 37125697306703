import { NgModule } from '@angular/core';
import { BuildActivitiesSettingsModule } from './pages/build-activities/build-activities.module';
import { GlobalSettingsSettingsModule } from './pages/global-settings/global-settings.module';
import { CouncilsSettingsModule } from './pages/councils/councils.module';
import { SpecGroupsSettingsModule } from './pages/spec-groups/spec-groups.module';
import { AddressRegionsSettingsModule } from './pages/address-regions/address-regions.module';
import { BuildStagesSettingsModule } from './pages/build-stages/build-stages.module';
import { PricingComplexitySettingsModule } from './pages/pricing-complexity/pricing-complexity.module';
import { TeamsSettingsModule } from './pages/teams/teams.module';
import { TagsSettingsModule } from './pages/tags/tags.module';
import { TaskOutcomesSettingsModule } from './pages/task-outcomes/components/task-outcomes.module';
import { PaymentFrequenciesSettingsModule } from './pages/payment-frequencies/components/payment-frequencies.module';
import { StandardPlansModule } from '../standard-plans/standard-plans.module';
import { ComplianceRegistrationSettingsModule } from './pages/compliance-registration/compliance-registration.module';
import { CbStylesSettingModule } from '@app/views/settings/pages/standard-plans/styles/styles.module';
import { CbStandardPlansSettingModule } from '@app/views/settings/pages/standard-plans/standard-plans.module';
import { BankAccountsSettingsModule } from './pages/bank-accounts/bank-accounts.module';
import { BundleTemplatesSettingsModule } from './pages/bundle-templates/bundle-templates.module';
import { DocumentTypesSettingsModule } from './pages/document-types/document-types.module';
import { SearchIndexesSettingsModule } from './pages/search-indexes/search-indexes.module';
import { BusinessAccountsSettingsModule } from './pages/business-accounts/business-accounts.module';
import { DesignWorkflowSettingsModule } from './pages/design-workflow/design-workflow.module';
import { BusinessEntitiesSettingsModule } from './pages/business-entities/business-entities.module';
import { TradeTypesModule } from './pages/trade-types/trade-types.module';
import { PaymentTemplatesModule } from './pages/payment-templates/payment-templates.module';
import { ColourItemsSettingsModule } from './pages/colour-items/colour-items.module';
import { IntegrationLogsModule } from './pages/integration-logs/integration-logs.module';
import { SystemLogsModule } from './pages/system-logs/system-logs.module';
import { SlotTagsSettingsModule } from './pages/slot-tags/slot-tags.module';
import { SlotsSettingsModule } from './pages/slots/slots.module';
import { FeatureTogglesModule } from './pages/feature-toggles/feature-toggles.module';
import { MaintenanceSettingsModule } from './pages/maintenance-settings/maintenance-settings.module';
import { BuildTypesSettingsModule } from './pages/build-types/build-types.module';
import { NonWorkingDaysSettingsModule } from './pages/non-working-days/non-working-days.module';
import { LocationsSettingsModule } from './pages/locations/locations.module';
import { DocumentTemplatesModule } from './pages/document-templates/document-templates.module';
import { NotificationTemplatesModule } from './pages/notification-templates/notification-templates.module';
import { SettingsComponent } from './settings.component';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { ExternalSystemFieldsModule } from './pages/external-system-fields/external-system-fields.module';
import { DocumentGroupsModule } from './pages/document-groups/document-groups.module';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormsModule } from '@angular/forms';
import { SlotExternalSystemFieldValueComponentModule } from './pages/slot-external-system-field-value/slot-external-system-field-value.module';
import { DocumentNotificationsModule } from './pages/document-notifications/document-notifications.module';
import { PriceOptionsComponentModule } from '@app/views/settings/pages/price-options/price-options.module';
import { ConfirmDeactivateGuard } from '@app/shared/validators/can-component-deactivate';
import { PriceOptionsComponent } from '@app/views/settings/pages/price-options/price-options.component';
import { AddressRegionsComponent } from '@app/views/settings/pages/address-regions/address-regions.component';
import { BankAccountsComponent } from '@app/views/settings/pages/bank-accounts/bank-accounts.component';
import { CustomReuseStrategy } from '@app/views/settings/custom-reuse-strategy';
import { TabNames } from './tabNames';
import { BuildActivitiesComponent } from '@app/views/settings/pages/build-activities/build-activities.component';
import { BuildStagesComponent } from '@app/views/settings/pages/build-stages/build-stages.component';
import { BundleTemplatesComponent } from '@app/views/settings/pages/bundle-templates/bundle-templates.component';
import { BusinessEntitiesComponent } from '@app/views/settings/pages/business-entities/business-entities.component';
import { ColourItemsComponent } from '@app/views/settings/pages/colour-items/colour-items.component';
import { ComplianceRegistrationComponent } from '@app/views/settings/pages/compliance-registration/compliance-registration.component';
import { CouncilsComponent } from '@app/views/settings/pages/councils/councils.component';
import { DesignWorkflowComponent } from '@app/views/settings/pages/design-workflow/design-workflow/design-workflow.component';
import { DocumentTypesComponent } from '@app/views/settings/pages/document-types/document-types.component';
import { LocationsComponent } from '@app/views/settings/pages/locations/locations.component';
import { BusinessAccountsComponent } from '@app/views/settings/pages/business-accounts/business-accounts.component';
import { BuildTypesComponent } from '@app/views/settings/pages/build-types/build-types.component';
import { DocumentGroupsComponent } from '@app/views/settings/pages/document-groups/document-groups.component';
import { DocumentNotificationsComponent } from '@app/views/settings/pages/document-notifications/document-notifications.component';
import { DocumentTemplatesComponent } from '@app/views/settings/pages/document-templates/document-templates.component';
import { ExternalSystemFieldsComponent } from '@app/views/settings/pages/external-system-fields/external-system-fields.component';
import { FeatureToggleViewComponent } from '@app/views/settings/pages/feature-toggles/feature-toggle-view/feature-toggle-view.component';
import { SpecGroupsComponent } from '@app/views/settings/pages/spec-groups/spec-groups.component';
import { IntegrationLogsComponent } from '@app/views/settings/pages/integration-logs/integration-logs/integration-logs.component';
import { MaintenanceSettingsComponent } from '@app/views/settings/pages/maintenance-settings/maintenance-settings.component';
import { NonWorkingDaysComponent } from '@app/views/settings/pages/non-working-days/non-working-days.component';
import { NotificationTemplatesComponent } from '@app/views/settings/pages/notification-templates/notification-templates.component';
import { PaymentFrequenciesComponent } from '@app/views/settings/pages/payment-frequencies/components/payment-frequencies.component';
import { PaymentTemplatesComponent } from '@app/views/settings/pages/payment-templates/payment-templates/payment-templates.component';
import { PricingComplexityComponent } from '@app/views/settings/pages/pricing-complexity/pricing-complexity.component';
import { SearchIndexesComponent } from '@app/views/settings/pages/search-indexes/search-indexes.component';
import {
    SlotExternalSystemFieldValueComponent
} from '@app/views/settings/pages/slot-external-system-field-value/slot-external-system-field-value-search/slot-external-system-field-value.component';
import { SlotTagsComponent } from '@app/views/settings/pages/slot-tags/slot-tags.component';
import { SlotsComponent } from '@app/views/settings/pages/slots/slots.component';
import { StandardPlansSettingsComponent } from '@app/views/settings/pages/standard-plans/standard-plans.component';
import { SystemLogsComponent } from '@app/views/settings/pages/system-logs/system-logs/system-logs.component';
import { TagsComponent } from '@app/views/settings/pages/tags/tags.component';
import { TaskOutcomesComponent } from '@app/views/settings/pages/task-outcomes/components/task-outcomes.component';
import { TeamsComponent } from '@app/views/settings/pages/teams/teams.component';
import { TradeTypesComponent } from '@app/views/settings/pages/trade-types/trade-types/trade-types.component';
import { GlobalSettingsComponent } from '@app/views/settings/pages/global-settings/global-settings.component';
import { SupplyTypesComponent } from '@app/views/settings/pages/supply-types/supply-types/supply-types.component';
import { SupplyTypeModule } from '@app/views/settings/pages/supply-types/supply-type.module';
import { ProductCentreConfigurationModule } from '@app/views/settings/pages/product-centre-configuration/product-centre-configuration.module';
import { ProductCentreConfigurationComponent } from '@app/views/settings/pages/product-centre-configuration/product-centre-configuration.component';
import { BuildMilestoneTemplatesComponent } from './pages/build-milestone-templates/build-milestone-templates.component';
import { BuildMilestoneTemplateSettingsModule } from "@app/views/settings/pages/build-milestone-templates/build-milestone-template.module";

@NgModule({
    declarations: [
        SettingsComponent
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: CustomReuseStrategy }],
    imports: [
        BuildActivitiesSettingsModule,
        BundleTemplatesSettingsModule,
        ComplianceRegistrationSettingsModule,
        GlobalSettingsSettingsModule,
        CouncilsSettingsModule,
        BankAccountsSettingsModule,
        SpecGroupsSettingsModule,
        AddressRegionsSettingsModule,
        ColourItemsSettingsModule,
        BuildStagesSettingsModule,
        PricingComplexitySettingsModule,
        TeamsSettingsModule,
        TagsSettingsModule,
        SlotTagsSettingsModule,
        SlotsSettingsModule,
        TaskOutcomesSettingsModule,
        PaymentFrequenciesSettingsModule,
        StandardPlansModule,
        CbStylesSettingModule,
        CbStandardPlansSettingModule,
        DocumentTypesSettingsModule,
        SearchIndexesSettingsModule,
        BusinessAccountsSettingsModule,
        DesignWorkflowSettingsModule,
        BusinessEntitiesSettingsModule,
        TradeTypesModule,
        SupplyTypeModule,
        PaymentTemplatesModule,
        IntegrationLogsModule,
        SystemLogsModule,
        FeatureTogglesModule,
        MaintenanceSettingsModule,
        BuildTypesSettingsModule,
        NonWorkingDaysSettingsModule,
        LocationsSettingsModule,
        DocumentTemplatesModule,
        DocumentNotificationsModule,
        NotificationTemplatesModule,
        DocumentGroupsModule,
        ExternalSystemFieldsModule,
        SlotExternalSystemFieldValueComponentModule,
        BuildMilestoneTemplateSettingsModule,
        MatCardModule,
        MatSidenavModule,
        MatListModule,
        MatTabsModule,
        CommonModule,
        FormsModule,
        PriceOptionsComponentModule,
        ProductCentreConfigurationModule,
        RouterModule.forRoot([
            {
                component: SettingsComponent,
                path: 'settings',
                data: {
                    breadcrumb: 'Settings',
                },
                children: [
                    {
                        path: '',
                        redirectTo: TabNames.AddressRegions,
                        pathMatch: 'full'
                    },
                    {
                        path: TabNames.AddressRegions,
                        component: AddressRegionsComponent
                    },
                    {
                        path: TabNames.BankAccounts,
                        component: BankAccountsComponent
                    },
                    {
                        path: TabNames.BuildActivities,
                        component: BuildActivitiesComponent
                    },
                    {
                        path: TabNames.BuildStages,
                        component: BuildStagesComponent
                    },
                    {
                        path: TabNames.BuildMilestoneTemplates,
                        component: BuildMilestoneTemplatesComponent
                    },
                    {
                        path: TabNames.BundleTemplates,
                        component: BundleTemplatesComponent
                    },
                    {
                        path: TabNames.BusinessAccounts,
                        component: BusinessAccountsComponent
                    },
                    {
                        path: TabNames.BusinessEntities,
                        component: BusinessEntitiesComponent
                    },
                    {
                        path: TabNames.BuildTypes,
                        component: BuildTypesComponent
                    },
                    {
                        path: TabNames.ComplianceRegistration,
                        component: ComplianceRegistrationComponent
                    },
                    {
                        path: TabNames.ColourItems,
                        component: ColourItemsComponent
                    },
                    {
                        path: TabNames.Councils,
                        component: CouncilsComponent
                    },
                    {
                        path: TabNames.DesignWorkflow,
                        component: DesignWorkflowComponent
                    },
                    {
                        path: TabNames.DocumentGroups,
                        component: DocumentGroupsComponent
                    },
                    {
                        path: TabNames.DocumentNotifications,
                        component: DocumentNotificationsComponent
                    },
                    {
                        path: TabNames.DocumentTypes,
                        component: DocumentTypesComponent
                    },
                    {
                        path: TabNames.DocumentTemplates,
                        component: DocumentTemplatesComponent
                    },
                    {
                        path: TabNames.ExternalSystemFields,
                        component: ExternalSystemFieldsComponent
                    },
                    {
                        path: TabNames.FeatureToggles,
                        component: FeatureToggleViewComponent
                    },
                    {
                        path: TabNames.GlobalSettings,
                        component: GlobalSettingsComponent
                    },
                    {
                        path: TabNames.HouseAreas,
                        component: SpecGroupsComponent
                    },
                    {
                        path: TabNames.IntegrationLogs,
                        component: IntegrationLogsComponent
                    },
                    {
                        path: TabNames.Locations,
                        component: LocationsComponent
                    },
                    {
                        path: TabNames.Maintenance,
                        component: MaintenanceSettingsComponent
                    },
                    {
                        path: TabNames.NonWorkingDays,
                        component: NonWorkingDaysComponent
                    },
                    {
                        path: TabNames.NotificationTemplates,
                        component: NotificationTemplatesComponent
                    },
                    {
                        path: TabNames.PaymentFrequencies,
                        component: PaymentFrequenciesComponent
                    },
                    {
                        path: TabNames.PriceOptions,
                        component: PriceOptionsComponent,
                        canDeactivate: [ConfirmDeactivateGuard]
                    },
                    {
                        path: TabNames.ProductCentre,
                        component: ProductCentreConfigurationComponent
                    },
                    {
                        path: TabNames.PaymentTemplates,
                        component: PaymentTemplatesComponent
                    },
                    {
                        path: TabNames.QSWorkflow,
                        component: PricingComplexityComponent
                    },
                    {
                        path: TabNames.SearchIndexes,
                        component: SearchIndexesComponent
                    },
                    {
                        path: TabNames.ScheduleItemExternal,
                        component: SlotExternalSystemFieldValueComponent
                    },
                    {
                        path: TabNames.ScheduleItemTags,
                        component: SlotTagsComponent
                    },
                    {
                        path: TabNames.ScheduleItems,
                        component: SlotsComponent
                    },
                    {
                        path: TabNames.StandardPlans,
                        component: StandardPlansSettingsComponent
                    },
                    {
                        path: TabNames.SystemLogs,
                        component: SystemLogsComponent
                    },
                    {
                        path: TabNames.Tags,
                        component: TagsComponent
                    },
                    {
                        path: TabNames.TaskOutcomes,
                        component: TaskOutcomesComponent
                    },
                    {
                        path: TabNames.Teams,
                        component: TeamsComponent
                    },
                    {
                        path: TabNames.TradeTypes,
                        component: TradeTypesComponent
                    },
                    {
                        path: TabNames.SupplyTypes,
                        component: SupplyTypesComponent
                    },
                ]
            }
        ], { onSameUrlNavigation: 'ignore' })
    ],
})
export class SettingsModule { }
