import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './notes/notes.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NoteDialogComponent } from './note-dialog/note-dialog.component';
import { NotesListComponent } from './notes-list/notes-list.component';
import { CbDialogModule } from '../dialog/dialog.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CbLoadingSpinnerModule } from 'cb-hub-lib';
import { CbDisplayValueModule } from '@app/shared/components/display-value/display-value.module';
import { CbInfoMessageModule } from 'cb-hub-lib';
import { CbFormsModule } from '../forms/forms.module';

const COMPONENTS = [
    NotesComponent,
    NotesListComponent,
    NoteDialogComponent,
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatCheckboxModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        CbDialogModule,
        MatDialogModule,
        MatExpansionModule,
        CbDialogModule,
        MatDividerModule,
        CbLoadingSpinnerModule,
        CbDisplayValueModule,
        CbInfoMessageModule,
        CbFormsModule
    ],
    exports: COMPONENTS
})

export class CbNotesModule { }
