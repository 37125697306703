import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotSpecItemManageAttributes } from '@app/core/services/user-cache/user-cache-areas';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { ILotSpecScheduleManageAttributeItemLogicService } from '@app/logic/lot-spec-schedule-item/interfaces/i.lot-spec-schedule-manage-attribute-item-logic.service';
import { ILotSpecScheduleManageAttributeItemMappedItem } from '@app/logic/lot-spec-schedule-item/interfaces/i.lot-spec-schedule-manage-attribute-item.mapped';
import { LotSpecScheduleManageAttributeItemLogicService } from '@app/logic/lot-spec-schedule-item/lot-spec-schedule-manage-attribute-item-logic.service';
import { LotSpecScheduleManageAttributeItemMappedItem } from '@app/logic/lot-spec-schedule-item/lot-spec-schedule-manage-attribute-item.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ILotSpecScheduleManageAttributeItemDto, ILotSpecScheduleManageAttributesDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { flattenDeep, values } from 'lodash';
import { Observable } from 'rxjs';

interface IGroupedItems {
    id: number;
    label: string;
    items: LotSpecScheduleManageAttributeItemMappedItem[];
    expanded: boolean;
    anyEmptyItems: () => boolean;
}

@Component({
    templateUrl: './manage-lot-spec-schedule-item-attributes-dialog.component.html',
    styleUrls: ['./manage-lot-spec-schedule-item-attributes-dialog.component.scss']
})
export class ManageLotSpecScheduleItemAttributesDialogComponent extends BaseDialogFormViewDirective<
ILotSpecScheduleManageAttributeItemDto,
ILotSpecScheduleManageAttributeItemMappedItem,
ILotSpecScheduleManageAttributeItemLogicService> {
    public static readonly MIN_WIDTH = '70%';

    public finishedLoading = false;
    public groupedItems: IGroupedItems[] = [];
    public changedItems: { [key: number]: boolean } = {};
    public itemsHaveChanged = false;

    public get userCacheItem(): UserCacheItem<ILotSpecItemManageAttributes> {
        return this.userCacheService.lotSpecItemManageAttributes;
    }

    constructor(
        public readonly dialogRef: MatDialogRef<ManageLotSpecScheduleItemAttributesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            lotId: number;
            specVersion: number;
            specId: number;
        },
        private readonly ManageAttributeItemService: LotSpecScheduleManageAttributeItemLogicService,
        private readonly userCacheService: UserCacheService,
        private readonly toastService: ToastService,
    ) {
        super(dialogRef, toastService);
        this.ManageAttributeItemService
            .getLotSpecAndColourItemsWithAttributes(this.data.lotId)
            .subOnce(this.handleLoadItems);
    }

    public attributeChanged(item: LotSpecScheduleManageAttributeItemMappedItem): void {
        this.itemsHaveChanged = true;
        item.$hasChanged = true;
    }

    public saveMethod(): Observable<ILotSpecScheduleManageAttributesDto> {
        const mappedItems = flattenDeep(
            this.groupedItems.map(group => group.items)
        ).filter(groupedItem => groupedItem != null);

        const dtos = mappedItems.map((element: ILotSpecScheduleManageAttributeItemMappedItem) => element.$getMappedDtoItem());

        return this.ManageAttributeItemService
            .saveLotSpecAndColourItemsWithAttributes(this.data.lotId, {lotSpecScheduleId: this.data.specId,
                scheduleItems: dtos} as ILotSpecScheduleManageAttributesDto);
    }

    public togglePanelPinned(groupId: number): void {
        event.stopPropagation();
        this.userCacheItem.data[groupId]
            = !this.userCacheItem.silentData[groupId];
    }

    public expandCollapseAll(): void {
        const shouldExpand = !this.allPanelsExpanded();
        this.groupedItems.forEach(item => {
            item.expanded = shouldExpand;
        });
    }

    public allPanelsExpanded(): boolean {
        return this.groupedItems != null && this.groupedItems.every(a => a.expanded);
    }

    private handleLoadItems = (scheduleManageAttributeDto: ILotSpecScheduleManageAttributesDto): void => {
        const grouped: { [key: number]: IGroupedItems } = {};

        scheduleManageAttributeDto.scheduleItems.forEach(item => {
            const mappedItem = this.ManageAttributeItemService.$createMappedItem(item, LotSpecScheduleManageAttributeItemMappedItem);
            if (!grouped[mappedItem.specGroupId]) {
                grouped[mappedItem.specGroupId] = {
                    anyEmptyItems() { return this.items.some(a => a.anyEmptyRestrictions()); },
                    expanded: false,
                    id: mappedItem.specGroupId,
                    items: [],
                    label: mappedItem.specGroup,
                    ...mappedItem
                };
            }
            grouped[mappedItem.specGroupId].items.push(mappedItem);
        });

        this.groupedItems = values(grouped);
        this.loadPinnedPanels();
        this.finishedLoading = true;
    };

    private loadPinnedPanels(): void {
        this.userCacheItem.init().then(() => {
            this.groupedItems.forEach((value) => {
                value.expanded = this.userCacheItem.silentData[value.id];
            });
        });
    }
}
