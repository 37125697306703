import {ContactsSearchService} from '@app/core/services/search/contacts-search.service';
import {ContactsLogicService} from '@app/logic/contacts';
import {AfterViewInit, ChangeDetectorRef, Directive, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InputFindType} from '../../../input-find-type.enum';
import {BaseInputFindDialogDirective} from '../../base-input-find-dialog.component';
import {IContactMappedItem} from '@app/logic/contacts/interfaces/i.contact.mapped';

interface IData {
    mappedItem: IContactMappedItem;
    searchService: ContactsSearchService;
    extraSearchParams: { [key: string]: any };
    dialogHeading: string;
    contentHeading: string;
    placeholder: string;
}

/**
 * Compose this inside a concrete inputFindCardComponent and a concrete InputFindTableComponent
 */
@Directive()
export class ContactsInputFindDialogDirective extends BaseInputFindDialogDirective<ContactsLogicService> implements AfterViewInit {
    constructor(
        public readonly dialogRef: MatDialogRef<ContactsInputFindDialogDirective>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public logicService: ContactsLogicService,
        public cdRef: ChangeDetectorRef
    ) {
        super(
            dialogRef,
            data,
            logicService,
            cdRef,
            InputFindType.Contact,
        );
    }

    public ngAfterViewInit(): void {
        this.data.searchService.infiniteScrollContainer = this.scrollContainer;
        this.data.searchService.extraSearchParams = this.data.extraSearchParams;
        this.data.searchService.logicService = this.logicService;
        if (this.data.searchService.isPagination) {
            this.data.searchService.nextPage$.next({ addPage: 1 });
        } else {
            this.data.searchService.doSearchIfNoScrollBar();
        }
        this.cdRef.detectChanges();
    }
}

