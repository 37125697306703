<ng-container *cbWaitFor="mappedItem">
    <mat-accordion [multi]="true">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                <mat-panel-title class="start-center flex-row">
                    <mat-icon class="cb-margin-right">info</mat-icon>
                    <h1 class="mat-body-strong">
                        Scheme Details
                    </h1>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="cb-margin flex-row flex">
                <div class="cb-margin-right flex-col flex-50">
                    <cb-display-value-new label="Design Requirements"
                                          [value]="mappedItem.designRequirements"></cb-display-value-new>
                    <div class="flex-col flex">
                        <cb-select label="Build Type"
                                   name="buildType"
                                   [(ngModel)]="mappedItem.buildTypeId"
                                   [options]="buildTypes"
                                   [readonly]="true"></cb-select>
                        <cb-display-value-new label="Scheme Approver"
                                              [value]="mappedItem.approverUserName"></cb-display-value-new>
                    </div>
                </div>
                <mat-divider [vertical]="true"></mat-divider>
                <div class="cb-margin-left-2x flex-col flex-25">
                    <cb-display-value-new label="Standard Plan"
                                          [value]="mappedItem.standardPlanLabel"></cb-display-value-new>
                    <cb-display-value-new label="Bedrooms"
                                          [value]="mappedItem.amenities.noBedrooms"></cb-display-value-new>
                    <cb-display-value-new label="Living"
                                          [value]="mappedItem.amenities.noLivingRooms"></cb-display-value-new>
                    <cb-display-value-new label="Study"
                                          [value]="mappedItem.amenities.noStudy"></cb-display-value-new>
                </div>
                <div class="flex-col flex-25">
                    <cb-display-value-new label="Floor Area"
                                          [value]="mappedItem.amenities.floorArea"></cb-display-value-new>
                    <cb-display-value-new label="Land Area"
                                          [value]="mappedItem.amenities.landArea"></cb-display-value-new>
                    <cb-display-value-new label="Bathrooms"
                                          [value]="mappedItem.amenities.noBathrooms"></cb-display-value-new>
                    <cb-display-value-new label="Garages"
                                          [value]="mappedItem.amenities.noGarages"></cb-display-value-new>
                </div>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                <mat-panel-title class="start-center flex-row">
                    <mat-icon class="cb-margin-right">list</mat-icon>
                    <h1 class="mat-body-strong">
                        Scheme Items
                    </h1>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let revisonItem of mappedItem.designRequirementItems">
                <div *ngIf="!revisonItem.isDeleted">
                    <cb-basic-list-item [itemId]="revisonItem.itemId"
                                        [description]="revisonItem.description"></cb-basic-list-item>
                </div>
            </div>
            <div class="cb-margin start-center flex-row"
                 *ngIf="!mappedItem.designRequirementItems || mappedItem.designRequirementItems.length < 1">
                <mat-icon class="mat-accent">info</mat-icon>
                <span class="mat-body-2">&nbsp; There are no scheme items</span>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                <mat-panel-title class="start-center flex-row">
                    <mat-icon class="cb-margin-right">timelapse</mat-icon>
                    <h1 class="mat-body-strong">
                        Design Changes
                    </h1>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let record of mappedItem.designSchemeLines">
                <cb-basic-list-item [itemId]="record.changeNumber"
                                    [description]="record.changeRecordDetails"></cb-basic-list-item>
            </div>
            <div class="cb-margin start-center flex-row"
                 *ngIf="!mappedItem.designSchemeLines || mappedItem.designSchemeLines.length < 1">
                <mat-icon class="mat-accent">info</mat-icon>
                <span class="mat-body-2">&nbsp;There are no design changes</span>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                <mat-panel-title class="start-center flex-row">
                    <mat-icon class="cb-margin-right">content_copy</mat-icon>
                    <h1 class="mat-body-strong">Design Info Documents</h1>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="documentsToUpload.designInfoDocuments.length > 0"
                 class="cb-margin flex-col flex">
                <cb-table [loaded]="true">
                    <thead cbTableHead>
                        <tr>
                            <td>File Name</td>
                            <td>File Type</td>
                        </tr>
                    </thead>
                    <tbody cbTableBody>
                        <tr *ngFor="let document of documentsToUpload.designInfoDocuments">
                            <td>{{document.name}}</td>
                            <td>{{documentTypesLookup[document.documentTypeId].label | cbDasher}}</td>
                        </tr>
                        <tr [hidden]="documentsToUpload.designInfoDocuments.length > 0">
                            <td colspan="2">
                                <mat-icon class="mat-accent">info_circle</mat-icon>
                                &nbsp; No Documents added
                            </td>
                        </tr>
                    </tbody>
                </cb-table>
            </div>
            <div class="cb-margin start-center flex-row"
                 *ngIf="!documentsToUpload.designInfoDocuments || documentsToUpload.designInfoDocuments.length < 1">
                <mat-icon class="mat-accent">info</mat-icon>
                <span class="mat-body-2">&nbsp; No Documents added</span>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                <mat-panel-title class="start-center flex-row">
                    <mat-icon class="cb-margin-right">content_copy</mat-icon>
                    <h1 class="mat-body-strong">Site Photos & Videos</h1>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="documentsToUpload.lotSitePhotoDocuments.length > 0"
                 class="cb-margin flex-col flex">
                <cb-table [loaded]="true">
                    <thead cbTableHead>
                        <tr>
                            <td>File Name</td>
                            <td>File Type</td>
                        </tr>
                    </thead>
                    <tbody cbTableBody>
                        <tr *ngFor="let document of documentsToUpload.lotSitePhotoDocuments">
                            <td>{{document.document.name}}</td>
                            <td>{{documentTypesLookup[document.documentType.id].label | cbDasher}}</td>
                        </tr>
                        <tr [hidden]="documentsToUpload.lotSitePhotoDocuments.length > 0">
                            <td colspan="2">
                                <mat-icon class="mat-accent">info_circle</mat-icon>
                                &nbsp; No Documents added
                            </td>
                        </tr>
                    </tbody>
                </cb-table>
            </div>
            <div class="cb-margin start-center flex-row"
                 *ngIf="!documentsToUpload.lotSitePhotoDocuments || documentsToUpload.lotSitePhotoDocuments.length < 1">
                <mat-icon class="mat-accent">info</mat-icon>
                <span class="mat-body-2">&nbsp; No Documents added</span>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header class="cb-padding-top cb-padding-bottom">
                <mat-panel-title class="start-center flex-row">
                    <mat-icon class="cb-margin-right">content_copy</mat-icon>
                    <h1 class="mat-body-strong">Scheme Files</h1>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="documentsToUpload.designSchemeDocuments.length > 0"
                 class="cb-margin flex-col flex">
                <cb-table [loaded]="true">
                    <thead cbTableHead>
                        <tr>
                            <td>File Name</td>
                            <td>File Type</td>
                        </tr>
                    </thead>
                    <tbody cbTableBody>
                        <tr *ngFor="let document of documentsToUpload.designSchemeDocuments">
                            <td>{{document.name}}</td>
                            <td>{{documentTypesLookup[document.documentTypeId].label | cbDasher}}</td>
                        </tr>
                    </tbody>
                </cb-table>
            </div>
            <div class="cb-margin start-center flex-row"
                 *ngIf="!documentsToUpload.designSchemeDocuments || documentsToUpload.designSchemeDocuments.length < 1">
                <mat-icon class="mat-accent">info</mat-icon>
                <span class="mat-body-2">&nbsp; No Documents added</span>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div style="margin: 20px;">
        <cb-agree-to-condition [(ngModel)]="mappedItem.lotSpecChangesMadePreperation"
                               name="lotSpecChangesMadePreperation"
                               label="All required Lot Specification changes have been made in preparation for requesting this Design Scheme">
        </cb-agree-to-condition>
    </div>
</ng-container>
