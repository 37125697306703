import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILotSpecItemMappedItem } from '@app/logic/lot-spec-item';
import { toPromisedArray } from 'cb-hub-lib';
import { COST_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    templateUrl: './lot-spec-quote-refs-dialog.component.html',
    styleUrls: ['./lot-spec-quote-refs-dialog.component.scss']
})
export class LotSpecQuoteRefsDialogComponent {
    public static readonly MIN_WIDTH = '75%';
    public readonly columns = [
        'Qref',
        'Cost Type',
        'Line Item',
        'Subtotal',
        'Supplier',
        'Quote Date',
        'Quote #',
        'CR #',
        'VO #',
        '',
    ];
    public readonly quoteLines = toPromisedArray(this.data.lotSpecItem.getQuoteLines());
    public readonly COST_TYPE_ENUM = COST_TYPE_ENUM;

    constructor(
        public readonly dialogRef: MatDialogRef<LotSpecQuoteRefsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            lotSpecItem: ILotSpecItemMappedItem;
        }
    ) { }

    public copyToClipboardClicked(copyText: number): void {
        navigator?.clipboard?.writeText(`Q${copyText}`);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
