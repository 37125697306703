import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatTableModule } from '@angular/material/table';
import { NgModule } from '@angular/core';
import { DesignTeamWorkLoadDialogComponent } from './design-team-work-load-dialog';
import { CbDialogModule } from '@app/shared/components/dialog/dialog.module';
import { CbDasherModule, CbLoadingSpinnerModule } from 'cb-hub-lib';

@NgModule({
    declarations: [
        DesignTeamWorkLoadDialogComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        FormsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        CbLoadingSpinnerModule,
        CbDialogModule,
        CbDasherModule,
    ],
    exports: [
        DesignTeamWorkLoadDialogComponent
    ]
})

export class DesignTeamWorkLoadDialogModule { }
