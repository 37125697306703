<cb-dialog class="flex-col"
           dialogHeading="Create Lead"
           (cancelDialog)="cancel(createLeadForm.dirty)">
    <form class="flex-col flex"
          #createLeadForm="ngForm">
        <div class="start-center flex-row">
            <cb-contact-autocomplete-scroller class="cb-margin-right flex"
                                              label="Primary Contact"
                                              name="primaryContact"
                                              [(ngModel)]="primaryContact"
                                              [requireMatch]="true"
                                              [matchProps]="['id']"
                                              [required]="true">
            </cb-contact-autocomplete-scroller>
            <button mat-icon-button
                    matTooltip="Add a new Contact"
                    (click)="createPrimaryContact()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="start-center flex-row">
            <cb-contact-autocomplete-scroller class="cb-margin-right flex"
                                              label="Secondary Contact"
                                              name="secondaryContact"
                                              [requireMatch]="true"
                                              [matchProps]="['id']"
                                              [(ngModel)]="secondaryContact">
            </cb-contact-autocomplete-scroller>
            <button mat-icon-button
                    matTooltip="Add a new Contact"
                    (click)="createSecondaryContact()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <cb-datepicker label="Enquiry Date"
                       name="enquiryDate"
                       [(ngModel)]="createLeadDto.enquiryDate"
                       [required]="true"
                       pickerType="calendar"></cb-datepicker>
        <cb-select label="Enquiry Origin"
                   name="enquiryOrigin"
                   [(ngModel)]="createLeadDto.enquiryOrigin"
                   [options]="enquiryOrigins"
                   [required]="true"></cb-select>
        <cb-select *ngIf="createLeadDto.enquiryOrigin === ENQUIRY_ORIGIN_ENUM.Showhome"
                   label="Showhome"
                   name="showhome"
                   [(ngModel)]="createLeadDto.showHomeId"
                   [options]="showhomes"
                   [required]="true"></cb-select>
        <cb-input *ngIf="createLeadDto.enquiryOrigin === ENQUIRY_ORIGIN_ENUM.Showhome && createLeadDto.showHomeId === 0"
                  label="Showhome Other"
                  type="text"
                  name="otherShowHome"
                  [(ngModel)]="createLeadDto.otherShowHome"
                  [required]="true"></cb-input>
        <cb-user-autocomplete-scroller label="Building Consultant"
                                       name="buildingConsultant"
                                       [(ngModel)]="buildingConsultant"
                                       [required]="true"
                                       [active]="true"
                                       [requireMatch]="true"
                                       [matchProps]="['id']"
                                       [tag]="BUILDING_CONSULTANT_TAG"></cb-user-autocomplete-scroller>
        <mat-radio-group class="flex-col"
                         aria-label="Select a Lot Interest Type"
                         name="lotInterestType"
                         [(ngModel)]="createLeadDto.lotContractType"
                         [required]="true">
            <mat-radio-button [value]="LOT_CONTRACT_TYPE_ENUM.None">To be confirmed</mat-radio-button>
            <mat-radio-button [value]="LOT_CONTRACT_TYPE_ENUM.HouseAndLand">House &amp; Land</mat-radio-button>
            <mat-radio-button [value]="LOT_CONTRACT_TYPE_ENUM.DesignAndLand">Design &amp; Land</mat-radio-button>
            <mat-radio-button [value]="LOT_CONTRACT_TYPE_ENUM.DesignAndBuild">Design &amp; Build</mat-radio-button>
        </mat-radio-group>
        <cb-lot-autocomplete-scroller *ngIf="createLeadDto.lotContractType === LOT_CONTRACT_TYPE_ENUM.HouseAndLand"
                                      label="House & Land Lot"
                                      name="houseAndLandLot"
                                      [(ngModel)]="hlLot"
                                      [availableOnly]="true"></cb-lot-autocomplete-scroller>
        <cb-lot-autocomplete-scroller *ngIf="createLeadDto.lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand"
                                      label="Design & Land Lot"
                                      name="designAndLandLot"
                                      [(ngModel)]="designAndLandLot"
                                      [availableOnly]="true"
                                      [lotContractTypes]="[LOT_CONTRACT_TYPE_ENUM.DesignAndLand]"
                                      [requireActiveConcept]="false">
        </cb-lot-autocomplete-scroller>
        <div class="flex-col"
             *ngIf="createLeadDto.lotContractType === LOT_CONTRACT_TYPE_ENUM.DesignAndBuild">
            <cb-checkbox label="Lot details are known"
                         name="dbDetailsKnown"
                         [(ngModel)]="dbDetailsKnown"></cb-checkbox>
            <div class="flex-col"
                 *ngIf="dbDetailsKnown">
                <cb-input label="Lot Number"
                          type="number"
                          name="dbLotNumber"
                          [(ngModel)]="createLeadDto.designAndBuildDetails.lotNumber"
                          [required]="true"></cb-input>
                <cb-address-new name="dbAddress"
                                [(ngModel)]="createLeadDto.designAndBuildDetails.address"
                                [required]="true"
                                [hideHeading]="true"
                                [regionId$]="regionId$">
                </cb-address-new>
                <cb-location-autocomplete-scroller label="Area"
                                                   name="dbLocation"
                                                   [(ngModel)]="location"
                                                   [required]="true"
                                                   [regionId]="regionId"
                                                   (change)="reloadBuidlingConsentCouncils()">
                </cb-location-autocomplete-scroller>
                <cb-select label="Build Consent Council"
                           name="sbBuildConsentCouncil"
                           [(ngModel)]="createLeadDto.designAndBuildDetails.buildingConsentCouncilId"
                           [options]="councils"
                           [required]="true"></cb-select>
                <cb-checkbox label="Client Owns Land"
                             name="dbClientOwnsLand"
                             [(ngModel)]="createLeadDto.designAndBuildDetails.clientOwnsLand"></cb-checkbox>
                <cb-select label="Build Type"
                           name="BuildType"
                           [(ngModel)]="createLeadDto.designAndBuildDetails.buildTypeId"
                           [options]="buildTypes"
                           [required]="true"></cb-select>
            </div>
        </div>
        <cb-text-area label="Notes"
                      name="notes"
                      [(ngModel)]="createLeadDto.notes"></cb-text-area>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (cbClick)="submitCreate()"
                [clickLimit]="1"
                [disabled]="createLeadForm.invalid || createLeadForm.pristine">Create</button>
        <button mat-raised-button
                (click)="cancel(createLeadForm.dirty)">Cancel</button>
    </div>
</cb-dialog>
