import { Inject } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILotSpecItemMappedItem } from '@app/logic/lot-spec-item';
import { SsrsLogicService } from '@app/logic/ssrs';
import { toPromisedArray } from 'cb-hub-lib';

@Component({
    templateUrl: './lot-spec-purchase-orders-dialog.component.html',
    styleUrls: ['./lot-spec-purchase-orders-dialog.component.scss']
})
export class LotSpecPurchaseOrdersDialogComponent {
    public static readonly MIN_WIDTH = '75%';
    public readonly columns = [
        'PO #',
        'Date ',
        'Quantity',
        'Status',
        '',
    ];
    public readonly purchaseOrders = toPromisedArray(this.data.lotSpecItem.getPurchaseOrders());

    constructor(
        public readonly dialogRef: MatDialogRef<LotSpecPurchaseOrdersDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            lotSpecItem: ILotSpecItemMappedItem;
        },
        @Inject(SsrsLogicService) private readonly ssrLogic: SsrsLogicService,
    ) { }

    public downloadPurchaseOrder(id: number): void {
        this.ssrLogic.downloadPo(id).subOnce();
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
