import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IReleaseStageMappedItem } from '@app/logic/release-stages/interfaces/i.release-stage.mapped';
import { ReleaseStagesLogicService } from '@app/logic/release-stages/release-stages.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { FormMode } from '@app/shared/enums/form';
import {
    BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM,
    BUSINESS_ACCOUNT_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { Observable } from 'rxjs';
import { IProjectReleaseStageDefaultProductSupplierDto } from '@classictechsolutions/hubapi-transpiled-enums/build/main/lib/dtos/ProjectReleaseStageDefaultProductSupplierDto';


export interface IDefaultSuppliersDialogData {
    mappedItem: IReleaseStageMappedItem;
    item: IProjectReleaseStageDefaultProductSupplierDto;
}

@Component({
    selector: 'cb-release-default-supplier-dialog',
    templateUrl: './release-default-supplier-dialog.component.html',
    styleUrls: ['./release-default-supplier-dialog.component.scss']
})

export class ReleaseDefaultSupplierDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '50%';
    public loaded = false;
    public mappedItem: IReleaseStageMappedItem;
    public item: IProjectReleaseStageDefaultProductSupplierDto;
    public availableSupplyTypes: any[];
    public selectedSupplyTypeId: number;
    public selectedSupplier: any = {};
    public businessAccountSearchParams: { [param: string]: any };
    public invalidSupplier: boolean;

    constructor(
        public readonly dialogRef: MatDialogRef<ReleaseDefaultSupplierDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IDefaultSuppliersDialogData,
        private readonly releaseStagesLogicService: ReleaseStagesLogicService,
        private readonly toastService: ToastService
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.item = data.item;
    }

    public ngOnInit(): void {
        super.ngOnInit();
        if (!this.item) {
            this.formMode = FormMode.Add;
        }
        if (this.isEdit()) {
            this.selectedSupplyTypeId = this.item.supplyType.id;
            this.selectedSupplier = { id: this.item.supplier.id, tradingName: this.item.supplier.label };
        } else {
            this.releaseStagesLogicService.getAvailableSupplyTypes(this.mappedItem.id).subOnce((supplyTypes) => {
                this.availableSupplyTypes = supplyTypes;
            });
        }
        this.setBusinessAccountSearchParams();
    }

    public saveMethod(): Observable<IProjectReleaseStageDefaultProductSupplierDto> {
        const payload = {
            projectReleaseStageId: this.mappedItem.$id,
            supplier: { id: this.selectedSupplier.id, label: this.selectedSupplier.tradingName },
            supplyType: { id: this.selectedSupplyTypeId, label: undefined }
        } as IProjectReleaseStageDefaultProductSupplierDto;


        return this.releaseStagesLogicService.updateDefaultSupplier(this.mappedItem.$id as number, payload);
    }

    public get modeString(): string {
        return this.isEdit() ? 'Edit' : 'Add';
    }

    public selectedSupplyTypeChange(): void {
        this.resetForm();
        this.setBusinessAccountSearchParams();
    }

    public changeSupplier(): void {
        if (this.item !== undefined) {
            this.item.supplier = {
                id: this.selectedSupplier.id,
                label: this.selectedSupplier.tradingName
            };
        }
    }

    public isSaveDisabled(): boolean {
        return !(this.selectedSupplyTypeId && this.selectedSupplier.id);
    }

    private resetForm(): void {
        this.invalidSupplier = false;
        this.selectedSupplier = {};
    }

    private setBusinessAccountSearchParams(): void {
        this.businessAccountSearchParams = {
            reg: this.mappedItem.projectLocation ? [this.mappedItem.projectLocation.region.id] : undefined,
            ro: false, // not ratesonly
            s: [BUSINESS_ACCOUNT_STATUS_ENUM.Active, BUSINESS_ACCOUNT_STATUS_ENUM.Overridden],
            t: BUSINESS_ACCOUNT_SEARCH_TYPE_ENUM.SupplierOnly,
            supt: this.selectedSupplyTypeId
        };
    }

}
