import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DesignComplexityLogicService } from '@app/logic/design-complexity';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { IDesignTeamReportItemDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-design-team-work-load-dialog',
    templateUrl: 'design-team-work-load-dialog.html',
    styleUrls: ['design-team-work-load-dialog.scss'],
})

export class DesignTeamWorkLoadDialogComponent {
    public static readonly MIN_WIDTH = '100%';

    public dataSource = new MatTableDataSource<IDesignTeamReportItemDto>();

    public isWorkloadLoaded = false;
    public isColumnsLoaded = false;


    public displayedColumns: string[] = [];

    public schemeColumns: string[] = [];
    public preConsentColumns: string[] = [];
    public workingDrawingColumns: string[] = [];

    constructor(
        public readonly dialogRef: MatDialogRef<DesignTeamWorkLoadDialogComponent>,
        @Inject(DesignComplexityLogicService) public readonly designComplexityLogicService: DesignComplexityLogicService,
        @Inject(TeamsLogicService) public readonly teamsLogicService: TeamsLogicService,
    ) {

        this.loadDesignTeamWorkLoad();
        this.loadColumns();
    }


    public loadDesignTeamWorkLoad(): void {
        this.teamsLogicService.getDesignTeamWorkLoad().subOnce((result) => {
            this.dataSource.data = result;
            this.isWorkloadLoaded = true;
        });
    }

    public loadColumns(): void {
        this.designComplexityLogicService.getDesignSchemeComplexitiesInUse().subOnce(result => {

            this.schemeColumns = result.scheme.map((x) => 'S' + x);
            this.preConsentColumns = result.preConsent.map((x) => 'C' + x);
            this.workingDrawingColumns = result.workingDrawing.map((x) => 'W' + x);

            this.displayedColumns = ['designer'].concat(this.schemeColumns, this.preConsentColumns, this.workingDrawingColumns);

            this.isColumnsLoaded = true;
        });
    }

}

