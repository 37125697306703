<cb-dialog dialogHeading="Select Existing Contact">
    <cb-table class="cb-margin-top"
              [stringColumns]="displayedColumns"
              [loaded]="true">
        <tr cbTableRow
            *ngFor="let ele of data.existingContacts">
            <td>{{ele.name | cbDasher}}</td>
            <td>{{ele.email | cbDasher}}</td>
            <td>{{ele.phoneMobile | cbDasher}}</td>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        (click)="selectContact(ele)">
                    <mat-icon>launch</mat-icon>
                </button>
            </td>
        </tr>
    </cb-table>
    <div actions>
        <button mat-raised-button
                (click)="cancel()">Close</button>
    </div>
</cb-dialog>
