<div class="flex-col flex">
    <div class="mat-subtitle-1 widget-name">{{formattedLabel}}</div>
    <div class="widget-container flex-col">
        <div class="widget-list-item flex-col"
             *ngFor="let item of data; let i = index">
            <div class="cb-padding-top cb-padding-bottom start-start flex-row flex">
                <div class="flex-col flex">
                    <span class="mat-body-2">
                        {{item.label}} {{item.secondaryLabel != undefined ? '- ' + item.secondaryLabel : ''}}</span>
                    <span *ngIf="!!item.description"
                          class="mat-caption">
                        {{item.description}}</span>
                    <span *ngIf="!!item.furtherDescription"
                          class="mat-caption">
                        {{item.furtherDescription}}</span>
                    <span *ngIf="displayStatus && item.primaryEnumLabel"
                          class="mat-caption">
                        {{item.primaryEnumLabel}}</span>
                    <span *ngIf="showDueDate"
                          class="mat-caption">
                        Due: {{item.dueDate | cbDate}}</span>
                </div>
                <button mat-icon-button
                        class="cb-margin-right"
                        [disabled]="viewItemUrl(item)?.url === '#'"
                        (click)="goToItem(viewItemUrl(item), $event)">
                    <mat-icon>pageview</mat-icon>
                </button>
            </div>
            <hr *ngIf="i < data.length-1"
                class="mat-divider"/>
        </div>
        <span class="start-center flex layoutGap-6"
              *ngIf="!data || !data.length">
            <mat-icon class="mat-accent">info_circle</mat-icon>
            <div>
                There are no {{formattedLabel.toLowerCase()}}
            </div>
        </span>
    </div>
</div>
