<form class="flex-col flex"
      #projectSearchForm="ngForm">
    <mat-card class="cb-margin start flex">
        <mat-card-content>
            <div class="start-center flex-row flex">
                <mat-icon class="cb-grey">search</mat-icon>
                <mat-form-field class="cb-margin-left cb-margin-right md-33 sm-50 xs-100 flex-25">
                    <mat-label>Type in Project Name, Suburb or City</mat-label>
                    <input class="flex"
                           matInput
                           type="text"
                           name="search"
                           [(ngModel)]="userCacheItem.data.query"/>
                </mat-form-field>
                <span class="flex"></span>
                <div>
                    <button mat-raised-button
                            color="primary"
                            *ngIf="canCreate()"
                            (cbClick)="createProject()">
                        Create Project
                    </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <div class="scroll-container"
         [cbInfiniteScroll]="listContainer"
         [fetch]="fetchResults()"
         [queryUpdated]="queryUpdate"
         [enabled]="searchEnabled"
         [(results)]="results"
         [(currentPage)]="currentPage">
        <cb-info-message *ngIf="shouldShowNoResultsMessage()"
                         message="There are no Projects available for your search criteria"
                         iconClass="cb-accent">
        </cb-info-message>
        <div #listContainer
             class="list-container flex-wrap xs-column flex">
            <div class="md-33 sm-50 xs-100 flex-25"
                 *ngFor="let result of results">
                <cb-project-card [dto]="result">
                </cb-project-card>
            </div>
        </div>
    </div>
</form>
