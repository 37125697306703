<cb-dialog>
    <form class="flex-col flex"
          #documentTemplateForm="ngForm">
        <cb-select label="System Area"
                   name="category"
                   [required]="true"
                   [options]="categories"
                   [readonly]="data.isUploadOnly"
                   [ngModel]="getDefaultCategory()"
                   (change)="onCategoryChanged($event)"></cb-select>
        <cb-input type="text"
                  name="name"
                  label="Template Name"
                  [required]="true"
                  [readonly]="data.isUploadOnly"
                  [(ngModel)]="mappedItem.name">
        </cb-input>
        <cb-select label="Template Code"
                   name="code"
                   [required]="true"
                   [readonly]="mappedItem?.id != null || data.isUploadOnly"
                   [options]="documentTemplateCodes"
                   [(ngModel)]="mappedItem.code"></cb-select>
        <div *ngIf="isCodeDuplicated()"
             class="cb-padding-bottom-2x">
            <mat-error>
                <small>
                    Template Code needs to be unique
                </small>
            </mat-error>
        </div>
        <cb-text-area name="description"
                      label="Description"
                      [maxlength]="1000"
                      [required]="true"
                      [readonly]="data.isUploadOnly"
                      [(ngModel)]="mappedItem.description"></cb-text-area>
        <div *ngIf="!mappedItem.id || data.isUploadOnly"
             class="cb-margin-top-2x flex">
            <cb-drag-and-drop-file class="flex-col flex"
                                   [description]="mappedItem.filename"
                                   [file$]="file$"
                                   [isFileValidAndUploaded$]="isFileValidAndUploaded$"
                                   [allowedFileTypes]="allowedFileTypes">
            </cb-drag-and-drop-file>
        </div>
        <cb-checkbox label="Active"
                     name="isActive"
                     class="cb-margin-top-2x"
                     [readonly]="data.isUploadOnly"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="onSaveClick()"
                [disabled]="!canSave()">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
