import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ChangeOptionLineLogicService } from '@app/logic/change-option-line/change-option-line.logic.service';
import { IChangeOptionLogicService } from '@app/logic/change-option/interfaces/i.change-option.logic-service';
import { IChangeOptionMappedItem } from '@app/logic/change-option/interfaces/i.change-option.mapped';
import { IChangeRecordMappedItem } from '@app/logic/change-records/interfaces/i.change-record.mapped';
import { ILotMappedItem } from '@app/logic/lots';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { FormMode } from '@app/shared/enums/form';
import {
    CHANGE_DIRECTION_ENUM,
    CHANGE_STATUS_ENUM,
    COST_NATURE_ENUM,
    IChangeOptionDto,
    IQuoteLineDto
} from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    changeRecordMappedItem: IChangeRecordMappedItem;
    changeOptionMappedItem: IChangeOptionMappedItem;
    lotMappedItem: ILotMappedItem;
    mode: FormMode;
}

@Component({
    selector: 'cb-lot-change-option-dialog',
    templateUrl: './lot-change-option-dialog.component.html',
    styleUrls: ['./lot-change-option-dialog.component.scss']
})

export class LotChangeOptionDialogNewComponent extends BaseDialogFormViewDirective<IChangeOptionDto, IChangeOptionMappedItem, IChangeOptionLogicService> {
    public static readonly MIN_WIDTH = '80%';

    public changeRecordMappedItem: IChangeRecordMappedItem;
    public lotMappedItem: ILotMappedItem;

    public totalLessThanZero = false;
    public calculatedPriceForQuoteLines: number;

    public mode: FormMode;


    constructor(
        public readonly dialogRef: MatDialogRef<LotChangeOptionDialogNewComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(ChangeOptionLineLogicService) public readonly changeOptionLineLogicService: ChangeOptionLineLogicService,
    ) {
        super(dialogRef, toastService);
        this.mode = data.mode;
        this.mappedItem = data.changeOptionMappedItem;
        this.changeRecordMappedItem = data.changeRecordMappedItem;
        this.lotMappedItem = data.lotMappedItem;

        this.calculatedPriceForQuoteLines = data.changeOptionMappedItem.price;
    }

    public get dialogHeading(): string {
        if (this.mode === FormMode.Edit) {
            return 'Edit Change Option';
        } else if (this.mode === FormMode.Add) {
            return 'Add Change Option';
        } else {
            return 'View Change Option';
        }
    }

    public get calculatedTotalPlusOther(): number {
        const total = this.netChange + (+this.mappedItem.otherCosts || 0);
        this.setTotalLessZeroAndMargin(total);
        return total;
    }

    public setTotalLessZeroAndMargin(total: number): void {
        this.totalLessThanZero = total < 0;
        if (this.totalLessThanZero) {
            this.mappedItem.marginPercentage = 0;
        }
    }

    public get sumofAllocatedAmounts(): number {
        return this.mappedItem?.allocatedQuoteLines
            ?.reduce((sum: number, b: IQuoteLineDto) => sum + b.allocatedAmount, 0) ?? 0;
    }

    public get calculatedMarginAmount(): number {
        return this.calculatedTotalPlusOther * (this.mappedItem.marginPercentage / 100);
    }

    public get calculatedTotalWithMargin(): number {
        return this.calculatedTotalPlusOther * ((this.mappedItem.marginPercentage / 100) + 1);
    }

    // used for readonly display once change option is no longer editable (Accepted/Complete etc)
    public get changeOptionPriceInclMargin(): number {
        if (this.mappedItem.marginPercentage > 0) {
            return this.mappedItem.price * ((this.mappedItem.marginPercentage / 100) + 1);
        } else {
            return this.mappedItem.price;
        }
    }


    public get sumAddAmounts(): number {
        let returnValue = 0;

        this.mappedItem.changeOptionLines?.forEach((line) => {
            if (line.changeDirection === CHANGE_DIRECTION_ENUM.Add) {
                returnValue += line.costAmount;
            }
        });

        return returnValue;
    }

    public get sumCreditAmounts(): number {
        let returnValue = 0;

        this.mappedItem.changeOptionLines?.forEach((line) => {
            if (line.changeDirection === CHANGE_DIRECTION_ENUM.Credit) {
                returnValue += line.costAmount;
            }
        });

        return returnValue;
    }


    public get netChange(): number {
        const designCost = Number(this.mappedItem.designChangeCost || 0);
        if (designCost > -1 && this.mappedItem.designChangesRequired) {
            return this.sumAddAmounts + designCost - this.sumCreditAmounts;
        } else {
            return this.sumAddAmounts - this.sumCreditAmounts;
        }
    }


    public marginIsEditable(): boolean {
        return !this.totalLessThanZero
            && !this.mappedItem.isSelectedChangeOption
            && this.changeRecordMappedItem.changeStatus !== CHANGE_STATUS_ENUM.Cancelled
            && (this.changeRecordMappedItem.isExtraToClient
                || this.changeRecordMappedItem.costNature === COST_NATURE_ENUM.ExtraToSupplier);
    }



    public getViewMode(): boolean {
        return this.changeRecordMappedItem.changeStatus !== CHANGE_STATUS_ENUM.InProgress;
    }

    public disableClientApprovalCheckbox(): boolean {
        return !this.lotMappedItem.hasClientSale || this.changeRecordMappedItem.isExtraToClient;
    }

    public isLotSpecItemReversal(): boolean {
        return this.mappedItem && this.mappedItem.lotSpecItemId > 0;
    }


    public calculateChanges(): void {
        const total = this.netChange + (+this.mappedItem.otherCosts || 0);
        this.setTotalLessZeroAndMargin(total);
    }

    public saveChangeOption(): void {

        if (this.mappedItem.id > 0) {

            // Delete Removed Change Option Lines
            let deletedOptionCount = this.mappedItem?.changeOptionLinesToDelete?.length ?? 0;
            const deletedOptions = new Promise((resolve, reject) => {
                // If there are no options to delete, resolve immediately
                if (deletedOptionCount === 0) {
                    resolve(true);
                }
                // Loop and delete each option
                this.mappedItem.changeOptionLinesToDelete.forEach((element) => {
                    this.changeOptionLineLogicService.deleteOptionLine(element).toPromise().then(() => {
                        deletedOptionCount -= 1;
                        // Resolve on the last option
                        if (deletedOptionCount <= 0) {
                            resolve(true);
                        }
                    });
                });
            });

            deletedOptions.then((success) => {
                // Map the recent edited option
                const elementsIndex = this.changeRecordMappedItem.changeOptions.findIndex(element => element.id === this.mappedItem.id);

                if (elementsIndex > -1) {
                    this.changeRecordMappedItem.changeOptions[elementsIndex] = this.mappedItem.$getMappedDtoItem();
                }

                this.changeRecordMappedItem.$save().subOnce((changeRecordDto) => {
                    this.dialogRef.close(changeRecordDto);
                });
            });
        } else {
            this.changeRecordMappedItem.changeOptions.push(this.mappedItem.$getMappedDtoItem());

            this.changeRecordMappedItem.$save().subOnce((changeRecordDto) => {
                this.dialogRef.close(changeRecordDto);
            });
        }
    }

    public get isExtraToSupplier(): boolean {
        return this.changeRecordMappedItem.costNature === COST_NATURE_ENUM.ExtraToSupplier;
    }


    public get isExtraToClient(): boolean {
        return this.changeRecordMappedItem.costNature === COST_NATURE_ENUM.ExtraToClient;
    }

}
