<cb-dialog [disableCloseButtonFocus]="true">
    <div class="flex-row flex">
        <h3 class="mat-headline-6">{{contentHeading}}</h3>
        <span class="flex"></span>
        <div>
            <button mat-raised-button
                    *ngIf="buttonLabel"
                    color="primary"
                    (click)="buttonClick()">
                {{buttonLabel}}
            </button>
        </div>
    </div>
    <form class="flex-row flex"
          #inputFindCardsForm="ngForm">
        <cb-input class="flex-100"
                  name="userInput"
                  [required]="true"
                  [maxlength]="150"
                  [name]="placeholder"
                  [label]="placeholder"
                  [disabled]="false"
                  [(ngModel)]="searchService.query"
                  (change)="searchService.handleInputChange()">
        </cb-input>
    </form>
    <div #scrollContainer
         class="scroll-container flex-col flex">
        <div class="flex-wrap"
             infiniteScroll
             (scrolled)="dataScrolled()"
             [immediateCheck]="false"
             [fromRoot]="true"
             [infiniteScrollContainer]="scrollContainer">
            <!-- TODO: Remove $any() usage and make this scroll container generic
                NOTE: Because searchService.searchResult$ is always IResult, the type of item
                will never be the same as all specific dto types for each card component.
                There is probably a way around this, but the implementation of this base component
                and the hardcoded checks for each input type seems to go against the nature of
                having a base component in the first place.
             -->
            <ng-container *ngIf="type === INPUT_FIND_TYPE.BusinessAccount">
                <cb-business-account-card class="flex-col md-33 lg-33 sm-50 xl-20 xs-100"
                                          *ngFor="let item of searchService?.searchResults$ | async"
                                          [dto]="$any(item)"
                                          cardType="addAccount"
                                          style="position: relative;min-height: 100px;"
                                          (selectionChosenEmitter)="selectionChosen(item)">
                </cb-business-account-card>
            </ng-container>
            <ng-container *ngIf="type === INPUT_FIND_TYPE.Lead">
                <cb-lead-card class="flex-col md-33 lg-33 sm-50 xl-20 xs-100"
                              *ngFor="let item of searchService?.searchResults$ | async"
                              [dto]="$any(item)"
                              style="position: relative;min-height: 100px;"
                              (selectionChosenEmitter)="selectionChosen(item)">
                </cb-lead-card>
            </ng-container>
            <ng-container *ngIf="type === INPUT_FIND_TYPE.Contact">
                <cb-contact-card class="flex-col md-33 lg-33 sm-50 xl-20 xs-100"
                                 *ngFor="let item of searchService?.searchResults$ | async"
                                 [dto]="$any(item)"
                                 style="position: relative;min-height: 100px;"
                                 (selectionChosenEmitter)="selectionChosen(item)">
                </cb-contact-card>
            </ng-container>
            <ng-container *ngIf="type === INPUT_FIND_TYPE.ClientAccount">
                <cb-client-account-input-find-card class="flex-col md-33 lg-33 sm-50 xl-20 xs-100"
                                                   *ngFor="let item of searchService?.searchResults$ | async"
                                                   [dto]="$any(item)"
                                                   style="position: relative;min-height: 100px;"
                                                   (selectionChosenEmitter)="selectionChosen(item)">
                </cb-client-account-input-find-card>
            </ng-container>
            <ng-container *ngIf="type === INPUT_FIND_TYPE.Vendor">
                <cb-vendor-input-find-card class="flex-col md-33 lg-33 sm-50 xl-20 xs-100"
                                           *ngFor="let item of searchService?.searchResults$ | async"
                                           [dto]="$any(item)"
                                           style="position: relative;min-height: 100px;"
                                           (selectionChosenEmitter)="selectionChosen(item)">
                </cb-vendor-input-find-card>
            </ng-container>
            <ng-container *ngIf="type === INPUT_FIND_TYPE.SellingAgent">
                <cb-sellingagent-input-find-card class="flex-col md-33 lg-33 sm-50 xl-20 xs-100"
                                                 *ngFor="let item of searchService?.searchResults$ | async"
                                                 [dto]="$any(item)"
                                                 style="position: relative;min-height: 100px;"
                                                 (selectionChosenEmitter)="selectionChosen(item)">
                </cb-sellingagent-input-find-card>
            </ng-container>
        </div>
    </div>
    <cb-loading-spinner *ngIf="!searchService?.searchResultsLoaded"></cb-loading-spinner>
    <div actions>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
