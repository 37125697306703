<cb-dialog dialogHeading="Manage Rates">
    <form class="flex-col flex"
          #manageProductForm="ngForm">
        <div class="flex-row flex">
            <div class="cb-margin-right-3x flex-col flex-50">
                <cb-display-value-new label="Product ID"
                                      [value]="mappedItem.code"></cb-display-value-new>
                <cb-display-value-new label="Product"
                                      [value]="mappedItem.name"></cb-display-value-new>
                <cb-business-accounts-input-find-cards label="Supplier"
                                                       [(ngModel)]="supplier"
                                                       name="selectedSupplier"
                                                       [text]="supplier?.tradingName"
                                                       [required]="true"
                                                       (change)="supplierSelected()"
                                                       [removeable]="true">
                </cb-business-accounts-input-find-cards>
                <cb-input class="flex"
                          label="Supplier SKU"
                          [maxlength]="150"
                          [(ngModel)]="rate.sku"
                          name="sku"
                          *ngIf="rate">
                </cb-input>
                <cb-currency type="number"
                             label="Rate / {{unit}}"
                             [inclGst]="false"
                             name="rateValue"
                             [(ngModel)]="rate.value"
                             *ngIf="rate">
                </cb-currency>
                <cb-datepicker name="dateRateApplied"
                               [(ngModel)]="rate.appliedRatesFrom"
                               [required]="true"
                               label="Date Rate Applies"
                               *ngIf="rate"
                               pickerType="calendar">
                </cb-datepicker>
            </div>
            <div class="flex-col flex-50">
                <cb-select label="Region"
                           [(ngModel)]="regionSelected"
                           name="region"
                           [options]="regions"
                           [disabled]="regions.length === 0"
                           *ngIf="regions"
                           (change)="regionChanged()">
                 </cb-select>
                <cb-select label="District"
                           [(ngModel)]="districtSelected"
                           name="district"
                           [options]="districts"
                           [disabled]="regions.length === 0"
                           *ngIf="regions"
                           (change)="districtChanged()">
                 </cb-select>
                <cb-select label="Area"
                           [(ngModel)]="areaSelected"
                           name="area"
                           [options]="areas"
                           [disabled]="regions.length === 0"
                           (change)="setProjects(areaSelected)"
                           *ngIf="regions">
                 </cb-select>
                <cb-select label="Project"
                           [(ngModel)]="projectSelected"
                           name="project"
                           [options]="projects"
                           (change)="setReleases(projectSelected)"
                           [disabled]="!hasProjectsLoaded">
                 </cb-select>
                <cb-select label="Release Stage"
                           [(ngModel)]="releaseSelected"
                           name="releaseSelected"
                           [options]="releases"
                           [disabled]="!hasReleasesLoaded"
                           [required]="hasProjectSelected()">
                 </cb-select>
                <cb-checkbox label="Active"
                             name="active"
                             [(ngModel)]="rate.isActive"
                             *ngIf="rate">
                 </cb-checkbox>
                <cb-datepicker *ngIf="rate && !rate.isActive && rate.inactivatedDate"
                               name="dateInactivated"
                               [(ngModel)]="rate.inactivatedDate"
                               [required]="false"
                               [readonly]="true"
                               label="Inactivated Date"
                               pickerType="calendar">
                </cb-datepicker>
            </div>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="manageProductForm.pristine || manageProductForm.invalid"
                (click)="save()">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
