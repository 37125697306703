<cb-dialog [dialogHeading]="data.dialogHeading">
    <p *ngIf="data.message"
       class="cb-margin-bottom-2x start-center flex-row flex">
        <mat-icon class="cb-grey cb-margin-right">warning</mat-icon>
        &nbsp;
        <span [innerHTML]="data.message"></span>
    </p>
    <form #form="ngForm">
        <cb-text-area label="Reason"
                      name="reason"
                      [maxlength]="1000"
                      [required]="true"
                      [(ngModel)]="reason"></cb-text-area>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                [disabled]="!form.dirty || !form.valid || !canSave()"
                (click)="onSaveClicked()">{{data.action}}</button>
        <button mat-raised-button
                (click)="dialogRef.close()">Cancel</button>
    </div>
</cb-dialog>
