<div class="flex-row flex"
     style="overflow-x: auto">
    <div class="cb-padding-left-3x cb-padding-right-3x cb-padding-bottom-3x flex-col flex-100">
        <div class="start-center flex-row">
            <h3 class="mat-headline-6 flex">
                Global Settings
            </h3>
        </div>
        <div class="cb-table cb-margin-top">
            <mat-table [dataSource]="searchResults">
                <ng-container matColumnDef="settingsKey">
                    <mat-header-cell *matHeaderCellDef>
                        Settings Key
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{globalSettingsLogicService.getLabelFromEnum(element)}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef>
                        Description
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.description}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="value">
                    <mat-header-cell *matHeaderCellDef>
                        Value
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.value}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isActive">
                    <mat-header-cell *matHeaderCellDef>
                        Active
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-icon>
                            {{element.isActive ? "check_box" : "check_box_outline_blank"}}
                        </mat-icon>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions"
                              stickyEnd>
                    <mat-header-cell *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-icon-button
                                (click)="editItemClicked(element)"
                                *ngIf="!element.readOnly">
                            <mat-icon>
                                edit
                            </mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <cb-info-message *ngIf="searchResultsLoaded && searchResults.length === 0"
                             [message]="'There are no Global Settings to display'"
                             [icon]="'info_circle'"></cb-info-message>
            <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
        </div>
    </div>
</div>
