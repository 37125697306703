import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { INoteMappedItem } from '@app/logic/notes';

interface IData {
    note: INoteMappedItem;
    dialogHeading?: string;
}

@Component({
    selector: 'cb-note-dialog',
    templateUrl: './note-dialog.component.html',
    styleUrls: ['./note-dialog.component.scss']
})
export class NoteDialogComponent {
    public static readonly MIN_WIDTH = '400px';

    constructor(
        public readonly dialogRef: MatDialogRef<NoteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData
    ) { }

    public save(): void {
        this.data.note.saveNote()
            .subOnce(note => {
                this.dialogRef.close(this.data.note);
            });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
