<mat-card class="flex-col">
    <div class="card-area">
        <mat-card-header>
            <mat-card-title class="cb-margin-bottom-2x">
                {{ lead.name }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <section>
                <cb-display-value-new label="Lead Type"
                                      [value]="contractType">
                </cb-display-value-new>
                <cb-display-value-new label="Building Consultant"
                                      [value]="lead.buildingConsultant">
                </cb-display-value-new>
                <cb-display-value-new label="Status"
                                      [value]="lead.status">
                </cb-display-value-new>
                <cb-user-date-time-new label="Next Callback"
                                       [date]="lead.callbackDate">
                </cb-user-date-time-new>
            </section>
        </mat-card-content>
    </div>
    <mat-divider class="card-divider"></mat-divider>
    <mat-card-actions class="end-center flex-row">
        <button mat-stroked-button color="accent"
                (cbClick)="viewLead($event)">
            View Lead
        </button>
    </mat-card-actions>
</mat-card>
