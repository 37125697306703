import { Component } from '@angular/core';
import { BUSINESS_ACCOUNT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { ProductLogicService } from '@app/logic/products';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cb-product-export-tab',
    templateUrl: './product-export-tab.component.html',
    styleUrls: ['./product-export-tab.component.scss']
})
export class ProductExportTabComponent {
    public ratesSupplier: any;
    public supplierItemsSupplier: any;
    public id: number;
    public exportCurrentRates = true;
    public isLoading$ = new BehaviorSubject<boolean>(false);

    public rateExportBusinessAccountSearchParams = {
        s: [
            BUSINESS_ACCOUNT_STATUS_ENUM.Active,
            BUSINESS_ACCOUNT_STATUS_ENUM.Overridden,
            BUSINESS_ACCOUNT_STATUS_ENUM.Pending,
            BUSINESS_ACCOUNT_STATUS_ENUM.OnHold
        ]
    };

    public supplierItemsBusinessAccountSearchParams = {
        s: [
            BUSINESS_ACCOUNT_STATUS_ENUM.Active,
            BUSINESS_ACCOUNT_STATUS_ENUM.Overridden,
            BUSINESS_ACCOUNT_STATUS_ENUM.Pending,
            BUSINESS_ACCOUNT_STATUS_ENUM.OnHold,
            BUSINESS_ACCOUNT_STATUS_ENUM.Closed
        ]
    };

    constructor(
        protected readonly productsLogicService: ProductLogicService,
        private readonly dialog: MatDialog,
    ) {
    }

    public exportCatalogue(): void {
        this.productsLogicService.exportCatalogue().subOnce();
    }

    public exportSupplierRates(): void {
        const params = {
            currentRates: this.exportCurrentRates
        };
        let targetSupplierId;
        if (this.ratesSupplier) { targetSupplierId = this.ratesSupplier.id; }
        this.productsLogicService.exportSupplierRates(targetSupplierId, params).subOnce();
    }

    public exportAllRates(): void {
        this.productsLogicService.exportAllRates().subOnce();
    }

    public exportSupplierItems(): void {
        let targetSupplierId;
        if (this.supplierItemsSupplier) {
            targetSupplierId = this.supplierItemsSupplier.id;
            this.productsLogicService.exportSupplierItems(targetSupplierId).subOnce();
        }
    }

    public exportCreateBook(): void {
        this.productsLogicService.exportCreateBook().subOnce();
    }
}
