import { Component, Inject, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BuildStagesLogicService } from '@app/logic/build-stages';
import { PurchaseOrdersLogicService } from '@app/logic/purchase-orders';
import { NonWorkingDayLogicService } from '@app/logic/non-working-day';
import { UserSearchService } from '@app/core/services/search/user.search.service';
import { UsersLogicService } from '@app/logic/users';
import { SearchService } from '@app/core/services/search/base.search.service';

interface IData {
    searchService: SearchService;
    extraSearchParams: { [key: string]: any };
    dialogHeading: string;
    contentHeading: string;
    placeholder: string;
}

@Component({
    selector: 'cb-search-dialog',
    templateUrl: './search-dialog.component.html',
    styleUrls: ['./search-dialog.component.scss'],
    providers: [
        BuildStagesLogicService,
        PurchaseOrdersLogicService,
        NonWorkingDayLogicService,
        UserSearchService
    ]
})
export class SearchDialogComponent implements AfterViewInit {

    @ViewChild('infiniteScrollContainer', { static: true }) public infiniteScrollContainer: any;

    public readonly displayedColumns = [
        'userName',
        'name',
        'job',
        'email',
        'primaryBusinessEntity',
        'active',
        'actions'
    ];

    constructor(
        public readonly dialogRef: MatDialogRef<SearchDialogComponent>,
        public readonly userLogicService: UsersLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly dialog: MatDialog,
        private readonly cdRef: ChangeDetectorRef) { }

    public ngAfterViewInit(): void {
        this.data.searchService.infiniteScrollContainer = this.infiniteScrollContainer;
        this.data.searchService.extraSearchParams = this.data.extraSearchParams;
        this.data.searchService.logicService = this.userLogicService;
        if (this.data.searchService.isPagination) {
            this.data.searchService.nextPage$.next({ addPage: 1 });
        } else {
            this.data.searchService.doSearchIfNoScrollBar();
        }
        this.cdRef.detectChanges();
    }

    public itemSelected(result: any): void {
        const { id, name } = result;
        this.dialogRef.close({
            buildingConsultantId: id,
            buildingConsultantName: name
        });
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
