import {Component, Inject} from '@angular/core';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import {IProductDto, IProductMappedItem, ProductLogicService} from '@app/logic/products';
import {IProductLogicService} from '@app/logic/products/interfaces/i.product.logic.service';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    AddProductToTemplatedBundleDialogComponent
} from '@app/views/products/view-product/dialogs/add-product-to-templated-bundle-dialog/add-product-to-templated-bundle-dialog.component';
import {ToastService} from '@app/core/services/toast/toast.service';
import moment from 'moment';


@Component({
    selector: 'cb-purchase-order-usage-report-dialog',
    templateUrl: './purchase-order-usage-report-dialog.component.html',
    styleUrls: ['./purchase-order-usage-report-dialog.component.scss'],
    providers: [
        ProductLogicService
    ]
})
export class PurchaseOrderUsageReportDialogComponent extends BaseDialogFormViewDirective<IProductDto, IProductMappedItem, IProductLogicService> {
    public static readonly MIN_WIDTH = '600px';

    public productId: number;
    public purchaseOrderDateFrom;
    public purchaseOrderDateTo;
    constructor(
        public readonly dialogRef: MatDialogRef<AddProductToTemplatedBundleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: any,
        @Inject(ToastService) public readonly toastService: ToastService,
        protected readonly productLogicService: ProductLogicService) {
        super(dialogRef, toastService);
        this.productId = data.productId;
    }

    public downloadUsageReport(): any {
        this.productLogicService.downloadPurchaseOrdersUsageReport(
            this.productId,
            this.purchaseOrderDateFrom ? encodeURIComponent(moment(this.purchaseOrderDateFrom).format()) : undefined,
            this.purchaseOrderDateTo ? encodeURIComponent(moment(this.purchaseOrderDateTo).format()) : undefined
        ).subOnce({
            next: result => {
                if (result) {
                    this.cancel();
                }
            }
        });
    }
}
