/* eslint-disable max-len */
import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import { LotSpecScheduleItemLogicService } from '@app/logic/lot-spec-schedule-item/lot-spec-schedule-item-logic.service';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { COST_TYPE_ENUM, ILotSpecScheduleItemDto, OFFERING_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { LotSpecScheduleItemDialogComponent } from '../lot-spec-schedule-item-dialog/lot-spec-schedule-item-dialog.component';
import { LotSpecItemUIActions } from '../lot-spec-item-card/lot-spec-item-ui-actions';
import { FormMode } from '@app/shared/enums/form';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LotSpecScheduleQuoteRefsDialogComponent } from '../lot-spec-schedule-quote-refs-dialog/lot-spec-schedule-quote-refs-dialog.component';
import { LotSpecificationService } from '../services/lot-specification.service';
import { QuoteLineLogicService } from '@app/logic/quote/quote-line/quote-line.logic.service';
import { ILotSpecScheduleItemMappedItem, LotSpecScheduleItemMappedItem } from '@app/logic/lot-spec-schedule-item';
import { IHouseAreaWithOneMappedItem } from '../lot-spec-schedule-items-table/lot-spec-schedule-items-table.component';
import { MappedItemUtils } from '@app/shared/utils/mapped-item.util';
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'cb-lot-spec-schedule-items-table-row',
    templateUrl: './lot-spec-schedule-items-table-row.component.html',
    styleUrls: ['./lot-spec-schedule-items-table-row.component.scss'],
    providers: [
        LotSpecItemUIActions
    ]
})
export class LotSpecScheduleItemsTableRowComponent implements AfterViewInit {

    public readonly costTypeEnum = COST_TYPE_ENUM;
    public readonly offeringStatusEnum = OFFERING_STATUS_ENUM;

    @Input() public expandedParents = {} as { [parentId: number]: boolean };
    @Input() public isExpandCollapseAllClicked:BehaviorSubject<boolean> = new BehaviorSubject(false);
    @Output() public expandedParentsChange = new EventEmitter<{ [parentId: number]: boolean }>();


    @Input() public readonly parentItem: ILotSpecScheduleItemDto;
    @Input() public readonly item: ILotSpecScheduleItemMappedItem;
    @Input() public readonly lotId: number;
    @Input() public readonly houseAreaId: number;

    @Input() public isSkinnyView: boolean;

    constructor(
        public readonly uiActions: LotSpecItemUIActions,
        public readonly cbDialog: CbDialogService,
        public readonly lotSpecScheduleItemLogic: LotSpecScheduleItemLogicService,
        public readonly quoteLineLogic: QuoteLineLogicService,
        public readonly lotSpecificationService: LotSpecificationService,
        private readonly snackBar: MatSnackBar) { }

    public ngAfterViewInit(): void {
        this.isExpandCollapseAllClicked.subscribe(expanded => {this.expandedParents[this.item.id] = expanded})
        if (this.item.hasIncompleteChildren) {
            this.expandedParents[this.item.id] = true;
        }
    }

    public quoteRefsDialog(): void {
        this.lotSpecScheduleItemLogic.$getMappedItem(this.item.id, this.lotId).subOnce(mappedItem => {
            this.cbDialog.open(
                LotSpecScheduleQuoteRefsDialogComponent,
                {
                    minWidth: '75%',
                    data: {
                        lotSpecItem: mappedItem,
                    }
                }
            );
        });
    }

    public getQRef(): string {
        if (this.item.quoteLineId > 0) {
            return 'Q' + this.item.quoteLineId;
        } else {
            return '';
        }
    }

    public editItem(): void {

        this.lotSpecScheduleItemLogic.$getMappedItem(this.item.id, this.lotId).subOnce(mappedItem => {
            this.cbDialog.open(
                LotSpecScheduleItemDialogComponent,
                {
                    data:
                    {
                        mappedItem,
                        lotId: this.lotId,
                        formMode: FormMode.Edit,
                        isLotSpecLocked$: this.item.getIsLotSpecLocked()
                    },
                }
            ).afterClosed().subOnce((result) => {
                if (result) {
                    this._mapResultToMappedItem(result);
                    if (this.item.parentLotSpecScheduleItemId) {
                        const houseAreaWithOneMappedItem: IHouseAreaWithOneMappedItem = {
                            houseAreaId: this.houseAreaId,
                            mappedItem: this.item,
                            parentMappedItemId: this.item.parentLotSpecScheduleItemId
                        };
                        this.lotSpecificationService.childLotScheduleItemEdited.next(houseAreaWithOneMappedItem);
                    }
                }
            });
        });
    }

    public abandonQuoteLine(): void {

        const block = this.cbDialog.block('Abandoning Quote Line...');

        // abandon quoteline
        this.quoteLineLogic.abandonQuoteLine(this.item.quoteLineId).subOnce({
            next: quoteLineAbandoned => {
                if (quoteLineAbandoned) {

                    // refresh sim item
                    this.lotSpecScheduleItemLogic.$getItem(this.item.id, this.lotId).subOnce({
                        next: this._mapResultToMappedItem
                    });
                } else {
                    this.openSnackBar('Failed to abandon quote line', 'Ok');
                }
                block.close();
            },
            error: () => {
                this.openSnackBar('Error occured when attempting to abandon quote line', 'Ok');
                block.close();
            }
        });
    }

    public removeItem(): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete Item from the Lot Specification',
            message: 'Are you sure you want to delete this item for the Lot Specification?\n\nPlease Note\nAny child items will be removed with the parent item AND\nActive quote lines associated with removed items will be abandoned, and quotes with no active quote lines will be abandoned.',
            confirmed: () => {
                const block = this.cbDialog.block('Removing Item...');
                this.lotSpecScheduleItemLogic.delete(this.item, this.lotId)
                    .subOnce({
                        next: result => {
                            block.close();
                            if (this.parentItem === undefined) {
                                this.lotSpecificationService.lotScheduleParentItemRemoved.next(this.item.id);
                            } else {
                                this.lotSpecificationService.lotScheduleChildItemRemoved.next({ parentId: this.parentItem.id, childId: this.item.id, });
                            }
                        },
                        error: () => {
                            this.openSnackBar('Failed to remove item', 'Ok');
                            block.close();
                        }
                    });
            }
        });
    }

    private openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['snackbar']
        });
    }

    private _mapResultToMappedItem = (result: ILotSpecScheduleItemDto): void => {
        if (result) {
            const mappedResult = MappedItemUtils.isMappedItem(result) ? result : this.lotSpecScheduleItemLogic
                .$createMappedItem(result, LotSpecScheduleItemMappedItem);
            Object.assign(this.item, {
                ...mappedResult,
                childItems: this.item.childItems.map(
                    childItem => MappedItemUtils.isMappedItem(childItem) ? childItem : this.lotSpecScheduleItemLogic
                        .$createMappedItem(childItem, LotSpecScheduleItemMappedItem)),
            });
        }
    };
}
