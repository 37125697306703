import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBankAccountMappedItem } from '@app/logic/bank-accounts/interfaces/i.bank-account.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IBankAccountsLogicService } from '@app/logic/bank-accounts/interfaces/i.bank-accounts.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { BankAccountMappedItem } from '@app/logic/bank-accounts/bank-account.mapped';
import { BusinessEntitiesLogicService, IBusinessEntityDto } from '@app/logic/business-entity';
import { IBankAccountDetailsDto, IIdAndLabelDto } from '@classictechsolutions/hubapi-transpiled-enums';
import { WHOLE_OPTION_VALUE_PROP } from '@app/shared/utils/select.util';

interface IData {
    mappedItem: BankAccountMappedItem;
}

@Component({
    selector: 'cb-bank-account-dialog',
    templateUrl: './bank-account-dialog.component.html',
    styleUrls: ['./bank-account-dialog.component.scss']
})
export class BankAccountDialogComponent extends BaseDialogFormViewDirective<IBankAccountDetailsDto, IBankAccountMappedItem, IBankAccountsLogicService> {
    public get mappedItem(): BankAccountMappedItem {
        return this.data.mappedItem;
    }
    public businessEntities: IBusinessEntityDto[];
    public businessEntityOptions: IIdAndLabelDto[];
    protected readonly WHOLE_OPTION_VALUE_PROP = WHOLE_OPTION_VALUE_PROP;

    constructor(
        public readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<BankAccountDialogComponent>,
        private readonly businessEntitiesLogicService: BusinessEntitiesLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastService);

        this.businessEntitiesLogicService.$getList().subOnce(entities => {
            this.businessEntities = entities.filter(entity => entity.isActive);
            this.businessEntityOptions = this.businessEntities.map(entity => {
                return { id: entity.id, label: entity.name } as IIdAndLabelDto;
            })
        });
    }

    public optionLabel = (option: IIdAndLabelDto): string => {
        return option ? option.label : '';
    };
}
