<div class="cb-margin-bottom cb-margin-top end-center flex-row">
    <span class="cb-margin-left start-center flex-row flex"
          *ngIf="!questions?.length">
        <mat-icon color="accent">info</mat-icon>
        <span class="mat-body-2">&nbsp;There are no Questions for this item</span>
    </span>
    <button mat-raised-button
            class="cb-margin-right"
            color="primary"
            (click)="createQuestion()"
            [disabled]="!edit">
        New Question
    </button>
</div>
<div class="flex-col">
    <mat-divider></mat-divider>
    <cb-generic-question-item *ngFor="let question of questions | cbSortByPipe: 'questionNumber' : 'desc'"
                              [ngClass]="{ 'highlighted-question': question.questionNumber === questionNumber }"
                              [genericQuestion]="question"
                              [questionNumber]="questionNumber"
                              [childItemLabel]="childItemLabel"
                              [childItems]="childItems"
                              [entityId]="entityId"
                              [childSystemArea]="childSystemArea"
                              [systemArea]="systemArea"
                              [edit]="edit"
                              class="cb-margin-bottom-2x"></cb-generic-question-item>
</div>
