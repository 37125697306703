<cb-dialog [dialogHeading]="(mappedItem.id > 0 ? 'Edit' : 'Create') + ' Line'">
    <form #editLineItemForm="ngForm">
        <div class="cb-margin flex-row">
            <div class="flex-col flex"
                 *ngIf="!mappedItem.isNoteLine">
                <div class="flex-col">
                    <div class="start-center flex-row">
                        <cb-product-autocomplete-scroller class="cb-margin-top flex"
                                                          #autocompleteControl
                                                          label="Product"
                                                          name="product"
                                                          [displayCategories]="true"
                                                          [productType]="PRODUCT_SEARCH_TYPE_ENUM.All"
                                                          [(ngModel)]="selectedProduct"
                                                          (optionSelected)="productUpdate($event)"
                                                          (searchTextChanged)="updateLineItemDescription($event)"
                                                          [ignoreDirty]="true"
                                                          [hideCompositeItems]="true"
                                                          [required]="true">
                        </cb-product-autocomplete-scroller>
                    </div>
                    <span class="mat-caption"
                          style="margin: -16px 0 16px 4px">{{mappedItem.offeringId > 0 ? 'Product Selected' : 'Free Text'}}</span>
                </div>
                <cb-input label="Code"
                          name="code"
                          [(ngModel)]="mappedItem.productCode"
                          [disabled]="mappedItem?.offeringId != null"
                          [required]="true"></cb-input>
                <cb-select label="UOM"
                           name="uom"
                           [(ngModel)]="mappedItem.uomId"
                           (change)="uomChanged()"
                           [disabled]="mappedItem?.offeringId != null"
                           [options]="uomList"></cb-select>
                <cb-input type="number"
                          label="Quantity"
                          [required]="true"
                          [(ngModel)]="mappedItem.quantity"
                          name="quantity"
                          [suffix]="mappedItem.uom"
                          allow-cents="false"></cb-input>
                <cb-currency label="Rate"
                             name="rate"
                             [(ngModel)]="mappedItem.rate"
                             [required]="true"
                             [disabled]="mappedItem.costToBeDetermined"
                             [inclGst]="false"></cb-currency>
                <cb-checkbox label="Unknown Rate"
                             name="costToBeDetermined"
                             [(ngModel)]="mappedItem.costToBeDetermined"
                             (change)="unknownRateChanged()"></cb-checkbox>
                <cb-text-area label="Internal Notes"
                              name="internalNotes"
                              [maxlength]="1024"
                              [(ngModel)]="mappedItem.internalNotes"></cb-text-area>
                <cb-checkbox label="GST Applies"
                             name="gstApplies"
                             [(ngModel)]="mappedItem.gstApplies"></cb-checkbox>
            </div>
            <div class="flex-col flex"
                 *ngIf="mappedItem.isNoteLine">
                <cb-text-area label="Note"
                              name="description"
                              [(ngModel)]="mappedItem.description"
                              [maxlength]="1024"
                              [required]="true"></cb-text-area>
            </div>
        </div>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="editLineItemForm.pristine || editLineItemForm.invalid"
                (cbClick)="saveDialog()"
                [clickLimit]="1">
            Save
        </button>
        <button mat-raised-button
                (click)="cancelDialog()">Cancel</button>
    </div>
</cb-dialog>
