import { Observable } from 'rxjs';
import { IGenericQuestionDto, SYSTEM_AREA_ENUM, SystemAreaEnumId } from '@classictechsolutions/hubapi-transpiled-enums';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IGenericQuestionMappedItem, IGenericQuestionsLogicService } from '@app/logic/generic-questions';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';

interface IData {
    childItemLabel: string;
    childItems: any[];
    childSystemArea: SystemAreaEnumId;
    entityId: number;
    readonly: boolean;
    genericQuestion: IGenericQuestionMappedItem;
    lotId: number;
}

@Component({
    selector: 'cb-generic-question-response-dialog',
    templateUrl: './generic-question-response-dialog.component.html',
    styleUrls: ['./generic-question-response-dialog.component.scss']
})
export class GenericQuestionResponseDialogComponent
    extends BaseDialogFormViewDirective<IGenericQuestionDto, IGenericQuestionMappedItem, IGenericQuestionsLogicService>
    implements OnInit {
    public static readonly MIN_WIDTH = '40%';

    public get mappedItem(): IGenericQuestionMappedItem {
        return this.data.genericQuestion;
    }

    public readonly SYSTEM_AREA_ENUM = SYSTEM_AREA_ENUM;

    constructor(
        public readonly dialogRef: MatDialogRef<GenericQuestionResponseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService) {
        super(dialogRef, toastService);
    }



    public saveMethod(): Observable<IGenericQuestionDto> {
        this.mappedItem.isAwaitingResponse = false;
        return this.mappedItem.$save();
    }
}
