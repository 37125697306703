import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseFormComponentDirective, getBaseFormComponentDirectiveProvider } from '../base-form-component';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { SelectItemsService } from './select-items-service';
import { isEmpty } from 'lodash';

interface IItemDto { id: number;[key: string]: any }

@Component({
    selector: 'cb-select-items-dialog',
    templateUrl: './select-items-dialog.component.html',
    styleUrls: ['./select-items-dialog.component.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(SelectItemsDialogComponent),
    ],
    viewProviders: [
        provideParentForm(),
    ]
})
export class SelectItemsDialogComponent<ItemType extends { id: any }> extends BaseFormComponentDirective implements OnInit {
    @Input() public newEmptyItem: IItemDto;
    @Input() public columnsValues = [];
    @Input() public columnsNames: string[] = []
    constructor(
        public readonly selectItemsService: SelectItemsService,
        public readonly dialogRef: MatDialog) {
        super();
        this.selectItemsService.handleNewItem = this.handleNewItem;
        this.selectItemsService.handleEditItem = this.handleEditItem;
    }

    public ngOnInit(): void {}

    getColumnValue(item: any, column: string): any {
        return (item as any)[column];
    }

    public isEmpty(obj: any): boolean {
        return Object.keys(obj || {}).length === 0;
    }
    public deleteItemClicked = (item: ItemType): void => {
        this.value = this.value.filter(x => {
            return isEmpty(x) || (x.id !== item.id);
        });
    };

    public newItemClicked = (): void => {
        const copy = JSON.parse(JSON.stringify(this.newEmptyItem));
        this.selectItemsService.newItemClicked(copy);
    };

    public editItemClicked = (item: ItemType): void => {
        this.selectItemsService.editItemClicked(item);
    };

    public handleNewItem = (item: ItemType): void => {
        if (item) {
            this.value.splice(this.value.length - 1, 0, item);
        }
    };

    public handleEditItem = (item: ItemType): void => {
        if (item) {
            this.value.forEach(itm => {
                if (itm.id === item.id) {
                    Object.assign(itm, item);
                }
            });
        }
    };
}
