<mat-accordion>
    <mat-expansion-panel *ngFor="let note of getSortedNotes(notes)">
        <mat-expansion-panel-header class="panel-heading">
            <mat-panel-title class="start-center flex-row">
                <mat-icon *ngIf="!note.isImportant">info_outline</mat-icon>
                <mat-icon color="accent"
                          *ngIf="note.isImportant">error</mat-icon>
                <div class="cb-margin-left-2x flex-col flex">
                    <h1 class="mat-body-strong">{{note.subject}}</h1>
                    <h2 class="mat-body-2">{{note.createdByName}}, {{relativeDate(note.createdDate)}}</h2>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="flex-col">
            <div class="cb-margin-right-3x flex-col flex">
                <cb-display-value-new label="Note"
                                      [value]="note.body"></cb-display-value-new>
                <cb-display-value-new label="Created">
                    {{note.createdByName}} on {{note.createdDate | date}}
                </cb-display-value-new>
                <cb-display-value-new label="Update">
                    {{note.updatedByName}} on {{note.updatedDate | date}}
                </cb-display-value-new>
            </div>
            <mat-divider class="divider"></mat-divider>
            <div class="footer-buttons end-center flex-row">
                <button mat-stroked-button
                        (click)="deleteNote(note)"
                        *ngIf="canDelete.value">
                    <mat-icon>delete</mat-icon>
                    &nbsp;Delete
                </button>
                <button mat-stroked-button
                        color="accent"
                        (click)="editNote(note)"
                        *ngIf="canEdit.value">
                    <mat-icon>mode_edit</mat-icon>
                    &nbsp;Edit
                </button>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<cb-info-message *ngIf="noNotes.value"
                 message="There were no Notes found">
</cb-info-message>
<div class="start-center flex-row"
     *ngIf="!notesLoaded">
    <cb-loading-spinner></cb-loading-spinner>
</div>
