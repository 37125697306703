import { Component, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IStandardPlanCategoryDto} from '@classictechsolutions/hubapi-transpiled-enums';
import { ICategoryMappedItem } from '@app/logic/categories/interfaces/i.category.mapped';
import { ICategoriesLogicService } from '@app/logic/categories/interfaces/i.category.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';

@Component({
    selector: 'cb-category-dialog',
    templateUrl: './category-dialog.component.html',
    styleUrls: ['./category-dialog.component.scss']
})
export class CategoryDialogComponent extends BaseDialogFormViewDirective<IStandardPlanCategoryDto, ICategoryMappedItem, ICategoriesLogicService> {

    constructor(
        public readonly dialogRef: MatDialogRef<CategoryDialogComponent>,
        public toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public readonly data: { mappedItem: ICategoryMappedItem }
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
    }
}
