import { ClientSalePermissions } from '@app/core/permissions';
import { Component, Input } from '@angular/core';
import { BaseClientSaleCardDirective } from '../base-client-sale-card';
import { MatDialog } from '@angular/material/dialog';
import { SetUnconditionalDialogComponent } from '../../dialogs/set-unconditonal-dialog/set-unconditional-dialog.component';
import { ClientSaleDocumentsService } from '../../documents-service/client-sale-documents.service';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';
import { getDocumentUploadLabel } from '../../document-upload-label.util';
import { IClientSaleMappedItem } from '@app/logic/client-sale/interfaces/i.client-sale.mapped';

@Component({
    selector: 'cb-client-sale-unconditional-card',
    templateUrl: './unconditional-card.component.html',
    styleUrls: ['./unconditional-card.component.scss']
})
export class ClientSaleUnconditionalCardComponent extends BaseClientSaleCardDirective {

    @Input() public readonly stepNumber: number;

    @Input() public readonly clientSale: IClientSaleMappedItem;

    public date: Date;

    public get isComplete(): boolean {
        return this.clientSale?.stepCompletion?.unconditional;
    }

    public readonly uploadLabels = new ComputedProperty(() => {
        return {
            spAgreement: getDocumentUploadLabel(
                this.clientSale?.documentInfo?.spAgreementUploadDate,
                this.clientSaleDocuments?.documents?.spAgreement?.entity?.documentStatus
            ),
        };
    });

    constructor(
        public readonly matDialog: MatDialog,
        public readonly permissions: ClientSalePermissions,
        public readonly clientSaleDocuments: ClientSaleDocumentsService,
    ) {
        super(clientSaleDocuments);
    }

    public recomputeIconProps = (): void => {
        super.recomputeIconProps();
        this.uploadLabels.recompute();
    };

    public setUnconditional(): void {
        if (!this.permissions.canEdit()) {
            return;
        }
        this.matDialog
            .open(SetUnconditionalDialogComponent, {
                data: { clientSale: this.clientSale.$clone() },
                panelClass: 'cb-dialog-container',
                minWidth: 400
            })
            .afterClosed()
            .subOnce({ next: this.recomputeIconProps });
    }
}
