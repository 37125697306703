<form class="flex-col flex-fill"
      #form="ngForm"
      style="position: relative; max-width: 280px"
      *cbWaitFor="projectDto">
    <button mat-mini-fab
            color="accent"
            class="collapse-button"
            (click)="collapsed$.next(!collapsed$.value)"
            [matTooltip]="collapsed$.value ? 'Expand' : 'Collapse'">
        <mat-icon>
            {{ collapsed$.value ? 'chevron_left' : 'chevron_right ' }}
        </mat-icon>
    </button>
    <mat-card class="collapsible-card flex-col">
        <mat-card-content>
            <div class="flex-row"
                 *ngIf="(collapsed$ | async)">
                <h1 class="mat-headline-6 collapsed-title">Release Stage Information</h1>
            </div>
            <div class="flex-col flex"
                 *ngIf="!(collapsed$ | async)">
                <h4 class="mat-headline-6">{{ mappedItem.releaseId }}</h4>
                <h4 class="mat-subtitle-2 cb-margin-bottom-nil">{{ mappedItem.name }}</h4>
                <div style="position: relative"
                     class="cb-margin-bottom-2x">
                    <mat-divider></mat-divider>
                </div>
                <cb-display-value-new label="Release Status"
                                      [value]="PROJECT_RELEASE_STATUS_ENUM[mappedItem.releaseStatus]">
                </cb-display-value-new>
                <cb-display-value-new label="Project"
                                      [value]="projectDto.projectName"
                                      [showBtn]="true"
                                      (click)="viewProject()">
                </cb-display-value-new>
                <cb-display-value-new label="Project Principal"
                                      [value]="projectDto.accountOwner.label">
                </cb-display-value-new>
                <cb-display-value-new label="Business Entity"
                                      [value]="projectDto.businessEntity.label">
                </cb-display-value-new>
                <cb-display-value-new label="Vendor"
                                      *ngIf="mappedItem.type === PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand && mappedItem.vendorBusinessEntity"
                                      [value]="mappedItem.vendorBusinessEntity.label">
                </cb-display-value-new>
                <cb-display-value-new label="Vendor"
                                      *ngIf="mappedItem.type === PROJECT_RELEASE_STAGE_TYPE_ENUM.HouseAndLand && mappedItem.vendorClientAccount"
                                      [value]="mappedItem.vendorClientAccount.label">
                </cb-display-value-new>
                <button mat-raised-button
                        class="cb-margin-bottom-2x"
                        color="primary"
                        (cbClick)="editProjectReleaseStage()"
                        [disabled]="mappedItem.releaseStatus === PROJECT_RELEASE_STATUS_ENUM.Cancelled"
                        *ngIf="projectReleasePermissions.canEdit()">
                    Edit Release
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</form>
