<div class="content container">
    <div class="header">
        <div class="save-as-tab center-center flex-row">
            <mat-form-field appearance="fill" class="name-field">
                <input matInput style="text-align: center;" [(ngModel)]="viewModel.name" />
            </mat-form-field>
            <div class="center-center flex-row default-checkbox">
                <mat-checkbox [(ngModel)]="viewModel.isDefault">
                    Default
                </mat-checkbox>
            </div>
        </div>

        <button mat-raised-button color="primary" (click)="backToView()" class="cancel-button">
            Cancel
        </button>

        <button mat-raised-button color="primary" (click)="saveView()" class="save-button">
            Save
        </button>
    </div>
    <mat-divider [inset]="true"> </mat-divider>
    <mat-tab-group *ngIf="selectedView || isCreatingNewView">
        <mat-tab label="Column Management">
            <div
                class="columns-tab center-center flex flex-col"
            >
                <div class="columns-container"
                     [dragula]="'COLUMNS_BAG'"
                     [dragulaModel]="combinedColumns">
                    <div
                        class="column-item"
                        *ngFor="let col of combinedColumns; let i = index"
                    >
                        <div
                            class="drag-item flex space-between"
                            [ngClass]="{ 'selected': col.active }"
                        >
                            <div class="start-center flex-70">
                                <span class="order-badge">{{ i + 1 }}</span>

                                <mat-checkbox
                                    [(ngModel)]="col.active"
                                    matTooltip = "active"
                                ></mat-checkbox>
                                <div class="flex">
                                    {{ WipHelper.formatColumnName(col.name) }}
                                </div>
                            </div>

                            <div class="end-center flex-20" matTooltip = "sticky">
                                <mat-icon fontSet="material-symbols-outlined">
                                    push_pin
                                </mat-icon>

                                <mat-checkbox [(ngModel)]="col.sticky">
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Filters">
            <wip-filters [filters]="viewModel.filters" [displayedColumns]="displayedColumns"></wip-filters>
        </mat-tab>
    </mat-tab-group>
</div>
