import { MatButtonModule } from '@angular/material/button';
import { DragulaModule } from 'ng2-dragula';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LotUnitsListComponent } from './lot-units-list/lot-units-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { CbCurrencyModule } from '@app/shared/pipe/currency/currency.module';
import { CbLoadingSpinnerModule, CbInfoMessageModule } from 'cb-hub-lib';
import { MatSlideToggleModule as MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
    declarations: [LotUnitsListComponent],
    exports: [
        LotUnitsListComponent
    ],
    imports: [
        CommonModule,
        DragulaModule,
        MatTableModule,
        MatIconModule,
        CbCurrencyModule,
        MatButtonModule,
        CbLoadingSpinnerModule,
        CbInfoMessageModule,
        MatSlideToggleModule,
    ]
})

export class LotUnitsModule { }
