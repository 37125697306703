import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessAccountMappedItem } from '@app/logic/business-accounts';
import { BUSINESS_ACCOUNT_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { cloneDeep } from 'lodash';

interface IData {
    account: BusinessAccountMappedItem;
}

@Component({
    selector: 'cb-business-account-close-account-dialog',
    templateUrl: './business-account-close-account-dialog.component.html',
    styleUrls: ['./business-account-close-account-dialog.component.scss']
})
export class BusinessAccountCloseAccountDialogComponent {
    public static readonly MIN_WIDTH = '60%';

    public description = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public readonly dialogRef: MatDialogRef<BusinessAccountCloseAccountDialogComponent>,
    ) {
    }

    public canSave(): boolean {
        return !!this.data?.account?.id && !!this.description;
    }

    public onSaveClicked(): void {
        if (this.canSave()) {
            const mappedItem = cloneDeep(this.data.account);
            mappedItem.status = BUSINESS_ACCOUNT_STATUS_ENUM.Closed;
            mappedItem.reason = this.description;
            mappedItem.$save().subOnce(() => this.dialogRef.close());
        }
    }
}
