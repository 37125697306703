<cb-dialog>
    <form class="flex-col flex"
          #externalSystemFieldForm="ngForm">
        <cb-input type="text"
                  name="fieldName"
                  label="External Field Name"
                  [required]="true"
                  [maxlength]="200"
                  [(ngModel)]="mappedItem.fieldName"></cb-input>
        <cb-select [required]="true"
                   name="fieldTypes"
                   label="Field Type"
                   [options]="fieldTypes"
                   [(ngModel)]="mappedItem.fieldTypeId">
        </cb-select>
        <cb-input type="number"
                  name="fieldLength"
                  label="Field Length"
                  [required]="true"
                  [pattern]="positiveIntegerPattern"
                  [(ngModel)]="mappedItem.fieldLength"></cb-input>
        <cb-checkbox label="Active"
                     name="isActive"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (cbClick)="save()"
                [disabled]="!externalSystemFieldForm.dirty || !externalSystemFieldForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
