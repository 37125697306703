<cb-dialog dialogHeading="Reassign Council RFI">
    <form class="flex-col flex"
          #reassignForm="ngForm">
        <ng-container *cbWaitFor="mappedItem && teams">
            <label>Reassign to</label>
            <cb-select label="Team"
                       name="team"
                       [(ngModel)]="mappedItem.assignedToTeamId"
                       (change)="teamChanged($event)"
                       [options]="teams"
                       [required]="true"
                       valueProp="id"></cb-select>
            <cb-user-autocomplete-scroller class="flex"
                                           label="User"
                                           name="assignTo"
                                           [(ngModel)]="assignedToUser"
                                           [requireMatch]="true"
                                           [matchProps]="['id']"
                                           [active]="true"
                                           [team]="[selectedTeam?.key]"
                                           [disabled]="!mappedItem.assignedToTeamId || !selectedTeam || !selectedTeam?.key"
                                           [required]=true></cb-user-autocomplete-scroller>
        </ng-container>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="saveDisabled(reassignForm)"
                (cbClick)="save()"
                [clickLimit]="1">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
