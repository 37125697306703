import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PermissionsPermissions } from '@app/core/permissions';
import { FieldValidationItem } from '@app/core/services/field-validation/field.validation.item';
import { ProjectReleaseLotFieldValidatorService } from '@app/core/services/field-validation/project-release-lot-field-validator/project-release-lot-field-validator.service';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotMappedItem } from '@app/logic/lots';
import { ILotsLogicService } from '@app/logic/lots/interfaces/i.lots.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { FormMode } from '@app/shared/enums/form';
import { IManageDialogData } from '@app/shared/interfaces/i.manage-dialog-data';
import { COMPASS_DIRECTION_TYPE_ENUM, IBuildTypeDto, ILotDto, LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-project-release-stage-edit-lot-dialog',
    templateUrl: './edit-lot-dialog.component.html',
    styleUrls: ['./edit-lot-dialog.component.scss']
})
export class ProjectReleaseStageEditLotDialogComponent extends BaseDialogFormViewDirective<ILotDto, ILotMappedItem, ILotsLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '80%';
    public buildTypes: IBuildTypeDto[];
    public COMPASS_DIRECTION_TYPE_ENUM = COMPASS_DIRECTION_TYPE_ENUM;
    public LOT_CONTRACT_TYPE_ENUM = LOT_CONTRACT_TYPE_ENUM;
    public isReadonly: boolean;

    public bathroomsField: FieldValidationItem;
    public bedroomsField: FieldValidationItem;
    public buildTypeField: FieldValidationItem;
    public floorAreaField: FieldValidationItem;
    public garagesField: FieldValidationItem;
    public carparksField: FieldValidationItem;
    public landAreaField: FieldValidationItem;
    public landPriceField: FieldValidationItem;
    public livingRoomsField: FieldValidationItem;
    public lotAddressField: FieldValidationItem;
    public lotNumberField: FieldValidationItem;
    public jobNumberField: FieldValidationItem;
    public sellPriceField: FieldValidationItem;
    public roadDirectionField: FieldValidationItem;
    public lotHoldingDepositField: FieldValidationItem;
    public hasExternalLandVendorField: FieldValidationItem;
    public lotPurchasePriceField: FieldValidationItem;
    public lotDepositField: FieldValidationItem;

    constructor(
        private projectReleaseLotFieldValidatorService: ProjectReleaseLotFieldValidatorService,
        private readonly toastService: ToastService,
        private readonly navigationService: NavigationService,
        public securityPermissions: PermissionsPermissions,
        @Inject(LookupService) public readonly lookupService: LookupService,
        public readonly dialogRef: MatDialogRef<ProjectReleaseStageEditLotDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<ILotMappedItem> & { isReadonly: boolean },
        public readonly cbDialog: CbDialogService
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.isReadonly = data.isReadonly;

        if (!this.mappedItem?.$id) {
            this.formMode = FormMode.Add;
        } else {
            this.formMode = FormMode.Edit;
        }

        if (data.isReadonly) {
            this.formMode = FormMode.View;
        }
    }

    public getDialogHeading(): string {
        switch (this.formMode) {
            case FormMode.View:
                return 'View Lot Details';
            case FormMode.Add:
                return 'Add Lot';
            case FormMode.Edit:
                return 'Edit Lot';
        }
    }

    public ngOnInit(): void {
        this.lookupService.BUILD_TYPES.toLookup().$promise.then((result: any) => {
            this.buildTypes = (result as IBuildTypeDto[]);
        });
        this.projectReleaseLotFieldValidatorService.dto = this.mappedItem.$getMappedDtoItem();
        this.populateFieldValidationItems();
    }

    public buildTypeChanged = () => {
        this.mappedItem.buildType = this.buildTypes.find(type => type.id === this.mappedItem.buildTypeId);
    };

    private populateFieldValidationItems(): void {
        this.projectReleaseLotFieldValidatorService.hasPriceRevisions = this.mappedItem.hasPriceRevisions;

        this.bathroomsField = this.projectReleaseLotFieldValidatorService.amenitiesNoBathrooms();
        this.bathroomsField.isEditable = this.bathroomsField.isEditable;

        this.bedroomsField = this.projectReleaseLotFieldValidatorService.amenitiesNoBedrooms();
        this.bedroomsField.isEditable = this.bedroomsField.isEditable;

        this.buildTypeField = this.projectReleaseLotFieldValidatorService.buildType();
        this.buildTypeField.isEditable = this.buildTypeField.isEditable;

        this.floorAreaField = this.projectReleaseLotFieldValidatorService.amenitiesFloorArea();
        this.floorAreaField.isEditable = this.floorAreaField.isEditable;

        this.garagesField = this.projectReleaseLotFieldValidatorService.amenitiesNoGarages();
        this.garagesField.isEditable = this.garagesField.isEditable;

        this.carparksField = this.projectReleaseLotFieldValidatorService.amenitiesNoCarparks();
        this.carparksField.isEditable = this.carparksField.isEditable;

        this.landAreaField = this.projectReleaseLotFieldValidatorService.amenitiesLandArea();
        this.landAreaField.isEditable = this.landAreaField.isEditable;

        this.landPriceField = this.projectReleaseLotFieldValidatorService.landPrice();
        this.landPriceField.isEditable = this.landPriceField.isEditable;

        this.livingRoomsField = this.projectReleaseLotFieldValidatorService.amenitiesNoLivingRooms();
        this.livingRoomsField.isEditable = this.livingRoomsField.isEditable;

        this.lotAddressField = this.projectReleaseLotFieldValidatorService.lotAddress();
        this.lotAddressField.isEditable = this.lotAddressField.isEditable;

        this.lotNumberField = this.projectReleaseLotFieldValidatorService.lotNumber();
        this.lotNumberField.isEditable = this.lotNumberField.isEditable;

        this.jobNumberField = this.projectReleaseLotFieldValidatorService.jobNumber();
        this.jobNumberField.isEditable = this.jobNumberField.isEditable;

        this.sellPriceField = this.projectReleaseLotFieldValidatorService.sellingPrice();
        this.sellPriceField.isEditable = this.sellPriceField.isEditable;

        this.roadDirectionField = this.projectReleaseLotFieldValidatorService.roadDirection();
        this.roadDirectionField.isEditable = this.roadDirectionField.isEditable;

        this.lotHoldingDepositField = this.projectReleaseLotFieldValidatorService.lotHoldingDeposit();
        this.lotHoldingDepositField.isEditable = this.lotHoldingDepositField.isEditable;

        this.hasExternalLandVendorField = this.projectReleaseLotFieldValidatorService.hasExternalLandVendor();
        this.hasExternalLandVendorField.isEditable = this.hasExternalLandVendorField.isEditable;

        this.lotPurchasePriceField = this.projectReleaseLotFieldValidatorService.lotPurchasePrice();
        this.lotPurchasePriceField.isEditable = this.lotPurchasePriceField.isEditable;

        this.lotDepositField = this.projectReleaseLotFieldValidatorService.lotDeposit();
        this.lotDepositField.isEditable = this.lotDepositField.isEditable;
    }

}
