<mat-card class="cb-margin">
    <mat-card-content>
        <div class="standardTable">
            <table>
                <thead>
                <td>Lot Number</td>
                <td>Job Number</td>
                <td class="right-align">Land Price</td>
                <td class="right-align">Build Cost</td>
                <td class="right-align">Build Price</td>
                <td class="right-align">Sell Price</td>
                <td class="right-align">Contract Price</td>
                </thead>
                <tbody>
                <tr *ngFor="let unit of unitCosts">
                    <td>{{ unit.lotNumber }}</td>
                    <td>{{ unit.jobNumber }}</td>
                    <td class="right-align">{{ unit.financials.landPrice | cbCurrency }}</td>
                    <td class="right-align">{{ unit.financials.buildCost | cbCurrency }}</td>
                    <td class="right-align">{{ unit.financials.buildPrice | cbCurrency }}</td>
                    <td class="right-align">{{ unit.financials.sellPrice | cbCurrency }}</td>
                    <td class="right-align">{{ unit.financials.contractPrice | cbCurrency }}</td>
                </tr>
                <tr>
                    <td colspan="7">
                        <strong>Block:</strong>
                    </td>
                </tr>
                <tr class="block-row">
                    <td>{{ lot.lotNumber }}</td>
                    <td>{{ lot.jobNumber }}</td>
                    <td class="right-align">{{ lot.financials.landPrice | cbCurrency }}</td>
                    <td class="right-align">{{ lot.financials.buildCost | cbCurrency }}</td>
                    <td class="right-align">{{ lot.financials.buildPrice | cbCurrency }}</td>
                    <td class="right-align">{{ lot.financials.sellPrice | cbCurrency }}</td>
                    <td class="right-align">{{ lot.financials.contractPrice | cbCurrency }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>
