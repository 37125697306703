<cb-dialog class="flex"
           dialogHeading="{{mode}} Design Scheme">
    <form #designSchemeForm="ngForm">
        <mat-tab-group class="tab-container" mat-stretch-tabs="false">
            <mat-tab label="Scheme Details">
                <cb-design-scheme-details class="cb-margin-2x"
                                          *cbWaitFor="data && mappedItem"
                                          [mappedItem]="mappedItem"
                                          [lotTypeEnumId]="data.lotTypeEnumId"></cb-design-scheme-details>
            </mat-tab>
            <mat-tab label="Scheme Items">
                <cb-design-scheme-items class="cb-margin"
                                        *cbWaitFor="mappedItem"
                                        [mappedItem]="mappedItem"
                                        [parentForm]="designSchemeForm"></cb-design-scheme-items>
            </mat-tab>
            <mat-tab label="Assigned Changes">
                <cb-design-scheme-assigned-changes class="cb-margin"
                                                   *cbWaitFor="mappedItem"
                                                   [mappedItem]="mappedItem"
                                                   [parentForm]="designSchemeForm"></cb-design-scheme-assigned-changes>
            </mat-tab>
        </mat-tab-group>
    </form>
    <div actions>
        <button color="primary"
                mat-raised-button
                [disabled]="designSchemeForm.invalid || designSchemeForm.pristine"
                (cbClick)="save()"
                [clickLimit]="1">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
