import { Component, OnInit, Inject } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { LotBuildPermissions } from '@app/core/permissions';
import { IProductDto, IProductBundleProductDto } from '@app/logic/products/interfaces/i.product.dto';
import { IProductMappedItem, ProductLogicService } from '@app/logic/products';
import { IProductLogicService } from '@app/logic/products/interfaces/i.product.logic.service';
import { FormMode } from '@app/shared/enums/form';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { BundleTemplatesLogicService } from '@app/logic/bundle-templates';
import { TradeTypesLogicService } from '@app/logic/trade-types/trade-types.logic.service';
import { CategoryLogicService } from '@app/logic/product-categories';
import { IUpdateBundleProductDto, PRODUCT_SEARCH_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: IProductMappedItem;
    bundleItem: IProductBundleProductDto;
}

@Component({
    templateUrl: './add-product-to-bundle-dialog.component.html',
    styleUrls: ['./add-product-to-bundle-dialog.component.scss'],
    providers: [
        LookupService,
        BundleTemplatesLogicService,
        ProductLogicService,
        TradeTypesLogicService,
        CategoryLogicService
    ]
})
export class AddProductToBundleDialogComponent
    extends BaseDialogFormViewDirective<IProductDto, IProductMappedItem, IProductLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '600px';

    public bundleItem: IProductBundleProductDto;
    public product: IProductDto;
    public productSearchText: string;
    public selectedProduct: IProductBundleProductDto;
    public quantity: number;
    public isChangeable: boolean;
    public mappedItem: IProductMappedItem;
    public readonly PRODUCT_SEARCH_TYPE_ENUM = PRODUCT_SEARCH_TYPE_ENUM;

    constructor(
        public readonly dialogRef: MatDialogRef<AddProductToBundleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(LookupService) public readonly lookupService: LookupService,
        @Inject(LotBuildPermissions) public readonly lotBuildPermissions: LotBuildPermissions,
        protected readonly bundleTemplatesLogicService: BundleTemplatesLogicService,
        protected readonly productLogicService: ProductLogicService
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.bundleItem = data.bundleItem;

        if (this.bundleItem) {
            this.formMode = FormMode.Edit;
        } else {
            this.formMode = FormMode.Add;
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
        if (this.bundleItem) {
            this.selectedProduct = this.bundleItem;
            this.quantity = this.bundleItem.quantity;
            this.isChangeable = this.bundleItem.isChangeable;
        }
    }

    public getTitle(): string {
        let returnValue = 'Add Item to Bundle';
        if (this.bundleItem) {
            returnValue = 'Edit Bundle Item';
        }
        return returnValue;
    }

    public productSelected(value: IProductBundleProductDto | void): void {
        if (value) {
            this.selectedProduct = value;
        }
    }

    public saveBundleProduct(): void {
        const bundleProduct: IUpdateBundleProductDto = {
            id: this.bundleItem ? this.bundleItem.id : undefined,
            isChangeable: this.isChangeable,
            productId: this.selectedProduct.id,
            quantity: this.quantity
        } as IUpdateBundleProductDto;
        this.mappedItem.saveBundleProduct(bundleProduct).subOnce((item) => {
            if (item) {
                this.mappedItem.products.forEach((sr: any) => {
                    item.products.forEach((ir: any) => {
                        if (sr.id === ir.id) {
                            Object.assign(sr, ir);
                        }
                    });
                });
                this.handleNext(item);
            } else {
                this.handleError(item);
            }
        });
    }
}
