<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x flex-col flex">
    <div class="start-center flex-row">
        <h3 class="mat-headline-6 flex">
            Price Options
        </h3>

        <button mat-raised-button color="primary" (click)="addNew()">Add New</button>
    </div>

    <div class="cb-table cb-margin-top flex">
        <table mat-table [dataSource]="dataSource" class="full-width-table flex">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> <div class="flex center-center">Name</div> </th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex center-center flex-col container-input">
                        <mat-form-field appearance="outline">
                            <input matInput class="input-field" required
                                   [(ngModel)]="element.name"
                                   name="name{{element.id}}">
                            <mat-label *ngIf="element.isNew">name</mat-label>
                        </mat-form-field>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> <div class="flex center-center">Description</div> </th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex center-center container-input">
                        <mat-form-field appearance="outline">
                            <input matInput class="input-field"
                                   [(ngModel)]="element.description">
                        </mat-form-field>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="textTemplate">
                <th mat-header-cell *matHeaderCellDef> <div class="flex center-center">Text Template</div> </th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex flex-row center-center">
                        <div class="flex center-center container-input">
                            <mat-form-field appearance="outline">
                                <input matInput class="input-field"
                                       [(ngModel)]="element.textTemplate">
                            </mat-form-field>
                        </div>
                        <div>
                            <button mat-icon-button [matTooltip]="getPreviewText(element)" matTooltipClass="custom-tooltip-class">
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>


            <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef> <div class="flex center-center">Is Active</div> </th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex center-center">
                        <mat-checkbox [(ngModel)]="element.isActive" color="primary"></mat-checkbox>
                    </div>
                </td>
            </ng-container>


            <ng-container matColumnDef="isDefault">
                <th mat-header-cell *matHeaderCellDef> <div class="flex center-center">Is Default</div> </th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex center-center">
                        <mat-checkbox [(ngModel)]="element.isDefault" color="primary"></mat-checkbox>
                    </div>
                </td>
            </ng-container>


            <ng-container matColumnDef="captureAltPrice">
                <th mat-header-cell *matHeaderCellDef> <div class="flex center-center">Capture Alt Price</div> </th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex center-center">
                        <mat-checkbox [(ngModel)]="element.captureAltPrice" color="primary"></mat-checkbox>
                    </div>
                </td>
            </ng-container>


            <ng-container matColumnDef="showSellPrice">
                <th mat-header-cell *matHeaderCellDef> <div class="flex center-center">Show Sell Price</div> </th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex center-center">
                        <mat-checkbox [(ngModel)]="element.showSellPrice" color="primary"></mat-checkbox>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> <div class="flex center-center">Actions</div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [disabled]="!element.name" (click)="save(element)" matTooltip="save" >
                        <mat-icon>save</mat-icon>
                    </button>
                    <button [disabled]="element.isDefault" mat-icon-button (click)="delete(element.id)" matTooltip ="delete">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr class="header   " mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [class.inactive-row]="!row.isActive">
            </tr>
        </table>
    </div>
</div>
