import {Component, OnInit, Inject, HostBinding} from '@angular/core';
import { findIndex } from 'lodash';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface IData {
    imgUrl: string;
    images: string[];
}

@Component({
    selector: 'cb-lightbox',
    templateUrl: './lightbox.component.html',
    styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnInit {
    public static readonly MIN_WIDTH = '100%';
    @HostBinding('class') class = 'flex-row';
    public imgUrl: string;
    public images: string[];
    public imgIndex: any;

    constructor(
        public readonly dialogRef: MatDialogRef<LightboxComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData
    ) {
        this.imgUrl = data.imgUrl;
        this.images = data.images;
    }

    public ngOnInit(): void {
        if (this.images) {
            this.imgIndex = findIndex(this.images, img => img === this.imgUrl);
            if (this.imgIndex < 0) {
                this.imgIndex = 0;
            }
        }
    }

    public nextImage(): void {
        this.imgIndex++;
        if (this.imgIndex >= this.images.length) {
            this.imgIndex = 0;
        }
    }

    public previousImage(): void {
        this.imgIndex--;
        if (this.imgIndex < 0) {
            this.imgIndex = this.images.length - 1;
        }
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}
