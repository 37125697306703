<mat-card class="cb-margin flex-row flex"
          style="overflow-x: auto">
    <mat-card-content>
        <div class="flex-col flex-100">
            <div class="start-center flex-row">
                <form class="flex-row flex">
                    <div class="flex-row center-center search-filter">
                    <mat-form-field class="flex">
                        <mat-label>Type in Legal Name, Trading Name or ABM Code</mat-label>
                        <input #searchQuery
                               matInput
                               type="text"
                               name="query"
                               [ngModel]="userCacheItem.data.query"
                               (keydown.enter)="onEnter($event, searchQuery.value)"
                               [disabled]="fetchInProgress"/>
                    </mat-form-field>
                    <button mat-icon-button
                            class="search-button"
                            [disabled]="fetchInProgress"
                            (click)="onEnter($event, searchQuery.value)">
                        <mat-icon>search</mat-icon>
                    </button>
                    </div>
                    <cb-select label="Filter by Account Type"
                               name="accountType"
                               class="cb-margin-right-2x"
                               [(ngModel)]="userCacheItem.data.t"
                               [options]="accountTypes"
                               [disabled]="fetchInProgress"></cb-select>
                    <cb-select label="Filter by Trade Type"
                               name="tradeType"
                               class="cb-margin-right-2x"
                               [(ngModel)]="userCacheItem.data.tt"
                               [options]="tradeTypes"
                               [disabled]="fetchInProgress"></cb-select>
                    <cb-select label="Filter by Status"
                               name="status"
                               [(ngModel)]="userCacheItem.data.s"
                               [options]="accountStatuses"
                               [disabled]="fetchInProgress"></cb-select>
                    <div class="checkbox flex-row end-center cb-margin-right-2x">
                        <mat-checkbox name="StrictSearch"
                                      [(ngModel)]="userCacheItem.data.st"
                                      [disabled]="fetchInProgress">Strict Search
                        </mat-checkbox>
                    </div>
                </form>
                <div class="end-center flex layoutGap-16">
                    <button mat-raised-button
                            (click)="onCreateNewAccountClicked()"
                            color="primary">
                        Create New Account
                    </button>
                    <button mat-raised-button
                            (click)="onRequestNewAccountClicked()"
                            color="primary">
                        Request New Account
                    </button>
                    <button mat-raised-button
                            (click)="onExportAccountsClicked()"
                            color="primary">
                        Export Business Accounts
                    </button>
                </div>
            </div>
            <mat-card class="flex-row"
                      *ngIf="filterChips?.length">
                <mat-card-content>
                    <mat-chip-set>
                        <mat-chip *ngFor="let chip of filterChips"
                                  [removable]="true"
                                  (removed)="onChipRemoved(chip.id)">
                            <strong>{{ chip.label }}</strong>
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                    </mat-chip-set>
                </mat-card-content>
            </mat-card>
            <div class="cb-margin-top-2x flex-row">
                <div class="cb-padding-right-2x flex-20">
                    <cb-business-account-location-picker [region]="userCacheItem.data.reg"
                                                         [district]="userCacheItem.data.dis"
                                                         [area]="userCacheItem.data.sub"
                                                         [disabled]="fetchInProgress"
                                                         (locationChange)="onLocationChanged($event)">
                    </cb-business-account-location-picker>
                </div>
                <cb-table *cbWaitFor="userCacheItem.silentData"
                          class="cb-margin-top flex"
                          maxHeightOffset="{{filterChips?.length ? 295 : 230}}"
                          [loaded]="true"
                          [infiniteScrollEnabled]="infiniteScrollEnabled"
                          [fetch]="fetchResults()"
                          [queryUpdated]="queryUpdate$"
                          [(results)]="accounts"
                          [(currentPage)]="currentPage"
                          (fetchInProgressChange)="fetchInProgress = $event">
                    <thead cbTableHead>
                    <tr>
                        <td>ABM Code</td>
                        <td>Trading Name</td>
                        <td>Legal Name</td>
                        <td>Supplier</td>
                        <td>Installer</td>
                        <td>Parent</td>
                        <td>Status</td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody cbTableBody>
                    <tr *ngFor="let account of accounts">
                        <td>{{ account.abmCode }}</td>
                        <td>{{ account.tradingName }}</td>
                        <td>{{ account.legalName }}</td>
                        <td>
                            <mat-icon class="cb-grey">
                                {{ account.isSupplier ? 'check_box' : 'check_box_outline_blank' }}
                            </mat-icon>
                        </td>
                        <td>
                            <mat-icon class="cb-grey">
                                {{ account.isInstaller ? 'check_box' : 'check_box_outline_blank' }}
                            </mat-icon>
                        </td>
                        <td>
                            <mat-icon class="cb-grey">
                                {{ account.isParentAccount ? 'check_box' : 'check_box_outline_blank' }}
                            </mat-icon>
                        </td>
                        <td>{{ account.statusDisplay }}</td>
                        <td cbTableShrinkCol
                            cbTableStickyCol="end">
                            <button mat-icon-button
                                    (click)="onViewAccountClicked(account)">
                                <mat-icon>pageview</mat-icon>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </cb-table>
            </div>
        </div>
    </mat-card-content>
</mat-card>
