<div class="cb-margin">
    <mat-card class="cb-margin-bottom flex-col">
        <mat-card-content>
            <form #form="ngForm">
                <div class="flex-row flex">
                    <div class="flex start-center">
                        <mat-icon class="cb-margin-right-2x">search</mat-icon>
                        <cb-input type="text"
                                  label="Search"
                                  [(ngModel)]="searchFilters.data.query"
                                  (ngModelChange)="searchFiltersChanged$.next($event)"
                                  name="search"
                                  class="cb-margin-right-2x flex">
                        </cb-input>
                    </div>
                    <cb-select label="Order"
                               name="resultOrders"
                               [(ngModel)]="searchFilters.data.order"
                               [options]="resultOrders$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-checkbox label="Awaiting Response Only"
                                 name="awaitingResponseOnly"
                                 class="cb-margin-top-2x"
                                 [(ngModel)]="searchFilters.data.awaitingResponseOnly">
                    </cb-checkbox>
                </div>
            </form>
            <cb-table class="cb-margin-top flex"
                      *ngIf="searchFiltersLoaded && searchEnabled"
                      label="Questions"
                      maxHeightOffset="280"
                      [infiniteScrollEnabled]="infiniteScrollEnabled"
                      [fetch]="fetchResults()"
                      [loaded]="loaded"
                      [queryUpdated]="searchFiltersChanged$"
                      [(results)]="results"
                      [(currentPage)]="currentPage">
                <thead cbTableHead>
                <tr>
                    <td>System Area</td>
                    <td>Name</td>
                    <td>Related</td>
                    <td>Question #</td>
                    <td>Created</td>
                    <td>Created By</td>
                    <td>Sent To</td>
                    <td>Response Date</td>
                    <td></td>
                </tr>
                </thead>
                <tbody cbTableBody>
                <tr *ngFor="let question of results">
                    <cb-td-text [value]="question.systemAreaName"></cb-td-text>
                    <cb-td-text [value]="question.entityName"></cb-td-text>
                    <cb-td-text [value]="question.childEntityName"></cb-td-text>
                    <cb-td-text [value]="question.questionNumber"></cb-td-text>
                    <cb-td-date [value]="question.createdDate"></cb-td-date>
                    <cb-td-text [value]="question.createdByUser"></cb-td-text>
                    <cb-td-text [value]="question.assignedToUserName"></cb-td-text>
                    <cb-td-date [value]="question.responseDate"></cb-td-date>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <cb-button [menu]="menu"></cb-button>
                        <mat-menu #menu="matMenu">
                            <cb-button [isMenuItem]="true"
                                       icon="pageview"
                                       menuItemLabel="View"
                                       (clicked)="viewQuestion(question)">
                            </cb-button>
                            <cb-button [isMenuItem]="true"
                                       icon="edit"
                                       menuItemLabel="Edit"
                                       [disabled]="!(canEdit$(question) | async)"
                                       (clicked)="editQuestion(question)">
                            </cb-button>
                            <cb-button [isMenuItem]="true"
                                       icon="reply"
                                       menuItemLabel="Respond"
                                       [disabled]="!(canRespond$(question) | async)"
                                       (clicked)="respondQuestion(question)">
                            </cb-button>
                            <cb-button [isMenuItem]="true"
                                       icon="cancel"
                                       menuItemLabel="Cancel Question"
                                       [disabled]="!(canEdit$(question) | async)"
                                       (clicked)="cancelQuestion(question)">
                            </cb-button>
                        </mat-menu>
                    </td>
                </tr>
                </tbody>
            </cb-table>
        </mat-card-content>
    </mat-card>
</div>
