import { Injectable, Injector } from '@angular/core';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import {IBuildMilestoneTemplateMappedItem} from "@app/logic/build-milestone-templates/interfaces/i.build-milestone-template.mapped";
import {BuildMilestoneTemplateMappedItem} from "@app/logic/build-milestone-templates/build-milestone-template.mapped";
import {IBuildMilestoneTemplateLogicService} from "@app/logic/build-milestone-templates/interfaces/i.build-milestone-template.logic-service";
import {IBuildMilestoneTemplateDto} from "@classictechsolutions/hubapi-transpiled-enums";
import {Observable} from "rxjs";

@Injectable()
export class BuildMilestoneTemplateLogicService
    extends BaseLogicService<IBuildMilestoneTemplateDto, IBuildMilestoneTemplateMappedItem>
    implements IBuildMilestoneTemplateLogicService {

    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
    ) {
        super(
            'BuildMilestoneTemplates',
            BuildMilestoneTemplateMappedItem,
        );
    }


    public updateBuildMilestones(): Observable<boolean> {
        return this.$http.post(`${this.$baseUri}/UpdateLotBuildMilestones`);
    }

}
