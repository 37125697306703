<cb-dialog>
    <form class="flex-col flex"
          #buildStagesForm="ngForm">
        <h1 class="mat-headline-6">
            {{ mappedItem.id > 0 ? 'Edit' : 'New' }} Stage
        </h1>
        <cb-input type="text"
                  name="code"
                  label="Stage Code"
                  [required]="true"
                  [(ngModel)]="mappedItem.code"></cb-input>
        <cb-input type="text"
                  name="name"
                  label="Stage Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.label"></cb-input>
        <mat-radio-group [(ngModel)]="mappedItem.constructionManagerRole"
                         name="constructionManagerRole"
                         aria-label="Select an option">
            <span class="required">
                Construction Manager
            </span>
            <mat-radio-button [checked]="role.id === mappedItem.constructionManagerRole"
                              *ngFor="let role of constructionManagerRoles"
                              [value]="role.id">
                {{ role.label }}
            </mat-radio-button>
        </mat-radio-group>
        <cb-checkbox name="isActive"
                     label="Is Active"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
        <cb-checkbox name="isPreconsentStage"
                     label="Is Pre consent Stage"
                     [(ngModel)]="mappedItem.isPreconsentStage"></cb-checkbox>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (cbClick)="save()"
                [clickLimit]="1"
                [disabled]="!buildStagesForm.dirty || !buildStagesForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
