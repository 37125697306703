<cb-dialog>
    <form class="flex-col flex"
          #bankAccountForm="ngForm">
        <cb-input type="text"
                  name="accountName"
                  label="Account Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.accountName">
        </cb-input>
        <cb-input type="text"
                  name="bankName"
                  label="Bank Name"
                  [required]="true"
                  [(ngModel)]="mappedItem.bankName">
        </cb-input>
        <cb-input type="text"
                  name="accountNumber"
                  label="Account Number"
                  [required]="true"
                  [maskType]="'BANK'"
                  [(ngModel)]="mappedItem.accountNumber">
        </cb-input>
        <cb-selection-list name="allowedBusinessEntities"
                           [(ngModel)]="mappedItem.allowedBusinessEntities"
                           [options]="businessEntityOptions"
                           [optionLabel]="optionLabel"
                           label="Allowed Business Entities"
                           checkboxPosition="before">
        </cb-selection-list>
        <cb-checkbox name="isTrustAccount"
                     label="Is Trust Account"
                     [(ngModel)]="mappedItem.isTrustAccount">
        </cb-checkbox>
        <cb-checkbox name="isActive"
                     label="Is Active"
                     [(ngModel)]="mappedItem.isActive">
        </cb-checkbox>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="save()"
                [disabled]="!bankAccountForm.dirty || !bankAccountForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
