import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LotBuildProgrammeEventService } from '../../../services/lot-build-programme-event.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LotBuildProgrammeActionBarService } from '../../services/lot-build-programme-action-bar.service';
import { forIn, orderBy, remove } from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { UserCacheItem } from '@app/core/services/user-cache/user-cache-item';
import { ILotBuildProgrammeView } from '@app/core/services/user-cache/user-cache-areas';
import { IBuildProgrammeStageIndexedDto, ILotDto, SSR_STATE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { CompleteBuildProgrammeActivitiesDialogComponent } from '../complete-build-programme-activities-dialog/complete-build-programme-activities-dialog.component';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { LotBuildPermissions } from '@app/core/permissions';
import { NavigationService } from '@app/core/services/navigation/navigation.service';

@Component({
    selector: 'cb-lot-build-programme-stage-list',
    templateUrl: './lot-build-programme-stage-list.component.html',
    styleUrls: ['./lot-build-programme-stage-list.component.scss']
})
export class LotBuildProgrammeStageListComponent implements OnInit, OnDestroy {

    @Input() public lotDto: ILotDto;

    public loaded$$: BehaviorSubject<boolean> = new BehaviorSubject(null);
    public expandedPanels: { [buildStageId: number]: boolean } = {};
    public dragulaGroup = 'BUILD_PROGRAMME_ACTIVITIES';
    public get cache(): UserCacheItem<ILotBuildProgrammeView> {
        return this.userCacheService?.lotBuildProgrammeView;
    }

    public visibleStages: IBuildProgrammeStageIndexedDto[] = [];

    public stageFlags: {
        [stageId: number]: { canCompleteSsrs: boolean; canConfirmSsrs: boolean; canGenerateSsrs: boolean };
    } = {};

    private readonly $subs = new Subscription();

    public queryParamsLoaded = false;
    public highlightedActivityId: number | null;

    constructor(
        public readonly lotBuildProgrammePermissions: LotBuildPermissions,
        public readonly lotBuildProgrammeEvents: LotBuildProgrammeEventService,
        private readonly lotBuildProgrammeActionBar: LotBuildProgrammeActionBarService,
        public readonly dragulaService: DragulaService,
        private readonly userCacheService: UserCacheService,
        public readonly cbDialog: CbDialogService,
        public readonly navigationService: NavigationService,
    ) {
    }

    public ngOnInit(): void {
        this.setSubs();
        this.setDragulaModel();
        this.userCacheService.lotBuildProgrammeView.init().then(() => {
            this.expandedPanels = this.cache.copyData();
            this.lotBuildProgrammeEvents.buildStages?.$promise?.then(() => {
                this.filterStages();
            });
        });
    }

    public ngOnDestroy(): void {
        this.$subs.unsubscribe();
    }

    private setSubs(): void {
        this.$subs.add(
            this.lotBuildProgrammeActionBar.VIEW_ALL_STAGES_CHANGED.subscribe(this.filterStages)
        );
        this.$subs.add(
            this.lotBuildProgrammeEvents.STAGE_RECEIVED.subscribe(this.filterStages)
        );
        this.$subs.add(
            this.lotBuildProgrammeEvents.ACTIVITY_RECEIVED.subscribe(this.filterStages)
        );
        this.$subs.add(
            this.lotBuildProgrammeActionBar.EXPAND_COLLAPSE_ALL.subscribe(this.expandCollapseAll)
        );
        this.$subs.add(
            this.lotBuildProgrammeEvents.STAGE_REQUIRES_UPDATE.subscribe(this.updateStageFlags)
        );
    }

    public anyActivitiesAvailable(): boolean {
        return Object.values(this.lotBuildProgrammeEvents.activities).filter(a => a.length > 0).length > 0;
    }

    private readonly updateStageFlags = (buildStageId: number): void => {
        const activities = this.lotBuildProgrammeEvents.activities[buildStageId];
        this.stageFlags[buildStageId] = {
            canCompleteSsrs: activities?.some(x => x?.rules?.canCompleteSsr),
            canConfirmSsrs: activities?.some(x => x?.rules?.canConfirmSsr),
            canGenerateSsrs: activities?.some(x => x?.rules?.canGenerateSsr)
        };
    };

    public generateSsrs(event: MouseEvent, buildStageId: number): void {
        event.stopPropagation();
        this.cbDialog
            .open(CompleteBuildProgrammeActivitiesDialogComponent, {
                data: {
                    targetSsrState: SSR_STATE_ENUM.Draft,
                    buildStageId,
                }
            });
    }

    public confirmSsrs(event: MouseEvent, buildStageId: number): void {
        event.stopPropagation();
        this.cbDialog
            .open(CompleteBuildProgrammeActivitiesDialogComponent, {
                data: {
                    targetSsrState: SSR_STATE_ENUM.Confirmed,
                    buildStageId,
                }
            });
    }

    public completeActivities(event: MouseEvent, buildStageId: number): void {
        event.stopPropagation();
        this.cbDialog
            .open(CompleteBuildProgrammeActivitiesDialogComponent, {
                data: {
                    targetSsrState: SSR_STATE_ENUM.Completed,
                    buildStageId,
                }
            });
    }

    public togglePinnedPanel(event: MouseEvent, buildStageId: number): void {
        event.stopPropagation();
        this.cache.data[buildStageId] = !this.cache.data[buildStageId];
    }

    public readonly expandCollapseAll = (): void => {
        this.resetAllExpanded();
        const result = !this.lotBuildProgrammeActionBar.allExpanded;
        this.visibleStages.forEach((stage) => {
            this.expandedPanels[stage.buildStageId] = result;
        });
        this.lotBuildProgrammeActionBar.allExpanded = result;
        this.lotBuildProgrammeActionBar.POST_EXPAND_COLLAPSE_ALL.next(result);
    };

    public getAllExpanded(): boolean {
        const expandedPanelsCount = this.getExpandedPanelCount();
        const panelCount = this.visibleStages?.length ?? 0;
        return expandedPanelsCount >= panelCount;
    }

    public getExpandedPanelCount(): number {
        let expandedPanelsCount = 0;
        forIn(this.expandedPanels, (value, key) => {
            if (value === true) {
                expandedPanelsCount++;
            }
        });
        return expandedPanelsCount;
    }

    public resetAllExpanded(): void {
        this.lotBuildProgrammeActionBar.allExpanded = this.getAllExpanded();
    }

    public readonly filterStages = (): void => {
        const stages = this.lotBuildProgrammeActionBar.showAll
            ? this.getAllStages()
            : this.lotBuildProgrammeEvents.stages?.filter(stage => this.lotBuildProgrammeEvents.activities[stage.buildStageId]?.length > 0);

        this.visibleStages = orderBy(stages, 'buildStageSortOrder', 'asc');
        this.visibleStages.forEach(stage => this.updateStageFlags(stage.buildStageId));
        this.resetAllExpanded();
        this.loaded$$.next(true);
        this.loadQueryParams();
    };

    private loadQueryParams(): void {
        if (this.queryParamsLoaded || this.visibleStages?.length < 1) {
            return;
        }
        this.queryParamsLoaded = true;
        const params = this.navigationService.getQueryParams<{ buildProgrammeActivityId?: number; paramEntityId?: number }>();

        this.highlightedActivityId = Number(params.buildProgrammeActivityId ?? params.paramEntityId) || null;
        if (this.highlightedActivityId > 0) {
            const stage = this.visibleStages.find(x => x.buildProgrammeActivities?.some(y => y === this.highlightedActivityId));
            if (stage) {
                this.expandedPanels[stage.buildStageId] = true;
            }
        }
    }

    public getAllStages(): IBuildProgrammeStageIndexedDto[] {
        this.lotBuildProgrammeEvents?.buildStages.forEach((buildStage) => {
            const exists = this.lotBuildProgrammeEvents.buildStageIndex[buildStage.id] != null;
            if (!exists) {
                const newBuildProgrammeStage: IBuildProgrammeStageIndexedDto = {
                    buildProgrammeActivities: [],
                    buildStageCode: buildStage.code,
                    buildStageId: buildStage.id,
                    buildStageLabel: buildStage.label,
                    buildStageSortOrder: buildStage.sortOrder,
                    id: 0 - buildStage.id,
                    sortOrder: 0,
                    buildProgrammeId: this.lotBuildProgrammeEvents?.buildProgramme?.id,
                };
                this.lotBuildProgrammeEvents.STAGE_RECEIVED.next([newBuildProgrammeStage]);
            }
        });

        return this.lotBuildProgrammeEvents.stages;
    }

    public setDragulaModel(): void {
        this.dragulaService.destroy(this.dragulaGroup);
        this.dragulaService.createGroup(this.dragulaGroup, {
            moves: () => {
                return !!this.lotBuildProgrammeActionBar?.reorderingEnabled;
            },
            invalid: (el) => el.classList.contains('dragula-ignore')
        });
        this.$subs.add(
            this.dragulaService.drop(this.dragulaGroup).subscribe(this.handleDrop)
        );
    }

    private getNumberAfterUnderscore(str = ''): number {
        return Number(str.split('_').pop() ?? 0);
    }

    private readonly handleDrop = ({ name, el, target, source, sibling }: any): void => {
        const buildProgrammeActivityId = this.getNumberAfterUnderscore(el?.id);
        const siblingId = this.getNumberAfterUnderscore(sibling?.id);
        const buildStageId = this.getNumberAfterUnderscore(target?.id);

        if (buildProgrammeActivityId === 0 || buildStageId === 0) {
            return;
        }

        const stage = this.lotBuildProgrammeEvents.stages.find(x => x.buildStageId === buildStageId);
        const buildProgrammeActivity = this.lotBuildProgrammeEvents.activityIndex[buildProgrammeActivityId];
        if (!stage || !buildProgrammeActivity) {
            return;
        }
        const oldStageId = buildProgrammeActivity.buildStageId;
        remove(this.lotBuildProgrammeEvents.activities[oldStageId], { id: buildProgrammeActivityId });

        buildProgrammeActivity.buildProgrammeStageId = stage.id;
        buildProgrammeActivity.buildStageId = stage.buildStageId;

        if (!this.lotBuildProgrammeEvents.activities[stage.buildStageId]) {
            this.lotBuildProgrammeEvents.activities[stage.buildStageId] = [];
        }

        const siblingIndex = this.lotBuildProgrammeEvents.activities[stage.buildStageId].findIndex(x => x.id === siblingId);

        if (siblingIndex > -1) {
            this.lotBuildProgrammeEvents.activities[stage.buildStageId].splice(siblingIndex, 0, buildProgrammeActivity);
        } else {
            this.lotBuildProgrammeEvents.activities[stage.buildStageId].push(buildProgrammeActivity);
        }
        this.filterStages();

        this.sortAndSaveBuildProgrammeActivities(stage.buildStageId, buildProgrammeActivity.id, oldStageId);
    };

    private sortAndSaveBuildProgrammeActivities(buildStageId: number, activityId: number, oldStageId: number): void {
        // BUILD PROGRAMME TODO - new endpoint for moving
        this.lotBuildProgrammeEvents.activities[buildStageId].forEach((activity, index) => {
            const previousOrder = activity.sortOrder;
            activity.sortOrder = index + 1;
            if (previousOrder !== activity.sortOrder || activity.id === activityId) {
                activity
                    .saveOrder()
                    .subOnce((result) => {
                        this.lotBuildProgrammeEvents.ACTIVITY_RECEIVED.next([result]);
                    });
            }
        });
        if (oldStageId !== buildStageId) {
            this.lotBuildProgrammeEvents.activities[oldStageId].forEach((activity, index) => {
                const previousOrder = activity.sortOrder;
                activity.sortOrder = index + 1;
                if (previousOrder !== activity.sortOrder) {
                    activity
                        .saveOrder()
                        .subOnce();
                }
            });
        }
    }
}
