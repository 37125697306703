import { NgModule } from '@angular/core';
import { CbTableModule } from 'cb-hub-lib';
import { LotLeadListComponent } from './lot-lead-list/lot-lead-list.component';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        LotLeadListComponent
    ],
    exports: [
        LotLeadListComponent
    ],
    imports: [
        CommonModule,
        CbTableModule,
        MatIconModule,
        BrowserModule,
        CbDatePipeModule,
        MatCardModule,
        MatButtonModule
    ]
})

export class CbLotLeadsModule { }
