<mat-card class="flex">
    <mat-card-header>
        <mat-card-title class="cb-title">Change #{{ changeRecordMappedItem.changeNumber }}</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>

    <mat-card-content>
        <mat-tab-group class="viewContainer flex" mat-stretch-tabs="false">
            <mat-tab label="Details">
                <cb-lot-change-details *cbWaitFor="changeRecordMappedItem"
                                       [changeRecordMappedItem]="changeRecordMappedItem"
                                       [lotMappedItem]="lotMappedItem"
                                       [teams]="teams">
                </cb-lot-change-details>
            </mat-tab>
            <mat-tab label="Documents ({{documentsCount}})">
                <cb-document *cbWaitFor="changeRecordMappedItem"
                             (documentCountChange)="documentsCount = $event"
                             [entity]="changeRecordMappedItem"
                             [permissions]="documentPermissions"
                             [hideDeletedToggle]="true"
                             [hideRenewalColumn]="true"
                             [hideUploadButton]="false"
                             [includeLinked]="true"
                             [enableCheckbox]="true"
                             [edit]="true"
                             [systemArea]="systemArea"></cb-document>
            </mat-tab>
            <mat-tab label="Questions ({{questionsCount}})">
                <cb-generic-question-list *cbWaitFor="changeRecordMappedItem"
                                          (questionCountChange)="questionsCount = $event"
                                          [entityId]="changeRecordMappedItem.id"
                                          [systemArea]="systemArea"
                                          [edit]="true"
                                          [lotId]="changeRecordMappedItem.lotId">
                </cb-generic-question-list>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>
