import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPreConsentPlanMappedItem } from '@app/logic/pre-consent-plans';
import { NoteDialogComponent } from '@app/shared/components/notes/note-dialog/note-dialog.component';
import { FormMode } from '@app/shared/enums/form';
import { cloneDeepSafe } from '@app/shared/utils/clone-object.util';
import {
    IPreConsentPlanItemDto,
    PreConsentPlanItemStatusEnumId,
    PRE_CONSENT_PLAN_ITEM_STATUS_ENUM
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { filter, orderBy } from 'lodash';

@Component({
    selector: 'cb-pre-consent-item-dialog',
    templateUrl: './pre-consent-item-dialog.component.html',
    styleUrls: ['./pre-consent-item-dialog.component.scss']
})
export class PreConsentItemDialogComponent implements OnInit {
    public static readonly MIN_WIDTH = '400px';
    public FormMode = FormMode;

    public tempItem: IPreConsentPlanItemDto;
    public statuses: IEnumLookup<PreConsentPlanItemStatusEnumId>[];
    public commentRequired: boolean;
    public commentRequiredMessage = 'Please write a comment explaining why this Item will not be completed';

    constructor(
        public readonly dialogRef: MatDialogRef<NoteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData
    ) {
        this.commentRequired = this.data.item.statusId === PRE_CONSENT_PLAN_ITEM_STATUS_ENUM.NotDoing;
    }

    public ngOnInit(): void {
        this.tempItem = cloneDeepSafe(this.data.item);

        this.statuses = PRE_CONSENT_PLAN_ITEM_STATUS_ENUM.toLookup({
            transform: array => {
                let statuses = filter(array, item => item.id > 0);
                statuses = orderBy(statuses, 'label');
                return statuses;
            }
        });
    }

    public addToList(): void {
        if (this.data.formMode === FormMode.Add) {
            if (!Array.isArray(this.data.mappedItem?.items)) {
                this.data.mappedItem.items = [];
            }
            this.tempItem.statusId = PRE_CONSENT_PLAN_ITEM_STATUS_ENUM.NotStarted;
            this.data.mappedItem.items.push(this.tempItem);
        } else {
            this.data.mappedItem.items.splice(this.data.itemIndex, 1, this.tempItem);
        }

        this.dialogRef.close(this.tempItem);
    }

    public saveItem(): void {
        this.addToList();
        this.data.mappedItem.$save().subOnce();
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public statusChanged(statusId: PreConsentPlanItemStatusEnumId): void {
        this.tempItem.statusId = statusId;
        this.tempItem.statusLabel = this.statuses.find(status => status.id === statusId).label;
        this.commentRequired = statusId === PRE_CONSENT_PLAN_ITEM_STATUS_ENUM.NotDoing;
    }
}

interface IData {
    item: IPreConsentPlanItemDto;
    mappedItem: IPreConsentPlanMappedItem;
    formMode: FormMode;
    addAndSave: boolean;
    itemIndex: number;
}
