import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import {
    BuildProgrammeTemplateLogicService, IBuildProgrammeTemplateDto, IBuildTemplateActivityDto, IBuildTemplateActivityRelationshipDto,
    IBuildTemplateStageDto
} from '@app/logic/build-programme-template';
import { DATE_CALC_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { find, some } from 'lodash';
import { map, Observable, startWith } from 'rxjs';

interface IData {
    template: IBuildProgrammeTemplateDto;
    stage: IBuildTemplateStageDto;
    activity: IBuildTemplateActivityDto;
    predecessor: IBuildTemplateActivityRelationshipDto;
}

@Component({
    selector: 'cb-build-programme-template-activity-predecessor-dialog',
    templateUrl: './build-programme-template-activity-predecessor-dialog.component.html',
    styleUrls: ['./build-programme-template-activity-predecessor-dialog.component.scss']
})
export class BuildProgrammeTemplateActivityPredecessorDialogComponent {
    public static readonly MIN_WIDTH = '90%';
    public dateCalculationTypes = DATE_CALC_TYPE_ENUM.toLookup();
    public activityControl = new UntypedFormControl();
    public existingActivities: IBuildTemplateActivityDto[];
    public filteredOptions: Observable<IBuildTemplateActivityDto[]>;
    public predecessor: IBuildTemplateActivityRelationshipDto;

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<BuildProgrammeTemplateActivityPredecessorDialogComponent>,
        private readonly buildProgrammeTemplateLogicService: BuildProgrammeTemplateLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        this.existingActivities = [];
        this.data.template.stages.forEach((stage: IBuildTemplateStageDto) => {
            stage.activities.forEach((activity: IBuildTemplateActivityDto) => {
                if (activity.id !== this.data.activity.id && !some(this.data.activity.relationships, { predecessorBuildProgrammeActivityId: activity.id })) {
                    this.existingActivities.push({ id: activity.id, label: this.getDisplayString(stage, activity) } as IBuildTemplateActivityDto);
                }
            });
        });
        this.filteredOptions = this.activityControl.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(searchText => searchText ? this._filter(searchText) : this.existingActivities.slice())
            );
        this.predecessor = data.predecessor ? data.predecessor : {} as IBuildTemplateActivityRelationshipDto;
    }

    public getDisplayString(stage: IBuildTemplateStageDto, activity: IBuildTemplateActivityDto): string {
        return stage && activity
            ? `(${stage.buildStageCode}) ${stage.buildStageLabel} - (${activity.activity.code}) ${activity.activity.name}`
            : '';
    }

    public getDisplayWith(activityId: string): string {
        return find(this.existingActivities, { id: activityId })?.label;
    }

    private _filter(searchText: string): IBuildTemplateActivityDto[] {
        const filterValue = searchText.toLowerCase();
        return this.existingActivities.filter(option => option.label.toLowerCase().indexOf(filterValue) > -1);
    }

    public saveClicked(): void {
        if (this.predecessor.predecessorBuildProgrammeActivityId || this.activityControl.value) {
            const match: IBuildTemplateActivityRelationshipDto = find(this.data.activity.relationships,
                { predecessorBuildProgrammeActivityId: this.predecessor.predecessorBuildProgrammeActivityId });
            if (match) {
                match.lagDays = this.predecessor.lagDays;
                match.shouldSetStartBasedOnPredecessor = this.predecessor.shouldSetStartBasedOnPredecessor;
                match.dateCalcType = this.predecessor.dateCalcType;
            } else {
                this.data.activity.relationships.push({
                    dateCalcType: this.predecessor.dateCalcType,
                    descendantBuildProgrammeActivityId: this.data.activity.id,
                    lagDays: this.predecessor.lagDays,
                    predecessorBuildProgrammeActivityId: this.activityControl.value,
                    predecessorName: find(this.existingActivities, { id: this.activityControl.value })?.label,
                    shouldSetStartBasedOnPredecessor: this.predecessor.shouldSetStartBasedOnPredecessor,
                    sortOrder: (this.data.activity.relationships.length + 1),
                });
            }
        }
        this.buildProgrammeTemplateLogicService.saveActivities(this.data.template.id, this.data.activity).subOnce(() => this.dialogRef.close());
    }
}
