import { Component, Inject, OnDestroy } from '@angular/core';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IProductDto, IRateDto } from '@app/logic/products/interfaces/i.product.dto';
import { IProductMappedItem, ProductLogicService } from '@app/logic/products';
import { IProductLogicService } from '@app/logic/products/interfaces/i.product.logic.service';
import { FormMode } from '@app/shared/enums/form';
import { LookupService } from '@app/core/services/lookup/lookup.service';
import { isString } from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { environment } from '@src/environments/environment';
import { NgForm } from '@angular/forms';

interface IData {
    mappedItem: IProductMappedItem;
    rate: IRateDto;
}

@Component({
    templateUrl: './manage-images-dialog.component.html',
    styleUrls: ['./manage-images-dialog.component.scss'],
    providers: [
        ProductLogicService
    ]
})
export class ManageImagesDialogComponent
    extends BaseDialogFormViewDirective<IProductDto, IProductMappedItem, IProductLogicService> implements OnDestroy {
    public static readonly MIN_WIDTH = '80%';

    public imageFileRegEx = /^(?:\w?\s?\-?\(?\)?)+\.(jpg|jpeg|png|gif|bmp)$/i;
    public mappedItem: IProductMappedItem;
    public imageOrderChanged = false;
    public hasChanged = false;
    public fileExtension: string;
    public isFileExtensionValid = true;
    public isFilesizeValid = true;
    public fileSize: number;
    public fileSizeFormatted: string;
    public isFileValidAndUploaded: boolean;
    public document: any = {};
    public documentForm: NgForm;
    public api: string = environment.api;
    public uploadingImage = false;
    public FORM_POST_RENDER = 'post-render::manage-images-view::image-form';
    public readonly ORDERED_IMAGES = 'ORDERED_IMAGES';
    public ORDERED_IMAGE_CLASSNAME = 'ordered-image';
    public subscriptions = new Subscription();
    public dragulaModel: string[] = [];
    public sortOrderChanged: boolean;
    constructor(
        public readonly dialogRef: MatDialogRef<ManageImagesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        private readonly dragulaService: DragulaService,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(LookupService) public readonly lookupService: LookupService,
        protected readonly productLogicService: ProductLogicService,
    ) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        if (this.mappedItem.id) {
            this.formMode = FormMode.Edit;
        } else {
            this.formMode = FormMode.Add;
        }
        this.setupDragula();
        this.setDragulaModel();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.dragulaService.destroy(this.ORDERED_IMAGES);
    }

    public selectFile(uploadedFile: File): void {
        if (!uploadedFile) {
            return;
        }

        this.document.file = uploadedFile[0];
        this.checkFileSize();
        this.checkFileExtension();
    }

    public uploadFile(): void {
        // upload the image file
        this.checkFileSize();
        this.checkFileExtension();
        this.mappedItem.uploadImageFile(this.document.file).subOnce({
            next: result => {
                if (isString(result)) {
                    this.uploadingImage = false;
                } else {
                    this.document = {};
                    this.uploadingImage = false;
                    this.mappedItem = this.productLogicService.$createMappedItem(result as IProductDto);
                }
                if (result) {
                    this.hasChanged = true;
                }
            }
        });
    }

    public deleteImage(imageId: string): void {
        this.cbDialog
            .confirm({
                dialogHeading: 'Delete Image',
                message: 'Are you sure you want to delete this image?',
                confirmed: () => {
                    this.mappedItem.deleteImage(imageId).subOnce({
                        next: result => {
                            if (result) {
                                this.hasChanged = true;
                            }
                            this.mappedItem = this.productLogicService.$createMappedItem(result);
                        }
                    });
                }
            });
    }

    public imageMoved(index): void {
        this.mappedItem.images.splice(index, 1);
        this.imageOrderChanged = true;
    }

    public saveImageOrder(): void {
        this.productLogicService.saveImageOrder(this.mappedItem.id, this.dragulaModel).subOnce({
            next: result => {
                if (result) {
                    this.hasChanged = true;
                }
                this.handleNext(result);
            }
        });
    }

    private checkFileExtension(): void {
        if (this.document.file.name.match(this.imageFileRegEx)) {
            this.isFileExtensionValid = true;
        } else {
            this.isFileExtensionValid = false;
            this.isFileValidAndUploaded = false;
        }
    }

    public cancel(): void {
        if (this.hasChanged) {
            this.dialogRef.close(true);
        } else {
            this.dialogRef.close(false);
        }
    }

    public checkFileSize(): void {
        if (this.document.file) {
            this.fileSize = (this.document.file.size / 1024 / 1024);
            this.fileSizeFormatted = `${this.fileSize.toFixed(2)}MB`;

            if (50 > this.fileSize) {
                this.isFilesizeValid = true;
            } else {
                this.isFilesizeValid = false;
                this.isFileValidAndUploaded = false;
            }
        }
    }

    public imageOrderDisabled = () => {
        if (this.mappedItem.images && this.mappedItem.images.length > 1) {
            return false;
        } else {
            return true;
        }
    };

    private clearOldFileReference(): void {
        this.document.file = undefined;
    }

    /** Creates dragula group for address regions table and suscribes to dragula observables */
    private setupDragula(): void {
        this.dragulaService.createGroup(
            this.ORDERED_IMAGES,
            {
                removeOnSpill: false,
                revertOnSpill: true
            }
        );

        this.subscriptions.add(
            this.dragulaService.dropModel(this.ORDERED_IMAGES)
                .subscribe(
                    ({ el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
                        if (sourceIndex === targetIndex) {
                            return;
                        }
                        this.imageOrderChanged = true;
                    }
                )
        );
    }
    /** Sets the dragula model for drag and drop - seperate to the actual array of data because
     *  the dragula model needs an item for the header row aswell...
     */
    private setDragulaModel(): void {
        this.dragulaModel = this.mappedItem.images.map(x => x.id);
    }
}
