<cb-dialog #dialogElment>
    <form class="flex-col flex"
          #notificationTemplateForm="ngForm">
        <cb-input label="Template"
                  name="name"
                  [readonly]="true"
                  [ngModel]="mappedItem.label"></cb-input>
        <cb-select label="Template Category"
                   name="category"
                   [required]="true"
                   [options]="categories"
                   [(ngModel)]="mappedItem.templateCategoryId"></cb-select>
        <cb-select label="Notification Type"
                   name="type"
                   [required]="true"
                   [options]="types"
                   [(ngModel)]="mappedItem.notificationTypeId"></cb-select>
        <cb-checkbox label="Is Notification Template Active"
                     name="isActive"
                     class="cb-margin-top"
                     [(ngModel)]="mappedItem.isActive"></cb-checkbox>
        <div class="cb-table flex-col flex">
            <mat-tab-group (selectedTabChange)="onTabClicked()" mat-stretch-tabs="false">
                <mat-tab *ngFor="let mediumTab of mediumTabs"
                         templateLabel="matTabLabel">
                    <ng-template #matTabLabel
                                 mat-tab-label>
                        <mat-icon *ngIf="indexedTemplateMediums[mediumTab.id]?.isActive"
                                  class="mat-accent cb-margin-right">
                            check_circle
                        </mat-icon>
                        {{mediumTab.label}}
                    </ng-template>
                    <div class="cb-margin-top cb-padding-2x flex-col flex">
                        <button *ngIf="!indexedTemplateMediums[mediumTab.id]"
                                mat-raised-button
                                color="primary"
                                (click)="onGenerateTemplateMediumClicked(mediumTab.id)">
                            Generate {{mediumTab.label}} Template
                        </button>
                        <div *ngIf="indexedTemplateMediums[mediumTab.id]">
                            <cb-select label="Template Variables"
                                       [name]="'variables'+mediumTab.id"
                                       [options]="templateVariableFields"
                                       [disabled]="!insertVariableLabel"
                                       [(ngModel)]="seletedVariableField"
                                       (change)="onVariableSelected($event)"></cb-select>
                            <div *ngIf="insertVariableLabel"
                                 class="mat-caption cb-margin-bottom-2x">
                                {{insertVariableLabel}}
                            </div>
                            <cb-input label="Subject"
                                      [name]="'subject'+mediumTab.id"
                                      [(ngModel)]="indexedTemplateMediums[mediumTab.id].subject"
                                      (click)="selectSubjectField($event)"></cb-input>
                            <cb-text-area label="Body"
                                          [name]="'body'+mediumTab.id"
                                          [maxlength]="5000"
                                          [(ngModel)]="indexedTemplateMediums[mediumTab.id].template"
                                          (click)="selectBodyField($event)"></cb-text-area>
                            <cb-checkbox label="Is Active"
                                         [name]="'isActive'+mediumTab.id"
                                         class="cb-margin-top"
                                         [(ngModel)]="indexedTemplateMediums[mediumTab.id].isActive"></cb-checkbox>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </form>
    <div actions>
        <span class="flex"></span>
        <button mat-raised-button
                class="save-button"
                color="primary"
                (click)="onSaveClicked()"
                [disabled]="!notificationTemplateForm.dirty || !notificationTemplateForm.valid">
            Save
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
