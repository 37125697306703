<cb-dialog dialogHeading="Finalise Working Drawing">
    <form class="flex flex-row layoutGap-16"
          #finaliseWorkingDrawingForm="ngForm">
        <div class="flex-col flex-50">
            <div class="flex-row flex">
                <cb-display-value-new class="flex"
                                      label="Lot #"
                                      [value]="lotMappedItem.lotNumber">
                </cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Job #"
                                      [value]="lotMappedItem.jobNumber">
                </cb-display-value-new>
            </div>
            <cb-select name="designComplexity"
                       label="Design Complexity"
                       [options]="designComplexities"
                       [required]="true"
                       [(ngModel)]="mappedItem.designComplexityId">
            </cb-select>
            <cb-display-value-new label="Approver"
                                  [value]="mappedItem.approverName"></cb-display-value-new>
        </div>
        <div class="flex-col flex-50">
            <div class="flex-row flex">
                <cb-display-value-new class="flex"
                                      label="Land Area">
                    <div class="start-center">
                        <mat-icon class="cb-grey">border_style</mat-icon>
                        <span class="mat-body-2">&nbsp;{{
                            mappedItem.amenities.landArea | cbDasher
                            }}m²</span>
                    </div>
                </cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Floor Area">
                    <div class="start-center">
                        <mat-icon class="cb-grey"
                                  svgIcon="floor-plan"></mat-icon>
                        <span class="mat-body-2">&nbsp;{{
                            mappedItem.amenities.floorArea | cbDasher
                            }}m²</span>
                    </div>
                </cb-display-value-new>
            </div>
            <div class="flex-row flex">
                <cb-display-value-new class="flex"
                                      label="Bedroom">
                    <div class="start-center">
                        <mat-icon class="cb-grey">hotel</mat-icon>
                        <span class="mat-body-2">&nbsp;{{
                            mappedItem.amenities.noBedrooms | cbDasher
                            }}</span>
                    </div>
                </cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Bathroom">
                    <div class="start-center">
                        <mat-icon class="cb-grey">wc</mat-icon>
                        <span class="mat-body-2">&nbsp;{{
                            mappedItem.amenities.noBathrooms | cbDasher
                            }}</span>
                    </div>
                </cb-display-value-new>
            </div>
            <div class="flex-row flex">
                <cb-display-value-new class="flex"
                                      label="Living">
                    <div class="start-center">
                        <mat-icon class="cb-grey">weekend</mat-icon>
                        <span class="mat-body-2">&nbsp;{{
                            mappedItem.amenities.noLivingRooms | cbDasher
                            }}</span>
                    </div>
                </cb-display-value-new>
                <cb-display-value-new class="flex"
                                      label="Garage">
                    <div class="start-center">
                        <mat-icon class="cb-grey">directions_car</mat-icon>
                        <span class="mat-body-2">&nbsp;{{
                            mappedItem.amenities.noGarages | cbDasher
                            }}</span>
                    </div>
                </cb-display-value-new>
            </div>
        </div>
    </form>
    <div actions>
        <button color="primary"
                mat-raised-button
                [disabled]="finaliseInProgress || finaliseWorkingDrawingForm.invalid"
                (cbClick)="finaliseWorkingDrawing()">
            Finalise Working Drawing</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
