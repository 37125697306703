<div class="cb-margin-3x flex-col flex">
    <mat-card class="cb-margin-bottom top-panel flex-row">
        <mat-card-content class="mat-card-content-row">
            <button mat-raised-button
                    color="primary"
                    (cbClick)="downloadUsageReport()">
                Usage Report
            </button>
        </mat-card-content>
    </mat-card>
    <cb-table label="Purchase Orders"
              #infiniteScrollTable
              maxHeightOffset="200"
              [stringColumns]="['Order Number', 'Job Number', 'Business Entity', 'Business Account', 'Status', '']"
              [loaded]="true"
              infiniteScroll
              [immediateCheck]="false"
              [infiniteScrollContainer]="infiniteScrollTable?.infiniteScrollContainer"
              (scrolled)="doSearch()">
        <tbody cbTableBody>
        <tr *ngFor="let purchaseOrder of searchResults">
            <cb-td-text [value]="purchaseOrder?.orderNumber"></cb-td-text>
            <cb-td-text [value]="purchaseOrder?.jobNumber"></cb-td-text>
            <cb-td-text [value]="purchaseOrder?.businessEntityName"></cb-td-text>
            <cb-td-text [value]="purchaseOrder?.businessAccountName"></cb-td-text>
            <cb-td-enum [value]="purchaseOrder?.status"
                        [enum]="SSR_STATE_ENUM">
            </cb-td-enum>
            <td cbTableShrinkCol
                cbTableStickyCol="end">
                <button mat-icon-button
                        (click)="downloadPurchaseOrder(purchaseOrder)">
                    <mat-icon>file_download</mat-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </cb-table>
</div>
