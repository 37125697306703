import {Component, Input, OnInit} from '@angular/core';
import {ProjectReleaseStagePermissions} from '@app/core/permissions/project-release-stage.permissions';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {ProjectsLogicService} from '@app/logic/projects';
import {IReleaseStageMappedItem} from '@app/logic/release-stages/interfaces/i.release-stage.mapped';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {IProjectDetailsDto, PROJECT_RELEASE_STAGE_TYPE_ENUM, PROJECT_RELEASE_STATUS_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';
import {BehaviorSubject} from 'rxjs';
import {ManageProjectReleaseStageDetailsDialogComponent} from '../details/manage-release-details-dialog/manage-release-details-dialog.component';

@Component({
    selector: 'cb-manage-project-release-stage-side-bar-card',
    templateUrl: './release-side-bar-card.component.html',
    styleUrls: ['./release-side-bar-card.component.scss']
})
export class ManageProjectReleaseStageSideBarCardComponent implements OnInit {
    @Input() public mappedItem: IReleaseStageMappedItem;
    public projectDto: IProjectDetailsDto;
    public PROJECT_RELEASE_STATUS_ENUM = PROJECT_RELEASE_STATUS_ENUM;
    public PROJECT_RELEASE_STAGE_TYPE_ENUM = PROJECT_RELEASE_STAGE_TYPE_ENUM;
    public collapsed$ = new BehaviorSubject<boolean>(false);

    constructor(
        public readonly projectReleasePermissions: ProjectReleaseStagePermissions,
        private readonly projectsLogicService: ProjectsLogicService,
        private readonly navigationService: NavigationService,
        private readonly cbDialog: CbDialogService
    ) { }

    public ngOnInit(): void {
        this.projectsLogicService.$getItem(this.mappedItem.projectId).subOnce(result => {
            this.projectDto = result;
        });
    }

    public viewProject(): void {
        this.navigationService.navigate([`/projects/${this.mappedItem.projectId}/details`]);
    }

    public editProjectReleaseStage() {
        const clone = this.mappedItem.$clone();
        this.cbDialog
            .open(ManageProjectReleaseStageDetailsDialogComponent, {
                data: { mappedItem: clone },
                minWidth: '80%',
            })
            .afterClosed()
            .subOnce();
    }

}
