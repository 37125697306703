<div class="flex-row flex">
    <div class="flex-col flex">
        <mat-form-field class="flex"
                        *ngIf="editingComment"
                        [ngClass]="{'cb-soft-required': softRequired && !required }">
            <mat-label>{{ label }}</mat-label>
            <textarea matInput
                      [name]="internalName"
                      type="text"
                      [(ngModel)]="value"
                      [required]="required"
                      [maxlength]="maxlength || 1000"
                      [rows]="rows || 5">
            </textarea>
        </mat-form-field>
        <cb-display-value-new *ngIf="!editingComment"
                              [label]="label"
                              [value]="value"></cb-display-value-new>
        <div class="end-start flex-row"
             *ngIf="editingComment">
            <span>{{getCharCount()}}</span>
        </div>
        <div class="cb-margin-top end-end flex-row"
             *ngIf="editingComment">
            <button mat-stroked-button
                    class="cb-margin-right"
                    color="accent"
                    (click)="saveComment()">
                Save
            </button>
            <button mat-stroked-button
                    (click)="cancelComment()">
                Cancel
            </button>
        </div>
    </div>
    <div class="cb-margin-left center-center flex-col">
        <button mat-icon-button
                *ngIf="!editingComment && !isEditDisabled"
                (click)="editComment()">
            <mat-icon>edit</mat-icon>
        </button>
    </div>
</div>
