import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'cb-display-pill',
    templateUrl: './display-pill.component.html',
    styleUrls: ['./display-pill.component.scss']
})
export class DisplayPillComponent {
    @Input() public displayPillText: string;
    @Input() public textColor?: string;
    @Input() public backgroundColor?: string;
    @Input() public fontWeight?: string;

    @HostBinding('style.--pill-text-color') protected get textColorVar(): string {
        return this.textColor || null; // Null keeps default if not provided
    }

    @HostBinding('style.--pill-bg-color') protected get backgroundColorVar(): string {
        return this.backgroundColor || null;
    }

    @HostBinding('style.--pill-font-weight') protected get fontWeightVar(): string {
        return this.fontWeight || null; // Null keeps default if not provided
    }
}
