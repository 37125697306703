import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { SellingAgentSearchService } from '@app/core/services/search/sellingagent.search.service';
import { SellingAgentsLogicService } from '@app/logic/selling-agents/sellingagents.logic.service';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { SellingAgentsInputFindDialogCardsComponent } from '../../dialog/extensions/selling-agents/sellingagents-input-find-dialog-cards.component';
import { SellingAgentsInputFindDirective } from './sellingagents-input-find.directive';

@Component({
    selector: 'cb-sellingagents-input-find-cards',
    templateUrl: '../../base-input-find.directive.html',
    styleUrls: ['../../base-input-find.directive.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(SellingAgentsInputFindCardsComponent),
        SellingAgentSearchService,
        provideParentForm()
    ]
})
export class SellingAgentsInputFindCardsComponent extends SellingAgentsInputFindDirective {
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public readonly sellingAgentsLogicService: SellingAgentsLogicService,
        public readonly navigationService: NavigationService,
        public readonly searchService: SellingAgentSearchService) {
        super(
            dialogRef,
            router,
            sellingAgentsLogicService,
            navigationService,
            searchService
        );
        this._myDialog = SellingAgentsInputFindDialogCardsComponent;
    }
}
