<div class="cb-margin"
     *cbWaitFor="cacheLoaded$ | async">
    <mat-card class="cb-margin-bottom flex-col">
        <mat-card-content>
            <form #form="ngForm">
                <div class="start-center flex-row flex">
                    <mat-icon>search</mat-icon>
                    <cb-input type="text"
                              label="Search"
                              [(ngModel)]="searchFiltersCache.data.query"
                              (ngModelChange)="searchFiltersChanged$.next($event)"
                              name="search"
                              class="cb-margin-left cb-margin-right-2x flex">
                    </cb-input>
                    <cb-select label="Assigned To"
                               name="userId"
                               *ngIf="designTeamPermissions.canSeeAll()"
                               [(ngModel)]="searchFiltersCache.data.userId"
                               [options]="teamUsersOptions$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select-list label="Status"
                                    name="statusId"
                                    class="cb-margin-right-2x flex"
                                    [allOptions]="councilRfiStatuses$ | async"
                                    [multiple]="true"
                                    [(value)]="searchFiltersCache.data.statusId">
                    </cb-select-list>
                    <cb-select label="Order"
                               name="resultOrders"
                               [(ngModel)]="searchFiltersCache.data.order"
                               [options]="resultOrders$ | async"
                               class="cb-margin-right-2x">
                    </cb-select>
                    <cb-select class="cb-margin-right"
                               label="Direction"
                               [(ngModel)]="searchFiltersCache.data.direction"
                               name="direction"
                               [options]="resultDirections$ | async">
                    </cb-select>
                </div>
            </form>
            <cb-table class="cb-margin-top flex"
                      label="Council Rfis"
                      maxHeightOffset="280"
                      [infiniteScrollEnabled]="infiniteScrollEnabled"
                      [fetch]="fetchResults()"
                      [loaded]="loaded"
                      [queryUpdated]="searchFiltersChanged$"
                      [(results)]="results"
                      [(currentPage)]="currentPage">
                <thead cbTableHead>
                <tr>
                    <td>Rfi #</td>
                    <td>Job #</td>
                    <td>lot #</td>
                    <td>Project</td>
                    <td>Address</td>
                    <td>Client Account Name</td>
                    <td>Council Name</td>
                    <td>External Reference</td>
                    <td>Rfi date</td>
                    <td>Received From</td>
                    <td>Due</td>
                    <td>Status</td>
                    <td>Assigned To</td>
                    <td></td>
                </tr>
                </thead>
                <tbody cbTableBody>
                <tr *ngFor="let councilRfi of results">
                    <cb-td-text [value]="councilRfi.id"></cb-td-text>
                    <cb-td-text [value]="councilRfi.jobNumber"></cb-td-text>
                    <cb-td-text [value]="councilRfi.lotNumber"></cb-td-text>
                    <cb-td-text [value]="councilRfi.projectName"></cb-td-text>
                    <cb-td-text [value]="getStreetAddress(councilRfi)"></cb-td-text>
                    <cb-td-text [value]="councilRfi.clientAccountName"></cb-td-text>
                    <cb-td-text [value]="councilRfi.councilName"></cb-td-text>
                    <cb-td-text [value]="councilRfi.externalReference"></cb-td-text>
                    <cb-td-date [value]="councilRfi.rfiDate"></cb-td-date>
                    <cb-td-text [value]="councilRfi.receivedFrom"></cb-td-text>
                    <cb-td-date [value]="councilRfi.rfiDueDate"></cb-td-date>
                    <cb-td-text [value]="councilRfi.statusLabel"></cb-td-text>
                    <cb-td-text [value]="councilRfi.assignedToUserName"></cb-td-text>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end">
                        <cb-button [menu]="menu"></cb-button>
                        <mat-menu #menu="matMenu">
                            <cb-button [isMenuItem]="true"
                                       icon="edit"
                                       menuItemLabel="Edit Council Rfi"
                                       *ngIf="hasPermissionsToEdit$ | async"
                                       (clicked)="editCouncilRfi(councilRfi)">
                            </cb-button>
                            <cb-button [isMenuItem]="true"
                                       icon="pageview"
                                       menuItemLabel="View Council Rfi"
                                       (clicked)="viewLot(councilRfi.lotId)">
                            </cb-button>
                        </mat-menu>
                    </td>
                </tr>
                </tbody>
            </cb-table>
        </mat-card-content>
    </mat-card>
</div>
