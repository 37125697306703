<cb-table [loaded]="true"
          [stringColumns]="columnsNames"
          [tableLabel]="label"
          class="cb-margin-top-2x">
    <tbody cbTableBody>
    <ng-container *ngFor="let item of value">
        <tr *ngIf="!isEmpty(item)">
            <ng-container *ngFor="let column of columnsValues">
                <td>
                    <ng-container [ngSwitch]="column.type">
                        <ng-container *ngSwitchCase="'text'">
                            {{ item[column.valueProp] }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'checkbox'">
                            <mat-icon class="cb-grey">
                                {{ item[column.valueProp] ? 'check_box' : 'check_box_outline_blank' }}
                            </mat-icon>
                        </ng-container>
                    </ng-container>
                </td>
            </ng-container>

            <td cbTableShrinkCol
                cbTableStickyCol="end"
                class="layoutGap-4">
                <button mat-icon-button (click)="editItemClicked(item)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button (click)="deleteItemClicked(item)">
                    <mat-icon>remove_circle</mat-icon>
                </button>
            </td>
        </tr>
    </ng-container>
    <tr>
        <td colspan="5"
            [style.textAlign]="'right'">
            <button mat-icon-button
                    (click)="newItemClicked()">
                <mat-icon>add</mat-icon>
            </button>
        </td>
    </tr>
    </tbody>
</cb-table>