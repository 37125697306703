import { Component, OnInit, Inject, ViewChildren } from '@angular/core';
import { UsersLogicService } from '@app/logic/users';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { ISkinnyUserDto, IUserDto, LOT_CONTRACT_TYPE_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotMappedItem } from '@app/logic/lots';
import { FormMode } from '@app/shared/enums/form';
import { findIndex } from 'lodash';
import { ILeadLotInterestDto } from '@app/logic/leads/interfaces/i.lead.lot.interest.dto';
import { ILeadDto } from '@app/logic/leads';
import { NgForm } from '@angular/forms';

interface IData {
    houseLandLot: ILotMappedItem;
}

@Component({
    selector: 'app-add-house-land-lead-dialog',
    templateUrl: './add-house-land-lead-dialog.component.html',
    styleUrls: ['./add-house-land-lead-dialog.component.scss']
})
export class AddHouseLandLeadDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '40%';

    public mappedItem: ILeadMappedItem;
    public houseLandLot: ILotMappedItem;
    public leadSearchParams = {
        status: ['hot', 'cold'],
        contractTypes: [LOT_CONTRACT_TYPE_ENUM.HouseAndLand, LOT_CONTRACT_TYPE_ENUM.DesignAndLand] // default allowed contract types
    };
    public selectedBuildingConsultantId: string;
    public buildingConsultants: IUserDto[];
    @ViewChildren('addHouseLandLeadForm') public addHouseLandLeadFormParent;
    public get addHouseLandLeadForm(): NgForm {
        return this.addHouseLandLeadFormParent.first;
    }

    constructor(
        private readonly usersLogicService: UsersLogicService,
        public readonly dialogRef: MatDialogRef<AddHouseLandLeadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        public toastService: ToastService) {
        super(dialogRef, toastService);
        this.loadLot();
    }

    public ngOnInit(): void {
        this.formMode = FormMode.Add;
    }

    public getTitle(): string {
        if (this.data.houseLandLot.contractType === LOT_CONTRACT_TYPE_ENUM.DesignAndLand) {
            return 'Add Design & Land to Lead';
        }
        return 'Add House & Land to Lead';
    }

    public validateSelectedLead(): void {
        const lotExistsOnLead = findIndex(this.mappedItem.interests, interest => interest.lotId === this.houseLandLot.$id) > -1;
        if (lotExistsOnLead) {
            this.addHouseLandLeadForm.form.controls.selectedLead.setErrors({ alreadyExists: true });
        } else {
            this.addHouseLandLeadForm.form.controls.selectedLead.setErrors(null);
        }
    }

    public saveLeadInterest(): void {
        const lotRead = this.houseLandLot.$getMappedDtoItem();
        const interest = {
            id: 0,
            buildTypeId: lotRead.buildType.id,
            contractType: lotRead.contractType,
            lotNumber: lotRead.lotNumber,
            isPrimary: false,
            isQualified: false,
            locationId: lotRead.locationId,
            lot: lotRead,
            lotId: lotRead.id as number,
            lotIsNotAvailable: false,
            roadDirection: lotRead.roadDirection
        } as ILeadLotInterestDto;
        this.mappedItem.addUpdateInterestObservable(interest).subOnce(lead => this.saveSuccess(lead));
    }

    private saveSuccess = (lead: ILeadDto): void => {
        this.houseLandLot.numberOfInterests++;

        const consultants = this.houseLandLot.buildingConsultantsWithLotInterest || [];
        if (consultants.findIndex(x => x.id === this.mappedItem.buildingConsultantId) < 0) {
            this.usersLogicService
                .$getItem(this.mappedItem.buildingConsultantId)
                .subOnce(user => {
                    consultants.push(user as unknown as ISkinnyUserDto);
                    this.houseLandLot.buildingConsultantsWithLotInterest = consultants;
                    this.handleNext(lead);
                });
        }

        this.handleNext(lead);
    };

    private loadLot(): void {
        this.houseLandLot = this.data.houseLandLot;
        this.leadSearchParams.contractTypes = [
            this.houseLandLot.contractType
        ];
    }
}
