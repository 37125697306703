import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {cloneDeepSafe} from '@app/shared/utils/clone-object.util';
import {CbViewPdfDialogComponent, cleanObjectDeep, DialogLoadingSpinnerComponent} from 'cb-hub-lib';
import FileSaver from 'file-saver';
import {catchError, map, Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

type THeaders = Object | TDefaultHeaders;

interface IStringDictionary { [name: string]: string | string[] }

type TDefaultHeaders = string | IStringDictionary;

type TBody = TBodyObject | TBodyArray;

type TBodyObject = Object | {
    [key: string]: TBody;
    [key: number]: TBody;
};

type TBodyArray = Array<TBody | TBodyProp>;

type TBodyProp = TBody | boolean | number | string | null | undefined;

type TResponseType = 'arraybuffer' | 'blob' | 'json' | 'text';

type TQueryParamIndexObject = TQueryParamIndex | Object;

type TQueryParamIndex = { [param: string]: string | string[] };

@Injectable()
export class HttpWrapperService {

    constructor(
        private readonly snackBar: MatSnackBar,
        private readonly dialog: MatDialog,
        private readonly httpClient: HttpClient
    ) { }

    public get<ResultType, ParamType extends TQueryParamIndex = {}, HeaderType extends THeaders = {}>(
        uri: string,
        parameters: TQueryParamIndexObject = {},
        responseType: TResponseType = 'json',
        headers = {} as HeaderType
    ): Observable<ResultType> {
        const params = new HttpParams({ fromObject: this.tranformsParamDates(parameters) });
        return this.httpClient.get<ResultType>(
            this.buildUrl(uri),
            {
                headers: this.getHeaders(headers),
                responseType: responseType as any,
                params
            }
        );
    }

    public post<ResultType, BodyType extends TBody = {}, HeaderType extends THeaders = {}>(
        uri: string,
        body = null as BodyType,
        responseType: TResponseType = 'json',
        headers = {} as HeaderType,
        parameters: TQueryParamIndexObject = {}
    ): Observable<ResultType> {
        const params = new HttpParams({ fromObject: this.tranformsParamDates(parameters) });

        return this.httpClient.post<ResultType>(
            this.buildUrl(uri),
            body,
            {
                headers: this.getHeaders(headers),
                responseType: responseType as any,
                params
            }
        );
    }

    public put<ResultType, BodyType extends TBody = {}, HeaderType extends THeaders = {}>(
        uri: string,
        body = {} as BodyType,
        responseType: TResponseType = 'json',
        headers = {} as HeaderType,
        parameters: TQueryParamIndexObject = {}
    ): Observable<ResultType> {
        const params = new HttpParams({ fromObject: this.tranformsParamDates(parameters) });

        return this.httpClient.put<ResultType>(
            this.buildUrl(uri),
            body,
            {
                headers: this.getHeaders(headers),
                responseType: responseType as any,
                params
            }
        );
    }

    public delete<ResultType, HeaderType extends THeaders = {}>(
        uri: string,
        responseType: TResponseType = 'json',
        headers = {} as HeaderType,
        parameters: TQueryParamIndexObject = {}
    ): Observable<ResultType> {

        const params = new HttpParams({ fromObject: this.tranformsParamDates(parameters) });

        return this.httpClient.delete<ResultType>(
            this.buildUrl(uri),
            {
                headers: this.getHeaders(headers),
                responseType: responseType as any,
                params
            }
        );
    }

    private buildUrl(uri: string): string {
        const url = `${environment.api}/api${this.sanitiseUri(uri)}`;
        return url;
    }

    private sanitiseUri(uri: string): string {
        uri = uri.trim();
        if (!uri.startsWith('/')) {
            uri = uri.padStart(uri.length + 1, '/');
        }
        return uri;
    }

    /** Downloads a file from the provided url */
    public download<ReturnType>(
        url: string,
        message?: string,
        parameters: TQueryParamIndexObject = {},
        method: 'get' | 'post' = 'get',
        postBody: any = new FormData(),
        isView = false
    ): Observable<ReturnType> {
        const params = new HttpParams({ fromObject: this.tranformsParamDates(parameters) });
        const options: any = {
            headers: this.getHeaders(),
            responseType: 'arraybuffer',
            observe: 'response',
            params
        };

        const dialogRef = this.dialog.open(
            DialogLoadingSpinnerComponent,
            {
                data: { message },
                width: '400px',
                height: '100px',
                panelClass: 'cb-dialog-translucent-bg'
            }
        );
        const request = method === 'get' ? this.httpClient.get(this.buildUrl(url), options) : this.httpClient.post(this.buildUrl(url), postBody, options);
        return request
            .pipe(
                map((response) => {
                    if (response) {
                        this.handleDownloadResponse(dialogRef, response as any, isView);
                    }
                    return response;
                }),
                catchError((error: any): any => {
                    if (error.status === 404) {
                        this.closeDialogAndHandleError(dialogRef, error);
                    }
                    return this.closeDialogAndHandleError(dialogRef, error);
                })
            ) as Observable<ReturnType>;
    }

    /** make the download request without handling the response */
    public downloadRequest(url: string, message?: string, parameters: TQueryParamIndexObject = {}, method: 'get' | 'post' = 'get'): Observable<HttpResponse<ArrayBuffer>> {
        const params = new HttpParams({ fromObject: this.tranformsParamDates(parameters) });
        const options: any = {
            headers: this.getHeaders(),
            responseType: 'arraybuffer',
            observe: 'response',
            params
        };

        const request = method === 'get'
            ? this.httpClient.get(this.buildUrl(url), options)
            : this.httpClient.post(this.buildUrl(url), new FormData(), options);
        return request as unknown as Observable<HttpResponse<ArrayBuffer>>;
    }

    public getHeaders<HeaderType extends THeaders = {}>(customHeaders = {} as HeaderType): HttpHeaders {
        let headers: IStringDictionary;
        if (typeof (customHeaders) === 'object') {
            headers = cleanObjectDeep({ ...customHeaders as Object }) as IStringDictionary;
        }
        return new HttpHeaders(headers ?? customHeaders as TDefaultHeaders);
    }

    private readonly closeDialogAndHandleError = (dialogRef: MatDialogRef<Object>, error: HttpErrorResponse): void => {
        dialogRef.close();
        this.openSnackBar('Download Failed', 'Ok');
        this.handleError(error);
    };

    private openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
            verticalPosition: 'top',
            panelClass: ['snackbar']
        });
    }

    private readonly handleDownloadResponse = (dialogRef: MatDialogRef<DialogLoadingSpinnerComponent>, response: HttpResponse<ArrayBuffer>, isView: boolean): void => {
        try {
            dialogRef.close();
            const fileType = response.headers.get('content-type').trim();
            const blob = new Blob([response.body], { type: fileType });
            const filename = response.headers.get('content-disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '');


            if (isView) {
                const dialog = this.dialog;
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function() {
                    const base64data = reader.result;
                    dialog.open(CbViewPdfDialogComponent, {
                        width: '100vw',
                        maxWidth: '100vw',
                        height: '100%',
                        maxHeight: '100%',
                        data: {
                            fileName: filename,
                            src: base64data,
                            blob
                        },
                    }
                    );
                };
            } else {
                FileSaver.saveAs(blob, filename);
            }

        } catch (error) {
            dialogRef.close();
            this.handleError(error);
        }
    };

    protected handleError(error: HttpErrorResponse): Observable<never> {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else if (error instanceof Error) {
            // A client-side js error occured
            console.error(error);
        } else {
            // the backend returned an unsuccessful response code.
            // the response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        throw new Error('Something bad happened; please try again later.');
    }

    private tranformsParamDates(parameters: TQueryParamIndexObject): TQueryParamIndex {
        if (!parameters) {
            return {};
        }
        const cloned = cloneDeepSafe(parameters);

        for (const [key, value] of Object.entries(cloned)) {
            if (!(value instanceof Date)) {
                continue;
            }
            const date = (new Date(value as any)).toJSON();
            if (date) {
                cloned[key] = date;
            }
        }

        return (cloned ?? {}) as TQueryParamIndex;
    }
}
