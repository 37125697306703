import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CbEnumService, SelectListItem } from '@app/core/services/enum/enum.service';
import { UserCacheService } from '@app/core/services/user-cache/user-cache.service';
import { filter, some, uniq } from 'lodash';
import { DASHBOARD_WIDGET, IDashboardWidget } from '../dashboard-base-widget/dashboard-base-widget.directive';

@Component({
    selector: 'cb-dashboard-add-widget-dialog',
    templateUrl: './dashboard-add-widget-dialog.component.html',
    styleUrls: ['./dashboard-add-widget-dialog.component.scss']
})
export class DashboardAddWidgetDialogComponent {
    public static readonly MIN_WIDTH = '75%';

    public availableWidgets: SelectListItem[] = [];
    public selectedWidgetIds: number[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            selectedWidgets: IDashboardWidget[];
        },
        public readonly dialogRef: MatDialogRef<DashboardAddWidgetDialogComponent>,
        protected readonly cbEnumService: CbEnumService,
        protected readonly userCacheService: UserCacheService,
    ) {
        this.selectedWidgetIds = uniq(data.selectedWidgets.map(x => x.type));
        this.userCacheService.dashboardWidgetConfig.init().then(() => {
            this.availableWidgets = filter(this.cbEnumService.getSelectList(DASHBOARD_WIDGET),
                enumItem => !some(data.selectedWidgets, existingWidget => existingWidget.type === enumItem.id));
        });
    }

    public isWidgetSelected(widgetId: number): boolean {
        return this.selectedWidgetIds.indexOf(widgetId) > -1;
    }

    public onWidgetChanged(widgetId: number, isSelected: boolean): void {
        const index = this.selectedWidgetIds.indexOf(widgetId);
        if (isSelected && index === -1) {
            this.selectedWidgetIds.push(widgetId);
        } else if (!isSelected && index > -1) {
            this.selectedWidgetIds.splice(index, 1);
        }
    }

    public canSave(): boolean {
        return !!this.availableWidgets?.length;
    }

    public onSaveClicked(): void {
        if (this.canSave()) {
            this.dialogRef.close(this.selectedWidgetIds);
        }
    }
}
