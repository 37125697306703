<cb-dialog dialogHeading="Manage Predecessor">
    <form class="flex-col"
          #editForm="ngForm">
        <cb-display-value-new *ngIf="data?.oldPredecessorBuildProgrammeActivity?.id > 0"
                              label="'Current Predecessor Activity'"
                              [value]="data?.oldPredecessorBuildProgrammeActivity?.name">
        </cb-display-value-new>
        <cb-build-programme-activity-autocomplete-scroller *ngIf="buildProgramme.id"
                                                           class="cb-margin-top"
                                                           label="New Predecessor Activity"
                                                           [(ngModel)]="newPredecessorBuildProgrammeActivity"
                                                           [buildProgrammeId]="buildProgramme.id"
                                                           [requireMatch]="true"
                                                           [matchProps]="['id']"
                                                           [required]="true"
                                                           [exclIds]="exclIds"
                                                           [exclStatuses]="exclStatuses"
                                                           [isControlledByParentLot]="isControlledByParentLot.value"
                                                           [itemLabel]="itemLabel"
                                                           name="newActivity">
        </cb-build-programme-activity-autocomplete-scroller>
        <span class="cb-margin-top cb-field-label">Date Calculation</span>
        <mat-radio-group required="true"
                         class="cb-margin-top"
                         [(ngModel)]="dateCalcType"
                         name="dateCacleType">
            <mat-radio-button class="cb-margin-right"
                              *ngFor="let dateCalcType of dateCalcTypes"
                              [value]="dateCalcType.id">{{dateCalcType.label}}</mat-radio-button>
        </mat-radio-group>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="editForm.invalid || editForm.pristine"
                (click)="save()">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
