import { Component, Input } from '@angular/core';
import { FormMode } from '@app/shared/enums/form';
import { IStandardPlanMappedItem } from '@app/logic/standard-plans/interfaces/i.standard-plan.mapped';
import { IStandardPlanStyleDto } from '@app/logic/styles/interfaces/i.style.dto';
import { MatDialog } from '@angular/material/dialog';
import { StandardPlanDialogComponent } from '../standard-plan-dialog/standard-plan-dialog';
import { IAddressRegionDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'cb-standard-plan-card',
    templateUrl: './standard-plan-card.component.html',
    styleUrls: ['./standard-plan-card.component.scss']
})
export class StandardPlanCardComponent {

    @Input() public addressRegions: IAddressRegionDto[];
    @Input() public standardPlans: IStandardPlanMappedItem[];
    @Input() public styles: IStandardPlanStyleDto[];

    constructor(
        public readonly dialog: MatDialog) { }

    public getDisplayImage(plan: IStandardPlanMappedItem): string {

        const stylesWithImage = plan.stylesWithUrl.filter(x => x.isPlaceHolderImage === false);

        if (stylesWithImage.length > 0) {
            return `${stylesWithImage[0].url}?w=600&h=400&scale=canvas`;
        } else {
            return 'assets/img/no-image-placeholder.png?w=600&h=400&scale=canvas';
        }
    }


    public openPlan(plan: IStandardPlanMappedItem): void {
        const dialogRef = this.dialog
            .open(
                StandardPlanDialogComponent,
                {
                    data: { standardPlans: this.standardPlans, plan, formMode: FormMode.View, addressRegions: this.addressRegions },
                    panelClass: 'cb-dialog-container',
                    minWidth: 400,
                    width: '600px',
                }
            );

        const subscription = dialogRef.componentInstance.onSave.subscribe((data) => {
            this.standardPlans = data;
        });

        dialogRef.afterClosed().subscribe(() => {
            subscription.unsubscribe();
        });
    }

    public getCostRange(plan: IStandardPlanMappedItem): string {
        let returnValue = 'N/A';
        if (!plan.planCosts) {
            return returnValue;
        }

        const activeCosts = plan.planCosts.filter(cost => cost.isActive).map(o => o.totalCost);
        if (!activeCosts || activeCosts && activeCosts.length < 1) {
            return returnValue;
        }

        const _minimum = Math.min.apply(Math, activeCosts);
        const _maximum = Math.max.apply(Math, activeCosts);

        const minK = Math.floor((_minimum / 1000));
        const maxK = Math.ceil((_maximum / 1000));

        returnValue = `$${minK}k - $${maxK}k`;

        return returnValue;
    }
}
