<div class="start-center flex">
    <span class="mat-headline-6 flex">
        Default Suppliers
    </span>
    <button mat-raised-button
            (click)="newItemClicked();"
            color="primary"
            [disabled]="!mappedItem?.id">
        Add New Default Supplier
    </button>
</div>
<cb-table class="cb-margin-top"
          [loaded]="true"
          [stringColumns]="columns"
          [maxHeightOffset]="287">
    <tr cbTableRow
        *ngFor="let result of searchResults">
        <cb-td-text [value]="result.supplyType?.path"></cb-td-text>
        <cb-td-text [value]="result.supplier?.label"></cb-td-text>
        <td cbTableStickyCol="end"
            cbTableShrinkCol>
            <button mat-icon-button
                    (click)="editItemClickedAlternative(result)">
                <mat-icon>
                    edit
                </mat-icon>
            </button>
            <button class="cb-margin-left"
                    mat-icon-button
                    (click)="deleteClicked(result)">
                <mat-icon>
                    delete
                </mat-icon>
            </button>
        </td>
    </tr>
</cb-table>
