import { Component, OnInit, Inject } from '@angular/core';
import { ILeadMappedItem } from '@app/logic/leads/interfaces/i.lead.mapped';
import { keys, pickBy } from 'lodash';
import {
    AccountTypeEnumId,
    LotTypeEnumId,
    LotContractTypeEnumId,
    MarketStatusEnumId,
    ACCOUNT_TYPE_ENUM,
    LOT_TYPE_ENUM,
    LOT_CONTRACT_TYPE_ENUM,
    MARKET_STATUS_ENUM,
    IClientAccountDto
} from '@classictechsolutions/hubapi-transpiled-enums';
import { ClientAccountPermissions } from '@app/core/permissions';
import { ClientAccountLogicService } from '@app/logic/client-account/client-account.logic.service';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import { BuildTypeLogicService } from '@app/logic/build-type';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { FormMode } from '@app/shared/enums/form';
import { ToastService } from '@app/core/services/toast/toast.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ILeadLotInterestDto } from '@app/logic/leads/interfaces/i.lead.lot.interest.dto';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';

interface IData {
    mappedItem: ILeadMappedItem;
}

@Component({
    selector: 'cb-lead-qualify-house-land-dialog',
    templateUrl: './lead-qualify-house-land-dialog.component.html',
    styleUrls: ['./lead-qualify-house-land-dialog.component.scss']
})
export class LeadQualifyHouseLandDialogComponent extends BaseDialogFormViewDirective<any, any, any> implements OnInit {
    public static readonly MIN_WIDTH = '85%';

    public mappedItem: ILeadMappedItem;
    public selectedLots: number[] = [];
    public accountMode = 'create';
    public accountTypes: IEnumLookup<AccountTypeEnumId>[];
    public lotTypes: IEnumLookup<LotTypeEnumId>[];
    public lotContractTypes: IEnumLookup<LotContractTypeEnumId>[];
    public buildTypes: any[];
    public marketStatus: IEnumLookup<MarketStatusEnumId>[];
    public data: any;
    public selectedAccount: IClientAccountDto;
    public readonly accountSearchParams = { status: ['open', 'locked'] };

    constructor(
        private readonly accountLogic: ClientAccountLogicService,
        private readonly buildTypeLogic: BuildTypeLogicService,
        private readonly navigationService: NavigationService,
        public readonly dialogRef: MatDialogRef<LeadQualifyHouseLandDialogComponent>,
        @Inject(CbDialogService) public readonly cbDialog: CbDialogService,
        @Inject(MAT_DIALOG_DATA) public readonly dta: IData,
        public toastService: ToastService,
        private readonly clientAccountPermissions: ClientAccountPermissions
    ) {
        super(dialogRef, toastService);
        this.formMode = FormMode.Edit;
        this.mappedItem = dta.mappedItem;
        this.accountTypes = ACCOUNT_TYPE_ENUM.toLookup();
        this.lotTypes = LOT_TYPE_ENUM.toLookup();
        this.lotContractTypes = LOT_CONTRACT_TYPE_ENUM.toLookup();
        this.buildTypeLogic
            .$getList()
            .subOnce(x => this.buildTypes = x);
        this.marketStatus = MARKET_STATUS_ENUM.toLookup();
    }

    public ngOnInit(): void {

        this.data = {
            lotIds: []
        } as any;

        if (this.mappedItem.interests.length === 1) {
            this.selectedLots.push(this.mappedItem.interests[0].lotId);
        }
    }

    public formatAddress(address): string {
        return address ? address.street + ', ' + address.suburb : '';
    }

    public selectedAccountChanged(): void {
        if (this.selectedAccount) {
            this.accountMode = 'view';
            this.data.clientAccountId = this.selectedAccount.id;
            this.mappedItem.accountId = this.selectedAccount.id;
            return;
        }
        this.accountMode = 'create';
    }

    public nameOnBlur(): void {
        if (this.data.clientAccount) {
            if (this.data.clientAccount.legalName === '' || this.data.clientAccount.legalName === undefined) {
                this.data.clientAccount.legalName = this.data.clientAccount.name;
            }
        }
    }

    public hasLots(): boolean {
        return keys(pickBy(this.selectedLots, (value, key) => value)).length > 0;
    }

    public isHouseAndLandOrDesignAndLand(lotContractTypeId: LotContractTypeEnumId): boolean {
        return lotContractTypeId === LOT_CONTRACT_TYPE_ENUM.HouseAndLand || lotContractTypeId === LOT_CONTRACT_TYPE_ENUM.DesignAndLand;
    }

    public isAvailable(marketStatusId: MarketStatusEnumId): boolean {
        return marketStatusId === MARKET_STATUS_ENUM.Available;
    }

    public canCreate(): boolean {
        return this.clientAccountPermissions.canCreate();
    }

    public selectedLotsChanged(checked: MatCheckboxChange, interest: ILeadLotInterestDto): void {
        if (checked) {
            if (!this.selectedLots.includes(interest.lotId)) {
                this.selectedLots.push(interest.lotId);
            }
        } else {
            if (this.selectedLots.includes(interest.lotId)) {
                this.selectedLots = this.selectedLots.filter(lotId => lotId !== interest.lotId);
            }
        }
    }

    public createClientAccountChange(): void {
        if (this.data.createNewClientAccount) {
            this.selectedAccount = undefined;
            this.data.clientAccountId = undefined;
        } else {
            this.data.clientAccountId = this.selectedAccount?.id;
        }
    }

    public interestSelected = (interestId: number): boolean => {
        return this.selectedLots?.includes(interestId);
    };

    public canSelectLot(interest: ILeadLotInterestDto): boolean {
        return this.isAvailable(interest.lot.marketStatus)
            && this.mappedItem.canQualify
            && this.isHouseAndLandOrDesignAndLand(interest.contractType);
    }

    public filterInterests = (leadInterest: ILeadLotInterestDto) => {
        return this.isHouseAndLandOrDesignAndLand(leadInterest.contractType) && leadInterest != null;
    };

    public saveDisabled = (): boolean => {
        if (!this.selectedLots || !this.selectedLots.length) {
            return true;
        }
        return false;
    };

    public save(): void {
        this.data.lotIds = this.selectedLots;
        this.createClientAccountChange();

        this.cbDialog.confirm({
            dialogHeading: 'Qualify Lead',
            message: 'Are you sure you want to qualify this lead?',
            confirmed: () => {
                this.mappedItem.qualify(this.data).subOnce((qualifyResult) => {
                    this.handleNext({ qualifyResult, qualifiedLotIds: this.data.lotIds });
                });
            }
        });
    }

}
