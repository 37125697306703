<cb-dialog dialogHeading="Purchase Order Usage Report">
    <form class="flex-row flex"
          #purchaseOrderUsageReportForm="ngForm">
        <cb-datepicker name="purchaseOrderDateFrom"
                       [required]="true"
                       class="cb-margin-right-2x flex"
                       [(ngModel)]="purchaseOrderDateFrom"
                       label="Order Date From"
                       pickerType="calendar">
          </cb-datepicker>
        <cb-datepicker class="flex"
                       name="purchaseOrderDateTo"
                       [(ngModel)]="purchaseOrderDateTo"
                       label="Order Date To"
                       [required]="true"
                       pickerType="calendar">
          </cb-datepicker>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                [disabled]="purchaseOrderUsageReportForm.invalid"
                (click)="downloadUsageReport()">Download</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
