import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpWrapperService } from '@app/core/services/http-wrapper/http-wrapper.service';
import { BaseLogicService } from '../base/base-logic.service';
import { ISpecTemplateLogicService } from './interfaces/i.spec-template.logic.service';
import { ISpecHouseAreaDto, ISpecTemplateColourItemDto, ISpecTemplateDto, ISpecTemplateFilter, ISpecTemplateItemDto } from './interfaces/i.spec-template.dto';
import { SpecTemplateMappedItem } from './spec-template.mapped';
import { ISpecTemplateMappedItem } from './interfaces/i.spec-template.mapped';
import { ISkinnySpecTemplateDto } from '@classictechsolutions/hubapi-transpiled-enums';

@Injectable()
export class SpecTemplateLogicService extends BaseLogicService<ISpecTemplateDto, ISpecTemplateMappedItem> implements ISpecTemplateLogicService {
    constructor(
        protected readonly $http: HttpWrapperService,
        protected readonly $injector: Injector,
        protected readonly httpClient: HttpClient,
    ) {
        super('spectemplates', SpecTemplateMappedItem);
    }

    public search(filters: ISpecTemplateFilter): Observable<ISkinnySpecTemplateDto[]> {
        return this.$http.get(`${this.$baseUri}/search`, filters);
    }

    public generateReport(templateId: number): Observable<unknown> {
        return this.$http.download(`${this.$baseUri}/${templateId}/report`);
    }

    public getHouseAreas(templateId: number): Observable<ISpecHouseAreaDto[]> {
        return this.$http.get(`${this.$baseUri}/${templateId}/houseareas`);
    }

    public getColourItems(templateId: number): Observable<ISpecTemplateColourItemDto[]> {
        return this.$http.get(`${this.$baseUri}/${templateId}/colouritems`);
    }

    public deleteItem(templateId: number, item: ISpecTemplateItemDto | ISpecTemplateColourItemDto): Observable<boolean> {
        return this.$http.delete(`${this.$baseUri}/${templateId}/${(item as ISpecTemplateColourItemDto).colourItem ? 'colour' : ''}items/${item.id}`);
    }

    public editColourItem(itemId: number, item: ISpecTemplateColourItemDto): Observable<ISpecTemplateColourItemDto> {
        const newBaseUri = this.$baseUri.replace(/spectemplates/g, 'spectemplate');
        return this.$http.post(`${newBaseUri}colouritems/${itemId}`, item);
    }

    public addColourItem(templateId: number, houseAreaId: number, item: ISpecTemplateColourItemDto): Observable<ISpecTemplateColourItemDto> {
        return this.$http.post(`${this.$baseUri}/${templateId}/houseareas/${houseAreaId}/colouritems`, item);
    }

    public addEditItem(templateId: number, houseAreaId: number, item: ISpecTemplateItemDto): Observable<ISpecTemplateItemDto> {
        return this.$http.post(`${this.$baseUri}/${templateId}/houseareas/${houseAreaId}/items/${item.id ? item.id : ''}`, { specTemplateItem: item });
    }
}
