<cb-dialog dialogHeading="Upload CostX Export">
    <form class="flex-col flex"
          #form="ngForm">
        <cb-drag-and-drop-document [(documentEntityMappedItem)]="document">
        </cb-drag-and-drop-document>
        <cb-datepicker name="lotEstimatedStartDate"
                       [(ngModel)]="lotEstimatedStartDate"
                       pickerType="calendar"
                       [nonWorkingDays]="nonWorkingDays"
                       *ngIf="canSetStartDate"
                       [required]="true"
                       label="Lot Estimated Start">
        </cb-datepicker>
        <cb-datepicker name="lotEstimatedEndDate"
                       [(ngModel)]="lotEstimatedEndDate"
                       pickerType="calendar"
                       [required]="true"
                       *ngIf="canSetEndDate"
                       label="Lot Estimated End">
        </cb-datepicker>
    </form>
    <div actions>
        <button mat-raised-button
                [disabled]="saveDisabled()"
                color="primary"
                (click)="save()">
            Upload
        </button>
        <button mat-raised-button
                (click)="cancel()">
            Cancel
        </button>
    </div>
</cb-dialog>
