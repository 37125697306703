import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import {
    DocumentTemplateLogicService, DocumentTemplateMappedItem,
    IDocumentTemplateDto, IDocumentTemplateLogicService, IDocumentTemplateMappedItem
} from '@app/logic/document-template';
import { ISkinnyDocumentTemplateHistoryDto } from '@classictechsolutions/hubapi-transpiled-enums';

interface IData {
    mappedItem: DocumentTemplateMappedItem;
}

@Component({
    selector: 'cb-document-template-history-dialog',
    templateUrl: './document-template-history-dialog.component.html',
    styleUrls: ['./document-template-history-dialog.component.scss']
})
export class DocumentTemplateHistoryDialogComponent extends BaseDialogFormViewDirective<IDocumentTemplateDto, IDocumentTemplateMappedItem, IDocumentTemplateLogicService> {
    public static readonly MIN_WIDTH = '75%';
    public readonly displayedColumns: string[] = ['fileName', 'uploadedBy', 'dateUploaded', 'actions'];
    public mappedItem: DocumentTemplateMappedItem;
    public history: ISkinnyDocumentTemplateHistoryDto[];

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<DocumentTemplateHistoryDialogComponent>,
        protected readonly documentTemplateLogicService: DocumentTemplateLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        this.documentTemplateLogicService.getHistory(this.mappedItem.id).subOnce((result) => this.history = result);
    }

    public onDownloadClicked(history: ISkinnyDocumentTemplateHistoryDto): void {
        this.documentTemplateLogicService.downloadHistory(history.id).subOnce();
    }
}
