import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { ToastService } from '@app/core/services/toast/toast.service';
import { IChangeRecordLogicService } from '@app/logic/change-records/interfaces/i.change-record.logic-service';
import { IChangeRecordMappedItem } from '@app/logic/change-records/interfaces/i.change-record.mapped';
import { CouncilRfiLogicService } from '@app/logic/council-rfis/council-rfi-logic.service';
import { ILotMappedItem } from '@app/logic/lots';
import { IUserDto, UsersLogicService } from '@app/logic/users';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { GuIdAndLabelDto } from '@app/shared/dtos/id-label.dto';
import { FormMode } from '@app/shared/enums/form';
import {
    ChangeSourceEnumId,
    CHANGE_SOURCE_ENUM,
    CostNatureEnumId,
    COST_NATURE_ENUM,
    IChangeRecordDto,
    ICouncilRfiDto,
    IIdAndLabelDto,
    ITeamDto,
    TEAM_CODES_CONST
} from '@classictechsolutions/hubapi-transpiled-enums';
import { IEnumLookup } from '@classictechsolutions/typescriptenums';
import _ from 'lodash';
import { Observable } from 'rxjs';

interface IData {
    mappedItem: IChangeRecordMappedItem;
    lotMappedItem: ILotMappedItem;
    mode: FormMode;
    teams: ITeamDto[];
}

@Component({
    selector: 'cb-lot-create-change-dialog',
    templateUrl: './lot-create-change-dialog.component.html',
    styleUrls: ['./lot-create-change-dialog.component.scss']
})
export class LotCreateChangeDialogComponent extends BaseDialogFormViewDirective<IChangeRecordDto, IChangeRecordMappedItem, IChangeRecordLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '500px';
    public readonly QS_TEAM = [TEAM_CODES_CONST.QsTeamKey];

    public costNatures: IEnumLookup<CostNatureEnumId>[] = [];
    public sourcesOfChange: IEnumLookup<ChangeSourceEnumId>[] = [];

    public lotMappedItem: ILotMappedItem;

    public teams: ITeamDto[];
    public selectedOwnerteams: ITeamDto[];

    public councilRfis: ICouncilRfiDto[];

    public selectedOwner: GuIdAndLabelDto;
    public selectedReviewer: GuIdAndLabelDto;
    public selectedBusinessAccount: IIdAndLabelDto;

    public qsReviewRequired: boolean;

    constructor(
        public readonly dialogRef: MatDialogRef<LotCreateChangeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        @Inject(CouncilRfiLogicService) public readonly councilRfiLogic: CouncilRfiLogicService,
        @Inject(UsersLogicService) public readonly usersLogicService: UsersLogicService,
        @Inject(CurrentUserService) public readonly currentUserService: CurrentUserService,

    ) {
        super(dialogRef, toastService);
        this.formMode = this.data.mode;
        this.mappedItem = data.mappedItem;
        this.lotMappedItem = data.lotMappedItem;
        this.teams = data.teams;
    }

    public ngOnInit(): void {
        this.loadCostNatures();
        this.loadSourcesOfChange();
        this.loadCouncilRfi();
        this.loadChangeOwner();
        this.loadChangeReviewer();
        this.loadSupplier();
        this.setQSRequired();
    }


    private loadChangeOwner(): void {
        if (this.mappedItem.ownerId) {
            this.selectedOwner = {
                id: this.mappedItem.ownerId,
                label: this.mappedItem.owner
            };

            this.ownerChanged();

        } else {
            this.usersLogicService.$getItem(this.currentUserService.guid).subOnce(user => {
                this.selectedOwner = {
                    id: user.id,
                    label: `${user.firstName} ${user.lastName}`
                };

                this.ownerChanged();

            });
        }
    }

    private loadChangeReviewer(): void {
        if (this.mappedItem.reviewerId) {
            this.selectedReviewer = {
                id: this.mappedItem.reviewerId,
                label: this.mappedItem.reviewer
            };
        }
    }

    private loadSupplier(): void {
        if (this.mappedItem.businessAccountId) {
            this.selectedBusinessAccount = {
                id: this.mappedItem.businessAccountId,
                label: this.mappedItem.businessAccountLabel
            };
        }
    }

    private loadCostNatures(): void {
        this.costNatures = COST_NATURE_ENUM.toLookup({
            transform: result => {
                if (!this.lotMappedItem?.hasClientSale) {
                    result = result.filter(cn => cn.id !== COST_NATURE_ENUM.ExtraToClient);
                }
                return result;
            }
        });
    }

    private loadSourcesOfChange(): void {
        this.sourcesOfChange = CHANGE_SOURCE_ENUM.toLookup({
            transform: result => {
                if (!this.lotMappedItem.hasConsents) {
                    result = result.filter(cn => cn.id !== CHANGE_SOURCE_ENUM.CouncilRequest);
                }
                return result;
            }
        });
    }

    private loadCouncilRfi(): void {
        this.councilRfiLogic.getByLotId(this.lotMappedItem.id).subOnce((result) => {
            this.councilRfis = result;
        });
    }

    private setQSRequired(): void {
        if (this.mappedItem.reviewerId) {
            this.qsReviewRequired = true;
        } else {
            this.qsReviewRequired = false;
            this.mappedItem.reviewer = undefined;
            this.mappedItem.reviewerId = undefined;
        }
    }

    public isExtraToSupplier(): boolean {
        return this.mappedItem.costNature === COST_NATURE_ENUM.ExtraToSupplier;
    }

    public isCouncilRequest(): boolean {
        return this.mappedItem.changeSource === CHANGE_SOURCE_ENUM.CouncilRequest;
    }

    public currentUserIsInQS = (): boolean => {
        return _.includes(this.teams.map(x => x.key), this.QS_TEAM);
    };

    public teamSelected(): void {
        const selectedTeam = this.teams.find(x => x.key === this.mappedItem.teamCode);
        this.mappedItem.assignedTeamId = selectedTeam.id;
        this.mappedItem.assignedTeam = selectedTeam.name;
    }

    public ownerChanged(): void {
        if (this.selectedOwner.id) {
            this.usersLogicService.$getItem(this.selectedOwner.id).subOnce(this.setOwnerTeams);
        }
    }

    private readonly setOwnerTeams = (user: IUserDto): void => {
        this.selectedOwnerteams = this.teams.filter(team => user.selectedTeams.includes(team.id));
        this.setDefaultTeam();
    };

    private setDefaultTeam(): void {
        if (this.selectedOwnerteams?.length === 1) {
            this.mappedItem.teamCode = this.selectedOwnerteams[0].key;
            this.mappedItem.assignedTeamId = this.selectedOwnerteams[0].id;
            this.mappedItem.assignedTeam = this.selectedOwnerteams[0].name;
        }
    }

    public qsRequiredChanged(): void {
        if (!this.qsReviewRequired) {
            this.mappedItem.reviewer = undefined;
            this.mappedItem.reviewerId = undefined;
        }
    }

    private _preSave(): void {

        if (this.selectedOwner) {
            this.mappedItem.ownerId = this.selectedOwner.id;
        }

        if (this.selectedReviewer) {
            this.mappedItem.reviewerId = this.selectedReviewer.id;
        }

        if (this.selectedBusinessAccount) {
            this.mappedItem.businessAccountId = this.selectedBusinessAccount.id;
        }
    }

    public saveMethod(): Observable<IChangeRecordDto> {
        this._preSave();
        return this.mappedItem.$save();
    }

}
