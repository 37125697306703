<div class="cb-margin-left-3x cb-margin-right-3x cb-margin-bottom-3x flex-col flex">
    <div class="start-center flex-row">
        <h3 class="mat-headline-6 flex">
            Categories
    </h3>
    <button mat-raised-button
            (click)="newItemClicked();"
            [disabled]="!canEdit()"
            color="primary">
        New Category
    </button>
    </div>
    <div class="cb-table cb-margin-top">
        <mat-table [dataSource]="searchResults">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef>
                    Id
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.id}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>
                    Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{element.name}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="isActive">
                <mat-header-cell *matHeaderCellDef>
                    Active
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-icon>
                        {{element.isActive ? "check_box" : "check_box_outline_blank"}}
                    </mat-icon>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions"
                          stickyEnd>
                <mat-header-cell *matHeaderCellDef>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button
                            (click)="editItemClicked(element);"
                            [disabled]="!canEdit()">
                        <mat-icon>
                            edit
                        </mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <cb-info-message *ngIf="searchResultsLoaded && searchResults.length < 1"
                         [message]="'There are no Categories to display'"
                         [icon]="'info_circle'"></cb-info-message>
        <cb-loading-spinner *ngIf="!searchResultsLoaded"></cb-loading-spinner>
    </div>
</div>
