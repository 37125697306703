<div class="cb-margin flex-col flex">
    <div class="flex-row flex"
         *ngIf="showHeaderAndAddButton">
        <span class="mat-body-strong">
            Specific Items that the Designer needs to complete as part of Working Drawings
        </span>
        <span class="flex"></span>
        <button mat-raised-button
                color="primary"
                (click)="addEditItem(FormMode.Add, null)">
            Add Item
        </button>
    </div>
    <mat-divider *ngIf="showHeaderAndAddButton"
                 class="cb-margin"></mat-divider>
    <cb-table class="flex"
              [loaded]="mappedItem !== null"
              emptyMessage="No Items or Changes have been added for this Working Drawing">
        <tbody cbTableBody>
            <ng-container *ngFor="let item of mappedItem.items; index as i">
                <tr *ngIf="!item.isDeleted">
                    <td>
                        Item #{{item.itemNumber}}
                    </td>
                    <td>
                        {{ item.statusId | cbEnumToLabel: WORKING_DRAWING_ITEM_STATUS_ENUM }}
                    </td>
                    <td>
                        <div class="cb-margin flex-col flex">
                            <div class="flex-row flex">{{ item.description }}</div>
                            <div *ngIf="item.comments"
                                 class="cb-margin flex-row flex">
                                <mat-icon class="cb-margin-right">message</mat-icon>
                                <mat-divider [vertical]="true"
                                             class="cb-margin-left cb-margin-right"></mat-divider>
                                {{item.comments}}
                            </div>
                        </div>
                    </td>
                    <td cbTableShrinkCol
                        cbTableStickyCol="end"
                        *ngIf="!isReadOnly">
                        <button mat-icon-button
                                matTooltip="Edit Item"
                                class="cb-margin-right"
                                (click)="addEditItem(FormMode.Edit, item, i)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngIf="showDeleteButton"
                                mat-icon-button
                                matTooltip="Delete Item"
                                class="cb-margin-right"
                                (click)="deleteItem(item)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </cb-table>
</div>
