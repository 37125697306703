<mat-card class="flex-row">
    <mat-card-content class="mat-card-content-row center-center">
        <mat-icon>search</mat-icon>
        <mat-form-field class="cb-margin-left cb-margin-right-2x flex">
            <mat-label>Search Notes</mat-label>
            <input type="text"
                   aria-label="Search Notes"
                   [formControl]="searchText"
                   name="{{entityName}}-task-search"
                   matInput/>
        </mat-form-field>
        <button mat-raised-button
                color="primary"
                *ngIf="canAddTask"
                [disabled]="completableButNotEditable"
                (click)="createTask()">
            Add Task
        </button>
    </mat-card-content>
</mat-card>
<div class="cb-margin-top flex-row flex">
    <div class="cb-margin-right list-container flex-col flex-50">
        <mat-card class="list-card">
            <mat-card-content>
                <span class="mat-body-strong">To Do</span>
            </mat-card-content>
        </mat-card>
        <mat-accordion class="tasks-accordion">
            <cb-task-card *ngFor="let task of filteredIncompleteTasks.value"
                          [task]="task"
                          [permissions]="permissions"
                          [allowFollowUp]="allowFollowUp"
                          (taskClosed)="taskClosed($event)"
                          [edit]="edit"
                          [completableButNotEditable]="completableButNotEditable"></cb-task-card>
        </mat-accordion>
        <cb-info-message
            *ngIf="tasksLoaded && filteredIncompleteTasks.value && filteredIncompleteTasks.value.length === 0"
            message="No results found"></cb-info-message>
    </div>
    <div class="list-container flex-col flex-50">
        <mat-card class="list-card">
            <mat-card-content>
                <span class="mat-body-strong list-title">Completed</span>
            </mat-card-content>
        </mat-card>
        <mat-accordion>
            <cb-task-card *ngFor="let task of filteredCompleteTasks.value"
                          [task]="task"
                          [permissions]="permissions"
                          [allowFollowUp]="allowFollowUp"
                          [edit]="edit"></cb-task-card>
        </mat-accordion>
        <cb-info-message *ngIf="tasksLoaded && filteredCompleteTasks.value && filteredCompleteTasks.value.length === 0"
                         message="No results found"></cb-info-message>
    </div>
</div>
<cb-loading-spinner *ngIf="!tasksLoaded"></cb-loading-spinner>
