import { Component, Inject } from '@angular/core';
import { PurchaseOrderApprovalStatusEnumId, PURCHASE_ORDER_APPROVAL_STATUS_ENUM } from '@classictechsolutions/hubapi-transpiled-enums';
import { IPurchaseOrderMappedItem } from '@app/logic/purchase-orders';
import { IPurchaseOrdersLogicService } from '@app/logic/purchase-orders/interfaces/i.purchase-orders.logic.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { CurrentUserService } from '@app/core/authentication/current.user';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { IPurchaseOrderDto } from '@app/logic/purchase-orders/interfaces/i.purchase-order.dto';
import { ComputedProperty } from '@app/shared/utils/computed-property.util';

interface IData {
    mappedItem: IPurchaseOrderMappedItem;
}

@Component({
    selector: 'cb-review-manual-order-dialog',
    templateUrl: './review-manual-order-dialog.component.html',
    styleUrls: ['./review-manual-order-dialog.component.scss']
})
export class ReviewManualOrderDialogComponent extends BaseDialogFormViewDirective<IPurchaseOrderDto, IPurchaseOrderMappedItem, IPurchaseOrdersLogicService> {
    public static readonly MIN_WIDTH = '40%';

    public get mappedItem(): IPurchaseOrderMappedItem {
        return this.data.mappedItem;
    }

    public approvalStatus: PurchaseOrderApprovalStatusEnumId;
    public readonly PURCHASE_ORDER_APPROVAL_STATUS_ENUM = PURCHASE_ORDER_APPROVAL_STATUS_ENUM;
    public comments = '';

    public commentsRequired = new ComputedProperty(() => this.approvalStatus === PURCHASE_ORDER_APPROVAL_STATUS_ENUM.Rejected);

    constructor(
        public readonly dialogRef: MatDialogRef<ReviewManualOrderDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) readonly toastService: ToastService,
        @Inject(CurrentUserService) public readonly currentUser: CurrentUserService,
    ) {
        super(dialogRef, toastService);
    }

    public submit(): void {
        if (this.approvalStatus == null) {
            return;
        }
        this.mappedItem
            .submitApprovalDescision(this.approvalStatus, this.comments)
            .subOnce({ next: this.handleNext });
    }
}
