import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ILotMappedItem, LotsLogicService } from '@app/logic/lots';
import { NonWorkingDayLogicService } from '@app/logic/non-working-day';
import { PricingComplexityLogicService } from '@app/logic/pricing-complexity';
import { IPricingRevisionMappedItem } from '@app/logic/pricing-revisions/interfaces/i.pricing-revision.mapped';
import { IPricingRevisionsLogicService } from '@app/logic/pricing-revisions/interfaces/i.pricing-revisions.logic.service';
import { PricingRevisionsLogicService } from '@app/logic/pricing-revisions/pricing-revisions.logic.service';
import { TeamsLogicService } from '@app/logic/teams/teams.logic.service';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { FormMode } from '@app/shared/enums/form';
import { IManageDialogData } from '@app/shared/interfaces/i.manage-dialog-data';
import { IDesignSchemeDto, ILotDto, IPricingComplexityDto, IPricingRevisionDto, ISkinnyUserDto, TEAM_CODES_CONST } from '@classictechsolutions/hubapi-transpiled-enums';
import { find } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';


@Component({
    selector: 'cb-manage-pricing-revision-dialog',
    templateUrl: './manage-pricing-revision-dialog.component.html',
    styleUrls: ['./manage-pricing-revision-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagePricingRevisionDialogComponent extends BaseDialogFormViewDirective<
IPricingRevisionDto,
IPricingRevisionMappedItem,
IPricingRevisionsLogicService> implements OnInit, OnDestroy {
    public static readonly MIN_WIDTH = '500px';

    public form: UntypedFormGroup;
    public currentLot: ILotMappedItem;
    public readonly QS_TEAM = [TEAM_CODES_CONST.QsTeamKey];
    private _subscriptions = new Subscription();

    public designSchemes$: Observable<IDesignSchemeDto[]>;

    public pricingComplexities$ = this.pricingComplexityLogicService.$getList();

    private _pricingComplexities$ = new BehaviorSubject<IPricingComplexityDto[]>(null);

    public quantitySurveyors$ = this
        .teamsLogicService.loadActiveMembersByKey(TEAM_CODES_CONST.QsTeamKey);

    public creatingFromLot$ = this.isAdd$.pipe(
        map(isAdd => isAdd &&
            this.currentLot !== undefined &&
            this.currentLot != null)
    );

    public selectedAssignee: ISkinnyUserDto;
    public selectedReviewer: ISkinnyUserDto;
    public selectedLot: ILotDto;

    constructor(
        private readonly teamsLogicService: TeamsLogicService,
        private readonly pricingComplexityLogicService: PricingComplexityLogicService,
        private readonly logicService: PricingRevisionsLogicService,
        private readonly lotLogicService: LotsLogicService,
        private readonly nonWorkingDaysLogicService: NonWorkingDayLogicService,
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<ManagePricingRevisionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IManageDialogData<IPricingRevisionMappedItem> & { mode: FormMode },
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        this.formMode$.next(data.mode);
        this._subscriptions.add(
            this.pricingComplexities$.subscribe(this._pricingComplexities$)
        );
    }

    public ngOnInit(): void {
        this._subscriptions.add(
            this.quantitySurveyors$.subscribe(this._setupAutocompleteSelectedItems)
        );
        if (this.mappedItem?.lotId) {
            this.designSchemes$ = this.lotLogicService
                .getDesignSchemesAvailableToPriceRevision(
                    this.mappedItem.lotId, this.mappedItem.id || 0);
        }
    }

    public lotChanged(): void {
        if (this.selectedLot?.id) {
            this.mappedItem.lotId = this.selectedLot.id as number;
            this.mappedItem.lotNumber = this.selectedLot.lotNumber as number;
            this.mappedItem.amenities = this.selectedLot.amenities;
            this.mappedItem.designSchemeId = undefined;
            this.mappedItem.designSchemeName = undefined;
            this.designSchemes$ = this.lotLogicService
                .getDesignSchemesAvailableToPriceRevision(
                    this.mappedItem.lotId, this.mappedItem.id || 0);
        }
    }

    public ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    public updateId(idToAssign: string, assignToProp: string): void {
        this.mappedItem[assignToProp] = idToAssign;
    }

    public reviewRequiredChanged(value: boolean): void {
        if (!value) {
            this.selectedReviewer = undefined;
            this.mappedItem.reviewerId = undefined;
            this.mappedItem.reviewerName = undefined;
        }
    }

    public cancelPricingRevision(): void {
        this.logicService.cancel(this.mappedItem.id)
            .subOnce(result => this.dialogRef.close(result));
    }

    public pricingComplexityChanged(): void {
        const selectedItem = find(this._pricingComplexities$.value, { id: this.mappedItem.pricingComplexityId });
        if (selectedItem) {
            this.mappedItem.targetWorkHours = selectedItem.targetWorkHours;
            // eslint-disable-next-line eqeqeq
            const startDate = this.mappedItem.createdDate != undefined ? this.mappedItem.createdDate : moment().format();
            this._subscriptions.add(
                this.nonWorkingDaysLogicService
                    .getDueDate(startDate, selectedItem.targetCompletionHours)
                    .subscribe(dateResult => this.mappedItem.dueBy = dateResult)
            );
        }
    }

    private _setupAutocompleteSelectedItems = (quantitySurveyors: ISkinnyUserDto[]): void => {
        this._setupLotDetails(quantitySurveyors);
        if (this.mappedItem.lotNumber) {
            this.selectedLot = {
                lotDescription: this.mappedItem.lotDescription,
                id: this.mappedItem.lotId,
                lotNumber: this.mappedItem.lotNumber,
                amenities: this.mappedItem.amenities
            } as ILotDto;
        }
        this.selectedReviewer = find(quantitySurveyors, { id: this.mappedItem.reviewerId });
        this.selectedAssignee = find(quantitySurveyors, { id: this.mappedItem.assignedToUserId });
    };

    private _setupLotDetails = (quantitySurveyors: ISkinnyUserDto[]): ISkinnyUserDto[] => {
        if (this.currentLot) {
            this.mappedItem.amenities = this.currentLot.amenities;
            this.mappedItem.lotNumber = this.currentLot.lotNumber;
            this.mappedItem.lotId = this.currentLot.$id as number;
            this.mappedItem.lotDescription = `${this.currentLot.jobNumber} - Lot ${this.currentLot.lotNumber}`;
        }
        return quantitySurveyors;
    };
}
