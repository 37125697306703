<ng-container>
    <cb-table class="cb-margin-top"
              label="Schedule Items"
              [loaded]="true"
              [minWidth]="750"
              [hideDefaultTable]="true">
        <table cbTable>
            <thead>
                <tr>
                    <td width="220"></td>
                    <td></td>
                    <td>Name</td>
                    <td>Tag(s)</td>
                    <td>Code</td>
                    <td width="320">Product Name/Description</td>
                    <td>Cost Type</td>
                    <td>Quantity</td>
                    <td style="width:48px"
                        *ngIf="!isSkinnyView"></td>
                </tr>
            </thead>
            <tbody class="parent-item"
                   *ngFor="let parentItem of houseAreaIdWithItems?.mappedItems">
                <cb-lot-spec-schedule-items-table-row class="tr parent-row"
                                                      [ngClass]="{ 'table-warn-no-scrollbar': parentItem.isIncomplete }"
                                                      [(expandedParents)]="expandedParents"
                                                      [isExpandCollapseAllClicked]="isExpandCollapseAllClicked"
                                                      [item]="parentItem"
                                                      [houseAreaId]="houseAreaIdWithItems?.houseAreaId"
                                                      [lotId]="lotId">
                </cb-lot-spec-schedule-items-table-row>
                <ng-container *ngIf="expandedParents[parentItem.id]">
                    <ng-container *ngFor="let childItem of parentItem.childItems">
                        <cb-lot-spec-schedule-items-table-row class="tr"
                                                              [parentItem]="parentItem"
                                                              [isExpandCollapseAllClicked]="isExpandCollapseAllClicked"
                                                              [ngClass]="{ 'table-warn-no-scrollbar': childItem.isIncomplete }"
                                                              [item]="childItem"
                                                              [houseAreaId]="houseAreaIdWithItems?.houseAreaId"
                                                              [lotId]="lotId">
                        </cb-lot-spec-schedule-items-table-row>
                    </ng-container>
                </ng-container>
            </tbody>
            <tbody *ngIf="houseAreaIdWithItems?.mappedItems?.length < 1">
                <tr class="cb-table-footer-row">
                    <td colspan="100%">
                        <cb-info-message message="There are no Schedule Items to display for this House Area"
                                         icon="info_circle"></cb-info-message>
                    </td>
                </tr>
            </tbody>
        </table>
    </cb-table>
</ng-container>
