<mat-card class="cb-margin flex-col flex">
    <mat-card-header>
        <mat-card-title>{{dto.name}}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex">
        <ng-container>
            <div class="flex-col">
                <cb-display-value-new label="Lead Type">{{contractType}}</cb-display-value-new>
                <cb-display-value-new label="Building Consultant">
                    {{dto.buildingConsultantName}}
                </cb-display-value-new>
                <cb-display-value-new label="Status">{{dto.status}}</cb-display-value-new>
                <cb-display-value-new label="Next Callback">{{getCallbackDate() | date: "dd MMM yyyy" | cbDasher}}</cb-display-value-new>
            </div>
        </ng-container>
    </mat-card-content>
    <mat-card-actions class="end-center flex-row">
        <button mat-stroked-button
                color="accent"
                (click)="selectionChosen(dto)">
            Add Lead
        </button>
    </mat-card-actions>
</mat-card>