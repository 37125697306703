<div class="flex-row flex">
    <div class="flex-col flex">
        <table mat-table
               #table
               class="cb-table"
               [dataSource]="categoryTree">
            <ng-container matColumnDef="categoryTreeItem">
                <th mat-header-cell
                    *matHeaderCellDef
                    style="width:50%">
                    Category Filter
                </th>
                <ng-container *matCellDef="let categoryTreeItem;  let isLast = last; let isFirst = first; let i = index">
                    <td mat-cell
                        *ngIf="!isLast"
                        style="width:50%">
                        <span *ngIf="categoryTreeItem.categories.length > 0 && !isLast">
                            {{categoryTreeItem.label}}
                        </span>
                    </td>
                </ng-container>
            </ng-container>
            <ng-container matColumnDef="categoryTreeItemSelection">
                <th mat-header-cell
                    style="width:50%"
                    *matHeaderCellDef></th>
                <ng-container *matCellDef="let categoryTreeItemSelection;  let isLast = last; let isFirst = first; let i = index">
                    <td mat-cell
                        style="width:50%"
                        *ngIf="!isLast">
                        <span *ngIf="categoryTreeItemSelection.categories.length > 0 && !isLast">
                            {{getSelectedCategoryLabel(categoryTreeItemSelection?.selectedCategory?.id,
                            categoryTreeItemSelection?.categories)}}
                        </span>
                    </td>
                </ng-container>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell
                    style="display: none;"
                    *matHeaderCellDef></th>
                <ng-container *matCellDef="let categoryTreeItem;  let isLast = last; let isFirst = first; let i = index">
                    <td mat-cell
                        *ngIf="isLast"
                        colspan="2">
                        <div class="start-center flex-row"
                             style="width: 100%;">
                            <span class="mat-body-2 start-center flex-row flex"
                                  *ngIf="categoryTreeItem.categories.length === 0"
                                  style="min-width: 400px">
                                <mat-icon class="mat-accent">
                                    info
                                </mat-icon>
                                &nbsp;
                                {{categoryTreeItem.label}} does not have any child categories
                            </span>
                            <cb-select class="flex"
                                       *ngIf="categoryTreeItem.categories.length > 0 && isLast"
                                       [label]="categoryTreeItem.label"
                                       name="mainCategory"
                                       [options]="categoryTreeItem.categories"
                                       (change)="onCategorySelected($event, categoryTreeItem.categories)">
                            </cb-select>
                            <button *ngIf="isLast && !isFirst"
                                    [hidden]="isLastCategoryRestricted()"
                                    [disabled]="isFetching"
                                    mat-icon-button
                                    (click)="removeLastCategory()">
                                <mat-icon>remove_circle</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
            </ng-container>
            <tr mat-header-row
                *matHeaderRowDef="displayedColumns">
            </tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns;">
            </tr>
        </table>
    </div>
</div>
