<mat-card class="cb-margin cb-padding-2x flex-col flex">
    <mat-card-content>
        <div>
            <button class="cb-margin-bottom"
                    mat-raised-button
                    (click)="copyInfoToClipboard($event)">Copy Table to Clipboard
            </button>
        </div>
        <div class="flex">
            <table id="info-table">
                <thead>
                <tr>
                    <th>Severity</th>
                    <th>Type</th>
                    <th>Name</th>
                    <th colspan="4">Description</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let rule of data?.rules">
                    <td>{{ rule.severity }}</td>
                    <td>{{ rule.type }}</td>
                    <td>{{ rule.name }}</td>
                    <td colspan="4">
                        <span class="description"
                              [innerHTML]="rule.htmlDesc"></span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>
