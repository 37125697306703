import { LeadSearchService } from '@app/core/services/search/lead-search.service';
import { Component } from '@angular/core';
import { LeadLogicService } from '@app/logic/leads';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { getBaseFormComponentDirectiveProvider } from '../../../base-form-component';
import { provideParentForm } from '@app/shared/providers/provide-parent-form.provider';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { LeadsInputFindDirective } from './leads-input-find.directive';
import { LeadsInputFindDialogCardsComponent } from '../../dialog/extensions/leads-input-find-dialogs/leads-input-find-dialog-cards.component';

@Component({
    selector: 'cb-leads-input-find-cards',
    templateUrl: '../../base-input-find.directive.html',
    styleUrls: ['../../base-input-find.directive.scss'],
    providers: [
        ...getBaseFormComponentDirectiveProvider(LeadsInputFindCardsComponent),
        LeadSearchService,
        provideParentForm()
    ]
})
export class LeadsInputFindCardsComponent extends LeadsInputFindDirective {
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public readonly leadLogicService: LeadLogicService,
        public readonly navigationService: NavigationService,
        public readonly searchService: LeadSearchService) {
        super(
            dialogRef,
            router,
            leadLogicService,
            navigationService,
            searchService
        );
        this._myDialog = LeadsInputFindDialogCardsComponent;
    }
}
