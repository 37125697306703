<cb-dialog dialogHeading="Edit Build Programme Activity">
    <form #editBuildProgrammeActivityForm="ngForm">
        <mat-tab-group class="tab-container"
                       mat-stretch-tabs="false">
            <mat-tab label="Activity Details">
                <cb-edit-build-programme-activity class="tab-content-margin"
                                                  [buildProgrammeActivity]="mappedItem"
                                                  (dateCalculating)="dateCalculating = $event"
                                                  (durationChanged)="durationHasChanged($event)">
                </cb-edit-build-programme-activity>
            </mat-tab>
            <mat-tab label="Activity Relationships">
                <cb-build-programme-activity-relationships class="tab-content-margin"
                                                           *ngIf="activityRelationships"
                                                           [buildProgrammeActivity]="mappedItem"
                                                           [activityRelationships]="activityRelationships">
                </cb-build-programme-activity-relationships>
            </mat-tab>
        </mat-tab-group>
    </form>
    <div actions>
        <button mat-raised-button
                [disabled]="isDisabled(editBuildProgrammeActivityForm)"
                color="primary"
                (click)="save()">Save</button>
        <button mat-raised-button
                (click)="cancel()">Cancel</button>
    </div>
</cb-dialog>
