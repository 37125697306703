import {Component, OnInit} from '@angular/core';
import {IProductDto, IProductMappedItem, ProductLogicService} from '@app/logic/products';
import {OFFERING_STATUS_ENUM, PRODUCT_TYPE_ENUM} from '@classictechsolutions/hubapi-transpiled-enums';

import {ActivatedRoute} from '@angular/router';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {FormMode} from '@app/shared/enums/form';
import {IProductLogicService} from '@app/logic/products/interfaces/i.product.logic.service';
import {ManageAvailabilityDialogComponent} from '../dialogs/manage-availability-dialog/manage-availability-dialog.component';
import {ManageBundleDialogComponent} from '../../tabs/product-catalogue/product-catalogue-item-dialog/manage-bundle-dialog.component';
import {ManageImagesDialogComponent} from '../dialogs/manage-images-dialog/manage-images-dialog.component';
import {ManageProductDialogComponent} from '../../tabs/product-catalogue/product-catalogue-item-dialog/manage-product-dialog.component';
import {ManageRatesDialogComponent} from '../dialogs/manage-rates-dialog/manage-rates-dialog.component';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {NavigationService} from '@app/core/services/navigation/navigation.service';
import {PRODUCT_TAB_FULL_ROUTE} from '../../products-route.const';
import {ProductPermissions} from '@app/core/permissions';
import {ToastService} from '@app/core/services/toast/toast.service';
import {emptyAttribute} from '@app/logic/products/interfaces/i.product.dto';
import {switchMap} from 'rxjs';
import {BaseFormViewDirective} from '@app/shared/base-views/base-form-view.directive';

@Component({
    selector: 'cb-view-product',
    templateUrl: './view-product.component.html',
    styleUrls: ['./view-product.component.scss'],
    providers: [NavigationService]
})
export class ViewProductComponent extends BaseFormViewDirective<IProductDto, IProductMappedItem, IProductLogicService> implements OnInit {
    public mappedItem: IProductMappedItem;
    public compositeProduct: IProductDto;
    public PRODUCT_TYPE_ENUM = PRODUCT_TYPE_ENUM;
    public OFFERING_STATUS_ENUM = OFFERING_STATUS_ENUM;
    public readonly PRODUCT_TAB_FULL_ROUTE = PRODUCT_TAB_FULL_ROUTE;

    constructor(
        public readonly navigationService: NavigationService,
        public readonly productLogicService: ProductLogicService,
        public readonly activatedRoute: ActivatedRoute,
        private readonly cbDialogService: CbDialogService,
        public readonly toastService: ToastService,
        public readonly productPermissions: ProductPermissions
    ) {
        super(toastService);
    }

    public ngOnInit(): void {

        this.activatedRoute.params.pipe(
            switchMap((params: { id: string }) => {
                return this.productLogicService.$getMappedItem(+params.id);
            })
        ).subOnce((mappedItem: IProductMappedItem) => {
            this.setupMappedItem(mappedItem);
            if (this.mappedItem.compositeItemId) {
                this.productLogicService.$getItem(this.mappedItem.compositeItemId).subOnce({
                    next: res => this.compositeProduct = res
                });
            }
        });
    }

    private readonly setupMappedItem = (productMappedItem: IProductMappedItem): void => {
        this.mappedItem = productMappedItem;
        if (this.mappedItem.compositeItemId) {
            this.productLogicService.$getItem(this.mappedItem.compositeItemId).subOnce({
                next: (compositeProductDto) => {
                    this.compositeProduct = compositeProductDto;
                }
            });
        }
    };

    public editProduct = (): void => {
        this.productLogicService.$getItem(this.mappedItem.id).subOnce({
            next: this.handleGetFullProduct
        });
    };

    public openManageAvailability(): void {
        if (this.mappedItem.isBundle() && this.mappedItem.bundleTemplateIsComplete() === false) {
            return;
        }

        const mappedItem = this.productLogicService
            .$createMappedItem(this.mappedItem.$getMappedDtoItem());

        this.cbDialogService.open(
            ManageAvailabilityDialogComponent,
            {
                data: {
                    mappedItem
                },
                fullWidth: true,
                minWidth: 600,
            }
        )
            .afterClosed()
            .subOnce({
                next: this.handleEditItem
            });
    }

    public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.formMode = tabChangeEvent.tab.textLabel === 'Item Details' ? FormMode.View : FormMode.Edit;
    }

    public canManageRates(): boolean {
        return this.productPermissions.canManageRates();
    }

    public addRate(): void {
        const rateCopy = emptyAttribute();
        this.cbDialogService.open(
            ManageRatesDialogComponent,
            {
                data: {
                    mappedItem: this.mappedItem,
                    rate: rateCopy
                },
                fullWidth: true,
                minWidth: '70%',
            }
        )
            .afterClosed()
            .subOnce({
                next: this.handleRate
            });
    }

    public openManageImages(): void {
        if (this.mappedItem.isBundle() && this.mappedItem.bundleTemplateIsComplete() === false) {
            return;
        }

        this.cbDialogService.open(
            ManageImagesDialogComponent,
            {
                data: {
                    mappedItem: this.mappedItem
                },
                fullWidth: true,
                minWidth: '80%'
            }
        )
            .afterClosed()
            .subOnce({
                next: this.handleEditItem
            });
    }

    private readonly openEditDialog = (mappedItem: IProductMappedItem) => {
        const dialog = mappedItem.productType === PRODUCT_TYPE_ENUM.Bundle ?
            ManageBundleDialogComponent :
            ManageProductDialogComponent;
        this.cbDialogService.open(
            dialog,
            {
                data: {
                    mappedItem
                },
                fullWidth: true,
                minWidth: 600,
            }
        )
            .afterClosed()
            .subOnce({
                next: this.handleEditItem
            });
    };

    public handleEditItem = (item: any): void => {
        if (item) {
            if (item !== true) {
                this.mappedItem = this.productLogicService.$createMappedItem(item);
                this.viewProduct(this.mappedItem.id);
            } else {
                this.viewProduct(this.mappedItem.id);
            }
        }
    };

    public handleRate = (item: any): void => {
        if (item) {
            this.viewProduct(this.mappedItem.id);
        }
    };

    public handleGetFullProduct = (product: IProductDto): void => {
        const mappedItem = this.productLogicService
            .$createMappedItem(product);
        this.openEditDialog(mappedItem);
    };

    public canManageAvailability(): boolean {
        if (this.mappedItem.isBundle()) {
            const templateId = this.mappedItem.bundleTemplateId;
            if (!this.mappedItem.products) {
                return false;
            }
            return this.productPermissions.canManageProductAvailability()
                && (templateId ? this.mappedItem.bundleTemplateIsComplete() : true)
                && (this.mappedItem.products.length > 1 || (this.mappedItem.isActive && this.mappedItem.products.length > 0));
        } else {
            return true;
        }
    }

    public getHtmlClassFromStatus(): string {
        return this.mappedItem?.status === OFFERING_STATUS_ENUM.Active ? 'cb-primary' : 'cb-warn';
    }

    public canEdit = (): boolean => {
        return this.mappedItem?.isActive;
    };

    public canManageImages(): boolean {
        return this.productPermissions.canManageImages();
    }

    public canViewProductUsage(): boolean {
        return this.productPermissions.canViewProductUsage();
    }

    public canEditProduct(): boolean {
        return this.productPermissions.canEdit();
    }

    public getStatusText(): string {
        switch (this.mappedItem.status) {
            case OFFERING_STATUS_ENUM.Active:
                return '(Active)';
            case OFFERING_STATUS_ENUM.TemporarilyUnavailable:
                return '(Unavailable)';
            case OFFERING_STATUS_ENUM.PermanentlyUnavailable:
                return '(Inactive)';
            default:
                return '';
        }
    }
    public viewProduct = (productId: number): void => {
        this.navigationService.redirectTo(`view-product/${productId}/details`);
    };
}
