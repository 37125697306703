import {Component, Inject} from '@angular/core';
import {BaseDialogFormViewDirective} from '@app/shared/base-views/base-dialog-form-view.directive';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastService} from '@app/core/services/toast/toast.service';
import {IBuildOverheadCostMappedItem} from '@app/logic/build-overhead-costs';
import {IBuildOverheadCostDto} from '@classictechsolutions/hubapi-transpiled-enums';
import {IBuildOverheadCostLogicService} from '@app/logic/build-overhead-costs/interfaces/i.build-overhead-cost-logic.service';

export interface IData {
    mappedItem: IBuildOverheadCostMappedItem;
}
@Component({
    selector: 'cb-product-build-overhead-costs-dialog',
    templateUrl: './product-build-overhead-costs-dialog.component.html',
    styleUrls: ['./product-build-overhead-costs-dialog.component.scss']
})
export class ProductBuildOverheadCostsDialogComponent
    extends BaseDialogFormViewDirective<IBuildOverheadCostDto, IBuildOverheadCostMappedItem, IBuildOverheadCostLogicService> {

    constructor(
        public readonly toastService: ToastService,
        public readonly dialogRef: MatDialogRef<ProductBuildOverheadCostsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ){
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
    }
}
