import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TakeoffsLogicService} from '@app/logic/takeoffs/takeoffs.logic.service';
import {CbDialogService} from '@app/shared/components/dialog/cb-dialog.service';
import {ToastService} from '@app/core/services/toast/toast.service';
import {ICostXImportResultDto} from '@classictechsolutions/hubapi-transpiled-enums';

@Component({
    selector: 'takeoff-errors-dialog',
    templateUrl: './takeoff-errors-dialog.component.html',
    styleUrls: ['./takeoff-errors-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeoffErrorsDialogComponent {

    public static readonly MIN_WIDTH = '800px';
    public takeOffResult: ICostXImportResultDto;

    constructor(
        private readonly dialogService: CbDialogService,
        private readonly toastService: ToastService,
        private readonly takeoffsLogicService: TakeoffsLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: {
            takeOffResult: ICostXImportResultDto;
        },

        public readonly dialogRef: MatDialogRef<TakeoffErrorsDialogComponent>
    ) {
        this.takeOffResult = data.takeOffResult;

    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public getTransferHistoryErrors(): void {
        const observable = this.takeoffsLogicService.getTransferHistoryErrors(this.takeOffResult.importHistoryId).subOnce();

    }
}
