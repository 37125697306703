import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientSalesSummaryReportComponent } from './client-sales-summary-report/client-sales-summary-report.component';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CbInfoMessageModule, CbTableModule, CbInfoPopupModule } from 'cb-hub-lib';
import { CbDatePipeModule } from '@app/shared/pipe/date/date.module';
import { CbButtonModule } from '@app/shared/components/button/button.module';
import {CbSortByModule} from '@app/shared/pipe/sort-by/sort-by.module';


const MAT_MODULES = [
    MatCardModule,
];

@NgModule({
    declarations: [
        ClientSalesSummaryReportComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forRoot([
            {
                component: ClientSalesSummaryReportComponent,
                path: 'building-consultant-dashboard',
                data: {
                    breadcrumb: 'Building Consultant Dashboard',
                }
            },
            {
                component: ClientSalesSummaryReportComponent,
                path: 'building-consultant-dashboard/:tabName',
                data: {
                    breadcrumb: 'Building Consultant Dashboard',
                }
            },
        ], {}),
        CbTableModule,
        CbDatePipeModule,
        CbInfoMessageModule,
        CbButtonModule,
        CbInfoPopupModule,
        MatTooltipModule,
        ...MAT_MODULES,
        CbSortByModule
    ]
})
export class SalesDashboardModule { }
