import { Component, OnInit, Inject } from '@angular/core';
import { IProductCategoryDto } from '@app/logic/product-categories/interfaces/i.product-category.dto';
import { IProductCategoriesLogicService } from '@app/logic/product-categories/interfaces/i.product-categories.logic.service';
import { IProductCategoryMappedItem } from '@app/logic/product-categories/interfaces/i.product-category.mapped';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManageProductDialogComponent } from '../../product-catalogue/product-catalogue-item-dialog/manage-product-dialog.component';
import { ToastService } from '@app/core/services/toast/toast.service';
import { CategoryLogicService } from '@app/logic/product-categories';
import { FormMode } from '@app/shared/enums/form';

export interface IData {
    parentCategory: IProductCategoryMappedItem;
    mappedItem: IProductCategoryMappedItem;
}

@Component({
    selector: 'cb-manage-product-category-dialog',
    templateUrl: './manage-product-category-dialog.component.html',
    styleUrls: ['./manage-product-category-dialog.component.scss']
})
export class ManageProductCategoryDialogComponent
    extends BaseDialogFormViewDirective<IProductCategoryDto, IProductCategoryMappedItem, IProductCategoriesLogicService> implements OnInit {
    public static readonly MIN_WIDTH = '50%';

    public parentCategory: IProductCategoryMappedItem;
    public mappedItem: IProductCategoryMappedItem;
    public dialogHeading: string;

    constructor(
        public readonly dialogRef: MatDialogRef<ManageProductDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
        @Inject(ToastService) public readonly toastService: ToastService,
        public readonly categoryLogicService: CategoryLogicService) {
        super(dialogRef, toastService);
        this.mappedItem = data.mappedItem;
        this.parentCategory = data.parentCategory;
    }

    public ngOnInit(): void {
        this.formMode = FormMode.Edit;
        if (this.mappedItem === undefined && this.parentCategory !== undefined) {
            // intention is to add a child category -- need to create a new mappedItem
            this.formMode = FormMode.Add;
            this.mappedItem = this.categoryLogicService.$createMappedItem({ parentId: this.parentCategory.id });
            this.mappedItem.isActive = !this.mappedItem.isDeleted;
        } else if (this.mappedItem === undefined && this.parentCategory === undefined) {
            this.formMode = FormMode.Add;
            this.mappedItem = this.categoryLogicService.$createMappedItem();
            this.mappedItem.isActive = !this.mappedItem.isDeleted;
        }

        this.dialogHeading = this.getDialogHeading();
    }

    public isActiveChanged = (): void => {
        this.mappedItem.isDeleted = !this.mappedItem.isActive;
    };

    public getDialogHeading(): string {
        let returnValue = '';
        if (this.isEdit()) {
            returnValue = 'Edit Category';
        } else if (this.isAdd() && !this.parentCategory) {
            returnValue = 'Add Top Level Category';
        } else if (this.isAdd() && this.parentCategory) {
            returnValue = 'Add Child Category';
        }

        return returnValue;
    }
}
