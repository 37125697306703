import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseDialogFormViewDirective } from '@app/shared/base-views/base-dialog-form-view.directive';
import { ToastService } from '@app/core/services/toast/toast.service';
import { INonWorkingDayDto, INonWorkingDayLogicService, INonWorkingDayMappedItem, NonWorkingDayLogicService, NonWorkingDayMappedItem } from '@app/logic/non-working-day';

interface IData {
    mappedItem: NonWorkingDayMappedItem;
    year: number;
}

@Component({
    selector: 'cb-non-working-day-dialog',
    templateUrl: './non-working-day-dialog.component.html',
    styleUrls: ['./non-working-day-dialog.component.scss']
})
export class NonWorkingDayDialogComponent extends BaseDialogFormViewDirective<INonWorkingDayDto, INonWorkingDayMappedItem, INonWorkingDayLogicService> {
    public static readonly MIN_WIDTH = '50%';
    public mappedItem: NonWorkingDayMappedItem;
    public nonWorkingDays: INonWorkingDayDto[];

    constructor(
        public readonly toastSerivce: ToastService,
        public readonly dialogRef: MatDialogRef<NonWorkingDayDialogComponent>,
        protected readonly nonWorkingDayLogicService: NonWorkingDayLogicService,
        @Inject(MAT_DIALOG_DATA) public readonly data: IData,
    ) {
        super(dialogRef, toastSerivce);
        this.mappedItem = data.mappedItem;
        this.nonWorkingDayLogicService.$getList(data.year).subscribe(result => this.nonWorkingDays = result);
    }
}
