import { BaseInputFindDirective } from '../../base-input-find.directive';
import { LeadSearchService } from '@app/core/services/search/lead-search.service';
import { Directive } from '@angular/core';
import { ILeadDto, LeadLogicService } from '@app/logic/leads';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { takeOne } from 'cb-hub-lib';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { ComponentType } from '@angular/cdk/portal';
import { LeadsInputFindDialogDirective } from '../../dialog/extensions/leads-input-find-dialogs/leads-input-find-dialog.directive';
import { viewLeadUrl } from '@app/views/leads/lead-routes';

@Directive()
export class LeadsInputFindDirective extends BaseInputFindDirective {
    protected _myDialog: ComponentType<LeadsInputFindDialogDirective>;
    constructor(
        public readonly dialogRef: MatDialog,
        public readonly router: Router,
        public readonly leadLogicService: LeadLogicService,
        public readonly navigationService: NavigationService,
        public readonly searchService: LeadSearchService) {
        super();
    }

    public openSearchDialog = (): void => {
        this.dialogRef.open(
            this._myDialog,
            {
                minWidth: '70%',
                data: {
                    extraSearchParams: this.searchParams,
                    placeholder: 'Type First Name, Last Name, or Email',
                    dialogHeading: 'Leads',
                    contentHeading: 'Lead Search',
                    searchService: this.searchService
                },
                panelClass: 'cb-dialog-container'
            }
        )
            .afterClosed()
            .pipe(takeOne())
            .subscribe({
                next: (lead: ILeadDto) => {
                    if (lead) {
                        this.text = lead.name;
                        this.leadLogicService.$getMappedItem(lead.id).subOnce(leadMapped => this.value = leadMapped);
                    }
                }
            });
    };

    public viewItem = (): void => {
        this.navigationService.navigate([viewLeadUrl(this.id)]);
    };
}
