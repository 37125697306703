<mat-tab-group class="cb-margin-left-2x flex" mat-stretch-tabs="false">
    <mat-tab label="Design Complexity">
        <ng-template matTabContent>
            <cb-design-complexity></cb-design-complexity>
        </ng-template>
    </mat-tab>
    <mat-tab label="Default Design Requirements Documents">
        <ng-template matTabContent>
            <cb-default-system-area-documents [systemArea]="designRequirementsArea"
                                              [documentGroupCodes]="designRequirementsDocGroups"
                                              [disableContractType]="true"
                                              label="Design Requirements">
            </cb-default-system-area-documents>
        </ng-template>
    </mat-tab>
    <mat-tab label="Default Scheme Documents">
        <ng-template matTabContent>
            <cb-default-system-area-documents [systemArea]="designSchemeArea"
                                              [documentGroupCodes]="designSchemeDocGroups"
                                              label="Design Scheme">
            </cb-default-system-area-documents>
        </ng-template>
    </mat-tab>
    <mat-tab label="Pre-consent Documents">
        <ng-template matTabContent>
            <cb-default-system-area-documents [systemArea]="preconsentArea"
                                              [documentGroupCodes]="preconsentDocGroups"
                                              [disableContractType]="true"
                                              label="Pre-consent">
            </cb-default-system-area-documents>
        </ng-template>
    </mat-tab>
    <mat-tab label="Pre-consent Plan Documents">
        <ng-template matTabContent>
            <cb-default-system-area-documents [systemArea]="preconsentPlanArea"
                                              [documentGroupCodes]="preconsentPlanDocGroups"
                                              label="Pre-consent Plan">
            </cb-default-system-area-documents>
        </ng-template>
    </mat-tab>
    <mat-tab label="Design Schemes Requiring Notifications">
        <ng-template matTabContent>
            <cb-design-scheme-notifications></cb-design-scheme-notifications>
        </ng-template>
    </mat-tab>
</mat-tab-group>
