<cb-dialog dialogHeading="{{dialogHeading}}">
    <form class="flex-col flex"
          #manageCategoryForm="ngForm">
        <cb-input type="text"
                  [required]="true"
                  [readonly]="true"
                  label="Parent Category"
                  name="parentCategoryName"
                  [maxlength]="256"
                  [(ngModel)]="parentCategory.name"
                  *ngIf="isAdd() && parentCategory">
        </cb-input>
        <cb-input type="text"
                  [required]="true"
                  label="Category Name"
                  name="categoryName"
                  [maxlength]="256"
                  [(ngModel)]="mappedItem.name">
        </cb-input>
        <cb-checkbox name="isActive"
                     label="Is Active"
                     [(ngModel)]="mappedItem.isActive">
        </cb-checkbox>
    </form>
    <div actions>
        <button mat-raised-button
                color="primary"
                (cbClick)="save()"
                [clickLimit]="1"
                [disabled]="!manageCategoryForm.dirty || !manageCategoryForm.valid">Save
        </button>
        <button mat-raised-button
                (click)="cancel()">Cancel
        </button>
    </div>
</cb-dialog>
